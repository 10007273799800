/* eslint-disable react/forbid-prop-types */
import React from 'react';
import GaugeChart from 'react-gauge-chart';

import { lighten } from 'polished';
import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

export default function GaugeInfo({ options, item, show, label }) {
    // Dynamically create colors for interaction types
    const { n_colors } = options;

    const n_step = (1 - 0.25) / n_colors;
    const gauge_colors = [];
    for (let i = 0; i < n_colors; i++) {
        const factor = options.orientation === 'l2r' ? n_colors - i - 1 : i;
        const light = n_step * factor;
        const c = options.color ? lighten(light, options.color) : '#ffffff';
        gauge_colors.push(c);
    }

    const { title, progress, value } = item;

    return (
        <div
            className={options.size}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {title ? <LocaleMessage msg={title} className="mb-3" /> : null}
            {show ? (
                <GaugeChart
                    nrOfLevels={20}
                    cornerRadius={1}
                    colors={gauge_colors}
                    percent={progress}
                    hideText
                />
            ) : (
                <div className="info">
                    <span className="big-value mt-3 mb-3">
                        {`${value || 0} `}
                    </span>
                </div>
            )}
            {label || null}
        </div>
    );
}

GaugeInfo.propTypes = {
    options: PropTypes.object,
    item: PropTypes.object,
    show: PropTypes.bool,
    label: PropTypes.element,
};

GaugeInfo.defaultProps = {
    options: {},
    item: {},
    show: false,
    label: null,
};
