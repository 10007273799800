export default function generateDownloadableFile(event, settings) {
    const { filename, data } = settings;
    const output = JSON.stringify(data);

    // Download it
    const blob = new Blob([output], {
        type: 'text/plain',
    });
    const downloadURL = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    if (window.webkitURL != null) {
        downloadLink.href = window.webkitURL.createObjectURL(blob);
    } else {
        downloadLink.href = downloadURL;
        downloadLink.onclick = document.body.removeChild(event.target);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
    }
    downloadLink.click();
}
