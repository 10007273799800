/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import { lighten } from 'polished';
import PropTypes from 'prop-types';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CardItem from '~/components/CardItem';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

export default function RobotTypes({
    customLabel,
    linkRoot,
    goLink,
    allowNew,
}) {
    const dispatch = useDispatch();
    const colors = GetPluginspaceTheme(useTheme());

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [deleteTypeOpen, setDeleteTypeOpen] = useState(false);

    const [types, setTypes] = useState([]);

    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`types?group=robot`)
            .then(response => {
                const data = response.data.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setTypes(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteTypeOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteTypeOpen(false);
        setAnchorEl(null);
    }

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        history.push(`${linkRoot}/${_id}`);
    }

    async function deleteType() {
        setIsLoading(true);
        setDeleteTypeOpen(false);
        await api
            .delete(`types/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.robot_types.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function robotCard(item) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Link to={`${linkRoot}/${item.id}${goLink}`}>
                    <CardItem
                        title={`${item.name}`}
                        hasOptions={allowNew}
                        optionsClick={event => handleClick(event, item.id)}
                        updated={item.updated}
                        img={{ url: item.file.url, name: item.name }}
                        fit="contain"
                        background={
                            colors.primary
                                ? `linear-gradient(0deg, ${lighten(
                                      0.25,
                                      colors.primary
                                  )}, ${colors.primary})`
                                : '#fff'
                        }
                    >
                        <>
                            <small className="card-text" />
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                {allowNew ? (
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={e => goToSettings(e, currItem)}>
                            <LocaleMessage msg="page.robots.list.label.settings" />
                        </MenuItem>
                        <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                            <LocaleMessage msg="page.robots.list.delete" />
                        </MenuItem>
                    </Menu>
                ) : null}
                <div
                    className="row mb-5"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {types.map(r => robotCard(r))}
                </div>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteTypeOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.robot_types.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            setAnchorEl(null);
                            setDeleteTypeOpen(false);
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteType(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={customLabel || 'page.robot_types.list.title'}
                />
            }
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteTypeOpen ? buildDeleteDialog() : null}
                {allowNew ? (
                    <div className="body-top-controls">
                        <NewItemButton
                            link={`${linkRoot}/new`}
                            text={
                                <LocaleMessage msg="page.robot_types.list.add" />
                            }
                        />
                    </div>
                ) : null}
                {buildGridView()}
            </>
        </PageContent>
    );
}

RobotTypes.defaultProps = {
    allowNew: false,
    goLink: '',
    customLabel: '',
};

RobotTypes.propTypes = {
    linkRoot: PropTypes.string.isRequired,
    goLink: PropTypes.string,
    customLabel: PropTypes.string,
    allowNew: PropTypes.bool,
};
