/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import { MdSearch, MdClose } from 'react-icons/md';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function DataFilter({
    rawData,
    headerColumns,
    returnFilteredData,
    prefilter,
}) {
    const [filter, setFilter] = useState('');

    function filterData(val, pre) {
        const preData =
            pre && pre.key
                ? rawData.filter(row => {
                      return row[pre.key] === pre.value;
                  })
                : rawData;

        if (!val) {
            returnFilteredData(preData);
            return;
        }
        const VAL = val.toUpperCase();
        const f_data = preData.filter(row => {
            return headerColumns.find(column => {
                const d = row[column];
                if (d) {
                    if (Array.isArray(d)) {
                        return (
                            d.findIndex(element =>
                                element.toUpperCase().includes(VAL)
                            ) !== -1
                        );
                    }
                    if (typeof d === 'string') {
                        if (d.toUpperCase().includes(VAL)) {
                            return true;
                        }
                    }
                }
                return false;
            });
        });
        returnFilteredData(f_data);
    }

    useEffect(() => {
        filterData(filter, prefilter);
    }, [rawData, prefilter]);

    const waitForFilter = useCallback(
        debounce((val, pre) => filterData(val, pre), 200),
        []
    );

    function handleFilterChange(val) {
        setFilter(val);
        waitForFilter(val, prefilter);
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '0px 5px',
                }}
            >
                <TextField
                    id="outlined-basic"
                    fullWidth
                    label={<LocaleMessage msg="table.label.filter" />}
                    value={filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdSearch
                                    size={20}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {filter !== '' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            style={{
                                                marginLeft: '10px',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                            }}
                                            onClick={() =>
                                                handleFilterChange('')
                                            }
                                        >
                                            <MdClose />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null}
                            </>
                        ),
                    }}
                    onChange={event => handleFilterChange(event.target.value)}
                />
            </div>
        </>
    );
}

DataFilter.defaultProps = {
    rawData: [],
    headerColumns: [],
    prefilter: null,
};

DataFilter.propTypes = {
    rawData: PropTypes.array,
    headerColumns: PropTypes.array,
    returnFilteredData: PropTypes.func.isRequired,
    prefilter: PropTypes.object,
};
