/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import MultipleInputs from '~/components/Form/MultipleInputs';

export default function WakeUpConfig({ texts, setBody, languages }) {
    function setTexts(t) {
        setBody(t);
    }

    return (
        <div className="col-12 row mt-3 mb-3">
            {languages
                ? languages.map(l => {
                      const l_label = `list.languages.${l}`;
                      const l_texts = texts[l] || [];
                      return (
                          <MultipleInputs
                              margin="mb-1"
                              key={`lang_input_${l}`}
                              language={l}
                              label={l_label}
                              texts={l_texts}
                              open={languages.length === 1}
                              onChange={t => {
                                  setTexts({
                                      ...texts,
                                      [l]: t,
                                  });
                              }}
                          />
                      );
                  })
                : null}
        </div>
    );
}

WakeUpConfig.defaultProps = {
    languages: ['pt_BR'],
    texts: {},
};

WakeUpConfig.propTypes = {
    languages: PropTypes.array,
    texts: PropTypes.object,
    setBody: PropTypes.func.isRequired,
};
