/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import MultiInfoCard from '~/components/MultiInfoCard';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Container } from './styles';

export default function Analytics() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);
    const [counters, setCounters] = useState({});
    const [interactions, setInteractions] = useState({});

    const colors = GetPluginspaceTheme(useTheme());

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadCounters() {
        await api
            .get(`admin/all`)
            .then(response => {
                const data = response.data || {};
                setCounters(data);
            })
            .catch(error => requestError(error));
    }

    async function loadInteractions() {
        await api
            .get(`admin/interactions/counter`)
            .then(response => {
                const data = response.data || {};
                setInteractions(data);
            })
            .catch(error => requestError(error));
    }

    async function loadAll() {
        setIsLoading(true);
        await loadInteractions();
        await loadCounters();

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadAll();
    }, [date_loc]);

    function renderObjectCard(c) {
        const { key } = c;
        if (!key) return null;
        const data = counters[key];
        return (
            <div className="col-md-4 col-12 mb-5 dash-card" key={c.key}>
                <Link to={c.link}>
                    <MultiInfoCard
                        data={[
                            {
                                key: `${key}_total`,
                                value: data ? data.total : '0',
                                subTitle: (
                                    <LocaleMessage msg="page.su_analytics.label.total" />
                                ),
                            },
                            {
                                key: `${key}_active`,
                                value: data ? data.active : '0',
                                subTitle: (
                                    <LocaleMessage msg="page.su_analytics.label.active" />
                                ),
                            },
                        ]}
                        title={<LocaleMessage msg={c.title} />}
                        icon={c.icon}
                        color={colors.dashboard_cards}
                    />
                </Link>
            </div>
        );
    }

    function renderInteractionCard(c) {
        const { key } = c;
        if (!key) return null;
        const data = interactions[key];

        return (
            <div className="col-md-4 col-12 mb-5 dash-card" key={c.key}>
                <MultiInfoCard
                    data={[
                        {
                            key: `${key}_total`,
                            value: data || '0',
                            subTitle: '',
                        },
                    ]}
                    title={<LocaleMessage msg={c.title} />}
                    icon={c.icon}
                    color={colors.dashboard_cards}
                />
            </div>
        );
    }

    const cards = [
        {
            link: '/admin/pluginspaces',
            key: 'pluginspaces',
            title: 'page.su_pluginspace.title',
            icon: 'layers',
        },
        {
            link: '/admin/groups',
            key: 'groups',
            title: 'page.su_groups.title',
            icon: 'account',
        },
        {
            link: '/admin/users',
            key: 'users',
            title: 'page.su_users.title',
            icon: 'account',
        },
        {
            link: '/admin/robots',
            key: 'robots',
            title: 'page.su_robots.title',
            icon: 'android-alt',
        },
        {
            link: '/admin/applications',
            key: 'applications',
            title: 'page.su_applications.title',
            icon: 'play-circle',
        },
        {
            link: '/admin/locations',
            key: 'locations',
            title: 'page.su_locations.title',
            icon: 'pin',
        },
    ];

    const interactions_cards = [
        {
            key: 'messages',
            title: 'page.dashboard.title.dialog_logs',
        },
        {
            key: 'actions',
            title: 'page.dashboard.title.action_logs',
        },
        {
            key: 'invites',
            title: 'page.dashboard.title.invite_logs',
        },
        {
            key: 'telepresence_calls',
            title: 'page.dashboard.title.telepresence_logs',
        },
        {
            key: 'disinfections',
            title: 'page.dashboard.title.disinfection_logs',
        },
        {
            key: 'deliveries',
            title: 'page.dashboard.title.delivery_logs',
        },
    ];

    return (
        <PageContent
            title={<LocaleMessage msg="page.su_analytics.title" />}
            breadcrumbs={[]}
            loading={isLoading}
        >
            <Container>
                <div
                    className="animated slideInUpTiny animation-duration-3 mt-5"
                    style={{ width: '100%' }}
                >
                    <div
                        className="row card-row mb-5"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Typography variant="h6" style={{ padding: '0px 5px' }}>
                            <LocaleMessage msg="page.su_analytics.elements" />
                        </Typography>
                    </div>
                    <div className="row card-row mb-5">
                        {cards.map(c => {
                            return renderObjectCard(c);
                        })}
                    </div>
                    <div
                        className="row card-row mb-5"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Typography variant="h6" style={{ padding: '0px 5px' }}>
                            <LocaleMessage msg="page.su_analytics.interactions_30" />
                        </Typography>
                    </div>
                    <div className="row card-row mb-5">
                        {interactions_cards.map(c => {
                            return renderInteractionCard(c);
                        })}
                    </div>
                    {/* <div
                        className="row"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <div className="col-md-5 mb-5">
                            <Link to="/apps/admin">
                                <Button
                                    className="p-3"
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    <LocaleMessage msg="page.su_analytics.go_dashboard" />
                                </Button>
                            </Link>
                        </div>
                    </div> */}
                </div>
            </Container>
        </PageContent>
    );
}
