/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import GroupSelector from '~/components/GroupSelector';
import LocaleMessage from '~/components/LocaleMessage';

import { Title } from './styles';

export default function Groups() {
    return (
        <>
            <Title>
                <h1 className="title mt-5 mb-5 mb-sm-0">
                    <LocaleMessage msg="message.group_selector.title" />
                </h1>
            </Title>
            <GroupSelector dark />
        </>
    );
}
