/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
// import { MdStarBorder, MdStar } from 'react-icons/md';
import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import OtpInput from 'react-otp-input';
import QRCode from 'react-qr-code';

import PropTypes from 'prop-types';

import { Button, Typography, IconButton } from '@material-ui/core';

import ExitDialog from '~/components/ExitDialog';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import { Column, SettingsArea, ParameterArea, QRArea } from '../styles';

const APP_URL = {
    development: 'http://192.168.15.56:3001',
    staging: 'https://app-staging.pluginbot.ai',
    production: 'https://app.pluginbot.ai',
};

export default function Simulator({ app, updateObject }) {
    const BASE_APP_URL = APP_URL[process.env.REACT_APP_ENV_VERSION];
    const { settings: appConfig } = app;

    const appSettings =
        appConfig && appConfig.settings ? appConfig.settings : {};

    const [isLoading] = useState(false);
    const [config, setConfig] = useState(appSettings);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [pwVisible, setPWVisible] = useState(!config.password);

    function setSettings(key, val) {
        setConfig({
            ...config,
            [key]: val,
        });
    }

    function handleSubmit() {
        updateObject({ ...app, settings: config });
    }

    function buildConfirmDialog() {
        return (
            <ExitDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                destination="/tools/maps"
            />
        );
    }

    function renderQRCode(qr, width) {
        return (
            <QRArea>
                <QRCode className="mt-3 mb-3" value={qr.content} size={width} />
                {qr.show_label && qr.label ? qr.label : null}
            </QRArea>
        );
    }

    function renderPasswordConfig() {
        const limit = 6;
        return (
            <div
                className="col-12 row mb-5"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Typography className="col-6" variant="body1">
                    Simulator Password:
                </Typography>
                <div
                    className="col-6"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <OtpInput
                        value={config.password || ''}
                        numInputs={limit}
                        separator={<span>-</span>}
                        isInputNum
                        isInputSecure={!pwVisible}
                        inputStyle={{
                            fontSize: '2.5vh',
                        }}
                        onChange={value => setSettings('password', value)}
                        hasErrored={
                            !config.password || config.password.length !== limit
                        }
                        errorStyle={{
                            border: '1px solid #f00',
                        }}
                    />
                    <IconButton
                        onClick={() => setPWVisible(!pwVisible)}
                        edge="end"
                    >
                        {pwVisible ? <MdVisibilityOff /> : <MdVisibility />}
                    </IconButton>
                </div>
            </div>
        );
    }

    return (
        <Column>
            {dialogOpen ? buildConfirmDialog() : null}
            <SettingsArea className="row col-12 mt-5 mb-5">
                <div className="col-md-8 col-12 row">
                    {isLoading ? (
                        <Splash />
                    ) : (
                        <ParameterArea className="row pt-5 px-3">
                            <div
                                className="row mb-5"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <span className="mb-3">Simulator URL:</span>
                                {renderQRCode(
                                    {
                                        content: `${BASE_APP_URL}/simulator/delivery/${app.id}`,
                                    },
                                    200
                                )}
                            </div>
                            {renderPasswordConfig()}
                        </ParameterArea>
                    )}
                </div>
                <Button
                    className="col-md-8 col-12 p-3 mt-5"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => handleSubmit()}
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </SettingsArea>
        </Column>
    );
}

Simulator.defaultProps = {};

Simulator.propTypes = {
    app: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    // handleError: PropTypes.func.isRequired,
};
