/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';

import { Item } from './styles';

const status_list = lists.rc3_status_list;

export default function RobotItem({
    robot,
    type,
    status,
    active,
    onItemClick,
    showStatus,
    size,
    fontSize,
    mode,
}) {
    const status_code = status ? status.status : null;

    const default_image =
        type && type.file && type.file.url ? type.file.url : null;
    const status_images = type && type.images ? type.images : {};

    const status_label =
        status_code &&
        status_list[status_code] &&
        status_list[status_code].label_code
            ? status_list[status_code].label_code
            : 'rc3.robot.status.undefined';

    const def_status = `${
        status_code && status_list[status_code]
            ? status_list[status_code].value
            : 'status-undefined'
    }`;
    const status_class = showStatus ? def_status : 'no-status';

    function renderImage() {
        return (
            <div className="robot-avatar">
                <img
                    src={
                        status_images[status_code]
                            ? status_images[status_code].url
                            : default_image
                    }
                    className={status_class}
                    alt={robot.name}
                />
            </div>
        );
    }

    function renderInfo() {
        return (
            <>
                <span className="name">{robot.name}</span>
                <div className="group">
                    <small>{robot.group ? robot.group.name : '---'}</small>
                    {showStatus ? (
                        <>
                            <br />
                            <small>
                                {status_code !== 'alert' ? (
                                    <LocaleMessage msg={status_label} />
                                ) : (
                                    <LocaleMessage
                                        msg={
                                            status.alert
                                                ? `rc3.alerts.${status.alert}`
                                                : status_label
                                        }
                                    />
                                )}
                            </small>
                        </>
                    ) : null}
                </div>
            </>
        );
    }

    function renderRow() {
        return (
            <div className="row">
                <div className="avatar-col col-3">{renderImage()}</div>

                <div className="robot-info col-9">{renderInfo()}</div>
            </div>
        );
    }

    function renderCentered() {
        return (
            <div className="column">
                <div className="avatar-col">{renderImage()}</div>

                <div className="robot-info">{renderInfo()}</div>
            </div>
        );
    }

    const renders = {
        item: () => renderRow(),
        center: () => renderCentered(),
    };

    const renderContent = renders[mode] || renderRow();

    return (
        <Item
            className={`${active ? 'active' : ''}`}
            onClick={event => onItemClick(event, robot)}
            size={size}
            fontSize={fontSize}
        >
            {renderContent()}
        </Item>
    );
}

RobotItem.propTypes = {
    robot: PropTypes.object,
    type: PropTypes.object,
    status: PropTypes.object,
    active: PropTypes.bool,
    showStatus: PropTypes.bool,
    onItemClick: PropTypes.func,
    size: PropTypes.string,
    fontSize: PropTypes.string,
    mode: PropTypes.string,
};

RobotItem.defaultProps = {
    robot: {},
    type: {},
    status: null,
    active: false,
    showStatus: true,
    onItemClick: () => {},
    size: '5vh',
    fontSize: '1.5vh',
    mode: 'item',
};
