import styled from 'styled-components';

export const Preview = styled.div`
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const MessagePreview = styled.div`
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 5px;
    color: #fff;
    padding: 12px;
    font-weight: ${props => {
        return props.hasTag ? 'bold' : 'regular';
    }};
`;
