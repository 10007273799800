/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import {
    Bar,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';

import { ChartCard } from './style';

export default function ReportQuantityChart({
    classes,
    title,
    color,
    data,
    dataKey,
    xAxisKey,
    customization = {},
}) {
    const { height, margins } = customization;

    return (
        <ChartCard
            className={`${classes ||
                'col-md-6 col-12 mb-3'} area-chart-container`}
        >
            <div>
                <p className="card-title">{title}</p>
            </div>
            <ResponsiveContainer
                className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                width="100%"
                height={height || 300}
            >
                <BarChart
                    data={data || []}
                    margin={margins || { top: 30, left: -20 }}
                >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey={xAxisKey} />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey={dataKey} fill={`${hexToRgba(color, 0.7)}`} />
                </BarChart>
            </ResponsiveContainer>
        </ChartCard>
    );
}

ReportQuantityChart.defaultProps = {
    data: [],
    classes: '',
    title: null,
    color: '',
    dataKey: 'value',
    xAxisKey: 'label',
    customization: {},
};

ReportQuantityChart.propTypes = {
    data: PropTypes.array,
    classes: PropTypes.string,
    title: PropTypes.element,
    color: PropTypes.string,
    dataKey: PropTypes.string,
    xAxisKey: PropTypes.string,
    customization: PropTypes.object,
};
