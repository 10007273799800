import { shade } from 'polished';
import styled from 'styled-components';

export const CentralPanel = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .panel-header {
        padding: 1vh;
        color: #ccc;
        display: flex;
        justify-content: flex-end;

        :hover {
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .fullsize {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const InfoCard = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1vh 0.5vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1.35vh;

    .clickable {
        :hover {
            cursor: pointer;
        }
    }

    .row {
        width: 100%;
    }

    .card-block {
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 0.5vh;
        margin: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
        }

        .info {
            font-size: 1.65vh;
        }

        .big-value {
            font-size: 3vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }
    }

    .card-title {
        color: #999;
        width: 100%;
        text-align: left;
        margin-bottom: 0.5vh;
    }

    .card-info {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 0.5vw;
        height: 100%;

        .info-title {
            color: #999;
            padding: 0px;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
        }

        .info-text {
            text-align: right;
        }

        .info-value {
            font-size: 1.65vh;
        }

        .big-value {
            font-size: 2.5vh;
            span {
                margin-top: 1vh;
            }
        }

        /* : {
            margin-bottom: 0.5vh;
        } */
    }
`;

export const InfoArea = styled.div`
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.35vh;

    .jr-overview-row {
        width: 100%;
    }

    .jr-description {
        width: 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-left: solid 1px #555555;
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    }

    .desc-full {
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 1vh;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;
            font-size: 1.35vh !important;
        }

        .value {
            font-size: 1.65vh;
            font-weight: bold;

            span {
                margin-top: 1vh;
            }
        }

        .big-value {
            font-size: 3vh;
            font-weight: bold;

            span {
                margin-top: 1vh;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;

export const MainInfo = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0.5vh;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .info {
        width: 100%;
        padding: 0.5vh;
        font-size: 1.5vh;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;
            font-size: 1.35vh !important;
        }

        .value {
            font-size: 3.5vh;
            font-weight: bold;
            margin-bottom: 1vh;
            margin-top: 1vh;
        }
    }
`;

export const TimeChart = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    // border-radius: 0px 0px 0.375em 0.375em !important;
    width: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.25vh;

    .chart-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-title {
        padding: 10px;
        margin: 0px;

        span {
            font-size: 1.35vh;
            color: #999;
        }
    }

    .jr-overview-row {
        width: 100%;
    }

    .jr-description {
        width: 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-left: solid 1px #555555;
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    }

    .desc-full {
        display: flex;
        flex: 1 1;
        padding: 0.5vh;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;
        font-size: 1.35vh !important;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
            font-size: 1.35vh !important;
        }

        .value {
            font-size: 1.65vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }

        .big-value {
            font-size: 2.35vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;
