/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import getDateLocale from '~/util/GetDateLocale';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Column, SettingsArea } from '../styles';
import MainParameters from './MainParameters';
import PageParameters from './PageParameters';
import Preview from './Preview';

export default function Visual({
    app,
    robot,
    setToast,
    handleError,
    updateObject,
    imageGalleriesList,
    defaultGalleries,
    needSaving,
    setNeedSaving,
}) {
    const theme = GetPluginspaceTheme(useTheme());
    const user_settings = useSelector(state => state.settings || null);

    const date_loc = getDateLocale(user_settings);
    const def_lang =
        date_loc && date_loc.code ? date_loc.code.replace('-', '_') : 'en_US';

    const { settings: appConfig } = app;
    const appSettings =
        appConfig && appConfig.settings
            ? appConfig.settings
            : { language: def_lang };

    const [config, setConfig] = useState(appSettings);

    const colors = [
        {
            id: 'top',
            default: theme.primary,
            value: theme.primary,
            title: 'page.applications.indoor_delivery.form.colors.top',
        },
        {
            id: 'bottom',
            default: '#000',
            value: '#000',
            title: 'page.applications.indoor_delivery.form.colors.bottom',
        },
        {
            id: 'buttons',
            default: theme.primary,
            value: theme.primary,
            title: 'page.applications.indoor_delivery.form.colors.buttons',
        },
    ];

    const defColors = {};
    colors.forEach(c => {
        const { id: i, default: d } = c;
        defColors[i] = d;
    });

    const defImageGalleries = defaultGalleries.images || [];
    const galleriesList = [...imageGalleriesList, ...defImageGalleries];

    const [currFile, setCurrFile] = useState(null);

    function setSettings(key, val) {
        setConfig({
            ...config,
            [key]: val,
        });
        setNeedSaving(true);
    }

    return (
        <Column>
            <SettingsArea className="row col-12 mt-5">
                <div className="col-md-6 col-12" style={{ margin: '20px' }}>
                    <MainParameters
                        colors={colors}
                        settings={config}
                        setSettings={(k, v) => setSettings(k, v)}
                    />
                    <PageParameters
                        pageKey="login_page"
                        pageTitle="page.applications.indoor_delivery.form.preview.login.title"
                        settings={config}
                        galleries={galleriesList}
                        currFile={currFile}
                        setCurrFile={f => setCurrFile(f)}
                        updateFile={f => setCurrFile(f)}
                        setToast={toast => setToast(toast)}
                        handleError={error => handleError(error)}
                        setSettings={(k, v) => setSettings(k, v)}
                        allowedSettings={{
                            logo: true,
                            image: true,
                        }}
                    />
                    <PageParameters
                        pageKey="delivery_page"
                        pageTitle="page.applications.indoor_delivery.form.preview.delivery.title"
                        settings={config}
                        galleries={galleriesList}
                        currFile={currFile}
                        setCurrFile={f => setCurrFile(f)}
                        updateFile={f => setCurrFile(f)}
                        setToast={toast => setToast(toast)}
                        handleError={error => handleError(error)}
                        setSettings={(k, v) => setSettings(k, v)}
                        allowedSettings={{
                            logo: true,
                        }}
                    />
                </div>
                <div className="col-md-5 col-12 container">
                    <LocaleMessage msg="page.applications.indoor_delivery.preview" />
                    <Preview
                        robot={robot}
                        file={currFile}
                        configuration={config}
                        defColors={defColors}
                        refreshedImage={currFile}
                    />
                </div>
                <Button
                    className="col-md-8 col-12 p-3 mt-5"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={!needSaving}
                    onClick={() => updateObject({ ...app, settings: config })}
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </SettingsArea>
        </Column>
    );
}

Visual.defaultProps = {
    imageGalleriesList: [],
    defaultGalleries: {},
    robot: {},
};

Visual.propTypes = {
    app: PropTypes.object.isRequired,
    robot: PropTypes.object,
    updateObject: PropTypes.func.isRequired,
    imageGalleriesList: PropTypes.array,
    defaultGalleries: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    needSaving: PropTypes.bool.isRequired,
    setNeedSaving: PropTypes.func.isRequired,
};
