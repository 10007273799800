import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

const useStyles = makeStyles(theme => {
    return {
        content: {
            width: '100%',
            textAlign: 'center',
            backgroundColor: '#fff',
        },
        pluginspace: {
            backgroundColor: theme.palette.primary.main,
        },
        title: {
            color: '#212529',
            fontSize: '40px',
            fontWeight: '600',
        },
    };
});

export default function UnavailablePlugin() {
    const classes = useStyles();

    return (
        <div className="app-wrapper">
            <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                <div className={`page-error-content ${classes.content}`}>
                    <div
                        className="error-code mb-4 animated zoomInDown"
                        style={{ textAlign: 'center' }}
                    >
                        <span className={`${classes.title}`}>
                            <LocaleMessage msg="message.unavailable_pluginspace.title" />
                        </span>
                    </div>
                    <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                        <span>
                            <LocaleMessage msg="message.unavailable_pluginspace.content" />
                        </span>
                    </h2>
                </div>
            </div>
        </div>
    );
}
