import { lighten } from 'polished';
import styled from 'styled-components';

export const PreviewArea = styled.div`
    border-left: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const ItemCard = styled.div`
    width: 100%;
    aspect-ratio: 9/16 auto;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: ${props => {
        const { color } = props;
        const c2 = lighten(0.2, color || '#f00');
        return `linear-gradient(45deg, ${color}, ${c2})`;
    }};

    .card-img {
        height: 80%;
        padding: 0px;
        object-fit: ${props => {
            return props.expand ? 'cover' : 'contain';
        }};
    }

    .card-txt {
        height: 20%;
        padding: 10px 5px;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;
