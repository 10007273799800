/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdLocationOn } from 'react-icons/md';

import PropTypes from 'prop-types';

import { MapArea } from './styles';

export default function SVGMap({
    illustration,
    points,
    activePoint,
    onMouseMove,
    onMouseUp,
    height,
}) {
    const svgRef = React.createRef();
    const size = {
        width: 1000,
        height: 1000,
    };
    const lineWidth = 3;
    const iconSize = 50;

    function getCoords(event) {
        const pt = svgRef.current.createSVGPoint();
        pt.x = event.clientX;
        pt.y = event.clientY;

        // The cursor point, translated into svg coordinates
        const cursorpt = pt.matrixTransform(
            svgRef.current.getScreenCTM().inverse()
        );
        return cursorpt;
    }

    function renderWall(obj) {
        const p1 = {
            x: obj.xStart,
            y: obj.yStart,
        };
        const p2 = {
            x: obj.xEnd,
            y: obj.yEnd,
        };

        return (
            <g key={`line_${obj.id}`}>
                <line
                    x1={p1.x}
                    y1={p1.y}
                    x2={p2.x}
                    y2={p2.y}
                    stroke="white"
                    strokeWidth={lineWidth}
                />
            </g>
        );
    }

    function renderPoint(p) {
        const p_mouseDown = p.onMouseDown ? p.onMouseDown : () => {};
        const p_mouseOver = p.onMouseOver ? p.onMouseOver : () => {};
        const p_mouseOut = p.onMouseOut ? p.onMouseOut : () => {};

        const p_x = p.x - iconSize / 2;
        const p_y = p.y - iconSize;

        return p_x && p_y ? (
            <g key={p.id}>
                <text
                    x={p_x}
                    y={p_y - 10}
                    fill="white"
                    dominantBaseline="middle"
                >
                    {p.label}
                </text>
                <MdLocationOn
                    x={p_x}
                    y={p_y}
                    className={`${p.id === activePoint ? 'active' : ''}`}
                    fill="white"
                    fontSize={`${iconSize}px`}
                    onMouseDown={event => p_mouseDown(event, getCoords(event))}
                    onMouseOver={event => p_mouseOver(event, getCoords(event))}
                    onMouseOut={event => p_mouseOut(event, getCoords(event))}
                />
            </g>
        ) : null;
    }

    const s_x = size.width;
    const s_y = size.height;

    return (
        <MapArea {...(height && { height })}>
            <Scrollbars autoHide>
                <svg
                    viewBox={`0 0 ${s_x} ${s_y}`}
                    ref={svgRef}
                    onMouseMove={event => onMouseMove(event, getCoords(event))}
                    onMouseUp={event => onMouseUp(event, getCoords(event))}
                >
                    {illustration.objects
                        ? illustration.objects.map(o => renderWall(o))
                        : null}
                    {points ? points.map(p => renderPoint(p)) : null}
                </svg>
            </Scrollbars>
        </MapArea>
    );
}

SVGMap.defaultProps = {
    points: [],
    onMouseMove: () => {},
    onMouseUp: () => {},
    activePoint: null,
    height: '70vh',
};

SVGMap.propTypes = {
    illustration: PropTypes.object.isRequired,
    points: PropTypes.array,
    onMouseMove: PropTypes.func,
    onMouseUp: PropTypes.func,
    activePoint: PropTypes.string,
    height: PropTypes.string,
};
