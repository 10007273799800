/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdAdd,
    MdCancel,
    MdCloudUpload,
    MdCloudDownload,
    MdBuildCircle,
    MdAltRoute,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import generateDownloadableFile from '~/util/GenerateDownloadableFile';
import getDateLocale from '~/util/GetDateLocale';
import lng_labels from '~/util/LangMessages';

const delete_key = 'message.delete_confirm';
export default function QnAVersions({
    updateDialog,
    dialogSettings,
    handleError,
    versions,
    reloadVersions,
}) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const lng_all = lng_labels[date_loc.lang];
    const delete_string = lng_all[delete_key];

    const { id, settings: d_settings } = dialogSettings;
    const dialog_versions =
        d_settings && d_settings.versions ? d_settings.versions : {};

    const [option, setOption] = useState('');
    const [body, setBody] = useState({});

    const [currItem, setCurrItem] = useState(null);
    const [showVersionDialog, setShowVersionDialog] = useState(false);
    const [showOptionDialog, setShowOptionDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [internalLoading, setInternalLoading] = useState(true);
    const [deleteConfirm, setDeleteConfirm] = useState('');

    const [templateFile, setTemplateFile] = useState({});

    async function loadVersionDetail() {
        if (currItem && currItem !== 'new') {
            setInternalLoading(true);
            await api
                .get(`dialogs/${id}/versions/${currItem}`)
                .then(response => {
                    const version = response.data;
                    setBody(version);
                })
                .catch(error => handleError(error));
        }
        setInternalLoading(false);
    }

    useEffect(() => {
        loadVersionDetail();
    }, [currItem]);

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const fileContents = e.target.result;
            const qna_file = JSON.parse(fileContents);

            setTemplateFile({
                id: fileObj.name,
                name: fileObj.name,
                qna_version: qna_file,
            });
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        reader.onload = fileloaded;
        reader.readAsText(fileObj);
    };

    async function createVersion() {
        const data = {
            ...body,
            ...(body.input_type === 'upload_file' && {
                file: templateFile,
            }),
        };

        return api
            .post(`dialogs/${id}/versions`, data)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function updateVersion() {
        const data = {
            ...body,
        };
        return api
            .put(`dialogs/${id}/versions/${currItem}`, data)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function removeVersion() {
        return api
            .delete(`dialogs/${id}/versions/${currItem}`)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function setVersion(tag) {
        const updated = {
            ...dialog_versions,
            [tag]: currItem,
        };

        updateDialog('versions', updated);
    }

    async function submitForm() {
        setIsLoading(true);
        setShowVersionDialog(false);
        let success = true;
        if (currItem === 'new') {
            success = await createVersion();
        } else {
            success = await updateVersion();
        }
        if (success) {
            toast.success('Content Updated');
        }
        setBody({});
        reloadVersions();
        setCurrItem(null);
        setIsLoading(false);
    }

    async function submitOption() {
        setIsLoading(true);
        setShowOptionDialog(false);
        setDeleteConfirm('');
        let success = true;

        switch (option) {
            case 'remove':
                success = await removeVersion();
                break;
            case 'set_release':
                success = await setVersion('release');
                break;
            case 'set_working':
                success = await setVersion('working');
                break;
            default:
                success = false;
        }
        if (success) {
            toast.success(
                <LocaleMessage msg="page.qna.content.update_success" />
            );
        }
        reloadVersions();
        setOption('');
        setCurrItem(null);
        setIsLoading(false);
    }

    async function handleExportVersion(event, _id) {
        setInternalLoading(true);
        toast.info(<LocaleMessage msg="message.generating_file" />);
        await api
            .get(`dialogs/${id}/versions/${_id}/export`)
            .then(async response => {
                const config = response.data;

                // Prepare the file
                const timestamp = new Date().toISOString();
                const filename = `PLUGINBOT_QNA-${config.name}-${timestamp}.json`;

                generateDownloadableFile(event, {
                    filename,
                    data: config,
                });
            })
            .catch(error => handleError(error));
        setInternalLoading(false);
    }

    function handleOptionClick(event, _id, opt) {
        event.preventDefault();
        setCurrItem(_id);
        setOption(opt);
    }

    function handleBranchVersionClick(event, _id) {
        setBody({
            input_type: 'clone_version',
            branch_from: _id,
        });
        setCurrItem('new');
        setShowVersionDialog(true);
    }

    function handleRemoveVersionClick(event, _id) {
        handleOptionClick(event, _id, 'remove');
        setShowOptionDialog(true);
    }

    function handleSetAsReleaseClick(event, _id) {
        handleOptionClick(event, _id, 'set_release');
        setShowOptionDialog(true);
    }

    function handleSetAsWorkingClick(event, _id) {
        handleOptionClick(event, _id, 'set_working');
        setShowOptionDialog(true);
    }

    function handleListRowClick(event, _id) {
        handleOptionClick(event, _id, 'edit');
        setShowVersionDialog(true);
    }

    function handleDialogsClose(event) {
        handleOptionClick(event, null, '');
        setShowVersionDialog(false);
        setShowOptionDialog(false);
        setDeleteConfirm('');
    }

    function renderSetDialog() {
        return (
            <DialogContentText>
                <LocaleMessage
                    msg={`page.qna.versions.${
                        option === 'set_release' ? 'release' : 'working'
                    }.confirm`}
                />
            </DialogContentText>
        );
    }

    function renderRemoveDialog() {
        const r_version =
            dialog_versions && dialog_versions.release
                ? dialog_versions.release
                : '';

        return (
            <>
                <DialogContentText>
                    <LocaleMessage
                        msg={`page.qna.versions.delete.${
                            r_version && r_version === currItem
                                ? 'release'
                                : 'confirm'
                        }`}
                    />
                </DialogContentText>
                {r_version && r_version === currItem ? null : (
                    <div className="col-12 mt-3 mb-5">
                        <TextField
                            label={
                                <>
                                    <LocaleMessage msg="message.delete.confirm" />
                                    <span>{delete_string}</span>
                                </>
                            }
                            fullWidth
                            onChange={event =>
                                setDeleteConfirm(event.target.value)
                            }
                            value={deleteConfirm}
                        />
                    </div>
                )}
            </>
        );
    }

    function renderOptionDialog() {
        const option_content = {
            remove: {
                label: 'page.qna.versions.delete.title',
                content: renderRemoveDialog,
                button: 'button.delete',
            },
            set_release: {
                label: 'page.qna.versions.release.set',
                content: renderSetDialog,
            },
            set_working: {
                label: 'page.qna.versions.working.set',
                content: renderSetDialog,
            },
        };
        const dialog_content = option_content[option];

        return (
            <SimpleDialog
                open={showOptionDialog}
                onClose={handleDialogsClose}
                title={
                    dialog_content ? (
                        <LocaleMessage msg={dialog_content.label} />
                    ) : (
                        ''
                    )
                }
                content={dialog_content ? dialog_content.content() : null}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            setShowOptionDialog(false);
                            setDeleteConfirm('');
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    ...(dialog_content
                        ? [
                              {
                                  key: 'submit',
                                  onClick: () => {
                                      submitOption();
                                  },
                                  disabled:
                                      option === 'remove' &&
                                      deleteConfirm !== delete_string,
                                  label: (
                                      <LocaleMessage
                                          msg={
                                              dialog_content.button ||
                                              'button.save'
                                          }
                                      />
                                  ),
                              },
                          ]
                        : []),
                ]}
            />
        );
    }

    function renderVersionForm() {
        return internalLoading ? (
            <Splash />
        ) : (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-6 col-12">
                    <TextField
                        className="mb-3"
                        label={
                            <LocaleMessage msg="page.qna.versions.form.name" />
                        }
                        fullWidth
                        value={body.name || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                name: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12">
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.qna.versions.form.description" />
                        }
                        fullWidth
                        value={body.description || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                description: event.target.value,
                            })
                        }
                    />
                </div>
                {currItem === 'new' ? (
                    <>
                        <div className="col-md-6 col-12">
                            <FormControl fullWidth className="mb-5">
                                <InputLabel>
                                    <LocaleMessage msg="page.qna.versions.form.input_type" />
                                </InputLabel>
                                <Select
                                    value={body.input_type || ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            input_type: event.target.value,
                                        })
                                    }
                                >
                                    <MenuItem value="no_import">
                                        <LocaleMessage msg="page.qna.versions.form.no_import" />
                                    </MenuItem>
                                    <MenuItem value="clone_version">
                                        <LocaleMessage msg="page.qna.versions.form.clone_version" />
                                    </MenuItem>
                                    <MenuItem value="upload_file">
                                        <LocaleMessage msg="page.qna.versions.form.upload_file" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {body.input_type === 'clone_version' ? (
                            <div className="col-md-6 col-12">
                                <FormControl fullWidth className="mb-3">
                                    <InputLabel>
                                        <LocaleMessage msg="page.qna.versions.form.clone_version" />
                                    </InputLabel>
                                    <Select
                                        value={body.branch_from || ''}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                branch_from: event.target.value,
                                            })
                                        }
                                    >
                                        {versions.map(v => (
                                            <MenuItem
                                                value={v.id}
                                                key={`version_${v.id}`}
                                            >
                                                {v.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : null}

                        {body.input_type === 'upload_file' ? (
                            <div
                                className="col-12"
                                style={{
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <div
                                    style={{
                                        height: 'max-content',
                                    }}
                                    className="mb-3"
                                >
                                    <FileInput
                                        defaultValue={templateFile || {}}
                                        onFileUpload={onFileUpload}
                                        multiple={false}
                                        text={
                                            <LocaleMessage msg="label.form.upload_file" />
                                        }
                                        type="json"
                                        showFooter={false}
                                        showIcon={false}
                                    />
                                </div>
                                <div className="mt-1 mb-3">
                                    <LocaleMessage msg="page.qna.versions.form.upload_file.warning" />
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }

    function renderVersionDialog() {
        return (
            <SimpleDialog
                open={showVersionDialog}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage
                        msg={`page.qna.versions.${
                            currItem === 'new' ? 'create' : 'edit'
                        }.title`}
                    />
                }
                content={renderVersionForm()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowVersionDialog(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitForm(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderListView() {
        const headCells = [
            {
                id: 'label',
                label: <LocaleMessage msg="page.qna.versions.list" />,
                order_by: 'name',
            },
            {
                id: 'description',
                label: <LocaleMessage msg="table.headers.description" />,
            },
            {
                id: 'created',
                label: <LocaleMessage msg="table.headers.created" />,
                order_by: 'created_timestamp',
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'branch',
                label: <LocaleMessage msg="page.qna.versions.branch" />,
                icon: <MdAltRoute />,
                action: handleBranchVersionClick,
            },
            {
                id: 'export',
                label: <LocaleMessage msg="page.qna.versions.download.title" />,
                icon: <MdCloudDownload />,
                action: handleExportVersion,
            },
            {
                id: 'set_release',
                label: <LocaleMessage msg="page.qna.versions.release.button" />,
                icon: <MdCloudUpload />,
                action: handleSetAsReleaseClick,
            },
            {
                id: 'set_working',
                label: <LocaleMessage msg="page.qna.versions.working.button" />,
                icon: <MdBuildCircle />,
                action: handleSetAsWorkingClick,
            },
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: handleRemoveVersionClick,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={versions}
                    orderDirection="asc"
                    orderColumn="created"
                    handleTableRowClick={(e, i) => handleListRowClick(e, i)}
                    rowActions={rowActions}
                    hasActions
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAdd />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                setBody({});
                                setCurrItem('new');
                                setShowVersionDialog(true);
                            }}
                        >
                            <LocaleMessage msg="page.qna.versions.add" />
                        </Button>
                    }
                />
            </div>
        );
    }

    return (
        <>
            <div className="col-12 row" style={{ alignItems: 'center' }}>
                <div className="sidecard-header col-9">
                    <h2>
                        <LocaleMessage msg="page.dialogs.info.versions" />
                    </h2>
                </div>
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash
                        label={
                            <div className="mb-5">
                                <LocaleMessage msg="page.qna.content.training.loading" />
                            </div>
                        }
                        labelPosition="top"
                    />
                ) : (
                    <>
                        {showVersionDialog ? renderVersionDialog() : null}
                        {showOptionDialog ? renderOptionDialog() : null}
                        {renderListView()}
                    </>
                )}
            </div>
        </>
    );
}

QnAVersions.defaultProps = {
    versions: [],
};

QnAVersions.propTypes = {
    versions: PropTypes.array,
    reloadVersions: PropTypes.func.isRequired,
    updateDialog: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
