/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import LocationZoneForm from '~/components/Locations/Zones';

import CheckGroupPermission from '~/util/CheckGroupPermission';

export default function ZoneForm({ match }) {
    const user = useSelector(state => state.user);

    const { id, location_id, zone_id } = match.params;
    const permissions = CheckGroupPermission(user || {}, id);

    const allowEdit = permissions.allow;

    return (
        <LocationZoneForm
            breadcrumbs={[
                {
                    url: '/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
                {
                    url: `/groups/${id}/locations`,
                    title: <LocaleMessage msg="breadcrumbs.locations" />,
                },
                {
                    url: `/groups/${id}/locations/${location_id}`,
                    title: (
                        <LocaleMessage msg="page.locations.zone.form.title" />
                    ),
                },
            ]}
            allowEdit={allowEdit}
            linkRoot={`/groups/${id}`}
            location_id={location_id}
            zone_id={zone_id}
        />
    );
}

ZoneForm.propTypes = {
    match: PropTypes.object.isRequired,
};
