import { enGB, enUS, ptBR } from 'date-fns/locale';

const locales = { en_GB: enGB, en_US: enUS, pt_BR: ptBR };

export default function getFormats(settings) {
    const loc = settings ? settings.locale : null;
    const date_loc = locales[loc && loc.code ? loc.code : 'en_US'];

    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    return {
        date: date_opt,
        time: time_opt,
        format: loc,
        locale: date_loc,
    };
}
