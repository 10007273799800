import { takeLatest, call, put, all } from 'redux-saga/effects';

import history from '~/services/history';
import api from '~/services/pluginbot-api';

import {
    signFailure,
    signInComplete,
    signOutSuccess,
    refreshSession,
} from './actions';

export function* refreshSessionRequest() {
    try {
        const response = yield call(api.post, `auth/pluginspace/refresh`);
        const { profile } = response.data;
        yield put(refreshSession(profile));
    } catch (err) {
        console.error(err);
    }
}

export function* signInSuccess({ payload }) {
    const { token } = payload;

    try {
        api.defaults.headers.group = '';
        if (token) {
            api.defaults.headers.Authorization = `Bearer ${token}`;
            yield put(signInComplete());
        } else {
            yield put(signFailure());
        }
    } catch (err) {
        yield put(signFailure());
    }
}

export function setToken({ payload }) {
    if (!payload) return;

    const { token } = payload.auth;

    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
    }
}

export function* signOutRequest() {
    try {
        yield call(api.post, `auth/signout`);
    } catch (err) {
        console.error(err);
    }
    yield put(signOutSuccess());
}

export function signOut() {
    history.push('/login');
}

export default all([
    takeLatest('persist/REHYDRATE', setToken),
    takeLatest('@auth/SIGN_IN_SUCCESS', signInSuccess),
    takeLatest('@auth/SIGN_OUT_REQUEST', signOutRequest),
    takeLatest('@auth/SIGN_OUT', signOut),
    takeLatest('@auth/SESSION_REFRESH_REQUEST', refreshSessionRequest),
]);
