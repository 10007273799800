/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../styles';

const parameters = {
    map_point: ['end_conversation', 'speak_message'],
    script: ['end_conversation', 'return_conversation'],
    contact: [],
};

export default function OnFinishActionSetup({
    settings,
    onChange,
    languages,
    actionType,
}) {
    const action_settings = settings.data || {};

    const exit_actions = {
        end_conversation: {
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.actions.end_conversation" />
            ),
            defaults: {},
        },
        speak_message: {
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.actions.speak_message" />
            ),
            defaults: {},
        },
        return_conversation: {
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.actions.go_conversation" />
            ),
            defaults: {
                send_message: false,
                language: languages.main || 'pt_BR',
            },
        },
    };

    function handleUpdateActionData(key, value) {
        onChange({
            ...settings,
            data: {
                ...action_settings,
                [key]: value,
            },
        });
    }

    function handleActionSelection(event) {
        const action = event.target.value;
        const a_settings = exit_actions[action] || {};

        const a_defaults = a_settings.defaults || {};

        onChange({
            ...settings,
            action,
            data: {
                ...a_defaults,
            },
        });
    }

    function buildLanguageInput(lang) {
        const messages =
            action_settings && action_settings.messages
                ? action_settings.messages
                : {};
        return (
            <div className="col-12 row mb-3" key={`starter_${lang}`}>
                <TextField
                    id={`${lang}_starter`}
                    label={<LocaleMessage msg={`list.languages.${lang}`} />}
                    fullWidth
                    value={messages[lang] || ''}
                    onChange={event =>
                        handleUpdateActionData('messages', {
                            ...messages,
                            [lang]: event.target.value,
                        })
                    }
                />
            </div>
        );
    }

    function renderSpeakMessageSettings() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <ParameterArea className="col-12 mb-5 p-4">
                    {languages.list.map(l => {
                        return buildLanguageInput(l);
                    })}
                </ParameterArea>
            </div>
        );
    }

    function renderReturnConversationSettings() {
        return (
            <>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={action_settings.send_message || false}
                        onChange={event =>
                            handleUpdateActionData(
                                'send_message',
                                event.target.checked
                            )
                        }
                        label={
                            <LocaleMessage msg="page.applications.conversation.script_mode.identifier.exit.label.send_message" />
                        }
                        helper={
                            <LocaleMessage msg="page.applications.conversation.script_mode.identifier.exit.label.send_message.helper" />
                        }
                    />
                </div>
                {/* {action_settings.send_message ? (
                    <div className="col-12 row">
                        <div className="col-md-3 col-12 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.conversation.script_mode.identifier.exit.label.message_lang" />
                                </InputLabel>
                                <Select
                                    value={action_settings.language || ''}
                                    onChange={event =>
                                        handleUpdateActionData(
                                            'language',
                                            event.target.value
                                        )
                                    }
                                >
                                    {languages && languages.list
                                        ? languages.list.map(l => {
                                              return (
                                                  <MenuItem value={l} key={l}>
                                                      <LocaleMessage
                                                          msg={`list.languages.${l}`}
                                                      />
                                                  </MenuItem>
                                              );
                                          })
                                        : null}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-9 col-12 mb-5">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.applications.conversation.script_mode.identifier.exit.label.message_content" />
                                }
                                fullWidth
                                value={action_settings.message || ''}
                                onChange={event =>
                                    handleUpdateActionData(
                                        'message',
                                        event.target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                ) : null} */}
                {action_settings.send_message ? (
                    <>{renderSpeakMessageSettings()}</>
                ) : null}
            </>
        );
    }

    function renderActionSettings() {
        const action = settings && settings.action ? settings.action : '';
        const settings_renderer = {
            end_conversation: null,
            speak_message: renderSpeakMessageSettings(),
            return_conversation: renderReturnConversationSettings(),
        };

        return settings_renderer[action] || null;
    }

    return (
        <div className="row">
            <ParameterArea className="col-12 pt-5 mt-3 mb-3">
                <div className="col-12">
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.on_finish.label" />
                            </InputLabel>
                            <Select
                                value={
                                    settings.action
                                        ? settings.action
                                        : 'end_conversation'
                                }
                                onChange={event => handleActionSelection(event)}
                            >
                                {Object.keys(exit_actions).map(m => {
                                    const action = exit_actions[m];
                                    return (
                                        parameters[actionType] || []
                                    ).includes(m) ? (
                                        <MenuItem value={m} key={m}>
                                            {action.label}
                                        </MenuItem>
                                    ) : null;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-12">{renderActionSettings()}</div>
            </ParameterArea>
        </div>
    );
}

OnFinishActionSetup.defaultProps = {
    actionType: '',
    languages: {},
};

OnFinishActionSetup.propTypes = {
    actionType: PropTypes.string,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    languages: PropTypes.object,
};
