/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { subHours, getHours } from 'date-fns';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { TimeChart } from '../../../RobotPage/RobotData/styles';

const h100 = { height: '100%' };
const margins = {
    top: 10,
    right: 20,
    left: -10,
    bottom: 10,
};

export default function InteractionsCard({
    width,
    data,
    colors,
    windowSize,
    locale,
}) {
    const w_height = windowSize.height || 1080;
    const box_height = parseInt(w_height * 0.25, 10);
    const color = lighten(0.1, colors.primary);

    const [interactionData, setInteractionData] = useState({});

    const card_data = interactionData ? interactionData.list : [];
    const card_labels = interactionData ? interactionData.labels : [];

    function filterInteractions(_ints) {
        if (!_ints) {
            setInteractionData({});
            return;
        }
        const int_data = [];
        const labels = [];

        const { list, period } = _ints;

        if (!period || !list) {
            setInteractionData({});
            return;
        }

        const end = new Date(period.end);

        const grouped_data = {};
        list.forEach(m => {
            const formatted_date = getHours(new Date(m.date));
            if (grouped_data[formatted_date]) {
                grouped_data[formatted_date] += 1;
            } else {
                grouped_data[formatted_date] = 1;
            }
        });

        for (let t = 23; t >= 0; t--) {
            const period_time = subHours(end, t);
            const p_hours = getHours(period_time);

            const label_time = `${p_hours}h`;
            labels.push(label_time);
            int_data.push({
                time: label_time,
                value: grouped_data[p_hours] || 0,
            });
        }

        setInteractionData({
            list: int_data,
            labels,
        });
    }

    useEffect(() => {
        filterInteractions(data);
    }, [data, locale]);

    return (
        <div className={`${width} mb-3`} key="card_interactions">
            <Box style={h100}>
                <TimeChart className="jr-overview-row">
                    <div className="chart-area">
                        <div className="card-title">
                            <span>
                                <LocaleMessage msg="rc3.robot.data.interactions.title" />
                            </span>
                        </div>
                        <div className="area-chart-container">
                            <ResponsiveContainer
                                className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                                width="100%"
                                height={box_height}
                            >
                                <BarChart
                                    data={card_data}
                                    labels={card_labels}
                                    margin={margins}
                                >
                                    <CartesianGrid
                                        stroke="#464A4F"
                                        vertical={false}
                                    />
                                    <XAxis dataKey="time" axisLine={false} />
                                    <YAxis allowDecimals={false} />
                                    <Tooltip />
                                    <Bar dataKey="value" fill={color} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </TimeChart>
            </Box>
        </div>
    );
}

InteractionsCard.propTypes = {
    width: PropTypes.string,
    data: PropTypes.object,
    colors: PropTypes.object,
    locale: PropTypes.object.isRequired,
    windowSize: PropTypes.object.isRequired,
};

InteractionsCard.defaultProps = {
    width: 'col-12',
    data: {},
    colors: {},
};
