import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px 10px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
        margin: 10px 0px;
    }
`;

export const DeleteParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }
`;
