import styled from 'styled-components';

export const Content = styled.div`
    padding: 0.75vh;
    color: #fff;
    font-size: 1.4vh;

    .item-header {
    }

    .title {
        font-size: 1.45vh;
        font-weight: bold;
        margin-bottom: 0.75vh;
    }

    .time {
        font-size: 1.2vh;
        color: #999;
    }

    .content {
        margin-top: 0.5vh;
    }
`;
