import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px;
    width: 100%;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const MediaSelector = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MediaArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        aspect-ratio: 16 / 9;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .audio-card {
        padding: 0px 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #000;

        p {
            color: #fff;
            margin: 2px;
        }

        audio {
            width: 100%;
        }
    }
`;
