/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdBatteryChargingFull,
    MdWifi,
    MdVideoLibrary,
    MdPlace,
    MdClose,
    MdMyLocation,
} from 'react-icons/md';
import ReactLoading from 'react-loading';

import { differenceInSeconds } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';
import RC3Cards from '~/config/RC3Cards';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import api from '~/services/pluginbot-api';
import GetOperationTime from '~/util/GetOperationTime';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import ActionsCard from '../../Components/Cards/ActionsCard';
import ActiveCard from '../../Components/Cards/ActiveCard';
import ControlCard from '../../Components/Cards/ControlCard';
import ConversationActionCard from '../../Components/Cards/ConversationActionCard';
import HardwareCard from '../../Components/Cards/HardwareCard';
import InfoGridCard from '../../Components/Cards/InfoGridCard';
import InteractionsCard from '../../Components/Cards/InteractionsCard';
import LevelsCard from '../../Components/Cards/LevelsCard';
import OperationCard from '../../Components/Cards/OperationCard';
import PresentationCard from '../../Components/Cards/PresentationCard';
import RobotCard from '../../Components/Cards/RobotCard';
import StatusCard from '../../Components/Cards/StatusCard';
import { connectCall } from '../../lib/ExtFunctions';
import { DarkDialog } from '../../styles';
import { CentralPanel, Container, InfoRow } from './styles';

const status_list = lists.rc3_status_list;
const wifi_signal = lists.rc3_wifi_sign;

const date_opt = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};
const time_opt = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
};

const cardtime_label = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
};

const { robot_cards } = RC3Cards;

const update_time = 15 * 1000;

export default function RobotData({
    user,
    settings,
    groups,
    robot,
    robotType,
    applications,
    locations,
    zones,
    clearSelection,
    status,
    requestError,
    sendAction,
    lastNotification,
    smallScreen,
    eventsOpen,
}) {
    const colors = GetPluginspaceTheme(useTheme());
    const loc = settings.locale;

    const status_code = status ? status.status : null;
    const robot_type = robotType && robotType.code ? robotType.code : null;
    const card_settings = robot_cards[robot_type] || robot_cards.default;
    const default_image =
        robotType && robotType.file && robotType.file.url
            ? robotType.file.url
            : null;
    const status_images = robotType && robotType.images ? robotType.images : {};

    const status_label =
        status_code &&
        status_list[status_code] &&
        status_list[status_code].label_code
            ? status_list[status_code].label_code
            : 'rc3.robot.status.undefined';

    const windowSize = useWindowDimensions();
    const [application, setApplication] = useState(null);
    const [location, setLocation] = useState(null);
    const [zone, setZone] = useState(null);
    const [groupPermission, setGroupPermission] = useState(null);

    const [interactions, setInteractions] = useState(null);
    const [operationData, setOperationData] = useState({});
    const [openActionParams, setOpenActionParams] = useState(false);
    const [actionParams, setActionParams] = useState(false);
    const [allowAction, setAllowAction] = useState(true);
    const [now, setNow] = useState({});

    function getNow() {
        const datetime = new Date();
        const date = datetime.toLocaleDateString(loc.format, date_opt);
        const time = datetime.toLocaleTimeString([], time_opt);
        setNow({
            datetime,
            date: date.toUpperCase(),
            time,
        });
    }

    function getTime(t) {
        const datetime = t ? new Date(t) : null;
        const date = datetime
            ? datetime.toLocaleDateString(loc.format, date_opt)
            : '---';
        const time = datetime
            ? datetime.toLocaleTimeString([], time_opt)
            : '---';
        return {
            date: date.toUpperCase(),
            time,
        };
    }

    function parseTime(time, scale, format = '0') {
        switch (scale) {
            case 'hours': {
                const t_val = time / (60 * 60);
                return {
                    hours: t_val,
                    text: numeral(t_val).format(format),
                };
            }
            case 'minutes': {
                const t_val = time / 60;
                return {
                    minutes: t_val,
                    text: numeral(t_val).format(format),
                };
            }
            case 'hours_minutes': {
                const m_val = Math.floor(time / 60);

                const t_hours = Math.floor(m_val / 60);
                const t_minutes = t_hours > 0 ? m_val % 60 : m_val;

                return {
                    hours: t_hours,
                    minutes: t_minutes,
                    text: `${numeral(t_hours).format('0')}:${numeral(
                        t_minutes
                    ).format('00')}`,
                };
            }
            case 'full': {
                const m_val = Math.floor(time / 60);

                const t_hours = Math.floor(m_val / 60);
                const t_minutes = t_hours > 0 ? m_val % 60 : m_val;
                const t_seconds = time % 60;

                return {
                    hours: t_hours,
                    minutes: t_minutes,
                    seconds: t_seconds,
                    text: `${numeral(t_hours).format('0')}:${numeral(
                        t_minutes
                    ).format('00')}:${numeral(t_seconds).format('00')}`,
                };
            }
            default:
                return {};
        }
    }

    function getOperationTime(time) {
        const active = time && time.active ? time.active : 0;
        const occupied = time && time.occupied ? time.occupied : 0;

        const active_time = parseTime(active, 'full');
        const occupied_time = parseTime(occupied, 'full');

        const rate = active ? numeral(occupied / active) : numeral(0);

        return {
            active: {
                hours: active_time.hours,
                minutes: numeral(active_time.minutes).format('00'),
                text: active_time.text,
            },
            occupied: {
                hours: occupied_time.hours,
                minutes: numeral(occupied_time.minutes).format('00'),
                text: occupied_time.text,
            },
            rate: rate.format('0.00%'),
        };
    }

    async function loadOperations() {
        if (robot && robot.id) {
            await api
                .get(`rc3/operations/${robot.id}`)
                .then(response => {
                    const { data } = response;
                    setOperationData(data);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadInteractions() {
        if (robot && robot.id) {
            await api
                .get(`rc3/interactions/${robot.id}`)
                .then(response => {
                    const { data } = response;
                    setInteractions(data);
                })
                .catch(error => requestError(error));
        }
    }

    function handleNotificationMessage(notification) {
        const { sender, type } = notification;
        if (sender === 'robot') {
            switch (type) {
                case 'ready':
                    return setAllowAction(true);
                default:
                    return false;
            }
        }
        return false;
    }

    function getGroupPermission() {
        if (!robot || !groups) {
            return setGroupPermission('viewer');
        }
        const r_group_id =
            robot.group && robot.group.id ? robot.group.id : null;
        const r_group = groups.find(g => {
            return g.id === r_group_id;
        });

        if (!r_group) {
            return setGroupPermission('viewer');
        }

        const permission = r_group.permission || 'viewer';

        return setGroupPermission(permission);
    }

    function handleDialogClose() {
        setOpenActionParams(false);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getNow();
        }, 1 * 1000);
        return () => clearInterval(interval);
    }, [loc]);

    useEffect(() => {
        const robot_app =
            status && status.application ? status.application.id : null;
        if (robot_app && applications) {
            if (!application || application.id !== robot_app) {
                const app = applications.find(a => {
                    return a.id === robot_app;
                });
                setApplication(app);
            }
        } else {
            setApplication(null);
        }
    }, [applications, status]);

    useEffect(() => {
        const robot_loc = status && status.location ? status.location.id : null;
        if (robot_loc && locations) {
            if (!location || location.id !== robot_loc) {
                const r_loc = locations.find(l => {
                    return l.id === robot_loc;
                });
                setLocation(r_loc);
            }
        } else {
            setLocation(null);
        }
    }, [locations, status]);

    useEffect(() => {
        const robot_zone = status && status.zone ? status.zone.id : null;
        if (robot_zone && zones) {
            if (!zone || zone.id !== robot_zone) {
                const r_zone = zones.find(z => {
                    return z.id === robot_zone;
                });
                setZone(r_zone);
            }
        } else {
            setZone(null);
        }
    }, [zones, status]);

    useEffect(() => {
        loadOperations();
        loadInteractions();
        const s = status.status;
        if (s !== 'disconnected') {
            const interval = setInterval(() => {
                loadInteractions();
            }, update_time);
            return () => clearInterval(interval);
        }
        return () => {};
    }, [robot, status]);

    useEffect(() => {
        getGroupPermission();
    }, [robot, groups]);

    useEffect(() => {
        setAllowAction(true);
    }, [robot]);

    useEffect(() => {
        if (!allowAction) {
            setTimeout(() => setAllowAction(true), 20 * 1000);
        }
    }, [allowAction]);

    useEffect(() => {
        if (lastNotification.type) {
            handleNotificationMessage(lastNotification);
        }
    }, [lastNotification]);

    function actionClick(params) {
        const { action, data } = params;
        if (!action) return false;
        setAllowAction(false);
        return sendAction({ action, robot, body: data });
    }

    function actionParamClick(action, data) {
        setOpenActionParams(false);
        setActionParams({});
        return actionClick({ action, data });
    }

    function openActionClick(params) {
        setOpenActionParams(true);
        setActionParams(params);
    }

    const functions = {
        sendAction: params => actionClick(params),
        openAction: params => openActionClick(params),
        connectCall: params => {
            setAllowAction(false);
            connectCall({ ...params, robot }, requestError);
        },
    };

    function renderActionParams() {
        const { action, options } = actionParams;

        return (
            <Dialog
                open={openActionParams}
                onClose={handleDialogClose}
                maxWidth="sm"
            >
                <DarkDialog>
                    <DialogTitle>
                        <LocaleMessage msg={actionParams.label} />
                    </DialogTitle>
                    <DialogContent>
                        {options.map(option => {
                            const { values, key: o_key } = option;
                            return (
                                <div
                                    className="row"
                                    key={`action_option_${o_key}`}
                                >
                                    {values.map(v => {
                                        return (
                                            <Button
                                                key={`option_${o_key}_${v.value}`}
                                                className="mb-3 col-12"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() =>
                                                    actionParamClick(action, {
                                                        [o_key]: v.value,
                                                    })
                                                }
                                            >
                                                <LocaleMessage msg={v.label} />
                                            </Button>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.close" />
                        </Button>
                    </DialogActions>
                </DarkDialog>
            </Dialog>
        );
    }

    function runFunction({ function: f, params }) {
        if (!f) return false;
        const func = functions[f];
        if (!func) return false;
        return func(params);
    }

    function renderLoading() {
        return (
            <ReactLoading
                type="bubbles"
                color="#c8c8c8"
                height={50}
                width={50}
            />
        );
    }

    function renderRobotCard(w) {
        const status_value =
            status_code && status_list[status_code]
                ? status_list[status_code].value
                : 'status-undefined';
        const image = status_images[status_code]
            ? status_images[status_code].url
            : default_image;

        return (
            <RobotCard
                key="card_robot"
                width={w}
                image={image}
                status={{ ...status, value: status_value, label: status_label }}
                robot={robot}
                format={{
                    format: loc.format,
                    label: { ...cardtime_label, second: '2-digit' },
                }}
            />
        );
    }

    function renderStatusCard(width, { Icon, key, title, body, infoClass }) {
        return (
            <StatusCard
                key={`card_${key}`}
                Icon={Icon}
                width={width}
                title={title}
                body={body}
                infoClass={infoClass}
            />
        );
    }

    function renderApplicationCard(w) {
        const { application: status_app } = status;

        // Fail values will be removed in the near future
        const fail_name = application ? application.name : '---';
        const fail_type =
            application && application.type ? (
                <LocaleMessage msg={`apps.${application.type.code}`} />
            ) : (
                '---'
            );

        const app_name =
            status_app && status_app.name ? status_app.name : fail_name;
        const app_version =
            status_app && status_app.version ? ` v${status_app.version}` : '';
        const app_type =
            status_app && status_app.type ? (
                <>
                    <LocaleMessage msg={`apps.${status_app.type}`} />
                    {app_version}
                </>
            ) : (
                fail_type
            );

        return renderStatusCard(w, {
            Icon: MdVideoLibrary,
            key: 'application',
            title: <LocaleMessage msg="rc3.robot.data.card.application" />,
            body: [
                {
                    label: 'rc3.robot.data.card.application.type',
                    value: app_type,
                },
                {
                    label: 'rc3.robot.data.card.application.name',
                    value: app_name || '---',
                },
            ],
            infoClass: 'info-value',
        });
    }

    function renderBatteryCard(w, options = { charging: false }) {
        const { battery, status: curr_status } = status;

        const level =
            battery &&
            battery.level &&
            battery.level != null &&
            battery.level !== 'None'
                ? battery.level
                : null;
        const scale = battery && battery.scale ? battery.scale : '%';
        const isCharging =
            battery && battery.charging ? battery.charging : false;
        const { charging: showCharging } = options;
        const value =
            curr_status !== 'disconnected' &&
            level &&
            level >= 0 &&
            level <= 100
                ? `${level} ${scale}`
                : '---';

        const values = [
            {
                label: 'rc3.robot.data.card.battery.level',
                value,
            },
        ];

        if (isCharging && showCharging) {
            values.push({
                label: 'rc3.robot.data.card.battery.charging',
                value: isCharging,
            });
        }

        return renderStatusCard(w, {
            Icon: MdBatteryChargingFull,
            key: 'battery',
            title: <LocaleMessage msg="rc3.robot.data.card.battery" />,
            body: values,
            infoClass: 'big-value',
        });
    }

    function renderWifiCard(w) {
        const { wifi } = status;
        const wifi_code = wifi ? wifi.level : null;
        const wifi_label =
            wifi_code &&
            wifi_signal[wifi_code] &&
            wifi_signal[wifi_code].label_code
                ? wifi_signal[wifi_code].label_code
                : 'rc3.robot.status.wifi.signal.unknown';

        return renderStatusCard(w, {
            Icon: MdWifi,
            key: 'wifi',
            title: <LocaleMessage msg="rc3.robot.data.card.wifi" />,
            body: [
                {
                    label: 'rc3.robot.data.card.wifi.ssid',
                    value:
                        wifi && wifi.ssid
                            ? `${wifi.ssid}${wifi.ip ? ` (${wifi.ip})` : ''}`
                            : '---',
                },
                {
                    label: 'rc3.robot.data.card.wifi.signal',
                    value: <LocaleMessage msg={wifi_label} />,
                },
            ],
            infoClass: 'info-value',
        });
    }

    function renderLocationCard(w) {
        return renderStatusCard(w, {
            Icon: MdPlace,
            key: 'location',
            title: <LocaleMessage msg="rc3.robot.data.card.location" />,
            body: [
                {
                    label: 'rc3.robot.data.card.location.name',
                    value: location ? location.name : '---',
                },
                {
                    label: 'rc3.robot.data.card.location.zone',
                    value: zone ? zone.name : '---',
                },
            ],
            infoClass: 'info-value',
        });
    }

    function renderMapCard(w) {
        const { map_point } = status;

        return renderStatusCard(w, {
            Icon: MdMyLocation,
            key: 'map',
            title: <LocaleMessage msg="rc3.robot.data.card.map" />,
            body: [
                {
                    label: 'rc3.robot.data.card.map_point.name',
                    value: map_point ? map_point.name : '---',
                },
            ],
            infoClass: 'info-value',
        });
    }

    function renderActionsCard(w, options = {}, r_state) {
        return (
            <ActionsCard
                key="card_robot_actions"
                width={w}
                options={options}
                permission={groupPermission}
                robot={r_state || {}}
                runFunction={runFunction}
                renderLoading={renderLoading}
                allowAction={allowAction}
            />
        );
    }

    function renderInteractionsBox(w) {
        return (
            <InteractionsCard
                key="card_interactions"
                width={w}
                colors={colors}
                windowSize={windowSize}
                locale={loc}
                data={interactions}
            />
        );
    }

    function renderTelepresenceCard(w, options = {}, r_state) {
        const r_status = r_state.status || {};
        const { telepresence, status: robot_status } = r_status;

        const op_since =
            telepresence && telepresence.since
                ? new Date(telepresence.since)
                : null;
        const op_time_s = differenceInSeconds(now.datetime, op_since);
        const op_time = op_time_s ? GetOperationTime(op_time_s) : null;
        const op_user =
            telepresence && telepresence.user ? telepresence.user : '';

        const card_header = (
            <div className="mb-3 desc-full">
                {robot_status && robot_status === 'occupied' ? (
                    <>
                        <span className="jr-fs-md title">
                            <LocaleMessage msg="rc3.robot.data.telepresence.active_time" />
                        </span>
                        <div className="info">
                            <span className="big-value">
                                {op_time ? op_time.label : '-- : -- : --'}
                            </span>
                            <span className="jr-fs-md title">
                                {op_user || '---'}
                            </span>
                        </div>
                    </>
                ) : (
                    <span className="big-value">
                        <LocaleMessage msg="rc3.robot.data.telepresence.free" />
                    </span>
                )}
            </div>
        );

        return (
            <ActionsCard
                key="card_telepresence_actions"
                width={w}
                options={options}
                permission={groupPermission}
                robot={r_state || {}}
                runFunction={runFunction}
                renderLoading={renderLoading}
                allowAction={allowAction}
                header={card_header}
            />
        );
    }

    function renderActiveCard(w, options = { occupation: true }, r_state) {
        const op_session =
            operationData && operationData.session ? operationData.session : {};
        const op_start = op_session.start;
        const op_time = operationData.time || {};

        const op_status =
            r_state && r_state.status && r_state.status.status
                ? r_state.status.status
                : 'disconnected';
        if (op_status !== 'disconnected') {
            const op_active = differenceInSeconds(
                now.datetime,
                new Date(op_start)
            );
            op_time.active = op_active;
        }

        const time = getOperationTime(op_time);

        return (
            <ActiveCard
                smallScreen={smallScreen}
                key="card_active"
                width={w}
                options={options}
                robot={r_state || {}}
                data={{
                    status: op_status,
                    time,
                    start: getTime(op_start),
                }}
                parseTime={(value, scale, format) =>
                    parseTime(value, scale, format)
                }
            />
        );
    }

    function renderOperationCard(w, options, r_state) {
        return (
            <OperationCard
                key={`card_operation_${options.key}`}
                width={w}
                options={options}
                robot={r_state || {}}
            />
        );
    }

    function renderHardwareUsageCard(
        w,
        options = { key: 'total', style: 'bar', size: 'col-12' },
        r_state
    ) {
        return (
            <HardwareCard
                key={`card_hardware_${options.key}`}
                width={w}
                options={options}
                robot={r_state || {}}
                robotType={robotType}
                parseTime={(value, scale, format) =>
                    parseTime(value, scale, format)
                }
            />
        );
    }

    function renderLevelIndicatorCard(w, options, r_state) {
        return (
            <LevelsCard
                key={`card_level_${options.key}`}
                width={w}
                options={options}
                robot={r_state || {}}
            />
        );
    }

    function renderInfoGridCard(w, options, r_state) {
        return (
            <InfoGridCard
                key={`card_level_${options.key}`}
                width={w}
                options={options}
                status={r_state.status || {}}
                format={{
                    format: loc.format,
                    label: cardtime_label,
                }}
            />
        );
    }

    function renderPresentationInputCard(w, options, r_state) {
        const { application: curr_app, operation } = status;
        const presentation_mode_on =
            operation && operation.mode && operation.mode === 'presentation';
        return presentation_mode_on ? (
            <PresentationCard
                permission={groupPermission}
                key={`card_presentation_${options.key}`}
                width={w}
                user={user}
                options={options}
                robot={r_state || {}}
                robotType={robot_type}
                application={curr_app || {}}
                renderLoading={renderLoading}
                sendAction={a => actionClick(a)}
            />
        ) : null;
    }

    function renderConversationActionCard(w, options, r_state) {
        return (
            <ConversationActionCard
                permission={groupPermission}
                key={`card_conversation_action_${options.key}`}
                now={now}
                width={w}
                options={options}
                robot={r_state || {}}
                renderLoading={renderLoading}
                sendAction={a => actionClick(a)}
            />
        );
    }

    function renderControlCard(w, options = {}, r_state) {
        return (
            <ControlCard
                key="card_robot_control"
                width={w}
                options={options}
                allowAction={allowAction}
                permission={groupPermission}
                robot={robot}
                status={r_state}
                runFunction={runFunction}
                renderLoading={renderLoading}
            />
        );
    }

    const cards = {
        robot: (w, o) => renderRobotCard(w, o),
        application: (w, o) => renderApplicationCard(w, o),
        battery: (w, o) => renderBatteryCard(w, o),
        wifi: (w, o) => renderWifiCard(w, o),
        location: (w, o) => renderLocationCard(w, o),
        map: (w, o) => renderMapCard(w, o),
        actions: (w, o, r) => renderActionsCard(w, o, r),
        telepresence: (w, o, r) => renderTelepresenceCard(w, o, r),
        interactions: (w, o, r) => renderInteractionsBox(w, o, r),
        operation: (w, o, r) => renderOperationCard(w, o, r),
        active: (w, o, r) => renderActiveCard(w, o, r),
        hardware_usage: (w, o, r) => renderHardwareUsageCard(w, o, r),
        level_indicator: (w, o, r) => renderLevelIndicatorCard(w, o, r),
        info_grid: (w, o, r) => renderInfoGridCard(w, o, r),
        presentation: (w, o, r) => renderPresentationInputCard(w, o, r),
        conversation_action: (w, o, r) => renderConversationActionCard(w, o, r),
        control: (w, o, r) => renderControlCard(w, o, r),
    };

    function renderRowObject(item) {
        const { card: card_type, width, options } = item;
        const state = robot && robot.state ? robot.state : {};

        const cardFunction =
            cards && cards[card_type] ? cards[card_type] : null;

        if (cardFunction) {
            return cardFunction(width, options, { status, state });
        }
        return null;
    }

    return (
        <CentralPanel
            smallScreen={smallScreen}
            open={!(smallScreen && eventsOpen)}
        >
            {robot ? (
                <>
                    {openActionParams ? renderActionParams() : null}
                    <div className="panel-header">
                        <MdClose size={20} onClick={() => clearSelection()} />
                    </div>
                    {/* <CustomScrollbars style={{ height: '100%' }}> */}
                    <Container smallScreen={smallScreen}>
                        {card_settings.map((r, idx) => {
                            return (
                                <InfoRow className="row" key={`row_${idx}`}>
                                    {r.map(c => {
                                        return renderRowObject(c);
                                    })}
                                </InfoRow>
                            );
                        })}
                    </Container>
                    {/* </CustomScrollbars> */}
                </>
            ) : null}
        </CentralPanel>
    );
}

RobotData.propTypes = {
    eventsOpen: PropTypes.bool,
    smallScreen: PropTypes.bool,
    user: PropTypes.object,
    settings: PropTypes.object,
    robot: PropTypes.object,
    robotType: PropTypes.object,
    applications: PropTypes.array,
    locations: PropTypes.array,
    zones: PropTypes.array,
    groups: PropTypes.array,
    status: PropTypes.object,
    clearSelection: PropTypes.func,
    requestError: PropTypes.func.isRequired,
    sendAction: PropTypes.func.isRequired,
    lastNotification: PropTypes.object,
};

RobotData.defaultProps = {
    eventsOpen: false,
    smallScreen: false,
    user: {},
    settings: {},
    robot: {},
    robotType: {},
    applications: [],
    locations: [],
    zones: [],
    groups: [],
    status: {},
    lastNotification: {},
    clearSelection: () => {},
};
