/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import api from '~/services/pluginbot-api';

import { AppRow } from '../styles';
import RobotData from './RobotData';
import RobotList from './RobotList';
import RobotTimeline from './RobotTimeline';

const update_time = 30 * 1000;

export default function RobotPage({
    settings,
    user,
    robots,
    robotTypes,
    applications,
    locations,
    zones,
    groups,
    robotStatus,
    requestError,
    lastMessage,
    lastNotification,
    sendAction,
    sendNotification,
    smallScreen,
}) {
    const date_format = settings.locale;

    const [currRobotId, setCurrRobotId] = useQueryState('robot', '');
    const [currRobotObj, setCurrRobotObj] = useState(robots[0] || null);
    const [currRobotType, setCurrRobotType] = useState({});

    const [loadingTimeline, setLoadingTimeline] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [moreEvents, setMoreEvents] = useState(true);
    const [showList, setShowList] = useState(true);
    const [eventsOpen, setEventsOpen] = useState(true);

    async function loadTimeline(operation = 'list') {
        if (currRobotId) {
            if (operation === 'list' || !timeline[0]) {
                setLoadingTimeline(true);
                await api
                    .get(`rc3/events/${currRobotId}`)
                    .then(response => {
                        const { data } = response;
                        if (!data || !data.success) {
                            return;
                        }
                        const { list } = data;
                        setTimeline(list);
                        if (list.length === 0) {
                            setMoreEvents(false);
                        } else {
                            setMoreEvents(true);
                        }
                    })
                    .catch(error => requestError(error));
                setLoadingTimeline(false);
            } else if (operation === 'update') {
                setLoadingTimeline(true);
                const newestElement =
                    timeline && timeline[0] ? timeline[0] : null;
                const from = newestElement ? newestElement.timestamp : null;
                if (from) {
                    const url = `rc3/events/${currRobotId}?from=${from}`;
                    await api
                        .get(url)
                        .then(response => {
                            const { data } = response;
                            if (!data || !data.success) {
                                return;
                            }
                            const { list, robot_id } = data;
                            if (currRobotId === robot_id) {
                                setTimeline([...list, ...timeline]);
                            }
                        })
                        .catch(error => requestError(error));
                }
                setLoadingTimeline(false);
            } else if (operation === 'older') {
                setLoadingTimeline(true);
                const oldestElement = timeline.slice(-1)[0];
                const until =
                    oldestElement && oldestElement.timestamp
                        ? oldestElement.timestamp
                        : null;
                if (until) {
                    const url = `rc3/events/${currRobotId}?until=${until}`;
                    await api
                        .get(url)
                        .then(response => {
                            const { data } = response;
                            if (!data || !data.success) {
                                return;
                            }
                            const { list, robot_id } = data;
                            if (currRobotId === robot_id) {
                                if (list.length === 0) {
                                    setMoreEvents(false);
                                } else {
                                    setMoreEvents(true);
                                }
                                setTimeline([...timeline, ...list]);
                            }
                        })
                        .catch(error => requestError(error));
                }
                setLoadingTimeline(false);
            }
        } else {
            setTimeline([]);
            setMoreEvents(true);
        }
    }

    function handleEventsUpdate(message) {
        if (!message) {
            return null;
        }
        const { robot_id } = message;
        if (robot_id && currRobotId === robot_id) {
            const hasTimestamp = !!message.timestamp;
            setTimeout(() => {
                loadTimeline(hasTimestamp ? 'list' : 'update');
            }, 400);
        }
        return true;
    }

    function findRobot(r_list, id) {
        if (r_list && id) {
            return r_list.find(r => {
                return r.id === id;
            });
        }
        return null;
    }

    function changeRobotType() {
        if (currRobotObj && robotTypes) {
            const type_id = currRobotObj.type ? currRobotObj.type.id : null;
            if (type_id) {
                const type = robotTypes[type_id];
                return setCurrRobotType(type);
            }
        }
        return setCurrRobotType({});
    }

    useEffect(() => {
        handleEventsUpdate(lastMessage || null);
    }, [lastMessage]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currRobotObj) {
                loadTimeline('update');
            }
        }, update_time);
        return () => clearInterval(interval);
    }, [timeline]);

    useEffect(() => {
        loadTimeline();
        setCurrRobotObj(findRobot(robots, currRobotId));
    }, [robots, currRobotId]);

    useEffect(() => {
        changeRobotType();
    }, [robotTypes, currRobotObj]);

    const list_open = !smallScreen || (showList && !currRobotObj);

    return (
        <AppRow smallScreen={smallScreen}>
            {list_open ? (
                <RobotList
                    smallScreen={smallScreen}
                    robots={robots}
                    robotTypes={robotTypes}
                    status={robotStatus}
                    currentActive={currRobotId || ''}
                    onItemClick={(event, r) => {
                        setEventsOpen(!smallScreen);
                        setCurrRobotId(r ? r.id : null);
                        setShowList(false);
                    }}
                />
            ) : null}
            {!smallScreen || !list_open ? (
                <>
                    <RobotData
                        eventsOpen={eventsOpen}
                        smallScreen={smallScreen}
                        showList={showList}
                        openList={() => setShowList(true)}
                        settings={settings}
                        groups={groups}
                        applications={applications}
                        locations={locations}
                        zones={zones}
                        user={user}
                        robotType={currRobotType}
                        robot={currRobotObj}
                        status={currRobotId ? robotStatus[currRobotId] : {}}
                        clearSelection={() => {
                            setCurrRobotObj(null);
                            setCurrRobotId('');
                            setShowList(true);
                        }}
                        requestError={error => requestError(error)}
                        sendAction={action => sendAction(action)}
                        sendNotification={n => sendNotification(n)}
                        lastNotification={lastNotification}
                    />
                    <RobotTimeline
                        eventsOpen={eventsOpen}
                        smallScreen={smallScreen}
                        robot={currRobotObj}
                        items={timeline}
                        dateFormat={date_format}
                        isLoading={loadingTimeline}
                        hasMore={moreEvents}
                        loadMore={() => loadTimeline('older')}
                        handleShowClick={() => setEventsOpen(!eventsOpen)}
                    />
                </>
            ) : null}
        </AppRow>
    );
}

RobotPage.propTypes = {
    smallScreen: PropTypes.bool,
    settings: PropTypes.object,
    robots: PropTypes.array,
    user: PropTypes.object,
    robotTypes: PropTypes.object,
    applications: PropTypes.array,
    locations: PropTypes.array,
    zones: PropTypes.array,
    groups: PropTypes.array,
    robotStatus: PropTypes.object,
    lastMessage: PropTypes.object,
    lastNotification: PropTypes.object,
    requestError: PropTypes.func.isRequired,
    sendAction: PropTypes.func.isRequired,
    sendNotification: PropTypes.func.isRequired,
};

RobotPage.defaultProps = {
    smallScreen: false,
    robots: [],
    user: {},
    robotTypes: {},
    applications: [],
    locations: [],
    zones: [],
    groups: [],
    settings: null,
    robotStatus: null,
    lastMessage: null,
    lastNotification: null,
};
