import React from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    Switch,
} from '@material-ui/core';

export default function FormSwitch({
    classes,
    color,
    label,
    value,
    onChange,
    disabled,
    helper,
    size,
}) {
    return (
        <FormControl
            component="fieldset"
            variant="standard"
            className={classes}
        >
            <FormControlLabel
                control={
                    <Switch
                        color={color}
                        checked={value}
                        value={value}
                        onChange={event => onChange(event)}
                        disabled={disabled}
                        size={size || 'medium'}
                    />
                }
                label={label}
            />
            {helper ? <FormHelperText>{helper}</FormHelperText> : null}
        </FormControl>
    );
}

FormSwitch.defaultProps = {
    classes: 'mb-3',
    color: 'primary',
    label: '',
    value: false,
    disabled: false,
    helper: null,
    size: 'medium',
};

FormSwitch.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    helper: PropTypes.element,
    size: PropTypes.string,
};
