/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdFileDownload } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetOperationTime from '~/util/GetOperationTime';

export default function DisinfectionReportDetails({
    id,
    requestError,
    onClose,
    format,
}) {
    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [disinfection, setDisinfection] = useState({});

    async function loadData(_id) {
        setIsLoading(true);

        await api
            .get(`disinfections/${_id}`)
            .then(response => {
                const { data } = response;
                setDisinfection(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function showDownload(file) {
        return (
            <div
                className="jr-card jr-card-widget jr-card-full card p-3"
                key={file.id}
            >
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="col-md-6 col-12">
                        {file.format ? `[${file.format.toUpperCase()}] ` : ''}
                        {file.name ? file.name.toUpperCase() : ''}
                    </div>
                    <div className="col-md-3 col-12">
                        {file && file.url ? (
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon={
                                    <MdFileDownload style={{ color: '#fff' }} />
                                }
                                onClick={() => {
                                    window.location.assign(file.url);
                                }}
                            >
                                <LocaleMessage msg="button.download_file" />
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    function showImage(image) {
        return (
            <div
                className="jr-card jr-card-widget jr-card-full text-center card"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#000',
                }}
                key={image.id}
            >
                {image && image.url ? (
                    <img
                        className="half-img-title"
                        src={image.url}
                        alt={image.name}
                        style={{
                            maxWidth: '70%',
                            maxHeight: '100%',
                            objectPosition: 'center',
                        }}
                    />
                ) : null}
            </div>
        );
    }

    const file_renderer = {
        image: showImage,
        document: showDownload,
    };

    function buildFileView(file) {
        const { type } = file;
        const renderer = file_renderer[type];
        return renderer ? renderer(file) : null;
    }

    function detailRow(label, start, end) {
        return (
            <div className="row mb-1">
                <DialogContentText className="col-4">{label}</DialogContentText>
                <DialogContentText className="col-4">
                    <strong>{start}</strong>
                </DialogContentText>
                <DialogContentText className="col-4">
                    <strong>{end}</strong>
                </DialogContentText>
            </div>
        );
    }

    function buildAirQuality(air_quality = {}) {
        const air_start = air_quality.start || {};
        const air_end = air_quality.end || {};

        return (
            <>
                <div className="row mt-3">
                    <DialogContentText className="col-4">
                        <strong>
                            <LocaleMessage msg="page.reports.disinfections.label.details.air_quality" />
                        </strong>
                    </DialogContentText>
                </div>
                {detailRow(
                    <LocaleMessage msg="page.reports.disinfections.label.details.temperature" />,
                    air_start.temperature ? `${air_start.temperature}°C` : '',
                    air_end.temperature ? `${air_end.temperature}°C` : ''
                )}
                {detailRow(
                    <LocaleMessage msg="page.reports.disinfections.label.details.humidity" />,
                    air_start.humidity ? `${air_start.humidity}%` : '',
                    air_end.humidity ? `${air_end.humidity}%` : ''
                )}
                {detailRow(
                    <LocaleMessage msg="page.reports.disinfections.label.details.co2" />,
                    air_start.co2 ? `${air_start.co2}` : '',
                    air_end.co2 ? `${air_end.co2}` : ''
                )}
            </>
        );
    }

    function buildDetails() {
        if (!disinfection) return null;
        const {
            map,
            map_point,
            mode,
            files,
            time,
            start,
            end,
            details,
        } = disinfection;

        const mode_key = `list.operations.mode.${mode || 'undefined'}`;
        const duration = GetOperationTime(time);
        const start_dt = GetDateTimeLabel(new Date(start), { format });
        const end_dt = end ? GetDateTimeLabel(new Date(end), { format }) : null;
        const air_quality =
            details && details.air_quality ? details.air_quality : null;

        return (
            <>
                <div className="row">
                    <DialogContentText className="col-4">
                        <LocaleMessage msg="page.reports.disinfections.label.details.point" />
                        <strong>
                            {map ? map.name : ''}
                            {map_point && map_point.name
                                ? ` - ${map_point.name}`
                                : ''}
                        </strong>
                    </DialogContentText>
                    <DialogContentText className="col-4">
                        <LocaleMessage msg="page.reports.disinfections.label.details.mode" />
                        <strong>
                            <LocaleMessage msg={mode_key} />
                        </strong>
                    </DialogContentText>
                </div>
                <div className="row">
                    <DialogContentText className="col-4">
                        <LocaleMessage msg="page.reports.disinfections.label.details.duration" />
                        <strong>{end_dt ? duration.label : ''}</strong>
                    </DialogContentText>
                    <DialogContentText className="col-4">
                        <LocaleMessage msg="page.reports.disinfections.label.details.start" />
                        <strong>{start_dt ? start_dt.string : ''}</strong>
                    </DialogContentText>
                    <DialogContentText className="col-4">
                        <LocaleMessage msg="page.reports.disinfections.label.details.end" />
                        <strong>{end_dt ? end_dt.string : ''}</strong>
                    </DialogContentText>
                </div>

                {air_quality ? buildAirQuality(air_quality) : null}

                <div className="row mt-5">
                    <DialogContentText className="col-4">
                        <strong>
                            <LocaleMessage msg="page.reports.disinfections.label.files" />
                        </strong>
                    </DialogContentText>
                </div>

                {files && files.length > 0 ? (
                    files.map(f => {
                        return buildFileView(f);
                    })
                ) : (
                    <DialogContentText style={{ textAlign: 'center' }}>
                        <LocaleMessage msg="page.reports.disinfections.label.files.no_files" />
                    </DialogContentText>
                )}
            </>
        );
    }

    useEffect(() => {
        if (id) {
            loadData(id);
        } else {
            setDisinfection(null);
        }
    }, [id]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <Dialog open={!!id} onClose={() => onClose()} maxWidth="md" fullWidth>
            <DialogTitle id="alert-dialog-title">
                <LocaleMessage msg="page.reports.disinfections.label.details" />
            </DialogTitle>
            <DialogContent>
                {isLoading ? <Splash /> : buildDetails()}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    <LocaleMessage msg="button.close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DisinfectionReportDetails.defaultProps = {};

DisinfectionReportDetails.propTypes = {
    id: PropTypes.string.isRequired,
    requestError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    format: PropTypes.object.isRequired,
};
