/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

export default function SURobots({ requestError, filter, formats }) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const headersFile = [
        { key: 'type_name', label: 'Robot Type' },
        { key: 'code', label: 'Code' },
        { key: 'name', label: 'Name' },
        { key: 'status', label: 'Active Status' },
        { key: 'binding', label: 'Binding Status' },
        { key: 'pluginspace_name', label: 'Pluginspace' },
        { key: 'group_name', label: 'Group' },
        { key: 'location_name', label: 'Location' },
        { key: 'location_city', label: 'City' },
        { key: 'location_state', label: 'State' },
        { key: 'location_country', label: 'Country' },
        { key: 'zone_name', label: 'Zone' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
    ];

    const headerCells = [
        {
            id: 'code',
            label: <LocaleMessage msg="table.headers.code" />,
        },
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'type_name',
            label: <LocaleMessage msg="table.headers.robot_type" />,
        },
        {
            id: 'status_name',
            label: <LocaleMessage msg="table.headers.status" />,
            order_by: 'status',
        },
        {
            id: 'binding_status',
            label: <LocaleMessage msg="table.headers.binding" />,
            order_by: 'binding',
        },
        {
            id: 'pluginspace_name',
            label: <LocaleMessage msg="table.headers.pluginspace" />,
        },
        {
            id: 'created_date',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(r => {
            const created = new Date(r.created);
            const updated = new Date(r.updated);
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);

            return {
                id: r.id,
                code: r.code,
                name: r.name,
                status: r.status,
                status_name: r.status ? (
                    <LocaleMessage msg={`robot.status.${r.status}`} />
                ) : (
                    <LocaleMessage msg="info.status.unknown" />
                ),
                binding: r.binding,
                binding_status: r.binding ? (
                    <LocaleMessage msg={`robot.bind.${r.binding}`} />
                ) : (
                    <LocaleMessage msg="info.status.unknown" />
                ),
                pluginspace_name:
                    r.pluginspace && r.pluginspace.name
                        ? r.pluginspace.name
                        : '---',
                group_name: r.group && r.group.name ? r.group.name : '---',
                type_name: r.type && r.type.name ? r.type.name : '---',
                location_name:
                    r.location && r.location.name ? r.location.name : '---',
                zone_name: r.zone && r.zone.name ? r.zone.name : '---',
                location_city:
                    r.location && r.location.city ? r.location.city : '---',
                location_state:
                    r.location && r.location.state ? r.location.state : '---',
                location_country:
                    r.location && r.location.country
                        ? r.location.country
                        : '---',
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
            };
        });
        setItems(data);
    }

    async function loadRobots() {
        setIsLoading(true);
        const filter_url = filter ? `?pluginspace=${filter}` : '';
        await api
            .get(`admin/robots${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadRobots();
    }, [formats, filter]);

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headersFile}
                filename={GetFileName(`admin_analytics_robots`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headerCells}
                    data={items}
                    orderColumn="code"
                    handleTableRowClick={(event, id) => {
                        history.push(`/admin/robots/${id}`);
                    }}
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <div style={{ padding: '15px' }}>{buildExportButton()}</div>
            <>{buildListView()}</>
        </>
    );
}

SURobots.defaultProps = {
    filter: '',
    requestError: () => {},
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SURobots.propTypes = {
    filter: PropTypes.string,
    requestError: PropTypes.func,
    formats: PropTypes.object,
};
