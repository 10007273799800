import { darken, lighten } from 'polished';
import styled from 'styled-components';

export const PreviewArea = styled.div`
    border: 2px solid #999;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #999;
    border-radius: 5px;
    width: 100%;
    height: ${props => {
        const w = props.dimensions.width;
        const h = (9.0 / 16.0) * w;
        return `${parseInt(h, 10) || 0}px`;
    }};
    background: ${props => {
        const { colors } = props;
        const c1 = darken(0.02, colors ? colors.background_color : '#000');
        const c2 = darken(1, colors ? colors.background_color : '#000');
        return `radial-gradient(${c1}, ${c2})`;
    }};
    font-family: 'Saira Semi Condensed', sans-serif !important;
    color: #eee;
    padding: 10px;

    .list {
        width: 100%;
        border-radius: 5px;
        padding: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.025, 10) || 0}px ${parseInt(
                h * 0.0125,
                10
            ) || 0}px`;
        }};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .exit {
            min-height: 10%;
            max-height: 10%;
        }
    }

    .header {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pluginbot-logo {
            max-width: 15%;
            max-height: 95%;
            margin: 0px !important;
        }
    }
`;

export const ListsBody = styled.div`
    padding: 5px 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    width: ${props => {
        const w = props.dimensions.width;
        return `${w}px`;
    }};
    height: 70%;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding: 10px;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    ul {
        height: 100%;
        display: flex;
        flex-direction: row;
        margin: 0px;
    }
`;

export const ItemCard = styled.div`
    flex: 0 0 23%;
    height: 100%;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: ${props => {
        const { color } = props;
        const c2 = lighten(0.2, color || '#f00');
        return `linear-gradient(45deg, ${color}, ${c2})`;
    }};

    .card-img {
        height: 80%;
        padding: 0px;
        object-fit: ${props => {
            return props.expand ? 'cover' : 'contain';
        }};
    }

    .card-txt {
        height: 20%;
        padding: 10px 5px;
        text-align: center;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

export const Footer = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0px 10px;

    .random-btn {
        color: #fff;
        padding: 5px 10px;
        font-size: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.035, 10) || 0}px`;
        }};
    }
`;
