import { darken } from 'polished';
import styled from 'styled-components';

export const AppContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const AppRow = styled.div`
    height: 100%;
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props => {
        return props.smallScreen ? 'column' : 'row';
    }};
`;

export const DarkDialog = styled.div`
    color: #fff !important;
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-bottom: 0.5vh solid ${props => props.theme.palette.primary.main};
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `${darken(0.25, color)}`;
    }};
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiDialogTitle-root {
        h2 {
            color: #fff !important;
        }
    }
`;
