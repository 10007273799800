/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdForum } from 'react-icons/md';

import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import Page404 from '~/pages/Open/Page404';
import api from '~/services/pluginbot-api';

import AutonomousModeConfig from './AutonomousModeConfig';
import ConversationConfig from './ConversationConfig';
import ApplicationContentConfig from './DialogContentConfig';
import HIVEAppsConfig from './HIVEAppsConfig';
import IdentifiersConfig from './IdentifiersConfig';
import LanguageConfig from './LanguageConfig';
import MapNavigationConfig from './MapNavigationConfig';
import PresentationModeConfig from './PresentationModeConfig';
import TriggerConfig from './TriggerConfig';
import VisionConfig from './VisionConfig';

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
            borderRight: `1px solid ${theme.palette.divider}`,
            textAlign: 'left',
        },
    };
});

const available_settings = [
    {
        value: 'app_setup',
        label_code: 'label.settings.app.conversation.setup',
    },
    {
        value: 'language',
        label_code: 'label.settings.app.conversation.language',
    },
    {
        value: 'application_content',
        label_code: 'label.settings.app.conversation.application_content',
    },
    {
        value: 'map_navigation',
        label_code: 'label.settings.app.conversation.map_navigation',
        condition_type: 'plugin',
        condition: 'robot_functions',
    },
    {
        value: 'hive_apps',
        label_code: 'label.settings.app.conversation.hive_apps',
        condition_type: 'plugin',
        condition: 'hive_apps',
    },
    {
        value: 'trigger',
        label_code: 'label.settings.app.conversation.trigger',
    },
    {
        value: 'identifiers',
        label_code: 'label.settings.app.conversation.trigger_identifiers',
        condition_type: 'plugin',
        condition: 'robot_functions',
    },
    {
        value: 'presentation_mode',
        label_code: 'label.settings.app.conversation.presentation_mode',
        condition_type: 'tool',
        condition: 'presentation_contents',
    },
    {
        value: 'autonomous_mode',
        label_code: 'label.settings.app.conversation.autonomous_mode',
        condition_type: 'tool',
        condition: 'autonomous_actions',
    },
    // {
    //     value: 'vision',
    //     label_code: 'label.settings.app.conversation.vision',
    //     condition_type: 'plugin',
    //     condition: 'vision',
    // },
];

export default function ConversationApp({
    location,
    setToast,
    appSettings,
    updateObject,
}) {
    const { robot_types } = appSettings;

    const { search } = location;
    const query = qs.parse(search);

    const [queryTab, setQueryTab] = useQueryState('tab', '');
    const [queryRobotType, setQueryRobotType] = useQueryState('robot_type', '');

    const [plugins, setPlugins] = useState([]);
    const [tools, setTools] = useState([]);
    const [currType, setCurrType] = useState(robot_types[0]);
    const [isLoading, setIsLoading] = useState(true);

    const [value, setValue] = useState(query && query.tab ? query.tab : null);

    const classes = useStyles();

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            setToast({
                color: 'error',
                body: message,
            });
        } else if (error.request) {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.request" />,
            });
        } else {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.unknown" />,
            });
        }
        setIsLoading(false);
    }

    async function loadPlugins() {
        setIsLoading(true);
        await api
            .get(`pluginspaces/plugins`)
            .then(async response => {
                const { data } = response;
                setPlugins(data);
            })
            .catch(e => {
                requestError(e);
            });
        setIsLoading(false);
    }

    async function loadTools() {
        setIsLoading(true);
        await api
            .get(`pluginspaces/tools`)
            .then(async response => {
                const { data } = response;
                setTools(data);
            })
            .catch(e => {
                requestError(e);
            });
        setIsLoading(false);
    }

    async function loadSettings() {
        let v = available_settings[0].value;
        if (queryTab) {
            v = queryTab;
        }
        setValue(v);
    }

    useEffect(() => {
        loadSettings();
        loadPlugins();
        loadTools();
    }, []);

    useEffect(() => {
        const r_type = robot_types.find(r => {
            return r.id === queryRobotType;
        });
        setCurrType(r_type || robot_types[0]);
    }, [queryRobotType]);

    const pages = {
        app_setup: ConversationConfig,
        language: LanguageConfig,
        application_content: ApplicationContentConfig,
        trigger: TriggerConfig,
        map_navigation: MapNavigationConfig,
        hive_apps: HIVEAppsConfig,
        presentation_mode: PresentationModeConfig,
        autonomous_mode: AutonomousModeConfig,
        vision: VisionConfig,
        identifiers: IdentifiersConfig,
    };

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

        if (search !== newValue) {
            setQueryTab(newValue);
        }
    };

    const handleChangeRobot = newValue => {
        setQueryRobotType(newValue);
    };

    function renderOptions() {
        const filteredSettings = available_settings.filter(s => {
            if (s.condition_type === 'plugin') {
                const c = s.condition;
                const p = plugins.find(pl => {
                    return pl.slug === c;
                });
                return !!p;
            }
            if (s.condition_type === 'tool') {
                const c = s.condition;
                const tool = tools.find(t => {
                    return t.slug === c;
                });
                return !!tool;
            }
            return true;
        });

        const currTab = filteredSettings.find(t => {
            return t.value === value;
        });

        const firstTab = filteredSettings[0] ? filteredSettings[0].value : '';

        return (
            <div className="col-md-2 col-12">
                <Tabs
                    indicatorColor="primary"
                    orientation="vertical"
                    value={currTab ? value : firstTab}
                    onChange={handleChangeTab}
                    className={classes.tabs}
                >
                    {filteredSettings.map(s => {
                        return (
                            <Tab
                                key={s.value}
                                label={<LocaleMessage msg={s.label_code} />}
                                value={s.value}
                            />
                        );
                    })}
                </Tabs>
            </div>
        );
    }

    function renderInternalPage(page) {
        const Page = pages[page] || Page404;
        return (
            <Page
                currType={currType}
                setCurrType={r => {
                    const r_id = r ? r.id : '';
                    handleChangeRobot(r_id);
                }}
                robotTypes={robot_types}
                updateObject={data => updateObject(data)}
                appSettings={appSettings}
                setToast={message => setToast(message)}
                handleError={error => requestError(error)}
            />
        );
    }

    return (
        <div className="col-12 mb-5">
            {isLoading ? (
                <Splash />
            ) : (
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.applications.conversation.title" />
                    }
                    Icon={MdForum}
                    hide
                >
                    <div className={`sidecard-body ${classes.root} col-12 row`}>
                        {value ? (
                            <>
                                {renderOptions()}
                                <div
                                    className="col-md-10 col-12 row"
                                    style={{ flex: 1 }}
                                >
                                    {renderInternalPage(value)}
                                </div>
                            </>
                        ) : null}
                    </div>
                </CardSideBordered>
            )}
        </div>
    );
}

ConversationApp.propTypes = {
    appSettings: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
};
