/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

import Dashboard from './Dashboard';
import { AppContainer } from './styles';

const error_time = 2000;

export default function SUView() {
    const mountedRef = React.useRef(true);
    const settings = useSelector(state => state.settings || null);

    const [robots, setRobots] = useState([]);
    const [counters, setCounters] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message, {
                autoClose: error_time,
            });
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />, {
                autoClose: error_time,
            });
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />, {
                autoClose: error_time,
            });
        }
    }

    async function loadRobots() {
        await api
            .get(`admin/robots?active=true&status=active`)
            .then(response => {
                const robot_list = response.data.map(r => {
                    return {
                        id: r.id,
                        name: r.name,
                        code: r.code,
                        status: r.status,
                        type_id: r.type ? r.type.id : '',
                        pluginspace_id: r.pluginspace ? r.pluginspace.id : '',
                        coordinates:
                            r.location && r.location.coordinates
                                ? r.location.coordinates
                                : null,
                        created: r.created,
                    };
                });
                setRobots(robot_list);
            })
            .catch(error => requestError(error));
    }

    async function loadCounters() {
        await api
            .get(`admin/all`)
            .then(response => {
                setCounters(response.data);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadRobots();
        loadCounters();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function renderDashboard() {
        return (
            <Dashboard
                settings={settings}
                robots={robots}
                counters={counters}
                users={counters && counters.users ? counters.users.active : 0}
                pluginspaces={
                    counters && counters.pluginspaces
                        ? counters.pluginspaces.active
                        : 0
                }
                requestError={error => requestError(error)}
            />
        );
    }

    return <AppContainer>{renderDashboard()}</AppContainer>;
}

SUView.propTypes = {};

SUView.defaultProps = {};
