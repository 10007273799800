/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

export default function IconWithTextCard({ data, image, color }) {
    const { title, subTitle } = data;
    return (
        <Card
            className="jr-card jr-card-widget"
            style={{
                backgroundColor: color,
                height: '100%',
            }}
        >
            <div className="media align-items-center flex-nowrap row">
                <div className="mr-3 col-4">
                    <img
                        className="img-fluid"
                        src={image}
                        alt={subTitle}
                        style={{ maxWidth: '100%' }}
                    />
                </div>
                <div className="media-body col-8">
                    <h1
                        className="text-white mb-0"
                        style={{ fontSize: '36px', fontWeight: 'bold' }}
                    >
                        {title}
                    </h1>
                    <p className="text-white mb-0">{subTitle}</p>
                </div>
            </div>
        </Card>
    );
}

IconWithTextCard.defaultProps = {
    data: {},
    color: '#3f51b5',
    image: '',
};

IconWithTextCard.propTypes = {
    data: PropTypes.object,
    image: PropTypes.string,
    color: PropTypes.string,
};
