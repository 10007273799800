import { shade } from 'polished';
import styled from 'styled-components';

export const CentralPanel = styled.div`
    width: ${props => {
        return props.smallScreen ? '100%' : 'calc(70% - 40px)';
    }};
    height: ${props => {
        const closed_height = props.smallScreen ? '5%' : '100px';
        return props.open ? '100%' : closed_height;
    }};
    padding-bottom: ${props => {
        return props.smallScreen ? '5vh' : '20vh';
    }};
    overflow: ${props => {
        return props.smallScreen ? 'scroll' : 'hidden';
    }};
    border: 1px solid #555555;
    border-style: ${props => {
        return props.smallScreen ? 'solid' : 'none';
    }};

    .panel-header {
        padding: 1vh;
        color: #ccc;
        display: flex;
        justify-content: flex-end;

        :hover {
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: ${props => {
        return props.smallScreen ? '0px 10px 15px 10px' : '0px 40px 50px 40px';
    }};
`;

export const Placeholder = styled.div`
    max-width: 100%;
    max-height: 250px;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
    color: #fff;
    padding: 5px;
`;

export const InfoRow = styled.div`
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: center;

    .fullsize {
        display: flex;
        flex: 1;
        height: 100%;
    }
`;

export const InfoCard = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: ${props => props.direction || 'column'};
    align-items: center;

    .icon-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        height: 100%;
        padding: 0px;

        .card-title {
            color: #999;
            font-size: 1.35vh;
            width: 100%;
            text-align: center;
            margin-bottom: 1.5vh;
        }
    }

    .info-area {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 0.25vw;
        height: 100%;

        .info {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            font-size: 1.35vh;

            .info-title {
                color: #999;
                padding: 0px;
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
            }

            .info-text {
                text-align: left;
            }

            .info-value {
                font-size: 1.35vh;
            }

            .big-value {
                font-size: 2.75vh;
                span {
                    margin-top: 0.5vh;
                }
            }
        }

        .info:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .desc-full {
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1vh;

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 1.35vh !important;
        }

        .info-chart {
            margin: 1vh 1vw;
        }

        .big-value {
            font-size: 2vh;
            font-weight: bold;

            span {
                margin-top: 10px;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .desc-full:not(:last-child) {
        border-bottom: ${props =>
            props.direction === 'column' ? ' solid 1px #555555' : 'none'};
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;

export const TimeChart = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    width: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.35vh;
    height: 100%;

    .chart-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-title {
        padding: 10px 20px;
        margin: 0px;

        span {
            font-size: 1.35vh;
            color: #999;
        }
    }

    .jr-overview-row {
        width: 100%;
    }

    .jr-description {
        width: 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-left: solid 1px #555555;
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    }

    .desc-full {
        display: flex;
        flex: 1 1;
        padding: 15px;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.35vh;
        }

        .value {
            font-size: 1.35vh;
            font-weight: bold;
            span {
                margin-top: 20px;
            }
        }

        .big-value {
            font-size: 2.35vh;
            font-weight: bold;
            span {
                margin-top: 20px;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;
