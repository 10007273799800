/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';

export default function Contacts({ requestError, locale, active }) {
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);

    async function loadContacts() {
        await api
            .get(`contacts`)
            .then(async response => {
                const all_contacts = response.data.map(c => {
                    const updated = new Date(c.updated);
                    return {
                        ...c,
                        group_name: c.group ? c.group.name : '',
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${c.source}`}
                            />
                        ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setContacts(all_contacts);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadContacts();
    }, [active]);

    async function deleteContact() {
        setIsLoading(true);
        setDeleteOpen(false);

        await api
            .delete(`contacts/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.contacts.list.delete_success" />
                );
                loadContacts();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleListRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/tools/contacts/${id}`);
    }

    function renderDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={event => handleDialogClose(event, 'delete')}
                title={
                    <LocaleMessage msg="page.tools.contacts.list.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteContact(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderContacts() {
        const headCells = [
            ...(active.id === '*'
                ? [
                      {
                          id: 'group_name',
                          label: <LocaleMessage msg="table.headers.group" />,
                      },
                  ]
                : []),
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'source_type',
                label: <LocaleMessage msg="table.headers.source" />,
            },
            {
                id: 'user_count',
                label: <LocaleMessage msg="table.headers.contact_users" />,
            },
            {
                id: 'list_count',
                label: <LocaleMessage msg="table.headers.in_lists" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={contacts}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleListRowClick(event, id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div className="col-3" style={{ padding: '0px' }}>
                                <NewItemButton
                                    link="/tools/contacts/new"
                                    text={
                                        <LocaleMessage msg="page.tools.contacts.list.add" />
                                    }
                                    disabled={!active || active.id === '*'}
                                />
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            {deleteOpen ? renderDeleteDialog() : null}
            <div className="row full-body">{renderContacts()}</div>
        </>
    );
}

Contacts.propTypes = {
    requestError: PropTypes.func.isRequired,
    locale: PropTypes.object.isRequired,
    active: PropTypes.object.isRequired,
};
