import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
 0% {box-shadow: 0 0 0 0 #44444444; }
 50% {box-shadow: 0 0 0 20px #44444444;}
 100% {box-shadow: 0 0 0 0 #44444444; }
`;

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .pulse {
        animation-name: ${pulse};
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
`;

export const Title = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    strong {
        font-size: ${props => {
            return props.fontSize || '1.5vh';
        }};
    }
`;

export const Actions = styled.div`
    height: 100%;
    /* width: 100%; */
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: ${props => {
        return props.align || 'center';
    }};
    justify-content: ${props => {
        return props.justify || 'space-between';
    }};
`;
