/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportPieChart from '~/components/Reports/ReportPieChart';

import GetFileName from '~/util/GetFileName';

import { TotalContainer, RowArea, InfoContainer } from '../../style';
import Filter from './FilterData';

export default function CallAccessView({ settings, data, active, colors }) {
    const { format } = settings;
    const call_data = Filter(data, format);

    const _headers = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'group_name',
            label: <LocaleMessage msg="table.headers.group" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'user_name',
            label: <LocaleMessage msg="table.headers.accessed_by" />,
        },
        {
            id: 'end',
            order_by: 'end_timestamp',
            label: <LocaleMessage msg="table.headers.end_date" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
    ];

    const headers_file = {
        main: [
            { key: 'mode_str', label: 'Mode' },
            { key: 'group_name', label: 'Group' },
            { key: 'robot_name', label: 'Robot' },
            { key: 'application_name', label: 'Application' },
            {
                key: 'start_date',
                label: 'Start Date',
            },
            {
                key: 'start_time',
                label: 'Start Time',
            },
            {
                key: 'end_date',
                label: 'End Date',
            },
            {
                key: 'end_time',
                label: 'End Time',
            },
            {
                key: 'user_name',
                label: 'Accessed By',
            },
            {
                key: 'duration_label',
                label: 'Duration',
            },
        ],
    };

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: call_data.groups,
                  },
              ]
            : []),
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_graphs,
            data: call_data.robots,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: call_data.applications,
        },
        {
            key: 'users',
            title: 'per_user',
            color: colors.dashboard_graphs,
            data: call_data.users,
        },
    ];

    const total_calls = data ? data.length : 0;
    const call_list = call_data ? call_data.data : [];

    function renderCSVButton() {
        return (
            <ExportCSVButton
                data={call_list}
                headers={headers_file.main}
                filename={GetFileName(
                    `REPORTS-telepresence_calls-accesses`,
                    'csv'
                )}
            />
        );
    }

    return (
        <>
            <RowArea className="mt-5 mb-3">
                <div>
                    <p className="card-title">
                        <LocaleMessage msg="page.reports.telepresence.label.mode.access" />
                    </p>
                </div>
            </RowArea>
            <TotalContainer className="row mt-3 mb-5">
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.total_calls" />
                    }
                    value={total_calls}
                />
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.total_duration" />
                    }
                    value={call_data.duration || '00:00:00'}
                />
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.mean_duration" />
                    }
                    value={call_data.mean_duration || '00:00:00'}
                />
            </TotalContainer>
            <Divider />
            <InfoContainer className="row mt-3 mb-3">
                {call_data ? (
                    <>
                        {pie_charts.map(c => {
                            const datakey = 'total';
                            return (
                                <ReportPieChart
                                    key={`${datakey}_${c.key}`}
                                    classes={def_class}
                                    title={
                                        <LocaleMessage
                                            msg={`page.reports.telepresence.label.${c.title}.total`}
                                        />
                                    }
                                    color={c.color}
                                    data={c.data}
                                    dataKey={datakey}
                                />
                            );
                        })}
                    </>
                ) : null}
            </InfoContainer>
            <Divider />
            <InfoContainer className="row mt-3 mb-3">
                <RowArea className="col-12 mb-3 table-responsive-material">
                    <div>
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.telepresence.label.history" />
                        </p>
                    </div>
                    <DataTable
                        headerColumns={_headers}
                        data={call_list}
                        orderDirection="desc"
                        orderColumn="start_timestamp"
                        hasActions={false}
                        sortable
                        defaultRowsPerPage={10}
                        hasHeader
                        hasFilter
                        headerDirection="row-reverse"
                        header={
                            <div className="col-4" style={{ padding: '0px' }}>
                                {renderCSVButton()}
                            </div>
                        }
                    />
                </RowArea>
            </InfoContainer>
        </>
    );
}

CallAccessView.defaultProps = {
    data: [],
    active: {},
};

CallAccessView.propTypes = {
    data: PropTypes.array,
    active: PropTypes.object,
    settings: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
};
