/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import Nodes from './QnANodes';

export default function QnATree(props) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { match } = props;
    const { id, version_id } = match.params;

    const [dialog, setDialog] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currVersion, setCurrVersion] = useState(null);
    const [allowedLanguages, setAllowedLanguages] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const list = response.data;
                const langs = [];
                list.forEach(l => {
                    langs.push(l.value);
                });
                setAllowedLanguages(langs);
            })
            .catch(e => {
                setAllowedLanguages([]);
                requestError(e);
            });
    }

    async function loadDialog() {
        await api
            .get(`dialogs/${id}`)
            .then(response => {
                const { data } = response;
                setDialog(data);
            })
            .catch(error => {
                setDialog({});
                requestError(error);
            });
        setIsLoading(false);
    }

    async function loadVersionDetail() {
        setIsLoading(true);
        if (version_id) {
            await api
                .get(`dialogs/${id}/versions/${version_id}`)
                .then(response => {
                    const version = response.data;
                    setCurrVersion(version);
                })
                .catch(error => requestError(error));
        } else {
            setCurrVersion({});
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
        loadDialog();
        loadVersionDetail();
    }, []);

    return (
        <>
            {isLoading ? (
                <Splash />
            ) : (
                <Nodes
                    locale={date_loc}
                    dialogSettings={dialog}
                    answerLanguages={allowedLanguages}
                    workingVersion={version_id}
                    versionSettings={
                        currVersion && currVersion.values
                            ? currVersion.values
                            : {}
                    }
                    handleError={error => requestError(error)}
                />
            )}
        </>
    );
}

QnATree.propTypes = {
    match: PropTypes.object.isRequired,
};
