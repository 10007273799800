import { shade } from 'polished';
import styled from 'styled-components';

export const ListPanel = styled.div`
    height: 100%;
    width: ${props => {
        return props.smallScreen ? '100%' : 'calc(15% + 20px)';
    }};
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.97, color);
    }};
    margin: 3vh 0px;
    border-right: 1px solid #555555;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SearchDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vh 0px;
    width: 85%;
    font-size: 1.4vh !important;

    h3 {
        font-size: 1.75vh;
        font-weight: bold;
        color: #eee;
        text-align: center;
        margin-bottom: 20px;
    }

    .filter-field {
        width: 100%;
        margin: 2vh 0px;
        background-color: #38424b;
        * {
            font-size: 1.4vh !important;
            color: #eee;
        }
    }
`;

export const ListDiv = styled.div`
    height: 100%;
    width: 100%;
    font-size: 1.4vh !important;
    border-top: 1px solid #555555;

    ul {
        margin: 0px;
        padding: 0px;
        padding-bottom: 200px;

        .active {
            background-color: rgba(0, 0, 0, 0.5);
            border-right: 0.25vw solid
                ${props => props.theme.palette.primary.main};
        }

        li {
            border-bottom: 1px solid #555555;
        }
    }
`;
