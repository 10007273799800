/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdPhotoSizeSelectLarge,
    MdPhotoSizeSelectActual,
} from 'react-icons/md';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Grid,
    Slider,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

import { ParameterArea, MediaArea } from '../../styles';

export default function ContentParameters({
    settings,
    setSettings,
    galleries,
    setToast,
    handleError,
    updateFile,
}) {
    const mediaSettings = settings.media || {};
    const defGallery = mediaSettings.gallery_id || '';

    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [currGallery, setCurrGallery] = useState(defGallery);
    const [currFile, setCurrFile] = useState(null);

    async function loadAlbumFiles(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setIsLoading(false);
        } else {
            setFileList([]);
        }
    }

    function loadFile(_id) {
        const file = fileList.find(f => {
            return f.id === _id;
        });
        setCurrFile(file);
        updateFile(file);
    }

    useEffect(() => {
        if (settings.show_image && !currGallery) {
            setCurrGallery(galleries && galleries[0] ? galleries[0].id : null);
        }
    }, [galleries, settings.show_image]);

    useEffect(() => {
        loadAlbumFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        return loadFile(mediaSettings.file_id);
    }, [fileList, mediaSettings.file_id]);

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(() => {
                setToast({
                    color: 'success',
                    body: <LocaleMessage msg="message.media_uploaded" />,
                });
                loadAlbumFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const file_url = file.url;
        const f_properties = file_url.split('.');
        const f_format = f_properties[f_properties.length - 1];

        setSettings('media', {
            type: 'image',
            gallery_id: currGallery,
            file_id: file.id,
            url: file.url,
            image: file.path,
            format: f_format,
        });
        setMediaDialogOpen(false);
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
    }

    function renderGallery(type) {
        const currGalleryObj = galleries.find(g => {
            return g.id === currGallery;
        });
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.telepresence.form.gallery" />
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {!g.editable ? (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : null}
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={
                                        type === 'image' &&
                                        currGalleryObj.editable
                                    }
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type = 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    <LocaleMessage msg="page.applications.telepresence.form.media" />
                }
                content={renderGallery(g_type)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {mediaDialogOpen ? renderMediaDialog() : null}
            <ParameterArea>
                <div className="row mt-3">
                    <div className="col-12">
                        <FormSwitch
                            value={
                                settings.show_logo ? settings.show_logo : false
                            }
                            onChange={event =>
                                setSettings('show_logo', event.target.checked)
                            }
                            label={
                                <LocaleMessage msg="page.applications.telepresence.form.show_logo" />
                            }
                        />
                    </div>
                    <div className="col-12">
                        <FormSwitch
                            value={
                                settings.show_text ? settings.show_text : false
                            }
                            onChange={event =>
                                setSettings('show_text', event.target.checked)
                            }
                            label={
                                <LocaleMessage msg="page.applications.telepresence.form.show_text" />
                            }
                        />
                    </div>
                    {settings.show_text ? (
                        <>
                            <div className="col-8">
                                <TextField
                                    className="mb-3"
                                    label={
                                        <LocaleMessage msg="page.applications.telepresence.form.standby_text" />
                                    }
                                    fullWidth
                                    value={
                                        settings && settings.standby_text
                                            ? settings.standby_text
                                            : ''
                                    }
                                    onChange={event =>
                                        setSettings(
                                            'standby_text',
                                            event.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="col-4">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.applications.telepresence.form.text_size" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            settings && settings.text_size
                                                ? settings.text_size
                                                : ''
                                        }
                                        onChange={event =>
                                            setSettings(
                                                'text_size',
                                                event.target.value
                                            )
                                        }
                                    >
                                        <MenuItem value="small">
                                            <LocaleMessage msg="list.size.small" />
                                        </MenuItem>
                                        <MenuItem value="medium">
                                            <LocaleMessage msg="list.size.medium" />
                                        </MenuItem>
                                        <MenuItem value="large">
                                            <LocaleMessage msg="list.size.large" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="col-12 row">
                    <div className="col-6">
                        <FormSwitch
                            fullWidth
                            className="mb-3"
                            value={
                                settings.show_image
                                    ? settings.show_image
                                    : false
                            }
                            onChange={event =>
                                setSettings('show_image', event.target.checked)
                            }
                            label={
                                <LocaleMessage msg="page.applications.telepresence.form.show_image" />
                            }
                        />
                        {settings.show_image ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span className="mb-3">
                                    <LocaleMessage msg="page.applications.telepresence.form.image_size" />
                                </span>
                                <Grid className="mb-3" container spacing={2}>
                                    <Grid item>
                                        <MdPhotoSizeSelectLarge />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            min={1}
                                            max={100}
                                            value={
                                                settings && settings.media_width
                                                    ? Number(
                                                          settings.media_width
                                                      )
                                                    : 50
                                            }
                                            onChange={(event, newValue) =>
                                                setSettings(
                                                    'media_width',
                                                    newValue
                                                )
                                            }
                                            aria-labelledby="continuous-slider"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <MdPhotoSizeSelectActual />
                                    </Grid>
                                </Grid>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setMediaDialogOpen(true)}
                                >
                                    <LocaleMessage msg="label.form.change_image" />
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    {settings.show_image ? (
                        <div className="col-6">
                            <MediaArea className="col-12">
                                {currFile ? (
                                    <Card className="img-card">
                                        <img
                                            src={currFile.url}
                                            alt={currFile.id}
                                        />
                                    </Card>
                                ) : null}
                            </MediaArea>
                        </div>
                    ) : null}
                </div>
            </ParameterArea>
        </>
    );
}

ContentParameters.defaultProps = {
    settings: {},
    galleries: [],
};

ContentParameters.propTypes = {
    settings: PropTypes.object,
    setSettings: PropTypes.func.isRequired,
    galleries: PropTypes.array,
    handleError: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
};
