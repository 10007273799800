/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdSend,
    MdExpandLess,
    MdExpandMore,
    MdLink,
    MdLinkOff,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import PropTypes from 'prop-types';

import {
    Button,
    IconButton,
    TextField,
    TextareaAutosize,
    Tab,
    Tabs,
    MenuItem,
    Select,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import {
    Wrapper,
    Column,
    BorderedArea,
    IconParameter,
    PresentationModeArea,
    MessageShortcut,
} from './styles';

const error_time = 2000;
const server_port = 38301;
const server_protocol = 'ws';
const default_server = `${server_protocol}://0.0.0.0`;
// const default_server =  'wss://echo.websocket.org';

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            textAlign: 'left',
        },
    };
});

export default function Local({ match }) {
    const { id } = match.params;

    const classes = useStyles();
    const mountedRef = React.useRef(true);

    const userSettings = useSelector(state => state.user);

    const user =
        userSettings && userSettings.profile ? userSettings.profile : null;

    const [isLoading, setIsLoading] = useState(true);
    const [robot, setRobot] = useState({});
    const [robotApps, setRobotApps] = useState({});
    const [application, setApplication] = useState({});
    const [robotIP, setRobotIP] = useState('');
    const [connection, setConnection] = useState({
        url: default_server,
        state: '',
    });

    const [currLanguage, setCurrLanguage] = useState('pt_BR');

    const [newMessage, setNewMessage] = useState('');
    // const [messageHistory, setMessageHistory] = useState([]);
    // const [lastMessage, setLastMessage] = useState({});
    // const [lastEvent, setLastEvent] = useState({});
    // const [lastNotification, setLastNotification] = useState({});
    // const [lastAlert, setLastAlert] = useState({});

    const [appType, setAppType] = useState('');
    const [presentationContent, setPresentationContent] = useState({});
    const [options, setOptions] = useState({});

    const r_type = robot ? robot.type : {};

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message, {
                autoClose: error_time,
            });
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />, {
                autoClose: error_time,
            });
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />, {
                autoClose: error_time,
            });
        }
    }

    async function loadRobot() {
        setIsLoading(true);
        await api
            .get(`robots/${id}`)
            .then(response => {
                const { data } = response;
                const r_settings = data.settings || {};
                setRobotApps(r_settings.applications || {});
                setRobot(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadApplication(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`applications/${_id}`)
                .then(response => {
                    const { data } = response;
                    setApplication(data);
                })
                .catch(error => requestError(error));
        } else {
            setApplication({});
        }
        setIsLoading(false);
    }

    async function loadPresentationContent(content_id) {
        // console.log(`load: ${content_id}`);
        if (content_id) {
            setIsLoading(true);
            await api
                .get(`app_contents/${content_id}`)
                .then(response => {
                    const { data } = response;
                    setPresentationContent(data);
                })
                .catch(error => requestError(error));
        } else {
            setPresentationContent({});
        }
        setIsLoading(false);
    }

    async function getConversationContents() {
        const app_data = application.settings || {};
        const presentation_mode = app_data.presentation_mode || {};
        const r_slug = r_type ? r_type.slug : '';
        const r_presentation = presentation_mode[r_slug] || {};

        loadPresentationContent(r_presentation.content_id);
    }

    const handleChangeTab = (event, newValue) => {
        setAppType(newValue);
    };

    function handleWSConnected(sendMessage) {
        console.log('opened');
        sendMessage({
            type: 'auth',
            client: user.id,
            password: id,
        });
    }

    function onConnectionFailed(e) {
        if (connection.url === default_server) return;
        setConnection({ url: default_server });
        console.log(e);
        toast.error(<LocaleMessage msg="errors.ws_connection" />, {
            autoClose: error_time,
        });
    }

    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
        connection.url,
        {
            // queryParams: {
            //     password: id,
            // },
            onOpen: () => handleWSConnected(sendJsonMessage),
            onClose: () => console.log('closed'),
            onError: e => onConnectionFailed(e),
            shouldReconnect: () => false,
            onMessage: () => {
                console.log(m);
                // const msg = JSON.parse(m.data || '{}');
                // console.log('🚀 ~ Local ~ msg:', msg);
                // setLastMessage(msg);
                // setMessageHistory([...messageHistory, msg]);
            },
        }
    );

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    function connectWebSocket(c) {
        const dt = new Date().toISOString();
        // const url = `${server_protocol}://${dt}:${id}@${c.ip ||
        //     '0'}:${server_port}`;
        const url = `${server_protocol}://${c.ip}:${server_port}`;
        setConnection({
            url,
            timestamp: dt,
        });
    }

    function disconnectWebSocket() {
        // console.log('close!!');
        setConnection({
            url: default_server,
        });
    }

    function handleWSMessage() {
        return null;
    }

    useEffect(() => {
        loadRobot();
        return () => {
            mountedRef.current = false;
        };
    }, [id]);

    useEffect(() => {
        handleWSMessage();
    }, [lastMessage]);

    useEffect(() => {
        const app_list = Object.keys(robotApps) || [];
        setAppType(app_list[0] || '');
    }, [robotApps]);

    useEffect(() => {
        const app_id =
            robotApps && robotApps[appType] ? robotApps[appType] : '';
        loadApplication(app_id);
    }, [robotApps, appType]);

    useEffect(() => {
        if (appType === 'conversation') {
            getConversationContents();
        }
    }, [appType, application]);
    // useEffect(() => {
    //     if (lastMessage !== null) {
    //         setMessageHistory([lastMessage, ...messageHistory]);
    //     }
    // }, [lastMessage]);

    function sendWSMessage(type, body) {
        const msg = {
            topic: type,
            sender: 'user',
            client: user.id,
            message: {
                message_type: type,
                sender: 'user',
                user: {
                    id: user.id,
                    name: user.name,
                },
                ...body,
            },
        };
        sendJsonMessage(msg);
    }

    function sendWSAction(action_type, action_body) {
        // console.log('🚀 ~ sendWSAction ~ action_body:', action_body);
        sendWSMessage('actions', {
            action_type,
            data: action_body,
        });
    }

    function sendPresentationModeMessage(language, msg) {
        sendWSAction('speak_message', {
            language,
            text: msg,
            timestamp: new Date().toISOString(),
        });
        setNewMessage('');
    }

    function renderContentShortcut(shortcut, lang) {
        const l_message = shortcut[lang] ? shortcut[lang] : null;

        const is_shortcut = !!shortcut.tag;
        return l_message ? (
            <div
                className="col-md-4 col-6 mb-2 px-1"
                key={`message_${shortcut.item_id}`}
            >
                <Tooltip
                    arrow
                    placement="top"
                    title={l_message}
                    classes={{ tooltip: classes.tooltip }}
                    // disableHoverListener={!is_shortcut}
                >
                    <MessageShortcut
                        className="d-flex flex-nowrap shortcut-item mb-3"
                        bold={is_shortcut}
                        onClick={() => {
                            sendPresentationModeMessage(lang, l_message);
                        }}
                    >
                        <span>
                            {is_shortcut ? `# ${shortcut.tag}` : l_message}
                        </span>
                    </MessageShortcut>
                </Tooltip>
            </div>
        ) : null;
    }

    function renderConversationApp() {
        const app_settings = application.settings || {};
        const lang_settings = app_settings.language || {};
        const def_lang = lang_settings.main || 'pt_BR';
        const presentation_lang = currLanguage || def_lang;

        const presentation_data = presentationContent.content || {};
        const presentation_texts = presentation_data.texts || [];
        const filtered_texts = presentation_texts.filter(t => {
            return t[presentation_lang];
        });

        const hide_shortcuts = options.hide_presentation_shortcuts || false;
        const ShortcutsIcon = hide_shortcuts ? MdExpandMore : MdExpandLess;

        return (
            <>
                <PresentationModeArea>
                    <div className="mt-3 mb-3 text-center">
                        <h4>PRESENTATION MODE (LOCAL NETWORK)</h4>
                    </div>
                    <div className="row col-12 mt-4">
                        <div className="col-md-6 col-12">
                            <Button
                                fullWidth
                                className="mb-3"
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    sendWSAction('switch_mode', {
                                        mode: 'conversation',
                                    })
                                }
                                disabled={readyState !== ReadyState.OPEN}
                            >
                                EXIT PRESENTATION MODE
                            </Button>
                        </div>
                        <div className="col-md-6 col-12">
                            <Button
                                fullWidth
                                className="mb-3"
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    sendWSAction('switch_mode', {
                                        mode: 'presentation',
                                    })
                                }
                                disabled={readyState !== ReadyState.OPEN}
                            >
                                ENTER PRESENTATION MODE
                            </Button>
                        </div>
                    </div>
                    <BorderedArea className="mb-3">
                        <div className="col-12 row mb-3 space-between">
                            <h4 className="mb-0">MESSAGE SHORTCUTS</h4>
                            <IconParameter style={{ justifyContent: 'right' }}>
                                <ShortcutsIcon
                                    size={26}
                                    onClick={() =>
                                        setOptions({
                                            ...options,
                                            hide_presentation_shortcuts: !hide_shortcuts,
                                        })
                                    }
                                />
                            </IconParameter>
                        </div>
                        {hide_shortcuts ? null : (
                            <div className="col-12 row pb-0 px-1 mb-0">
                                {filtered_texts.map(t => {
                                    return renderContentShortcut(
                                        t,
                                        presentation_lang
                                    );
                                })}
                            </div>
                        )}
                    </BorderedArea>
                    <div className="row col-12 mb-2 presentation-input">
                        <div className="no-margin">
                            <Select
                                autoWidth
                                value={presentation_lang}
                                onChange={event =>
                                    setCurrLanguage(event.target.value)
                                }
                            >
                                {(lang_settings.list || []).map(l => {
                                    const l_split = l.split('_');
                                    const l_country = l_split[1]
                                        ? l_split[1].toLowerCase()
                                        : 'br';
                                    return (
                                        <MenuItem key={`lang_${l}`} value={l}>
                                            <IconButton className="icon-btn">
                                                <i
                                                    className={`flag flag-24 flag-${l_country}`}
                                                />
                                            </IconButton>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="no-margin" style={{ flex: 1 }}>
                            <TextareaAutosize
                                className="border-0 form-control chat-textarea"
                                value={newMessage}
                                color="primary"
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        sendPresentationModeMessage(
                                            presentation_lang,
                                            newMessage
                                        );
                                    }
                                }}
                                onChange={event =>
                                    setNewMessage(event.target.value)
                                }
                            />
                        </div>
                        <div className="no-margin">
                            <IconButton
                                onClick={() =>
                                    sendPresentationModeMessage(
                                        presentation_lang,
                                        newMessage
                                    )
                                }
                            >
                                <MdSend color="#fff" size={20} />
                            </IconButton>
                        </div>
                    </div>
                </PresentationModeArea>
            </>
        );
    }

    function renderInternalPage(page) {
        const pages = {
            conversation: renderConversationApp(),
        };

        return (
            <BorderedArea style={{ flex: 1 }}>
                {pages[page] || null}
            </BorderedArea>
        );
    }

    function renderApplications() {
        const tab_settings = Object.keys(robotApps).map(a => {
            return {
                label: a,
                value: a,
            };
        });

        return (
            <div style={{ width: '100%' }} className="mb-5">
                <BorderedArea style={{ width: '100%' }}>
                    <h3 className="mb-0">APPLICATIONS</h3>
                    {tab_settings.length > 1 ? (
                        <Tabs
                            indicatorColor="primary"
                            value={appType || false}
                            onChange={handleChangeTab}
                            className={classes.tabs}
                            centered
                        >
                            {tab_settings.map(s => {
                                return (
                                    <Tab
                                        key={s.value}
                                        label={s.label}
                                        value={s.value}
                                    />
                                );
                            })}
                        </Tabs>
                    ) : null}
                </BorderedArea>
                {renderInternalPage(appType)}
            </div>
        );
    }

    function renderConnectionButton() {
        const btn_props = {
            [ReadyState.OPEN]: {
                label: 'DISCONNECT',
                action: () => {
                    disconnectWebSocket();
                },
            },
            [ReadyState.CLOSED]: {
                label: 'CONNECT',
                action: () =>
                    connectWebSocket({
                        ip: robotIP,
                    }),
            },
        }[readyState];

        const Icon = readyState === ReadyState.CLOSED ? MdLink : MdLinkOff;

        return btn_props ? (
            <Button
                margin="normal"
                fullWidth
                variant="contained"
                color="primary"
                size="medium"
                onClick={btn_props.action}
                disabled={readyState === ReadyState.CONNECTING}
            >
                <Icon size={24} />
            </Button>
        ) : (
            'LOADING'
        );
    }

    function renderConnectionArea() {
        return (
            <BorderedArea className="col-12 mb-3">
                <h3>CONNECTION</h3>
                <div className="row space-between mb-0">
                    <div className="col-md-10 col-8 pl-0">
                        {readyState === ReadyState.OPEN ? (
                            <span>CONNECTED: {robotIP}</span>
                        ) : (
                            <TextField
                                label="Robot IP"
                                fullWidth
                                value={robotIP || ''}
                                onChange={event =>
                                    setRobotIP(event.target.value)
                                }
                                variant="outlined"
                                disabled={readyState === ReadyState.CONNECTING}
                            />
                        )}
                    </div>
                    <div className="col-md-2 col-4 pr-0">
                        {renderConnectionButton()}
                    </div>
                </div>
            </BorderedArea>
        );
    }

    return (
        <Wrapper>
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <h2 className="mt-3 mb-3" style={{ color: '#fff' }}>
                        LOCAL NETWORK COMMANDS
                    </h2>
                    <h3
                        className="mb-3"
                        style={{ color: '#fff', fontWeight: 'bold' }}
                    >
                        {robot && robot.name
                            ? robot.name.toUpperCase()
                            : 'ROBOT'}
                    </h3>

                    <Column>
                        {renderConnectionArea()}

                        {readyState === ReadyState.OPEN
                            ? renderApplications()
                            : null}
                    </Column>
                </>
            )}
        </Wrapper>
    );
}

Local.propTypes = {
    match: PropTypes.object.isRequired,
};

Local.defaultProps = {};
