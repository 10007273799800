/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
// import { MdStarBorder, MdStar } from 'react-icons/md';
import React, { useState, useEffect } from 'react';
import InjectIntl from 'react-intl-inject';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import ExitDialog from '~/components/ExitDialog';
import FormCheckList from '~/components/Form/CheckList';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';

import { Column, SettingsArea, ParameterArea } from '../styles';
import Preview from './Preview';

const contact_label = {
    none: 'page.applications.telepresence.preview.call.disabled',
    broadcast: 'page.applications.telepresence.preview.call.all',
};

export default function ContactList({
    app,
    requestError,
    updateObject,
    handleChangeTab,
    needSaving,
    setNeedSaving,
}) {
    const settings = useSelector(state => state.settings || null);

    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_contacts = lng_labels[lang];

    const { settings: appConfig } = app;
    const appSettings =
        appConfig && appConfig.settings ? appConfig.settings : {};

    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState(appSettings);

    const [lists, setLists] = useState([]);
    const [currList, setCurrList] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [listContacts, setListContacts] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const appLists =
        config && config.contact_lists
            ? config.contact_lists
            : { main: null, lists: [] };

    const { allow_requests, request_mode, broadcast_text } = config;
    const { main, lists: checked } = appLists;

    const mainContact = main
        ? contacts.find(c => {
              return c.id === main;
          })
        : null;

    async function loadContacts() {
        setIsLoading(true);
        await api
            .get(`contacts`)
            .then(async response => {
                const list = response.data.map(l => {
                    return {
                        ...l,
                        group_name: l.group ? l.group.name : '',
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${l.source}`}
                            />
                        ),
                    };
                });
                setContacts(list);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadLists() {
        setIsLoading(true);
        await api
            .get(`contact_lists`)
            .then(async response => {
                const list = response.data.map(l => {
                    return {
                        ...l,
                        group_name: l.group ? l.group.name : '',
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${l.source}`}
                            />
                        ),
                    };
                });
                setLists(list);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadListContacts(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`contact_lists/${_id}/contacts`)
                .then(async response => {
                    const { contacts: l_contacts } = response.data;
                    const l_contact = l_contacts.map(c => {
                        return {
                            ...c,
                            source_type: (
                                <LocaleMessage
                                    msg={`list.contact.source.${c.source}`}
                                />
                            ),
                        };
                    });
                    setListContacts(l_contact);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        } else {
            setListContacts([]);
        }
    }

    function setListSettings(key, val) {
        setConfig({
            ...config,
            contact_lists: {
                ...appLists,
                [key]: val,
            },
        });
        setNeedSaving(true);
    }

    function handleSubmit() {
        updateObject({ ...app, settings: config });
    }

    function handleViewList(value) {
        setCurrList(currList === value ? null : value);
    }

    function buildConfirmDialog() {
        return (
            <ExitDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                destination="/tools/contacts"
            />
        );
    }

    useEffect(() => {
        loadLists();
        loadContacts();
    }, []);

    useEffect(() => {
        loadListContacts(currList);
    }, [currList]);

    function renderListConfig() {
        const options = lists.map(l => {
            return {
                id: l.id,
                name: l.name,
                subtitle: (
                    <span>
                        {`${l.contact_count} `}
                        <LocaleMessage msg="table.headers.contacts" />
                    </span>
                ),
            };
        });

        return (
            <ParameterArea>
                <div className="col-12 mt-3 mb-3">
                    <LocaleMessage msg="label.settings.app.telepresence.contacts" />
                    <br />
                    <FormCheckList
                        listKey="lists"
                        onItemClick={l_id => handleViewList(l_id)}
                        settings={appLists}
                        options={options}
                        secondaryLabelKey="subtitle"
                        onChange={l => {
                            setConfig({
                                ...config,
                                contact_lists: {
                                    ...appLists,
                                    ...l,
                                },
                            });
                            setNeedSaving(true);
                        }}
                    />
                </div>
            </ParameterArea>
        );
    }

    function renderMainContact() {
        const c_list = contacts.map(c => {
            return {
                value: c.id,
                label: c.name,
            };
        });

        const contact_list = [
            {
                value: 'false',
                label:
                    lng_contacts && lng_contacts[contact_label.none]
                        ? lng_contacts[contact_label.none]
                        : '',
            },
            {
                value: '*',
                label:
                    lng_contacts && lng_contacts[contact_label.broadcast]
                        ? lng_contacts[contact_label.broadcast]
                        : '',
            },
            ...c_list,
        ];

        return (
            <ParameterArea>
                <div className="col-12 mt-3 mb-3">
                    <FormSelect
                        options={contact_list}
                        optionKey="value"
                        labelKey="label"
                        label={
                            <LocaleMessage msg="page.applications.telepresence.contacts.main" />
                        }
                        onChange={value => {
                            setListSettings('main', value);
                        }}
                        value={
                            contacts && contacts.length > 0 && main
                                ? main
                                : 'false'
                        }
                        helper={
                            <LocaleMessage msg="page.applications.telepresence.contacts.main.helper" />
                        }
                    />
                </div>
                {main && main === '*' ? (
                    <div className="col-12">
                        <InjectIntl>
                            {() => (
                                <TextField
                                    className="mb-3"
                                    label={
                                        <LocaleMessage msg="page.applications.telepresence.form.request.text" />
                                    }
                                    fullWidth
                                    value={broadcast_text || ''}
                                    onChange={event => {
                                        setConfig({
                                            ...config,
                                            broadcast_text: event.target.value,
                                        });
                                        setNeedSaving(true);
                                    }}
                                    // placeholder={intl.formatMessage({
                                    //     id:
                                    //         'page.applications.telepresence.preview.call.broadcast',
                                    // })}
                                />
                            )}
                        </InjectIntl>
                    </div>
                ) : null}
            </ParameterArea>
        );
    }

    const checkedLists = lists.filter(l => {
        return checked.includes(l.id);
    });

    return (
        <Column>
            {dialogOpen ? buildConfirmDialog() : null}
            <SettingsArea className="row col-12 mt-5 mb-5">
                {allow_requests ? (
                    <>
                        {request_mode === 'contact' ? (
                            <>
                                <div
                                    className="col-md-6 col-12 "
                                    style={{ margin: '20px' }}
                                >
                                    {isLoading ? (
                                        <Splash />
                                    ) : (
                                        <>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    padding: '5px 20px',
                                                }}
                                                onClick={() =>
                                                    setDialogOpen(true)
                                                }
                                            >
                                                <LocaleMessage msg="page.applications.telepresence.contacts.edit" />
                                            </Button>
                                            {renderMainContact()}
                                            {renderListConfig()}
                                        </>
                                    )}
                                </div>
                                <div className="col-md-5 col-12 container">
                                    <LocaleMessage msg="label.preview" />
                                    <Preview
                                        configuration={config}
                                        onCancelClick={e =>
                                            handleChangeTab(e, 'settings')
                                        }
                                        currList={currList}
                                        setCurrList={l => setCurrList(l)}
                                        lists={checkedLists}
                                        contacts={listContacts}
                                        mainContact={{
                                            ...mainContact,
                                            value: main,
                                            broadcast_text,
                                        }}
                                    />
                                </div>
                                <Button
                                    className="col-md-8 col-12 p-3 mt-5"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    disabled={!needSaving}
                                    onClick={() => handleSubmit()}
                                >
                                    <LocaleMessage msg="button.save" />
                                </Button>
                            </>
                        ) : (
                            <LocaleMessage msg="page.applications.telepresence.contacts.broadcast" />
                        )}
                    </>
                ) : (
                    <LocaleMessage msg="page.applications.telepresence.contacts.not_available" />
                )}
            </SettingsArea>
        </Column>
    );
}

ContactList.defaultProps = {};

ContactList.propTypes = {
    app: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
    handleChangeTab: PropTypes.func.isRequired,
    needSaving: PropTypes.bool.isRequired,
    setNeedSaving: PropTypes.func.isRequired,
};
