import { takeLatest, put, all } from 'redux-saga/effects';

import history from '~/services/history';

import { changeAppSuccess, changeAppFailure } from './actions';

export function* changeApp({ payload }) {
    const { app } = payload;
    try {
        const goTo = app.home;
        setTimeout(() => {
            history.push(goTo);
        }, 300);
        yield put(changeAppSuccess(app));
    } catch (err) {
        yield put(changeAppFailure());
    }
}

export default all([takeLatest('@application/CHANGE_APP_REQUEST', changeApp)]);
