import produce from 'immer';

const INITIAL_STATE = {
    profile: {},
    groups: [],
};

export default function user(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@auth/SIGN_IN_SUCCESS': {
                draft.profile = action.payload.user.profile;
                break;
            }
            case '@auth/SESSION_REFRESH': {
                draft.profile = action.payload.profile;
                break;
            }
            case '@user/UPDATE_PROFILE_SUCCESS': {
                draft.profile = action.payload.profile;
                break;
            }
            case '@auth/SIGN_OUT': {
                draft.profile = {};
                draft.groups = [];
                break;
            }
            case '@user/LOAD_GROUPS_SUCCESS': {
                draft.groups = action.payload.groups;
                break;
            }
            default:
        }
    });
}
