/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdSettings,
    MdDelete,
    MdDeleteSweep,
    MdAdd,
    MdCheck,
    MdOutlineFileDownload,
} from 'react-icons/md';
import * as MaterialDesign from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import FormPasswordInput from '~/components/Form/PasswordInput';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import generateDownloadableFile from '~/util/GenerateDownloadableFile';
import getDateLocale from '~/util/GetDateLocale';

import { ParameterArea, NoAction } from './styles';

export default function PluginconfigForm(props) {
    const dispatch = useDispatch();

    const { match } = props;
    const { id } = match.params;
    const [configOperation, setConfigOperation] = useState('create');

    const [configs, setConfigs] = useState([]);
    const [actions, setActions] = useState([]);
    const [configurableActions, setConfigurableActions] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [settings, setSettings] = useState([]);
    const [parameters, setParameters] = useState({});

    const [currItem, setCurrItem] = useState(null);
    const [currAction, setCurrAction] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const [plugintype, setPlugintype] = useState(null);
    const [pluginSettings, setPluginSettings] = useState(null);
    const [pluginAvailability, setPluginAvailability] = useState({});
    const [pluginConfig, setPluginConfig] = useState({});
    const [formOpen, setFormOpen] = useState(false);
    const [templateFile, setTemplateFile] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(false);

    const user_settings = useSelector(state => state.settings || null);

    const { admin_groups, active } = user_settings;
    const availability = lists.plugin_availability_list;
    const date_loc = getDateLocale(user_settings);

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function actionSetup(val) {
        const newCurrAction = actions.find(a => {
            return a.id === val;
        });

        setCurrAction(newCurrAction);
    }

    function setupPage(_actions) {
        const actionList = _actions.filter(a => {
            return a.require_config;
        });
        setConfigurableActions(actionList);
    }

    async function loadPlugintype() {
        setIsLoading(true);
        await api
            .get(`pluginspaces/plugins/${id}`)
            .then(response => {
                const { data } = response;
                setPlugintype(data);
                setPluginSettings(data.settings);
                setPluginAvailability(
                    data.settings && data.settings.availability
                        ? data.settings.availability
                        : {}
                );
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function loadConfigs() {
        await api
            .get(`pluginconfigs?type=${id}`)
            .then(response => {
                const data = response.data.map(c => {
                    const updated = new Date(c.updated);
                    return {
                        ...c,
                        action_name: c.action ? (
                            <LocaleMessage msg={c.action.name} />
                        ) : (
                            '---'
                        ),
                        group_id: c.group && c.group.id ? c.group.id : null,
                        group_name:
                            c.group && c.group.name ? c.group.name : null,
                        plugintemplate_name:
                            c.plugintemplate && c.plugintemplate.name ? (
                                <LocaleMessage msg={c.plugintemplate.name} />
                            ) : (
                                '---'
                            ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setConfigs(data);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadPluginconfig(_id) {
        setInternalLoading(true);
        await api
            .get(`pluginconfigs/${_id}`)
            .then(async response => {
                const config = response.data;
                setPluginConfig({
                    name: config.name,
                    plugintype_id: id,
                    action_id:
                        config.action && config.action.id
                            ? config.action.id
                            : null,
                    group_id:
                        config.group && config.group.id
                            ? config.group.id
                            : null,
                    group_name:
                        config.group && config.group.name
                            ? config.group.name
                            : null,
                    plugintemplate_id:
                        config.plugintemplate && config.plugintemplate.id
                            ? config.plugintemplate.id
                            : null,
                    type:
                        config.plugintemplate && config.plugintemplate.type
                            ? config.plugintemplate.type
                            : null,
                });

                setParameters(config.parameters);
            })
            .catch(error => requestError(error));
        setInternalLoading(false);
    }

    async function loadActions() {
        await api
            .get(`pluginspaces/actions?type=${id}`)
            .then(response => {
                const r = response.data;
                const list = r.filter(a => {
                    return a.status;
                });

                setupPage(list);
                setActions(list);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadTemplates(_id) {
        setInternalLoading(true);
        await api
            .get(`integrations?action_id=${_id}`)
            .then(response => {
                const t = response.data;
                setTemplates(t);
                setInternalLoading(false);
                if (t && t.length === 1) {
                    if (t[0] && !pluginConfig.plugintemplate_id) {
                        setPluginConfig({
                            ...pluginConfig,
                            plugintemplate_id: t[0].id,
                        });
                    }
                }
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadConfigs();
        loadActions();
        loadPlugintype();
    }, [id]);

    function resetPluginconfig() {
        setPluginConfig({
            plugintype_id: id,
            group_id: active && active.id ? active.id : null,
        });
        setParameters({});
        setTemplateFile({});
    }

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const fileContents = e.target.result;
            const template = JSON.parse(fileContents);

            setTemplateFile({
                id: fileObj.name,
                name: fileObj.name,
            });

            if (template.plugintype_id && id === template.plugintype_id) {
                setPluginConfig({
                    ...template,
                    ...pluginConfig,
                });

                setParameters(template.parameters);
            } else {
                toast.error(
                    <LocaleMessage msg="page.plugins.form.wrong_type" />
                );
                resetPluginconfig();
            }
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        reader.onload = fileloaded;
        reader.readAsText(fileObj);
    };

    async function deletePluginconfigMulti() {
        const delete_list = JSON.stringify(selectedRows);
        await api
            .delete(`pluginconfigs?list=${delete_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.plugins.form.action_removed" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deletePluginconfig(item) {
        await api
            .delete(`pluginconfigs/${item}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.plugins.form.action_removed" />
                );
            })
            .catch(error => requestError(error));
    }

    async function removePluginconfigs() {
        setIsLoading(true);
        if (deleteOpen === 'single') {
            await deletePluginconfig(currItem);
        } else {
            await deletePluginconfigMulti();
        }
        await loadConfigs(id);
        setDeleteOpen('');
        setSelectedRows([]);
        setIsLoading(false);
    }

    async function handleConfigSubmit(event) {
        event.preventDefault();
        setFormOpen(false);
        setIsLoading(true);
        const data = { ...pluginConfig, parameters };

        if (configOperation === 'create') {
            await api
                .post(`pluginconfigs`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.plugins.form.config_added" />
                    );
                    loadConfigs();
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`pluginconfigs/${currItem}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.plugins.form.config_updated" />
                    );
                    loadConfigs();
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    async function handleExportConfig(event, _id) {
        setInternalLoading(true);
        toast.info(<LocaleMessage msg="message.generating_file" />);
        await api
            .get(`pluginconfigs/${_id}/export`)
            .then(async response => {
                const config = response.data;

                // Prepare the file
                const timestamp = new Date().toISOString();
                const filename = `PLUGINCONFIG-${config.name}-${timestamp}.json`;

                generateDownloadableFile(event, {
                    filename,
                    data: config,
                });
            })
            .catch(error => requestError(error));
        setInternalLoading(false);
    }

    function handleDialogsClose(event) {
        event.preventDefault();
        setDeleteOpen('');
        setSelectedRows([]);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setSelectedRows([]);
        setDeleteOpen('single');
    }

    function handleDeleteMultiOpen(event) {
        setCurrItem(null);
        event.preventDefault();
        setDeleteOpen('multi');
    }

    function handleTableRowClick(event, _id) {
        event.stopPropagation();
        setSelectedRows([]);
        setConfigOperation('update');
        setCurrItem(_id);
        const conf = configs.find(c => {
            return c.id === _id;
        });

        if (conf && conf.plugintemplate) {
            const t = conf.plugintemplate || {};
            switch (t.type) {
                case 'parameters': {
                    loadPluginconfig(_id);
                    setFormOpen(true);
                    break;
                }
                case 'route': {
                    const { content } = t;
                    if (!content) {
                        toast.error(
                            <LocaleMessage msg="page.plugins.form.invalid_template" />
                        );
                        break;
                    }
                    const { route } = content;
                    const parsed_route = route
                        .replace(':plugin_id', id)
                        .replace(':id', _id);
                    history.push(parsed_route);
                    break;
                }
                default:
                    break;
            }
        } else {
            toast.error(
                <LocaleMessage msg="page.plugins.form.invalid_template" />
            );
        }
    }

    function handleNewPluginconfig() {
        resetPluginconfig();
        setSelectedRows([]);
        setConfigOperation('create');
        setFormOpen(true);
    }

    function handleActionChange(val) {
        actionSetup(val);
        if (val) {
            loadTemplates(val);
        }
    }

    function handleTemplateChange(val) {
        const plugintemplate = templates.find(t => {
            return t.id === val;
        });

        if (!plugintemplate) return;

        setPluginConfig({
            ...pluginConfig,
            type: plugintemplate.type,
        });

        const template_params = plugintemplate.content;
        const default_params = {};

        Object.keys(template_params).forEach(p => {
            const param = template_params[p];
            default_params[param.name] = param.default_value;
        });

        setSettings(template_params);
        setParameters({
            ...default_params,
            ...parameters,
        });
    }

    useEffect(() => {
        handleActionChange(pluginConfig.action_id);
    }, [pluginConfig.action_id]);

    useEffect(() => {
        handleTemplateChange(pluginConfig.plugintemplate_id);
    }, [templates, pluginConfig.plugintemplate_id]);

    useEffect(() => {
        if (configOperation === 'create') {
            if (!pluginConfig.use_template) {
                resetPluginconfig();
            }
        }
    }, [configOperation, pluginConfig.use_template]);

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function openSettings(event, _id) {
        loadPluginconfig(_id);
        setCurrItem(_id);
        setConfigOperation('update');
        setFormOpen(true);
    }

    function buildListView() {
        const headCells = [
            ...(active && active.id === '*'
                ? [
                      {
                          id: 'group_name',
                          label: <LocaleMessage msg="table.headers.group" />,
                      },
                  ]
                : []),
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.config_name" />,
            },
            {
                id: 'action_name',
                label: <LocaleMessage msg="table.headers.action_name" />,
            },
            {
                id: 'plugintemplate_name',
                label: <LocaleMessage msg="table.headers.template_name" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'export',
                label: <LocaleMessage msg="button.export_settings" />,
                icon: <MdOutlineFileDownload />,
                action: handleExportConfig,
            },
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: openSettings,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={configs}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div className="col-4" style={{ padding: '0px' }}>
                                <Button
                                    onClick={() => handleNewPluginconfig()}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<MdAdd />}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 20px',
                                    }}
                                >
                                    <LocaleMessage msg="page.plugins.form.add_config" />
                                </Button>
                            </div>
                        </>
                    }
                    sortable
                    selectable
                    selectedActions={selAction}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={!!deleteOpen}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage msg="page.plugins.form.delete_action_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogsClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removePluginconfigs(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildParameterList() {
        return Object.keys(settings).map(idx => {
            const item = settings[idx];
            const name = item.name ? item.name : '';
            const password = name.toUpperCase().indexOf('PASSWORD') !== -1;

            const TextInput = password ? FormPasswordInput : TextField;
            return (
                <div className="col-12 mb-3" key={item.item_id}>
                    <TextInput
                        fullWidth
                        label={<LocaleMessage msg={item.description} />}
                        value={
                            parameters && parameters[item.name]
                                ? parameters[item.name]
                                : ''
                        }
                        onChange={event =>
                            setParameters({
                                ...parameters,
                                [item.name]: event.target.value,
                            })
                        }
                    />
                </div>
            );
        });
    }

    function buildParameterForm() {
        const { type } = pluginConfig;

        if (type === 'parameters') {
            return internalLoading ? (
                <Splash />
            ) : (
                <div className="col-12 mb-3" style={{ padding: '20px 15px' }}>
                    <h3>
                        <LocaleMessage msg="label.form.parameters" />
                    </h3>
                    <ParameterArea className="mt-1">
                        {buildParameterList()}
                    </ParameterArea>
                </div>
            );
        }
        return null;
    }

    function buildFormDialog() {
        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {configOperation === 'create' ? (
                        <LocaleMessage msg="page.plugins.form.create_config.title" />
                    ) : (
                        <LocaleMessage msg="page.plugins.form.edit_config.title" />
                    )}
                </DialogTitle>
                <DialogContent>
                    {internalLoading ? (
                        <Splash />
                    ) : (
                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="label.form.group" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            pluginConfig &&
                                            pluginConfig.group_id
                                                ? pluginConfig.group_id
                                                : active.id
                                        }
                                        onChange={event =>
                                            setPluginConfig({
                                                ...pluginConfig,
                                                group_id: event.target.value,
                                            })
                                        }
                                        disabled={
                                            (active && active.id !== '*') ||
                                            configOperation === 'update'
                                        }
                                    >
                                        {admin_groups ? (
                                            admin_groups.map(g => (
                                                <MenuItem
                                                    value={g.id}
                                                    key={g.id}
                                                >
                                                    {g.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem
                                                value={active ? active.id : ''}
                                            >
                                                {active ? active.name : ''}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.plugins.form.config_name" />
                                    }
                                    fullWidth
                                    value={
                                        pluginConfig && pluginConfig.name
                                            ? pluginConfig.name
                                            : ''
                                    }
                                    onChange={event =>
                                        setPluginConfig({
                                            ...pluginConfig,
                                            name: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            {configOperation === 'create' ? (
                                <>
                                    <div className="col-12 mb-1">
                                        <FormSwitch
                                            value={
                                                pluginConfig.use_template ||
                                                false
                                            }
                                            onChange={event =>
                                                setPluginConfig({
                                                    ...pluginConfig,
                                                    use_template:
                                                        event.target.checked,
                                                })
                                            }
                                            label={
                                                <LocaleMessage msg="page.plugins.form.template.use" />
                                            }
                                        />
                                    </div>
                                    {pluginConfig.use_template ? (
                                        <div
                                            className="col-12"
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 'max-content',
                                                }}
                                                className="mb-3"
                                            >
                                                <FileInput
                                                    defaultValue={
                                                        templateFile || {}
                                                    }
                                                    onFileUpload={onFileUpload}
                                                    multiple={false}
                                                    text={
                                                        <LocaleMessage msg="label.form.upload_file" />
                                                    }
                                                    type="json"
                                                    showFooter={false}
                                                    showIcon={false}
                                                />
                                            </div>
                                            <div className="mt-1 mb-3">
                                                <LocaleMessage msg="page.plugins.form.template.warning" />
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            <div className="col-md-6 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.plugins.form.action" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            pluginConfig &&
                                            pluginConfig.action_id
                                                ? pluginConfig.action_id
                                                : ''
                                        }
                                        onChange={event =>
                                            setPluginConfig({
                                                ...pluginConfig,
                                                action_id: event.target.value,
                                            })
                                        }
                                        disabled={
                                            configOperation === 'update' ||
                                            pluginConfig.use_template
                                        }
                                    >
                                        {configurableActions.map(a => (
                                            <MenuItem value={a.id} key={a.id}>
                                                <LocaleMessage msg={a.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            {currAction && currAction.require_config ? (
                                <>
                                    <div className="col-md-6 col-12 mb-3">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.plugins.form.template" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    pluginConfig &&
                                                    pluginConfig.plugintemplate_id
                                                        ? pluginConfig.plugintemplate_id
                                                        : ''
                                                }
                                                onChange={event =>
                                                    setPluginConfig({
                                                        ...pluginConfig,
                                                        plugintemplate_id:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    pluginConfig.use_template
                                                }
                                            >
                                                {templates.map(t => (
                                                    <MenuItem
                                                        value={t.id}
                                                        key={t.id}
                                                    >
                                                        <LocaleMessage
                                                            msg={t.name}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {pluginConfig.plugintemplate_id
                                        ? buildParameterForm()
                                        : null}
                                </>
                            ) : null}
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleConfigSubmit(event)}
                        color="primary"
                    >
                        {configOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildPluginDetails() {
        const mdIcon =
            MaterialDesign[
                pluginSettings.icon ? pluginSettings.icon : 'MdFullscreen'
            ];
        return (
            <div className="col-md-10 col-12 mb-5">
                <CardSideBordered title={plugintype.name} Icon={mdIcon}>
                    <>
                        <div style={{ padding: '15px' }}>
                            <p>
                                <LocaleMessage msg="page.plugins.form.used_content" />
                            </p>
                            <List>
                                {availability.map(item => {
                                    return pluginAvailability &&
                                        pluginAvailability[item.value] ? (
                                        <ListItem
                                            key={item.value}
                                            role={undefined}
                                            dense
                                            button
                                        >
                                            <ListItemIcon>
                                                <MdCheck size={24} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <LocaleMessage
                                                        msg={item.label_code}
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    ) : null;
                                })}
                            </List>
                        </div>
                    </>
                </CardSideBordered>
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.plugins.form.create.title" />}
            currentPage={plugintype ? plugintype.name : ''}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteOpen ? buildDeleteDialog() : null}
                {formOpen ? buildFormDialog() : null}
                {pluginSettings ? buildPluginDetails() : null}

                <div className="col-md-10 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.plugins.form.create.title" />
                        }
                        Icon={MdSettings}
                        hide
                    >
                        <>
                            {configurableActions &&
                            configurableActions.length > 0 ? (
                                <>{buildListView()}</>
                            ) : (
                                <NoAction>
                                    <LocaleMessage msg="page.plugins.form.no_actions" />
                                </NoAction>
                            )}
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

PluginconfigForm.propTypes = {
    match: PropTypes.object.isRequired,
};
