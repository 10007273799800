/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea, IconParameter } from './styles';

export default function LanguageInputs({
    open,
    title,
    languages,
    texts,
    onChange,
    margin,
}) {
    const [show, setShow] = useState(open);

    function buildLanguageInput(lang) {
        return (
            <div className="col-12 mb-3" key={`input_${lang}`}>
                <TextField
                    label={<LocaleMessage msg={`list.languages.${lang}`} />}
                    fullWidth
                    value={texts[lang] || ''}
                    onChange={event =>
                        onChange({
                            ...texts,
                            [lang]: event.target.value,
                        })
                    }
                />
            </div>
        );
    }

    return (
        <ParameterArea className={`col-12 justify-content-center ${margin}`}>
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter
                    className="col-2"
                    style={{ justifyContent: 'left' }}
                >
                    {show ? (
                        <MdExpandLess
                            size={22}
                            onClick={() => setShow(false)}
                        />
                    ) : (
                        <MdExpandMore size={22} onClick={() => setShow(true)} />
                    )}
                </IconParameter>
                <span>{title}</span>
                <IconParameter
                    className="col-2"
                    style={{ justifyContent: 'right' }}
                />
            </div>

            {show ? (
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {languages.map(l => {
                        return buildLanguageInput(l);
                    })}
                </div>
            ) : null}
        </ParameterArea>
    );
}

LanguageInputs.defaultProps = {
    languages: [],
    texts: {},
    margin: '',
    open: false,
    title: '',
};

LanguageInputs.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    texts: PropTypes.object,
    languages: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    margin: PropTypes.string,
    open: PropTypes.bool,
};
