import { select, takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/pluginbot-api';
import checkPermission from '~/util/CheckPermission';

import { loadGroupsSuccess, loadGroupsFailure } from './actions';

export function* loadGroups() {
    try {
        const response = yield call(api.get, `groups/me`);
        const { groups, admin } = response.data;

        const settings = yield select(state => state.settings);
        const { active } = settings;

        const user = yield select(state => state.user);
        const { profile } = user;
        const su = checkPermission(profile);

        let newActive = su ? { id: '*', name: 'all' } : { id: null, name: '' };
        const g_list = su ? admin : groups;
        if (active) {
            if (
                g_list.find(g => {
                    return (
                        g.id === active.id && g.permission === active.permission
                    );
                })
            ) {
                newActive = active;
            }
        }

        yield put(loadGroupsSuccess({ groups, admin, newActive }));
    } catch (err) {
        yield put(loadGroupsFailure());
    }
}

export default all([takeLatest('@user/LOAD_GROUPS_REQUEST', loadGroups)]);
