/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import IntlMessages from '../../util/IntlMessages';

export default function LocaleMessage(props) {
    const { msg } = props;
    return <IntlMessages id={msg} />;
}

LocaleMessage.defaultProps = {
    msg: 'default',
};

LocaleMessage.propTypes = {
    msg: PropTypes.string,
};
