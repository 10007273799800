import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }
`;

export const OptionArea = styled.div`
    width: 100%;

    .area-content {
        background-color: #eee;
        width: 100%;
        padding: 10px;
        margin: 10px 0px;
    }

    .option-row {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .option-card {
        margin: 10px 0px;
        width: 100%;
    }
`;

export const DeleteParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }
`;
