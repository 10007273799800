/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import {
    TotalContainer,
    InfoContainer,
    ChartsContainer,
    TableArea,
    RowArea,
} from '../style';

export default function ConversationReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const {
        active,
        colors,
        format: formatSettings,
        locale,
        dateOptions,
    } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(false);

    const [rawData, setRawData] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const [modes, setModes] = useState([]);
    const [currMode, setCurrMode] = useState('*');

    const lang_str = lng_labels[formatSettings ? formatSettings.code : 'pt_BR'];

    const headers_modes = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.mode" />,
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
            align: 'right',
        },
    ];

    const headers_file_commons = [
        {
            key: 'created_date',
            label: 'Date',
        },
        {
            key: 'created_time',
            label: 'Time',
        },
        {
            key: 'channel',
            label: 'Channel',
        },
        { key: 'group', label: 'Group' },
        { key: 'robot', label: 'Robot' },
        { key: 'robot_type', label: 'Robot Type' },
        {
            key: 'language',
            label: 'Language',
        },
    ];

    const headers_file_all = [
        ...headers_file_commons,
        { key: 'type_name', label: 'Type' },
        {
            key: 'message',
            label: 'Message',
        },
    ];

    const headers_file_conversation = [
        ...headers_file_commons,
        { key: 'dialog', label: 'Dialog' },
        {
            key: 'message',
            label: 'Message',
        },
        { key: 'answer', label: 'Answer' },
        { key: 'intent', label: 'Intent' },
        {
            key: 'subjects',
            label: 'Subjects',
        },
        {
            key: 'entities',
            label: 'Entities',
        },
        {
            key: 'values',
            label: 'Values',
        },
    ];

    const headers_file_presentation = [
        ...headers_file_commons,
        {
            key: 'message',
            label: 'Message',
        },
        {
            key: 'user_name',
            label: 'Sent By',
        },
    ];

    const headers_messages = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'channel_label',
            label: <LocaleMessage msg="table.headers.channel" />,
        },
        {
            id: 'language',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'type_name',
            label: <LocaleMessage msg="table.headers.type" />,
        },
        {
            id: 'message',
            label: <LocaleMessage msg="table.headers.message" />,
            width: '60%',
        },
    ];

    const headers_conversation = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'channel_label',
            label: <LocaleMessage msg="table.headers.channel" />,
        },
        {
            id: 'language',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'dialog',
            label: <LocaleMessage msg="table.headers.dialog" />,
        },
        {
            id: 'message',
            label: <LocaleMessage msg="table.headers.message" />,
            width: '30%',
        },
        {
            id: 'answer',
            label: <LocaleMessage msg="table.headers.answer" />,
            width: '40%',
        },
        {
            id: 'intent',
            label: <LocaleMessage msg="table.headers.intent" />,
        },
        {
            id: 'subjects',
            label: <LocaleMessage msg="table.headers.subjects" />,
        },
    ];

    const headers_presentation = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'language',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'message',
            label: <LocaleMessage msg="table.headers.message" />,
            width: '50%',
        },
        {
            id: 'user_name',
            label: <LocaleMessage msg="table.headers.sent_by" />,
        },
    ];

    const headers_subjects = [
        {
            id: 'value',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
        {
            id: 'dialog',
            label: <LocaleMessage msg="table.headers.dialog" />,
        },
        {
            id: 'subject',
            label: <LocaleMessage msg="table.headers.subjects" />,
        },
    ];

    function groupData(period, messages) {
        const {
            robot_types,
            robots,
            groups,
            dialogs,
            applications,
        } = counterData;

        const r_list = { ...robots };

        const total = messages ? messages.length : 0;
        const m_robots = [];
        const m_r_types = [];
        const m_groups = [];
        const m_dialogs = [];
        const m_applications = [];
        const m_subjects = [];
        const m_date = [];
        const m_time = [];
        const m_language = [];
        const test_label = (
            lang_str[`page.reports.messages.label.channel.test`] || ''
        ).toUpperCase();

        const mlist = messages.map((m, idx) => {
            const datetime = GetDateTimeLabel(new Date(m.created), {
                format: formatSettings,
            });
            const type = m.type || 'conversation';
            const channel =
                m.channel && type === 'conversation' ? m.channel : 'robot';

            const is_test = channel === 'test';

            const r_id = is_test ? 'test' : m.robot_id;
            const app_id = is_test ? 'test' : m.application_id;
            const rtype_id = is_test ? 'test' : m.robottype_id;

            if (!is_test && (!r_list[r_id] || r_list[r_id].code === '---')) {
                r_list[r_id] = {
                    id: r_id,
                    code: m.robot_code || '---',
                    name: '',
                };
            }

            const r_code = m.robot_code || '---';

            const r_type = robot_types[rtype_id];
            const r_type_name = r_type ? r_type.name : '---';

            const dialog = dialogs[m.dialog_id];

            const group = groups[m.group_id];

            const application = applications[app_id];
            const app_name = application ? application.name : '---';

            m_robots[r_id] = m_robots[r_id] ? m_robots[r_id] + 1 : 1;

            m_r_types[rtype_id] = m_r_types[rtype_id]
                ? m_r_types[rtype_id] + 1
                : 1;

            m_applications[app_id] = m_applications[app_id]
                ? m_applications[app_id] + 1
                : 1;

            m_groups[m.group_id] = m_groups[m.group_id]
                ? m_groups[m.group_id] + 1
                : 1;

            m_dialogs[m.dialog_id] = m_dialogs[m.dialog_id]
                ? m_dialogs[m.dialog_id] + 1
                : 1;

            m_date[datetime.date] = m_date[datetime.date]
                ? m_date[datetime.date] + 1
                : 1;

            m_time[datetime.hour] = m_time[datetime.hour]
                ? m_time[datetime.hour] + 1
                : 1;

            m_language[m.language] = m_language[m.language]
                ? m_language[m.language] + 1
                : 1;

            const message_info = {
                ...m,
                id: idx,
                type,
                type_name: lang_str[`list.operations.type.${type}`],
                channel,
                channel_label:
                    lang_str[`page.reports.messages.label.channel.${channel}`],
                language: m.language.toUpperCase(),
                group: group ? group.name : '---',
                dialog: dialog ? dialog.name : '---',
                robot: is_test ? test_label : r_code,
                robot_type: is_test ? test_label : r_type_name,
                application: is_test ? test_label : app_name,
                created_date: datetime.date,
                created_time: datetime.time,
                created: datetime.string,
                timestamp: datetime.timestamp,
            };

            if (type === 'conversation') {
                const ent = [];
                const val = [];
                const subject_list = m.subjects || [];
                const subjects = subject_list.join(', ');

                subject_list.forEach(s => {
                    const _s = s.split(':');
                    ent.push(_s[0]);
                    val.push(_s[1]);

                    m_subjects[s] = m_subjects[s]
                        ? { ...m_subjects[s], value: m_subjects[s].value + 1 }
                        : {
                              id: `${idx}_${s}`,
                              dialog: dialog.name,
                              dialog_id: dialog.id,
                              subject: s,
                              value: 1,
                          };
                });

                message_info.subjects = subjects;
                message_info.entities = ent;
                message_info.values = val;
            }

            return message_info;
        });

        const robot_grouped = Object.keys(m_robots).map(r => {
            const val = m_robots[r];
            const obj =
                r === 'test'
                    ? {
                          name: test_label,
                      }
                    : r_list[r];
            return {
                name: obj
                    ? `${obj.code ? `[${obj.code}] ` : ''}${obj.name || ''}`
                    : `---`,
                value: val,
            };
        });

        const r_type_grouped = Object.keys(m_r_types).map(t => {
            const val = m_r_types[t];
            const obj =
                t === 'test'
                    ? {
                          name: test_label,
                      }
                    : robot_types[t];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const application_grouped = Object.keys(m_applications).map(a => {
            const val = m_applications[a];
            const obj =
                a === 'test'
                    ? {
                          name: test_label,
                      }
                    : applications[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const group_grouped = Object.keys(m_groups).map(g => {
            const val = m_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const dialog_grouped = Object.keys(m_dialogs).map(d => {
            const val = m_dialogs[d];
            const obj = dialogs[d];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const language_grouped = Object.keys(m_language).map(l => {
            const val = m_language[l];

            return {
                name: lang_str[`list.languages.${l}`],
                value: val,
            };
        });

        const subject_grouped = Object.keys(m_subjects).map(s => {
            return m_subjects[s];
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                formatSettings.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(
                formatSettings.format,
                {
                    month: '2-digit',
                    day: '2-digit',
                }
            );
            d_labels.push(label_date);
            date_grouped.push({
                date: label_date,
                value: m_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);
            time_grouped.push({
                hour: label_time,
                value: m_time[t] || 0,
            });
        }

        setGroupedData({
            total,
            languages: language_grouped,
            groups: group_grouped,
            robots: robot_grouped,
            robot_types: r_type_grouped,
            applications: application_grouped,
            date: date_grouped,
            time: time_grouped,
            d_labels,
            t_labels,
            dialogs: dialog_grouped,
            subjects: subject_grouped,
        });

        setMessageList(mlist);
    }

    function filterData(data) {
        const messages = data.messages || { list: [] };
        const filtered = messages.list
            ? messages.list.filter(m => {
                  return currMode === '*' || m.type === currMode;
              })
            : { total: 0, list: [] };
        groupData(rawData.period, filtered);
    }

    function getModes(data) {
        const m_type = [];
        const messages = data.messages || { list: [] };

        messages.list.forEach(m => {
            const type = m.type || 'conversation';
            m_type[type] = m_type[type] ? m_type[type] + 1 : 1;
        });

        const type_grouped = Object.keys(m_type).map(t => {
            const val = m_type[t];

            return {
                id: t,
                name: lang_str[`list.operations.type.${t}`],
                total: val,
            };
        });

        setModes([...type_grouped]);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        await api
            .get(
                `reports/messages?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                getModes(data);
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function CSVButton() {
        const headers = {
            conversation: headers_file_conversation,
            presentation: headers_file_presentation,
        };

        return (
            <ExportCSVButton
                data={messageList}
                headers={headers[currMode] || headers_file_all}
                filename={GetFileName(
                    `REPORTS-${currMode !== '*' ? `${currMode}_` : ''}messages`,
                    'csv'
                )}
            />
        );
    }

    function buildListView() {
        const headers = {
            conversation: headers_conversation,
            presentation: headers_presentation,
        };

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers[currMode] || headers_messages}
                    data={messageList || []}
                    orderDirection="desc"
                    orderColumn="timestamp"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    sortable
                    hasFilter
                    hasHeader
                    headerDirection="row-reverse"
                    header={
                        <div className="col-3" style={{ padding: '0px' }}>
                            {CSVButton()}
                        </div>
                    }
                />
            </div>
        );
    }

    useEffect(() => {
        if (rawData) {
            filterData(rawData);
        }
    }, [currMode, rawData]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO, locale, options]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            color: colors.dashboard_cards,
            data: groupedData.robot_types,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_graphs,
            data: groupedData.robots,
        },
        {
            key: 'languages',
            title: 'per_language',
            color: colors.dashboard_cards,
            data: groupedData.languages,
        },
    ];

    function renderConversationMessagesExtras() {
        return (
            <>
                <InfoContainer className="row mt-3 mb-3">
                    <ChartsContainer className="row mt-3 mb-5">
                        <ReportPieChart
                            classes="col-md-4 col-12 mb-3"
                            title={
                                <LocaleMessage msg="page.reports.messages.label.per_dialog" />
                            }
                            color={colors.dashboard_graphs}
                            data={groupedData.dialogs}
                        />
                    </ChartsContainer>
                    <TableArea className="col-md-8 col-12 mb-3 table-responsive-material">
                        <div>
                            <p className="card-title">
                                <LocaleMessage msg="page.reports.messages.label.top_subjects" />
                            </p>
                        </div>
                        <DataTable
                            headerColumns={headers_subjects}
                            data={groupedData.subjects || []}
                            orderDirection="desc"
                            orderColumn="value"
                            handleTableRowClick={() => {}}
                            hasActions={false}
                            sortable
                            defaultRowsPerPage={10}
                            hasFilter
                        />
                    </TableArea>
                </InfoContainer>
                <Divider />
            </>
        );
    }

    return (
        <>
            <>
                <ReportHeader
                    date_props={{
                        locale,
                    }}
                    period={{
                        minDate,
                        fromDate: new Date(fromISO),
                        untilDate: new Date(untilISO),
                    }}
                    handleFromDateChange={handleFromDateChange}
                    handleUntilDateChange={handleUntilDateChange}
                    reloadData={() => loadData()}
                    shortcuts={shortcuts}
                    handleShortcutClick={_id => handlePeriodShortcut(_id)}
                />
            </>
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.messages.label.total_messages" />
                                }
                                value={
                                    rawData && rawData.messages
                                        ? rawData.messages.total
                                        : 0
                                }
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <ChartsContainer className="row mt-5 mb-3">
                                <ReportPieChart
                                    classes="col-md-4 col-12 mb-5"
                                    title={
                                        <LocaleMessage msg="page.reports.messages.label.per_mode" />
                                    }
                                    color={colors.dashboard_graphs}
                                    dataKey="total"
                                    data={modes}
                                />
                                <TableArea className="col-md-4 col-12 mb-3 table-responsive-material">
                                    <DataTable
                                        maxHeight="100%"
                                        headerColumns={headers_modes}
                                        data={modes}
                                        orderDirection="desc"
                                        orderColumn="total"
                                        handleTableRowClick={(event, id) => {
                                            setCurrMode(id);
                                        }}
                                        hasActions={false}
                                        sortable
                                        defaultRowsPerPage={10}
                                        hasFooter={false}
                                        headerData={{
                                            id: '*',
                                            name: 'TOTAL',
                                            total:
                                                rawData && rawData.messages
                                                    ? rawData.messages.total
                                                    : 0,
                                            clickable: true,
                                        }}
                                    />
                                </TableArea>
                            </ChartsContainer>
                        </InfoContainer>
                        <Divider />
                        <RowArea className="mt-5 mb-5">
                            <p className="card-title">
                                <LocaleMessage
                                    msg={`page.reports.messages.label.mode.${
                                        currMode === '*' ? 'all' : currMode
                                    }`}
                                />
                            </p>
                        </RowArea>
                        <ChartsContainer className="row mt-3 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.messages.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{}}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.messages.label.per_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.messages.label.per_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        {currMode === 'conversation'
                            ? renderConversationMessagesExtras()
                            : null}
                        <RowArea className="mt-5 mb-5">
                            <div>
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.messages.label.history" />
                                </p>
                            </div>
                            {buildListView()}
                        </RowArea>
                    </>
                )}
            </div>
        </>
    );
}

ConversationReports.defaultProps = {
    options: {},
};

ConversationReports.propTypes = {
    settings: PropTypes.object.isRequired,
    counterData: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    options: PropTypes.object,
};
