import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function GroupSelector({ value, onChange, disabled }) {
    const settings = useSelector(state => state.settings || null);

    const { admin_groups } = settings;

    return (
        <FormControl fullWidth>
            <InputLabel>
                <LocaleMessage msg="label.form.group" />
            </InputLabel>
            <Select
                id="group"
                value={value || ''}
                onChange={event => onChange(event.target.value)}
                disabled={disabled}
            >
                <MenuItem value="*">
                    <LocaleMessage msg="message.all_groups.short" />
                </MenuItem>
                {admin_groups.map(g => (
                    <MenuItem value={g.id} key={g.id}>
                        {g.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

GroupSelector.defaultProps = {
    value: '',
    disabled: false,
};

GroupSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
