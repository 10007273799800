/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { Card } from './styles';

export default function StatusCard({
    Icon,
    width,
    margin,
    title,
    body,
    infoClass,
}) {
    const boxShadow = 0;

    return (
        <div className={`${width} ${margin}`}>
            <Box boxShadow={boxShadow} className="fullsize">
                <Card className="row">
                    <div className="col-5 icon-area">
                        <span className="card-title">{title}</span>
                        {Icon ? <Icon style={{ fontSize: '4.5vh' }} /> : null}
                    </div>
                    <div className="col-7 side-padding info-area text-left">
                        {body.map(info => {
                            return (
                                <div className="info" key={info.label}>
                                    <span className="info-title">
                                        <LocaleMessage msg={info.label} />
                                    </span>
                                    <span className={`${infoClass} info-text`}>
                                        {info.value}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </Card>
            </Box>
        </div>
    );
}

StatusCard.propTypes = {
    width: PropTypes.string,
    margin: PropTypes.string,
    infoClass: PropTypes.string,
    body: PropTypes.array,
    title: PropTypes.element,
    Icon: PropTypes.func,
};

StatusCard.defaultProps = {
    width: 'col-md-4',
    margin: 'mb-3',
    infoClass: '',
    body: [],
    title: null,
    Icon: () => {},
};
