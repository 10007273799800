import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetPeriodDates from '~/util/GetPeriodDates';

export default function filter(counters, data, { format, dateOptions }) {
    const {
        robot_types,
        robots,
        groups,
        dialogs,
        applications,
        maps,
        map_points,
    } = counters;

    const { period, action_logs } = data;
    if (!action_logs) return {};
    const action_list = action_logs ? action_logs.list : [];

    const a_groups = [];
    const a_dialogs = [];
    const a_robots = [];
    const a_r_types = [];
    const a_applications = [];
    const a_maps = [];
    const a_map_points = [];
    const a_date = [];
    const a_time = [];
    const a_success = [];

    const list = action_list.map(a => {
        const { content } = a;
        const { map, point, success } = content;

        const datetime = GetDateTimeLabel(new Date(a.created), { format });

        const robot = robots[a.robot_id];
        const r_type = robot_types[a.robottype_id];
        const application = applications[a.application_id];
        const dialog =
            dialogs && dialogs[a.dialog_id] ? dialogs[a.dialog_id] : null;
        const group = groups[a.group_id];

        a_groups[a.group_id] = a_groups[a.group_id]
            ? a_groups[a.group_id] + 1
            : 1;

        a_robots[a.robot_id] = a_robots[a.robot_id]
            ? a_robots[a.robot_id] + 1
            : 1;

        a_r_types[a.robottype_id] = a_r_types[a.robottype_id]
            ? a_r_types[a.robottype_id] + 1
            : 1;

        a_applications[a.application_id] = a_applications[a.application_id]
            ? a_applications[a.application_id] + 1
            : 1;

        a_dialogs[a.dialog_id] = a_dialogs[a.dialog_id]
            ? a_dialogs[a.dialog_id] + 1
            : 1;

        a_success[success] = a_success[success] ? a_dialogs[success] + 1 : 1;

        const map_id = map && map.id ? map.id : '---';
        a_maps[map_id] = a_maps[map_id] ? a_maps[map_id] + 1 : 1;

        const point_id = point && point.id ? point.id : '---';
        a_map_points[point_id] = a_map_points[point_id]
            ? a_map_points[point_id] + 1
            : 1;

        const map_name = map && map.name ? map.name : '---';
        const point_name = point && point.name ? point.name : '---';

        a_date[datetime.date] = a_date[datetime.date]
            ? a_date[datetime.date] + 1
            : 1;
        a_time[datetime.hour] = a_time[datetime.hour]
            ? a_time[datetime.hour] + 1
            : 1;

        return {
            ...a,
            group_name: group ? group.name : '---',
            dialog_name: dialog ? dialog.name : '---',
            application_name: application ? application.name : '---',
            robot_name: robot ? `[${robot.code}] ${robot.name}` : '---',
            robottype: r_type ? r_type.name : '---',
            success,
            map_name,
            point_name,
            created_date: datetime.date,
            created_time: datetime.time,
            created: datetime.string,
            timestamp: datetime.timestamp,
        };
    });

    const group_grouped = Object.keys(a_groups).map(g => {
        const val = a_groups[g];
        const obj = groups[g];

        return {
            name: obj ? obj.name : '---',
            value: val,
        };
    });

    const robot_grouped = Object.keys(a_robots).map(r => {
        const val = a_robots[r];
        const obj = robots[r];

        return {
            name: obj ? obj.name : '---',
            value: val,
        };
    });

    const rtype_grouped = Object.keys(a_r_types).map(t => {
        const val = a_r_types[t];
        const obj = robot_types[t];

        return {
            name: obj ? obj.name : '---',
            value: val,
        };
    });

    const application_grouped = Object.keys(a_applications).map(a => {
        const val = a_applications[a];
        const obj = applications[a];

        return {
            name: obj ? obj.name : '---',
            value: val,
        };
    });

    const dialog_grouped = Object.keys(a_dialogs).map(d => {
        const val = a_dialogs[d];
        const obj = dialogs[d];

        return {
            name: obj ? obj.name : '---',
            value: val,
        };
    });

    const success_grouped = Object.keys(a_success).map(s => {
        const val = a_success[s];

        return {
            name: s,
            value: val,
        };
    });

    const map_grouped = Object.keys(a_maps).map(m => {
        const val = a_maps[m];
        const obj = maps[m] || {};

        return {
            name: obj ? obj.name : '---',
            value: val || 0,
        };
    });

    const point_grouped = Object.keys(a_map_points).map(p => {
        const val = a_map_points[p];
        const obj = map_points[p] || {};

        return {
            name: obj ? obj.name : '---',
            value: val || 0,
        };
    });
    const period_dates = GetPeriodDates(period);

    const d_labels = [];
    const date_grouped = [];
    period_dates.forEach(p => {
        const formatted_date = new Date(p).toLocaleDateString(
            format.format,
            dateOptions
        );
        const label_date = new Date(p).toLocaleDateString(format.format, {
            month: '2-digit',
            day: '2-digit',
        });
        d_labels.push(label_date);
        date_grouped.push({
            date: label_date,
            value: a_date[formatted_date] || 0,
        });
    });

    const t_labels = [];
    const time_grouped = [];
    for (let t = 0; t < 24; t++) {
        const label_time = t < 10 ? `0${t}h` : `${t}h`;
        t_labels.push(label_time);
        time_grouped.push({
            hour: label_time,
            value: a_time[t] || 0,
        });
    }

    const newFilteredData = {
        total: list.length,
        data: list,
        groups: group_grouped,
        robots: robot_grouped,
        robot_types: rtype_grouped,
        applications: application_grouped,
        dialogs: dialog_grouped,
        success: success_grouped,
        maps: map_grouped,
        points: point_grouped,
        date: date_grouped,
        time: time_grouped,
        d_labels,
        t_labels,
    };

    return newFilteredData;
}
