import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Drawer } from '@material-ui/core';

import icon from '~/assets/icon-white.png';
import createMenu from '~/config/Menu';

import Content from './SidebarContent';
import { Logo } from './styles';

export default function Sidebar(props) {
    const { drawer, open, toggleCollapsedNav, hideNav } = props;
    const drawerStyle = drawer === 'collapsible-drawer' ? '' : 'd-flex';

    const { profile } = useSelector(state => state.user);
    const { pluginspace } = useSelector(state => state.settings);
    const { permission } = profile;

    let type = 'permanent';
    if (drawer === 'collapsible-drawer') {
        type = 'temporary';
    }

    return (
        <div className={`app-sidebar d-none ${drawerStyle}`}>
            <Drawer
                className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? open : true}
                onClose={event => toggleCollapsedNav(event)}
                classes={{
                    paper: 'side-nav',
                }}
            >
                <Link to="/">
                    <Logo open={open}>
                        <img src={icon} alt="Pluginbot" title="Pluginbot" />
                    </Logo>
                </Link>
                <Content
                    type={type}
                    open={type.includes('temporary') ? open : true}
                    menu={createMenu(permission)}
                    permission={permission}
                    pluginspace={pluginspace.subdomain || ''}
                    onMenuItemClick={event => hideNav(event)}
                />
            </Drawer>
        </div>
    );
}

Sidebar.propTypes = {
    drawer: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    toggleCollapsedNav: PropTypes.func.isRequired,
    hideNav: PropTypes.func.isRequired,
};
