import { darken, lighten, shade } from 'polished';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
 0% {scale: 0; }
 30% {scale: 1;}
 70% {scale: 1;}
 100% {scale 0; }
`;

export const CentralPanel = styled.div`
    width: calc(90% - 40px);
    height: 100%;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(90deg, ${shade(0.95, color)}, ${darken(
            0.4,
            color
        )})`;
    }};
`;

export const Header = styled.div`
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555555;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(45deg, ${shade(0.95, color)}, ${darken(
            0.4,
            color
        )})`;
    }};

    .exit {
        width: 100%;
        color: #ccc;
        display: flex;
        justify-content: flex-end;

        :hover {
            cursor: pointer;
        }
    }

    .header-title {
        padding: 1vh 2.5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .title {
            font-size: 3vh;
            color: #eee;
            margin-bottom: 0px;
        }

        .subtitle {
            font-size: 1.5vh;
            color: #ccc;
        }
    }

    .header-options {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const Body = styled.div`
    height: 85%;
    width: 100%;
    display: flex;
`;

export const RoomList = styled.div`
    // width: 82.35%;
    width: ${props => (props.hasMap ? '60%' : '100%')};
    height: 100%;
    padding: 5vh 0vw 24vh 0px;
`;

export const MapContainer = styled.div`
    width: 40%;
    height: 100%;
    border-left: 1px solid #555555;
    display: flex;
    flex-direction: column;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(-90deg, ${shade(0.95, color)}, ${darken(
            0.4,
            color
        )})`;
    }};

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 2.5vw;
`;

export const InfoRow = styled.div`
    width: 100%;
    min-height: 15vh;
    height: fit-content;
    display: flex;
    align-items: top;
    margin: 0px;

    .fullsize {
        display: flex;
        flex: 1;
        height: 100%;
    }
`;

export const InfoCard = styled.div`
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.9, color);
    }};
    height: fit-content;
    padding: 1.5vh 1.5vw 0.5vh 1.5vw;

    border: ${props => {
        const color = props.theme.palette.primary.main;
        return props.active ? `1px solid ${lighten(0.35, color)}` : '';
    }};
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};

    // background: linear-gradient(-45deg, transparent 2vh, #333333 2vh) bottom
    //     right;

    :hover {
        cursor: pointer;
    }

    .title-row {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0px;

        .title-area {
            display: flex;
            flex-direction: column;
            padding: 0px;

            .info-title {
                color: #fff;
                font-size: 1.75vh;
                padding: 0px;
                width: 100%;
                margin-bottom: 1.5vh;
            }
        }

        .icon-area {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0px;
            margin-bottom: 1.25vh;

            svg {
                animation-name: ${blink};
                animation-duration: 3s;
                animation-iteration-count: infinite;
            }
        }
    }

    .info-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.3vh;
        text-align: left;
        color: #ccc;

        .info-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px;
        }

        .info-text {
            margin-bottom: 1.15vh;
        }

        .info-item {
            margin-bottom: 1.15vh;
            margin-left: 1vw;
        }
    }
`;
