/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    FormControlLabel,
    TextField,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

const map_auto_mode = [
    {
        value: 'random',
        label: (
            <LocaleMessage msg="page.robots.form.behaviour.autonomous_mode.random" />
        ),
    },
    {
        value: 'to_point',
        label: (
            <LocaleMessage msg="page.robots.form.behaviour.autonomous_mode.to_point" />
        ),
    },
];

export default function AppBehaviourParameters(props) {
    const {
        app_type,
        application,
        robot_id,
        handleDialogClose,
        requestError,
    } = props;
    const [appSettings, setAppSettings] = useState({});
    const [body, setBody] = useState({
        robot_id,
        application_id: application.id,
        apptype_id: app_type.id,
    });
    const [options, setOptions] = useState({});
    const [mapPoints, setMapPoints] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [operation, setOperation] = useState('create');

    async function loadAppSettings() {
        await api
            .get(`applications/${application.id}`)
            .then(response => {
                const { data } = response;
                setAppSettings(data.settings || {});
            })
            .catch(error => requestError(error));
    }

    async function loadRobotBehaviour() {
        await api
            .get(`app_behaviour/${application.id}/robot/${robot_id}`)
            .then(response => {
                const { data } = response;
                if (data && data.active) {
                    setOperation('update');
                    setBody({
                        robot_id: data.robot_id,
                        application_id: data.application_id,
                        apptype_id: data.app_type_id,
                    });
                    setOptions(data.settings);
                } else {
                    setOperation('create');
                }
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadMapPoints() {
        const mapNavigation = appSettings.map_navigation || {};

        if (mapNavigation && mapNavigation.map_layer_id) {
            setIsLoading(true);
            await api
                .get(`map_points?map_layer=${mapNavigation.map_layer_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: z.name,
                        reference: z.reference,
                    }));
                    setMapPoints(data);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadAppSettings();
        loadRobotBehaviour();
    }, []);

    useEffect(() => {
        loadMapPoints();
    }, [appSettings]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, settings: options };

        if (operation === 'create') {
            await api
                .post(`app_behaviour/${application.id}/robot/${robot_id}`, data)
                .then(() => {
                    setIsLoading(false);
                    handleDialogClose();
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`app_behaviour/${application.id}/robot/${robot_id}`, data)
                .then(() => {
                    setIsLoading(false);
                    handleDialogClose();
                })
                .catch(error => requestError(error));
        }

        setIsLoading(false);
    }

    function renderNullConfig() {
        return (
            <DialogContentText>
                <LocaleMessage msg="page.robots.form.behaviour.no_config" />
            </DialogContentText>
        );
    }

    function renderAutonomousNavigation() {
        return (
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={
                                        options.run_autonomous
                                            ? options.run_autonomous
                                            : false
                                    }
                                    value="active"
                                    onChange={event =>
                                        setOptions({
                                            ...options,
                                            run_autonomous:
                                                event.target.checked,
                                        })
                                    }
                                />
                            }
                            label={
                                <LocaleMessage msg="page.robots.form.behaviour.run_autonomous" />
                            }
                        />
                    </FormControl>
                </div>
                {options && options.run_autonomous ? (
                    <>
                        <div className="col-md-6 col-12 mb-3">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.robots.form.behaviour.autonomous_timeout" />
                                }
                                fullWidth
                                value={options.autonomous_timeout || ''}
                                onChange={event =>
                                    setOptions({
                                        ...options,
                                        autonomous_timeout: event.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.robots.form.behaviour.autonomous_mode" />
                                </InputLabel>
                                <Select
                                    value={
                                        options.autonomous_mode
                                            ? options.autonomous_mode
                                            : 'random'
                                    }
                                    onChange={event =>
                                        setOptions({
                                            ...options,
                                            autonomous_mode: event.target.value,
                                        })
                                    }
                                >
                                    {map_auto_mode.map(m => (
                                        <MenuItem
                                            value={m.value}
                                            key={`auto_mode_${m.value}`}
                                        >
                                            {m.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {options && options.autonomous_mode === 'to_point' ? (
                            <div className="col-md-6 col-12 mb-5">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.robots.form.behaviour.autonomous_to" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            options.autonomous_go_to
                                                ? options.autonomous_go_to
                                                : ''
                                        }
                                        onChange={event =>
                                            setOptions({
                                                ...options,
                                                autonomous_go_to:
                                                    event.target.value,
                                            })
                                        }
                                    >
                                        {mapPoints.map(p => (
                                            <MenuItem
                                                value={p.id}
                                                key={`auto_points_${p.id}`}
                                            >
                                                {p.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }

    function renderConversationAppSettings() {
        const mapNavigation = appSettings.map_navigation || {};
        if (!mapNavigation) {
            return null;
        }

        const { active, allow_autonomous, map_layer_id } = mapNavigation;

        const convSettings = {
            autonomous_mode: active && map_layer_id && allow_autonomous,
        };

        const activeConfigs = Object.keys(convSettings).filter(s => {
            return convSettings[s];
        });

        return (
            <>
                {activeConfigs.length > 0 ? (
                    <>
                        {convSettings.autonomous_mode
                            ? renderAutonomousNavigation()
                            : null}
                    </>
                ) : (
                    renderNullConfig()
                )}
            </>
        );
    }

    function renderAppSettings() {
        if (!app_type || !app_type.slug) {
            return null;
        }

        switch (app_type.slug) {
            case 'conversation':
                return renderConversationAppSettings();
            default:
                return null;
        }
    }

    return (
        <>
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <DialogContent>{renderAppSettings()}</DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={event => handleSubmit(event)}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
}

AppBehaviourParameters.propTypes = {
    app_type: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    robot_id: PropTypes.string.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
};
