/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

export default function SULocations({ requestError, filter, formats }) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const headersFile = [
        { key: 'active', label: 'Is Active' },
        { key: 'pluginspace_name', label: 'Pluginspace' },
        { key: 'group_name', label: 'Group' },
        { key: 'name', label: 'Name' },
        { key: 'lat_lng', label: 'Lat/Lng' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
    ];

    const headerCells = [
        {
            id: 'pluginspace_name',
            label: <LocaleMessage msg="table.headers.pluginspace" />,
        },
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'lat_lng',
            label: <LocaleMessage msg="table.headers.lat_lng" />,
        },
        {
            id: 'status_name',
            label: <LocaleMessage msg="table.headers.status" />,
            order_by: 'active',
        },
        {
            id: 'created_date',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(l => {
            const created = new Date(l.created);
            const updated = new Date(l.updated);
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);

            return {
                ...l,
                pluginspace_name:
                    l.pluginspace && l.pluginspace.name
                        ? l.pluginspace.name
                        : '---',
                group_name: l.group && l.group.name ? l.group.name : '---',
                status_name: l.active ? (
                    <LocaleMessage msg="info.status.active" />
                ) : (
                    <LocaleMessage msg="info.status.inactive" />
                ),
                lat_lng: `[${l.lat},${l.lng}]`,
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
            };
        });
        setItems(data);
    }

    async function loadLocations() {
        setIsLoading(true);
        const filter_url = filter ? `?pluginspace=${filter}` : '';
        await api
            .get(`admin/locations${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadLocations();
    }, [formats, filter]);

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headersFile}
                filename={GetFileName(`admin_analytics_locations`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headerCells}
                    data={items}
                    orderColumn="name"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <div style={{ padding: '15px' }}>{buildExportButton()}</div>
            <>{buildListView()}</>
        </>
    );
}

SULocations.defaultProps = {
    filter: '',
    requestError: () => {},
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SULocations.propTypes = {
    filter: PropTypes.string,
    requestError: PropTypes.func,
    formats: PropTypes.object,
};
