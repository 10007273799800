import { shade } from 'polished';
import styled from 'styled-components';

export const CentralPanel = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .panel-header {
        padding: 1vh;
        color: #ccc;
        display: flex;
        justify-content: flex-end;

        :hover {
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    width: ${props => (props.smallScreen ? '100%' : '90%')};
    height: 100%;
    margin: 0px 0.5vw;
    padding: ${props => (props.smallScreen ? '35px' : '0.5vh')} 0px;
    overflow: ${props => (props.smallScreen ? 'scroll' : 'hidden')};
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */

    .fullsize {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* height: 100%; */
    }
`;

export const MainInfo = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0.5vh;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .time {
        font-size: 3.5vh;
        font-weight: bold;
        margin-bottom: 1vh;
    }

    .title {
        color: #999;
        width: 100%;
        text-align: center;
        margin-bottom: 0.5vh;
    }

    .info {
        padding: 0.5vh;
        font-size: 1.5vh;
        text-align: center;
    }
`;

export const InfoCard = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1vh 0.5vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1.35vh;

    .clickable {
        :hover {
            cursor: pointer;
        }
    }

    .row {
        width: 100%;
    }

    .card-block {
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 0.5vh;
        margin: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
        }

        .info {
            font-size: 1.65vh;
        }

        .big-value {
            font-size: 3vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }
    }

    .card-title {
        color: #999;
        width: 100%;
        text-align: left;
        margin-bottom: 0.5vh;
    }

    .card-info {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 0.5vw;
        height: 100%;

        .info-title {
            color: #999;
            padding: 0px;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
        }

        .info-text {
            text-align: right;
        }

        .info-value {
            font-size: 1.65vh;
        }

        .big-value {
            font-size: 2.5vh;
            span {
                margin-top: 1vh;
            }
        }

        /* : {
            margin-bottom: 0.5vh;
        } */
    }
`;

export const StatusItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.35vh;

    .item {
        display: flex;
        align-items: center;
    }

    .title {
        color: #999;
        text-align: left;
    }

    .MuiBadge-root {
        margin: ${props => {
            const spacing = props.smallScreen ? '15px' : '0.25vw';
            return `0px 0.5vw 0px ${spacing}`;
        }};
    }

    .status-connected {
        .MuiBadge-dot {
            background-color: #0f0;
        }
    }

    .status-occupied {
        .MuiBadge-dot {
            background-color: #ff0;
        }
    }

    .status-alert {
        .MuiBadge-dot {
            background-color: #f00;
        }
    }

    .status-disconnected {
        .MuiBadge-dot {
            background-color: #999;
        }
    }

    .status-undefined {
        .MuiBadge-dot {
            background-color: #999;
        }
    }

    :not(:last-child) {
        margin-bottom: 0.5vh;
    }
`;

export const InfoArea = styled.div`
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.35vh;

    .jr-overview-row {
        width: 100%;
    }

    /* .jr-description {
        width: 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-left: solid 1px #555555;
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    } */

    .desc-full {
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 1vh;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;
            font-size: 1.35vh !important;
        }

        .value {
            font-size: 1.65vh;
            font-weight: bold;

            span {
                margin-top: 1vh;
            }
        }

        .big-value {
            font-size: 3vh;
            font-weight: bold;

            span {
                margin-top: 1vh;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;

export const TableCard = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    width: 100%;
    height: 35vh;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px 0px 1vh 0px;
    margin: 0px;
    font-size: 1.35vh !important;
    color: #999;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .card-settings {
        height: 10%;
        margin: 0.5vh 1vw;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
            font-size: 1.25vh;
        }
    }
`;

export const TableArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25vh !important;

    table {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;

        height: 100%;
        .MuiTableCell-head {
            color: #999;
            font-weight: bold;
        }

        .MuiTableCell-root {
            color: #ffffff;
        }

        .MuiIconButton-label {
            color: #ffffff;
        }
    }

    tbody {
        display: block;
        max-height: 25vh;
        overflow-y: auto;

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #ddd;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #999;
        }
    }

    .MuiTableCell-root {
        font-size: 1.25vh !important;
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .inner-row {
        border-bottom: 1px solid #999;
        font-size: 1.25vh !important;

        table {
            border: unset;
        }

        td {
            border-bottom: unset;
            color: #ffffff;
        }

        .row-ellipsis {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #999;
            padding: 0.5vh 0px;
        }
    }

    tr {
        :hover {
            cursor: pointer;
        }
    }
`;

export const TimeChart = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    width: 100%;
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.25vh;

    .chart-area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .area-chart-container {
            margin-left: -15px;
        }
    }

    .card-title {
        padding: 0.5vh;
        margin: 0px;

        span {
            font-size: 1.35vh;
            color: #999;
        }
    }

    .jr-overview-row {
        width: 100%;
    }

    .jr-description {
        /* width: 25%; */
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border: 1px #555555;
        border-style: ${props =>
            props.smallScreen ? 'solid' : 'none none none solid'};
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    }

    .desc-full {
        display: flex;
        flex: 1 1;
        padding: 0.5vh;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;
        font-size: 1.35vh !important;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 0.5vh;
            font-size: 1.35vh !important;
        }

        .value {
            font-size: 1.65vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }

        .big-value {
            font-size: 2.35vh;
            font-weight: bold;
            span {
                margin-top: 1vh;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;
