/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function NavMaps() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [view, setView] = useState('grid');

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { active } = settings;

    const [maps, setMaps] = useState([]);
    const [filteredData, setFilteredData] = useState(maps);
    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const open = Boolean(anchorEl);

    const allowNew = !(active && active.id === '*');

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`maps`)
            .then(response => {
                const data = response.data.map(m => {
                    const updated = new Date(m.updated);
                    return {
                        ...m,
                        location_name: m.location ? m.location.name : '---',
                        zone_name: m.zone ? m.zone.name : '---',
                        group_id: m.group && m.group.id ? m.group.id : '*',
                        group_name:
                            m.group && m.group.name ? (
                                m.group.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setMaps(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [active, date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`maps/${_id}`);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/tools/maps/${id}`);
    }

    async function deleteDialog() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`maps/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function mapCard(m) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={m.id}>
                <Link to={`/tools/maps/${m.id}`}>
                    <CardItem
                        title={m.name}
                        optionsClick={event => handleClick(event, m.id)}
                        updated={m.updated}
                        img={{
                            url: m.file && m.file.url ? m.file.url : null,
                            name: m.name,
                        }}
                        position="center"
                        fit="contain"
                        group={
                            m.group_name || (
                                <LocaleMessage msg="message.all_groups.short" />
                            )
                        }
                    >
                        <>
                            <div className="card-subtitle">
                                <strong>
                                    <small>{m.location_name}</small>
                                </strong>
                                <br />
                                <small>{m.zone_name}</small>
                            </div>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {filteredData.map(m => mapCard(m))}
                </div>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={<LocaleMessage msg="page.navmaps.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteDialog(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <>
                <div
                    className="mb-5"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        orderColumn="name"
                        setCurrItem={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.navmaps.title" />}
            currentPage={<LocaleMessage msg="page.navmaps.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div
                    className="body-top-controls"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="col-3 pl-0">
                        <NewItemButton
                            disabled={!allowNew}
                            link="/tools/maps/new"
                            text={
                                <LocaleMessage msg="page.navmaps.list.add_map" />
                            }
                        />
                    </div>
                    <div className="col-3">
                        <DataFilter
                            rawData={maps}
                            headerColumns={[
                                'name',
                                'location_name',
                                'zone_name',
                            ]}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
