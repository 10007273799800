/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPlaylistAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import FormStepper from '~/components/Form/Stepper';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import AnswerSetup from './Components/AnswerSetup';
import ExitActionSetup from './Components/ExitActionSetup';
import ExitConditionSetup from './Components/ExitConditionSetup';
import FormatterSetup from './Components/FormatterSetup';
import IntegrationSetup from './Components/IntegrationSetup';
import StartSetup from './Components/StartSetup';
import InfoSetup from './Components/TextsSetup';
import VisualSetup from './Components/VisualSetup';

export default function SurveyStepForm({ match }) {
    const colors = GetPluginspaceTheme(useTheme());
    const dispatch = useDispatch();

    const { plugin_id, id, step_id } = match.params;

    // Default Step Settings
    const defaultBody = {
        timeout: 30,
    };
    const defaultVisual = {};
    const defaultQuestion = { type: 'static' };
    const defaultAnswer = {
        mode: 'text_first',
        type: 'text',
        tries: 2,
        multi: 'single',
    };
    const defaultFormatter = {
        type: 'no-formatter',
    };
    const defaultIntegration = {
        allow: false,
        auth_type: 'none',
        url: '',
    };
    const defaultConditions = [];
    const defaultExit = {};

    // Component State
    const [isLoading, setIsLoading] = useState(true);
    const [hasStart, setHasStart] = useState(false);
    const [operation, setOperation] = useState('create');
    const [varList, setVarList] = useState([]);

    const [pluginConfig, setPluginConfig] = useState(null);

    const [languages, setLanguages] = useState({});
    const [stepList, setStepList] = useState([]);
    const [galleryList, setGalleryList] = useState([]);
    const [formatterList, setFormatterList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [activeFile, setActiveFile] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const [body, setBody] = useState(defaultBody);
    const [visualSetup, setVisualSetup] = useState(defaultVisual);
    const [stepSetup, setStepSetup] = useState(defaultQuestion);
    const [answerSetup, setAnswerSetup] = useState(defaultAnswer);
    const [formatterSetup, setFormatterSetup] = useState(defaultFormatter);
    const [startSetup, setStartSetup] = useState({});
    const [integrationSetup, setIntegrationSetup] = useState(
        defaultIntegration
    );
    const [conditionsSetup, setConditionsSetup] = useState(defaultConditions);
    const [exitSetup, setExitSetup] = useState(defaultExit);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadVisualTemplates() {
        await api
            .get(`templates`)
            .then(response => {
                const { data } = response;
                setTemplateList(data);
            })
            .catch(error => requestError(error));
    }

    async function loadPluginconfig(_id) {
        await api
            .get(`pluginconfigs/${_id}`)
            .then(async response => {
                const config = response.data;
                setPluginConfig({
                    name: config.name,
                    action_id:
                        config.action && config.action.id
                            ? config.action.id
                            : null,
                    group_id:
                        config.group && config.group.id
                            ? config.group.id
                            : null,
                    plugintemplate_id:
                        config.plugintemplate && config.plugintemplate.id
                            ? config.plugintemplate.id
                            : null,
                    type:
                        config.plugintemplate && config.plugintemplate.type
                            ? config.plugintemplate.type
                            : null,
                });

                const parameters = config.parameters || {};
                const langs = parameters.language || {};

                setLanguages(langs);
            })
            .catch(error => requestError(error));
    }

    async function loadFormatters() {
        await api
            .get(`info_formatters`)
            .then(response => {
                const data = response.data.map(f => ({
                    ...f,
                    label: !f.private ? (
                        <LocaleMessage
                            msg={`tools.info_formatters.${f.name}`}
                        />
                    ) : (
                        f.name
                    ),
                }));

                setFormatterList(data);
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    async function loadStep(_id) {
        if (_id !== 'new') {
            setOperation('update');
            await api
                .get(`survey/${id}/steps/${_id}`)
                .then(response => {
                    const { data } = response;

                    setBody({
                        type: data.type,
                        description: data.description,
                        timeout: data.timeout,
                        var_name: data.var_name,
                        skip: data.skip,
                        allow_back: data.allow_back,
                    });
                    setVisualSetup({
                        ...data.visual,
                        template_id: data.template_id,
                    });
                    setStepSetup(data.question || defaultQuestion);
                    setAnswerSetup(data.answer || defaultAnswer);
                    setFormatterSetup(data.formatter || defaultFormatter);
                    setIntegrationSetup(data.integration || defaultIntegration);
                    setConditionsSetup(data.conditions || defaultConditions);
                    setStartSetup(data.start || {});
                    setExitSetup(data.exit_action || defaultExit);
                })
                .catch(error => requestError(error));
        } else {
            setOperation('create');
        }
    }

    async function loadNextSteps(_id) {
        setIsLoading(true);
        await api
            .get(`survey/${_id}/steps`)
            .then(response => {
                const { data } = response;

                const steps = data.filter(s => {
                    return s.id !== step_id;
                });

                const start_exists = steps.find(s => {
                    return s.type === 'start';
                });

                const var_names = [];
                steps.forEach(s => {
                    if (s.var_name) {
                        var_names.push(s.var_name);
                    }
                });

                setHasStart(start_exists);
                setStepList(steps);
                setVarList(var_names);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                setGalleryList(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadAll() {
        Promise.all([
            loadVisualTemplates(),
            loadFormatters(),
            loadPluginconfig(id),
            loadGalleryList(),
            loadNextSteps(id),
            loadStep(step_id),
        ]).then(() => {
            setIsLoading(false);
        });
    }

    function getTemplateFile(tmpl_id) {
        const file = templateList.find(f => {
            return f.id === tmpl_id;
        });
        if (file) {
            const { usage } = file.settings;
            if (visualSetup && body.type && usage && usage[body.type]) {
                return setActiveFile({
                    url: file.file ? file.file.url : '',
                    name: file.slug || '',
                });
            }
        }
        return setActiveFile(null);
    }

    useEffect(() => {
        getTemplateFile(visualSetup.template_id);
    }, [visualSetup.template_id, body.type, templateList]);

    useEffect(() => {
        loadAll();
    }, []);

    async function handleSubmit() {
        const data = {
            ...body,
            template_id: visualSetup.template_id,
            visual: visualSetup,
            question: stepSetup,
            answer: answerSetup,
            formatter: formatterSetup,
            integration: integrationSetup,
            conditions: conditionsSetup,
            exit_action: exitSetup,
            ...(body.type === 'start' && {
                start: startSetup,
            }),
        };

        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`survey/${id}/steps`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.survey_steps.form.create_success" />
                    );
                    history.push(`/pluginconfig/${plugin_id}/survey/${id}`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`survey/${id}/steps/${step_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.survey_steps.form.update_success" />
                    );
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function handleStepClick(step) {
        setActiveStep(step);
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    // =========================================================================
    // STEP COMPONENTS
    // =========================================================================

    function renderStepVisual() {
        const filteredTemplates = templateList.filter(t => {
            const { usage } = t.settings;
            return body && body.type && usage && usage[body.type];
        });

        return (
            <VisualSetup
                stepType={body.type || 'input'}
                colors={colors}
                file={activeFile}
                templates={filteredTemplates}
                galleries={galleryList}
                settings={visualSetup}
                onChange={s => setVisualSetup(s)}
                handleError={err => requestError(err)}
            />
        );
    }

    function renderInfoSetup() {
        return (
            <InfoSetup
                settings={stepSetup}
                languageList={languages.list}
                galleries={galleryList}
                template={activeFile && activeFile.name ? activeFile.name : ''}
                onChange={s => setStepSetup(s)}
                handleError={err => requestError(err)}
            />
        );
    }

    function renderAnswerSetup() {
        return (
            <AnswerSetup
                colors={colors}
                visual={visualSetup}
                mainSettings={body}
                setMainSettings={s => setBody(s)}
                settings={answerSetup}
                onChange={s => setAnswerSetup(s)}
                languages={languages}
                template={activeFile && activeFile.name ? activeFile.name : ''}
            />
        );
    }

    function renderFormatterSetup() {
        return (
            <FormatterSetup
                formatters={formatterList}
                settings={formatterSetup}
                onChange={s => setFormatterSetup(s)}
            />
        );
    }

    function renderStartSetup() {
        return (
            <StartSetup
                settings={startSetup}
                onChange={s => setStartSetup(s)}
            />
        );
    }

    function renderIntegrationSetup() {
        return (
            <IntegrationSetup
                settings={integrationSetup}
                onChange={s => setIntegrationSetup(s)}
            />
        );
    }

    function renderConditionsSetup() {
        return (
            <ExitConditionSetup
                varList={[
                    ...varList,
                    ...(body && body.var_name ? [body.var_name] : []),
                ]}
                stepList={stepList}
                settings={conditionsSetup}
                onChange={s => setConditionsSetup(s)}
            />
        );
    }

    function renderExitActionSetup() {
        return (
            <ExitActionSetup
                languages={languages}
                settings={exitSetup}
                onChange={s => setExitSetup(s)}
            />
        );
    }

    const step_types = [
        {
            value: 'start',
            label: <LocaleMessage msg="page.survey_steps.type.start" />,
        },
        {
            value: 'input',
            label: <LocaleMessage msg="page.survey_steps.type.input" />,
        },
        {
            value: 'display',
            label: <LocaleMessage msg="page.survey_steps.type.display" />,
        },
        {
            value: 'end',
            label: <LocaleMessage msg="page.survey_steps.type.end" />,
        },
    ];

    const step_settings = {
        start_setup: {
            key: 'start_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.start_setup" />
            ),
            content: renderStartSetup(),
        },
        step_visual: {
            key: 'step_visual',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.visual_setup" />
            ),
            content: renderStepVisual(),
        },
        content_setup: {
            key: 'content_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.content_setup" />
            ),
            content: renderInfoSetup(),
        },
        answer_setup: {
            key: 'answer_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.answer_setup" />
            ),
            content: renderAnswerSetup(),
        },
        formatter_setup: {
            key: 'formatter_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.formatter_setup" />
            ),
            content: renderFormatterSetup(),
        },
        integration_setup: {
            key: 'integration_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.integration_setup" />
            ),
            content: renderIntegrationSetup(),
        },
        conditions_setup: {
            key: 'conditions_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.conditions_setup" />
            ),
            content: renderConditionsSetup(),
        },
        exit_action_setup: {
            key: 'exit_actions_setup',
            label: (
                <LocaleMessage msg="page.survey_steps.form.label.exit_action_setup" />
            ),
            content: renderExitActionSetup(),
        },
    };

    const input_steps = [
        step_settings.step_visual,
        step_settings.content_setup,
        step_settings.answer_setup,
        // step_settings.formatter_setup,
        step_settings.integration_setup,
        step_settings.conditions_setup,
    ];

    const start_steps = [step_settings.start_setup, ...input_steps];

    const display_steps = [
        step_settings.step_visual,
        step_settings.content_setup,
        step_settings.conditions_setup,
    ];

    const end_steps = [
        ...(body.skip
            ? []
            : [step_settings.step_visual, step_settings.content_setup]),
        step_settings.exit_action_setup,
    ];

    const type_settings = {
        start: start_steps,
        question: input_steps,
        input: input_steps,
        display: display_steps,
        end: end_steps,
    };

    function renderSettings() {
        const steps =
            body.type && type_settings[body.type]
                ? type_settings[body.type]
                : [];

        return (
            <CardSideBordered
                title={
                    <LocaleMessage msg="page.survey_steps.form.label.step_settings" />
                }
                Icon={MdPlaylistAdd}
                hide
            >
                <>
                    <FormStepper
                        steps={steps}
                        activeStep={activeStep}
                        handleStepClick={index => handleStepClick(index)}
                        handleBack={() => handleBack()}
                        handleNext={() => handleNext()}
                        handleSubmit={() => handleSubmit()}
                    />
                    <Button
                        className="mt-3 mb-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </>
            </CardSideBordered>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.survey_steps.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.survey_steps.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
                {
                    url: `/plugins/${plugin_id}`,
                    title: <LocaleMessage msg="breadcrumbs.survey" />,
                },
                {
                    url: `/pluginconfig/${plugin_id}/survey/${id}`,
                    title: pluginConfig ? pluginConfig.name : '---',
                },
            ]}
            loading={isLoading}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-10 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.survey_steps.title" />
                            }
                            Icon={MdPlaylistAdd}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-4 col-12 mb-5">
                                        <TextField
                                            id="step-description"
                                            label={
                                                <LocaleMessage msg="page.survey_steps.form.label.step_description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 col-6 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.survey_steps.form.label.step_type" />
                                            </InputLabel>
                                            <Select
                                                id="step-type"
                                                value={
                                                    body.type ? body.type : ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        type:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                {step_types.map(s => {
                                                    return hasStart &&
                                                        s.value ===
                                                            'start' ? null : (
                                                        <MenuItem
                                                            value={s.value}
                                                            key={s.value}
                                                        >
                                                            {s.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {body.type === 'end' ? (
                                        <div className="col-md-4 col-6 mb-3">
                                            <FormSwitch
                                                value={body.skip || false}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        skip:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                                label={
                                                    <LocaleMessage msg="page.survey_steps.form.label.skip_step" />
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    {body.type !== 'end' || !body.skip ? (
                                        <div className="col-md-4 col-6 mb-3">
                                            <TextField
                                                id="step-timeout"
                                                label={
                                                    <LocaleMessage msg="page.survey_steps.form.label.step_timeout" />
                                                }
                                                fullWidth
                                                value={
                                                    body.timeout ||
                                                    body.timeout === 0
                                                        ? body.timeout
                                                        : 30
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        timeout:
                                                            event.target.value,
                                                    })
                                                }
                                                helperText={
                                                    <LocaleMessage msg="page.survey_steps.form.label.step_timeout.default" />
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    {/* <div className="col-md-8 col-12 mb-3">
                                        <FormSwitch
                                            value={body.allow_back || false}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    allow_back:
                                                        event.target.checked,
                                                })
                                            }
                                            label={
                                                <LocaleMessage msg="page.survey_steps.form.label.allow_back" />
                                            }
                                        />
                                    </div> */}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {body.type ? (
                        <>
                            <div className="col-md-10 col-12 mb-5">
                                {renderSettings()}
                            </div>
                        </>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

SurveyStepForm.propTypes = {
    match: PropTypes.object.isRequired,
};
