/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import numeral from 'numeral';
import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import {
    TotalContainer,
    ChartsContainer,
    InfoContainer,
    TableArea,
    RowArea,
} from '../../style';
import ScriptDetailView from './ScriptDetail';

export default function ScriptRuns({
    settings,
    headerSettings,
    requestError,
    counterData,
    CustomTimeTooltip,
    language,
}) {
    const lng_all = lng_labels[language] || {};

    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [scripts, setScripts] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const [currScript, setCurrScript] = useState(null);
    const [filteredRunsList, setFilteredRunsList] = useState([]);

    const headers_file = [
        { key: 'group_name', label: 'Group' },
        { key: 'script_name', label: 'Script' },
        { key: 'robot_name', label: 'Robot' },
        { key: 'application_name', label: 'Application' },
        { key: 'language_label', label: 'Language' },
        {
            key: 'start_date',
            label: 'Start Date',
        },
        {
            key: 'start_time',
            label: 'Start Time',
        },
        {
            key: 'end_date',
            label: 'End Date',
        },
        {
            key: 'end_time',
            label: 'End Time',
        },
        {
            key: 'finished',
            label: 'Was Finished',
        },
    ];

    const headers_table = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'script_name',
            label: (
                <LocaleMessage msg="page.reports.actions.script_runs.label.script" />
            ),
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'language_label',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'was_finished',
            order_by: 'finished',
            label: <LocaleMessage msg="table.headers.was_finished" />,
        },
    ];

    const header_scripts = [
        {
            id: 'name',
            label: (
                <LocaleMessage msg="page.reports.actions.script_runs.label.script" />
            ),
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    function filterData(data) {
        const script_list = {};
        const { robots, robot_types, groups, applications } = counterData;
        const { script_runs, period } = data;
        if (!script_runs) return;
        const runs_list = script_runs ? script_runs.list : [];

        const s_groups = [];
        const s_robots = [];
        const s_r_types = [];
        const s_applications = [];
        const s_scripts = [];
        const s_languages = [];
        const s_s_date = [];
        const s_s_time = [];
        let longest = 0;

        const dlist = runs_list.map(r => {
            const s_time = r.time;
            const start = GetDateTimeLabel(new Date(r.start), { format });
            const end = r.end
                ? GetDateTimeLabel(new Date(r.end), { format })
                : null;

            const robot = robots[r.robot_id];
            const r_type = robot_types[r.robottype_id];
            const group = groups[r.group_id];
            const application = applications[r.application_id];

            if (!robots[r.robot_id] || robots[r.robot_id].code === '---') {
                robots[r.robot_id] = { code: r.robot_code || '---', name: '' };
            }

            const m_time = s_time / 60;
            const m_time_label = Math.floor(m_time);
            if (m_time_label > longest) {
                longest = m_time_label;
            }

            if (!script_list[r.script_id]) {
                script_list[r.script_id] = {
                    id: r.script_id,
                    name: r.script_name,
                };
            }

            s_groups[r.group_id] = {
                total: s_groups[r.group_id]
                    ? s_groups[r.group_id].total + 1
                    : 1,
            };

            s_robots[r.robot_id] = {
                total: s_robots[r.robot_id]
                    ? s_robots[r.robot_id].total + 1
                    : 1,
            };

            s_r_types[r.robottype_id] = s_r_types[r.robottype_id]
                ? s_r_types[r.robottype_id] + 1
                : 1;

            s_scripts[r.script_id] = {
                total: s_scripts[r.script_id]
                    ? s_scripts[r.script_id].total + 1
                    : 1,
            };

            s_applications[r.application_id] = {
                total: s_applications[r.application_id]
                    ? s_applications[r.application_id].total + 1
                    : 1,
            };

            s_languages[r.language || '---'] = {
                total: s_languages[r.language || '---']
                    ? s_languages[r.language || '---'].total + 1
                    : 1,
            };

            s_s_date[start.date] = s_s_date[start.date]
                ? s_s_date[start.date] + 1
                : 1;
            s_s_time[start.hour] = s_s_time[start.hour]
                ? s_s_time[start.hour] + 1
                : 1;

            return {
                ...r,
                id: r.id,
                language_label: lng_all[`list.languages.${r.language}`],
                group_name: group ? group.name : '---',
                robot_name: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${r.robot_code || '---'}]`,
                application_name: application ? application.name : '---',
                robottype: r_type ? r_type.name : '---',
                start_date: start.date,
                start_time: start.time,
                start: start.string,
                start_timestamp: start.timestamp,
                end_date: end ? end.date : '',
                end_time: end ? end.time : '',
                end: end ? end.string : '',
                end_timestamp: end ? end.timestamp : '',
                was_finished: r.finished ? (
                    <LocaleMessage msg="label.yes" />
                ) : (
                    <LocaleMessage msg="label.no" />
                ),
            };
        });

        const group_grouped = Object.keys(s_groups).map(g => {
            const val = s_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
            };
        });

        const robot_grouped = Object.keys(s_robots).map(r => {
            const val = s_robots[r];
            const obj = robots[r];

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                total: val ? val.total : 0,
            };
        });

        const rtype_grouped = Object.keys(s_r_types).map(t => {
            const val = s_r_types[t];
            const obj = robot_types[t];

            return {
                name: obj ? obj.name : '---',
                total: val,
            };
        });

        const script_grouped = Object.keys(s_scripts).map(s => {
            const val = s_scripts[s];
            const obj = script_list[s] || {};

            return {
                id: s,
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
            };
        });

        const application_grouped = Object.keys(s_applications).map(a => {
            const val = s_applications[a];
            const obj = applications[a];

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
            };
        });

        const language_grouped = Object.keys(s_languages).map(l => {
            const val = s_languages[l];

            return {
                id: l,
                name: l.toUpperCase(),
                total: val ? val.total : 0,
            };
        });

        const period_dates = GetPeriodDates(period);

        const s_labels = [];
        const s_date_grouped = [];

        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });

            s_labels.push(label_date);

            s_date_grouped.push({
                date: label_date,
                value: s_s_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const s_time_grouped = [];
        const m_time_grouped = [];

        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);

            s_time_grouped.push({
                hour: label_time,
                value: s_s_time[t] || 0,
            });
        }

        setScripts(script_list);

        setGroupedData({
            groups: group_grouped,
            robots: robot_grouped,
            robot_types: rtype_grouped,
            applications: application_grouped,
            scripts: script_grouped,
            languages: language_grouped,
            start_date: s_date_grouped,
            start_time: s_time_grouped,
            op_time: m_time_grouped,
            s_labels,
            t_labels,
        });

        setList(dlist);
    }

    async function loadData() {
        setIsLoading(true);

        await api
            .get(
                `reports/script_runs?mode=conversation&from=${fromISO}&until=${untilISO}`
            )
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleScriptSelection(s) {
        const sel_script = scripts[s];
        setCurrScript(
            s
                ? {
                      id: s,
                      ...sel_script,
                  }
                : null
        );
        if (!s) {
            setFilteredRunsList(list);
            return;
        }

        const currList = list.filter(c => {
            return c.script_id === s;
        });
        setFilteredRunsList(currList);
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '200px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="start_timestamp"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    sortable
                    hasFilter
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function renderScriptDetails() {
        const total = filteredRunsList.length;
        const completed = filteredRunsList.filter(s => {
            return s.finished;
        }).length;

        const ans_rate = total
            ? numeral((100 * completed) / total).format('0.0')
            : '0';
        return currScript ? (
            <>
                <RowArea className="mt-5 mb-3">
                    <div>
                        <p className="card-title">
                            {currScript ? currScript.name : ''}
                        </p>
                    </div>
                </RowArea>
                <TotalContainer className="row mt-3 mb-3">
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.script_runs.title" />
                        }
                        value={total}
                    />
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.script_runs.label.completed" />
                        }
                        value={completed}
                    />
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.script_runs.label.completion_rate" />
                        }
                        value={`${ans_rate || '0'}%`}
                    />
                </TotalContainer>
                <Divider />
                <ScriptDetailView
                    script={currScript}
                    settings={settings}
                    data={filteredRunsList}
                    active={active}
                    colors={colors}
                    from={fromISO}
                    until={untilISO}
                    timeTooltip={CustomTimeTooltip}
                    requestError={e => requestError(e)}
                />
            </>
        ) : null;
    }

    function renderCSVButton() {
        return (
            <ExportCSVButton
                data={list}
                headers={headers_file}
                filename={GetFileName(`REPORTS-action_scripts`, 'csv')}
            />
        );
    }

    useEffect(() => {
        filterData(rawData);
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const script_runs =
        rawData && rawData.script_runs ? rawData.script_runs.total : 0;

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'per_application',
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'per_robot',
            data: groupedData.robots,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            data: groupedData.robot_types,
        },
        {
            key: 'scripts',
            title: 'per_script',
            data: groupedData.scripts,
        },
    ];

    const script_filter = groupedData.scripts || [];

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={renderCSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.script_runs.label.total" />
                                }
                                value={script_runs}
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map((c, idx) => {
                                return (
                                    <ReportPieChart
                                        key={`${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.script_runs.label.${c.title}`}
                                            />
                                        }
                                        color={
                                            idx % 2 === 0
                                                ? colors.dashboard_cards
                                                : colors.dashboard_graphs
                                        }
                                        data={c.data}
                                        dataKey="total"
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.script_runs.label.per_s_date" />,
                                groupedData.start_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.script_runs.label.per_s_hour" />,
                                groupedData.start_time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <ChartsContainer className="row mt-5 mb-3">
                                <ReportPieChart
                                    classes="col-md-4 col-12 mb-5"
                                    title={
                                        <LocaleMessage msg="page.reports.actions.script_runs.label.per_script" />
                                    }
                                    color={colors.dashboard_graphs}
                                    data={groupedData.scripts}
                                    dataKey="total"
                                />
                                <TableArea className="col-md-4 col-12 mb-3 table-responsive-material">
                                    <DataTable
                                        maxHeight="100%"
                                        sortable
                                        orderDirection="desc"
                                        orderColumn="total"
                                        headerColumns={header_scripts}
                                        data={script_filter}
                                        handleTableRowClick={(event, id) => {
                                            handleScriptSelection(id);
                                        }}
                                        hasActions={false}
                                        hasFooter={false}
                                        defaultRowsPerPage={10}
                                        headerData={{
                                            id: null,
                                            name: 'TOTAL',
                                            total: script_runs,
                                        }}
                                    />
                                </TableArea>
                            </ChartsContainer>
                        </InfoContainer>
                        <Divider />
                        {currScript ? (
                            <>{renderScriptDetails()}</>
                        ) : (
                            <RowArea className="mt-3 mb-5">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.actions.script_runs.label.all" />
                                    </p>
                                </div>
                                {buildListView()}
                            </RowArea>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

ScriptRuns.defaultProps = {
    language: 'pt_BR',
};

ScriptRuns.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    language: PropTypes.string,
};
