/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
    MdHelp,
    MdFormatListBulleted,
    MdCloudUpload,
    MdDownload,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tab,
    Tabs,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import QnAExamples from '~/components/QnAExamples';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import GetFileName from '~/util/GetFileName';
import QnALoadFile from '~/util/QnALoadFile';

import { ParameterArea } from '../../styles';

const status = ['unavailable', 'development', 'testing', 'available'];
const default_types = ['question', 'decision', 'reference'];

const content_types = {
    intents: 'intent',
    subjects: 'subject',
    topics: 'topic',
    questions: 'question',
    decisions: 'decision',
    references: 'reference',
};

export default function QnATrainingForm({ match }) {
    const dispatch = useDispatch();
    const content_id = match.params.id;
    const type_key = match.params.type;
    const content_type = content_types[type_key] || '';

    const { qna_languages } = lists;

    const headers_file = qna_languages.map(l => {
        return {
            key: l.value,
            label: l.key.toUpperCase(),
        };
    });

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState({
        type: content_type || '',
        status: 'development',
    });

    const [contentSettings, setContentSettings] = useState({});
    const [currLang, setCurrLang] = useState(
        qna_languages[0] ? qna_languages[0].value : 'en_GB'
    );

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status: resp_status } = error.response;
            if (resp_status === 401) {
                dispatch(expireSession());
            } else if (resp_status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function parseExamplesExport(data) {
        const example_list = {};
        const example_sizes = headers_file.map(l => {
            const lng = l.key;
            const lng_settings = data[lng] || {};
            const examples = lng_settings.training || [];
            example_list[lng] = examples;
            return examples.length;
        });

        const max_size = Math.max(...example_sizes);

        const examples = [];
        for (let i = 0; i < max_size; i++) {
            const row = {};
            headers_file.forEach(l => {
                const lng = l.key;
                const list = example_list[l.key] || [];
                row[lng] = list && list[i] ? list[i] : '';
            });
            examples.push(row);
        }

        return examples;
    }

    async function loadContent(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/qna/admin/contents/${_id}`)
                .then(response => {
                    const q = response.data;
                    const q_settings = q.settings || {};
                    const formBody = {
                        identifier: q.identifier,
                        type: q.type,
                        status: q.status,
                        settings: q_settings,
                    };
                    setContentSettings(q_settings);
                    setBody(formBody);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadContent(content_id);
    }, [content_id]);

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const updatedSettings = QnALoadFile(contentSettings, e);
            setContentSettings(updatedSettings);
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        try {
            reader.readAsText(fileObj, 'UTF-8');
            reader.onload = fileloaded;
        } catch (error) {
            toast.error(
                <LocaleMessage msg="page.qna.content.training.form.examples.error" />
            );
        }
    };

    function setTraining(lang, lng_settings) {
        setContentSettings({
            ...contentSettings,
            [lang]: lng_settings,
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, settings: contentSettings };

        if (operation === 'create') {
            await api
                .post(`/qna/admin/contents`, data)
                .then(r => {
                    toast.success(
                        <LocaleMessage msg="page.qna.content.create_success" />
                    );
                    const created = r.data;
                    history.push(
                        `/pluginbot_qna/training/${type_key}/${created.id}`
                    );
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`/qna/admin/contents/${content_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.qna.content.update_success" />
                    );
                    loadContent(content_id);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function updateExamples(examples) {
        const lng_settings = contentSettings[currLang] || {};
        setTraining(currLang, {
            ...lng_settings,
            training: examples,
        });
    }

    function renderExampleArea() {
        const lng_settings = contentSettings[currLang] || {};
        const examples = lng_settings.training || [''];
        const non_empty = examples.filter(e => {
            return !!e;
        });

        const export_data = parseExamplesExport(contentSettings);

        return (
            <>
                <Button
                    className="p-1 mb-3"
                    component="label"
                    variant="outlined"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<MdCloudUpload size={20} />}
                >
                    <LocaleMessage msg="page.qna.content.training.form.examples.upload" />
                    <input
                        hidden
                        accept="text/csv"
                        type="file"
                        onChange={onFileUpload}
                    />
                </Button>
                <CSVLink
                    data={export_data}
                    headers={headers_file}
                    filename={GetFileName(
                        `QNA-TRAINING-${content_type.toUpperCase()}-${
                            body ? body.identifier : ''
                        }`,
                        'csv'
                    )}
                    onClick={() => {
                        toast.info(
                            <LocaleMessage msg="message.generating_file" />
                        );
                    }}
                >
                    <Button
                        className="p-1 mb-3"
                        component="label"
                        variant="outlined"
                        color="primary"
                        size="large"
                        fullWidth
                        startIcon={<MdDownload size={20} />}
                    >
                        <LocaleMessage msg="page.qna.content.training.form.examples.export" />
                    </Button>
                </CSVLink>
                <Box
                    style={{
                        border: '1px solid #ddd',
                    }}
                >
                    <Tabs
                        indicatorColor="primary"
                        centered
                        value={currLang}
                        onChange={(e, v) => {
                            setCurrLang(v);
                        }}
                    >
                        {qna_languages.map(l => {
                            return (
                                <Tab
                                    key={l.key}
                                    value={l.value}
                                    label={
                                        <small>
                                            <LocaleMessage msg={l.label} />
                                        </small>
                                    }
                                />
                            );
                        })}
                    </Tabs>
                    <div style={{ flex: 1, padding: '30px 10px 10px 10px' }}>
                        <QnAExamples
                            size="col-md-6"
                            content={body}
                            contentId={content_id}
                            language={currLang}
                            examples={examples}
                            handleError={e => requestError(e)}
                            updateExamples={e => updateExamples(e)}
                            allowSuggestions={non_empty.length >= 3}
                        />
                    </div>
                </Box>
            </>
        );
    }

    function renderLanguageSettings() {
        return (
            <>
                <h4>
                    <LocaleMessage msg="page.qna.content.display_name" />
                </h4>
                <ParameterArea className="mt-2">
                    {qna_languages.map(l => {
                        const lng_settings = contentSettings[l.value] || {};
                        return (
                            <div
                                className="col-12"
                                key={`display_name_${l.value}`}
                            >
                                <TextField
                                    fullWidth
                                    className="mb-3"
                                    label={<LocaleMessage msg={l.label} />}
                                    value={lng_settings.display_name || ''}
                                    onChange={event =>
                                        setContentSettings({
                                            ...contentSettings,
                                            [l.value]: {
                                                ...lng_settings,
                                                display_name:
                                                    event.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        );
                    })}
                </ParameterArea>
            </>
        );
    }

    function renderContentForm() {
        return (
            <>
                <div className="col-12 row">
                    <div className="col-md-6 col-12">
                        <TextField
                            className="mb-5"
                            label={
                                <LocaleMessage msg="page.qna.content.training.form.identifier" />
                            }
                            fullWidth
                            value={body.identifier || ''}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    identifier: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="label.status" />
                            </InputLabel>
                            <Select
                                value={body.status || 'development'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        status: event.target.value,
                                    })
                                }
                                disabled={operation === 'create'}
                            >
                                {status.map(s => {
                                    return (
                                        <MenuItem value={s} key={`status_${s}`}>
                                            <LocaleMessage
                                                msg={`list.status.${s}`}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {type_key === 'defaults' ? (
                    <div className="col-md-6 col-12">
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="page.qna.content.defaults.type" />
                            </InputLabel>
                            <Select
                                value={body.type || ''}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        type: event.target.value,
                                    })
                                }
                                disabled={operation === 'update'}
                            >
                                {default_types.map(type => {
                                    return (
                                        <MenuItem
                                            value={type}
                                            key={`types_${type}`}
                                        >
                                            <LocaleMessage
                                                msg={`page.qna.content.${type}`}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}
                <div className="col-12 mb-5" style={{ padding: '20px 15px' }}>
                    {renderLanguageSettings()}
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage msg={`page.qna.content.${operation}.title`} />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginbot_qna',
                    title: <LocaleMessage msg="page.qna.title" />,
                },
                {
                    url: `/pluginbot_qna/training#tab=${type_key}`,
                    title: (
                        <LocaleMessage
                            msg={`page.qna.content.${content_type ||
                                type_key}.add`}
                        />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="row full-body mt-5">
                    <div
                        className={`${
                            operation === 'update' ? 'col-md-6' : 'col-md-8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage
                                    msg={`page.qna.content.${type_key}`}
                                />
                            }
                            Icon={MdHelp}
                        >
                            <>
                                {renderContentForm()}
                                <div className="col-12">
                                    <Button
                                        className="p-3 mb-3"
                                        variant="contained"
                                        color="primary"
                                        onClick={event => handleSubmit(event)}
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.save" />
                                    </Button>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.qna.content.examples" />
                                }
                                hide
                                Icon={MdFormatListBulleted}
                            >
                                {renderExampleArea()}
                            </CardSideBordered>
                        </div>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

QnATrainingForm.propTypes = {
    match: PropTypes.object.isRequired,
};
