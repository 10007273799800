/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import {
    Chip,
    Divider,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import nao_sensors from '~/assets/images/robots/sensors/nao.jpg';

import { SensorsArea, Option } from './styles';

const sensor_img = {
    nao: nao_sensors,
};

const default_actions = {
    no_action: {
        value: 'no_action',
        label_code: 'page.tools.scripts.step.form.exit.no_action',
        modes: {
            multi: true,
        },
    },
    return_home: {
        value: 'return_home',
        label_code: 'page.applications.scripting.form.action.return_home',
        modes: {
            multi: true,
        },
    },
    return_selector: {
        value: 'return_selector',
        label_code: 'page.applications.scripting.form.action.return_selector',
        modes: {
            multi: true,
        },
    },
    pluginspace: {
        value: 'pluginspace',
        label_code: 'page.applications.scripting.form.action.pluginspace',
        modes: {
            single: true,
            multi: true,
        },
    },
};

const robot_defaults = {
    nao: ['no_action', 'return_home', 'return_selector', 'pluginspace'],
};

const center = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function ScriptsConfig({
    robot,
    defaultGalleries,
    config,
    setConfig,
    scriptLists,
}) {
    const robot_type = robot ? robot.slug : null;
    const robot_settings = robot ? robot.settings : {};
    const robot_hardware =
        robot_settings && robot_settings.hardware
            ? robot_settings.hardware
            : {};
    const available_exits = robot_defaults[robot_type] || ['return_home'];

    function compare(a, b) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    const robotSensors = robot_hardware
        ? Object.keys(robot_hardware).map(s => {
              const sensor = robot_hardware[s];
              return sensor;
          })
        : [];
    const sortedSensors = robotSensors.sort(compare);

    const scriptSettings = config.scripts || {};

    function setScriptSettings(trigger, key, val) {
        const def_val = scriptSettings[trigger];
        const scr =
            def_val && typeof def_val === 'object'
                ? def_val
                : {
                      action_class: 'no_action',
                      value: 'no_action',
                  };

        scr[key] = val;
        if (key === 'action_class') {
            if (
                val === 'no_action' ||
                val === 'return_home' ||
                val === 'return_selector'
            ) {
                scr.value = val;
            }
        }
        setConfig({
            ...config,
            scripts: {
                ...scriptSettings,
                [trigger]: scr,
            },
        });
    }

    function renderDefaultOptions(opt, onChange) {
        const { action_class } = opt;
        const showList =
            action_class &&
            action_class !== 'no_action' &&
            action_class !== 'return_selector' &&
            action_class !== 'return_home';

        const list =
            scriptLists && scriptLists[action_class]
                ? scriptLists[action_class]
                : [];

        return (
            <div
                className="row"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <div className="col-md-6 col-12 mb-1">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.scripting.form.action.class" />
                        </InputLabel>
                        <Select
                            value={
                                opt && opt.action_class
                                    ? opt.action_class
                                    : 'no_action'
                            }
                            onChange={event => onChange(event, 'action_class')}
                        >
                            {available_exits.map(e => {
                                const exit_cond = default_actions[e];
                                const mode_available =
                                    exit_cond &&
                                    exit_cond.modes &&
                                    exit_cond.modes[config.mode];
                                return mode_available ? (
                                    <MenuItem
                                        key={`exit_${exit_cond.value}`}
                                        value={exit_cond.value}
                                    >
                                        <LocaleMessage
                                            msg={exit_cond.label_code}
                                        />
                                    </MenuItem>
                                ) : null;
                            })}
                            {defaultGalleries.map(s => (
                                <MenuItem key={s.id} value={s.id}>
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                    {s.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {showList ? (
                    <div className="col-md-6 col-12 mb-1">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.tools.scripts.step.form.action.title" />
                            </InputLabel>
                            <Select
                                value={
                                    opt && opt.value ? opt.value : 'no_action'
                                }
                                onChange={event => onChange(event, 'value')}
                            >
                                {list.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}
            </div>
        );
    }

    function renderSensorItem(item) {
        return (
            <Option className="row mt-3 p-1" key={item.item_id}>
                <div
                    className="col-1 mb-3"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {item.order}.
                </div>
                <div
                    className="col-md-5 col-11 mb-3"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LocaleMessage msg={item.label} />
                </div>
                {renderDefaultOptions(
                    scriptSettings && scriptSettings[item.value]
                        ? scriptSettings[item.value]
                        : { action_class: 'no_action', value: 'no_action' },
                    (event, key) =>
                        setScriptSettings(item.value, key, event.target.value)
                )}
            </Option>
        );
    }

    function renderMultiScriptApp() {
        return (
            <SensorsArea className="row col-12 mt-3">
                <div className="col-md-5 col-12 container">
                    <img
                        src={sensor_img[robot_type]}
                        alt={`sensors_${robot_type}`}
                    />
                </div>
                <Divider orientation="vertical" flexItem />
                <div className="col-md-5 col-12 container">
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel>
                            <LocaleMessage msg="page.tools.scripts.step.form.exit.sensors" />
                        </FormLabel>
                        {sortedSensors.map(item => {
                            return renderSensorItem(item);
                        })}
                    </FormControl>
                </div>
            </SensorsArea>
        );
    }

    function renderSingleScriptApp() {
        return (
            <Option className="col-md-8 col-12" style={{ ...center }}>
                <div className="col-12 mb-1" style={center}>
                    {renderDefaultOptions(
                        scriptSettings && scriptSettings.main
                            ? scriptSettings.main
                            : { action_class: 'no_action', value: 'no_action' },
                        (event, key) =>
                            setScriptSettings('main', key, event.target.value)
                    )}
                </div>
            </Option>
        );
    }

    function renderAppContent() {
        switch (config.mode) {
            case 'multi':
                return renderMultiScriptApp();
            case 'single':
            default:
                return renderSingleScriptApp();
        }
    }

    return renderAppContent();
}

ScriptsConfig.defaultProps = {
    config: {},
    scriptLists: {},
    defaultGalleries: [],
};

ScriptsConfig.propTypes = {
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
    robot: PropTypes.object.isRequired,
    scriptLists: PropTypes.object,
    defaultGalleries: PropTypes.array,
};
