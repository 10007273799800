/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdRefresh } from 'react-icons/md';

import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import LocaleMessage from '~/components/LocaleMessage';

import { DatesContainer } from './style';

export default function ReportHeader(props) {
    const {
        date_props,
        period,
        handleFromDateChange,
        handleUntilDateChange,
        reloadData,
        exportButton,
        shortcuts,
        handleShortcutClick,
    } = props;
    const date_loc = date_props.locale;

    const { minDate, fromDate, untilDate } = period;

    function renderShortcuts(buttons) {
        const { list, active } = buttons;
        return (
            <div
                className="col-12 row shortcuts-row"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {list
                    ? Object.keys(list).map(s => {
                          const b = list[s];
                          return (
                              <div
                                  className="col-md-2 col-4 mb-3"
                                  key={`shortcut_${s}`}
                              >
                                  <Button
                                      fullWidth
                                      variant={
                                          active === s
                                              ? 'contained'
                                              : 'outlined'
                                      }
                                      size="small"
                                      color="primary"
                                      onClick={() => handleShortcutClick(s)}
                                  >
                                      {b}
                                  </Button>
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    }

    return (
        <>
            {shortcuts ? renderShortcuts(shortcuts) : null}
            <div
                className="sidecard-header"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px',
                }}
            >
                <DatesContainer className="col-9" style={{ margin: '0px' }}>
                    <div className="row picker-row">
                        <div className="col-4">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={date_loc}
                            >
                                <DatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    label={
                                        <LocaleMessage msg="label.form.from" />
                                    }
                                    format="dd MMM yyyy"
                                    value={fromDate || new Date()}
                                    onChange={handleFromDateChange}
                                    disableFuture
                                    autoOk
                                    minDate={minDate || undefined}
                                    ampm={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-4">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={date_loc}
                            >
                                <DatePicker
                                    variant="inline"
                                    inputVariant="outlined"
                                    label={
                                        <LocaleMessage msg="label.form.until" />
                                    }
                                    format="dd MMM yyyy"
                                    value={untilDate || new Date()}
                                    onChange={handleUntilDateChange}
                                    disableFuture
                                    autoOk
                                    ampm={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </DatesContainer>

                <div
                    className="col-3"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Button
                        className="mb-3"
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<MdRefresh style={{ color: '#fff' }} />}
                        onClick={reloadData}
                    >
                        <LocaleMessage msg="page.dialogs.info.logs.label.refresh" />
                    </Button>
                    {exportButton || null}
                </div>
            </div>
        </>
    );
}

ReportHeader.defaultProps = {
    exportButton: null,
    shortcuts: null,
    handleShortcutClick: () => {},
};

ReportHeader.propTypes = {
    date_props: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
    reloadData: PropTypes.func.isRequired,
    handleFromDateChange: PropTypes.func.isRequired,
    handleUntilDateChange: PropTypes.func.isRequired,
    exportButton: PropTypes.element,
    shortcuts: PropTypes.object,
    handleShortcutClick: PropTypes.func,
};
