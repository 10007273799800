/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdEdit } from 'react-icons/md';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import pbLogo from '~/assets/logo-white.png';

import { Container } from './styles';

export default function LogoInput(props) {
    const { defaultValue, onFileUpload } = props;
    const file = defaultValue.id || null;
    const preview = defaultValue.url || null;

    return (
        <Container>
            <label htmlFor="pluginspace-logo">
                <span className="mb-3">
                    <MdEdit />
                    <LocaleMessage msg="page.pluginspace.form.label.logo" />
                </span>
                <div className="logo-input mb-3">
                    <img
                        src={preview || pbLogo}
                        alt={preview ? 'Logo' : 'Pluginbot Logo'}
                    />
                </div>
                <input
                    type="file"
                    id="pluginspace-logo"
                    accept="image/png"
                    onChange={e => onFileUpload(e)}
                    data-file={file}
                />
            </label>
        </Container>
    );
}

LogoInput.propTypes = {
    onFileUpload: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
};
