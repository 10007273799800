/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Timeline } from 'react-event-timeline';

import PropTypes from 'prop-types';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import { TimelinePanel, TitleDiv, ListDiv } from './styles';
import TimelineItem from './TimelineItem';

export default function RobotTimeline({
    isLoading,
    eventsOpen,
    robot,
    items,
    dateFormat,
    loadMore,
    hasMore,
    smallScreen,
    handleShowClick,
}) {
    const handleScroll = event => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        if (scrollHeight - scrollTop === clientHeight) {
            if (hasMore) {
                loadMore();
            }
        }
    };

    return (
        <TimelinePanel smallScreen={smallScreen} open={eventsOpen}>
            <TitleDiv
                smallScreen={smallScreen}
                onClick={() => {
                    handleShowClick();
                }}
            >
                <h3>
                    <LocaleMessage msg="rc3.robot.events.title" />
                </h3>
            </TitleDiv>
            <ListDiv
                smallScreen={smallScreen}
                open={eventsOpen || !smallScreen}
            >
                {eventsOpen || !smallScreen ? (
                    <CustomScrollbars onScroll={handleScroll}>
                        {robot && items && items.length > 0 ? (
                            <>
                                <Timeline
                                    style={{
                                        paddingTop: '0px',
                                    }}
                                    lineStyle={{ marginTop: '30px' }}
                                    lineColor="#555"
                                >
                                    <>
                                        {items.map(item => {
                                            return (
                                                <TimelineItem
                                                    key={item.id}
                                                    item={item}
                                                    dateFormat={dateFormat}
                                                />
                                            );
                                        })}
                                    </>
                                </Timeline>
                                {isLoading ? <Splash /> : null}
                            </>
                        ) : null}
                    </CustomScrollbars>
                ) : null}
            </ListDiv>
        </TimelinePanel>
    );
}

RobotTimeline.propTypes = {
    eventsOpen: PropTypes.bool,
    smallScreen: PropTypes.bool,
    isLoading: PropTypes.bool,
    robot: PropTypes.object,
    items: PropTypes.array,
    dateFormat: PropTypes.object,
    loadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    handleShowClick: PropTypes.func.isRequired,
};

RobotTimeline.defaultProps = {
    eventsOpen: true,
    smallScreen: false,
    isLoading: true,
    hasMore: true,
    robot: {},
    items: [],
    dateFormat: {},
    loadMore: () => {},
};
