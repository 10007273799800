import styled from 'styled-components';

export const Container = styled.div`
    a {
        text-decoration: none !important;
        color: #343a40 !important;
    }

    .one-item {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }

    .two-items {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;
    }

    .vertical-items {
        display: flex;
        flex-direction: column;
    }
`;
