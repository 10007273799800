/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdVpnKey } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';

export default function APIkeys(props) {
    const { match } = props;
    const { id } = match.params;

    const [isLoading, setIsLoading] = useState(true);
    const [app, setApp] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadApplication(_id) {
        if (_id) {
            await api
                .get(`applications/${_id}`)
                .then(async response => {
                    const a = response.data;
                    setApp({
                        name: a.name,
                        description: a.description,
                        apptype_id: a.app_type.id,
                        apikey: a.apikey,
                    });

                    // const { settings } = a;
                    // const { vision } = settings;

                    // const settingsOBJ = {
                    //     vision_face:
                    //         vision &&
                    //         vision.faceRecognition &&
                    //         vision.faceRecognition.active
                    //             ? vision.faceRecognition.active
                    //             : false,
                    // };

                    setTimeout(() => {
                        setIsLoading(false);
                    }, 100);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadApplication(id);
    }, [id]);

    return (
        <PageContent
            title={<LocaleMessage msg="page.applications.apidetails.title" />}
            currentPage={
                <LocaleMessage msg="page.applications.apidetails.title" />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/applications',
                    title: <LocaleMessage msg="breadcrumbs.applications" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                <div className="col-md-10 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.applications.apidetails.title" />
                        }
                        Icon={MdVpnKey}
                    >
                        <>
                            <div style={{ padding: '15px' }}>
                                <span>
                                    <strong>
                                        <LocaleMessage msg="page.applications.apidetails.app_name" />
                                    </strong>
                                </span>
                                <p>{app.name || '---'}</p>

                                <span>
                                    <strong>
                                        <LocaleMessage msg="page.applications.apidetails.app_url" />
                                    </strong>
                                </span>
                                <p>{`${process.env.REACT_APP_API_URL}/tools/apps/${id}`}</p>

                                <span>
                                    <strong>
                                        <LocaleMessage msg="page.applications.apidetails.api_key" />
                                    </strong>
                                </span>
                                <p>
                                    {app.apikey || (
                                        <LocaleMessage msg="page.applications.apidetails.unavailable" />
                                    )}
                                </p>

                                {/* <span>
                                    <a>See API Reference</a>
                                </span> */}
                            </div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

APIkeys.propTypes = {
    match: PropTypes.object.isRequired,
};
