/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';

export default function QnATrigger({
    dialogSettings,
    handleError,
    config,
    onChange,
}) {
    const { id: dialog_id, group: dialog_group } = dialogSettings;

    const [requiresConfig, setRequiresConfig] = useState(false);
    const [pluginconfigList, setPluginconfigList] = useState([]);
    const [plugintypeList, setPlugintypeList] = useState([]);
    const [triggerList, setTriggerList] = useState([]);
    const [actionList, setActionList] = useState([]);

    async function loadTriggers() {
        await api
            .get(`triggers?dialog_id=${dialog_id}`)
            .then(response => {
                const filtered = response.data.filter(t => {
                    return dialog_group && t.group_id === dialog_group.id;
                });
                const data = filtered.map(t => {
                    return {
                        ...t,
                        label: (
                            <>
                                <LocaleMessage msg={t.action} />
                                {t.pluginconfig ? (
                                    <span>{` (${t.pluginconfig})`}</span>
                                ) : (
                                    ''
                                )}
                            </>
                        ),
                        pluginconfig: t.pluginconfig || '---',
                    };
                });
                setTriggerList(data);
            })
            .catch(error => handleError(error));
    }

    async function loadPlugintypes() {
        await api
            .get(`pluginspaces/plugins`)
            .then(response => {
                const { data } = response;
                const available = data.filter(plugin => {
                    const p_settings = plugin.settings;
                    const availability = p_settings.availability || {};
                    return availability.conversation_apps;
                });
                setPlugintypeList(available);
            })
            .catch(error => handleError(error));
    }

    async function loadActions() {
        try {
            const response = await api.get(
                `pluginspaces/actions?allow_trigger=true`
            );

            const { data } = response;
            setActionList(data);
            return;
        } catch (err) {
            handleError(err);
        }
    }

    async function loadPluginconfigs(action_id) {
        await api
            .get(`pluginconfigs?action_id=${action_id}`)
            .then(response => {
                const filtered = response.data.filter(c => {
                    return dialog_group && c.group_id === dialog_group.id;
                });
                setPluginconfigList(filtered);
            })
            .catch(error => handleError(error));
    }

    async function onActionChanged(val) {
        const action = actionList.find(a => {
            return a.id === val;
        });
        const require_config = action ? action.require_config : false;

        setRequiresConfig(require_config || false);
        if (require_config) {
            await loadPluginconfigs(val);
        } else {
            setPluginconfigList([]);
        }
    }

    useEffect(() => {
        loadPlugintypes();
        loadActions();
    }, []);

    useEffect(() => {
        loadTriggers();
    }, [dialog_id]);

    useEffect(() => {
        if (config.id === 'new') {
            onChange({
                id: config.id,
            });
        } else {
            const curr_trigger = triggerList.find(t => {
                return t.id === config.id;
            });
            if (curr_trigger) {
                onChange({
                    ...config,
                    action_id: curr_trigger.action_id,
                    pluginconfig_id: curr_trigger.pluginconfig_id,
                    plugintype_id: curr_trigger.plugintype_id,
                });
            }
        }
    }, [config.id]);

    useEffect(() => {
        onActionChanged(config.action_id);
    }, [actionList, config.action_id]);

    function handleActionChange(event) {
        const val = event.target.value;
        onChange({ ...config, action_id: val, pluginconfig_id: null });
    }

    function renderTriggerForm() {
        const filter = config.plugintype_id || '';
        const filteredAction = actionList.filter(a => {
            return a.plugintype_id === filter;
        });

        return (
            <ParameterArea className="col-md-8 col-12 row mt-1 p-3">
                <div
                    className="col-12 row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <FormControl className="mb-3" fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.triggers.label.plugintype" />
                        </InputLabel>
                        <Select
                            value={
                                config && config.plugintype_id
                                    ? config.plugintype_id
                                    : ''
                            }
                            onChange={event =>
                                onChange({
                                    ...config,
                                    plugintype_id: event.target.value,
                                })
                            }
                            disabled={config.id && config.id !== 'new'}
                        >
                            {plugintypeList.map(p => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {config && config.plugintype_id ? (
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.label.action" />
                            </InputLabel>
                            <Select
                                value={
                                    config && config.action_id
                                        ? config.action_id
                                        : ''
                                }
                                onChange={event => handleActionChange(event)}
                                disabled={config.id && config.id !== 'new'}
                            >
                                {filteredAction.map(a => (
                                    <MenuItem key={a.id} value={a.id}>
                                        <LocaleMessage msg={a.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                    {config && config.action_id && requiresConfig ? (
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.label.pluginconfig" />
                            </InputLabel>
                            <Select
                                value={
                                    config && config.pluginconfig_id
                                        ? config.pluginconfig_id
                                        : ''
                                }
                                onChange={event =>
                                    onChange({
                                        ...config,
                                        pluginconfig_id: event.target.value,
                                    })
                                }
                                disabled={config.id && config.id !== 'new'}
                            >
                                {pluginconfigList.map(c => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}
                </div>
            </ParameterArea>
        );
    }

    return (
        <>
            <div className="col-md-8 col-12 mt-4">
                <FormControl fullWidth className="mb-4">
                    <InputLabel>
                        <LocaleMessage msg="page.qna.nodes.form.action" />
                    </InputLabel>
                    <Select
                        value={config.id || 'new'}
                        onChange={event => {
                            onChange({
                                ...config,
                                id: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value="new">
                            <LocaleMessage msg="page.qna.nodes.form.action.trigger.new" />
                        </MenuItem>
                        {triggerList.map(t => {
                            return (
                                <MenuItem value={t.id} key={`trigger_${t.id}`}>
                                    {t.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            {renderTriggerForm()}
        </>
    );
}

QnATrigger.defaultProps = {
    dialogSettings: {},
    config: {},
};

QnATrigger.propTypes = {
    dialogSettings: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    config: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};
