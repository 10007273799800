/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdHelp } from 'react-icons/md';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import useWindowDimensions from '~/hooks/useWindowDimensions';

import { Background, AppFooter, AppList } from './styles';

const iconSize = '4vh';

export default function RC3Footer({ items, handleChangeTab, smallScreen }) {
    const { width, height } = useWindowDimensions();
    const menuWidth = parseInt(width * (smallScreen ? 1 : 0.55), 10);
    const footerHeight = parseInt(height * (smallScreen ? 0.075 : 0.1), 10);
    const lineHeight = parseInt(height * (smallScreen ? 0.025 : 0.035), 10);
    const blackWidth = parseInt(width * (smallScreen ? 0.05 : 0.06), 10);
    const colorWidth = parseInt(width * 0.07, 10);

    const footer = {
        height: `${lineHeight + footerHeight}px`,
        lineHeight: `${lineHeight}px`,
        blackHeight: `${footerHeight}px`,
        blackWidth: `${blackWidth}px`,
        colorWidth: `${colorWidth}px`,
        menuWidth: `${menuWidth}px`,
    };

    function menuItem(item) {
        const Icon = item.icon || MdHelp;
        return (
            <li
                key={item.key}
                onClick={event => handleChangeTab(event, item.value)}
            >
                <div className="item">
                    <Icon style={{ fontSize: iconSize }} />
                    {!smallScreen ? (
                        <span>
                            <LocaleMessage msg={item.key} />
                        </span>
                    ) : null}
                </div>
            </li>
        );
    }

    return (
        <Background footerStyle={footer} smallScreen={smallScreen}>
            <AppFooter
                className="footer"
                footerStyle={footer}
                smallScreen={smallScreen}
            >
                <div className="title">
                    <span>ROBOT CORE CENTRALIZED CONTROL</span>
                </div>
                <AppList
                    className="row p-0"
                    footerStyle={footer}
                    smallScreen={smallScreen}
                >
                    <div className="scrollbar">
                        <Scrollbars
                            style={{
                                padding: '0px',
                            }}
                        >
                            <ul>
                                {items.map(i => {
                                    return menuItem(i);
                                })}
                            </ul>
                        </Scrollbars>
                    </div>
                </AppList>
            </AppFooter>
        </Background>
    );
}

RC3Footer.defaultProps = {
    smallScreen: false,
    handleChangeTab: () => {},
};

RC3Footer.propTypes = {
    smallScreen: PropTypes.bool,
    items: PropTypes.array.isRequired,
    handleChangeTab: PropTypes.func,
};
