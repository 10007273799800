import styled from 'styled-components';

export const ParametersForm = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PreviewArea = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #ddd;

    .title {
        text-align: center;
    }

    .content {
        text-align: center;
        word-wrap: break-word;
    }
`;

export const QRArea = styled.div`
    border: 1px solid #ddd;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
`;

export const PrintableArea = styled.div`
    display: flex !important;
    flex-wrap: wrap !important;
    -webkit-flex-wrap: wrap !important;
    -moz-flex-wrap: wrap !important;
    -o-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    padding: 10px;

    .item {
        display: inline-block;
    }

    .title {
        text-align: center;
    }

    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        html,
        body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
        @page {
            size: portrait;
        }
    }

    @media print {
        .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: auto;
        }
    }

    @page {
        size: auto;
        margin: 5mm;
    }
`;
