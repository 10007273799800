/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

export default function SUUsers({ requestError, filter, formats }) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const headersFile = [
        { key: 'active', label: 'Is Active' },
        { key: 'status', label: 'Status' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
        ...(!filter
            ? [{ key: 'pluginspace_permissions', label: 'Pluginspaces' }]
            : [
                  { key: 'pluginspace_permission', label: 'Permission' },
                  {
                      key: 'added_date',
                      label: 'Approval Date',
                  },
                  {
                      key: 'added_time',
                      label: 'Approval Time',
                  },
              ]),
    ];

    const headersCell = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'email',
            label: <LocaleMessage msg="table.headers.email" />,
        },
        {
            id: 'status_name',
            order_by: 'status',
            label: <LocaleMessage msg="table.headers.status" />,
        },
        {
            id: 'created_date',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
        ...(!filter
            ? [
                  {
                      id: 'pluginspace_permissions',
                      label: <LocaleMessage msg="table.headers.pluginspaces" />,
                  },
              ]
            : [
                  {
                      id: 'pluginspace_permission',
                      label: <LocaleMessage msg="table.headers.permission" />,
                  },
                  {
                      id: 'added_date',
                      label: <LocaleMessage msg="table.headers.approved" />,
                      order_by: 'added_timestamp',
                  },
              ]),
    ];

    function filterData(raw_data) {
        const data = raw_data.map(u => {
            const created = new Date(u.created);
            const updated = new Date(u.updated);
            const added = u.pluginspace_added
                ? new Date(u.pluginspace_added)
                : null;
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);
            const a_time =
                filter && added
                    ? added.toLocaleTimeString([], formats.time)
                    : {};

            let pluginspace_names = '';
            let pluginspace_perm = '';
            if (!filter && u.pluginspaces) {
                u.pluginspaces.forEach((u_p, idx) => {
                    pluginspace_names += `${idx ? ', ' : ''}${u_p.name}`;
                    pluginspace_perm += `${idx ? ', ' : ''}${u_p.name} [${
                        u_p.pluginspace_permission
                    }]`;
                });
            }
            return {
                ...u,
                pluginspace_names,
                pluginspace_permissions: pluginspace_perm,
                status_name:
                    u.status && u.active ? (
                        <LocaleMessage msg={`user.status.${u.status}`} />
                    ) : (
                        <LocaleMessage msg="info.status.unknown" />
                    ),
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
                ...(filter &&
                    added && {
                        pluginspace_permission: u.pluginspace_permission || '',
                        added_distance: formatDistance(added, new Date(), {
                            addSuffix: true,
                            locale: formats.locale,
                        }),
                        added_date: added.toLocaleDateString(
                            formats.format,
                            formats.date
                        ),
                        added_time: a_time,
                        added_timestamp: added.toISOString(),
                    }),
            };
        });

        setItems(data);
    }

    async function loadUsers() {
        setIsLoading(true);
        const filter_url = filter ? `?pluginspace=${filter}` : '';
        await api
            .get(`admin/users${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadUsers();
    }, [formats, filter]);

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headersFile}
                filename={GetFileName(
                    `admin_analytics_users${filter ? `_${filter}` : ''}`,
                    'csv'
                )}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headersCell}
                    data={items}
                    orderColumn="permission"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <div style={{ padding: '15px' }}>{buildExportButton()}</div>
            <>{buildListView()}</>
        </>
    );
}

SUUsers.defaultProps = {
    filter: '',
    requestError: () => {},
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SUUsers.propTypes = {
    filter: PropTypes.string,
    requestError: PropTypes.func,
    formats: PropTypes.object,
};
