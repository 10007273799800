/* eslint-disable prefer-destructuring */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    MdStar,
    MdStarBorder,
    MdExpandMore,
    MdExpandLess,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import { IconParameter } from './styles';

function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const useStyles = makeStyles(theme => {
    return {
        icon: {
            color: `${theme.palette.primary.main} !important`,
        },
    };
});

export default function FormCheckList({
    onChange,
    settings,
    options,
    optionKey,
    primaryLabelKey,
    secondaryLabelKey,
    disabled,
    onItemClick,
    showShortcut,
    currItem,
    listKey,
    allowStar,
    starKey,
    multiple,
    collapsable,
    open,
}) {
    const classes = useStyles();
    const [show, setShow] = useState(!collapsable || open);

    const starred =
        settings && starKey && settings[starKey] ? settings[starKey] : null;

    const checked = settings[listKey] || [];

    function numberOfChecked(items) {
        return intersection(checked, items).length;
    }

    function onItemChange(key, value) {
        let change_def = null;
        if (starKey) {
            const def_item =
                options && options[starKey] ? options[starKey] : '';

            if (key === listKey) {
                if (Array.isArray(value)) {
                    if (
                        value.length === 1 ||
                        !value.find(l => l === def_item)
                    ) {
                        change_def = value[0];
                    }
                }
            }
        }

        onChange({
            ...settings,
            [key]: value,
            ...(change_def && { [starKey]: change_def }),
        });
    }

    function handleToggle(v) {
        const currentIndex = checked.indexOf(v);
        let newChecked = [...checked];

        if (multiple) {
            if (currentIndex === -1) {
                newChecked.push(v);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        } else {
            newChecked = [v];
        }

        onItemChange(listKey, newChecked);
    }

    function handleToggleAll(items) {
        if (numberOfChecked(items) === items.length) {
            onItemChange(listKey, not(checked, items));
        } else {
            onItemChange(listKey, union(checked, items));
        }
    }

    function onStarClick(value) {
        onItemChange(starKey, value);
    }

    const items = options.map(l => {
        return l[optionKey || 'id'];
    });

    return (
        <div className="mb-3">
            {showShortcut && multiple ? (
                <>
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormControlLabel
                            className={collapsable ? 'col-10' : 'col-12'}
                            control={
                                <Checkbox
                                    color="primary"
                                    onClick={() => handleToggleAll(items)}
                                    checked={
                                        numberOfChecked(items) ===
                                            items.length && items.length !== 0
                                    }
                                    indeterminate={
                                        numberOfChecked(items) !==
                                            items.length &&
                                        numberOfChecked(items) !== 0
                                    }
                                    disabled={items.length === 0 || disabled}
                                />
                            }
                            label={
                                <span>
                                    {`${numberOfChecked(items)}/${
                                        items.length
                                    } `}
                                    <LocaleMessage msg="label.selected" />
                                </span>
                            }
                        />
                        {collapsable ? (
                            <IconParameter className="col-1">
                                {show ? (
                                    <MdExpandLess
                                        size={24}
                                        onClick={() => setShow(false)}
                                    />
                                ) : (
                                    <MdExpandMore
                                        size={24}
                                        onClick={() => setShow(true)}
                                    />
                                )}
                            </IconParameter>
                        ) : null}
                    </div>
                    <Divider />
                </>
            ) : null}
            {show ? (
                <List dense component="div" role="list">
                    {options.map(o => {
                        const o_id = o[optionKey];
                        const in_use = checked.indexOf(o_id) !== -1;
                        const Icon =
                            starred && starred === o_id ? MdStar : MdStarBorder;

                        return (
                            <ListItem
                                key={o_id}
                                role="listitem"
                                button
                                selected={currItem && currItem === o_id}
                            >
                                <ListItemIcon
                                    onClick={() =>
                                        !disabled ? handleToggle(o_id) : {}
                                    }
                                >
                                    <Checkbox
                                        color="primary"
                                        checked={in_use}
                                        disableRipple
                                        disabled={disabled}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    onClick={() => onItemClick(o_id)}
                                    primary={o[primaryLabelKey] || ''}
                                    secondary={o[secondaryLabelKey] || ''}
                                />
                                {allowStar && in_use ? (
                                    <ListItemIcon
                                        color="primary"
                                        onClick={() => onStarClick(o_id)}
                                        className={classes.icon}
                                    >
                                        <Icon size={22} color="primary" />
                                    </ListItemIcon>
                                ) : null}
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}
        </div>
    );
}

FormCheckList.defaultProps = {
    options: [],
    optionKey: 'id',
    primaryLabelKey: 'name',
    secondaryLabelKey: '',
    disabled: false,
    onItemClick: () => {},
    onChange: () => {},
    showShortcut: true,
    currItem: null,
    listKey: 'list',
    allowStar: false,
    settings: {},
    starKey: 'default',
    multiple: true,
    open: true,
    collapsable: false,
};

FormCheckList.propTypes = {
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    options: PropTypes.array,
    optionKey: PropTypes.string,
    primaryLabelKey: PropTypes.string,
    secondaryLabelKey: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    showShortcut: PropTypes.bool,
    currItem: PropTypes.string,
    allowStar: PropTypes.bool,
    settings: PropTypes.object,
    starKey: PropTypes.string,
    listKey: PropTypes.string,
    open: PropTypes.bool,
    collapsable: PropTypes.bool,
};
