/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import PageTitle from '~/components/PageTitle';

import PageBody from '../PageBody';
import { Container } from './styles';

export default function PageContent(props) {
    const {
        title,
        currentPage,
        breadcrumbs,
        loading,
        forbidden,
        children,
    } = props;
    return (
        <Container>
            <div className="app-wrapper">
                <PageTitle
                    title={title}
                    currentPage={currentPage || title}
                    breadcrumbs={breadcrumbs}
                />
                <PageBody loading={loading} forbidden={forbidden}>
                    {children}
                </PageBody>
            </div>
        </Container>
    );
}

PageContent.defaultProps = {
    loading: true,
    forbidden: false,
    breadcrumbs: [],
    currentPage: '',
};

PageContent.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    breadcrumbs: PropTypes.array,
    loading: PropTypes.bool,
    forbidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
};
