/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdAdd } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Container } from './styles';

const file_types = {
    images: 'image/*',
    audios: 'audio/mpeg, audio/x-wav, audio/wav, audio/ogg',
    json: 'application/json',
};

export default function FileInput({
    defaultValue,
    onFileUpload,
    text,
    multiple,
    type,
    disabled,
    showIcon,
    showFooter,
}) {
    return (
        <Container>
            <div className="jr-card jr-card-widget jr-card-full text-center card new-card">
                <label htmlFor="file">
                    {defaultValue.id ? (
                        <div
                            className="label"
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {type === 'images' ? (
                                <img src={defaultValue.url} alt="" />
                            ) : (
                                <div className="card-body text-center p-3">
                                    {defaultValue.name || ''}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="card-body text-center">
                            {text}
                            {showIcon ? (
                                <MdAdd
                                    size={48}
                                    style={{
                                        margin: '15px',
                                    }}
                                />
                            ) : null}
                        </div>
                    )}
                    <input
                        type="file"
                        id="file"
                        accept={file_types[type]}
                        onChange={onFileUpload}
                        multiple={multiple}
                        disabled={disabled}
                    />
                    {showFooter && defaultValue && defaultValue.id ? (
                        <span>{text}</span>
                    ) : null}
                </label>
            </div>
        </Container>
    );
}

FileInput.propTypes = {
    defaultValue: PropTypes.object,
    onFileUpload: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    multiple: PropTypes.bool.isRequired,
    type: PropTypes.string,
    showIcon: PropTypes.bool,
    showFooter: PropTypes.bool,
};

FileInput.defaultProps = {
    defaultValue: { id: null, url: null },
    disabled: false,
    type: 'images',
    text: '',
    showIcon: true,
    showFooter: true,
};
