/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAdd, MdCancel } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea } from '../styles';

export default function Constants({
    dialogSettings,
    versionSettings,
    answerLanguages,
    updateVersionSettings,
}) {
    const { language: dialog_lng } = dialogSettings;

    const [items, setItems] = useState([]);
    const [body, setBody] = useState({});

    const [operation, setOperation] = useState('add');
    const [currItemId, setCurrItemId] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [needSaving, setNeedSaving] = useState(false);

    useEffect(() => {
        const const_obj = versionSettings.constants || {};
        const c_list = Object.keys(const_obj).map(c => {
            const item_id = `item_${c}`;
            const c_obj = const_obj[c] || {};
            return {
                id: item_id,
                key: c,
                per_language: c_obj.per_language || false,
                values: c_obj.values || {},
            };
        });
        setItems(c_list);
    }, [versionSettings]);

    async function submitEdit() {
        const constants_obj = {};
        items.forEach(c => {
            constants_obj[c.key] = {
                per_language: c.per_language || false,
                values: c.values || {},
            };
        });
        setNeedSaving(false);
        updateVersionSettings('constants', constants_obj);
    }

    function addOption() {
        const list = items || [];
        const item_id = (+new Date()).toString(36);
        list.push({ id: item_id, ...body });
        setItems([...list]);
    }

    function editOption() {
        const list = items || [];
        const item_id = body.id;

        if (item_id) {
            const idx = list.findIndex(i => {
                return i.id === item_id;
            });
            list[idx] = body;
        }

        setItems([...list]);
    }

    function removeOptions() {
        const list = items || [];
        const filtered = list.filter(c => {
            return !selectedRows.includes(c.id);
        });
        setNeedSaving(true);
        setItems([...filtered]);
        setShowEditDialog(false);
        setSelectedRows([]);
    }

    function saveOptions() {
        if (operation === 'add') {
            addOption();
        } else {
            editOption(currItemId);
        }
        setNeedSaving(true);
        setBody({});
        setShowEditDialog(false);
    }

    function updateLanguageValue(l, v) {
        const curr_values = body.values || {};
        setBody({
            ...body,
            values: {
                ...curr_values,
                [l]: v,
            },
        });
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
        setBody({});
    }

    function handleItemClick(event, _id) {
        setOperation('edit');
        setSelectedRows([]);
        setShowEditDialog(true);
        const curr_item = items.find(i => {
            return i.id === _id;
        });
        setBody(curr_item || {});
    }

    function renderEditContentForm() {
        const values = body.values || {};
        return (
            <>
                <div className="row p-3">
                    <div className="col-12">
                        <TextField
                            className="mb-3"
                            label={
                                <LocaleMessage msg="page.qna.context.form.key" />
                            }
                            fullWidth
                            value={body.key || ''}
                            onChange={e => {
                                setBody({
                                    ...body,
                                    key: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="col-12">
                        <FormSwitch
                            value={body.per_language || false}
                            onChange={event => {
                                setBody({
                                    ...body,
                                    per_language: event.target.checked,
                                });
                            }}
                            label={
                                <LocaleMessage msg="page.qna.context.form.per_language" />
                            }
                        />
                    </div>
                    <div className="col-12">
                        {body.per_language ? (
                            <ParameterArea
                                className="p-3"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <span className="mb-3">
                                    <LocaleMessage msg="page.qna.context.form.value.language" />
                                </span>
                                {answerLanguages.map(l => {
                                    return (
                                        <TextField
                                            key={`val_lang_${l}`}
                                            className="mb-3"
                                            label={
                                                <LocaleMessage
                                                    msg={`list.languages.${l}`}
                                                />
                                            }
                                            fullWidth
                                            value={values[l] || ''}
                                            onChange={e => {
                                                updateLanguageValue(
                                                    l,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </ParameterArea>
                        ) : (
                            <TextField
                                className="mb-3"
                                label={
                                    <LocaleMessage msg="page.qna.context.form.value" />
                                }
                                fullWidth
                                value={values[dialog_lng] || ''}
                                onChange={e => {
                                    updateLanguageValue(
                                        dialog_lng,
                                        e.target.value
                                    );
                                }}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage
                        msg={`page.qna.context.${
                            operation === 'remove' ? 'remove' : 'edit'
                        }.title`}
                    />
                }
                content={
                    operation === 'remove' ? (
                        <LocaleMessage msg="page.qna.context.remove.content" />
                    ) : (
                        renderEditContentForm()
                    )
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    ...(operation === 'remove'
                        ? [
                              {
                                  key: 'remove',
                                  onClick: () => removeOptions(),
                                  label: <LocaleMessage msg="button.remove" />,
                              },
                          ]
                        : [
                              {
                                  key: 'save',
                                  onClick: () => saveOptions(),
                                  label: <LocaleMessage msg="button.save" />,
                              },
                          ]),
                ]}
            />
        );
    }

    function renderContentList() {
        const headCells = [
            {
                id: 'key',
                label: <LocaleMessage msg="page.qna.context.key" />,
                width: '20%',
            },
        ];

        const rowActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: (e, _id) => {
                    setOperation('remove');
                    setSelectedRows([_id]);
                    setShowEditDialog(true);
                },
            },
        ];

        const headerActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: () => {
                    setOperation('remove');
                    setShowEditDialog(true);
                },
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    orderDirection="asc"
                    orderColumn="key"
                    handleTableRowClick={handleItemClick}
                    hasActions
                    rowActions={rowActions}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAdd />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                setOperation('add');
                                setCurrItemId('new');
                                setShowEditDialog(true);
                                setSelectedRows([]);
                            }}
                        >
                            <LocaleMessage msg="page.qna.context.add" />
                        </Button>
                    }
                    selectable
                    selectedActions={headerActions}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.dialogs.info.constants" />
                </h2>
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {renderContentList()}
                <div
                    className="mt-5 row"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="col-md-6 col-12 mb-3">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => submitEdit(event)}
                            fullWidth
                            size="large"
                            disabled={!needSaving}
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Constants.defaultProps = {
    versionSettings: {},
    answerLanguages: [],
};

Constants.propTypes = {
    versionSettings: PropTypes.object,
    updateVersionSettings: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
    answerLanguages: PropTypes.array,
};
