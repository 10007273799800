/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../../styles';

const column = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function TextsSetup({
    settings,
    onChange,
    languageList,
    template,
}) {
    const layout_settings = {
        step_default: {
            title: true,
            info: false,
            value: false,
        },
        step_side_by_side: {
            title: true,
            info: true,
            value: false,
        },
        display_default: {
            title: true,
            info: false,
            value: true,
        },
        end_default: {
            title: true,
            info: false,
            value: false,
        },
        end_side_by_side: {
            title: true,
            info: false,
            value: false,
        },
    };

    const template_settings = layout_settings && layout_settings[template];

    // const source_types = [
    //     {
    //         value: 'static',
    //         label: (
    //             <LocaleMessage msg="page.survey_steps.form.source.label.type.static" />
    //         ),
    //     },
    //     {
    //         value: 'dynamic',
    //         label: (
    //             <LocaleMessage msg="page.survey_steps.form.source.label.type.dynamic" />
    //         ),
    //     },
    // ];

    // function renderSource() {
    //     return (
    //         <div className="col-md-6 col-12 mb-5">
    //             <FormControl fullWidth>
    //                 <InputLabel>
    //                     <LocaleMessage msg="page.survey_steps.form.source.label.type" />
    //                 </InputLabel>
    //                 <Select
    //                     id="source-type"
    //                     value={settings.type ? settings.type : 'static'}
    //                     onChange={event =>
    //                         onChange({
    //                             ...settings,
    //                             type: event.target.value,
    //                         })
    //                     }
    //                 >
    //                     {source_types.map(l => {
    //                         return (
    //                             <MenuItem value={l.value} key={l.value}>
    //                                 {l.label}
    //                             </MenuItem>
    //                         );
    //                     })}
    //                 </Select>
    //             </FormControl>
    //         </div>
    //     );
    // }

    function onQuestionChange(event, key, lang) {
        let obj = settings[key];
        if (!obj) {
            obj = {
                [lang]: '',
            };
        }

        obj[lang] = event.target.value;
        onChange({ ...settings, [key]: obj });
    }

    function renderTextInput({ key, label, value, changeKey, lng }) {
        return (
            <div className="col-12 mb-5" key={key}>
                <TextField
                    label={label}
                    fullWidth
                    value={value || ''}
                    onChange={event => onQuestionChange(event, changeKey, lng)}
                    multiline
                />
            </div>
        );
    }

    function renderStepTexts() {
        let { title, info, speech, value } = settings;
        if (!title) {
            title = {};
        }
        if (!info) {
            info = {};
        }
        if (!speech) {
            speech = {};
        }
        if (!value) {
            value = {};
        }
        return (
            <>
                {languageList.length === 0 ? (
                    <div className="col-12 mt-5 mb-5 text-center">
                        <LocaleMessage msg="page.survey_steps.form.question.label.texts.no_language" />
                    </div>
                ) : null}
                {languageList.map(l => {
                    return (
                        <ParameterArea
                            className="row mt-1 mb-1 p-3"
                            style={column}
                            key={`${l}_texts`}
                        >
                            <h5>
                                <LocaleMessage msg={`list.languages.${l}`} />
                            </h5>

                            {template_settings && template_settings.title
                                ? renderTextInput({
                                      key: `${l}_steptitle`,
                                      label: (
                                          <LocaleMessage msg="page.survey_steps.form.question.label.title" />
                                      ),
                                      value: title && title[l] ? title[l] : '',
                                      changeKey: 'title',
                                      lng: l,
                                  })
                                : null}
                            {template_settings && template_settings.info
                                ? renderTextInput({
                                      key: `${l}_stepinfo`,
                                      label: (
                                          <LocaleMessage msg="page.survey_steps.form.question.label.info" />
                                      ),
                                      value: info && info[l] ? info[l] : '',
                                      changeKey: 'info',
                                      lng: l,
                                  })
                                : null}
                            {renderTextInput({
                                key: `${l}_stepspeech`,
                                label: (
                                    <LocaleMessage msg="page.survey_steps.form.question.label.speech" />
                                ),
                                value: speech && speech[l] ? speech[l] : '',
                                changeKey: 'speech',
                                lng: l,
                            })}
                            {template_settings && template_settings.value
                                ? renderTextInput({
                                      key: `${l}_stepvalue`,
                                      label: (
                                          <LocaleMessage msg="page.survey_steps.form.question.label.value" />
                                      ),
                                      value: value && value[l] ? value[l] : '',
                                      changeKey: 'value',
                                      lng: l,
                                  })
                                : null}
                        </ParameterArea>
                    );
                })}
            </>
        );
    }

    return (
        <div className="mt-3" style={column}>
            {/* {template_settings && template_settings.source ? (
                <div className="col-12 mb-5">{renderSource()}</div>
            ) : null} */}

            <div className="col-12 mb-3">
                <LocaleMessage msg="page.survey_steps.form.question.label.texts.variables" />
            </div>

            <div className="col-12 mb-3">{renderStepTexts()}</div>
        </div>
    );
}

TextsSetup.propTypes = {
    template: PropTypes.string,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    languageList: PropTypes.array,
};

TextsSetup.defaultProps = {
    template: '',
    languageList: [],
};
