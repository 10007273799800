/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Divider,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';

import nao_sensors from '~/assets/images/robots/sensors/nao.jpg';
import api from '~/services/pluginbot-api';

import { ExitSettingsArea, Option } from '../styles';

const sensor_img = {
    nao: nao_sensors,
};

const default_actions = {
    end_script: {
        value: 'end_script',
        label_code: 'page.tools.scripts.step.form.exit.end_script',
    },
    no_action: {
        value: 'no_action',
        label_code: 'page.tools.scripts.step.form.exit.no_action',
    },
    return_home: {
        value: 'return_home',
        label_code: 'page.applications.scripting.form.action.return_home',
    },
    return_selector: {
        value: 'return_selector',
        label_code: 'page.applications.scripting.form.action.return_selector',
    },
};

const robot_exclusive_exits = {
    all: ['timeout', 'next', 'failed'],
    nao: ['hardware'],
};

const robot_default_actions = {
    all: [''],
    cruzr: [''],
    temi: [''],
    nao: ['return_home', 'return_selector'],
};

const allow_hide_buttons = true;

const warnings = {
    cruzr: ['warnings.version.cruzr_v390'],
    temi: ['warnings.version.temi_v220'],
};

const allow_fail_exit = ['navigate', 'play_audio'];

export default function ExitConditionConfig({
    handleError,
    stepAction,
    config,
    setConfig,
    stepList,
    enabled,
    robotTypes,
    currType,
    setCurrType,
}) {
    const all_robots = {
        id: 'all',
        name: <LocaleMessage msg="label.all" />,
        slug: 'all',
    };

    const exit_settings = config.exits || {};

    const [exitsImg, setExitsImg] = useState(null);
    const [robotSettings, setRobotSettings] = useState({});
    const [currRobotExits, setCurrRobotExits] = useState(
        currType && currType.slug && robot_exclusive_exits[currType.slug]
            ? currType
            : all_robots
    );

    const [availableSettings, setAvailableSettings] = useState([]);
    const [availableExits, setAvailableExits] = useState([]);

    const r_type_exits =
        (currRobotExits &&
            currRobotExits.slug &&
            exit_settings[currRobotExits.slug]) ||
        {};

    function compare(a, b) {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    async function loadRobotSettings(_id) {
        await api
            .get(`types/${_id}`)
            .then(response => {
                setRobotSettings(response.data);
            })
            .catch(error => handleError(error));
    }

    useEffect(() => {
        const r_type = currRobotExits ? currRobotExits.slug : '';
        setAvailableSettings(robot_exclusive_exits[r_type] || []);
        setAvailableExits(robot_default_actions[r_type] || []);
        setExitsImg(sensor_img[r_type] || null);

        if (currRobotExits && currRobotExits.id !== 'all') {
            loadRobotSettings(currRobotExits.id);
            setCurrType(currRobotExits);
        } else {
            setRobotSettings(null);
            setCurrType(robotTypes[0]);
        }
    }, [currRobotExits]);

    function setCondition(type, key, val) {
        const type_exits = exit_settings[type] || {};
        setConfig({
            ...config,
            exits: {
                ...exit_settings,
                [type]: {
                    ...type_exits,
                    [key]: val,
                },
            },
        });
    }

    function setHWSettings(key, val) {
        const r_type = currRobotExits ? currRobotExits.slug : '';
        const type_exits = exit_settings[r_type] || {};
        const type_hardware =
            type_exits && type_exits.hardware ? type_exits.hardware : {};
        setCondition(r_type, 'hardware', {
            ...type_hardware,
            [key]: val,
        });
    }

    function setTimeoutSettings(type, key, val) {
        const type_exits = exit_settings[type] || {};
        const type_timeout =
            type_exits && type_exits.timeout ? type_exits.timeout : {};
        const timeout_settings = {
            ...type_timeout,
            [key]: val,
        };
        setCondition(type, 'timeout', timeout_settings);
    }

    function renderExitOptions(prefix, value, onChange) {
        return (
            <FormControl className="col-6 mb-1" fullWidth>
                <InputLabel>
                    <LocaleMessage msg="page.tools.scripts.step.form.exit.go_to" />
                </InputLabel>
                <Select
                    value={value || 'no_action'}
                    onChange={event => onChange(event)}
                >
                    <MenuItem value="no_action">
                        <LocaleMessage msg="page.tools.scripts.step.form.exit.no_action" />
                    </MenuItem>
                    {availableExits.map(e => {
                        const exit_cond = default_actions[e];
                        return exit_cond ? (
                            <MenuItem
                                key={`${prefix}_exit_${exit_cond.value}`}
                                value={exit_cond.value}
                            >
                                <LocaleMessage msg={exit_cond.label_code} />
                            </MenuItem>
                        ) : null;
                    })}
                    {stepList.map(s => (
                        <MenuItem key={s.id} value={s.id}>
                            {s.label}
                        </MenuItem>
                    ))}
                </Select>
                {value === 'return_selector' ? (
                    <FormHelperText>
                        <LocaleMessage msg="page.applications.scripting.form.action.return_selector.long" />
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }

    function renderSensorItem(hardware_settings, item) {
        return (
            <Option className="row mb-3 p-3" key={item.item_id}>
                <div
                    className="col-1 mb-1"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {item.order}.
                </div>
                <div
                    className="col-5 mb-1"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LocaleMessage msg={item.label} />
                </div>
                {renderExitOptions(
                    item.value,
                    hardware_settings && hardware_settings[item.value]
                        ? hardware_settings[item.value]
                        : 'no-action',
                    event => setHWSettings(item.value, event.target.value)
                )}
            </Option>
        );
    }

    function renderHardwareConditions() {
        const robot_settings = robotSettings ? robotSettings.settings : {};
        const robot_hardware =
            availableSettings.includes('hardware') &&
            robot_settings &&
            robot_settings.hardware
                ? robot_settings.hardware
                : {};

        const robotSensors = Object.keys(robot_hardware).map(s => {
            const sensor = robot_hardware[s];
            return sensor;
        });

        const sortedSensors = robotSensors.sort(compare);

        const r_type = currRobotExits ? currRobotExits.slug : '';
        const type_exits = exit_settings[r_type] || {};
        const type_hardware =
            type_exits && type_exits.hardware ? type_exits.hardware : {};

        return (
            <>
                <FormLabel className="mb-3">
                    <LocaleMessage msg="page.tools.scripts.step.form.exit.sensors" />
                </FormLabel>
                {sortedSensors.map(item => {
                    return renderSensorItem(type_hardware, item);
                })}
            </>
        );
    }

    function renderTimeoutCondition() {
        const r_type = currRobotExits ? currRobotExits.slug : '';
        const type_exits = exit_settings[r_type] || {};
        const type_timeout =
            type_exits && type_exits.timeout ? type_exits.timeout : {};

        return (
            <Option className="row mb-3 p-3">
                <div
                    className="col-6 mb-1"
                    style={{
                        display: 'flex',
                        alignItems: 'top',
                    }}
                >
                    <TextField
                        label={
                            <LocaleMessage msg="page.tools.scripts.step.form.exit.timeout" />
                        }
                        fullWidth
                        value={type_timeout.time ? type_timeout.time : ''}
                        onChange={event =>
                            setTimeoutSettings(
                                r_type,
                                'time',
                                event.target.value
                            )
                        }
                        helperText={
                            <LocaleMessage msg="page.tools.scripts.step.form.exit.timeout.default" />
                        }
                    />
                </div>
                {renderExitOptions(
                    'timeout',
                    type_timeout.action ? type_timeout.action : '',
                    event =>
                        setTimeoutSettings(r_type, 'action', event.target.value)
                )}
            </Option>
        );
    }

    function renderNextStepCondition() {
        const r_type = currRobotExits ? currRobotExits.slug : '';
        const type_exits = exit_settings[r_type] || {};
        const type_next = type_exits && type_exits.next ? type_exits.next : {};

        const show_buttons = type_exits.show_buttons || !allow_hide_buttons;

        return show_buttons ? (
            <Option className="row mb-3 p-3">
                <div
                    className="col-6"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LocaleMessage msg="page.tools.scripts.step.form.exit.button.next" />
                </div>
                {renderExitOptions(
                    'next',
                    type_next.action ? type_next.action : '',
                    event =>
                        setCondition(r_type, 'next', {
                            action: event.target.value,
                        })
                )}
            </Option>
        ) : null;
    }

    function renderFailedStepCondition() {
        const r_type = currRobotExits ? currRobotExits.slug : '';
        const type_exits = exit_settings[r_type] || {};
        const type_failed =
            type_exits && type_exits.failed ? type_exits.failed : {};

        return allow_fail_exit.includes(stepAction) ? (
            <Option className="row mb-3 p-3">
                <div
                    className="col-6"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LocaleMessage msg="page.tools.scripts.step.form.exit.failed" />
                </div>
                {renderExitOptions(
                    'failed',
                    type_failed.action ? type_failed.action : '',
                    event =>
                        setCondition(r_type, 'failed', {
                            action: event.target.value,
                        })
                )}
            </Option>
        ) : null;
    }

    const filteredTypes = [
        all_robots,
        ...robotTypes.filter(t => {
            return (
                robot_exclusive_exits[t.slug] &&
                robot_exclusive_exits[t.slug].length > 0
            );
        }),
    ];

    const has_warning = !!robotTypes.find(t => {
        return warnings[t.slug];
    });

    return enabled ? (
        <>
            <div className="row col-12 mt-3 mb-3">
                <LocaleMessage msg="page.tools.scripts.step.form.exit.title" />
            </div>
            <div className="col-12 mt-3 mb-3 pb-3">
                <AppConfigRobotType
                    className="col-12 mb-1"
                    robotTypes={filteredTypes}
                    currType={currRobotExits}
                    setCurrType={t => setCurrRobotExits(t)}
                >
                    <ExitSettingsArea className="row col-12 mx-0">
                        {exitsImg ? (
                            <>
                                <div className="col-md-5 col-12 container mx-1">
                                    <img
                                        src={exitsImg}
                                        alt={`sensors_${currRobotExits.id}`}
                                    />
                                </div>
                                <Divider orientation="vertical" flexItem />
                            </>
                        ) : null}
                        <div
                            className={`container col-12 col-md-${
                                exitsImg ? '6 ml-5' : '10'
                            }`}
                        >
                            <FormControl component="fieldset" fullWidth>
                                {allow_hide_buttons &&
                                availableSettings.includes('next') ? (
                                    <FormSwitch
                                        value={
                                            r_type_exits.show_buttons || false
                                        }
                                        onChange={event =>
                                            setCondition(
                                                currRobotExits
                                                    ? currRobotExits.slug
                                                    : '',
                                                'show_buttons',
                                                event.target.checked
                                            )
                                        }
                                        label={
                                            <>
                                                <LocaleMessage msg="page.tools.scripts.step.form.exit.show_buttons" />
                                                {has_warning ? (
                                                    <span> *</span>
                                                ) : null}
                                            </>
                                        }
                                        helper={
                                            <>
                                                {robotTypes.map(t => {
                                                    return warnings[t.slug] ? (
                                                        <div>
                                                            <LocaleMessage
                                                                msg={
                                                                    warnings[
                                                                        t.slug
                                                                    ]
                                                                }
                                                            />
                                                        </div>
                                                    ) : null;
                                                })}
                                                {has_warning ? (
                                                    <div>
                                                        <LocaleMessage msg="warnings.version.script.show_buttons" />
                                                    </div>
                                                ) : null}
                                            </>
                                        }
                                    />
                                ) : null}
                                {availableSettings.includes('failed')
                                    ? renderFailedStepCondition()
                                    : null}
                                {availableSettings.includes('next')
                                    ? renderNextStepCondition()
                                    : null}
                                {availableSettings.includes('timeout')
                                    ? renderTimeoutCondition()
                                    : null}
                                {availableSettings.includes('hardware')
                                    ? renderHardwareConditions()
                                    : null}
                            </FormControl>
                        </div>
                    </ExitSettingsArea>
                </AppConfigRobotType>
            </div>
        </>
    ) : (
        <div
            className="row col-12 mt-3 mb-5"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            <LocaleMessage msg="page.tools.scripts.step.form.exit.end" />
        </div>
    );
}

ExitConditionConfig.defaultProps = {
    config: {},
    stepList: [],
    enabled: false,
    stepAction: '',
    currType: {},
};

ExitConditionConfig.propTypes = {
    handleError: PropTypes.func.isRequired,
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
    stepList: PropTypes.array,
    enabled: PropTypes.bool,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object,
    setCurrType: PropTypes.func.isRequired,
    stepAction: PropTypes.string,
};
