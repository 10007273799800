/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdEdit } from 'react-icons/md';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import { Container } from './styles';

export default function FileInput({ defaultValue, onFileUpload, background }) {
    const file = defaultValue.id || null;
    const preview = defaultValue.url || null;

    return (
        <Container
            style={{ background, height: '100%', border: '1px solid #ccc' }}
        >
            <label htmlFor="image-input">
                <div className="image-input">
                    {preview ? (
                        <img src={preview} alt="" />
                    ) : (
                        <div className="image-placeholder">
                            <div
                                className="jr-separator"
                                style={{ background: '#999' }}
                            />
                            <h2 style={{ color: '#999' }}>
                                <LocaleMessage msg="page.robot_movements.form.label.illustration" />
                            </h2>
                            <div
                                className="jr-separator"
                                style={{ background: '#999' }}
                            />
                        </div>
                    )}
                </div>

                <input
                    type="file"
                    id="image-input"
                    accept={['image/png', 'image/gif']}
                    onChange={e => onFileUpload(e)}
                    data-file={file}
                />
                <span>
                    <MdEdit />
                    <LocaleMessage msg="page.robot_movements.form.label.change_illustration" />
                </span>
            </label>
        </Container>
    );
}

FileInput.defaultProps = {
    background: '#ddd',
};

FileInput.propTypes = {
    background: PropTypes.string,
    onFileUpload: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
};
