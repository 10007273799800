import styled from 'styled-components';

export const ExamplesArea = styled.div`
    display: flex;

    .remove-option {
        :hover {
            cursor: pointer;
        }
    }
`;
