/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function PluginstoreApps() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);

    const [currItem, setCurrItem] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [items, setItems] = useState([]);

    function requestError(error) {
        if (error.response) {
            toast.error(error.response.data.error);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error('Connection Error! Please wait a moment and try again');
        } else {
            toast.error('Unknown Error! Please wait a moment and try again');
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`pluginstore/apps`)
            .then(response => {
                const { applications } = response.data;
                const data = applications.map(a => ({
                    ...a,
                    last_updated: formatDistance(
                        new Date(a.updated),
                        new Date(),
                        {
                            addSuffix: true,
                            locale: date_loc,
                        }
                    ),
                }));

                setItems(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/pluginstore/apps/${id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`pluginstore/apps/${currItem}`)
            .then(() => {
                toast.success('App Version deleted');
                loadItems();
            })
            .catch(error => requestError(error));
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: 'App' },
            { id: 'robot', label: 'Robot Type' },
            { id: 'robot_version', label: 'System Version' },
            { id: 'app_version', label: 'App Version' },
            { id: 'last_updated', label: 'Updated' },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: 'Delete',
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    orderColumn="name"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title=" Confirm App exclusion?"
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.pluginstore.title" />}
            currentPage={<LocaleMessage msg="page.pluginstore.title" />}
            breadcrumbs={[]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="body-top-controls">
                    <NewItemButton
                        link="/pluginstore/apps/new"
                        text={<LocaleMessage msg="page.pluginstore.list.add" />}
                    />
                </div>
                <>{buildListView()}</>
            </>
        </PageContent>
    );
}
