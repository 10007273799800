/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdExtension, MdAdd, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

export default function PluginspacePlugins({
    pluginspace_id,
    plugin_list,
    requestError,
    renderAddonButton,
}) {
    const [pluginOperation, setPluginOperation] = useState('create');

    const [actionList, setActionList] = useState([]);
    const [pluginspacePlugins, setPluginspacePlugins] = useState([]);

    const [currPluginItem, setCurrPluginItem] = useState(null);
    const [pluginFormOpen, setPluginFormOpen] = useState(false);
    const [pluginDeleteOpen, setPluginDeleteOpen] = useState(false);

    const [options, setOptions] = useState({});

    const [plugintype, setPlugintype] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    async function loadActions() {
        setIsLoading(true);
        await api
            .get(`actions`)
            .then(response => {
                const { data } = response;
                setActionList(data);
            })
            .catch(error => {
                requestError(error);
            });
    }

    async function loadPlugins() {
        await api
            .get(`admin/pluginspaces/${pluginspace_id}/addons?type=plugin`)
            .then(response => {
                const p = response.data;
                setPluginspacePlugins(p);
            })
            .catch(error => requestError(error));
    }

    async function loadPluginspaceActions(plugintype_id) {
        if (plugintype_id) {
            setIsLoading(true);

            setPlugintype(plugintype_id);
            await api
                .get(
                    `admin/pluginspaces/${pluginspace_id}/actions?type=${plugintype_id}`
                )
                .then(response => {
                    const { data } = response;
                    const list = {};
                    data.forEach(a => {
                        list[a.id] = a.status;
                    });
                    setOptions(list);
                })
                .catch(error => requestError(error));
        } else {
            setPlugintype(null);
            setOptions({});
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadActions();
        loadPlugins();
    }, []);

    useEffect(() => {
        loadPluginspaceActions(currPluginItem);
    }, [currPluginItem]);

    async function addPlugin(data) {
        setIsLoading(true);

        const body = {
            type: 'plugin',
            ...data,
        };
        await api
            .post(`admin/pluginspaces/${pluginspace_id}/addons`, body)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.plugin.added" />
                );
            })
            .catch(error => requestError(error));

        await loadPlugins(pluginspace_id);
        setIsLoading(false);
    }

    async function removePlugin() {
        setIsLoading(true);
        setPluginDeleteOpen(false);
        await api
            .delete(
                `admin/pluginspaces/${pluginspace_id}/addons/${currPluginItem}`
            )
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.plugin.removed" />
                );
            })
            .catch(error => requestError(error));

        await loadPlugins(pluginspace_id);
        setIsLoading(false);
    }

    async function handleTableRowClick(event, _id) {
        event.stopPropagation();
        setPluginOperation('update');
        setCurrPluginItem(_id);
        setPluginFormOpen(true);
    }

    async function handlePluginSubmit(event) {
        event.preventDefault();

        if (pluginOperation === 'create') {
            const data = { addon: plugintype };
            await addPlugin(data);

            handleTableRowClick(event, plugintype);
        } else {
            const data = { pluginspace_id, plugintype_id: plugintype, options };

            await api
                .put(`admin/pluginspaces/${pluginspace_id}/actions`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.su_pluginspace.form.action_list.updated" />
                    );
                    await loadPlugins(pluginspace_id);
                    setPluginFormOpen(false);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    // async function handleFreePlugins() {
    //     setPluginFormOpen(false);
    //     const data = { addon: 'all' };
    //     await addPlugin(data);
    // }

    function handleNewPlugin() {
        setPluginOperation('create');
        setCurrPluginItem(null);
        setPluginFormOpen(true);
    }

    function handlePluginDeleteOpen(event, _id) {
        setCurrPluginItem(_id);
        event.preventDefault();
        setPluginDeleteOpen(true);
    }

    function handleCheckboxChange(event, key) {
        setOptions({ ...options, [key]: event.target.checked });
    }

    function handlePluginDialogClose(event) {
        event.preventDefault();
        setPluginDeleteOpen(false);
    }

    function handlePluginFormClose(event) {
        event.preventDefault();
        setPluginFormOpen(false);
    }

    function buildPluginDeleteDialog() {
        return (
            <SimpleDialog
                open={pluginDeleteOpen}
                onClose={handlePluginDialogClose}
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.plugin.delete" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setPluginDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removePlugin(currPluginItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildPluginFormDialog() {
        const filterPlugins = !plugintype
            ? plugin_list.filter(p => {
                  return !pluginspacePlugins.find(pp => {
                      return pp.id === p.id;
                  });
              })
            : plugin_list;

        const filterActions = plugintype
            ? actionList.filter(a => {
                  return a.plugintype_id === plugintype;
              })
            : [];

        return (
            <Dialog
                open={pluginFormOpen}
                onClose={handlePluginFormClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {pluginOperation === 'create' ? (
                        <LocaleMessage msg="page.su_pluginspace.form.plugin.add" />
                    ) : (
                        <LocaleMessage msg="page.su_pluginspace.form.plugins" />
                    )}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.su_pluginspace.form.label.plugin" />
                        </InputLabel>
                        <Select
                            value={plugintype || ''}
                            onChange={event =>
                                setPlugintype(event.target.value)
                            }
                            disabled={pluginOperation === 'update'}
                        >
                            {filterPlugins.length > 0 ? (
                                filterPlugins.map(p => (
                                    <MenuItem value={p.id} key={p.id}>
                                        {p.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">
                                    <LocaleMessage msg="page.su_pluginspace.form.label.no_available_plugins" />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {pluginOperation === 'update' && plugintype ? (
                        <FormControl component="fieldset">
                            <FormLabel>
                                <LocaleMessage msg="page.su_pluginspace.form.label.action_list" />
                            </FormLabel>
                            <FormGroup>
                                {filterActions.map(a => (
                                    <FormControlLabel
                                        key={a.id}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                value={a.id}
                                                checked={
                                                    options[a.id]
                                                        ? options[a.id]
                                                        : false
                                                }
                                                onChange={event =>
                                                    handleCheckboxChange(
                                                        event,
                                                        a.id
                                                    )
                                                }
                                            />
                                        }
                                        label={<LocaleMessage msg={a.name} />}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handlePluginFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handlePluginSubmit(event)}
                        color="primary"
                    >
                        {pluginOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildPluginListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handlePluginDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={pluginspacePlugins}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <>
            {pluginDeleteOpen ? buildPluginDeleteDialog() : null}
            {pluginFormOpen ? buildPluginFormDialog() : null}
            <CardSideBordered
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.label.plugin_list" />
                }
                hide
                Icon={MdExtension}
            >
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {/* <div className="body-top-controls">
                            <Button
                                className="col-12"
                                onClick={() => handleFreePlugins()}
                                variant="contained"
                                color="primary"
                                startIcon={<MdMoneyOff />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.su_pluginspace.form.plugin.free" />
                            </Button>
                        </div> */}
                        {renderAddonButton({
                            label: (
                                <LocaleMessage msg="page.su_pluginspace.form.plugin.add" />
                            ),
                            icon: <MdAdd />,
                            onClick: () => handleNewPlugin(),
                        })}
                        {buildPluginListView()}
                    </>
                )}
            </CardSideBordered>
        </>
    );
}

PluginspacePlugins.defaultProps = {
    plugin_list: [],
};

PluginspacePlugins.propTypes = {
    requestError: PropTypes.func.isRequired,
    pluginspace_id: PropTypes.string.isRequired,
    plugin_list: PropTypes.array,
    renderAddonButton: PropTypes.func.isRequired,
};
