/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    Card,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import MultipleInputs from '~/components/Form/MultipleInputs';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';

import { MediaArea, ParameterArea } from '../styles';

const action_types = lists.script_actions || {};

const warnings = {
    no_action: {
        cruzr: ['warnings.version.cruzr_v390'],
        temi: ['warnings.version.temi_v220'],
    },
};

export default function ActionConfig(props) {
    const {
        scriptSettings,
        handleError,
        setToast,
        config,
        setConfig,
        defaultGalleries,
        audioGalleries,
        languageList,
        robotTypes,
        currType,
        setCurrType,
        allowedActions,
    } = props;

    const r_type = currType ? currType.slug : '';

    const { group, content } = scriptSettings;
    const script_nav = content && content.navigation ? content.navigation : {};
    const group_id = group ? group.id : '*';

    const defAudioGalleries = defaultGalleries.audios || [];

    const [currGallery, setCurrGallery] = useState({
        audio: defAudioGalleries[0] ? defAudioGalleries[0] : null,
    });
    const [fileList, setFileList] = useState([]);
    const [internalLoading, setInternalLoading] = useState(false);
    const [audioDialogOpen, setAudioDialogOpen] = useState(false);

    const [mapList, setMapList] = useState([]);
    const [mapPointList, setMapPointList] = useState([]);

    const action_settings = config.action || {};

    const texts = action_settings.texts || [];
    const navigation_settings = action_settings.navigation || {};

    function saveActionSettings(key, value) {
        if (key === 'type') {
            const curr_type = action_settings.type;
            if (value !== curr_type) {
                setConfig({
                    ...config,
                    action: {
                        type: value,
                    },
                });
                return;
            }
        }
        setConfig({
            ...config,
            action: {
                ...action_settings,
                [key]: value,
            },
        });
    }

    async function loadMapPoints(_id) {
        if (_id) {
            setInternalLoading(true);
            await api
                .get(`map_points?map_layer=${_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: z.name,
                        reference: z.reference,
                    }));
                    setMapPointList(data);
                })
                .catch(error => handleError(error));
        } else {
            setMapPointList([]);
        }
    }

    async function loadMaps() {
        setInternalLoading(true);
        await api
            .get(`map_layers`)
            .then(async response => {
                const filtered = response.data.filter(m => {
                    return m.group && m.group.id === group_id;
                });
                const data = filtered.map(m => ({
                    ...m,
                    name: m.map ? m.map.name : '',
                    label: `${m.map ? m.map.name : ''} [${m.reference}]`,
                }));
                setMapList(data);
            })
            .catch(error => handleError(error));
        setInternalLoading(false);
    }

    async function loadFiles(gallery) {
        if (gallery && gallery.id) {
            setInternalLoading(true);
            await api
                .get(`albums/${gallery.id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setInternalLoading(false);
        }
    }

    useEffect(() => {
        loadMaps();
    }, []);

    useEffect(() => {
        const type_script =
            script_nav && script_nav[r_type] ? script_nav[r_type] : {};
        loadMapPoints(type_script.map_id);
    }, [r_type]);

    useEffect(() => {
        loadFiles(currGallery.audio);
    }, [currGallery.audio]);

    function handleGalleryClick(event, key, index) {
        const file = fileList[index];

        switch (key) {
            case 'audio': {
                saveActionSettings('audio', {
                    name: file.name,
                    file_id: file.id,
                    url: file.url,
                    path: file.path,
                });
                setAudioDialogOpen(false);
                break;
            }
            default:
                break;
        }
    }

    function handleGalleryChange(event, key) {
        const _id = event.target.value;
        switch (key) {
            case 'audio':
                {
                    let galleryObj = defAudioGalleries.find(g => {
                        return g.id === _id;
                    });
                    if (!galleryObj) {
                        galleryObj = audioGalleries.find(g => {
                            return g.id === _id;
                        });
                    }
                    setCurrGallery({ ...currGallery, audio: galleryObj });
                }
                break;
            default:
        }
    }

    function handleDialogClose(dialog) {
        switch (dialog) {
            case 'media':
                setAudioDialogOpen(false);
                break;
            default:
                setAudioDialogOpen(false);
        }
    }

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setInternalLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        const audioCurrGallery = currGallery.audio;
        if (audioCurrGallery) {
            await api
                .post(`albums/${audioCurrGallery.id}/files`, data)
                .then(() => {
                    setToast(<LocaleMessage msg="message.media_uploaded" />);
                    loadFiles(audioCurrGallery);
                })
                .catch(error => handleError(error));
        }
        setInternalLoading(false);
    }

    function renderAudioGallery() {
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.gallery" />
                    </InputLabel>
                    <Select
                        id="gallery"
                        value={currGallery.audio ? currGallery.audio.id : ''}
                        onChange={event => handleGalleryChange(event, 'audio')}
                    >
                        {defAudioGalleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label={
                                        <LocaleMessage msg="message.open_all.short" />
                                    }
                                    style={{ marginRight: '10px' }}
                                />
                                {g.name}
                            </MenuItem>
                        ))}
                        {audioGalleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {internalLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={40}
                            width={40}
                        />
                    </div>
                ) : (
                    <>
                        {currGallery.audio ? (
                            <div
                                className="sidecard-body"
                                style={{ height: '45vh' }}
                            >
                                <GalleryView
                                    media={fileList}
                                    allowNew={currGallery.audio.editable}
                                    multiple
                                    type="audios"
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleGalleryClick(e, 'audio', _id)
                                    }
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </>
        );
    }

    function renderAudioDialog() {
        return (
            <SimpleDialog
                open={audioDialogOpen}
                onClose={() => handleDialogClose('audio')}
                title={
                    <LocaleMessage msg="page.applications.conversation.condition.media" />
                }
                content={renderAudioGallery()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setAudioDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderAudioSettings() {
        const audio = action_settings.audio ? action_settings.audio : null;
        return (
            <MediaArea className="col-md-8 col-12">
                {audio ? (
                    <>
                        {audio.name ? (
                            <Typography variant="button" className="mb-3">
                                {audio.name}
                            </Typography>
                        ) : null}
                        {audio.url ? (
                            <Card className="audio-card">
                                <ReactAudioPlayer
                                    src={audio.url}
                                    controls
                                    title={audio.name}
                                />
                            </Card>
                        ) : null}
                    </>
                ) : null}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => setAudioDialogOpen(true)}
                >
                    <LocaleMessage msg="page.tools.scripts.step.form.audio.choose" />
                </Button>
            </MediaArea>
        );
    }

    function renderSpeechSettings() {
        const lang_list = action_settings.language
            ? [action_settings.language]
            : languageList;
        return (
            <>
                <div className="col-md-5 col-12 mb-5">
                    <FormControl fullWidth>
                        {action_settings.language ? (
                            <InputLabel id="demo-simple-select-standard-label">
                                <LocaleMessage msg="page.tools.scripts.step.form.action.type.speak.language" />
                            </InputLabel>
                        ) : null}
                        <Select
                            displayEmpty
                            value={action_settings.language || ''}
                            onChange={event => {
                                saveActionSettings(
                                    'language',
                                    event.target.value
                                );
                            }}
                        >
                            <MenuItem value="">
                                <LocaleMessage msg="page.tools.scripts.step.form.action.type.speak.language.current" />
                            </MenuItem>
                            {languageList.map(l => {
                                return (
                                    <MenuItem key={l} value={l}>
                                        <LocaleMessage
                                            msg={`list.languages.${l}`}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12">
                    <ParameterArea className="row mt-1 py-3 px-1">
                        <strong
                            className="col-12 mb-3"
                            style={{ textAlign: 'center' }}
                        >
                            <LocaleMessage msg="page.tools.scripts.step.form.text.random" />
                        </strong>
                        <div className="col-12 mb-3">
                            {lang_list.map(l => {
                                const l_label = `list.languages.${l}`;
                                const l_texts = texts[l] || [];
                                return (
                                    <MultipleInputs
                                        margin="mb-1"
                                        key={`lang_input_${l}`}
                                        language={l}
                                        label={l_label}
                                        texts={l_texts}
                                        open
                                        onChange={t => {
                                            saveActionSettings('texts', {
                                                ...texts,
                                                [l]: t,
                                            });
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </ParameterArea>
                </div>
            </>
        );
    }

    function renderNavigationSettings() {
        const allow_navigation =
            allowedActions.navigate && allowedActions.navigate[r_type]
                ? allowedActions.navigate[r_type]
                : false;

        const type_script =
            script_nav && script_nav[r_type] ? script_nav[r_type] : {};

        const type_navigation =
            navigation_settings && navigation_settings[r_type]
                ? navigation_settings[r_type]
                : {};

        const type_maps = mapList.filter(m => {
            return m.robot_type && m.robot_type.slug === r_type;
        });

        return script_nav.active ? (
            <AppConfigRobotType
                className="col-12 mb-1"
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={t => setCurrType(t)}
            >
                <div
                    className="col-12 row mb-3"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {allow_navigation ? (
                        <>
                            <div className="col-md-5 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.tools.scripts.step.form.navigation.map" />
                                    </InputLabel>
                                    <Select
                                        value={type_script.map_id || ''}
                                        disabled
                                    >
                                        {type_maps.map(m => (
                                            <MenuItem key={m.id} value={m.id}>
                                                {m.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-5 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.tools.scripts.step.form.navigation.point" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            type_navigation.point_id
                                                ? type_navigation.point_id
                                                : ''
                                        }
                                        onChange={event =>
                                            saveActionSettings('navigation', {
                                                ...navigation_settings,
                                                [r_type]: {
                                                    ...type_navigation,
                                                    map_id: type_script.map_id,
                                                    point_id:
                                                        event.target.value,
                                                },
                                            })
                                        }
                                    >
                                        {mapPointList.map(p => (
                                            <MenuItem key={p.id} value={p.id}>
                                                {p.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    ) : (
                        <div className="mt-1 mb-5">
                            <LocaleMessage msg="page.tools.scripts.step.form.action.skip" />
                        </div>
                    )}
                </div>
            </AppConfigRobotType>
        ) : (
            <div className="mt-1 mb-3">
                <LocaleMessage msg="page.tools.scripts.step.form.action.type.navigate.inactive" />
            </div>
        );
    }

    function renderActionSettings() {
        switch (action_settings.type) {
            case 'navigate':
                return renderNavigationSettings();
            case 'speak':
                return renderSpeechSettings();
            case 'play_audio':
                return renderAudioSettings();
            case 'no_action':
            default:
                return null;
        }
    }

    function renderActionWarnings() {
        const action_warning = warnings[action_settings.type];

        return action_warning ? (
            <FormHelperText>
                {robotTypes.map(t => {
                    return action_warning && action_warning[t.slug] ? (
                        <div>
                            <LocaleMessage msg={action_warning[t.slug]} />
                        </div>
                    ) : null;
                })}
            </FormHelperText>
        ) : null;
    }

    return (
        <div
            className="col-12 row"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="col-md-5 col-12 mb-3">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.tools.scripts.step.form.action.type.title" />
                    </InputLabel>
                    <Select
                        value={action_settings.type || ''}
                        onChange={event =>
                            saveActionSettings('type', event.target.value)
                        }
                    >
                        {Object.keys(action_types).map(k => {
                            const action = action_types[k];
                            if (
                                action.type === 'navigate' &&
                                !script_nav.active
                            )
                                return null;
                            return action ? (
                                <MenuItem
                                    key={action.value}
                                    value={action.value}
                                >
                                    <LocaleMessage msg={action.label_code} />
                                </MenuItem>
                            ) : null;
                        })}
                    </Select>
                    {renderActionWarnings()}
                </FormControl>
            </div>

            {audioDialogOpen ? renderAudioDialog() : null}

            {action_settings.type ? (
                <div className="col-12 row mt-3">
                    <div
                        className="col-12 row mb-5"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        {renderActionSettings()}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

ActionConfig.defaultProps = {
    config: {},
    defaultGalleries: {},
    audioGalleries: [],
    languageList: [],
    currType: {},
};

ActionConfig.propTypes = {
    allowedActions: PropTypes.object.isRequired,
    scriptSettings: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
    defaultGalleries: PropTypes.object,
    audioGalleries: PropTypes.array,
    languageList: PropTypes.array,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object,
    setCurrType: PropTypes.func.isRequired,
};
