/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

export default function CustomOperationTooltip({ active, payload }) {
    const active_label = payload && payload[0] ? payload[0].payload : {};
    const occupied_label = payload && payload[1] ? payload[1].payload : {};

    if (active && payload) {
        return (
            <div style={{ backgroundColor: '#fff', padding: '15px' }}>
                <p style={{ color: '#000', marginBottom: '5px' }}>
                    {active_label.date}
                </p>
                <p style={{ color: '#000', marginBottom: '5px' }}>
                    <LocaleMessage msg="rc3.dashboard.chart.label.active" />
                    {`: ${active_label.active_label}${payload[0].unit}`}
                </p>
                <p style={{ color: '#000', marginBottom: '0px' }}>
                    <LocaleMessage msg="rc3.dashboard.chart.label.in_use" />
                    {`: ${occupied_label.occupied_label}${payload[1].unit}`}
                </p>
            </div>
        );
    }
    return null;
}

CustomOperationTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
};

CustomOperationTooltip.defaultProps = {
    active: false,
    payload: [],
};
