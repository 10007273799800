/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdList } from 'react-icons/md';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Tab, Tabs, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import Page404 from '~/pages/Open/Page404';
import api from '~/services/pluginbot-api';

import ContactLists from './ContactLists';
import Settings from './Settings';

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
            textAlign: 'left',
        },
    };
});

const tab_settings = [
    {
        label: <LocaleMessage msg="label.settings.app.telepresence.settings" />,
        value: 'settings',
    },
    {
        label: <LocaleMessage msg="label.settings.app.telepresence.contacts" />,
        value: 'contacts',
    },
];

const pages = {
    settings: Settings,
    contacts: ContactLists,
};

export default function TelepresenceApp({
    setToast,
    appSettings,
    updateObject,
    location,
}) {
    const classes = useStyles();
    const { robot_types, group } = appSettings;
    const group_id = group ? group.id : '*';
    const robot_type = robot_types[0] || {};

    const [isLoading, setIsLoading] = useState(false);
    const [robotSettings, setRobotSettings] = useState({});
    const [imageGalleriesList, setImageGalleriesList] = useState([]);
    const [defaultGalleries, setDefaultGalleries] = useState({});
    const [needSaving, setNeedSaving] = useState(false);

    const [queryTab, setQueryTab] = useQueryState('tab', 'settings');
    const [value, setValue] = useState(queryTab || tab_settings[0].value);

    const [anchorEl, setAnchorEl] = useState(null);
    const tooltipOpen = Boolean(anchorEl);

    const { search } = location;

    const handleChangeTab = (event, newValue) => {
        if (needSaving && newValue !== value) {
            setAnchorEl(event.currentTarget);
            return;
        }
        if (!needSaving) {
            setValue(newValue);

            if (search !== newValue) {
                setQueryTab(newValue);
            }
        }
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            setToast({
                color: 'error',
                body: message,
            });
        } else if (error.request) {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.request" />,
            });
        } else {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.unknown" />,
            });
        }
        setIsLoading(false);
    }

    async function loadRobotSettings(_id) {
        await api
            .get(`types/${_id}`)
            .then(response => {
                setRobotSettings(response.data);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadRobotDefaults() {
        await api
            .get(`types/${robot_type.id}/defaults`)
            .then(response => {
                const defaults = response.data;
                const default_galleries = {};
                defaults.forEach(g => {
                    if (default_galleries[g.type]) {
                        default_galleries[g.type].push(g);
                    }
                    default_galleries[g.type] = [g];
                });
                setDefaultGalleries(default_galleries);
            })
            .catch(error => requestError(error));
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group &&
                        (g.group.id === '*' || g.group.id === group_id)
                    );
                });
                setImageGalleriesList(filtered);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        if (robot_type.id) {
            loadRobotDefaults(robot_type.id);
            loadRobotSettings(robot_type.id);
        }
    }, [robot_type]);

    useEffect(() => {
        loadGalleryList();
    }, []);

    function renderInternalPage(page) {
        const Page = pages[page] || Page404;
        return (
            <Page
                updateObject={data => {
                    updateObject(data);
                    setNeedSaving(false);
                }}
                app={appSettings}
                robot={robotSettings}
                defaultGalleries={defaultGalleries}
                imageGalleriesList={imageGalleriesList}
                setToast={message => setToast(message)}
                requestError={error => requestError(error)}
                handleChangeTab={(e, v) => handleChangeTab(e, v)}
                needSaving={needSaving}
                setNeedSaving={s => setNeedSaving(s)}
            />
        );
    }

    return (
        <div className="col-12 mb-5 mt-5">
            {isLoading ? (
                <Splash />
            ) : (
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.applications.telepresence.title" />
                    }
                    Icon={MdList}
                    hide
                >
                    <>
                        <div className="sidecard-body">
                            <Tabs
                                indicatorColor="primary"
                                value={value || '0'}
                                onChange={handleChangeTab}
                                className={classes.tabs}
                                centered
                            >
                                {tab_settings.map(s => {
                                    return (
                                        <Tab
                                            key={s.value}
                                            label={s.label}
                                            value={s.value}
                                        />
                                    );
                                })}
                            </Tabs>

                            <div style={{ flex: 1 }}>
                                {tooltipOpen ? (
                                    <Popover
                                        open={tooltipOpen}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div style={{ padding: '15px' }}>
                                            <LocaleMessage msg="message.need_save.content" />
                                        </div>
                                    </Popover>
                                ) : null}

                                {renderInternalPage(value)}
                            </div>
                        </div>
                    </>
                </CardSideBordered>
            )}
        </div>
    );
}

TelepresenceApp.propTypes = {
    appSettings: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
};
