/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdWidgets } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function PlatformModuleForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        group: 'module',
    });
    const [icon, setIcon] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [moduleSettings, setModuleSettings] = useState({});

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadType(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`types/${_id}`)
                .then(async response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        group: 'module',
                        slug: t.slug,
                    });
                    setModuleSettings(t.settings);
                    setIcon(
                        t.settings && t.settings.icon ? t.settings.icon : ''
                    );
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadType(id);
    }, [id]);

    async function updateType(data) {
        await api
            .put(`types/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.platform_modules.form.update_success" />
                );
                setIsLoading(false);
                loadType(id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options: { ...moduleSettings, icon } };
        if (operation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.platform_modules.form.create_success" />
                    );

                    history.push(`/platform_modules/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.platform_modules.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.platform_modules.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/platform_modules',
                    title: <LocaleMessage msg="page.platform_modules.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.platform_modules.form.label.settings" />
                            }
                            Icon={MdWidgets}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.platform_modules.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="label.form.slug" />
                                            }
                                            fullWidth
                                            value={body.slug || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                moduleSettings &&
                                                                moduleSettings.free
                                                                    ? moduleSettings.free
                                                                    : false
                                                            }
                                                            value="free"
                                                            onChange={event =>
                                                                setModuleSettings(
                                                                    {
                                                                        ...moduleSettings,
                                                                        free:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.platform_modules.form.label.free" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

PlatformModuleForm.propTypes = {
    match: PropTypes.object.isRequired,
};
