import styled from 'styled-components';

export const ParameterArea = styled.div`
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
        margin: 10px;
    }

    .lang-text-params {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            margin: 0px;
        }
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    color: ${props => props.theme.palette.primary.main};

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;

export const TextParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px;
    width: 100%;
`;

export const EditableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
`;
