import GetPeriodDates from './GetPeriodDates';

export default function parseData(
    rawData,
    dateSettings,
    dateKey = 'createdAt'
) {
    if (!rawData) {
        return {};
    }

    const loc = dateSettings.locale;
    const date_options = dateSettings.date;
    const label_options = dateSettings.label;

    const { total, list, period } = rawData;

    if (!list || !period) {
        return {};
    }

    const period_dates = GetPeriodDates(period);

    const grouped = {};
    list.forEach(m => {
        const date = new Date(m[dateKey]).toLocaleDateString(
            loc.format,
            date_options
        );
        grouped[date] = grouped[date] ? grouped[date] + 1 : 1;
    });

    const labels = [];
    const grouped_list = [];
    period_dates.forEach(p => {
        const formatted_date = new Date(p).toLocaleDateString(
            loc.format,
            date_options
        );
        const label_date = new Date(p).toLocaleDateString(
            loc.format,
            label_options
        );
        labels.push(label_date);
        grouped_list.push({
            date: label_date,
            value: grouped[formatted_date] || '0',
        });
    });

    return {
        total,
        list: grouped_list,
        labels,
    };
}
