/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import Splash from '~/components/Splash/Outside';

import UnavailablePluginspace from '~/pages/Open/UnavailablePluginspace';
import api from '~/services/pluginbot-api';
import { changePluginspaceRequest } from '~/store/modules/settings/actions';

import { Wrapper } from './styles';

export default function AuthLayout(props) {
    const { children } = props;

    const [pluginspaceList, setPluginspaceList] = useState([]);
    const currPluginspace = useSelector(state => state.settings.pluginspace);
    const [pluginspace, setPluginspace] = useState(
        currPluginspace.subdomain || null
    );

    async function loadPluginspaceList() {
        await api
            .get(`pluginspaces`)
            .then(response => {
                const list = response.data;
                setPluginspaceList(list);
            })
            .catch(() => {});
    }

    const { host } = window.location;

    const dispatch = useDispatch();

    function loadPluginspace(subdomain) {
        setPluginspace(subdomain || 'admin');
        if (subdomain !== 'new') {
            dispatch(changePluginspaceRequest(subdomain || 'admin'));
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENV_VERSION !== 'production') {
            loadPluginspaceList();
        }
    }, []);

    useEffect(() => {
        setPluginspace(currPluginspace.subdomain || null);
    }, [currPluginspace]);

    useEffect(() => {
        const url = host.split('.');
        const _ps = url[0];
        if (pluginspace !== 'new' || _ps !== pluginspace) {
            if (_ps === 'localhost:3000' || _ps === 'staging') {
                loadPluginspace(pluginspace);
            } else {
                loadPluginspace(_ps);
            }
        } else {
            loadPluginspace(pluginspace);
        }
    }, []);

    function pluginspaceSelector() {
        return (
            <FormControl>
                <InputLabel id="demo-simple-select-label">
                    Pluginspace
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={pluginspace || ''}
                    onChange={event => loadPluginspace(event.target.value)}
                >
                    <MenuItem value="new">New Pluginspace</MenuItem>
                    {pluginspaceList.map(p => {
                        return (
                            <MenuItem value={p.subdomain} key={p.id}>
                                {p.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    return pluginspace === null ? (
        <Splash />
    ) : (
        <Wrapper
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <ToastContainer toastClassName="toast-container" autoClose={3000} />
            {process.env.REACT_APP_ENV_VERSION !== 'production'
                ? pluginspaceSelector()
                : null}
            {pluginspace === 'new' ? (
                <UnavailablePluginspace />
            ) : (
                <>{children}</>
            )}
        </Wrapper>
    );
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired,
};
