import { shade } from 'polished';
import styled from 'styled-components';

export const Background = styled.div`
    position: fixed;
    height: ${props =>
        props.footerStyle ? props.footerStyle.height : '135px'};
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(0deg, #000 40%, transparent);
`;

export const AppFooter = styled.div`
    position: fixed;
    height: ${props =>
        props.footerStyle ? props.footerStyle.height : '135px'};
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: right 25% bottom, left 25% bottom, right 25% bottom,
        left 25% bottom, right bottom;
    background-image: ${props => {
        const color = props.theme.palette.primary.main;
        const blackW = props.footerStyle
            ? props.footerStyle.blackWidth
            : '100px';
        const colorW = props.footerStyle
            ? props.footerStyle.colorWidth
            : '100px';
        return props.smallScreen
            ? `
                linear-gradient(#000, #000)
            `
            : `
                linear-gradient(
                    -135deg,
                    transparent
                        ${blackW}, ${shade(0.95, color)} ${blackW}
                ),
                linear-gradient(
                    135deg,
                    transparent
                        ${blackW}, ${shade(0.95, color)} ${blackW}
                ),
                linear-gradient(
                    -135deg,
                    transparent
                        ${colorW}, ${color} ${colorW}
                ),
                linear-gradient(
                    135deg,
                    transparent
                    ${colorW}, ${color} ${colorW}
                ),
                linear-gradient(
                    0deg,${color} 100px, ${color} 100px
                )
            `;
    }};
    background-size: ${props => {
        const blackH = props.footerStyle
            ? props.footerStyle.blackHeight
            : '100px';
        const colorH = props.footerStyle
            ? props.footerStyle.lineHeight
            : '30px';
        return props.smallScreen
            ? `100%`
            : `50% ${blackH}, 50% ${blackH}, 50% 100%, 50% 100%, 100% ${colorH}`;
    }};

    .title {
        width: ${props => {
            const def_width = props.footerStyle
                ? props.footerStyle.menuWidth
                : '55%';
            return props.smallScreen ? '100%' : def_width;
        }};
        height: ${props =>
            props.footerStyle ? props.footerStyle.lineHeight : '30px'};
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.5vh;
        font-weight: bold;
        background-color: ${props => {
            return props.theme.palette.pluginspace.primary;
        }};
    }
`;

export const AppList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    width: ${props => {
        const def_width = props.footerStyle
            ? props.footerStyle.menuWidth
            : '55%';
        return props.smallScreen ? '100%' : def_width;
    }};

    .scrollbar {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        :hover {
            cursor: pointer;
        }
    }

    ul {
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        color: #fff;

        li {
            height: 100%;
            display: block;

            .item {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-width: 100px;
                font-size: 1.4vh;

                :hover {
                    cursor: pointer;
                    border: 1px solid
                        ${props => props.theme.palette.pluginspace.primary};
                }

                svg {
                    margin-bottom: 1vh;
                }
            }
        }
    }
`;
