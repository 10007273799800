/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

export default function InfoCard({ data, icon, color, onClick }) {
    return (
        <Card
            className="jr-card jr-card-widget net-chart"
            style={{
                height: '100%',
            }}
            onClick={onClick}
        >
            <div
                className="jr-card-thumb"
                style={{
                    backgroundColor: color,
                }}
            >
                <i className={`zmdi zmdi-${icon}`} />
            </div>
            <div className="jr-card-body br-break ml-2">
                <h1 className="mb-0" style={{ color }}>
                    <strong>{data.title}</strong>
                </h1>
                <p className="mb-0 text-grey">{data.subTitle}</p>
            </div>
        </Card>
    );
}

InfoCard.defaultProps = {
    icon: 'info-outline',
    color: '#3f51b5',
    onClick: () => {},
};

InfoCard.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.object.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string,
};
