/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdList } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FormStepper from '~/components/Form/Stepper';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import ActionConfig from './ActionConfig';
import BasicInfoConfig from './BasicInfoConfig';
import BehaviourConfig from './BehaviourConfig';
import ExitConditionConfig from './ExitConditionConfig';

const r_actions = {
    no_action: {
        cruzr: true,
        temi: true,
    },
    navigate: {
        cruzr: true,
        temi: true,
    },
    speak: {
        cruzr: true,
        temi: true,
        nao: true,
    },
    play_audio: {
        cruzr: true,
        temi: true,
        nao: true,
    },
};

export default function StepForm({ match }) {
    const dispatch = useDispatch();

    const { id, step_id } = match.params;

    const settings = useSelector(state => state.settings || null);
    const active_group = settings.active || {};

    const [robotTypes, setRobotTypes] = useState([]);
    const [currType, setCurrType] = useState({});

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState({
        type: 'step',
    });

    const [scriptSettings, setScriptSettings] = useState({});
    const [stepList, setStepList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [audioGalleryList, setAudioGalleryList] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadStep(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/scripts/${id}/steps/${_id}`)
                .then(response => {
                    const { data } = response;
                    setBody(data);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadScriptSteps(_id) {
        await api
            .get(`/scripts/${_id}/steps`)
            .then(response => {
                const { data } = response;
                const list = data.map(s => {
                    return {
                        ...s,
                        label: s.description ? s.description : '---',
                    };
                });
                setStepList(list);
            })
            .catch(error => requestError(error));
    }

    async function loadScript(_id) {
        setIsLoading(true);
        await api
            .get(`scripts/${_id}`)
            .then(async response => {
                const s = response.data;
                setScriptSettings(s);

                const types = s.robot_types || [];
                setRobotTypes(types);
                setCurrType(types[0] || null);

                await loadScriptSteps(_id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadAudioGalleryList() {
        await api
            .get(`albums?type=audios`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group &&
                        (g.group.id === '*' || g.group.id === active_group.id)
                    );
                });
                setAudioGalleryList(filtered);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadAudioGalleryList();
    }, []);

    useEffect(() => {
        loadScript(id);
        loadScriptSteps(id);
    }, [id]);

    useEffect(() => {
        loadStep(step_id);
    }, [step_id]);

    useEffect(() => {
        if (step_id === 'new') {
            setBody({
                type: stepList.length === 0 ? 'start' : 'step',
            });
        }
    }, [step_id, stepList]);

    function handleStepClick(step) {
        setActiveStep(step);
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    async function updateStep(data) {
        await api
            .put(`/scripts/${id}/steps/${step_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.tools.scripts.step.form.update_success" />
                );
                setIsLoading(false);
                loadStep(step_id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit() {
        const data = { ...body };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`/scripts/${id}/steps`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.scripts.step.form.create_success" />
                    );
                    history.push(`/tools/scripts/${id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateStep(data);
        }
    }

    function renderBasicInfo() {
        return (
            <BasicInfoConfig
                config={body}
                stepList={stepList}
                setConfig={c => setBody({ ...body, ...c })}
            />
        );
    }

    function renderActionSettings() {
        const script_content =
            scriptSettings && scriptSettings.content
                ? scriptSettings.content
                : {};
        return (
            <ActionConfig
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={r => setCurrType(r)}
                languageList={
                    script_content &&
                    script_content.languages &&
                    script_content.languages.list
                        ? script_content.languages.list
                        : []
                }
                allowedActions={r_actions}
                scriptSettings={scriptSettings}
                config={body && body.content ? body.content : {}}
                setConfig={c => setBody({ ...body, content: c })}
                handleError={error => requestError(error)}
                setToast={_toast => toast.success(_toast)}
                audioGalleries={audioGalleryList}
            />
        );
    }

    function renderBehaviourSettings() {
        const curr_action =
            body.content && body.content.action ? body.content.action : {};
        const curr_robots =
            curr_action && curr_action.type && r_actions[curr_action.type]
                ? r_actions[curr_action.type]
                : {};
        const allow_action =
            currType && curr_robots[currType.slug]
                ? curr_robots[currType.slug]
                : false;

        return (
            <BehaviourConfig
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={r => setCurrType(r)}
                scriptSettings={scriptSettings}
                allowAction={allow_action}
                config={body && body.content ? body.content : {}}
                setConfig={c => setBody({ ...body, content: c })}
                handleError={error => requestError(error)}
                setToast={_toast => toast.success(_toast)}
            />
        );
    }

    function renderExitConditionSettings() {
        const curr_action_type =
            body.content && body.content.action && body.content.action.type
                ? body.content.action.type
                : '';
        return (
            <ExitConditionConfig
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={r => setCurrType(r)}
                stepAction={curr_action_type}
                config={body && body.settings ? body.settings : {}}
                setConfig={c =>
                    setBody({
                        ...body,
                        settings: c,
                    })
                }
                stepList={stepList}
                enabled={body && body.type !== 'end'}
                handleError={error => requestError(error)}
                setToast={_toast => toast.success(_toast)}
            />
        );
    }

    const steps = [
        {
            key: 'info',
            label: <LocaleMessage msg="page.tools.scripts.step.form.info" />,
            content: renderBasicInfo(),
        },
        {
            key: 'action',
            label: <LocaleMessage msg="page.tools.scripts.step.form.action" />,
            content: renderActionSettings(),
        },
        {
            key: 'behaviour',
            label: (
                <LocaleMessage msg="page.tools.scripts.step.form.behaviour" />
            ),
            content: renderBehaviourSettings(),
        },
        {
            key: 'exit',
            label: <LocaleMessage msg="page.tools.scripts.step.form.exit" />,
            content: renderExitConditionSettings(),
        },
    ];

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.scripts.step.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.scripts.step.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/scripts',
                    title: <LocaleMessage msg="breadcrumbs.scripts" />,
                },
                {
                    url: `/tools/scripts/${id}`,
                    title: (
                        <LocaleMessage msg="page.tools.scripts.step.form.title" />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <div className="col-md-10 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.tools.scripts.step.form.title" />
                    }
                    Icon={MdList}
                >
                    <div
                        className="col-12 mb-3"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <FormStepper
                            className="col-12"
                            steps={steps}
                            activeStep={activeStep}
                            handleStepClick={index => handleStepClick(index)}
                            handleBack={() => handleBack()}
                            handleNext={() => handleNext()}
                            handleSubmit={() => handleSubmit()}
                        />
                        <Button
                            className="mt-3 mb-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </CardSideBordered>
            </div>
        </PageContent>
    );
}

StepForm.propTypes = {
    match: PropTypes.object.isRequired,
};
