/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

export default function CustomInteractionTooltip({ active, payload }) {
    if (active && payload) {
        return (
            <div style={{ backgroundColor: '#fff', padding: '15px' }}>
                <p style={{ color: '#000', marginBottom: '5px' }}>
                    {payload[0] && payload[0].payload
                        ? payload[0].payload.date
                        : ''}
                </p>
                <p style={{ color: '#000', marginBottom: '0px' }}>
                    <LocaleMessage msg="rc3.dashboard.chart.label.total" />
                    {`: ${payload[0].value}`}
                </p>
            </div>
        );
    }
    return null;
}

CustomInteractionTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
};

CustomInteractionTooltip.defaultProps = {
    active: false,
    payload: [],
};
