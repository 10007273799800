/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdCached, MdDelete } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';

import lngLabels from '~/util/LangMessages';

import { ParameterArea, DeleteParameter } from './styles';

const condition_types = [
    {
        value: 'robot_state',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.condition_type.state" />
        ),
    },
    {
        value: 'conversation',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.condition_type.conversation" />
        ),
    },
    {
        value: 'dialog_node',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.condition_type.dialog_node" />
        ),
    },
    // {
    //     value: 'context',
    //     label: (
    //         <LocaleMessage msg="page.applications.conversation.content.condition_type.context" />
    //     ),
    // },
    // {
    //     value: 'integration_resp',
    //     label: (
    //         <LocaleMessage msg="page.applications.conversation.content.condition_type.integration_response" />
    //     ),
    // },
];

const state_list = [
    {
        value: 'standby',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.state.standby" />
        ),
    },
    {
        value: 'wake_up',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.state.wakeup" />
        ),
    },
    {
        value: 'listening',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.state.listening" />
        ),
    },
    {
        value: 'thinking',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.state.thinking" />
        ),
    },
];

const comparison_types = [
    {
        value: 'key-exists',
        label: <LocaleMessage msg="list.comparison.key_exists" />,
    },
    {
        value: 'value-equals',
        label: <LocaleMessage msg="list.comparison.value_equals" />,
    },
    {
        value: 'value-different',
        label: <LocaleMessage msg="list.comparison.value_different" />,
    },
    {
        value: 'value-less',
        label: <LocaleMessage msg="list.comparison.value_less" />,
    },
    {
        value: 'value-less-equal',
        label: <LocaleMessage msg="list.comparison.value_less_equal" />,
    },
    {
        value: 'value-greater',
        label: <LocaleMessage msg="list.comparison.value_greater" />,
    },
    {
        value: 'value-greater-equal',
        label: <LocaleMessage msg="list.comparison.value_greater_equal" />,
    },
];

const conversation_label = {
    question_any:
        'page.applications.conversation.content.condition.question.any',
    subject_any: 'page.applications.conversation.content.condition.subject.any',
    topic_any: 'page.applications.conversation.content.condition.topic.any',
};

export default function DialogConditionConfig(props) {
    const {
        isLoading,
        reloadConversationContent,
        dialogContent,
        config,
        setConfig,
    } = props;

    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';

    const lng_conditions = lngLabels[lang] || {};

    function getCondition(key, defaultValue = {}) {
        return config && config.condition && config.condition[key]
            ? config.condition[key]
            : defaultValue;
    }

    function onConditionTypeChange(event) {
        const val = event.target.value;
        const content =
            val === 'conversation'
                ? { question: '--any--', subject: '--any--' }
                : {};
        setConfig({ type: val, condition: content });
    }

    function onConversationBodyChange(key, val) {
        setConfig({
            ...config,
            condition: { ...config.condition, [key]: val },
        });
    }

    function onConditionBodyChange(event, key) {
        const val = event.target.value;
        setConfig({
            ...config,
            condition: { ...config.condition, [key]: val },
        });
    }

    function handleNewItem(condition_key) {
        const items = getCondition(condition_key, []);
        const new_item = { key: '', value: '' };
        setConfig({
            ...config,
            condition: {
                ...config.condition,
                [condition_key]: [...items, new_item],
            },
        });
    }

    function handleDeleteItem(condition_key, idx) {
        const items = getCondition(condition_key, []);
        const new_list = [...items.slice(0, idx), ...items.slice(idx + 1)];

        setConfig({
            ...config,
            condition: { ...config.condition, [condition_key]: new_list },
        });
    }

    function onItemChange(event, condition_key, index, key) {
        const items = getCondition(condition_key, []);
        if (items[index]) {
            items[index] = {
                ...items[index],
                [key]: event.target.value,
            };
        }
        setConfig({
            ...config,
            condition: { ...config.condition, [condition_key]: items },
        });
    }

    function stateConditionForm() {
        return (
            <div className="col-md-6 col-12 row mb-3">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.conversation.content.condition_type.state" />
                    </InputLabel>
                    <Select
                        value={
                            config.condition && config.condition.state
                                ? config.condition.state
                                : ''
                        }
                        onChange={event =>
                            onConditionBodyChange(event, 'state')
                        }
                    >
                        {state_list.map(state => (
                            <MenuItem key={state.value} value={state.value}>
                                {state.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function conversationConditionForm() {
        const separate_topic = dialogContent && dialogContent.topics;

        const q_array =
            dialogContent && dialogContent.questions
                ? dialogContent.questions.map(q => {
                      return {
                          value: q,
                          label: q ? q.toUpperCase() : '',
                      };
                  })
                : [];

        const s_array =
            dialogContent && dialogContent.subjects
                ? dialogContent.subjects.map(s => {
                      return {
                          value: s,
                          label: s ? s.toUpperCase() : '',
                      };
                  })
                : [];

        const t_array =
            dialogContent && dialogContent.topics
                ? dialogContent.topics.map(t => {
                      return {
                          value: t,
                          label: t ? t.toUpperCase() : '',
                      };
                  })
                : [];

        const questions = [
            {
                value: '--any--',
                label: lng_conditions[conversation_label.question_any] || '',
            },
            ...q_array,
        ];

        const subjects = [
            {
                value: '--any--',
                label: lng_conditions[conversation_label.subject_any] || '',
            },
            ...s_array,
        ];

        const topics = separate_topic
            ? [
                  {
                      value: '*',
                      label: lng_conditions[conversation_label.topic_any] || '',
                  },
                  ...t_array,
              ]
            : null;

        const curr_subject =
            config.condition && config.condition.subject
                ? config.condition.subject
                : '--any--';
        const curr_topic =
            config.condition && config.condition.topic
                ? config.condition.topic
                : '*';

        let subject = curr_subject;
        let topic = curr_topic;
        if (separate_topic) {
            const subj_parts = curr_subject.split(':');
            subject = subj_parts[0];
            if (subj_parts[1]) {
                topic = subj_parts[1];
            }
        }

        const values = {
            question:
                config.condition && config.condition.question
                    ? config.condition.question
                    : '--any--',
            subject,
            topic,
        };

        return (
            <div className="col-md-6 col-12 row ">
                <div className="col-12 mb-3">
                    <FormSelect
                        options={questions}
                        optionKey="value"
                        labelKey="label"
                        label={
                            <LocaleMessage msg="page.applications.conversation.content.condition.question" />
                        }
                        onChange={value => {
                            onConversationBodyChange('question', value);
                        }}
                        value={values.question ? values.question : '--any--'}
                        defaultValue="--any--"
                    />
                </div>
                <div className="col-12 mb-3">
                    <FormSelect
                        options={subjects}
                        optionKey="value"
                        labelKey="label"
                        label={
                            <LocaleMessage msg="page.applications.conversation.content.condition.subject" />
                        }
                        onChange={value => {
                            onConversationBodyChange(
                                'subject',
                                topics ? `${value}:*` : value
                            );
                        }}
                        value={values.subject ? values.subject : '--any--'}
                        defaultValue="--any--"
                    />
                </div>
                {topics ? (
                    <div className="col-12 mb-3">
                        <FormSelect
                            options={topics}
                            optionKey="value"
                            labelKey="label"
                            label={
                                <LocaleMessage msg="page.applications.conversation.content.condition.topic" />
                            }
                            onChange={value => {
                                onConversationBodyChange(
                                    'subject',
                                    `${values.subject}:${value}`
                                );
                            }}
                            value={values.topic ? values.topic : '*'}
                            defaultValue="*"
                        />
                    </div>
                ) : null}
                <div
                    className="col-12 mb-3"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdCached />}
                        style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                        }}
                        onClick={() => reloadConversationContent()}
                    >
                        <LocaleMessage msg="page.applications.conversation.content.condition.refresh_list" />
                    </Button>
                </div>
            </div>
        );
    }

    function dialogNodeConditionForm() {
        return (
            <div className="col-md-6 col-12 row">
                <div className="col-12 mb-3">
                    <TextField
                        id="dialognode"
                        label={
                            <LocaleMessage msg="page.applications.conversation.content.condition_type.dialog_node" />
                        }
                        fullWidth
                        value={
                            config &&
                            config.condition &&
                            config.condition.dialog_node
                                ? config.condition.dialog_node
                                : ''
                        }
                        onChange={event =>
                            onConditionBodyChange(event, 'dialog_node')
                        }
                    />
                </div>
            </div>
        );
    }

    function renderVariableItem(condition_key, idx, item) {
        return (
            <FormGroup
                row
                key={idx}
                className="row col-12 mb-3"
                style={{ padding: '0px' }}
            >
                <div className="col-3">
                    <TextField
                        label={
                            <LocaleMessage msg="page.applications.conversation.content.condition.variable" />
                        }
                        fullWidth
                        value={item ? item.key : ''}
                        onChange={event =>
                            onItemChange(event, condition_key, idx, 'key')
                        }
                    />
                </div>
                <div className="col-4">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.content.condition.operator" />
                        </InputLabel>
                        <Select
                            value={item.op ? item.op : ''}
                            onChange={event =>
                                onItemChange(event, condition_key, idx, 'op')
                            }
                        >
                            {comparison_types.map(c => {
                                return (
                                    <MenuItem value={c.value} key={c.value}>
                                        {c.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-4">
                    {item.op !== 'key-exists' ? (
                        <TextField
                            label={
                                <LocaleMessage msg="page.applications.conversation.content.condition.value" />
                            }
                            fullWidth
                            value={item ? item.value : ''}
                            onChange={event =>
                                onItemChange(event, condition_key, idx, 'value')
                            }
                        />
                    ) : null}
                </div>
                <DeleteParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => handleDeleteItem(condition_key, idx)}
                    />
                </DeleteParameter>
            </FormGroup>
        );
    }

    function variablesForm(condition_key, list) {
        return (
            <ParameterArea className="col-md-8 col-12 mt-3 mb-3">
                <LocaleMessage msg="page.applications.conversation.content.label.at_least_one" />
                <FormControl component="fieldset" fullWidth>
                    {list
                        ? list.map((item, idx) => {
                              return renderVariableItem(
                                  condition_key,
                                  idx,
                                  item
                              );
                          })
                        : null}
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNewItem(condition_key)}
                    fullWidth
                >
                    <LocaleMessage msg="page.applications.conversation.content.condition.new_key" />
                </Button>
            </ParameterArea>
        );
    }

    function contextConditionForm() {
        const condition_key = 'variables';
        const variables = getCondition(condition_key, []);
        return variablesForm(condition_key, variables);
    }

    function integrationConditionForm() {
        const condition_key = 'variables';
        const variables = getCondition(condition_key, []);
        return variablesForm(condition_key, variables);
    }

    function renderConditionSettings() {
        switch (config.type) {
            case 'robot_state':
                return stateConditionForm();
            case 'conversation':
                return conversationConditionForm();
            case 'dialog_node':
                return dialogNodeConditionForm();
            case 'context':
                return contextConditionForm();
            case 'integration_resp':
                return integrationConditionForm();
            default:
                return null;
        }
    }

    return (
        <div
            className="col-12 row mt-2 mb-4"
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {isLoading ? (
                <ReactLoading
                    type="bars"
                    color="#c8c8c8"
                    height={50}
                    width={50}
                />
            ) : (
                <div
                    className="col-12 row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    <div className="col-md-6 col-12 mb-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.condition.type" />
                            </InputLabel>
                            <Select
                                value={(config && config.type) || ''}
                                onChange={event => onConditionTypeChange(event)}
                            >
                                {condition_types.map(type => (
                                    <MenuItem
                                        key={type.value}
                                        value={type.value}
                                    >
                                        {type.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {config && config.type ? renderConditionSettings() : null}
                </div>
            )}
        </div>
    );
}

DialogConditionConfig.defaultProps = {
    isLoading: false,
    config: {},
    dialogContent: { qustions: [], subjects: [] },
    reloadConversationContent: () => {},
};

DialogConditionConfig.propTypes = {
    config: PropTypes.object,
    isLoading: PropTypes.bool,
    setConfig: PropTypes.func.isRequired,
    dialogContent: PropTypes.object,
    reloadConversationContent: PropTypes.func,
};
