import styled from 'styled-components';

export const Item = styled.div`
    padding: 5px 10px !important;

    .row {
        padding: 0px 15px;
    }

    .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .robot-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    }

    img {
        width: ${props => {
            return props.size || '5vh';
        }};
        height: ${props => {
            return props.size || '5vh';
        }};
        border-radius: 50%;
        border: 2px solid #999;
    }

    .robot-avatar {
        .no-status {
            border: 0.3vh solid #fff;
            background-color: #ddd;
        }

        .status-connected {
            border: 0.3vh solid #0f0;
        }

        .status-occupied {
            border: 0.3vh solid #ff0;
        }

        .status-alert {
            border: 0.3vh solid #f00;
        }

        .status-disconnected {
            border: 0.3vh solid #999;
        }

        .status-undefined {
            border: 0.3vh solid #999;
        }
    }

    :hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }

    .avatar-col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .robot-info {
        color: #ddd;
        padding-top: 5px;
        padding-bottom: 5px;
        flex-direction: column;
        font-size: ${props => {
            return props.fontSize || '1.5vh';
        }};

        .name {
            font-weight: bold;
        }

        .group {
            color: #999999;
        }
    }
`;
