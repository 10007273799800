/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function ExportCSVButton({ data, headers, filename }) {
    return (
        <CSVLink
            data={data || []}
            headers={headers}
            filename={filename}
            onClick={() => {
                toast.info(<LocaleMessage msg="message.generating_file" />);
            }}
        >
            <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<MdFileDownload style={{ color: '#fff' }} />}
            >
                <span style={{ color: '#fff' }}>
                    <LocaleMessage msg="button.export_raw" />
                </span>
            </Button>
        </CSVLink>
    );
}

ExportCSVButton.defaultProps = {
    data: [],
    filename: 'data.csv',
};

ExportCSVButton.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.array.isRequired,
    filename: PropTypes.string,
};
