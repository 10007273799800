/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { DialogContentText } from '@material-ui/core';

import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import lng_labels from '~/util/LangMessages';

export default function Users() {
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState(items);
    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const tableHeaders = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
        {
            id: 'status_label',
            label: <LocaleMessage msg="table.headers.status" />,
        },
        {
            id: 'permission_label',
            label: <LocaleMessage msg="table.headers.pluginspace_permission" />,
        },
        {
            id: 'approved_by',
            label: <LocaleMessage msg="table.headers.approved_by" />,
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`users`)
            .then(response => {
                const users = response.data;

                const user_list = users.map(u => {
                    return {
                        ...u,
                        permission_label: u.permission
                            ? lng_all[`user.permission.${u.permission}`]
                            : '---',
                        status_label: u.status
                            ? lng_all[`user.status.${u.status}`]
                            : '---',
                        approved_by:
                            u.approver && u.approver.name
                                ? u.approver.name
                                : '---',
                    };
                });
                setItems(user_list);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`users/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.users.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadItems();
    }, []);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/pluginspace/users/${id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="buttons.deactivate" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={tableHeaders}
                    data={filteredItems}
                    orderColumn="name"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.users.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.users.list.title" />}
            currentPage={<LocaleMessage msg="page.users.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <>
                    <div className="body-top-controls">
                        <div className="col-3 pl-0">
                            <DataFilter
                                rawData={items}
                                headerColumns={['name', 'email']}
                                returnFilteredData={f_data =>
                                    setFilteredItems(f_data)
                                }
                            />
                        </div>
                        <div
                            className="col-md-3 col-12"
                            style={{ padding: '0px' }}
                        >
                            <NewItemButton
                                link="/pluginspace/users/new"
                                text={
                                    <LocaleMessage msg="page.users.list.add" />
                                }
                            />
                        </div>
                    </div>
                    {buildListView()}
                </>
            </>
        </PageContent>
    );
}
