/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import numeral from 'numeral';
import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportPieChart from '~/components/Reports/ReportPieChart';

import GetFileName from '~/util/GetFileName';

import {
    TotalContainer,
    ChartsContainer,
    RowArea,
    InfoContainer,
    TableArea,
} from '../../style';
import Filter from './FilterData';

export default function CallRequestView({ settings, data, active, colors }) {
    const { format } = settings;
    const _settings = useSelector(state => state.settings || null);
    const call_data = Filter(data, format, _settings);

    const [subTables, setSubTables] = useState({});

    const header_answers = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    const _headers = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'end',
            order_by: 'end_timestamp',
            label: <LocaleMessage msg="table.headers.end_date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'contact_name',
            label: <LocaleMessage msg="table.headers.to_contact" />,
        },
        {
            id: 'answered_name',
            label: <LocaleMessage msg="table.headers.answered_by" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
        {
            id: 'rejections_length',
            label: <LocaleMessage msg="table.headers.rejections" />,
        },
    ];

    const headers_file = {
        main: [
            { key: 'mode_str', label: 'Mode' },
            { key: 'group_name', label: 'Group' },
            { key: 'robot_name', label: 'Robot' },
            { key: 'application_name', label: 'Application' },
            {
                key: 'start_date',
                label: 'Start Date',
            },
            {
                key: 'start_time',
                label: 'Start Time',
            },
            {
                key: 'end_date',
                label: 'End Date',
            },
            {
                key: 'end_time',
                label: 'End Time',
            },
            {
                key: 'contact_name',
                label: 'To Contact',
            },
            {
                key: 'answered_name',
                label: 'Answered By',
            },
            {
                key: 'duration_label',
                label: 'Duration',
            },
            { key: 'rejections_length', label: 'Rejections' },
        ],
        sub: [
            { key: 'date', label: 'Rejection Date' },
            { key: 'time', label: 'Rejection Time' },
            { key: 'user_name', label: 'Rejected By' },
        ],
    };

    const headers_sub = [
        {
            id: 'date_str',
            order_by: 'date_timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'user_name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
    ];

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: call_data.groups,
                  },
              ]
            : []),
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_graphs,
            data: call_data.robots,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: call_data.applications,
        },
        {
            key: 'contacts',
            title: 'per_contact',
            color: colors.dashboard_graphs,
            data: call_data.contacts,
        },
    ];

    const total_calls = data ? data.length : 0;
    const call_list = call_data ? call_data.data : [];
    const call_answers = call_data ? call_data.answers : {};
    const answered = call_answers.accepted ? call_answers.accepted.total : 0;
    const ans_rate = total_calls
        ? numeral((100 * answered) / total_calls).format('0.0')
        : '0';

    function onRowClick(event, _id) {
        const call = call_list.find(i => {
            return i.id === _id;
        });

        if (call) {
            const sub = subTables[_id]
                ? false
                : {
                      id: _id,
                      title: <LocaleMessage msg="table.headers.rejections" />,
                      headers: headers_sub,
                      rows: call.rejections,
                  };

            setSubTables({
                ...subTables,
                [_id]: sub,
            });
        }
    }

    function getSubValues(list, subKey) {
        const full_list = [];

        const sub_headers = headers_file.sub || [];
        const sub_headers_file = sub_headers.map(h => {
            return {
                ...h,
                key: `${subKey}_${h.key}`,
            };
        });

        list.forEach(r => {
            full_list.push(r);

            const sub_list = r.rejections || [];
            sub_list.forEach(v => {
                const sub_obj = {};
                sub_headers.forEach((k, i) => {
                    const sub_h = sub_headers[i] || {};
                    const file_h = sub_headers_file[i] || {};
                    sub_obj[file_h.key] = v[sub_h.key];
                });
                full_list.push(sub_obj);
            });
        });

        return {
            headers: [...headers_file.main, ...sub_headers_file],
            list: full_list,
        };
    }

    function renderCSVButton() {
        const full_list = getSubValues(call_list, 'rejection');

        return (
            <ExportCSVButton
                data={full_list.list}
                headers={full_list.headers}
                filename={GetFileName(
                    `REPORTS-telepresence_calls-requests`,
                    'csv'
                )}
            />
        );
    }

    return (
        <>
            <RowArea className="mt-5 mb-3">
                <div>
                    <p className="card-title">
                        <LocaleMessage msg="page.reports.telepresence.label.mode.request" />
                    </p>
                </div>
            </RowArea>
            <TotalContainer className="row mt-3 mb-3">
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.total_calls" />
                    }
                    value={total_calls}
                />
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.total_duration" />
                    }
                    value={call_data.duration || '00:00:00'}
                />
            </TotalContainer>
            <Divider />
            <RowArea className="mt-5 mb-3">
                <div>
                    <p className="card-title">
                        <LocaleMessage msg="page.reports.telepresence.label.answered.total" />
                    </p>
                </div>
            </RowArea>
            <TotalContainer className="row mt-3 mb-3">
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.answered.total" />
                    }
                    value={
                        call_answers.accepted ? call_answers.accepted.total : 0
                    }
                />
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.mean_duration" />
                    }
                    value={call_data.mean_duration || '00:00:00'}
                />
                <CounterCard
                    classes="col-md-3 col-12"
                    title={
                        <LocaleMessage msg="page.reports.telepresence.label.answered.rate" />
                    }
                    value={`${ans_rate || '0'}%`}
                />
            </TotalContainer>
            <Divider />
            <InfoContainer className="row mt-3 mb-3">
                {call_data ? (
                    <>
                        {pie_charts.map(c => {
                            const datakey = 'total';
                            return (
                                <ReportPieChart
                                    key={`${datakey}_${c.key}`}
                                    classes={def_class}
                                    title={
                                        <LocaleMessage
                                            msg={`page.reports.telepresence.label.${c.title}.total`}
                                        />
                                    }
                                    color={c.color}
                                    data={c.data}
                                    dataKey={datakey}
                                />
                            );
                        })}
                    </>
                ) : null}
            </InfoContainer>
            <Divider />
            {/* <InfoContainer className="row mt-3 mb-3">
                {call_data ? (
                    <>
                        {pie_charts.map(c => {
                            const datakey = 'time';
                            return (
                                <ReportPieChart
                                    key={`${datakey}_${c.key}`}
                                    classes={def_class}
                                    title={
                                        <LocaleMessage
                                            msg={`page.reports.telepresence.label.${c.title}.time`}
                                        />
                                    }
                                    color={c.color}
                                    data={c.data}
                                    dataKey={datakey}
                                    customization={{
                                        tooltip: timeTooltip,
                                    }}
                                />
                            );
                        })}
                    </>
                ) : null}
            </InfoContainer>
            <Divider /> */}
            <RowArea className="mt-5 mb-3">
                <div>
                    <p className="card-title">
                        <LocaleMessage msg="page.reports.telepresence.label.answered.total" />
                    </p>
                </div>
            </RowArea>
            <InfoContainer className="row mt-3 mb-3">
                <ChartsContainer className="row mt-5 mb-3">
                    <TableArea className="col-md-6 col-12 mb-3 table-responsive-material">
                        <DataTable
                            headerColumns={header_answers}
                            data={call_data.users || []}
                            orderDirection="desc"
                            orderColumn="total"
                            hasActions={false}
                            sortable
                            defaultRowsPerPage={10}
                        />
                    </TableArea>
                    <ReportPieChart
                        classes={def_class}
                        title={
                            <LocaleMessage msg="page.reports.telepresence.label.answered.per_user" />
                        }
                        color={colors.dashboard_graphs}
                        data={call_data.users}
                        dataKey="total"
                    />
                </ChartsContainer>
            </InfoContainer>
            <Divider />
            <RowArea className="mt-5 mb-3">
                <div>
                    <p className="card-title">
                        <LocaleMessage msg="page.reports.telepresence.label.rejected.total" />
                    </p>
                </div>
            </RowArea>
            <InfoContainer className="row mt-3 mb-3">
                <ChartsContainer className="row mt-5 mb-3">
                    <ReportPieChart
                        classes={def_class}
                        title={
                            <LocaleMessage msg="page.reports.telepresence.label.per_user.rejection" />
                        }
                        color={colors.dashboard_graphs}
                        data={call_data.rejections}
                        dataKey="total"
                    />
                    <TableArea className="col-md-6 col-12 mb-3 table-responsive-material">
                        <DataTable
                            headerColumns={header_answers}
                            data={call_data.rejections || []}
                            orderDirection="desc"
                            orderColumn="total"
                            hasActions={false}
                            sortable
                            defaultRowsPerPage={10}
                        />
                    </TableArea>
                </ChartsContainer>
            </InfoContainer>
            <Divider />
            <InfoContainer className="row mt-3 mb-3">
                <RowArea className="col-12 mb-3 table-responsive-material">
                    <div>
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.telepresence.label.history" />
                        </p>
                    </div>
                    <DataTable
                        headerColumns={_headers}
                        data={call_data.data || []}
                        orderDirection="desc"
                        orderColumn="start_timestamp"
                        handleTableRowClick={(event, _id) =>
                            onRowClick(event, _id)
                        }
                        hasActions={false}
                        sortable
                        defaultRowsPerPage={25}
                        subTables={subTables}
                        hasHeader
                        hasFilter
                        headerDirection="row-reverse"
                        header={
                            <div className="col-4" style={{ padding: '0px' }}>
                                {renderCSVButton()}
                            </div>
                        }
                    />
                </RowArea>
            </InfoContainer>
        </>
    );
}

CallRequestView.defaultProps = {
    data: [],
    active: {},
};

CallRequestView.propTypes = {
    data: PropTypes.array,
    active: PropTypes.object,
    settings: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
};
