import styled from 'styled-components';

export const RowOptions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    border-left: 1px solid #ddd;

    :hover {
        cursor: pointer;
    }
`;

export const SettingsArea = styled.div`
    border: 1px solid #ddd;
    padding: 10px;
`;

export const CardArea = styled.div`
    border: 1px solid #ddd;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const RC3StatusView = styled.div`
    height: 100%;
    color: #fff;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color #000;

    img {
        max-height: 300px;
        height: 15vh;
        width: auto;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        background-color: #000;
        border: 0.5vh solid ${props => props.color};
        :hover{
            cursor: pointer;
        }
    }

    span {
        margin-top: 10px;
    }
`;

export const Placeholder = styled.div`
    max-height: 300px;
    max-width: 100%;
    height: 10vh;
    width: 10vh;
    background-color: #999;
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    :hover {
        cursor: pointer;
    }
`;
