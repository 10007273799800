/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DateFnsUtils from '@date-io/date-fns';
import { formatDistance, subMonths, formatISO } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import DataTable from '~/components/DataTable';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';

export default function SUBilling({ requestError, filter, formats }) {
    const settings = useSelector(state => state.settings || null);

    const date_loc = getDateLocale(settings);

    const now = new Date();
    const [filterOpen, setFilterOpen] = useState(false);
    const [untilDate, setUntilDate] = useState(now);
    const [fromDate, setFromDate] = useState(new Date(subMonths(now, 3)));

    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const headers_file = [
        { key: 'pluginspace_name', label: 'Pluginspace' },
        { key: 'active', label: 'Is Active' },
        { key: 'group_name', label: 'Group' },
        { key: 'name', label: 'Name' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
        {
            key: 'updated_date',
            label: 'Last Update Date',
        },
        {
            key: 'created_time',
            label: 'Last Update Time',
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(a => {
            const created = new Date(a.created);
            const updated = new Date(a.updated);
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);

            return {
                ...a,
                pluginspace_name:
                    a.pluginspace && a.pluginspace.name
                        ? a.pluginspace.name
                        : '---',
                group_name: a.group && a.group.name ? a.group.name : '---',
                status_name: a.active ? (
                    <LocaleMessage msg="info.status.active" />
                ) : (
                    <LocaleMessage msg="info.status.inactive" />
                ),
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
            };
        });
        setItems(data);
    }

    async function loadBillings() {
        const f_date = formatISO(new Date(fromDate));
        const u_date = formatISO(new Date(untilDate));

        setIsLoading(true);

        const filter_ps = filter ? `&pluginspace=${filter}` : '';
        const filter_url = `?from=${f_date}&until=${u_date}${filter_ps}${
            filterOpen ? '&open=true' : ''
        }`;

        await api
            .get(`admin/billing${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadBillings();
    }, [formats, filter, fromDate, untilDate, filterOpen]);

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headers_file}
                filename={GetFileName(`admin_analytics_billing`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'pluginspace_name',
                label: <LocaleMessage msg="table.headers.pluginspace" />,
            },
            {
                id: 'license_type',
                label: <LocaleMessage msg="table.headers.license_type" />,
            },
            {
                id: 'period',
                label: <LocaleMessage msg="table.headers.period" />,
            },
            {
                id: 'status_name',
                label: <LocaleMessage msg="table.headers.status" />,
            },
        ];

        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    orderColumn="name"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <div className="col-12 row mt-3" style={{ margin: '0px' }}>
                <div className="col-3 mb-3">
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={date_loc}
                    >
                        <DatePicker
                            variant="inline"
                            inputVariant="outlined"
                            views={['month', 'year']}
                            label={<LocaleMessage msg="label.form.from" />}
                            format="MMM yyyy"
                            value={fromDate || new Date()}
                            onChange={date => setFromDate(date)}
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="col-3 mb-3">
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={date_loc}
                    >
                        <DatePicker
                            variant="inline"
                            inputVariant="outlined"
                            views={['month', 'year']}
                            label={<LocaleMessage msg="label.form.until" />}
                            format="MMM yyyy"
                            value={untilDate || new Date()}
                            onChange={date => setUntilDate(date)}
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className="col-3 mb-3">
                    <FormSwitch
                        value={filterOpen || false}
                        onChange={event => setFilterOpen(event.target.checked)}
                        label="Show only open billings"
                    />
                </div>

                <div className="col-3 mb-3">{buildExportButton()}</div>
            </div>
            <>{buildListView()}</>
        </>
    );
}

SUBilling.defaultProps = {
    filter: '',
    requestError: () => {},
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SUBilling.propTypes = {
    filter: PropTypes.string,
    requestError: PropTypes.func,
    formats: PropTypes.object,
};
