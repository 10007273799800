/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import TypesList from '~/components/RobotTypes';

export default function RobotTypes() {
    return (
        <TypesList
            customLabel="page.robot_types.defaults.list.title"
            breadcrumbs={[]}
            allowNew={false}
            linkRoot="/robotdefaults"
            goLink="/albums"
        />
    );
}

RobotTypes.propTypes = {};
