/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdCached } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

export default function Intents(props) {
    const { dialogSettings, handleError } = props;
    const { id } = dialogSettings;

    const [subjectList, setSubjectList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function loadIntents(refresh = false) {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/content?intents=true&refresh=${refresh}`)
            .then(response => {
                const { questions } = response.data;
                const list = questions.map((e, idx) => {
                    const examples = e.examples.map(example => {
                        return `${example}, `;
                    });

                    return {
                        id: idx,
                        intent: `#${e.intent}`,
                        examples,
                    };
                });
                setSubjectList(list);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadIntents();
    }, []);

    function buildListView() {
        const headCells = [
            {
                id: 'intent',
                label: <LocaleMessage msg="page.dialogs.info.intents" />,
                width: '20%',
            },
            {
                id: 'examples',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={subjectList}
                    orderDirection="asc"
                    orderColumn="value"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdCached />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => loadIntents(true)}
                        >
                            <LocaleMessage msg="page.dialogs.info.logs.label.refresh" />
                        </Button>
                    }
                />
            </div>
        );
    }

    return (
        <div className="col-12 mt-3 mb-3">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.dialogs.info.intent_list" />
                </h2>
            </div>
            <div className="sidecard-body">
                {isLoading ? <Splash /> : <>{buildListView()}</>}
            </div>
        </div>
    );
}

Intents.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
