/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
// import { isIOS } from 'react-device-detect';
import { MdArrowUpward } from 'react-icons/md';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import { Fab, DialogContentText } from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import LocaleMessage from '~/components/LocaleMessage';
import Sidebar from '~/components/Sidebar';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Outside';

import bg1 from '~/assets/images/backgrounds/01.png';
import bg2 from '~/assets/images/backgrounds/02.png';
import bg3 from '~/assets/images/backgrounds/03.png';
import bg4 from '~/assets/images/backgrounds/04.png';
import bg5 from '~/assets/images/backgrounds/05.png';
import bg6 from '~/assets/images/backgrounds/06.png';
import bg7 from '~/assets/images/backgrounds/07.png';
import MyGroups from '~/pages/Platform/MyGroups/List';
import history from '~/services/history';
import {
    signOut,
    expireSession,
    refreshSessionRequest,
} from '~/store/modules/auth/actions';
import { loadPluginspaceSettingsRequest } from '~/store/modules/settings/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';

import { DarkBody, Container, Body } from './styles';

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];
const minOpScroll = 150;
const maxOpScroll = minOpScroll + 100;

export default function Default({
    selectGroup,
    children,
    expired,
    dark,
    screen,
}) {
    const { isSmallScreen, isMobile } = screen;
    const myRef = useRef(null);
    const dispatch = useDispatch();

    function randomBG() {
        return backgrounds[Math.floor(Math.random() * backgrounds.length)];
    }

    const [navCollapsed, setNavCollapsed] = useState(false);
    const [idleSettings, setIdleSettings] = useState({
        timeout: 30 * 1000 * 60, // 30 minutes
        isTimedOut: false,
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [heightDistance, setHeightDistance] = useState(0);
    const [bg, setBG] = useState(randomBG());

    const ps_settings = useSelector(state => state.settings);
    const appType = useSelector(state => state.application);
    const isLoading = ps_settings ? ps_settings.isLoading : true;
    const { locale } = ps_settings;
    const lang = locale ? locale.code : 'pt_BR';

    useEffect(() => {
        const { pluginspace } = ps_settings;
        if (pluginspace) {
            dispatch(loadPluginspaceSettingsRequest(pluginspace.id));
            dispatch(loadGroupsRequest(lang));
        }
        dispatch(refreshSessionRequest());
    }, []);

    useEffect(() => {
        setDialogOpen(expired);
    }, [expired]);

    useEffect(() => {
        if (myRef) {
            myRef.current.scrollIntoView();
        }
        setBG(randomBG());
    }, [children]);

    if (appType && appType.app_type !== 'platform') {
        history.push(appType.app_home);
    }

    // if (isIOS && isMobile) {
    //     document.body.classList.add('ios-mobile-view-height');
    // } else if (document.body.classList.contains('ios-mobile-view-height')) {
    //     document.body.classList.remove('ios-mobile-view-height');
    // }

    function isDesktop() {
        return window.innerWidth > 1024;
    }

    const drawerStyle = isDesktop() ? 'mini-drawer' : 'collapsible-drawer';

    function onToggleCollapsedNav() {
        setNavCollapsed(!navCollapsed);
    }

    function hideNav() {
        setNavCollapsed(false);
    }

    function handleDialogClose() {
        setDialogOpen(false);
        dispatch(signOut());
        return true;
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="message.session.expired" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.session.inactivity.description" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'close',
                        onClick: handleDialogClose,
                        label: <LocaleMessage msg="button.close" />,
                    },
                ]}
            />
        );
    }

    function onIdle() {
        if (!idleSettings.isTimedOut) {
            setIdleSettings({
                ...idleSettings,
                isTimedOut: true,
            });
            dispatch(expireSession());
        }
    }

    function handleScroll(e) {
        const { scrollTop } = e;
        setHeightDistance(scrollTop);
    }

    const Background = selectGroup || dark ? DarkBody : Container;

    const opacity =
        heightDistance > minOpScroll && heightDistance < maxOpScroll
            ? `${heightDistance - minOpScroll}%`
            : '100%';

    return (
        <>
            <IdleTimer
                element={document}
                onIdle={() => onIdle()}
                debounce={250}
                timeout={idleSettings.timeout}
            />
            <ToastContainer toastClassName="toast-container" autoClose={3000} />

            {isLoading ? (
                <Splash />
            ) : (
                <>
                    {dialogOpen ? buildConfirmDialog() : null}
                    <div className={`app-container ${drawerStyle}`}>
                        <Header
                            selectGroup={selectGroup}
                            drawer={drawerStyle}
                            smallScreen={isMobile || isSmallScreen}
                            toggleCollapsedNav={() => onToggleCollapsedNav()}
                        />
                        <Sidebar
                            drawer={drawerStyle}
                            open={navCollapsed}
                            toggleCollapsedNav={() => onToggleCollapsedNav()}
                            hideNav={() => hideNav()}
                        />
                        <Background className="app-main-container" bg={bg}>
                            <CustomScrollbars
                                onScrollFrame={e => handleScroll(e)}
                            >
                                <div ref={myRef} />
                                <Body className="app-main-content">
                                    {selectGroup ? <MyGroups /> : children}
                                </Body>
                                <Footer />
                                <Fab
                                    style={{
                                        opacity,
                                        display:
                                            heightDistance > minOpScroll
                                                ? 'flex'
                                                : 'none',
                                        position: 'fixed',
                                        bottom: 50,
                                        right: 50,
                                        cursor: 'pointer',
                                        transitionDuration: '0.2s',
                                        transitionTimingFunction: 'linear',
                                        transitionDelay: '0s',
                                    }}
                                    color="primary"
                                    onClick={() => {
                                        myRef.current.scrollIntoView();
                                    }}
                                >
                                    <MdArrowUpward size={24} />
                                </Fab>
                            </CustomScrollbars>
                        </Background>
                    </div>
                </>
            )}
        </>
    );
}

Default.propTypes = {
    selectGroup: PropTypes.bool,
    expired: PropTypes.bool,
    children: PropTypes.element.isRequired,
    dark: PropTypes.bool,
};

Default.defaultProps = {
    selectGroup: false,
    expired: false,
    dark: false,
};
