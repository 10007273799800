/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import hexToRgba from 'hex-to-rgba';
import numeral from 'numeral';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import { useQueryState } from 'use-location-state';

import {
    Box,
    Badge,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import CustomInteractionTooltip from './CustomInteractionTooltip';
import CustomOperationTooltip from './CustomOperationTooltip';
import CustomTableRow from './CustomTableRow';
import {
    CentralPanel,
    Container,
    MainInfo,
    InfoCard,
    StatusItem,
    TimeChart,
    TableCard,
    TableArea,
    InfoArea,
} from './styles';

const { interaction_keys } = lists;

export default function Dashboard({
    userStatus,
    settings,
    robotLocations,
    robots,
    robotStatus,
    requestError,
    handleChangeTab,
    smallScreen,
}) {
    const mountedRef = React.useRef(true);
    const colors = GetPluginspaceTheme(useTheme());

    const boxShadow = 0;
    const update_time = 30 * 1000;
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const interaction_labels = lng_labels[lang];
    const label_options = { year: 'numeric', month: '2-digit' };

    const date_opt = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
    };

    const windowSize = useWindowDimensions();

    const w_height = windowSize.height || 1080;
    const w_width = windowSize.width || 720;
    const box_height = parseInt(w_height * 0.25, 10);
    const box_margin_h = parseInt(w_height * 0.01, 10);
    const box_margin_w = parseInt(w_width * 0.005, 10);

    const [status, setStatus] = useState({});
    const [now, setNow] = useState(null);
    const [rLocations, setRLocations] = useState({});
    const [seeOffline, setSeeOffline] = useState(false);
    const [operations, setOperations] = useState(null);
    const [interactions, setInteractions] = useState(null);
    const [operationData, setOperationData] = useState({});
    const [interactionData, setInteractionData] = useState({});

    const [queryRobot, setQueryRobot] = useQueryState('robot', '');

    async function loadOperations() {
        await api
            .get(`rc3/operations`)
            .then(response => {
                const { data } = response;
                setOperations(data);
            })
            .catch(error => requestError(error));
    }

    async function loadInteractions() {
        await api
            .get(`rc3/interactions`)
            .then(response => {
                const { data } = response;
                setInteractions(data);
            })
            .catch(error => requestError(error));
    }

    function getOperationLabels({ active, occupied }) {
        // Minutes
        const m_active = Math.floor(active / 60);
        const m_occupied = Math.floor(occupied / 60);

        // Active hours and minutes
        const active_hours = Math.floor(m_active / 60);
        const a_minutes = active_hours > 0 ? m_active % 60 : m_active;
        const active_minutes = numeral(a_minutes).format('00');

        // Occupied hours and minutes
        const occupied_hours = Math.floor(m_occupied / 60);
        const o_minutes = occupied_hours > 0 ? m_occupied % 60 : m_occupied;
        const occupied_minutes = numeral(o_minutes).format('00');

        return {
            active: {
                hours: active_hours,
                minutes: active_minutes,
                text: `${active_hours}:${active_minutes}`,
            },
            occupied: {
                hours: occupied_hours,
                minutes: occupied_minutes,
                text: `${occupied_hours}:${occupied_minutes}`,
            },
            rate: numeral(m_active ? m_occupied / m_active : 0).format('0.00%'),
        };
    }

    function getInteractionLabels(_ints) {
        const n_total = _ints.total;
        let total_label = n_total;
        let scale = 'u';
        if (n_total > 9999 && n_total < 999999) {
            total_label = numeral(n_total / 1000).format('0.0');
            scale = 'k';
        } else if (n_total > 999999) {
            total_label = numeral(n_total / 1000000).format('0.0');
            scale = 'm';
        }

        return {
            total: {
                value: total_label,
                scale,
                label: (
                    <span>
                        {total_label}
                        {scale !== 'u' ? (
                            <LocaleMessage
                                msg={`rc3.dashboard.unit.${scale}`}
                            />
                        ) : null}
                    </span>
                ),
            },
        };
    }

    function getPeriodString(period) {
        return new Date(period).toLocaleDateString(loc.format, label_options);
    }

    function formatOperationData(data) {
        const label_date = getPeriodString(data.period);

        const time_occupied = data.occupied ? data.occupied / (60 * 60) : 0;
        const time_active = data ? (data.active || 0) / (60 * 60) : 0;

        const labels = getOperationLabels(data);
        return {
            date: label_date,
            active: Number(numeral(time_active).format('0.0')) || 0,
            occupied: Number(numeral(time_occupied).format('0.0')) || 0,
            active_label: labels.active.text,
            occupied_label: labels.occupied.text,
        };
    }

    function filterOperations(_ops) {
        if (!_ops) {
            setOperationData({});
            return;
        }
        const op_data = [];
        const labels = [];
        const { all_time, last_months, current_month } = _ops;

        const all_active = all_time.active || 0;

        const op_labels = getOperationLabels({
            active: all_time.active || 0,
            occupied: all_time.occupied || 0,
        });

        Object.keys(last_months).forEach(m => {
            const op = last_months[m];
            const f_data = formatOperationData(op);
            labels.push(f_data.date);
            op_data.push(f_data);
        });

        const f_data = formatOperationData(current_month);
        labels.push(f_data.date);
        op_data.push(f_data);

        const rate =
            all_time && all_time.active ? all_time.occupied / all_active : 0;

        setOperationData({
            info: {
                ...op_labels,
                rate: numeral(rate).format('0.00%'),
            },
            total: {
                active: all_active,
                occupied: all_time.occupied || 0,
            },
            list: op_data,
            labels,
        });
    }

    function filterInteractions(_ints) {
        if (!_ints) {
            setInteractionData({});
            return;
        }
        const int_data = [];
        const labels = [];
        let all_rates = [];
        const month_rates = [];

        const { all_time, last_months, current_month } = _ints;

        // Get the values for all time interactions
        Object.keys(last_months).forEach(m => {
            const i = last_months[m];
            const i_values = i.values;
            const i_date = getPeriodString(i.period);
            labels.push();

            int_data.push({
                date: i_date,
                ...i_values,
            });
        });

        // Add the current month to all time interactions
        const i_date = getPeriodString(current_month.period);
        labels.push(i_date);
        int_data.push({
            date: i_date,
            ...current_month.values,
        });

        // Get the values per interaction type
        Object.keys(all_time).forEach(i => {
            if (i !== 'total') {
                const i_val = all_time[i];
                const i_key = interaction_keys[i];
                all_rates.push({
                    key: i,
                    label: interaction_labels[i_key]
                        ? interaction_labels[i_key]
                        : i_key,
                    value: i_val,
                });
            }
        });

        // Dynamically create colors for interaction types
        const a_n_colors = all_rates.length;
        const a_n_step = (1 - 0.25) / a_n_colors;
        all_rates = all_rates.map((r, i) => {
            const light = a_n_step * i;
            const c =
                colors && colors.primary
                    ? lighten(light, colors.primary)
                    : '#ffffff';
            return {
                ...r,
                color: c,
            };
        });

        // Get the values for current month and sets the all-time-interaction color
        Object.keys(current_month.values).forEach(i => {
            if (i !== 'total') {
                const i_val = current_month.values[i];
                const i_key = interaction_keys[i];
                const all_item = all_rates.find(a => {
                    return a.key === i;
                });
                month_rates.push({
                    label: interaction_labels[i_key]
                        ? interaction_labels[i_key]
                        : i_key,
                    value: i_val,
                    color: all_item ? all_item.color : colors.primary,
                });
            }
        });

        setInteractionData({
            info: {
                all_time: getInteractionLabels(all_time),
            },
            list: int_data,
            rate: {
                all_time: all_rates,
                this_month: month_rates,
            },
            labels,
        });
    }

    function filterRobotLocations() {
        const r_locations = robotLocations
            ? Object.keys(robotLocations).map(l => {
                  const r_loc = robotLocations[l];
                  const l_robots = r_loc.robots || [];

                  const filtered_robots = !seeOffline
                      ? l_robots.filter(r => {
                            return (
                                r.status &&
                                r.status.status !== 'disconnected' &&
                                r.status.status !== 'unknown'
                            );
                        })
                      : l_robots;

                  return {
                      ...r_loc,
                      robots: filtered_robots,
                  };
              })
            : {};
        setRLocations(r_locations);
    }

    function getTime() {
        const datetime = new Date();
        const date = datetime.toLocaleDateString(loc.format, date_opt);
        const time = datetime.toLocaleTimeString([], time_opt);
        setNow({
            date: date.toUpperCase(),
            time,
        });
    }

    useEffect(() => {
        filterOperations(operations);
    }, [operations, loc]);

    useEffect(() => {
        filterInteractions(interactions);
    }, [interactions, loc]);

    useEffect(() => {
        loadOperations();
        loadInteractions();
        getTime();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            loadOperations();
            loadInteractions();
        }, update_time);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getTime();
        }, 1 * 1000);
        return () => clearInterval(interval);
    }, [loc]);

    useEffect(() => {
        const status_count = {
            connected: 0,
            occupied: 0,
            alert: 0,
            disconnected: 0,
        };
        if (robots && robotStatus) {
            robots.forEach(r => {
                const st = robotStatus[r.id];
                if (st) {
                    status_count[st.status] += 1;
                } else {
                    status_count.disconnected += 1;
                }
            });
        }
        setStatus(status_count);
    }, [robots, robotStatus]);

    useEffect(() => {
        filterRobotLocations();
    }, [seeOffline, robotLocations]);

    const op_times =
        operationData && operationData.info ? operationData.info : null;

    const int_times =
        interactionData && interactionData.info ? interactionData.info : null;

    const op_this_month =
        operations && operations.current_month
            ? getOperationLabels(operations.current_month)
            : null;

    const int_this_month =
        interactions && interactions.current_month
            ? getInteractionLabels(interactions.current_month.values)
            : null;

    const handleChangeRobot = (event, newValue) => {
        if (queryRobot !== newValue) {
            setQueryRobot(newValue);
        }
        handleChangeTab(event, 'robots');
    };

    function renderClockCard() {
        return (
            <Box boxShadow={boxShadow} className="mb-3">
                <InfoCard>
                    <InfoArea>
                        <MainInfo>
                            <div className="info">
                                <span className="time">
                                    {now ? now.time : '--:--'}
                                </span>
                                <StatusItem
                                    className="status"
                                    smallScreen={smallScreen}
                                >
                                    <div className="item">
                                        <Badge
                                            className={`status-${userStatus}`}
                                            overlap="rectangular"
                                            badgeContent=" "
                                            variant="dot"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        />
                                        <span className="title">
                                            {now ? now.date : '---'}
                                        </span>
                                    </div>
                                </StatusItem>
                            </div>
                        </MainInfo>
                    </InfoArea>
                </InfoCard>
            </Box>
        );
    }

    function renderRobotStatusCard() {
        return (
            <Box
                boxShadow={boxShadow}
                className="mb-3"
                onClick={event => handleChangeRobot(event, '')}
            >
                <InfoCard>
                    <InfoArea className="clickable">
                        <div className="desc-full info">
                            <div className="info-row">
                                <MainInfo className="col-6">
                                    <div className="title">
                                        <span>
                                            <LocaleMessage msg="rc3.dashboard.your_robots" />
                                        </span>
                                    </div>
                                    <div className="info">
                                        <span
                                            style={{
                                                fontSize: '3.5vh',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {robots ? robots.length : 0}
                                        </span>
                                    </div>
                                </MainInfo>
                                <div
                                    className="col-6"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {status
                                        ? Object.keys(status).map(s => {
                                              const stt = status[s];
                                              return (
                                                  <StatusItem
                                                      className="status"
                                                      key={`robot-status-${s}`}
                                                      smallScreen={smallScreen}
                                                  >
                                                      <div className="item">
                                                          <Badge
                                                              className={`status-${s}`}
                                                              overlap="rectangular"
                                                              badgeContent=" "
                                                              variant="dot"
                                                              anchorOrigin={{
                                                                  vertical:
                                                                      'bottom',
                                                                  horizontal:
                                                                      'left',
                                                              }}
                                                          />
                                                          <span className="title">
                                                              <LocaleMessage
                                                                  msg={`rc3.robot.status.${s}`}
                                                              />
                                                          </span>
                                                      </div>
                                                      <span>{stt}</span>
                                                  </StatusItem>
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </InfoArea>
                </InfoCard>
            </Box>
        );
    }

    function renderRobotTable() {
        return (
            <Box boxShadow={boxShadow}>
                <TableCard>
                    <div className="card-settings">
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    size="small"
                                    checked={seeOffline}
                                    onChange={event =>
                                        setSeeOffline(event.target.checked)
                                    }
                                />
                            }
                            label={
                                <LocaleMessage msg="rc3.dashboard.include_offline" />
                            }
                        />
                    </div>
                    <TableArea>
                        <TableContainer style={{ height: '100%' }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="60%" align="left">
                                            <LocaleMessage msg="rc3.dashboard.location" />
                                        </TableCell>
                                        <TableCell width="40%" align="right">
                                            <LocaleMessage msg="rc3.dashboard.robots" />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rLocations
                                        ? Object.keys(rLocations).map(l => {
                                              const r_l = rLocations[l];
                                              return (
                                                  <CustomTableRow
                                                      onClick={(event, r) => {
                                                          handleChangeRobot(
                                                              event,
                                                              r
                                                          );
                                                      }}
                                                      key={r_l.id}
                                                      row={r_l}
                                                  />
                                              );
                                          })
                                        : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableArea>
                </TableCard>
            </Box>
        );
    }

    function areaChart(data, labels, areas, tooltip) {
        return (
            <AreaChart
                data={data}
                labels={labels}
                margin={{
                    top: box_margin_h,
                    bottom: box_margin_h,
                    right: box_margin_w,
                    left: -2 * box_margin_w,
                }}
            >
                <CartesianGrid stroke="#464A4F" vertical={false} />
                <XAxis dataKey="date" />
                <YAxis type="number" allowDataOverflow allowDecimals={false} />
                {tooltip ? <Tooltip content={tooltip} /> : null}
                {areas.map(a => {
                    return (
                        <Area
                            key={a.key}
                            dataKey={a.key}
                            stroke={a.color}
                            fill={a.fill}
                            unit={a.unit}
                        />
                    );
                })}
            </AreaChart>
        );
    }

    function barChart(data, labels, bars, tooltip) {
        return (
            <BarChart
                data={data}
                labels={labels}
                margin={{
                    top: box_margin_h,
                    bottom: box_margin_h,
                    right: box_margin_w,
                    left: -2 * box_margin_w,
                }}
            >
                <CartesianGrid stroke="#464A4F" vertical={false} />
                <XAxis dataKey="date" axisLine={false} />
                <YAxis type="number" allowDataOverflow allowDecimals={false} />
                {tooltip ? <Tooltip content={tooltip} /> : null}
                {bars.map(b => {
                    return (
                        <Bar
                            key={b.key}
                            dataKey={b.key}
                            stroke={b.color}
                            // dot={{
                            //     stroke: b.color,
                            //     strokeWidth: 2,
                            // }}
                            fill={b.fill}
                            unit={b.unit}
                        />
                    );
                })}
            </BarChart>
        );
    }

    function pieChart(data, color, key) {
        const pie_size = parseInt(w_height * 0.035, 10);
        return (
            <PieChart>
                <Pie
                    dataKey="value"
                    nameKey="label"
                    data={data || []}
                    innerRadius={pie_size}
                    outerRadius={2 * pie_size}
                    paddingAngle={2}
                    fill={color}
                    stroke="#333333"
                >
                    {data
                        ? data.map(entry => (
                              <Cell
                                  key={`${key}_${entry.label}`}
                                  fill={entry.color ? entry.color : '#ffffff'}
                              />
                          ))
                        : null}
                </Pie>
                <Tooltip />
            </PieChart>
        );
    }

    function renderInteractionPieChart(data, key) {
        const box_size = parseInt(w_height * 0.15, 10);

        if (data) {
            return (
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-1 mt-3"
                    width="100%"
                    height={box_size}
                >
                    {pieChart(data, key, <Tooltip />)}
                </ResponsiveContainer>
            );
        }
        return null;
    }

    function renderTimeChart(title, data, labels, tooltip, mode, views = []) {
        let chart = null;
        switch (mode) {
            case 'area':
                chart = areaChart(data, labels, views, tooltip);
                break;
            case 'bar':
                chart = barChart(data, labels, views, tooltip);
                break;
            default:
                return null;
        }
        return (
            <>
                <div className="card-title">{title}</div>
                <div className="area-chart-container">
                    <ResponsiveContainer
                        className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                        width="100%"
                        height={box_height}
                    >
                        {chart}
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    function renderInfoRows(row) {
        const { infos } = row;
        return (
            <div
                className="desc-full info"
                key={row.key}
                style={{ padding: '1.5vh 0.5vw' }}
            >
                {row.title ? (
                    <span className="jr-fs-md title">{row.title}</span>
                ) : null}
                <div className="info-row">
                    {infos.map(c => {
                        return (
                            <div
                                className="jr-revenu-col info"
                                key={`${row.key}_${c.key}`}
                            >
                                {c.title ? (
                                    <span className="jr-fs-sm title">
                                        {c.title}
                                    </span>
                                ) : null}
                                {c.value ? (
                                    <span className="big-value">{c.value}</span>
                                ) : null}
                                {c.label ? (
                                    <span className="jr-fs-sm title">
                                        {c.label}
                                    </span>
                                ) : null}
                                {c.extra ? c.extra : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    function renderOperationChartCard() {
        const data =
            operationData && operationData.list ? operationData.list : [];
        const labels = operationData ? operationData.labels : [];
        const tooltip = <CustomOperationTooltip />;
        const areas = [
            {
                key: 'active',
                color: colors.primary,
                dot: lighten(0.1, colors.primary),
                fill: `${hexToRgba(lighten(0.1, colors.primary), 0.85)}`,
                unit: 'h',
            },
            {
                key: 'occupied',
                color: colors.secondary,
                dot: lighten(0.1, colors.secondary),
                fill: `${hexToRgba(lighten(0.1, colors.secondary), 0.85)}`,
                unit: 'h',
            },
        ];

        const active_total = op_times && op_times.active ? op_times.active : {};
        const active_month =
            op_this_month && op_this_month.active ? op_this_month.active : {};

        const occupied_total =
            op_times && op_times.occupied ? op_times.occupied : {};
        const occupied_month =
            op_this_month && op_this_month.occupied
                ? op_this_month.occupied
                : {};

        const active_time = {
            total: `${active_total.hours || 0} : ${active_total.minutes || 0}`,
            month: `${active_month.hours || 0} : ${active_month.minutes || 0}`,
        };

        const occupied_time = {
            total: `${occupied_total.hours || 0} : ${occupied_total.minutes ||
                0}`,
            month: `${occupied_month.hours || 0} : ${occupied_month.minutes ||
                0}`,
        };

        const rows = [
            {
                key: 'title',
                infos: [
                    {
                        key: 'total',
                        title: <LocaleMessage msg="rc3.dashboard.all_time" />,
                    },
                    {
                        key: 'month',
                        title: <LocaleMessage msg="rc3.dashboard.this_month" />,
                    },
                ],
            },
            {
                key: 'operation_active',
                title: <LocaleMessage msg="rc3.dashboard.active_time" />,
                infos: [
                    {
                        key: 'total',
                        value: active_time.total,
                        label: <LocaleMessage msg="label.time.hours_minutes" />,
                    },
                    {
                        key: 'month',
                        value: active_time.month,
                        label: <LocaleMessage msg="label.time.hours_minutes" />,
                    },
                ],
            },
            {
                key: 'operation_occupied',
                title: <LocaleMessage msg="rc3.dashboard.interaction_time" />,
                infos: [
                    {
                        key: 'total',
                        value: occupied_time.total,
                        label: <LocaleMessage msg="label.time.hours_minutes" />,
                    },
                    {
                        key: 'month',
                        value: occupied_time.month,
                        label: <LocaleMessage msg="label.time.hours_minutes" />,
                    },
                ],
            },
            {
                key: 'operation_rate',
                title: <LocaleMessage msg="rc3.dashboard.occupation_rate" />,
                infos: [
                    {
                        key: 'total',
                        value: op_times && op_times.rate ? op_times.rate : '0%',
                    },
                    {
                        key: 'month',
                        value:
                            op_this_month && op_this_month.rate
                                ? op_this_month.rate
                                : '0%',
                    },
                ],
            },
        ];

        return (
            <Box boxShadow={boxShadow} className="mb-3">
                <TimeChart
                    className="jr-overview-row"
                    smallScreen={smallScreen}
                >
                    <div className="col-md-8 col-12 chart-area">
                        {renderTimeChart(
                            <LocaleMessage msg="rc3.dashboard.operation_time" />,
                            data,
                            labels,
                            tooltip,
                            'area',
                            areas
                        )}
                    </div>
                    <InfoArea className="jr-description col-md-4 col-12">
                        {rows.map(r => {
                            return renderInfoRows(r);
                        })}
                    </InfoArea>
                </TimeChart>
            </Box>
        );
    }

    function renderInteractionsChartCard() {
        const data =
            interactionData && interactionData.list ? interactionData.list : [];
        const labels = interactionData ? interactionData.labels : [];
        const tooltip = <CustomInteractionTooltip />;
        const bars = [
            {
                key: 'total',
                color: colors.primary,
                dot: lighten(0.1, colors.primary),
                fill: `${lighten(0.1, colors.primary)}`,
            },
        ];

        const ints_total =
            int_times && int_times.all_time && int_times.all_time.total
                ? int_times.all_time.total
                : {};

        const ints_month =
            int_this_month && int_this_month.total ? int_this_month.total : {};

        const interaction_rates =
            interactionData && interactionData.rate ? interactionData.rate : {};

        const rows = [
            {
                key: 'interactions',
                infos: [
                    {
                        key: 'total',
                        value: ints_total.label || '0',
                        title: <LocaleMessage msg="rc3.dashboard.all_time" />,
                        extra: renderInteractionPieChart(
                            interaction_rates.all_time,
                            'all_time'
                        ),
                    },
                    {
                        key: 'month',
                        value: ints_month.label || '0',
                        title: <LocaleMessage msg="rc3.dashboard.this_month" />,
                        extra: renderInteractionPieChart(
                            interaction_rates.this_month,
                            'month'
                        ),
                    },
                ],
            },
        ];

        return (
            <Box boxShadow={boxShadow} className={smallScreen ? 'mb-3' : ''}>
                <TimeChart
                    className="jr-overview-row"
                    style={{ height: '100%' }}
                    smallScreen={smallScreen}
                >
                    <div className="col-md-8 col-12 chart-area">
                        {renderTimeChart(
                            <LocaleMessage msg="rc3.dashboard.robot_interactions" />,
                            data,
                            labels,
                            tooltip,
                            'bar',
                            bars
                        )}
                    </div>
                    <InfoArea className="jr-description col-md-4 col-12">
                        {rows.map(r => {
                            return renderInfoRows(r);
                        })}
                    </InfoArea>
                </TimeChart>
            </Box>
        );
    }

    return (
        <CentralPanel>
            <Container smallScreen={smallScreen}>
                <div
                    className="row col-12"
                    style={{ width: '100%', alignItems: 'center' }}
                >
                    <div
                        className="col-lg-3 col-12 fullsize"
                        style={{
                            paddingLeft: '20px',
                        }}
                    >
                        {renderClockCard()}
                        {renderRobotStatusCard()}
                        {!smallScreen ? renderRobotTable() : null}
                    </div>

                    <div className="col-lg-9 col-12 fullsize">
                        {renderOperationChartCard()}
                        {renderInteractionsChartCard()}
                    </div>
                </div>
            </Container>
        </CentralPanel>
    );
}

Dashboard.propTypes = {
    smallScreen: PropTypes.bool,
    userStatus: PropTypes.string,
    settings: PropTypes.object,
    robotLocations: PropTypes.object,
    robots: PropTypes.array,
    robotStatus: PropTypes.object,
    requestError: PropTypes.func,
    handleChangeTab: PropTypes.func,
};

Dashboard.defaultProps = {
    smallScreen: false,
    userStatus: 'disconnected',
    settings: {},
    robotLocations: {},
    robots: [],
    robotStatus: {},
    requestError: () => {},
    handleChangeTab: () => {},
};
