/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';

import { RowArea, InfoContainer } from '../../../style';

const step_types = {
    start: <LocaleMessage msg="page.survey_steps.type.start" />,
    step: <LocaleMessage msg="page.survey_steps.type.input" />,
    end: <LocaleMessage msg="page.survey_steps.type.end" />,
};

export default function ScriptDetailView({
    script,
    data,
    settings,
    requestError,
}) {
    const { format } = settings;
    const [isLoading, setIsLoading] = useState(true);
    const [stepKeys, setStepKeys] = useState({});
    const [stepList, setStepList] = useState([]);
    const [logCorrelation, setLogCorrelation] = useState([]);
    const [sessionSubTables, setSessionSubTables] = useState({});

    async function loadSteps(script_id) {
        setIsLoading(true);
        const steps_obj = {};
        await api
            .get(`scripts/${script_id}/steps`)
            .then(response => {
                const list = response.data;
                const steps = list.map(s => {
                    let order = 0;
                    switch (s.type) {
                        case 'start':
                            order = 0;
                            break;
                        case 'step':
                            order = 1;
                            break;
                        case 'end':
                        default:
                            order = 10;
                    }

                    const exit_conditions = s.conditions
                        ? s.conditions.length
                        : 0;

                    steps_obj[s.id] = s;

                    return {
                        ...s,
                        type_label: step_types[s.type],
                        order,
                        exit_conditions,
                    };
                });
                setStepKeys(steps_obj);
                setStepList(steps);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function correlateLogs() {
        const step_values = {};
        data.forEach(s_r => {
            const path = s_r.path || [];

            path.forEach((step, i) => {
                const { step_id } = step;
                if (!step_values[step_id]) {
                    step_values[step_id] = {
                        counter: 0,
                        logs: [],
                    };
                }
                const log_start = GetDateTimeLabel(new Date(step.start), {
                    format,
                });

                step_values[step_id].counter += 1;
                step_values[step_id].logs = [
                    ...step_values[step_id].logs,
                    {
                        id: `log_${step_id}_${i}`,
                        created_at: log_start.string,
                        created_timestamp: log_start.start_timestamp,
                    },
                ];
            });
        });

        const step_list_info = stepList.map(s => {
            const s_info = step_values[s.id] || {};
            return {
                ...s,
                counter: s_info.counter || 0,
                logs: s_info.logs || [],
            };
        });

        setLogCorrelation(step_list_info);
    }

    useEffect(() => {
        correlateLogs();
    }, [stepList, data]);

    useEffect(() => {
        if (script.id) {
            loadSteps(script.id);
        } else {
            setStepList([]);
        }
    }, [script]);

    const header_steps = [
        {
            id: 'description',
            label: <LocaleMessage msg="table.headers.step" />,
        },
        {
            id: 'counter',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    const header_sessions = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'language_label',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'was_finished',
            order_by: 'finished',
            label: <LocaleMessage msg="table.headers.was_finished" />,
        },
    ];

    const headers_sub_session = [
        {
            id: 'step_name',
            label: <LocaleMessage msg="table.headers.step" />,
        },
    ];

    function onSessionRowClick(event, _id) {
        const session = data.find(i => {
            return i.id === _id;
        });

        const path = session && session.path ? session.path : [];

        const path_idx = path.map((s, i) => {
            return {
                index: i + 1,
                id: i + 1,
                ...s,
                step_name: stepKeys[s.step_id]
                    ? stepKeys[s.step_id].description
                    : '',
            };
        });

        const sub = sessionSubTables[_id]
            ? false
            : {
                  id: _id,
                  title: (
                      <LocaleMessage msg="page.reports.actions.script_runs.label.indicators.session_path" />
                  ),
                  headers: headers_sub_session,
                  rows: path_idx,
              };

        setSessionSubTables({
            [_id]: sub,
        });
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <InfoContainer className="row mt-5 mb-3">
                <div className="row col-12 mb-3 table-responsive-material">
                    <RowArea className="col-md-5 col-12 mb-3">
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.actions.script_runs.label.indicators.per_step" />
                        </p>
                        <DataTable
                            headerColumns={header_steps}
                            data={logCorrelation || []}
                            orderDirection="asc"
                            orderColumn="order"
                            hasActions={false}
                            sortable={false}
                            defaultRowsPerPage={stepList.length}
                            hasFooter={false}
                            headerDirection="row"
                        />
                    </RowArea>
                    <RowArea className="col-md-7 col-12 mb-3">
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.actions.script_runs.label.indicators.per_session" />
                        </p>
                        <DataTable
                            headerColumns={header_sessions}
                            data={data || []}
                            orderDirection="desc"
                            orderColumn="start_timestamp"
                            handleTableRowClick={(event, _id) =>
                                onSessionRowClick(event, _id)
                            }
                            subTables={sessionSubTables}
                            hasActions={false}
                            sortable
                            defaultRowsPerPage={25}
                            hasFooter
                            headerDirection="row"
                        />
                    </RowArea>
                </div>
            </InfoContainer>
            <Divider />
        </>
    );
}

ScriptDetailView.defaultProps = {
    data: [],
    active: {},
};

ScriptDetailView.propTypes = {
    data: PropTypes.array,
    from: PropTypes.string.isRequired,
    until: PropTypes.string.isRequired,
    script: PropTypes.object.isRequired,
    active: PropTypes.object,
    settings: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    timeTooltip: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
};
