/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDoneAll, MdClose, MdSpeakerNotes } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function NeurobotSettings() {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [serviceInfo, setServiceInfo] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadServiceInfo() {
        await api
            .get(`neurobot/server`)
            .then(response => {
                const { data } = response;
                setServiceInfo(data);
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    useEffect(() => {
        loadServiceInfo();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            loadServiceInfo();
        }, 10 * 1000);
        return () => clearInterval(interval);
    }, []);

    function renderInfo({ key, label, value, width }) {
        return (
            <div className={`mb-3 ${width || 'col-12'}`} key={key}>
                <Typography variant="caption" display="block" gutterBottom>
                    {label}
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                    {value}
                </Typography>
            </div>
        );
    }

    const infos = [
        {
            key: 'server',
            label: (
                <LocaleMessage msg="page.neurobot_settings.server.address" />
            ),
            value: serviceInfo.server ? serviceInfo.server : '',
            width: 'col-6',
        },
        {
            key: 'online',
            label: <LocaleMessage msg="page.neurobot_settings.server.online" />,
            width: 'col-3',
            value: serviceInfo.online ? (
                <MdDoneAll size={24} />
            ) : (
                <MdClose size={24} />
            ),
        },
        {
            key: 'auth',
            label: <LocaleMessage msg="page.neurobot_settings.server.auth" />,
            width: 'col-3',
            value: serviceInfo.auth ? (
                <MdDoneAll size={24} />
            ) : (
                <MdClose size={24} />
            ),
        },
    ];

    return (
        <PageContent
            title="Neurobot Settings"
            breadcrumbs={[]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="row full-body">
                    {serviceInfo ? (
                        <>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.neurobot_settings.title" />
                                    }
                                    Icon={MdSpeakerNotes}
                                    hide
                                >
                                    <div
                                        className="row"
                                        style={{ padding: '15px' }}
                                    >
                                        {infos.map(i => {
                                            return renderInfo(i);
                                        })}
                                    </div>
                                </CardSideBordered>
                            </div>
                            <div className="col-md-6 col-12 mb-5" />
                        </>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}
