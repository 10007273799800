/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdLaunch } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Button, Chip, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';

import Constants from './QnAConstants';
import Defaults from './QnADefaults';
import Intents from './QnAIntents';
import Subjects from './QnASubjects';
import Topics from './QnATopics';
import Versions from './QnAVersions';
import { VersionArea } from './styles';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        options: {
            border: `1px solid ${theme.palette.divider}`,
            textAlign: 'center',
            height: 'auto',
            padding: '0px',
        },
        tabs: {},
    };
});

const internal_pages = [
    {
        value: 'constants',
        label_code: 'page.dialogs.info.constants',
    },
    {
        value: 'defaults',
        label_code: 'page.dialogs.info.defaults',
    },
    {
        value: 'intents',
        label_code: 'page.dialogs.info.intents',
    },
    {
        value: 'subjects',
        label_code: 'page.dialogs.info.subjects',
    },
    {
        value: 'topics',
        label_code: 'page.dialogs.info.topics',
    },
];

export default function ContentVersions({
    dialogSettings,
    reloadSettings,
    handleError,
}) {
    const { id, settings: d_settings } = dialogSettings;

    const dialog_versions =
        d_settings && d_settings.versions ? d_settings.versions : {};

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const classes = useStyles();

    const [queryTab, setQueryTab] = useQueryState('content', '');
    const [currTab, setCurrTab] = useState(queryTab);

    const [isLoading, setIsLoading] = useState(true);
    const [versions, setVersions] = useState([]);
    const [currVersion, setCurrVersion] = useState(null);
    const [allowedLanguages, setAllowedLanguages] = useState([]);

    const handleChangeTab = (event, newValue) => {
        setCurrTab(newValue);
        setQueryTab(newValue);
        return true;
    };

    function handleGoToTree() {
        const working_version =
            dialog_versions && dialog_versions.working
                ? dialog_versions.working
                : '';
        const url = `/tools/qna/${id}/${working_version}/tree`;
        return window.open(url, '_blank');
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const list = response.data;
                const langs = [];
                list.forEach(l => {
                    langs.push(l.value);
                });
                setAllowedLanguages(langs);
            })
            .catch(e => {
                handleError(e);
            });
    }

    async function loadVersionDetail(v_id) {
        setIsLoading(true);
        if (v_id) {
            await api
                .get(`dialogs/${id}/versions/${v_id}`)
                .then(response => {
                    const version = response.data;
                    setCurrVersion(version);
                })
                .catch(error => handleError(error));
        } else {
            setCurrVersion({});
        }
        setIsLoading(false);
    }

    async function loadVersions() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/versions`)
            .then(response => {
                const list = response.data;
                const v_list = list.map(v => {
                    const created = new Date(v.created);
                    const updated = new Date(v.updated);
                    const created_dt = GetDateTimeLabel(new Date(v.created), {
                        format: date_loc.code,
                    });

                    const release_tag =
                        dialog_versions.release === v.id ? (
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                label={
                                    <LocaleMessage msg="page.qna.versions.release.short" />
                                }
                                style={{ marginRight: '10px' }}
                            />
                        ) : null;
                    const working_tag =
                        dialog_versions.working === v.id ? (
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                label={
                                    <LocaleMessage msg="page.qna.versions.working.short" />
                                }
                                style={{ marginRight: '10px' }}
                            />
                        ) : null;

                    const label = (
                        <span>
                            {release_tag}
                            {working_tag}
                            {v.name}
                        </span>
                    );

                    return {
                        ...v,
                        label,
                        created: created_dt.string,
                        created_timestamp: created.toISOString(),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setVersions(v_list);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function updateDialog(updates) {
        setIsLoading(true);
        const data = {
            name: dialogSettings.name,
            description: dialogSettings.description,
            settings: {
                ...dialogSettings.settings,
                ...updates,
            },
        };
        await api
            .put(`dialogs/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.dialogs.form.update_success" />
                );
            })
            .catch(error => handleError(error));

        reloadSettings();
        loadVersions();
        setIsLoading(false);
    }

    async function updateVersionSettings(updates) {
        setIsLoading(true);
        const data = {
            name: currVersion.name,
            description: currVersion.description,
            values: {
                ...currVersion.values,
                ...updates,
            },
        };
        await api
            .put(`dialogs/${id}/versions/${currVersion.id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.dialogs.form.update_success" />
                );
            })
            .catch(error => handleError(error));
        loadVersionDetail(currVersion.id);
        loadVersions();
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
    }, []);

    useEffect(() => {
        loadVersions();
    }, [dialogSettings]);

    useEffect(() => {
        const working_version =
            dialog_versions && dialog_versions.working
                ? dialog_versions.working
                : '';
        if (working_version) {
            loadVersionDetail(working_version);
        } else {
            setCurrVersion(null);
        }
    }, [dialog_versions]);

    const page_components = {
        versions: Versions,
        constants: Constants,
        defaults: Defaults,
        intents: Intents,
        subjects: Subjects,
        topics: Topics,
    };

    function renderInternalPage(page) {
        const Page = page ? page_components[page] : Versions;
        return (
            <Page
                locale={date_loc}
                versions={versions}
                dialogSettings={dialogSettings}
                answerLanguages={allowedLanguages}
                workingVersion={
                    currVersion && currVersion.id ? currVersion.id : ''
                }
                versionSettings={
                    currVersion && currVersion.values ? currVersion.values : {}
                }
                reloadVersions={() => {
                    reloadSettings();
                    loadVersions();
                }}
                updateDialog={(k, v) => updateDialog({ [k]: v })}
                updateVersionSettings={(k, v) =>
                    updateVersionSettings({ [k]: v })
                }
                handleError={error => handleError(error)}
            />
        );
    }

    function renderVersionChips() {
        const { release } = dialog_versions;
        const { working } = dialog_versions;

        const r_version = versions.find(v => {
            return v.id === release;
        });

        const w_version = versions.find(v => {
            return v.id === working;
        });

        return (
            <VersionArea
                onClick={e => handleChangeTab(e, '')}
                selected={currTab === ''}
            >
                <div className="version">
                    <span className="mb-1">
                        <LocaleMessage msg="page.qna.versions.release.title" />:
                    </span>
                    <Chip
                        variant="outlined"
                        color="primary"
                        size="medium"
                        label={r_version ? r_version.name : '---'}
                    />
                </div>
                <Divider />
                <div className="version">
                    <span className="mb-1">
                        <LocaleMessage msg="page.qna.versions.working.title" />:
                    </span>
                    <Chip
                        variant="outlined"
                        color="primary"
                        size="medium"
                        label={w_version ? w_version.name : '---'}
                    />
                </div>
            </VersionArea>
        );
    }

    return (
        <div className="col-12 mt-3 mb-3 row">
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <div className={`${classes.options} col-md-2 col-12`}>
                        {renderVersionChips()}
                        <Divider />
                        {currVersion && currVersion.id ? (
                            <>
                                <Tabs
                                    indicatorColor="primary"
                                    orientation="vertical"
                                    value={currTab || false}
                                    onChange={handleChangeTab}
                                    className={classes.tabs}
                                    centered
                                >
                                    {internal_pages.map(p => {
                                        return (
                                            <Tab
                                                key={p.value}
                                                label={
                                                    <LocaleMessage
                                                        msg={p.label_code}
                                                    />
                                                }
                                                value={p.value}
                                                icon={
                                                    p.icon ? <p.icon /> : null
                                                }
                                            />
                                        );
                                    })}
                                </Tabs>
                                <Divider />
                                <Button
                                    className="p-3"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        handleGoToTree();
                                    }}
                                    fullWidth
                                >
                                    <LocaleMessage msg="page.dialogs.info.nodes" />
                                    <MdLaunch size={20} className="ml-1" />
                                </Button>
                            </>
                        ) : null}
                    </div>
                    <div className="col-md-10 col-12 row" style={{ flex: 1 }}>
                        {renderInternalPage(currTab)}
                    </div>
                </>
            )}
        </div>
    );
}

ContentVersions.propTypes = {
    reloadSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
