/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';

import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

import { MediaArea, ParameterArea } from '../styles';

export default function AudioContent({
    group,
    content,
    updateContent,
    handleError,
}) {
    const defAudioGalleries = [];
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [galleryList, setGalleryList] = useState([]);
    const [currGallery, setCurrGallery] = useState({});
    const [audioDialogOpen, setAudioDialogOpen] = useState(false);

    async function loadFiles(gallery) {
        if (gallery && gallery.id) {
            setIsLoading(true);
            await api
                .get(`albums/${gallery.id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=audios`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group && (g.group.id === '*' || g.group.id === group)
                    );
                });
                setGalleryList(filtered);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadGalleryList();
    }, [group]);

    useEffect(() => {
        loadFiles(currGallery);
    }, [currGallery]);

    async function updateSettings(key, data) {
        updateContent({
            ...content,
            [key]: data,
        });
    }

    async function onAudioUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        if (currGallery) {
            await api
                .post(`albums/${currGallery.id}/files`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="message.media_uploaded" />
                    );
                    loadFiles(currGallery);
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
    }

    function handleGalleryClick(event, key, index) {
        const file = fileList[index];

        switch (key) {
            case 'audio': {
                updateSettings('audio', {
                    name: file.name,
                    file_id: file.id,
                    url: file.url,
                    path: file.path,
                });
                setAudioDialogOpen(false);
                break;
            }
            default:
                break;
        }
    }

    function handleGalleryChange(event) {
        const _id = event.target.value;
        let galleryObj = defAudioGalleries.find(g => {
            return g.id === _id;
        });
        if (!galleryObj) {
            galleryObj = galleryList.find(g => {
                return g.id === _id;
            });
        }
        setCurrGallery(galleryObj);
    }

    function handleDialogClose() {
        setAudioDialogOpen(false);
    }

    function renderAudioGallery() {
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.gallery" />
                    </InputLabel>
                    <Select
                        id="gallery"
                        value={currGallery ? currGallery.id : ''}
                        onChange={event => handleGalleryChange(event, 'audio')}
                    >
                        {defAudioGalleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label={
                                        <LocaleMessage msg="message.open_all.short" />
                                    }
                                    style={{ marginRight: '10px' }}
                                />
                                {g.name}
                            </MenuItem>
                        ))}
                        {galleryList.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={40}
                            width={40}
                        />
                    </div>
                ) : (
                    <>
                        {currGallery ? (
                            <div
                                className="sidecard-body"
                                style={{ height: '45vh' }}
                            >
                                <GalleryView
                                    media={fileList}
                                    allowNew={currGallery.editable}
                                    multiple
                                    type="audios"
                                    fileUpload={e => onAudioUpload(e)}
                                    onClick={(e, _id) =>
                                        handleGalleryClick(e, 'audio', _id)
                                    }
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </>
        );
    }

    function renderAudioDialog() {
        return (
            <SimpleDialog
                open={audioDialogOpen}
                onClose={() => handleDialogClose('audio')}
                title={
                    <LocaleMessage msg="page.applications.conversation.condition.media" />
                }
                content={renderAudioGallery()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setAudioDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderAudioSettings() {
        const audio = content.audio ? content.audio : null;
        return (
            <MediaArea className="col-md-10 col-12">
                {audio ? (
                    <>
                        {audio.name ? (
                            <Typography variant="button" className="mb-3">
                                {audio.name}
                            </Typography>
                        ) : null}
                        {audio.url ? (
                            <Card className="audio-card">
                                <ReactAudioPlayer
                                    src={audio.url}
                                    controls
                                    title={audio.name}
                                />
                            </Card>
                        ) : null}
                    </>
                ) : null}
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => setAudioDialogOpen(true)}
                >
                    <LocaleMessage msg="page.dances.form.audio.choose" />
                </Button>
            </MediaArea>
        );
    }

    return (
        <ParameterArea
            className="col-12 row pt-3 pb-3 mb-3"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                <LocaleMessage msg="page.dances.form.audio" />
            </strong>
            {audioDialogOpen ? renderAudioDialog() : null}
            {renderAudioSettings()}
        </ParameterArea>
    );
}

AudioContent.defaultProps = {
    content: {},
    group: '*',
};

AudioContent.propTypes = {
    group: PropTypes.string,
    content: PropTypes.object,
    updateContent: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
};
