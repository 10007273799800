/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdCached } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

export default function Subjects(props) {
    const { dialogSettings, handleError } = props;
    const { id } = dialogSettings;

    const [subjectList, setSubjectList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function loadSubjects(refresh = false) {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/content?subjects=true&refresh=${refresh}`)
            .then(response => {
                const { subjects } = response.data;
                const list = subjects.map((s, idx) => {
                    const examples = s.examples.map(e => {
                        return `${e}, `;
                    });

                    return {
                        id: idx,
                        ...s,
                        examples,
                    };
                });
                setSubjectList(list);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadSubjects();
    }, []);

    function buildListView() {
        const headCells = [
            {
                id: 'value',
                label: <LocaleMessage msg="page.dialogs.info.subjects" />,
                width: '30%',
            },
            {
                id: 'examples',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={subjectList}
                    orderDirection="asc"
                    orderColumn="value"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdCached />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => loadSubjects(true)}
                        >
                            <LocaleMessage msg="page.dialogs.info.logs.label.refresh" />
                        </Button>
                    }
                />
            </div>
        );
    }

    return (
        <div className="col-12 mt-3 mb-3">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.dialogs.info.subject_list" />
                </h2>
            </div>
            <div className="sidecard-body">
                {isLoading ? <Splash /> : <>{buildListView()}</>}
            </div>
        </div>
    );
}

Subjects.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
