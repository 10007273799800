import styled from 'styled-components';

export const Item = styled.li`
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        i {
            font-size: 22px;
        }

        span {
            font-size: 12px;
        }
    }

    :hover {
        cursor: pointer;
        color: ${props => props.theme.palette.primary.main} !important;
        border-radius: 5px;
        border: 1px solid ${props => props.theme.palette.primary.main};
    }
`;
