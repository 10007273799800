/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import lng_labels from '~/util/LangMessages';

import { RowArea, InfoContainer } from '../../../style';

const step_types = {
    start: <LocaleMessage msg="page.survey_steps.type.start" />,
    question: <LocaleMessage msg="page.survey_steps.type.input" />,
    input: <LocaleMessage msg="page.survey_steps.type.input" />,
    display: <LocaleMessage msg="page.survey_steps.type.display" />,
    integration: <LocaleMessage msg="page.survey_steps.type.integration" />,
    end: <LocaleMessage msg="page.survey_steps.type.end" />,
};

export default function SurveyDetailView({
    survey,
    data,
    settings,
    requestError,
    from,
    until,
}) {
    const { format } = settings;
    const lang = format && format.code ? format.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const [isLoading, setIsLoading] = useState(true);
    const [stepList, setStepList] = useState([]);
    const [stepLogs, setStepLogs] = useState([]);
    const [logCorrelation, setLogCorrelation] = useState([]);
    const [sessionSubTables, setSessionSubTables] = useState({});
    const [stepSubTables, setStepSubTables] = useState({});

    async function loadSteps(survey_id) {
        setIsLoading(true);
        await api
            .get(`survey/${survey_id}/steps`)
            .then(response => {
                const list = response.data;
                const steps = list.map(s => {
                    let order = 0;
                    switch (s.type) {
                        case 'start':
                            order = 0;
                            break;
                        case 'question':
                        case 'input':
                            order = 1;
                            break;
                        case 'display':
                            order = 2;
                            break;
                        case 'end':
                        default:
                            order = 10;
                    }

                    const exit_conditions = s.conditions
                        ? s.conditions.length
                        : 0;

                    return {
                        ...s,
                        type_label: step_types[s.type],
                        order,
                        exit_conditions,
                    };
                });
                setStepList(steps);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadLogs(survey_id) {
        setIsLoading(true);
        await api
            .get(
                `reports/survey_logs?survey_id=${survey_id}from=${from}&until=${until}`
            )
            .then(response => {
                const logs = response.data.survey_logs;
                const list = logs && logs.list ? logs.list : [];

                const log_values = list.map(l => {
                    const val = l.value || {};
                    const op_label = `label.survey.operations.${l.operation}`;

                    const created_at = GetDateTimeLabel(
                        new Date(l.created_at),
                        {
                            format,
                        }
                    );

                    const answer_value =
                        val.type !== 'secret' ? val.answer : '********';

                    return {
                        ...l,
                        operation_str: lng_all[op_label],
                        step_id: l.step ? l.step.id : null,
                        step_name: l.step ? l.step.description : null,
                        to_step_id: l.to ? l.to.id : null,
                        to_step_name: l.to ? l.to.description : null,
                        answer_type: val.type,
                        answer_value,
                        start: created_at.string,
                        start_timestamp: created_at.timestamp,
                        result:
                            l.operation === 'submit'
                                ? answer_value
                                : l.operation_str,
                    };
                });
                setStepLogs(log_values);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function correlateLogs() {
        const step_values = {};
        stepLogs.forEach(l => {
            const { step_id } = l;
            if (!step_values[step_id]) {
                step_values[step_id] = {
                    counter: 0,
                    answers: [],
                };
            }
            step_values[step_id].counter += 1;
            step_values[step_id].answers = [
                ...step_values[step_id].answers,
                {
                    id: `answer_${l.id}`,
                    operation: l.operation,
                    operation_str: l.operation_str,
                    to: l.to_step_name,
                    created_at: l.start,
                    created_timestamp: l.start_timestamp,
                    result: l.result,
                },
            ];
        });

        const step_list_info = stepList.map(s => {
            const s_info = step_values[s.id] || {};
            return {
                ...s,
                counter: s_info.counter || 0,
                answers: s_info.answers || [],
            };
        });

        setLogCorrelation(step_list_info);
    }

    useEffect(() => {
        correlateLogs();
    }, [stepList, stepLogs]);

    useEffect(() => {
        if (survey.id) {
            loadSteps(survey.id);
            loadLogs(survey.id);
        } else {
            setStepList([]);
            setStepLogs([]);
        }
    }, [survey]);

    const header_steps = [
        {
            id: 'type_label',
            label: <LocaleMessage msg="table.headers.type" />,
        },
        {
            id: 'description',
            label: <LocaleMessage msg="table.headers.description" />,
        },
        {
            id: 'counter',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    const header_sessions = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'language_label',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'was_finished',
            order_by: 'finished',
            label: <LocaleMessage msg="table.headers.was_finished" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
    ];

    const headers_sub_session = [
        {
            id: 'index',
            label: null,
        },
        {
            id: 'step_name',
            label: <LocaleMessage msg="table.headers.step" />,
        },
        {
            id: 'result',
            label: <LocaleMessage msg="table.headers.answer" />,
        },
    ];

    const headers_sub_answers = [
        {
            id: 'created_at',
            order_by: 'created_timestamp',
            label: <LocaleMessage msg="table.headers.created" />,
        },
        {
            id: 'result',
            label: <LocaleMessage msg="table.headers.answer" />,
        },
        {
            id: 'to',
            label: <LocaleMessage msg="table.headers.go_to" />,
        },
    ];

    function onSessionRowClick(event, _id) {
        const session = data.find(i => {
            return i.id === _id;
        });

        const path = stepLogs.filter(l => {
            return l.session === session.id;
        });

        const path_idx = path.map((s, i) => {
            return {
                index: i + 1,
                ...s,
            };
        });

        const sub = sessionSubTables[_id]
            ? false
            : {
                  id: _id,
                  title: (
                      <LocaleMessage msg="page.reports.actions.survey_sessions.label.indicators.session_path" />
                  ),
                  headers: headers_sub_session,
                  rows: path_idx,
              };
        setSessionSubTables({
            [_id]: sub,
        });
    }

    function onStepRowClick(event, _id) {
        const step = logCorrelation.find(l => {
            return l.id === _id;
        });

        const ans_list = step.answers || [];

        const sub = stepSubTables[_id]
            ? false
            : {
                  id: _id,
                  title: (
                      <LocaleMessage msg="page.reports.actions.survey_sessions.label.indicators.step_logs" />
                  ),
                  headers: headers_sub_answers,
                  rows: ans_list,
              };
        setStepSubTables({
            [_id]: sub,
        });
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <InfoContainer className="row mt-5 mb-3">
                <div className="row col-12 mb-3 table-responsive-material">
                    <RowArea className="col-md-5 col-12 mb-3">
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.actions.survey_sessions.label.indicators.per_step" />
                        </p>
                        <DataTable
                            headerColumns={header_steps}
                            data={logCorrelation || []}
                            orderDirection="asc"
                            orderColumn="order"
                            handleTableRowClick={(event, _id) =>
                                onStepRowClick(event, _id)
                            }
                            subTables={stepSubTables}
                            hasActions={false}
                            sortable={false}
                            defaultRowsPerPage={stepList.length}
                            hasFooter={false}
                            hasFilter
                            headerDirection="row"
                        />
                    </RowArea>
                    <RowArea className="col-md-7 col-12 mb-3">
                        <p className="card-title">
                            <LocaleMessage msg="page.reports.actions.survey_sessions.label.indicators.per_session" />
                        </p>
                        <DataTable
                            headerColumns={header_sessions}
                            data={data || []}
                            orderDirection="desc"
                            orderColumn="start_timestamp"
                            handleTableRowClick={(event, _id) =>
                                onSessionRowClick(event, _id)
                            }
                            subTables={sessionSubTables}
                            hasActions={false}
                            sortable
                            defaultRowsPerPage={25}
                            hasFooter
                            hasFilter
                            headerDirection="row"
                        />
                    </RowArea>
                </div>
            </InfoContainer>
            <Divider />
        </>
    );
}

SurveyDetailView.defaultProps = {
    data: [],
    active: {},
};

SurveyDetailView.propTypes = {
    data: PropTypes.array,
    from: PropTypes.string.isRequired,
    until: PropTypes.string.isRequired,
    survey: PropTypes.object.isRequired,
    active: PropTypes.object,
    settings: PropTypes.object.isRequired,
    colors: PropTypes.object.isRequired,
    timeTooltip: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
};
