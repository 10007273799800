export default function checkGroupPermission(
    user,
    group_id,
    permitted = ['master', 'super_admin', 'admin']
) {
    const { profile } = user;
    const user_groups = user ? user.groups : [];

    const currGroup = user_groups.find(g => {
        return g.id === group_id;
    });

    const profile_permission =
        profile && profile.permission ? profile.permission : false;
    const adminMode = permitted.includes(profile_permission);

    const g_permission =
        currGroup && ['admin', 'editor'].includes(currGroup.permission);

    return {
        admin: adminMode,
        group: g_permission,
        allow: adminMode || g_permission,
    };
}
