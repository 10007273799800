/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import LocationsForm from '~/components/Locations/Form';

import CheckGroupPermission from '~/util/CheckGroupPermission';

export default function LocationForm({ match }) {
    const user = useSelector(state => state.user);

    const { id, location_id } = match.params;
    const permissions = CheckGroupPermission(user || {}, id);

    const allowEdit = permissions.allow;
    const adminMode = permissions.admin;

    return (
        <LocationsForm
            breadcrumbs={[
                {
                    url: '/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
                {
                    url: `/groups/${id}/locations`,
                    title: <LocaleMessage msg="breadcrumbs.locations" />,
                },
            ]}
            adminMode={adminMode}
            allowEdit={allowEdit}
            linkRoot={`/groups/${id}`}
            location_id={location_id}
        />
    );
}

LocationForm.propTypes = {
    match: PropTypes.object.isRequired,
};
