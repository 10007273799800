/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';

export default function ExitDialog({
    open,
    onClose,
    title,
    content,
    destination,
}) {
    return (
        <SimpleDialog
            open={open}
            onClose={onClose}
            title={title || <LocaleMessage msg="message.leave_page.title" />}
            content={
                <DialogContentText>
                    {content || <LocaleMessage msg="message.unsaved.content" />}
                </DialogContentText>
            }
            actions={[
                {
                    key: 'cancel',
                    onClick: () => onClose(),
                    label: <LocaleMessage msg="button.cancel" />,
                },
                {
                    key: 'go',
                    onClick: () => {
                        history.push(destination);
                    },
                    label: <LocaleMessage msg="button.go" />,
                },
            ]}
        />
    );
}

ExitDialog.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    destination: PropTypes.string,
};

ExitDialog.defaultProps = {
    title: null,
    content: null,
    open: false,
    destination: '/',
};
