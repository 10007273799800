/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getFormats from '~/util/GetFormats';

import List from './List';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

export default function RecycleBin() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const { active } = settings;
    const formats = getFormats(settings);

    const pages = [
        {
            value: 'applications',
            label: <LocaleMessage msg="page.applications.title" />,
        },
        {
            value: 'dialogs',
            label: <LocaleMessage msg="page.dialogs.title" />,
        },
        {
            value: 'albums',
            label: <LocaleMessage msg="page.galleries.title" />,
        },
        {
            value: 'locations',
            label: <LocaleMessage msg="page.locations.list.title" />,
        },
        {
            value: 'location_zones',
            label: <LocaleMessage msg="page.locations.zone.list.title" />,
        },
        {
            value: 'maps',
            label: <LocaleMessage msg="page.navmaps.title" />,
            condition_type: 'tool',
            condition: 'nav_maps',
        },
        {
            value: 'scripts',
            label: <LocaleMessage msg="page.tools.scripts.list.title" />,
            condition_type: 'tool',
            condition: 'scripts',
        },
        {
            value: 'dances',
            label: <LocaleMessage msg="page.dances.list.title" />,
            condition_type: 'tool',
            condition: 'dances',
        },
        {
            value: 'presentation_contents',
            label: (
                <LocaleMessage msg="page.tools.presentation_contents.title" />
            ),
            condition_type: 'tool',
            condition: 'presentation_contents',
        },
        {
            value: 'sections',
            label: <LocaleMessage msg="page.sections.list.title" />,
        },
        {
            value: 'contact_lists',
            label: <LocaleMessage msg="page.tools.contact_lists.title" />,
            condition_type: 'tool',
            condition: 'contact_list',
        },
        {
            value: 'contacts',
            label: <LocaleMessage msg="page.tools.contacts.title" />,
            condition_type: 'tool',
            condition: 'contact_list',
        },
        {
            value: 'autonomous_actions',
            label: (
                <LocaleMessage msg="page.tools.autonomous_actions.form.title" />
            ),
            condition_type: 'tool',
            condition: 'autonomous_actions',
        },
    ];

    const internalsettings = {
        applications: {
            apiURL: `recycle_bin/applications`,
            title: 'page.applications.title',
            successMessage: 'page.applications.form.update_success',
            confirmationMessage: 'page.recycle_bin.application.title',
        },
        albums: {
            apiURL: `recycle_bin/albums`,
            title: 'page.galleries.title',
            successMessage: 'page.galleries.form.update_success',
            confirmationMessage: 'page.recycle_bin.gallery.title',
        },
        autonomous_actions: {
            apiURL: `recycle_bin/autonomous_mode`,
            title: 'page.tools.autonomous_actions.form.title',
            successMessage: 'page.tools.autonomous_actions.form.update_success',
            confirmationMessage: 'page.recycle_bin.autonomous_mode.title',
        },
        dialogs: {
            apiURL: `recycle_bin/dialogs`,
            title: 'page.dialogs.title',
            successMessage: 'page.dialogs.form.update_success',
            confirmationMessage: 'page.recycle_bin.dialog.title',
            contentMessage: 'page.recycle_bin.dialog.content',
        },
        locations: {
            apiURL: `recycle_bin/locations`,
            title: 'page.locations.list.title',
            successMessage: 'page.locations.form.update_success',
            confirmationMessage: 'page.recycle_bin.location.title',
        },
        location_zones: {
            apiURL: `recycle_bin/zones`,
            title: 'page.locations.zone.form.title',
            successMessage: 'page.locations.zone.form.update_success',
            confirmationMessage: 'page.recycle_bin.location_zone.title',
        },
        maps: {
            apiURL: `recycle_bin/maps`,
            title: 'page.navmaps.title',
            successMessage: 'page.navmaps.form.update_success',
            confirmationMessage: 'page.recycle_bin.map.title',
        },
        scripts: {
            apiURL: `recycle_bin/scripts`,
            title: 'page.tools.scripts.list.title',
            successMessage: 'page.tools.scripts.form.update_success',
            confirmationMessage: 'page.recycle_bin.script.title',
        },
        dances: {
            apiURL: `recycle_bin/dances`,
            title: 'page.dances.list.title',
            successMessage: 'page.dances.form.update_success',
            confirmationMessage: 'page.recycle_bin.dance.title',
        },
        presentation_contents: {
            apiURL: `recycle_bin/presentation_contents`,
            title: 'page.tools.presentation_contents.title',
            successMessage:
                'page.tools.presentation_contents.form.update_success',
            confirmationMessage: 'page.recycle_bin.presentation_content.title',
        },
        sections: {
            apiURL: `recycle_bin/sections`,
            title: 'page.sections.list.title',
            successMessage: 'page.sections.update_success',
            confirmationMessage: 'page.recycle_bin.script.title',
        },
        contact_lists: {
            apiURL: `recycle_bin/contact_lists`,
            title: 'page.tools.contact_lists.title',
            successMessage: 'page.tools.contact_lists.form.update_success',
            confirmationMessage: 'page.recycle_bin.contact_list.title',
        },
        contacts: {
            apiURL: `recycle_bin/contacts`,
            title: 'page.tools.contacts.title',
            successMessage: 'page.tools.contacts.form.update_success',
            confirmationMessage: 'page.recycle_bin.contact.title',
        },
    };

    const [queryTab, setQueryTab] = useQueryState('tab', '');
    const [value, setValue] = useState(queryTab || pages[0].value);
    const [tools, setTools] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        setQueryTab(newValue);
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    async function loadTools() {
        setIsLoading(true);
        await api
            .get(`pluginspaces/tools`)
            .then(async response => {
                const { data } = response;
                setTools(data);
            })
            .catch(e => {
                requestError(e);
            });
        setIsLoading(false);
    }

    useEffect(() => {
        loadTools();
    }, []);

    function renderInternalPage(page) {
        const configs = internalsettings[page];

        return configs ? (
            <List
                page={page}
                active={active}
                formats={formats}
                requestError={error => requestError(error)}
                headers={configs.headers}
                apiURL={configs.apiURL}
                title={configs.title}
                successMessage={configs.successMessage}
                confirmationMessage={configs.confirmationMessage}
            />
        ) : null;
    }

    function renderOptions() {
        const filteredSettings = pages.filter(s => {
            if (s.condition_type === 'tool') {
                const c = s.condition;
                const tool = tools.find(t => {
                    return t.slug === c;
                });
                return !!tool;
            }
            return true;
        });

        const currTab = filteredSettings.find(t => {
            return t.value === value;
        });

        const firstTab = filteredSettings[0] ? filteredSettings[0].value : '';

        return (
            <Tabs
                indicatorColor="primary"
                orientation="vertical"
                value={currTab ? value : firstTab}
                onChange={handleChangeTab}
                className={classes.tabs}
                centered
            >
                {filteredSettings.map(p => {
                    return (
                        <Tab key={p.value} label={p.label} value={p.value} />
                    );
                })}
            </Tabs>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.recycle_bin.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                <div className="col-12 mb-5">
                    <CardSideBordered
                        title={<LocaleMessage msg="page.recycle_bin.title" />}
                        hide
                        Icon={MdDelete}
                    >
                        <>
                            <div
                                style={{
                                    padding: '0px 25px',
                                }}
                                className="mb-3"
                            >
                                <br />
                                <LocaleMessage msg="page.recycle_bin.subtitle" />
                            </div>
                            <div
                                className="sidecard-body"
                                style={{ display: 'flex' }}
                            >
                                {renderOptions()}
                                <div style={{ flex: 1 }}>
                                    {renderInternalPage(value)}
                                </div>
                            </div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

RecycleBin.propTypes = {};
