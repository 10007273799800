/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSettings, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const PAGE_VIEW_KEY = 'dialogs';
const PAGE_VIEW_DEF = 'grid';

export default function Dialogs() {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const date_loc = getDateLocale(settings);

    const [view, setView] = useState(
        page_settings[PAGE_VIEW_KEY] || PAGE_VIEW_DEF
    );
    const [isLoading, setIsLoading] = useState(true);
    const [dialogs, setDialogs] = useState([]);
    const [filteredData, setFilteredData] = useState(dialogs);

    const { active } = settings;
    const allowNew = !(active && active.id === '*');

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadDialogs() {
        setIsLoading(true);
        await api
            .get(`dialogs`)
            .then(response => {
                const data = response.data.map(d => {
                    const updated = new Date(d.updated);
                    return {
                        ...d,
                        group_name: d.group && d.group.name ? d.group.name : '',
                        lang_name: (
                            <LocaleMessage
                                msg={`list.languages.${d.language}`}
                            />
                        ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setDialogs(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadDialogs();
    }, [active, date_loc]);

    const [currDialog, setCurrDialog] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const open = Boolean(anchorEl);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrDialog(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`/tools/dialogs/${_id}`);
    }

    function handleClickOpen(event, _id) {
        setCurrDialog(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDialogOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrDialog(id);
        history.push(`/tools/dialogs/${id}/info`);
    }

    async function deleteDialog() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`dialogs/${currDialog}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.dialogs.list.delete_success" />
                );
                await loadDialogs();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function dialogCard(dialog) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={dialog.id}>
                <Link to={`/tools/dialogs/${dialog.id}/info`}>
                    <CardItem
                        title={dialog.name}
                        optionsClick={event => handleClick(event, dialog.id)}
                        group={
                            dialog.group_name || (
                                <LocaleMessage msg="message.all_groups.short" />
                            )
                        }
                        updated={dialog.updated}
                    >
                        <>
                            <div className="card-subtitle">
                                <p>{dialog.provider_name || dialog.provider}</p>
                            </div>
                            <div className="card-subtitle">
                                <p>{dialog.lang_name}</p>
                            </div>
                            <p
                                className="card-text"
                                style={{ minHeight: '40px' }}
                            >
                                <small>{dialog.description}</small>
                            </p>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {filteredData.map(dialog => dialogCard(dialog))}
                </div>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currDialog)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currDialog)}>
                        <LocaleMessage msg="page.dialogs.list.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'provider',
                label: <LocaleMessage msg="table.headers.provider" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: goToSettings,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        orderColumn="name"
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.dialogs.list.delete_title" />}
                content={
                    <>
                        <DialogContentText>
                            <LocaleMessage msg="message.recycler.content" />
                        </DialogContentText>
                        <DialogContentText>
                            <LocaleMessage msg="page.dialogs.list.delete_content" />
                        </DialogContentText>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteDialog(currDialog),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.dialogs.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div
                    className="body-top-controls"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="col-3 pl-0">
                        <NewItemButton
                            disabled={!allowNew}
                            link="/tools/dialogs/new"
                            text={<LocaleMessage msg="page.dialogs.list.add" />}
                        />
                    </div>
                    <div className="col-3">
                        <DataFilter
                            rawData={dialogs}
                            headerColumns={['name', 'provider']}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_VIEW_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
