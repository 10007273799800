export function signInRequest() {
    return {
        type: '@auth/SIGN_IN_REQUEST',
    };
}

export function signIn(auth) {
    return {
        type: '@auth/SIGN_IN',
        payload: { auth },
    };
}

export function signInSuccess(token, user, app = 'platform') {
    return {
        type: '@auth/SIGN_IN_SUCCESS',
        payload: { token, user, app },
    };
}

export function signInComplete() {
    return {
        type: '@auth/SIGN_IN_COMPLETE',
    };
}

export function signFailure() {
    return {
        type: '@auth/SIGN_FAILURE',
    };
}

export function refreshSessionRequest() {
    return {
        type: '@auth/SESSION_REFRESH_REQUEST',
    };
}

export function refreshSession(profile) {
    return {
        type: '@auth/SESSION_REFRESH',
        payload: { profile },
    };
}

export function expireSession() {
    return {
        type: '@auth/SESSION_EXPIRE',
    };
}

export function signOut() {
    return {
        type: '@auth/SIGN_OUT_REQUEST',
    };
}

export function signOutSuccess() {
    return {
        type: '@auth/SIGN_OUT',
    };
}
