/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSwapVerticalCircle } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Typography } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

const services = {
    pudutech: {
        key: 'pudutech',
        properties: [
            {
                key: 'address',
                label: <LocaleMessage msg="page.rc3_settings.server.address" />,
            },
            // {
            //     key: 'available',
            //     label: <LocaleMessage msg="page.rc3_settings.server.address" />,
            // },
        ],
    },
    synkar: {
        key: 'synkar',
        properties: [
            {
                key: 'address',
                label: <LocaleMessage msg="page.rc3_settings.server.address" />,
            },
            // {
            //     key: 'available',
            //     label: <LocaleMessage msg="page.rc3_settings.server.address" />,
            // },
        ],
    },
};

export default function RobotServices() {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    // const [serviceInfo, setServiceInfo] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadServiceInfo() {
        await api
            .get(`health`)
            .then(() => {
                // const { data } = response;
                // setServiceInfo(data);
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    useEffect(() => {
        loadServiceInfo();
    }, []);

    function renderInfo({ key, label, value }) {
        return (
            <div className="mb-3" key={key}>
                <Typography variant="caption" display="block" gutterBottom>
                    {label}
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                    {value}
                </Typography>
            </div>
        );
    }

    // const infos = [
    //     {
    //         key: 'address',
    //         label: <LocaleMessage msg="page.rc3_settings.server.address" />,
    //         value:
    //             serviceInfo.rc3 && serviceInfo.rc3.server
    //                 ? serviceInfo.rc3.server
    //                 : '',
    //     },
    //     {
    //         key: 'service',
    //         label: <LocaleMessage msg="page.rc3_settings.user.service" />,
    //         value:
    //             serviceInfo.rc3 && serviceInfo.rc3.user_config
    //                 ? 'true'
    //                 : 'false',
    //     },
    // ];

    return (
        <PageContent
            title="Robot Services"
            currentPage="Robot Services"
            breadcrumbs={[]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="row full-body">
                    {Object.keys(services).map(s => {
                        const r_service = services[s];
                        const properties = r_service.properties || [];
                        return (
                            <div
                                className="col-12 mb-5"
                                key={`service_${r_service.key}`}
                            >
                                <CardSideBordered
                                    title={`${r_service.key.toUpperCase()}`}
                                    Icon={MdSwapVerticalCircle}
                                    hide
                                >
                                    <div style={{ padding: '15px' }}>
                                        {properties.map(i => {
                                            return renderInfo(i);
                                        })}
                                    </div>
                                </CardSideBordered>
                            </div>
                        );
                    })}
                </div>
            </>
        </PageContent>
    );
}
