/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdAccountBalance, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
    addMonths,
    differenceInMonths,
    startOfMonth,
    getMonth,
    getYear,
} from 'date-fns';
import PropTypes from 'prop-types';

import {
    // Button,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Collapse,
    Box,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

const month_keys = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

export default function PluginspaceBilling({ match }) {
    const dispatch = useDispatch();
    const { id: pluginspace_id } = match.params;

    const [pluginspace, setPluginspace] = useState({});
    const [reportMonth, setReportMonth] = useState('');
    const [availableReports, setAvailableReports] = useState([]);
    const [rawData, setRawData] = useState({});
    const [parsedData, setParsedData] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getPeriods(start) {
        const limit = new Date();
        const months = differenceInMonths(startOfMonth(limit), start);
        const periods = [];
        for (let i = 0; i < months; i++) {
            const p_month = addMonths(start, i);
            periods.push({
                month: month_keys[getMonth(p_month)],
                year: getYear(p_month),
                value: p_month.toISOString(),
            });
        }

        setAvailableReports(periods.reverse());
        return periods;
    }

    function parseData(data) {
        const defaults = {
            total: 0,
            periods: [],
        };
        const pluginspace_report = {
            license: {
                label: 'License',
                ...defaults,
            },
        };
        const robots_report = {};
        const users_report = {};
        const tools_report = {};
        const plugins_report = {};

        const pluginspace_license = data.active_pluginspace || [];
        pluginspace_license.forEach(p => {
            pluginspace_report.license.periods.push({
                start: p.start,
                end: p.end,
            });
            pluginspace_report.license.total += p.days;
        });

        const pluginspace_robots = data.active_robot || [];
        pluginspace_robots.forEach(r => {
            const robot = r.robot || {};
            const r_id = robot.id || '---';
            if (!robots_report[r_id]) {
                robots_report[r_id] = {
                    label: `[${robot.type}]: ${robot.code} - ${robot.name ||
                        ''}`,
                    ...defaults,
                };
            }
            robots_report[r_id].total += r.days;
        });

        const pluginspace_users = data.active_user || [];
        pluginspace_users.forEach(r => {
            const user = r.user || {};
            const u_id = user.id || '---';
            if (!users_report[u_id]) {
                users_report[u_id] = {
                    label: user.name,
                    ...defaults,
                };
            }
            users_report[u_id].total += r.days;
        });
        // Show total users in month, new users, deactivated users, etc

        const pluginspace_tools = data.active_tool || [];
        pluginspace_tools.forEach(t => {
            const tool = t.addon || {};
            const t_id = tool.id || '---';
            if (!tools_report[t_id]) {
                tools_report[t_id] = {
                    label: tool.name,
                    ...defaults,
                };
            }
            tools_report[t_id].total += t.days;
        });

        const pluginspace_plugins = data.active_plugin || [];
        pluginspace_plugins.forEach(p => {
            const plugin = p.addon || {};
            const p_id = plugin.id || '---';
            if (!plugins_report[p_id]) {
                plugins_report[p_id] = {
                    label: plugin.name,
                    ...defaults,
                };
            }
            plugins_report[p_id].total += p.days;
        });

        const report = {
            pluginspace: pluginspace_report,
            robots: robots_report,
            users: users_report,
            tools: tools_report,
            plugins: plugins_report,
        };

        setParsedData(report);
    }

    async function loadPluginspace(_id) {
        if (_id !== 'new') {
            await api
                .get(`pluginspaces/${_id}`)
                .then(response => {
                    const p = response.data;
                    const { created } = p;
                    const start_date = startOfMonth(new Date(created));
                    setPluginspace({
                        name: p.name,
                    });
                    getPeriods(start_date);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    async function loadReport(m) {
        const filter = m === 'current' ? '' : `?month=${m}`;
        setIsLoading(true);
        await api
            .get(`pluginspaces/${pluginspace_id}/report${filter}`)
            .then(response => {
                const { assets } = response.data;
                setRawData(assets);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadPluginspace(pluginspace_id);
    }, [pluginspace_id]);

    useEffect(() => {
        parseData(rawData);
    }, [rawData]);

    useEffect(() => {
        loadReport(reportMonth);
    }, [reportMonth]);

    function Row({ name, entries }) {
        const [open, setOpen] = useState(true);
        const entry_rows = Object.keys(entries);

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell component="th" scope="row">
                        <IconButton
                            size="small"
                            onClick={() => setOpen(!open)}
                            className="mr-3"
                        >
                            {open ? <MdExpandLess /> : <MdExpandMore />}
                        </IconButton>
                        <strong>{name}</strong>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table>
                                    <TableBody>
                                        {entry_rows.map(r => {
                                            const entry = entries[r];
                                            return (
                                                <TableRow key={`entry_${r}`}>
                                                    <TableCell width="5%" />
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {entry.label}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    Row.defaultProps = {
        entries: {},
    };

    Row.propTypes = {
        name: PropTypes.string.isRequired,
        entries: PropTypes.object,
    };

    const rows = [
        {
            name: 'Pluginspace',
            key: `row_pluginspace`,
            entries:
                parsedData && parsedData.pluginspace
                    ? parsedData.pluginspace
                    : {},
        },
        {
            name: 'Robots',
            key: `row_robots`,
            entries: parsedData && parsedData.robots ? parsedData.robots : {},
        },
        // {
        //     name: 'Users',
        //     key: `row_users`,
        //     entries: parsedData && parsedData.users ? parsedData.users : {},
        // },
        {
            name: 'Tools',
            key: `row_tools`,
            entries: parsedData && parsedData.tools ? parsedData.tools : {},
        },
        {
            name: 'Plugins',
            key: `row_plugins`,
            entries: parsedData && parsedData.plugins ? parsedData.plugins : {},
        },
    ];

    return (
        <PageContent
            title="Pluginspace Billing"
            breadcrumbs={[
                {
                    url: '/admin/analytics',
                    title: <LocaleMessage msg="breadcrumbs.analytics" />,
                },
                {
                    url: '/admin/pluginspaces',
                    title: <LocaleMessage msg="breadcrumbs.pluginspaces" />,
                },
                {
                    url: `/admin/pluginspaces/${pluginspace_id}`,
                    title:
                        pluginspace && pluginspace.name ? pluginspace.name : '',
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="col-12 mb-5">
                    <CardSideBordered
                        title="Pluginspace Billing"
                        Icon={MdAccountBalance}
                        hide
                    >
                        <>
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <FormControl fullWidth className="mb-5">
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={reportMonth || 'current'}
                                            onChange={event =>
                                                setReportMonth(
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem value="current">
                                                <LocaleMessage msg="label.month.current" />
                                            </MenuItem>
                                            {availableReports.map(p => (
                                                <MenuItem
                                                    value={p.value}
                                                    key={`report_period_${p.value}`}
                                                >
                                                    <LocaleMessage
                                                        msg={`label.month.${p.month}`}
                                                    />
                                                    , {p.year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-12">
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            {rows.map(r => {
                                                return (
                                                    <Row
                                                        key={r.key}
                                                        name={r.name}
                                                        entries={r.entries}
                                                    />
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

PluginspaceBilling.propTypes = {
    match: PropTypes.object.isRequired,
};
