/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

export default function SimpleDialog({
    title,
    open,
    onClose,
    size,
    content,
    actions,
}) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={size} fullWidth>
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            <DialogContent>{content}</DialogContent>
            {actions ? (
                <DialogActions>
                    {actions.map(a => {
                        return (
                            <Button
                                key={a.key}
                                onClick={a.onClick}
                                color={a.color || 'primary'}
                                autoFocus
                                disabled={a.disabled}
                            >
                                {a.label}
                            </Button>
                        );
                    })}
                </DialogActions>
            ) : null}
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.string,
    actions: PropTypes.array,
};

SimpleDialog.defaultProps = {
    content: null,
    open: false,
    size: 'sm',
    actions: [],
};
