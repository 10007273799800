import { enUS, enGB, es, ptBR } from 'date-fns/locale';

const locales = { en_GB: enGB, en_US: enUS, es_ES: es, pt_BR: ptBR };

export default function getDateLocale(settings) {
    const loc = settings ? settings.locale : null;
    const lang = loc && loc.code ? loc.code : 'en_US';
    const date_loc = locales[lang];
    date_loc.lang = lang;

    return date_loc;
}
