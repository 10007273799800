import styled from 'styled-components';

export const Item = styled.li`
    & {
        border-bottom: 1px solid #555555;
    }

    .row {
        padding: 0px 15px;
    }

    :hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }

    .map-image {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .img-placeholder {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
            background-color: ${props => props.theme.palette.primary.main};
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .map-info {
        padding: 1.5vh 1vw !important;
        color: #ddd;
        flex-direction: column;
        font-size: 1.5vh;

        .name {
            font-weight: bold;
        }

        .group {
            color: #999999;
        }
    }
`;
