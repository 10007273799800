import styled from 'styled-components';

export const Container = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    label {
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
            opacity: 0.7;
        }

        .logo-input {
            height: 200px;
            padding: 20px;
            width: 100%;
            border-radius: 5px;
            border: 3px solid rgba(0, 0, 0, 0.1);
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: ${props => props.theme.palette.primary.main};
            color: #fff;
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }

        .logo-placeholder {
            max-height: 100%;
            max-width: 100%;
            background: ${props => props.theme.palette.primary.main};
            color: #fff;
        }

        input {
            display: none;
        }

        svg {
            margin-right: 5px;
        }
    }
`;
