/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { PreviewArea, Screen } from './styles';

export default function Preview({ configuration, language }) {
    const { template, settings, file } = configuration;

    const titles = settings && settings.title ? settings.title : {};
    const texts = settings && settings.info ? settings.info : {};
    const has_info =
        settings && settings.text_size && settings.text_size !== 'disabled';

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: (4.0 / 3.0) * targetRef.current.offsetWidth,
            });
        }
    }, []);

    function renderText() {
        return (
            <>
                <span className="title">{titles[language] || 'Title'}</span>
                {has_info ? (
                    <span className="text">
                        {texts[language] || 'Text Info'}
                    </span>
                ) : null}
            </>
        );
    }

    function renderFile() {
        return file && file.url ? (
            <img className="media" src={file.url} alt={file.file_id} />
        ) : null;
    }

    function renderTextOnlyTemplate() {
        return <div className="col-12 row text-template">{renderText()}</div>;
    }

    function renderImgOnlyTemplate() {
        return (
            <div className="col-12 row overlay-template">
                <div className="col-12 img_container">{renderFile()}</div>
            </div>
        );
    }

    function renderOverlayTemplate() {
        return (
            <div className="col-12 row overlay-template">
                <div className="col-12 img_container">{renderFile()}</div>
                <div className="col-12 overlay_container">
                    <div className="spacer" />
                    <div className="col-8 text_container">{renderText()}</div>
                    <div className="spacer" />
                </div>
            </div>
        );
    }

    function renderSideTemplate(inverted) {
        return (
            <div
                className="col-12 row side-template"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: inverted ? 'row' : 'row-reverse',
                }}
            >
                <div className="col-6 img_container">{renderFile()}</div>
                <div className="col-6 text_container">{renderText()}</div>
            </div>
        );
    }

    function renderTemplate() {
        switch (template) {
            case 'text_only':
                return renderTextOnlyTemplate();
            case 'img_only':
                return renderImgOnlyTemplate();
            case 'overlay':
                return renderOverlayTemplate();
            case 'side_img_left':
                return renderSideTemplate();
            case 'side_img_right':
                return renderSideTemplate(true);
            default:
                return null;
        }
    }

    return (
        <PreviewArea className="mt-3 mb-3" ref={targetRef}>
            <Screen dimensions={dimensions} settings={settings || {}}>
                {renderTemplate()}
            </Screen>
        </PreviewArea>
    );
}

Preview.defaultProps = {
    configuration: {},
    language: 'pt_BR',
};

Preview.propTypes = {
    configuration: PropTypes.object,
    language: PropTypes.string,
};
