/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';
import UnavailablePlugin from '~/components/UnavailablePlugin';

import api from '~/services/pluginbot-api';

export default function VisionConfig(props) {
    const { setToast, appSettings, updateObject } = props;
    const { settings } = appSettings;

    const [isLoading, setIsLoading] = useState(true);
    const [vision, setVision] = useState(
        settings && settings.vision ? settings.vision : {}
    );
    const [allowPlugin, setAllowPlugin] = useState(true);

    const [actionList, setActionList] = useState([]);
    const [pluginconfigList, setPluginconfigList] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            setToast({
                color: 'error',
                body: message,
            });
        } else if (error.request) {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.request" />,
            });
        } else {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.unknown" />,
            });
        }
        setIsLoading(false);
    }

    async function loadActionList(_id) {
        await api
            .get(`pluginspaces/actions?plugin=vision`)
            .then(response => {
                const { data } = response;
                setActionList(data);
            })
            .catch(error => requestError(error));
    }

    async function loadPluginconfigs(_id) {
        await api
            .get(`pluginconfigs?plugin=vision`)
            .then(response => {
                const { data } = response;
                const configs = {};
                data.forEach(conf => {
                    const { action } = conf;
                    if (!configs[action.id]) {
                        configs[action.id] = [];
                    }
                    configs[action.id].push({ id: conf.id, name: conf.name });
                });
                setPluginconfigList(configs);
            })
            .catch(error => requestError(error));
    }

    async function loadVisionPlugin() {
        await api
            .get(`pluginspaces/addons/vision`)
            .then(async response => {
                const { data } = response;
                if (data.id) {
                    // setVisionPlugin(data);
                    await loadActionList(data.id);
                    await loadPluginconfigs(data.id);
                } else {
                    setAllowPlugin(false);
                }
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadVisionPlugin();
    }, []);

    function handleSwitchChange(event, key) {
        setVision({
            ...vision,
            [key]: { ...vision[key], active: event.target.checked },
        });
    }

    function handlePluginconfigChange(event, key) {
        setVision({
            ...vision,
            [key]: { ...vision[key], pluginconfig_id: event.target.value },
        });
    }

    async function handleSubmit() {
        const data = { vision };
        updateObject(data);
    }

    function renderActionSettings(a) {
        const p_confs = pluginconfigList[a.id];
        return (
            <div key={a.id}>
                <FormControlLabel
                    className="mb-3"
                    control={
                        <Switch
                            color="primary"
                            checked={
                                vision[a.function_name] &&
                                vision[a.function_name].active
                                    ? vision[a.function_name].active
                                    : false
                            }
                            value={a.function_name}
                            onChange={event =>
                                handleSwitchChange(event, a.function_name)
                            }
                        />
                    }
                    label={<LocaleMessage msg={a.name} />}
                />

                {vision[a.function_name] && vision[a.function_name].active ? (
                    <FormControl fullWidth className="mb-5">
                        <InputLabel id={`integration-${a.function_name}`}>
                            <LocaleMessage msg="page.applications.conversation.vision.integration" />
                        </InputLabel>
                        <Select
                            labelId={`label-${a.function_name}`}
                            value={
                                vision[a.function_name].pluginconfig_id
                                    ? vision[a.function_name].pluginconfig_id
                                    : ''
                            }
                            onChange={event =>
                                handlePluginconfigChange(event, a.function_name)
                            }
                        >
                            {p_confs && p_confs.length > 0 ? (
                                p_confs.map(p => {
                                    return (
                                        <MenuItem value={p.id} key={p.id}>
                                            {p.name}
                                        </MenuItem>
                                    );
                                })
                            ) : (
                                <MenuItem value={null}>
                                    <LocaleMessage msg="page.applications.conversation.vision.no_config" />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                ) : null}
            </div>
        );
    }

    return (
        <div className="col-12 mb-3">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.vision.title" />
                </h2>
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {!allowPlugin ? (
                            <UnavailablePlugin />
                        ) : (
                            <>
                                <div
                                    className="row"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="col-md-6 col-12 mb-3">
                                        {actionList.length > 0 ? (
                                            <FormControl
                                                fullWidth
                                                component="fieldset"
                                            >
                                                <FormLabel component="legend">
                                                    <LocaleMessage msg="page.applications.conversation.vision.use" />
                                                </FormLabel>
                                                <FormGroup
                                                    style={{ width: '100%' }}
                                                >
                                                    {actionList.map(a =>
                                                        renderActionSettings(a)
                                                    )}
                                                </FormGroup>
                                            </FormControl>
                                        ) : (
                                            'This pluginspace does not have any functions available for Plugin Vision'
                                        )}
                                    </div>
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="col-md-6 col-12 mb-5">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

VisionConfig.propTypes = {
    updateObject: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
};
