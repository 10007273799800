/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdList } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import ExitDialog from '~/components/ExitDialog';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import ScriptsConfig from './Settings/ScriptsConfig';

export default function ScriptingApp(props) {
    const { setToast, appSettings, updateObject } = props;
    const { robot_types } = appSettings;
    const robot_type = robot_types[0] || {};

    const [isLoading, setIsLoading] = useState(false);
    const [scripts, setScripts] = useState({});
    const [robotSettings, setRobotSettings] = useState({});
    const [defaultScriptGalleries, setDefaultScriptGalleries] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [config, setConfig] = useState(
        appSettings && appSettings.settings ? appSettings.settings : {}
    );

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            setToast({
                color: 'error',
                body: message,
            });
        } else if (error.request) {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.request" />,
            });
        } else {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.unknown" />,
            });
        }
        setIsLoading(false);
    }

    async function loadRobotSettings(_id) {
        await api
            .get(`types/${_id}`)
            .then(response => {
                setRobotSettings(response.data);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    const settings = config.settings || {};

    async function loadItems() {
        return api
            .get(`scripts?robot=${robot_type.id}`)
            .then(response => {
                const items = response.data;
                return items.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                    };
                });
            })
            .catch(error => requestError(error));
    }

    async function loadRobotDefaults(_id) {
        await api
            .get(`types/${_id}/defaults?album_type=scripts`)
            .then(response => {
                const defaults = response.data;
                setDefaultScriptGalleries(defaults);
            })
            .catch(error => requestError(error));
    }

    async function loadGalleryItems() {
        setIsLoading(true);
        const items = {};
        await Promise.all(
            defaultScriptGalleries.map(async g => {
                if (g && g.id) {
                    await api.get(`albums/${g.id}/files`).then(response => {
                        const { data } = response;
                        items[g.id] = data.map(s => {
                            const obj = s.object;
                            return {
                                id: obj.id,
                                name: obj.name,
                            };
                        });
                    });
                }
            })
        );

        items.pluginspace = await loadItems();

        setScripts(items);
        setIsLoading(false);
    }

    useEffect(() => {
        loadGalleryItems();
    }, [defaultScriptGalleries]);

    useEffect(() => {
        if (robot_type.id) {
            loadRobotDefaults(robot_type.id);
            loadRobotSettings(robot_type.id);
        }
    }, [robot_type]);

    function handleModeChange(event) {
        const val = event.target.value;
        let scriptConfig = settings.scripts || {};
        if (val === 'multi') {
            scriptConfig = {
                ...scriptConfig,
                main: undefined,
            };
        }
        setConfig({
            ...config,
            settings: {
                mode: val,
                scripts: scriptConfig,
            },
        });
    }

    function renderAppContent() {
        return (
            <ScriptsConfig
                robot={robotSettings}
                defaultGalleries={defaultScriptGalleries}
                scriptLists={scripts}
                config={settings}
                setConfig={c => setConfig({ ...config, settings: c })}
            />
        );
    }

    function buildConfirmDialog() {
        return (
            <ExitDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                destination="/tools/scripts"
            />
        );
    }

    return (
        <div className="col-12 mb-5 mt-5">
            {isLoading ? (
                <Splash />
            ) : (
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.applications.scripting.title" />
                    }
                    Icon={MdList}
                    hide
                >
                    <>
                        <div className="sidecard-body">
                            <div
                                className="col-12 row mt-1"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className="col-12 row"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <div className="col-md-4 col-12 mb-3">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                <LocaleMessage msg="page.applications.scripting.label.mode" />
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                value={
                                                    settings.mode || 'single'
                                                }
                                                onChange={event =>
                                                    handleModeChange(event)
                                                }
                                            >
                                                <FormControlLabel
                                                    value="single"
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.applications.scripting.label.single_script" />
                                                    }
                                                />
                                                <FormControlLabel
                                                    value="multi"
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.applications.scripting.label.multi_script" />
                                                    }
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div
                                        className="col-md-4 col-12 mb-3"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                padding: '5px 20px',
                                            }}
                                            onClick={() => setDialogOpen(true)}
                                        >
                                            <LocaleMessage msg="page.applications.scripting.label.edit_scripts" />
                                        </Button>
                                    </div>
                                </div>
                                {renderAppContent()}
                                <Button
                                    className="col-md-8 col-12 p-3 mt-5"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        updateObject({ ...config, settings })
                                    }
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.save" />
                                </Button>
                            </div>
                        </div>
                        {dialogOpen ? buildConfirmDialog() : null}
                    </>
                </CardSideBordered>
            )}
        </div>
    );
}

ScriptingApp.propTypes = {
    appSettings: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
};
