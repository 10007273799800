import enGB_Messages from '~/lngProvider/messages/en_GB.json';
import enUS_Messages from '~/lngProvider/messages/en_US.json';
import esES_Messages from '~/lngProvider/messages/es_ES.json';
import ptBR_Messages from '~/lngProvider/messages/pt_BR.json';

export default {
    en_US: enUS_Messages,
    en_GB: enGB_Messages,
    es_ES: esES_Messages,
    pt_BR: ptBR_Messages,
};
