import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;
`;

export const LocationInfo = styled.div`
    font-size: 1.25vh;
    padding: 1vh;

    .title-name {
        font-size: 1.5vh;
    }
`;

export const StatusItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25vh;

    .MuiBadge-root {
        margin: 0px 15px 0px 5px;
    }

    .status-connected {
        .MuiBadge-dot {
            background-color: #0f0;
        }
    }

    .status-occupied {
        .MuiBadge-dot {
            background-color: #ff0;
        }
    }

    .status-alert {
        .MuiBadge-dot {
            background-color: #f00;
        }
    }

    .status-disconnected {
        .MuiBadge-dot {
            background-color: #999;
        }
    }

    .status-undefined {
        .MuiBadge-dot {
            background-color: #999;
        }
    }

    :not(:last-child) {
        margin-bottom: 10px;
    }

    :hover {
        cursor: pointer;
    }
`;
