/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdCompareArrows, MdAccountBox, MdContentCopy } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import DateFnsUtils from '@date-io/date-fns';
import { addDays, isAfter } from 'date-fns';
import PropTypes from 'prop-types';

import {
    FormControl,
    Chip,
    Card,
    CardContent,
    InputLabel,
    Select,
    Button,
    DialogContentText,
    MenuItem,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';

import CardSideBordered from '~/components/CardSideBordered';
import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import RobotInfo from '~/components/RobotInfo';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkPermission from '~/util/CheckPermission';
import getDateLocale from '~/util/GetDateLocale';

const { transfer_status } = lists;

export default function RobotTransfer(props) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { profile } = useSelector(state => state.user || null);
    const { active } = settings;
    const su_admin = checkPermission(profile, ['master', 'super_admin']);
    const g_admin = checkPermission(profile) || active.permission === 'admin';
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date_format = settings.locale;

    const date_loc = getDateLocale(settings);

    const { match } = props;
    const { id, robot_id } = match.params;
    const [operation, setOperation] = useState('update');
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const [groupList, setGroupList] = useState([]);

    const [enableTransfer, setEnableTransfer] = useState(true);
    const [robot, setRobot] = useState({});
    const [type, setType] = useState({});
    const [transferDialogOpen, setTransferDialogOpen] = useState(false);
    const [dateTime, setDateTime] = useState(addDays(new Date(), 1));
    const [transferBody, setTransferBody] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadGroups() {
        await api
            .get(`groups`)
            .then(response => {
                const groups = response.data;
                setGroupList(groups);
            })
            .catch(error => requestError(error));
    }

    async function loadRobot(_id, setDefaults = false) {
        await api
            .get(`robots/${_id}`)
            .then(response => {
                const _robot = response.data;
                const r = {
                    ..._robot,
                    type_id: _robot.type.id,
                    type_name: _robot.type.name,
                    file: _robot.type.file ? _robot.type.file.url : null,
                };
                setRobot(r);
                setType(_robot.type);
                setIsLoading(false);

                const { transfer } = _robot;
                if (id === 'new' && transfer && transfer.id) {
                    setEnableTransfer(false);
                    setTransferBody(transfer);
                    return;
                }

                if (setDefaults) {
                    setTransferBody({
                        robot_id,
                        origin: { group_id: _robot.group_id },
                    });
                }
            })
            .catch(error => requestError(error));
    }

    async function loadTransfer(_id) {
        await api
            .get(`robots/transfers/${_id}`)
            .then(async response => {
                const transfer = response.data;
                const date = transfer.until;

                if (transfer.robot) {
                    await loadRobot(transfer.robot.id);
                }
                setTransferBody(transfer);
                setDateTime(date);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadGroups();
        if (id === 'new') {
            setOperation('create');
            loadRobot(robot_id, true);
        } else {
            setOperation('update');
            loadTransfer(id);
        }
    }, [id, date_loc]);

    function handleCopy() {
        toast.info(<LocaleMessage msg="page.transfer.form.message.copied" />);
    }

    async function updateTransfer(body) {
        await api
            .put(`robots/transfers/${id}`, body)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.transfer.form.update_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            robot_id,
            ...transferBody,
            ...(transferBody.type === 'temporary' && {
                until: dateTime,
            }),
            timezone: tz,
        };

        if (operation === 'create') {
            await api
                .post(`robots/transfers`, data)
                .then(response => {
                    if (response.data.id) {
                        toast.success(
                            <LocaleMessage msg="page.transfer.form.message.sent" />
                        );
                        const destination_url = transferBody.auto_accept
                            ? `/robots`
                            : `/robots/transfer/${response.data.id}`;
                        history.push(destination_url);
                    }
                })
                .catch(error => requestError(error));
        } else {
            await updateTransfer(data);
        }
        setIsLoading(false);
    }

    async function finishTransfer(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            robot_id,
            ...transferBody,
            status: 'finished',
        };

        await updateTransfer(data);
        setIsLoading(false);
        history.push(`/robots`);
    }

    async function handleCancelTransfer(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            ...transferBody,
            status: 'canceled',
        };

        await api
            .delete(`robots/transfers/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.transfer.form.message.canceled" />
                );
                history.push(`/robots`);
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setTransferDialogOpen(false);
        setCancelDialogOpen(false);
    }

    function handleCancelOpen(event) {
        event.preventDefault();
        setCancelDialogOpen(true);
    }

    const handleDateChange = date => {
        setDateTime(date);
    };

    function buildTransferDialog() {
        return (
            <SimpleDialog
                open={transferDialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.transfer.form.message.page.transfer.form.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.transfer.form.unavailable.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setTransferDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'transfer',
                        onClick: event => handleSubmit(event),
                        label: <LocaleMessage msg="button.transfer" />,
                    },
                ]}
            />
        );
    }

    function buildCancelDialog() {
        return (
            <SimpleDialog
                open={cancelDialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.transfer.form.delete_title" />}
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: event => handleDialogClose(event),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'cancel_transfer',
                        onClick: event => handleCancelTransfer(event),
                        label: (
                            <LocaleMessage msg="page.transfer.form.label.cancel_transfer" />
                        ),
                    },
                ]}
            />
        );
    }

    function renderForm() {
        const transfer_limit = dateTime ? new Date(dateTime) : null;

        const transfer_ended =
            transfer_limit && isAfter(new Date(), transfer_limit);

        const disable =
            transfer_ended ||
            (operation === 'update' &&
                transferBody.status !== 'waiting' &&
                transferBody.status !== 'running');

        const origin_group =
            transferBody.origin && transferBody.origin.group_id
                ? transferBody.origin.group_id
                : null;

        const destination_group =
            transferBody.destination && transferBody.destination.group_id
                ? transferBody.destination.group_id
                : null;

        const destination_list = origin_group
            ? groupList.filter(g => {
                  return g.id !== origin_group;
              })
            : groupList;

        return (
            <>
                {operation === 'update' ? (
                    <div className="row mb-5">
                        {transfer_ended ? (
                            <div
                                className="col-12"
                                style={{ textAlign: 'center' }}
                            >
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    label={
                                        <LocaleMessage msg="page.robots.form.label.expired_transfer" />
                                    }
                                />
                            </div>
                        ) : (
                            <div className="col-md-6 col-12">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.transfer.form.label.status" />
                                    </InputLabel>
                                    <Select
                                        value={transferBody.status || 'open'}
                                        disabled
                                    >
                                        {transfer_status.map(s => {
                                            return (
                                                <MenuItem
                                                    value={s.value}
                                                    key={s.value}
                                                >
                                                    <LocaleMessage
                                                        msg={s.label_code}
                                                    />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                ) : null}
                <div className="row">
                    <div className="col-md-6 col-12">
                        <FormSelect
                            classes="mb-5"
                            options={groupList}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.transfer.form.label.origin_group" />
                            }
                            onChange={value =>
                                setTransferBody({
                                    ...transferBody,
                                    origin: {
                                        group_id: value,
                                    },
                                })
                            }
                            disabled
                            value={origin_group || ''}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <FormSelect
                            classes="mb-5"
                            options={destination_list}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.transfer.form.label.destination_group" />
                            }
                            onChange={value =>
                                setTransferBody({
                                    ...transferBody,
                                    destination: {
                                        group_id: value,
                                    },
                                })
                            }
                            disabled={operation === 'update'}
                            value={destination_group || ''}
                        />
                    </div>

                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel id="transfer-type">
                                <LocaleMessage msg="page.transfer.form.label.type" />
                            </InputLabel>
                            <Select
                                labelId="transfer-type"
                                id="transfer-type"
                                value={
                                    transferBody && transferBody.type
                                        ? transferBody.type
                                        : ''
                                }
                                onChange={event =>
                                    setTransferBody({
                                        ...transferBody,
                                        type: event.target.value,
                                    })
                                }
                                disabled={disable || operation === 'update'}
                            >
                                <MenuItem value="temporary">
                                    <LocaleMessage msg="page.transfer.form.label.temporary" />
                                </MenuItem>
                                <MenuItem value="definitive">
                                    <LocaleMessage msg="page.transfer.form.label.definitive" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {transferBody.type === 'temporary' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={date_loc}
                            >
                                <KeyboardDateTimePicker
                                    fullWidth
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label={
                                        <LocaleMessage msg="label.form.until" />
                                    }
                                    format="dd MMM yyyy HH:mm"
                                    value={dateTime || new Date()}
                                    onChange={handleDateChange}
                                    disablePast
                                    autoOk
                                    ampm={false}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    ) : null}
                </div>
                <div className="col-12">
                    {operation === 'update' &&
                    !disable &&
                    transferBody.status === 'waiting' ? (
                        <>
                            <p style={{ margin: '15px 0px' }}>
                                <LocaleMessage msg="page.transfer.form.label.key" />
                            </p>
                            <Card className="mb-5">
                                <CardContent
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        backgroundColor: '#f5f5f5',
                                        fontSize: '14px',
                                    }}
                                >
                                    <p style={{ margin: '0px' }}>
                                        {transferBody.id}
                                    </p>
                                    <CopyToClipboard
                                        text={transferBody.id}
                                        onCopy={() => handleCopy()}
                                    >
                                        <Button
                                            variant="outlined"
                                            startIcon={<MdContentCopy />}
                                        >
                                            <LocaleMessage msg="button.copy" />
                                        </Button>
                                    </CopyToClipboard>
                                </CardContent>
                            </Card>
                        </>
                    ) : null}
                    {su_admin && operation === 'create' ? (
                        <div className="col-12 mb-3">
                            <FormSwitch
                                value={transferBody.auto_accept || false}
                                onChange={event =>
                                    setTransferBody({
                                        ...transferBody,
                                        auto_accept: event.target.checked,
                                    })
                                }
                                label={
                                    <LocaleMessage msg="page.transfer.form.label.auto_accept" />
                                }
                            />
                        </div>
                    ) : null}
                    <Button
                        className="p-3 mb-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                        disabled={!g_admin}
                    >
                        {operation === 'create' ? (
                            <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                        ) : (
                            <LocaleMessage msg="page.transfer.form.label.update_transfer" />
                        )}
                    </Button>
                    {operation === 'update' ? (
                        <>
                            {!(
                                !g_admin || transferBody.status !== 'waiting'
                            ) ? (
                                <Button
                                    className="p-3 mb-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={event => handleCancelOpen(event)}
                                    fullWidth
                                    size="large"
                                    disabled={
                                        !g_admin ||
                                        transferBody.status !== 'waiting'
                                    }
                                >
                                    <LocaleMessage msg="page.transfer.form.label.cancel_transfer" />
                                </Button>
                            ) : null}
                            <Button
                                className="p-3 mb-3"
                                variant="contained"
                                color="primary"
                                onClick={event => finishTransfer(event)}
                                fullWidth
                                size="large"
                                disabled={
                                    !g_admin ||
                                    transferBody.status === 'waiting'
                                }
                            >
                                <LocaleMessage msg="page.transfer.form.label.finish_transfer" />
                            </Button>
                        </>
                    ) : null}
                </div>
            </>
        );
    }

    function renderBlockedTransfer() {
        const { until } = transferBody;
        const transfer_limit = new Date(until);
        const transfer_ended =
            transfer_limit && isAfter(new Date(), transfer_limit);

        return (
            <div className="row mb-5">
                <div
                    className="col-12"
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <p className="mb-5">
                        <LocaleMessage msg="page.transfer.form.label.in_transfer" />
                    </p>
                    <Chip
                        variant="outlined"
                        color="primary"
                        label={
                            transfer_ended ? (
                                <LocaleMessage msg="page.robots.form.label.expired_transfer" />
                            ) : (
                                <>
                                    <LocaleMessage msg="page.robots.form.label.transfered_until" />
                                    {` ${
                                        transfer_limit
                                            ? transfer_limit.toLocaleString(
                                                  date_format.format
                                              )
                                            : ''
                                    }`}
                                </>
                            )
                        }
                    />
                    {transfer_ended ? (
                        <p className="mt-5">
                            <LocaleMessage msg="page.transfer.form.label.return_owner" />
                        </p>
                    ) : null}
                </div>
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'receive' ? (
                    <LocaleMessage msg="page.transfer.form.label.receive_transfer" />
                ) : (
                    <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                )
            }
            currentPage={
                operation === 'send' ? (
                    <LocaleMessage msg="page.transfer.form.label.receive_transfer" />
                ) : (
                    <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/robots',
                    title: <LocaleMessage msg="breadcrumbs.robots" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {transferDialogOpen ? buildTransferDialog() : null}
                {cancelDialogOpen ? buildCancelDialog() : null}
                <div className="col-md-8 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.transfer.form.label.robot" />
                        }
                        Icon={MdAccountBox}
                    >
                        <>
                            {robot ? (
                                <RobotInfo
                                    robot={robot}
                                    setRobot={() => {}}
                                    robotTypes={[type]}
                                    type={type}
                                    disabled
                                />
                            ) : null}
                        </>
                    </CardSideBordered>
                    <CardSideBordered
                        title={
                            operation === 'receive' ? (
                                <LocaleMessage msg="page.transfer.form.label.receive_transfer" />
                            ) : (
                                <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                            )
                        }
                        Icon={MdCompareArrows}
                    >
                        {operation === 'create' && !enableTransfer
                            ? renderBlockedTransfer()
                            : renderForm()}
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

RobotTransfer.propTypes = {
    match: PropTypes.object.isRequired,
};
