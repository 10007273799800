/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

// must go before plugins
import FullCalendar from '@fullcalendar/react';
// eslint-disable-next-line import-helpers/order-imports
import interactionPlugin from '@fullcalendar/interaction';
// eslint-disable-next-line import-helpers/order-imports
import timeGridPlugin from '@fullcalendar/timegrid';
import { format, getDay, addDays } from 'date-fns';
import PropTypes from 'prop-types';

import getDateLocale from '~/util/GetDateLocale';

import { Container } from './styles';

export default function WeekView({
    timeslots,
    editable,
    onSlotClick,
    onSlotResize,
    onSlotDrag,
}) {
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const now = new Date();

    function getFormattedDate(date, diff = 0) {
        const desired_date = addDays(date, -diff);

        return format(desired_date, 'yyyy-MM-dd');
    }

    function parseTimeslots() {
        const today_day = getDay(now);
        const events = [];

        Object.keys(timeslots).forEach(d => {
            const day = timeslots[d];

            const slot_day = getFormattedDate(now, today_day - d);
            const slots = day.slots || [];

            slots.forEach(s => {
                const { id, start, end } = s;
                const start_dt = `${slot_day} ${start}`;
                const end_dt = `${slot_day} ${end}`;
                const event = {
                    id,
                    groupId: d,
                    start: start_dt,
                    end: end_dt,
                };
                events.push(event);
            });
        });

        return events;
    }

    const week_events = parseTimeslots();

    function getEventID(e) {
        return e.id;
    }

    function eventClick(e) {
        const event_id = getEventID(e.event);
        onSlotClick(event_id);
    }

    function eventResize(e) {
        const event_id = getEventID(e.event);
        onSlotResize(event_id);
    }

    function eventDrag(e) {
        const event_id = getEventID(e.event);
        onSlotDrag(event_id);
    }

    return (
        <Container>
            <FullCalendar
                nowIndicator
                locale={date_loc}
                headerToolbar={false}
                initialView="timeGridWeek"
                plugins={[timeGridPlugin, interactionPlugin]}
                allDaySlot={false}
                dayHeaderFormat={{
                    weekday: 'long',
                }}
                slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false,
                    hour12: false,
                }}
                slotDuration="02:00:00"
                contentHeight="auto"
                editable={editable}
                events={week_events}
                eventClick={e => eventClick(e)}
                eventResizeStop={e => eventResize(e)}
                eventDragStop={e => eventDrag(e)}
                snapDuration="00:05"
            />
        </Container>
    );
}

WeekView.propTypes = {
    timeslots: PropTypes.object,
    onSlotClick: PropTypes.func,
    onSlotResize: PropTypes.func,
    onSlotDrag: PropTypes.func,
    editable: PropTypes.bool,
};

WeekView.defaultProps = {
    editable: false,
    timeslots: {},
    onSlotClick: () => {},
    onSlotResize: () => {},
    onSlotDrag: () => {},
};
