export function updateProfileRequest(data) {
    return {
        type: '@user/UPDATE_PROFILE_REQUEST',
        payload: { data },
    };
}

export function updateProfileSuccess({ profile }) {
    return {
        type: '@user/UPDATE_PROFILE_SUCCESS',
        payload: { profile },
    };
}

export function updateProfileFailure() {
    return {
        type: '@user/UPDATE_PROFILE_FAILURE',
    };
}

export function loadGroupsRequest() {
    return {
        type: '@user/LOAD_GROUPS_REQUEST',
    };
}

export function loadGroupsSuccess({ groups, admin, newActive }) {
    return {
        type: '@user/LOAD_GROUPS_SUCCESS',
        payload: { groups, admin, active: newActive },
    };
}

export function loadGroupsFailure() {
    return {
        type: '@user/LOAD_GROUPS_FAILURE',
    };
}
