import { shade } from 'polished';
import styled from 'styled-components';

export const TimelinePanel = styled.div`
    height: ${props => {
        const closed_height = props.smallScreen ? '23vh' : '100%';
        return props.open ? '100%' : closed_height;
    }};
    width: ${props => {
        return props.smallScreen ? '100%' : 'calc(15% + 20px)';
    }};
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.97, color);
    }};
    border: 1px solid #555555;
    border-style: ${props => {
        return props.smallScreen
            ? 'solid solid solid solid'
            : 'none none none solid';
    }};
    margin: ${props => {
            return props.smallScreen ? '0px' : '30px';
        }}
        0px;
`;

export const TitleDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => {
            return props.smallScreen ? '10px' : '3vh';
        }}
        20px;

    h3 {
        font-weight: bold;
        color: #eee;
        text-align: center;
        margin: 0px;
        font-size: 1.75vh;
    }
`;

export const ListDiv = styled.div`
    height: ${props => {
        const closed_height = props.smallScreen ? '25vh' : '100px';
        return props.open ? '100%' : closed_height;
    }};
    width: 100%;
    padding-bottom: ${props => {
        const min_height = props.smallScreen ? '5vh' : '200px';
        return props.open ? '200px' : min_height;
    }};
`;
