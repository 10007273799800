/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function PluginTypes() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);

    const [types, setTypes] = useState([]);
    const [currItem, setCurrItem] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`types?group=plugin`)
            .then(response => {
                const data = response.data.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        updated: formatDistance(
                            new Date(t.updated),
                            new Date(),
                            {
                                addSuffix: true,
                                locale: date_loc,
                            }
                        ),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setTypes(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/plugintypes/${id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteType() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`types/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.plugin_type.list.delete_success" />
                );
            })
            .catch(error => {
                requestError(error);
            });
        setIsLoading(false);
        loadItems();
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={types}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.plugin_type.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteType(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.plugin_type.title" />}
            currentPage={<LocaleMessage msg="page.plugin_type.title" />}
            breadcrumbs={[]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="body-top-controls">
                    <NewItemButton
                        link="/plugintypes/new"
                        text={<LocaleMessage msg="page.plugin_type.list.add" />}
                    />
                </div>
                <>{buildListView()}</>
            </>
        </PageContent>
    );
}
