import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
    .toast-container {
        opacity: 0.9;
        height: 80px;
        padding: 20px;
        font-weight: bold;
    }
`;
