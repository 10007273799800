/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from './styles';

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
            textAlign: 'left',
        },
    };
});

export default function AppConfigRobotType({
    robotTypes,
    currType,
    setCurrType,
    children,
    className,
    forceRobotTitle,
}) {
    const classes = useStyles();

    const handleChangeTab = (event, newValue) => {
        const r_type = robotTypes.find(t => {
            return t.slug === newValue;
        });

        setCurrType(r_type);
    };

    return forceRobotTitle || robotTypes.length > 1 ? (
        <Container className={className}>
            <>
                <Tabs
                    indicatorColor="primary"
                    value={currType.slug}
                    onChange={handleChangeTab}
                    className={classes.tabs}
                    centered
                >
                    {robotTypes.map(s => {
                        return (
                            <Tab key={s.slug} label={s.name} value={s.slug} />
                        );
                    })}
                </Tabs>

                <div className="pt-5">{children}</div>
            </>
        </Container>
    ) : (
        <div className={className}>
            <div className="pt-3">{children}</div>
        </div>
    );
}

AppConfigRobotType.propTypes = {
    robotTypes: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired,
    currType: PropTypes.object,
    setCurrType: PropTypes.func.isRequired,
    className: PropTypes.string,
    forceRobotTitle: PropTypes.bool,
};

AppConfigRobotType.defaultProps = {
    currType: {},
    className: 'col-12',
    forceRobotTitle: false,
};
