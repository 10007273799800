/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import { MdSearch, MdClose } from 'react-icons/md';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import { TextField, IconButton, InputAdornment } from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import RobotItem from '../../Components/RobotItem';
import { ListPanel, SearchDiv, ListDiv } from './styles';

export default function RobotList({
    robots,
    robotTypes,
    status,
    currentActive,
    onItemClick,
    smallScreen,
}) {
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState(robots);

    function desc(a, b) {
        const a_status =
            status[a.id] &&
            status[a.id].status &&
            status[a.id].status !== 'disconnected'
                ? 1
                : 0;
        const b_status =
            status[b.id] &&
            status[b.id].status &&
            status[b.id].status !== 'disconnected'
                ? 1
                : 0;
        if (a_status === b_status) {
            if (b.name < a.name) {
                return -1;
            }
            if (b.name > a.name) {
                return 1;
            }
            return 0;
        }
        return a_status > b_status ? 1 : -1;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const _order = cmp(a[0], b[0]);
            if (_order !== 0) return _order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function orderRobots(list) {
        return stableSort(list, (a, b) => -desc(a, b));
    }

    function filterData(val) {
        if (val === '') {
            const ordered_list = orderRobots(robots);
            setFilteredData(ordered_list);
            return;
        }
        const f = val.toUpperCase();
        const f_data = robots.filter(r => {
            const { name, group, type } = r;
            return (
                name.toUpperCase().includes(f) ||
                (type && type.name.toUpperCase().includes(f)) ||
                (group && group.name.toUpperCase().includes(f))
            );
        });
        const ordered_list = orderRobots(f_data);
        setFilteredData(ordered_list);
    }

    useEffect(() => {
        filterData(filter);
    }, [robots, status]);

    const waitForFilter = useCallback(
        debounce(val => filterData(val), 300),
        [robots]
    );

    function handleFilterChange(val) {
        setFilter(val);
        waitForFilter(val);
    }

    return (
        <ListPanel smallScreen={smallScreen}>
            <SearchDiv>
                <h3>
                    <LocaleMessage msg="rc3.robot.list.title" />
                </h3>
                <TextField
                    className="filter-field"
                    value={filter || ''}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdSearch size={20} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {filter !== '' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleFilterChange('')
                                            }
                                        >
                                            <MdClose />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null}
                            </>
                        ),
                    }}
                    onChange={event => handleFilterChange(event.target.value)}
                />
            </SearchDiv>
            <ListDiv>
                <CustomScrollbars>
                    <ul>
                        {filteredData.map(robot => {
                            const stt =
                                status && status[robot.id]
                                    ? status[robot.id]
                                    : null;
                            const { type } = robot;
                            const type_id = type && type.id ? type.id : null;
                            return (
                                <li key={robot.id}>
                                    <RobotItem
                                        robot={robot}
                                        type={robotTypes[type_id] || {}}
                                        status={stt}
                                        active={currentActive === robot.id}
                                        onItemClick={(event, r) => {
                                            onItemClick(event, r);
                                        }}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </CustomScrollbars>
            </ListDiv>
        </ListPanel>
    );
}

RobotList.propTypes = {
    smallScreen: PropTypes.bool,
    robots: PropTypes.array,
    robotTypes: PropTypes.object,
    status: PropTypes.object,
    onItemClick: PropTypes.func,
    currentActive: PropTypes.string,
};

RobotList.defaultProps = {
    smallScreen: false,
    robots: [],
    robotTypes: {},
    status: {},
    onItemClick: () => {},
    currentActive: '',
};
