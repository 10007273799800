import styled from 'styled-components';

export const PreviewArea = styled.div`
    border: 2px solid #999;
    padding: 10px;
    width: 100%;
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #999;
    width: 100%;
    height: ${props => {
        const w = props.dimensions.width;
        const h = (4.0 / 3.0) * w;
        return `${parseInt(h, 10) || 0}px`;
    }};

    background: ${props => {
        const { colors } = props;
        const c1 = colors ? colors.top : '#000';
        const c2 = colors ? colors.bottom : '#000';
        return `linear-gradient(180deg, ${c1}, ${c2})`;
    }};

    font-family: 'Saira Semi Condensed', sans-serif !important;
    color: #eee;

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.85, 10) || 0}px`;
        }};
        margin-top: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.02, 10) || 0}px`;
        }};
    }

    .logo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.1, 10) || 0}px`;
        }};
        max-height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.1, 10) || 0}px`;
        }};

        img {
            max-width: 80%;
            height: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.1, 10) || 0}px`;
            }};

            &.pluginbot-logo {
                max-width: 60%;
                width: ${props => {
                    const ps_logo = props.showPSLogo;
                    return ps_logo ? '20%' : '50%';
                }};
            }

            &.pluginpace-logo {
                height: ${props => {
                    const h = props.dimensions.height;
                    return `${parseInt(h * 0.1, 10) || 0}px`;
                }};
                max-width: 80%;
            }
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        min-height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.45, 10) || 0}px`;
        }};
        max-height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.75, 10) || 0}px`;
        }};

        .standby_text {
            max-width: 80%;
            text-align: center;
            font-weight: bold;
            word-wrap: break-word;
            font-size: ${props => {
                const h = props.dimensions.height;
                const s = props.text_size || 'medium';
                const sizes = {
                    small: 0.03,
                    medium: 0.05,
                    large: 0.065,
                };
                return `${parseInt(h * sizes[s], 10) || 0}px`;
            }};
            margin-bottom: 0px;
            color: ${props => {
                const { colors } = props;
                return colors && colors.text ? colors.text : '#ffffff';
            }};
        }

        .img_container {
            width: 80%;
            max-height: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.4, 10) || 0}px`;
            }};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
                width: ${props => {
                    const w = props.media_width;
                    return `${w}%`;
                }};
                object-fit: contain;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.13, 10) || 0}px`;
        }};
        background-color: #333;
    }
`;
