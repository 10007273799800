/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import Forbidden from '~/components/Forbidden';
import Header from '~/components/Header';
import LocaleMessage from '~/components/LocaleMessage';

import menuApps from '~/config/Apps';
import { changeAppRequest } from '~/store/modules/application/actions';

import { AppContainer, Container, Body } from './styles';

export default function SUView(props) {
    const { children } = props;

    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings);
    const ps_settings =
        settings && settings.pluginspace ? settings.pluginspace : {};

    const main_app = menuApps.getMainApp();

    const [forbidden, setForbidden] = useState(
        ps_settings &&
            ps_settings.subdomain &&
            ps_settings.subdomain !== 'admin'
    );
    const [navCollapsed, setNavCollapsed] = useState(false);

    useEffect(() => {
        if (forbidden) {
            setTimeout(() => {
                dispatch(changeAppRequest(main_app));
            }, 5000);
        }
    }, [forbidden]);

    function isDesktop() {
        return window.innerWidth > 1024;
    }

    const drawerStyle = isDesktop() ? 'mini-drawer' : 'collapsible-drawer';

    function onToggleCollapsedNav() {
        setNavCollapsed(!navCollapsed);
    }

    return (
        <>
            <AppContainer>
                <Header
                    selectGroup
                    drawer={drawerStyle}
                    toggleCollapsedNav={() => onToggleCollapsedNav()}
                />
                <>
                    {forbidden ? (
                        <Forbidden
                            showHome={false}
                            body={
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <h3 className="text-center fw-regular title bounceIn animation-delay-10 animated mb-5">
                                        <span>
                                            <LocaleMessage msg="message.redirect.platform" />
                                        </span>
                                    </h3>
                                    <ReactLoading
                                        type="bars"
                                        color="#c8c8c8"
                                        height={80}
                                        width={80}
                                    />
                                </div>
                            }
                        />
                    ) : (
                        <>
                            <Container className="app-main-container">
                                <Body>
                                    {{
                                        ...children,
                                        props: {
                                            ...children.props,
                                            blockUser: () => setForbidden(true),
                                        },
                                    }}
                                </Body>
                            </Container>
                        </>
                    )}
                </>
            </AppContainer>
        </>
    );
}

SUView.propTypes = {
    children: PropTypes.element.isRequired,
};

SUView.defaultProps = {};
