import { takeLatest, put, all } from 'redux-saga/effects';

import { switchView } from './actions';

export function changeView({ payload }) {
    const pages = payload && payload.pages ? payload.pages : {};
    put(switchView(pages));
}

export default all([takeLatest('persist/REHYDRATE', changeView)]);
