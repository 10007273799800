import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    .counter-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 5px solid ${props => props.theme.palette.primary.main};

        .card-heading {
            color: ${props => props.theme.palette.primary.main};
        }

        .value {
            font-weight: bold;
            font-size: 42px;
            color: ${props => props.theme.palette.primary.main};
        }
    }
`;
