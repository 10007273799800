import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, TextField } from '@material-ui/core';

import FormPasswordInput from '~/components/Form/PasswordInput';
import LocaleMessage from '~/components/LocaleMessage';

import pbLogo from '~/assets/logo-dark.png';
import pbLogoW from '~/assets/logo-white.png';
import api from '~/services/pluginbot-api';
import {
    signInRequest,
    signInSuccess,
    signFailure,
} from '~/store/modules/auth/actions';

import { Container } from './styles';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const { loading, logo, pluginspace } = settings;

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        dispatch(signInRequest());

        await api
            .post(`/auth/pluginspace`, {
                pluginspace: pluginspace.id,
                email,
                password,
            })
            .then(response => {
                const { token, user, app } = response.data;
                dispatch(signInSuccess(token, user, app));
            })
            .catch(error => {
                requestError(error);
                dispatch(signFailure());
            });
    }

    return (
        <>
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-login-content">
                        <img
                            src={pbLogo}
                            alt="pluginbot"
                            title="pluginbot"
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                maxWidth: 100,
                                maxHeight: 150,
                            }}
                        />
                        <div className="app-login-form">
                            <form onSubmit={event => handleSubmit(event)}>
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.login.email" />
                                    }
                                    fullWidth
                                    defaultValue={email}
                                    onChange={event =>
                                        setEmail(event.target.value)
                                    }
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <FormPasswordInput
                                    label={
                                        <LocaleMessage msg="page.login.password" />
                                    }
                                    fullWidth
                                    value={password}
                                    onChange={event =>
                                        setPassword(event.target.value)
                                    }
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <Container className="mt-3 mb-3 d-flex">
                                    <div
                                        className={`${
                                            pluginspace && !loading
                                                ? 'two-items'
                                                : 'one-item'
                                        }`}
                                    >
                                        {pluginspace && !loading ? (
                                            <div className="vertical-items">
                                                <Link
                                                    to={`/recover/${pluginspace.id}`}
                                                    className="mb-3"
                                                >
                                                    <LocaleMessage msg="page.login.forgot" />
                                                </Link>
                                                <Link to="/register">
                                                    <LocaleMessage msg="page.login.signup" />
                                                </Link>
                                            </div>
                                        ) : null}
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {loading ? (
                                                <LocaleMessage msg="page.login.loading" />
                                            ) : (
                                                <LocaleMessage msg="page.login.signin" />
                                            )}
                                        </Button>
                                    </div>
                                </Container>
                            </form>
                        </div>
                    </div>

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <img
                            src={logo && logo.url ? logo.url : pbLogoW}
                            alt="Pluginspace Logo"
                            title="Pluginspace Logo"
                            style={{ maxWidth: 200, maxHeight: 200 }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
