/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy, MdChatBubble, MdCompress } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Tooltip,
    Fab,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import FormCheckList from '~/components/Form/CheckList';
import MultipleInputs from '~/components/Form/MultipleInputs';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import QnANodeContext from './QnANodeContext';
import QnATest from './QnATest';
import QnATrigger from './QnATrigger';
import { Container, FlowArea, ParameterArea, Panel } from './styles';
import TreeView from './TreeView';

export default function QnANodes({
    handleError,
    dialogSettings,
    workingVersion,
    answerLanguages,
}) {
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';

    const { id: dialog_id, language: dialog_lng } = dialogSettings;

    const [versionContents, setVersionContents] = useState({});
    const [versionNodes, setVersionNodes] = useState({});

    const [body, setBody] = useState({});
    const [childrenOptions, setChildrenOptions] = useState([]);
    const [currNode, setCurrNode] = useState(null);
    const [currLang, setCurrLang] = useState(dialog_lng);
    const [openBranches, setOpenBranches] = useState({});
    const [selectedFlow, setSelectedFlow] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [internalLoading, setInternalLoading] = useState(true);

    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [showNodeDialog, setShowNodeDialog] = useState('');
    const [showAnswerDialog, setShowAnswerDialog] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(true);
    const [showTestArea, setShowTestArea] = useState(false);

    async function loadDialogVersionContents() {
        if (!dialog_id || !workingVersion) {
            setVersionContents({});
            return;
        }
        await api
            .get(
                `dialogs/${dialog_id}/versions/${workingVersion}/contents?language=${dialog_lng}`
            )
            .then(response => {
                const list = response.data;
                const contents = {};
                const mapped = {};

                list.forEach(c => {
                    const c_type = c.type;
                    if (!contents[c_type]) contents[c_type] = [];
                    const item = {
                        id: c.id,
                        identifier: c.identifier,
                        type: c_type,
                        display_name: c.display_name,
                        grouping: c.grouping,
                    };

                    contents[c_type].push(item);
                    mapped[c.id] = item;
                });

                setVersionContents(contents);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function loadNodes() {
        // setIsLoading(true);
        if (!dialog_id || !workingVersion) {
            setVersionNodes({});
            return;
        }
        await api
            .get(`dialogs/${dialog_id}/versions/${workingVersion}/nodes`)
            .then(response => {
                const nodes = response.data;
                const n_ids = {};
                nodes.forEach(n => {
                    n_ids[n.id] = n;
                });

                setVersionNodes(n_ids);
            })
            .catch(error => {
                handleError(error);
            });
        setIsLoading(false);
    }

    async function loadNode(_id) {
        if (!dialog_id || !workingVersion) {
            return;
        }
        if (_id && _id !== 'new') {
            setInternalLoading(true);
            await api
                .get(
                    `dialogs/${dialog_id}/versions/${workingVersion}/nodes/${_id}`
                )
                .then(response => {
                    const ans = response.data;
                    setBody(ans);
                })
                .catch(error => {
                    setBody({});
                    handleError(error);
                });
        } else {
            setBody({});
        }

        setInternalLoading(false);
        setIsLoading(false);
    }

    async function loadAll() {
        await Promise.all([loadNodes(), loadDialogVersionContents()]);
    }

    async function createNode() {
        setInternalLoading(true);
        setShowNodeDialog('');

        const data = {
            ...body,
        };

        await api
            .post(`dialogs/${dialog_id}/versions/${workingVersion}/nodes`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        setBody({});
        loadAll();
        setInternalLoading(false);
    }

    async function updateNode() {
        setInternalLoading(true);
        setShowNodeDialog('');
        setShowAnswerDialog(false);

        const data = { ...body };

        await api
            .put(
                `dialogs/${dialog_id}/versions/${workingVersion}/nodes/${currNode.id}`,
                data
            )
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        setCurrNode(null);
        loadAll();
        setInternalLoading(false);
    }

    async function removeNode() {
        setShowRemoveDialog(false);
        setInternalLoading(true);
        setShowAnswerDialog(false);

        await api
            .delete(
                `dialogs/${dialog_id}/versions/${workingVersion}/nodes/${currNode.id}`
            )
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        setCurrNode(null);
        loadAll();
        setInternalLoading(false);
    }

    function handleCopy() {
        toast.info(<LocaleMessage msg="page.qna.nodes.list.node_id.copied" />);
    }

    async function requestSuggestions() {
        const body_settings = body.settings || {};
        const lng_settings = body_settings[currLang]
            ? body_settings[currLang]
            : {
                  ...(currLang !== dialog_lng && {
                      auto_translate: true,
                  }),
              };
        const l_texts = lng_settings.texts || [''];

        const data = { language: currLang, examples: l_texts };
        let added_examples = 0;
        setInternalLoading(true);
        await api
            .post(`/qna/generate/${currNode.id}/answers`, data)
            .then(r => {
                const generated = r.data;
                const suggestions =
                    generated && generated.suggestions
                        ? generated.suggestions
                        : [];
                suggestions.forEach(s => {
                    added_examples++;
                    l_texts.push(s);
                });
                setBody({
                    ...body,
                    settings: {
                        ...body_settings,
                        [currLang]: {
                            ...lng_settings,
                            texts: l_texts,
                        },
                    },
                });
                toast.success(`${added_examples} AI Examples Added`);
            })
            .catch(error => handleError(error));
        setInternalLoading(false);
    }

    useEffect(() => {
        setCurrLang(dialog_lng);
    }, [dialogSettings]);

    useEffect(() => {
        loadAll();
    }, [workingVersion]);

    useEffect(() => {
        loadNode(currNode && currNode.id ? currNode.id : 'new');
    }, [workingVersion, currNode]);

    function handleNodeActionClick(action, node) {
        const n_id = node.id;
        const actionHandlers = {
            add: () => {
                setShowNodeDialog('add');
                setChildrenOptions(node.children_type || []);
                setBody({
                    root: node.root,
                    condition_type:
                        node.children_type && node.children_type[0]
                            ? node.children_type[0]
                            : '',
                    contents: [],
                });
            },
            edit_children: () => {
                setCurrNode(node);
                setShowNodeDialog('children');
                setOpenBranches({
                    ...openBranches,
                    [n_id]: true,
                });
            },
            edit: () => {
                setCurrNode(node);
                setShowAnswerDialog(true);
            },
            delete: () => {
                setCurrNode(node);
                setShowRemoveDialog(true);
            },
            expand: () => {
                setOpenBranches({
                    ...openBranches,
                    [n_id]: !openBranches[n_id],
                });
            },
            null: () => {},
        };

        const handler = actionHandlers[action];
        if (handler) handler(n_id);
    }

    function handleTestAnswer(nodes) {
        const open = { ...openBranches };
        const selected = ['start', ...nodes];

        let n_id = nodes.pop();
        let steps = 1;

        const subject_list = versionContents.subject || [];

        const subject_keys = {};
        subject_list.forEach(s => {
            subject_keys[s.id] = s;
        });

        while (n_id && steps < 10) {
            selected.push(n_id);
            open[n_id] = true;
            steps++;

            const c_node = versionNodes[n_id];
            if (c_node) {
                if (c_node.root) {
                    n_id = c_node.root;
                } else {
                    n_id = '';
                    switch (c_node.condition_type) {
                        case 'intent':
                            selected.push('intents');
                            open.intents = true;
                            break;
                        case 'subject': {
                            const { subject } = c_node;
                            const subject_data = subject_keys[subject] || {};
                            const { grouping } = subject_data;
                            if (grouping) {
                                const g_key = grouping.replace(' ', '');
                                selected.push(g_key);
                                open[g_key] = true;
                            }
                            break;
                        }

                        default:
                            break;
                    }
                }
            } else {
                n_id = '';
            }
        }
        setSelectedFlow(selected);
        setOpenBranches(open);
    }

    function setLangSettings(l, key, val) {
        const body_settings = body.settings || {};
        const lng_settings = body_settings[currLang]
            ? body_settings[currLang]
            : {};
        setBody({
            ...body,
            settings: {
                ...body_settings,
                [l]: {
                    ...lng_settings,
                    [key]: val,
                },
            },
        });
    }

    function handleDialogsClose() {
        setShowAnswerDialog(false);
    }

    function renderTextAnswer() {
        const body_settings = body.settings || {};
        const lng_settings = body_settings[currLang]
            ? body_settings[currLang]
            : {
                  ...(currLang !== dialog_lng && {
                      auto_translate: true,
                  }),
              };
        const l_texts = lng_settings.texts || [''];
        const l_label = `list.languages.${currLang}`;
        const non_empty = l_texts.filter(t => !!t);

        return (
            <>
                <ParameterArea className="col-12 row mt-1 py-1 px-1">
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            className="col-12 row"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="col-md-4 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.qna.nodes.form.answer.language" />
                                    </InputLabel>
                                    <Select
                                        value={currLang || dialog_lng}
                                        onChange={event =>
                                            setCurrLang(event.target.value)
                                        }
                                    >
                                        {answerLanguages.map(l => (
                                            <MenuItem
                                                value={l}
                                                key={`ans_lng_${l}`}
                                            >
                                                <LocaleMessage
                                                    msg={`list.languages.${l}`}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {currLang !== dialog_lng ? (
                                <div className="col-md-8 col-12">
                                    <FormSwitch
                                        value={
                                            lng_settings.auto_translate || false
                                        }
                                        onChange={event =>
                                            setLangSettings(
                                                currLang,
                                                'auto_translate',
                                                event.target.checked
                                            )
                                        }
                                        label={
                                            <LocaleMessage msg="page.qna.nodes.form.answer.translate" />
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {currLang === dialog_lng || !lng_settings.auto_translate ? (
                        <>
                            <strong
                                className="col-12 mb-3"
                                style={{ textAlign: 'center' }}
                            >
                                <LocaleMessage msg="page.qna.nodes.form.answer.text.random" />
                            </strong>
                            <div className="col-12 mb-3">
                                <MultipleInputs
                                    margin="mb-1"
                                    key={`lang_input_${currLang}`}
                                    language={currLang}
                                    label={l_label}
                                    texts={l_texts}
                                    open
                                    allowSuggestions={non_empty.length >= 1}
                                    onChange={t => {
                                        setLangSettings(currLang, 'texts', t);
                                    }}
                                    suggestItems={() => {
                                        requestSuggestions();
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </ParameterArea>
            </>
        );
    }

    function renderContextVariables() {
        const body_settings = body.settings || {};
        const ctxt_settings = body_settings.context || {};

        return (
            <>
                <ParameterArea className="col-12 row mt-1 py-1 px-1">
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <>
                            <ParameterArea className="col-12 row mt-1 py-1 px-1">
                                <QnANodeContext
                                    settings={ctxt_settings}
                                    saveSettings={s => {
                                        setBody({
                                            ...body,
                                            settings: {
                                                ...body_settings,
                                                context: s,
                                            },
                                        });
                                    }}
                                />
                            </ParameterArea>
                        </>
                    </div>
                </ParameterArea>
            </>
        );
    }

    function renderAnswerForm() {
        const after_actions = {
            intent: ['return_start', 'wait_decision'],
            question: ['return_start', 'wait_decision'],
            decision: ['return_start', 'retry_decision'],
            topic: [],
            all: ['return_start'],
        };
        const condition_type = body.condition_type || 'all';
        const available_actions = after_actions[condition_type]
            ? after_actions[condition_type]
            : after_actions.all;

        return internalLoading ? (
            <Splash />
        ) : (
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="col-md-4 col-12">
                        <FormControl fullWidth className="mb-4">
                            <InputLabel>
                                <LocaleMessage msg="page.qna.nodes.form.action" />
                            </InputLabel>
                            <Select
                                value={body.type || 'text'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        type: event.target.value,
                                    })
                                }
                            >
                                {condition_type === 'decision' ? (
                                    <MenuItem value="search_answer">
                                        <LocaleMessage msg="page.qna.nodes.form.action.search_answer" />
                                    </MenuItem>
                                ) : null}
                                <MenuItem value="text">
                                    <LocaleMessage msg="page.qna.nodes.form.action.text" />
                                </MenuItem>
                                <MenuItem value="action">
                                    <LocaleMessage msg="page.qna.nodes.form.action.trigger" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {!body.type || body.type === 'text'
                        ? renderTextAnswer()
                        : null}

                    {renderContextVariables()}

                    {body.type === 'action' ? (
                        <QnATrigger
                            dialogSettings={dialogSettings}
                            config={body.trigger || {}}
                            onChange={t =>
                                setBody({
                                    ...body,
                                    trigger: t,
                                })
                            }
                            handleError={e => handleError(e)}
                        />
                    ) : null}

                    {body.type &&
                    body.type === 'text' &&
                    available_actions.length > 0 ? (
                        <div className="mt-4 col-md-4 col-12">
                            <FormControl fullWidth className="mb-4">
                                <InputLabel>
                                    <LocaleMessage msg="page.qna.nodes.form.after" />
                                </InputLabel>
                                <Select
                                    value={
                                        body.after_response || 'return_start'
                                    }
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            after_response: event.target.value,
                                        })
                                    }
                                >
                                    {available_actions.map(a => {
                                        return (
                                            <MenuItem
                                                value={a}
                                                key={`after_action_${a}`}
                                            >
                                                <LocaleMessage
                                                    msg={`page.qna.nodes.form.after.${a}`}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    function renderAnswerDialog() {
        const title = (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <span>
                    <>
                        <LocaleMessage msg="page.qna.nodes.form.edit" />
                        <span>
                            {body && body.name !== 'ELSE'
                                ? ` - ${body.name}`
                                : ''}
                        </span>
                    </>
                </span>
                {body.id ? (
                    <CopyToClipboard text={body.id} onCopy={() => handleCopy()}>
                        <Button
                            variant="outlined"
                            startIcon={<MdContentCopy />}
                        >
                            <LocaleMessage msg="page.qna.nodes.list.copy_id" />
                        </Button>
                    </CopyToClipboard>
                ) : null}
            </div>
        );

        return (
            <SimpleDialog
                size="md"
                open={showAnswerDialog}
                onClose={handleDialogsClose}
                title={title}
                content={renderAnswerForm()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowAnswerDialog(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => updateNode(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderNodeChildrenForm() {
        const condition_type = body.condition_type || 'decision';

        const children_label = {
            subject: {
                yes: 'page.qna.nodes.form.bind_topics',
                no: 'page.qna.nodes.form.any_topic',
            },
            topic: {
                yes: 'page.qna.nodes.form.bind_questions',
                no: 'page.qna.nodes.form.any_question',
            },
        };

        const labels = children_label[condition_type] || {};

        return internalLoading ? (
            <Splash />
        ) : (
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ParameterArea
                        style={{
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FormControl className="mt-3 mb-3">
                            <RadioGroup
                                value={`${body.allow_children || ''}`}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        allow_children: event.target.value,
                                    })
                                }
                            >
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    value=""
                                    label={<LocaleMessage msg={labels.no} />}
                                />
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    value="true"
                                    label={<LocaleMessage msg={labels.yes} />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </ParameterArea>
                </div>
            </div>
        );
    }

    function renderNodeForm() {
        const condition_type = body.condition_type || 'decision';
        const type_contents =
            versionContents && versionContents[condition_type]
                ? versionContents[condition_type]
                : [];
        const current_children = Object.keys(versionNodes)
            .filter(n => {
                const node = versionNodes[n];
                return node.root === body.root;
            })
            .map(i => {
                return versionNodes[i];
            });
        const bound_contents = current_children.map(c => {
            const filters = {
                intent: 'intent',
                question: 'intent',
                decision: 'intent',
                subject: 'subject',
                topic: 'topic',
            };
            return c[filters[condition_type] || 'intent'];
        });
        const filtered_contents = type_contents.filter(c => {
            return !bound_contents.includes(c.id) && c.identifier !== 'SUBJECT';
        });

        return internalLoading ? (
            <Splash />
        ) : (
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ParameterArea
                        style={{
                            padding: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div className="col-md-4 col-12 mt-3">
                            <FormControl fullWidth className="mb-4">
                                <InputLabel>
                                    <LocaleMessage msg="page.qna.nodes.form.condition_type" />
                                </InputLabel>
                                <Select
                                    value={condition_type || ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            condition_type: event.target.value,
                                        })
                                    }
                                >
                                    {childrenOptions.map(t => {
                                        return (
                                            <MenuItem
                                                key={`children_type_${t}`}
                                                value={t}
                                            >
                                                <LocaleMessage
                                                    msg={`page.qna.content.${t}`}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {filtered_contents.length > 0 ? (
                            <FormCheckList
                                settings={body}
                                options={filtered_contents}
                                listKey="contents"
                                primaryLabelKey="display_name"
                                onChange={c => {
                                    setBody({
                                        ...body,
                                        contents: c.contents,
                                    });
                                }}
                            />
                        ) : (
                            <>
                                <span className="mt-3 mb-3">
                                    <LocaleMessage msg="page.qna.nodes.form.bind_contents.empty" />
                                </span>
                            </>
                        )}
                    </ParameterArea>
                </div>
            </div>
        );
    }

    function renderNodeDialog(type) {
        const contents = {
            add: {
                title: <LocaleMessage msg="page.qna.nodes.list.add" />,
                body: renderNodeForm(),
                action: {
                    key: 'submit',
                    onClick: () => createNode(),
                    label: <LocaleMessage msg="button.create" />,
                },
            },
            children: {
                title: <LocaleMessage msg="page.qna.nodes.form.edit" />,
                body: renderNodeChildrenForm(),
                action: {
                    key: 'submit',
                    onClick: () => updateNode(),
                    label: <LocaleMessage msg="button.save" />,
                },
            },
        };

        const node_form = contents[type] || contents.add;

        return (
            <SimpleDialog
                size="md"
                open={!!showNodeDialog}
                onClose={() => setShowNodeDialog('')}
                title={node_form.title}
                content={node_form.body}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowNodeDialog(''),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    node_form.action,
                ]}
            />
        );
    }

    function renderRemoveDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showRemoveDialog}
                onClose={() => setShowRemoveDialog(false)}
                title={<LocaleMessage msg="page.qna.nodes.list.delete" />}
                content={null}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowRemoveDialog(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => removeNode(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderFlowView() {
        return (
            <FlowArea isFullScreen={showFullScreen}>
                <Panel>
                    <div
                        className={`col-md-${
                            showFullScreen && showTestArea ? '9' : '12'
                        } col-12`}
                        style={{ padding: '0px' }}
                    >
                        {versionNodes ? (
                            <TreeView
                                data={{
                                    nodes: versionNodes,
                                    contents: versionContents,
                                }}
                                labelLanguage={lang}
                                isFullScreen={showFullScreen}
                                selected={selectedFlow}
                                openBranches={openBranches}
                                onNodeAction={(a, n) =>
                                    handleNodeActionClick(a, n)
                                }
                            />
                        ) : null}
                        <div className="flow-buttons">
                            <div className="col-1">
                                {!showTestArea ? (
                                    <Tooltip
                                        title={
                                            <LocaleMessage msg="page.qna.nodes.test.title" />
                                        }
                                        placement="left"
                                    >
                                        <Fab
                                            className="mb-3"
                                            color="primary"
                                            onClick={() => {
                                                setShowTestArea(true);
                                                setShowFullScreen(true);
                                            }}
                                        >
                                            <MdChatBubble
                                                style={{ color: '#fff' }}
                                                size={24}
                                            />
                                        </Fab>
                                    </Tooltip>
                                ) : null}
                                <Tooltip
                                    title={
                                        <LocaleMessage msg="page.qna.nodes.list.collapse" />
                                    }
                                    placement="left"
                                >
                                    <Fab
                                        className="mb-3"
                                        color="primary"
                                        onClick={() => {
                                            setOpenBranches({});
                                        }}
                                    >
                                        <MdCompress
                                            style={{ color: '#fff' }}
                                            size={24}
                                        />
                                    </Fab>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {showFullScreen && showTestArea ? (
                        <div className="col-md-3 col-12 p-0">
                            <QnATest
                                languages={answerLanguages}
                                nodes={versionNodes}
                                version={workingVersion}
                                showAnswerFlow={nodes =>
                                    handleTestAnswer(nodes)
                                }
                                handleError={e => handleError(e)}
                                closeArea={() => {
                                    setSelectedFlow([]);
                                    setShowTestArea(false);
                                }}
                            />
                        </div>
                    ) : null}
                </Panel>
            </FlowArea>
        );
    }

    return (
        <>
            {/* <div className="col-12 row" style={{ alignItems: 'center' }}>
                <div className="sidecard-header col-9">
                    <h2>
                        <LocaleMessage msg="page.qna.nodes.list.title" />
                    </h2>
                </div>
            </div> */}

            {isLoading ? (
                <Splash
                    label={
                        <div className="mb-5">
                            <LocaleMessage msg="page.qna.content.training.loading" />
                        </div>
                    }
                    labelPosition="top"
                />
            ) : (
                <Container>
                    <>{renderFlowView()}</>
                    {showRemoveDialog ? renderRemoveDialog() : null}
                    {showNodeDialog ? renderNodeDialog(showNodeDialog) : null}
                    {showAnswerDialog ? renderAnswerDialog() : null}
                </Container>
            )}
        </>
    );
}

QnANodes.defaultProps = {
    workingVersion: '',
    answerLanguages: [],
};

QnANodes.propTypes = {
    workingVersion: PropTypes.string,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
    answerLanguages: PropTypes.array,
};
