import styled from 'styled-components';

export const MediaSelector = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MediaArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        aspect-ratio: 16 / 9;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .audio-card {
        padding: 0px 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #000;

        p {
            color: #fff;
            margin: 2px;
        }

        audio {
            width: 100%;
        }
    }
`;

export const OptionArea = styled.div`
    width: 100%;

    .area-content {
        background-color: #eee;
        width: 100%;
        padding: 10px;
        margin: 10px 0px;
    }

    .option-row {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .option-card {
        margin: 10px 0px;
        width: 100%;
    }
`;

export const OptionCard = styled.div`
    .card-body {
        min-height: 200px;
        width: 100%;
        display: flex;
        align-items: flex-top;
        justify-content: space-between;
        padding: 10px;
    }

    .card-image {
        background: #eee;
        min-height: 200px;
        padding: 0px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;

            :hover {
                cursor: pointer;
            }
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-around;
    }
`;

export const NoImageButton = styled.div`
    background: rgba(0, 0, 0, 0.03);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    cursor: pointer;

    :hover {
        opacity: 0.7;
    }
`;

export const ParameterArea = styled.div`
    padding: 20px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }
`;

export const DeleteParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }
`;

export const ExitSettingsArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const Option = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
`;
