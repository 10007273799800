/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSpellcheck, MdDelete, MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormLabel,
    FormControlLabel,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { ParameterArea, DeleteParameter } from './styles';

export default function VisualTemplateForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        group: 'validation',
    });
    const [file, setFile] = useState({
        id: null,
        url: null,
    });
    const [options, setOptions] = useState([]);
    const [usage, setUsage] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const types = [
        {
            label: (
                <LocaleMessage msg="page.visual_template.type.conversation" />
            ),
            value: 'conversation',
        },
        {
            label: (
                <LocaleMessage msg="page.visual_template.type.survey_step" />
            ),
            value: 'survey_step',
        },
    ];

    const step_types = [
        {
            label: <LocaleMessage msg="page.survey_steps.type.start" />,
            value: 'start',
        },
        {
            label: <LocaleMessage msg="page.survey_steps.type.question" />,
            value: 'question',
        },
        {
            label: <LocaleMessage msg="page.survey_steps.type.input" />,
            value: 'input',
        },
        {
            label: <LocaleMessage msg="page.survey_steps.type.display" />,
            value: 'display',
        },
        {
            label: <LocaleMessage msg="page.survey_steps.type.end" />,
            value: 'end',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadTemplate(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`templates/${_id}`)
                .then(response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        slug: t.slug,
                        type: t.type,
                    });
                    const t_settings = t.settings || {};
                    setOptions(t_settings.options || []);
                    setUsage(t_settings.usage || {});
                    setFile(t.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadTemplate(id);
    }, [id]);

    async function updateTemplate(data) {
        await api
            .put(`templates/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.visual_template.form.update_success" />
                );
                setIsLoading(false);
                loadTemplate(id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body, settings: { options, usage } };

        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`templates`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.visual_template.form.create_success" />
                    );

                    history.push(`/visual_templates/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateTemplate(data);
        }
    }

    function handleSwitchChange(event, key) {
        setUsage({
            ...usage,
            [key]: event.target.checked,
        });
    }

    function handleNewParameter() {
        const items = options || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            label: '',
            default: '',
        });
        setOptions([...items]);
    }

    function handleDeleteParameter(idx) {
        const old_items = options || [];
        const items = old_items.filter(c => {
            return c.item_id !== idx;
        });
        setOptions([...items]);
    }

    function onParameterChange(event, idx, key) {
        const items = options || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: event.target.value,
            };
        }
        setOptions([...items]);
    }

    function buildSurveyUsageList() {
        return (
            <>
                <FormLabel component="legend">
                    <LocaleMessage msg="page.visual_template.form.label.template_usage" />
                </FormLabel>
                <FormGroup>
                    {step_types.map(s => (
                        <FormControlLabel
                            key={s.value}
                            control={
                                <Switch
                                    color="primary"
                                    checked={
                                        usage[s.value] ? usage[s.value] : false
                                    }
                                    value={s.value}
                                    onChange={event =>
                                        handleSwitchChange(event, s.value)
                                    }
                                />
                            }
                            label={s.label}
                        />
                    ))}
                </FormGroup>
            </>
        );
    }

    function buildUsageList() {
        let list = null;

        switch (body.type) {
            case 'survey_step':
                list = buildSurveyUsageList();
                break;
            default:
                list = null;
        }

        return (
            <div className="col-md-6 col-12 mb-5">
                <FormControl component="fieldset">{list}</FormControl>
            </div>
        );
    }

    function buildParameterList(parameters) {
        return parameters.map((item, idx) => {
            return (
                <div className="col-12 row mb-3" key={item.item_id}>
                    <div className="col-7">
                        <TextField
                            label={
                                <LocaleMessage msg="page.visual_template.form.label.parameter_label" />
                            }
                            fullWidth
                            value={item && item.label ? item.label : ''}
                            onChange={event =>
                                onParameterChange(event, idx, 'label')
                            }
                        />
                    </div>
                    <div className="col-4">
                        <TextField
                            label={
                                <LocaleMessage msg="page.visual_template.form.label.default_value" />
                            }
                            fullWidth
                            value={item && item.default ? item.default : ''}
                            onChange={event =>
                                onParameterChange(event, idx, 'default')
                            }
                        />
                    </div>
                    <DeleteParameter className="col-1">
                        <MdDelete
                            size={18}
                            onClick={() => handleDeleteParameter(item.item_id)}
                        />
                    </DeleteParameter>
                </div>
            );
        });
    }

    function buildParameterForm() {
        return (
            <>
                <div className="col-12 mb-5">
                    <ParameterArea className="row mt-1 mb-5">
                        {buildParameterList(options)}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewParameter()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="label.form.add_parameter" />
                        </Button>
                    </ParameterArea>
                </div>
            </>
        );
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`templates/${id}/files`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id };
                setBody(updated);
                updateTemplate(updated);
            })
            .catch(error => requestError(error));
    };

    function fileInput() {
        return (
            <div
                className="col-12 mb-5"
                style={{ height: '200px', justifyContent: 'center' }}
            >
                <FileInput
                    defaultValue={file}
                    onFileUpload={onFileUpload}
                    multiple={false}
                    text={<LocaleMessage msg="label.form.change_image" />}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.visual_template.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.visual_template.form.edit.title" />
                )
            }
            currentPage={
                operation === 'create' ? (
                    <LocaleMessage msg="page.visual_template.form.create.title.short" />
                ) : (
                    <LocaleMessage msg="page.visual_template.form.edit.title.short" />
                )
            }
            breadcrumbs={[
                {
                    url: '/visual_templates',
                    title: <LocaleMessage msg="breadcrumbs.visual_templates" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.visual_template.list.title" />
                            }
                            Icon={MdSpellcheck}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <div className="col-md-6 col-12">
                                        <TextField
                                            className="mb-5"
                                            id="template-name"
                                            label={
                                                <LocaleMessage msg="page.visual_template.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                        <TextField
                                            className="mb-5"
                                            id="template-slug"
                                            label={
                                                <LocaleMessage msg="page.visual_template.form.label.code" />
                                            }
                                            fullWidth
                                            value={body.slug || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                        <FormControl className="mb-5" fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.visual_template.form.label.type" />
                                            </InputLabel>
                                            <Select
                                                id="template-type"
                                                value={
                                                    body.type ? body.type : ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        type:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    operation === 'update'
                                                }
                                            >
                                                {types.map(l => {
                                                    return (
                                                        <MenuItem
                                                            value={l.value}
                                                            key={l.value}
                                                        >
                                                            {l.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    {buildUsageList()}
                                    {operation === 'update' ? (
                                        <>{fileInput()}</>
                                    ) : null}
                                    {buildParameterForm()}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

VisualTemplateForm.propTypes = {
    match: PropTypes.object.isRequired,
};
