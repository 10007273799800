/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPinDrop } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Input,
    InputLabel,
    InputAdornment,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SVGMap from '~/components/SVGMap';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { ParameterArea } from './styles';

export default function MapPointForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id, layer_id, point_id } = match.params;

    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        map_id: id,
        map_layer_id: layer_id,
    });
    const [content, setContent] = useState({});
    const [apptypeSettings, setApptypeSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [appType, setAppType] = useState();
    const [illustration, setIllustration] = useState(null);
    const [drag, setDrag] = useState(null);
    const [position, setPosition] = useState({
        x: 500,
        y: 500,
    });
    const [robotType, setRobotType] = useState('*');

    const hours = [];
    const minutes = [];
    for (let i = 0; i <= 24; i++) {
        hours.push(i);
    }
    for (let i = 0; i < 12; i++) {
        minutes.push(i * 5);
    }

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPoint(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/map_points/${_id}`)
                .then(response => {
                    const p = response.data;
                    setBody({
                        name: p.name,
                        reference: p.reference,
                    });
                    setContent(p.content);
                    setPosition(p.position || { x: 500, y: 500 });
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadAppToolSettings(_id) {
        await api
            .get(`types/${_id}`)
            .then(async response => {
                const t = response.data;
                setAppType({
                    id: t.id,
                    name: t.name,
                    group: 'application',
                    slug: t.slug,
                });

                const settings = t.settings || {};
                const tool_settings = settings.tools || {};
                const point_key = Object.keys(tool_settings).find(s => {
                    const item = tool_settings[s];
                    return item.tool_key === 'map_point';
                });
                const point_settings = point_key
                    ? tool_settings[point_key]
                    : {};

                setApptypeSettings(point_settings.parameters);
            })
            .catch(error => requestError(error));
    }

    async function loadMapLayer() {
        await api
            .get(`map_layers/${layer_id}`)
            .then(response => {
                const l = response.data;
                const robot = l.robot_type;
                setRobotType(robot && robot.slug ? robot.slug : '*');
                const app = l.app_type;
                loadAppToolSettings(app.id);
            })
            .catch(error => requestError(error));
    }

    async function loadMap() {
        setIsLoading(true);
        await api
            .get(`maps/${id}`)
            .then(response => {
                const m = response.data;
                const map_content = m.content || {};
                const map_illustration = map_content.illustration || {};

                setIllustration(map_illustration);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadPoint(point_id);
    }, [point_id]);

    useEffect(() => {
        loadMap();
        loadMapLayer();
    }, []);

    async function updatePoint(data) {
        await api
            .put(`/map_points/${point_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.point.form.update_success" />
                );
                setIsLoading(false);
                loadPoint(point_id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body, content, position };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`/map_points`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.navmaps.point.form.create_success" />
                    );
                    history.push(`/tools/maps/${id}/layer/${layer_id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updatePoint(data);
        }
    }

    function handleMouseDown(e, coords) {
        const { x, y } = coords;
        setDrag({ origin: { x, y } });
    }

    function handleMouseMove(e, coords) {
        if (drag && drag.origin) {
            const { x, y } = coords;
            const e_x = x;
            const e_y = y;

            setPosition({
                x: e_x,
                y: e_y,
            });
        }
    }

    function handleMouseUp() {
        setDrag(null);
    }

    function renderInput(key, label_key, opt) {
        return (
            <div className="col-md-4 col-12 mb-5" key={`input_${key}`}>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg={label_key} />
                    </InputLabel>
                    <Input
                        value={content && content[key] ? content[key] : ''}
                        onChange={event =>
                            setContent({
                                ...content,
                                [key]: event.target.value,
                            })
                        }
                        type={opt.type || 'text'}
                        inputProps={opt.input ? opt.input : undefined}
                        endAdornment={
                            opt.unit ? (
                                <InputAdornment position="end">
                                    {opt.unit}
                                </InputAdornment>
                            ) : null
                        }
                    />
                </FormControl>
            </div>
        );
    }

    function renderOptions(key, label_key, opt) {
        const available_options = {
            disinfection_modes: lists.disinfection_modes,
        };

        const options = available_options[opt.list] || [];

        return (
            <div className="col-md-4 col-12 mb-5" key={`input_${key}`}>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg={label_key} />
                    </InputLabel>
                    <Select
                        value={content && content[key] ? content[key] : ''}
                        onChange={event =>
                            setContent({
                                ...content,
                                [key]: event.target.value,
                            })
                        }
                        fullWidth
                    >
                        {options.map(m => {
                            return (
                                <MenuItem
                                    value={m.value}
                                    key={`options_${m.value}`}
                                >
                                    <LocaleMessage msg={m.label_code} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function renderExtraItem(item) {
        const type = item.type || 'text';
        const { key, label, unit } = item;

        const item_renders = {
            number: renderInput(key, label, {
                unit,
                type: 'number',
                input: { ...(!!item.min && { min: item.min }) },
            }),
            option: renderOptions(key, label, { list: item.options }),
        };

        const input_render = item_renders[type];

        return input_render || null;
    }

    function renderDisinfectionExtra() {
        const app_extras = apptypeSettings || [];

        const filtered_extras =
            robotType === '*'
                ? app_extras
                : app_extras.filter(info => {
                      const robots = info.robots || [];
                      return robots.indexOf(robotType) !== -1;
                  });

        return (
            <ParameterArea className="col-12 pt-3">
                <div className="col-12 mb-3">
                    <h3>
                        <LocaleMessage msg="page.navmaps.point.extra.disinfection.title" />
                    </h3>
                </div>
                <div
                    className="col-12 row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="col-md-6 col-12">
                        <span>
                            <LocaleMessage msg="page.navmaps.point.extra.disinfection.limit" />
                            <small>
                                <br />
                                <LocaleMessage msg="page.navmaps.point.extra.disinfection.limit.helper" />
                            </small>
                        </span>
                    </div>
                    <div className="col-md-6 col-12">
                        <FormControl
                            className="col-md-6 col-12 mb-5"
                            variant="outlined"
                        >
                            <InputLabel>
                                <LocaleMessage msg="label.hours" />
                            </InputLabel>
                            <Select
                                value={
                                    content && content.limit_hours
                                        ? content.limit_hours
                                        : 0
                                }
                                onChange={event =>
                                    setContent({
                                        ...content,
                                        limit_hours: event.target.value,
                                    })
                                }
                                fullWidth
                                label={<LocaleMessage msg="label.hours" />}
                            >
                                {hours.map(h => {
                                    return (
                                        <MenuItem value={h} key={`hours_${h}`}>
                                            {h}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl
                            className="col-md-6 col-12 mb-5"
                            variant="outlined"
                        >
                            <InputLabel>
                                <LocaleMessage msg="label.minutes" />
                            </InputLabel>
                            <Select
                                value={
                                    content && content.limit_minutes
                                        ? content.limit_minutes
                                        : 0
                                }
                                onChange={event =>
                                    setContent({
                                        ...content,
                                        limit_minutes: event.target.value,
                                    })
                                }
                                fullWidth
                                label={<LocaleMessage msg="label.minutes" />}
                            >
                                {minutes.map(m => {
                                    return (
                                        <MenuItem
                                            value={m}
                                            key={`minutes_${m}`}
                                        >
                                            {m}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-12 row mb-0">
                        {filtered_extras.map(item => {
                            return renderExtraItem(item);
                        })}
                    </div>
                </div>
            </ParameterArea>
        );
    }

    function renderExtraInfo() {
        const a_type = appType ? appType.slug : null;
        switch (a_type) {
            case 'disinfection':
                return renderDisinfectionExtra();
            default:
                return null;
        }
    }

    function renderIllustration() {
        return (
            <div className="col-10 mb-5" style={{ height: 'fit-content' }}>
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.navmaps.layer.form.illustration.title" />
                    }
                    hide
                    Icon={MdPinDrop}
                    className="mb-5"
                    style={{ height: 'fit-content' }}
                >
                    <div className="row" style={{ height: '100%' }}>
                        <SVGMap
                            onMouseMove={(e, coords) =>
                                handleMouseMove(e, coords)
                            }
                            onMouseUp={(e, coords) => handleMouseUp(e, coords)}
                            illustration={illustration}
                            points={[
                                {
                                    id: point_id,
                                    label: body.name,
                                    x: position.x || 500,
                                    y: position.y || 500,
                                    onMouseDown: (e, coords) =>
                                        handleMouseDown(e, coords),
                                    onMouseOver: event => {
                                        event.target.setAttribute(
                                            'fill',
                                            'red'
                                        );
                                        event.target.setAttribute(
                                            'cursor',
                                            'move'
                                        );
                                    },
                                    onMouseOut: event => {
                                        event.target.setAttribute(
                                            'fill',
                                            'white'
                                        );
                                        event.target.setAttribute(
                                            'cursor',
                                            'default'
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </CardSideBordered>
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.navmaps.point.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.navmaps.point.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/maps',
                    title: <LocaleMessage msg="breadcrumbs.nav_maps" />,
                },
                {
                    url: `/tools/maps/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.map_layers" />,
                },
                {
                    url: `/tools/maps/${id}/layer/${layer_id}`,
                    title: (
                        <LocaleMessage msg="page.navmaps.point.form.title" />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.navmaps.point.form.title" />
                            }
                            Icon={MdPinDrop}
                            className="mb-5"
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.navmaps.point.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            helperText={
                                                <LocaleMessage msg="page.navmaps.point.form.label.name.helper" />
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.navmaps.point.form.label.reference" />
                                            }
                                            fullWidth
                                            value={body.reference || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    reference:
                                                        event.target.value,
                                                })
                                            }
                                            helperText={
                                                <LocaleMessage msg="page.navmaps.point.form.label.reference.helper" />
                                            }
                                        />
                                    </div>
                                    {renderExtraInfo()}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {/* {operation === 'update' &&
                    illustration &&
                    illustration.objects
                        ? renderIllustration()
                        : null} */}

                    <div className="col-md-8 col-12">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

MapPointForm.propTypes = {
    match: PropTypes.object.isRequired,
};
