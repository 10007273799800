/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Button, DialogContentText, TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea, IconParameter } from '../../styles';

export default function StartSetup({ settings, onChange }) {
    const initial_params = settings.parameters || [];

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleNewParameter() {
        const items = initial_params || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            key: '',
            value: '',
        });
        onChange({
            ...settings,
            parameters: [...items],
        });
    }

    function handleDeleteParameter(idx) {
        setDialogOpen(false);
        const old_items = initial_params || [];
        const items = old_items.filter(c => {
            return c.item_id !== idx;
        });
        onChange({
            ...settings,
            parameters: [...items],
        });
    }

    function onParameterChange(event, idx, key) {
        const items = initial_params || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: event.target.value,
            };
        }
        onChange({
            ...settings,
            parameters: [...items],
        });
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleDialogOpen(event, index) {
        setCurrItem(index);
        event.preventDefault();
        setDialogOpen(true);
    }

    function renderDeleteDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.survey_steps.form.start_setup.label.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => handleDeleteParameter(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderParameterRow(item, index) {
        return (
            <div className="row col-12">
                <div className="col-11 row mx-0">
                    <div className="col-md-6 col-12">
                        <TextField
                            label={
                                <LocaleMessage msg="page.survey_steps.form.start_setup.label.var_key" />
                            }
                            fullWidth
                            value={item && item.key ? item.key : ''}
                            onChange={event =>
                                onParameterChange(event, index, 'key')
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <TextField
                            label={
                                <LocaleMessage msg="page.survey_steps.form.start_setup.label.var_value" />
                            }
                            fullWidth
                            value={item && item.value ? item.value : ''}
                            onChange={event =>
                                onParameterChange(event, index, 'value')
                            }
                        />
                    </div>
                </div>
                <IconParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={event => handleDialogOpen(event, item.item_id)}
                    />
                </IconParameter>
            </div>
        );
    }

    function renderParameterList(parameters) {
        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                {parameters.map((item, idx) => {
                    return (
                        <ParameterArea
                            key={`param_${item.item_id}`}
                            style={{
                                userSelect: 'none',
                            }}
                        >
                            {renderParameterRow(item, idx)}
                        </ParameterArea>
                    );
                })}
            </div>
        );
    }

    return (
        <>
            {dialogOpen ? renderDeleteDialog() : null}
            <div className="mt-3">
                <div
                    style={{
                        padding: '20px 15px',
                    }}
                >
                    <LocaleMessage msg="page.survey_steps.form.start_setup.label.info" />
                </div>
                <div style={{ padding: '0px 15px' }}>
                    <ParameterArea className="row mt-1 mb-5">
                        {renderParameterList(initial_params || [])}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewParameter()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="page.survey_steps.form.start_setup.label.add" />
                        </Button>
                    </ParameterArea>
                </div>
            </div>
        </>
    );
}

StartSetup.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
