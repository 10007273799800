/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import hexToRgba from 'hex-to-rgba';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import {
    TotalContainer,
    ChartsContainer,
    ChartCard,
    InfoContainer,
    TableArea,
    RowArea,
} from '../../style';
import SurveyDetailView from './SurveyDetail';

export default function SurveySessions({
    settings,
    headerSettings,
    requestError,
    counterData,
    CustomTimeTooltip,
    language,
}) {
    const lng_all = lng_labels[language] || {};

    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [surveys, setSurveys] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const [currSurvey, setCurrSurvey] = useState(null);
    const [filteredSessionList, setFilteredSessionList] = useState([]);

    const headers_file = [
        { key: 'group_name', label: 'Group' },
        { key: 'survey_name', label: 'Survey' },
        { key: 'robot_name', label: 'Robot' },
        { key: 'application_name', label: 'Application' },
        { key: 'language_label', label: 'Language' },
        {
            key: 'start_date',
            label: 'Start Date',
        },
        {
            key: 'start_time',
            label: 'Start Time',
        },
        {
            key: 'end_date',
            label: 'End Date',
        },
        {
            key: 'end_time',
            label: 'End Time',
        },
        {
            key: 'finished',
            label: 'Was Finished',
        },
        {
            key: 'duration_label',
            label: 'Duration',
        },
    ];

    const headers_table = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'survey_name',
            label: <LocaleMessage msg="table.headers.survey" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'language_label',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'was_finished',
            order_by: 'finished',
            label: <LocaleMessage msg="table.headers.was_finished" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
    ];

    const header_surveys = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.survey" />,
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
        {
            id: 'time_label',
            label: <LocaleMessage msg="table.headers.duration" />,
            ordered_by: 'time',
        },
    ];

    function filterData(data) {
        const survey_list = {};
        const { robots, groups, applications } = counterData;

        const { survey_sessions, period } = data;
        if (!survey_sessions) return;
        const session_list = survey_sessions ? survey_sessions.list : [];

        const s_groups = [];
        const s_robots = [];
        const s_applications = [];
        const s_surveys = [];
        const s_languages = [];
        const s_s_date = [];
        const s_s_time = [];
        const s_m_time = [];
        let duration_sum = 0;
        let has_time = 0;
        let longest = 0;

        const dlist = session_list.map(d => {
            const s_time = d.time;
            if (s_time) {
                has_time++;
            }
            duration_sum += s_time;

            const start = GetDateTimeLabel(new Date(d.start), { format });
            const end = d.end
                ? GetDateTimeLabel(new Date(d.end), { format })
                : null;

            const robot = robots[d.robot_id];

            const group = groups[d.group_id];

            const application = applications[d.application_id];

            const m_time = s_time / 60;
            const m_time_label = Math.floor(m_time);
            if (m_time_label > longest) {
                longest = m_time_label;
            }

            if (!survey_list[d.survey_id]) {
                survey_list[d.survey_id] = {
                    id: d.survey_id,
                    name: d.survey_name,
                };
            }

            s_groups[d.group_id] = {
                total: s_groups[d.group_id]
                    ? s_groups[d.group_id].total + 1
                    : 1,
                time: s_groups[d.group_id]
                    ? s_groups[d.group_id].time + s_time
                    : s_time,
            };

            s_robots[d.robot_id] = {
                total: s_robots[d.robot_id]
                    ? s_robots[d.robot_id].total + 1
                    : 1,
                time: s_robots[d.robot_id]
                    ? s_robots[d.robot_id].time + s_time
                    : s_time,
            };

            s_surveys[d.survey_id] = {
                total: s_surveys[d.survey_id]
                    ? s_surveys[d.survey_id].total + 1
                    : 1,
                time: s_surveys[d.survey_id]
                    ? s_surveys[d.survey_id].time + s_time
                    : s_time,
            };

            s_applications[d.application_id] = {
                total: s_applications[d.application_id]
                    ? s_applications[d.application_id].total + 1
                    : 1,
                time: s_applications[d.application_id]
                    ? s_applications[d.application_id].time + s_time
                    : s_time,
            };

            s_languages[d.language || '---'] = {
                total: s_languages[d.language || '---']
                    ? s_languages[d.language || '---'].total + 1
                    : 1,
                time: s_languages[d.language || '---']
                    ? s_languages[d.language || '---'].time + s_time
                    : s_time,
            };

            s_s_date[start.date] = s_s_date[start.date]
                ? s_s_date[start.date] + 1
                : 1;
            s_s_time[start.hour] = s_s_time[start.hour]
                ? s_s_time[start.hour] + 1
                : 1;

            const duration = GetOperationTime(d.time);

            return {
                ...d,
                id: d.id,
                language_label: lng_all[`list.languages.${d.language}`],
                group_name: group ? group.name : '---',
                robot_name: robot ? `[${robot.code}] ${robot.name}` : '---',
                application_name: application ? application.name : '---',
                start_date: start.date,
                start_time: start.time,
                start: start.string,
                start_timestamp: start.timestamp,
                end_date: end ? end.date : '',
                end_time: end ? end.time : '',
                end: end ? end.string : '',
                end_timestamp: end ? end.timestamp : '',
                was_finished: d.finished ? (
                    <LocaleMessage msg="label.yes" />
                ) : (
                    <LocaleMessage msg="label.no" />
                ),
                duration: d.time || 0,
                duration_label: duration.label || '00:00:00',
            };
        });

        let extra_time = 0;
        const limit_time = longest > 0 && longest <= 60 ? longest + 10 : 60;
        const duration_spacer = limit_time > 20 ? 5 : 1;

        dlist.forEach(d => {
            const time = Math.floor(d.duration / 60);
            const time_label =
                Math.floor(time / duration_spacer) * duration_spacer || 0;

            if (time_label > limit_time) {
                extra_time++;
                return;
            }
            s_m_time[time_label] = s_m_time[time_label]
                ? s_m_time[time_label] + 1
                : 1;
        });

        const group_grouped = Object.keys(s_groups).map(g => {
            const val = s_groups[g];
            const obj = groups[g];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const robot_grouped = Object.keys(s_robots).map(r => {
            const val = s_robots[r];
            const obj = robots[r];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const survey_grouped = Object.keys(s_surveys).map(s => {
            const val = s_surveys[s];
            const obj = survey_list[s] || {};
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                id: s,
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const application_grouped = Object.keys(s_applications).map(a => {
            const val = s_applications[a];
            const obj = applications[a];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const language_grouped = Object.keys(s_languages).map(l => {
            const val = s_languages[l];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);
            return {
                id: l,
                name: l.toUpperCase(),
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const period_dates = GetPeriodDates(period);

        const s_labels = [];
        const s_date_grouped = [];

        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });

            s_labels.push(label_date);

            s_date_grouped.push({
                date: label_date,
                value: s_s_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const s_time_grouped = [];
        const m_time_grouped = [];

        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);

            s_time_grouped.push({
                hour: label_time,
                value: s_s_time[t] || 0,
            });
        }

        let end_label = '';
        for (let t = 0; t <= limit_time; t += duration_spacer) {
            end_label = t + duration_spacer;
            const label_time = `${t} - ${end_label} min`;

            m_time_grouped.push({
                time: label_time,
                value: s_m_time[t] || 0,
            });
        }
        if (extra_time) {
            m_time_grouped.push({
                time: `> ${end_label} min`,
                value: extra_time || 0,
            });
        }

        const mean_duration = (
            Math.round((duration_sum / has_time) * 10 || 0) / 10
        ).toFixed(1);

        const duration_time = GetOperationTime(duration_sum);
        const mean_time = GetOperationTime(mean_duration);

        // Seconds to Minutes
        const mean_time_txt = `${Number(mean_time.hours) * 60 +
            Number(mean_time.minutes)}:${mean_time.seconds}`;

        setSurveys(survey_list);

        setGroupedData({
            duration: duration_time,
            mean_duration: mean_time_txt,
            groups: group_grouped,
            robots: robot_grouped,
            applications: application_grouped,
            surveys: survey_grouped,
            languages: language_grouped,
            start_date: s_date_grouped,
            start_time: s_time_grouped,
            op_time: m_time_grouped,
            s_labels,
            t_labels,
        });

        setList(dlist);
    }

    async function loadData() {
        setIsLoading(true);

        await api
            .get(`reports/survey_sessions?from=${fromISO}&until=${untilISO}`)
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleSurveySelection(s) {
        const sel_survey = surveys[s];
        setCurrSurvey(
            s
                ? {
                      id: s,
                      ...sel_survey,
                  }
                : null
        );
        if (!s) {
            setFilteredSessionList(list);
            return;
        }

        const currList = list.filter(c => {
            return c.survey_id === s;
        });
        setFilteredSessionList(currList);
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '200px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="start_timestamp"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    sortable
                    hasFilter
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildDurationChart(title, data) {
        return (
            <ChartCard className="col-md-8 col-12 area-chart-container">
                <div>
                    <p className="card-title">{title}</p>
                </div>
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                    width="100%"
                    height={300}
                >
                    <BarChart data={data || []}>
                        <CartesianGrid />
                        <XAxis dataKey="time" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar
                            dataKey="value"
                            fill={`${hexToRgba(colors.dashboard_cards, 0.7)}`}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>
        );
    }

    function renderSurveyDetails() {
        const total = filteredSessionList.length;
        const completed = filteredSessionList.filter(s => {
            return s.finished;
        }).length;

        const ans_rate = total
            ? numeral((100 * completed) / total).format('0.0')
            : '0';
        return currSurvey ? (
            <>
                <RowArea className="mt-5 mb-3">
                    <div>
                        <p className="card-title">
                            {currSurvey ? currSurvey.name : ''}
                        </p>
                    </div>
                </RowArea>
                <TotalContainer className="row mt-3 mb-3">
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.survey_sessions.label.answered.total" />
                        }
                        value={total}
                    />
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.survey_sessions.label.answered.completed" />
                        }
                        value={completed}
                    />
                    <CounterCard
                        classes="col-md-3 col-12 mb-5"
                        title={
                            <LocaleMessage msg="page.reports.actions.survey_sessions.label.answered.completion_rate" />
                        }
                        value={`${ans_rate || '0'}%`}
                    />
                </TotalContainer>
                <Divider />
                <SurveyDetailView
                    survey={currSurvey}
                    settings={settings}
                    data={filteredSessionList}
                    active={active}
                    colors={colors}
                    from={fromISO}
                    until={untilISO}
                    timeTooltip={CustomTimeTooltip}
                    requestError={e => requestError(e)}
                />
            </>
        ) : null;
    }

    function renderCSVButton() {
        return (
            <ExportCSVButton
                data={list}
                headers={headers_file}
                filename={GetFileName(`REPORTS-action_survey_sessions`, 'csv')}
            />
        );
    }

    useEffect(() => {
        filterData(rawData);
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const survey_sessions =
        rawData && rawData.survey_sessions ? rawData.survey_sessions.total : 0;

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
    ];

    const survey_filter =
        groupedData && groupedData.surveys ? groupedData.surveys : [];

    const survey_total = {
        id: null,
        name: 'TOTAL',
        time: 0,
        time_label: groupedData.duration
            ? groupedData.duration.label
            : '00:00:00',
        total: survey_sessions,
    };

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={renderCSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.survey_sessions.label.total_sessions" />
                                }
                                value={survey_sessions}
                            />
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.survey_sessions.label.total_duration" />
                                }
                                value={
                                    groupedData.duration
                                        ? groupedData.duration.text
                                        : '00:00'
                                }
                                footer={
                                    <LocaleMessage msg="label.time.hours_minutes" />
                                }
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'total';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.survey_sessions.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'time';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.survey_sessions.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            tooltip: CustomTimeTooltip,
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.survey_sessions.label.per_s_date" />,
                                groupedData.start_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.survey_sessions.label.per_s_hour" />,
                                groupedData.start_time
                            )}
                        </div>
                        <Divider />
                        <TotalContainer
                            className="mt-3 mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.survey_sessions.label.mean_duration" />
                                }
                                value={
                                    groupedData.mean_duration
                                        ? groupedData.mean_duration
                                        : '00:00'
                                }
                                footer={
                                    <LocaleMessage msg="label.time.minutes_seconds" />
                                }
                            />
                            {buildDurationChart(
                                <LocaleMessage msg="page.reports.actions.survey_sessions.label.per_duration" />,
                                groupedData.op_time
                            )}
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <ChartsContainer className="row mt-5 mb-3">
                                <ReportPieChart
                                    classes="col-md-4 col-12 mb-5"
                                    title={
                                        <LocaleMessage msg="page.reports.actions.survey_sessions.label.per_survey.total" />
                                    }
                                    color={colors.dashboard_graphs}
                                    data={groupedData.surveys}
                                    dataKey="total"
                                />
                                <TableArea className="col-md-6 col-12 mb-3 table-responsive-material">
                                    <DataTable
                                        maxHeight="100%"
                                        sortable
                                        orderDirection="desc"
                                        orderColumn="total"
                                        headerColumns={header_surveys}
                                        data={survey_filter}
                                        handleTableRowClick={(event, id) => {
                                            handleSurveySelection(id);
                                        }}
                                        hasActions={false}
                                        hasFooter={false}
                                        defaultRowsPerPage={10}
                                        headerData={survey_total}
                                    />
                                </TableArea>
                            </ChartsContainer>
                        </InfoContainer>
                        <Divider />
                        {currSurvey ? (
                            <>{renderSurveyDetails()}</>
                        ) : (
                            <RowArea className="mt-3 mb-5">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.actions.survey_sessions.label.all_sessions" />
                                    </p>
                                </div>
                                {buildListView()}
                            </RowArea>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

SurveySessions.defaultProps = {
    language: 'pt_BR',
};

SurveySessions.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    language: PropTypes.string,
};
