/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { MdExitToApp, MdMenu, MdAccountCircle, MdGroup } from 'react-icons/md';
import InjectIntl from 'react-intl-inject';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import PropTypes from 'prop-types';

import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Toolbar,
    Tooltip,
} from '@material-ui/core';

import AppSwitcher from '~/components/AppSwitcher';
import GroupMenu from '~/components/GroupMenu';
import LanguageSwitcher from '~/components/LanguageSwitcher';
import LocaleMessage from '~/components/LocaleMessage';
import UserInfo from '~/components/UserInfo';

import pbLogo from '~/assets/logo-white.png';
import history from '~/services/history';
import { signOut } from '~/store/modules/auth/actions';

import { AppHeader, MenuItem } from './styles';

export default function Header({
    drawer,
    toggleCollapsedNav,
    selectGroup,
    extraInfo,
    smallScreen,
}) {
    const dispatch = useDispatch();
    const drawerStyle = drawer === 'collapsible-drawer' ? 'd-block' : 'd-none';

    const settings = useSelector(state => state.settings);
    const appSettings = useSelector(state => state.application);

    const { logo, locale, app_list } = settings;
    const { app_type, app_home } = appSettings;

    const [groupMenu, setGroupMenu] = useState(false);
    const [appSwitcher, setAppSwitcher] = useState(false);
    const [langSwitcher, setLangSwitcher] = useState(false);
    const [logoutOpen, setLogoutOpen] = useState(false);

    const [settingsOpen, setSettingsOpen] = useState(false);

    function renderIconButton(item) {
        const Icon = item.icon || MdExitToApp;
        return (
            <InjectIntl>
                {({ intl }) => (
                    <Tooltip
                        title={intl.formatMessage({ id: item.tooltip || '' })}
                        arrow
                    >
                        <IconButton className="icon-btn" onClick={item.onClick}>
                            <Icon size={24} style={{ color: '#fff' }} />
                        </IconButton>
                    </Tooltip>
                )}
            </InjectIntl>
        );
    }

    function renderText(item) {
        return (
            <>
                <span>
                    <LocaleMessage msg={item.text} />
                </span>
            </>
        );
    }

    function renderIconText(item, color = '#fff') {
        const Icon = item.icon || MdExitToApp;
        return (
            <MenuItem onClick={item.onClick}>
                <IconButton className="icon-btn mr-1">
                    <Icon size={18} style={{ color }} />
                </IconButton>
                {item.text ? <LocaleMessage msg={item.text} /> : null}
            </MenuItem>
        );
    }

    function companyLogo() {
        return (
            <Link to={app_home}>
                <img
                    src={logo.url || pbLogo}
                    alt="Pluginspace Logo"
                    title="Pluginspace Logo"
                    style={{ maxWidth: 125, maxHeight: 30 }}
                />
            </Link>
        );
    }

    const onLangSwitcherSelect = () => {
        setLangSwitcher(!langSwitcher);
    };

    const handleRequestClose = () => {
        setLangSwitcher(false);
    };

    function handleLogout() {
        dispatch(signOut());
        setLogoutOpen(false);
    }

    function renderUserOptions() {
        return (
            <>
                {app_type === 'platform' ? (
                    <li className="list-inline-item user-nav">
                        <UserInfo onLogoutClick={() => setLogoutOpen(true)} />
                    </li>
                ) : (
                    renderIconButton({
                        onClick: () => {
                            setLogoutOpen(true);
                        },
                        Icon: MdExitToApp,
                        tooltip: 'usermenu.logout',
                    })
                )}
            </>
        );
    }

    function renderLogoutDialog() {
        return (
            <div>
                <Dialog
                    open={logoutOpen}
                    onClose={() => setLogoutOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <LocaleMessage msg="message.logout.title" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LocaleMessage msg="message.logout.text" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setLogoutOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.close" />
                        </Button>
                        <Button
                            onClick={() => handleLogout()}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.logout" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderExtraInfo(item) {
        let info = null;
        switch (item.type) {
            case 'icon':
                info = renderIconButton(item);
                break;
            case 'icon-text':
                info = renderIconText(item);
                break;
            case 'text':
                info = renderText(item);
                break;
            default:
                break;
        }

        return info ? (
            <li className="list-inline-item" key={item.key}>
                {info}
            </li>
        ) : null;
    }

    function renderAppSwitcher() {
        return app_list && app_list.length > 0 ? (
            <li className="list-inline-item">
                <AppSwitcher
                    appList={app_list}
                    open={appSwitcher}
                    toggle={() => setAppSwitcher(!appSwitcher)}
                    hideMenu={() => setAppSwitcher(false)}
                />
            </li>
        ) : null;
    }

    function renderLanguageSwitcher() {
        return (
            <Dropdown
                className="quick-menu"
                isOpen={langSwitcher}
                toggle={onLangSwitcherSelect}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    <IconButton className="icon-btn">
                        <i className={`flag flag-24 flag-${locale.icon}`} />
                    </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                    <LanguageSwitcher handleRequestClose={handleRequestClose} />
                </DropdownMenu>
            </Dropdown>
        );
    }

    function renderMobileMenu() {
        return (
            <>
                {renderAppSwitcher()}
                <li className="list-inline-item">{renderLanguageSwitcher()}</li>
                <li className="list-inline-item">
                    <Dropdown
                        className="quick-menu"
                        isOpen={settingsOpen}
                        toggle={() => setSettingsOpen(!settingsOpen)}
                    >
                        <DropdownToggle
                            className="d-inline-block"
                            tag="span"
                            data-toggle="dropdown"
                        >
                            <MdMenu size={24} />
                        </DropdownToggle>

                        <DropdownMenu right className="w-50">
                            <ul className="header-notifications list-inline ml-auto">
                                {extraInfo
                                    ? extraInfo.map(item => {
                                          return (
                                              <li key={item.key}>
                                                  {renderIconText(
                                                      item,
                                                      '#575757'
                                                  )}
                                              </li>
                                          );
                                      })
                                    : null}
                                {app_type === 'platform' ? (
                                    <>
                                        <li>
                                            {renderIconText(
                                                {
                                                    icon: MdAccountCircle,
                                                    text: 'usermenu.profile',
                                                    onClick: () => {
                                                        history.push(
                                                            '/profile'
                                                        );
                                                        setSettingsOpen(false);
                                                    },
                                                    key: 'profile',
                                                },
                                                '#575757'
                                            )}
                                        </li>
                                        <li>
                                            {renderIconText(
                                                {
                                                    icon: MdGroup,
                                                    text: 'usermenu.my_groups',
                                                    onClick: () => {
                                                        history.push('/groups');
                                                        setSettingsOpen(false);
                                                    },
                                                    key: 'groups',
                                                },
                                                '#575757'
                                            )}
                                        </li>
                                    </>
                                ) : null}
                                <li>
                                    {renderIconText(
                                        {
                                            icon: MdExitToApp,
                                            text: 'usermenu.logout',
                                            onClick: () => {
                                                setLogoutOpen(true);
                                                setSettingsOpen(false);
                                            },
                                            key: 'logout',
                                        },
                                        '#575757'
                                    )}
                                </li>
                            </ul>
                        </DropdownMenu>
                    </Dropdown>
                </li>
            </>
        );
    }

    return (
        <>
            {logoutOpen ? renderLogoutDialog() : null}
            <AppHeader className="app-header" smallScreen={smallScreen}>
                <AppBar className="app-main-header">
                    <Toolbar className="app-toolbar">
                        {app_type === 'platform' ? (
                            <IconButton
                                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                                aria-label="Menu"
                                onClick={event => toggleCollapsedNav(event)}
                            >
                                <span className="menu-icon" />
                            </IconButton>
                        ) : null}

                        {drawer !== 'collapsible-drawer' ||
                        app_type !== 'platform'
                            ? companyLogo()
                            : null}
                        <div className="ellipse-shape" />
                        <ul className="header-notifications list-inline ml-auto">
                            {!smallScreen ? (
                                <>
                                    {extraInfo
                                        ? extraInfo.map(i => {
                                              return renderExtraInfo(i);
                                          })
                                        : null}
                                    {selectGroup ? null : (
                                        <li className="list-inline-item">
                                            <GroupMenu
                                                open={groupMenu}
                                                toggle={() =>
                                                    setGroupMenu(!groupMenu)
                                                }
                                                hideMenu={() =>
                                                    setGroupMenu(false)
                                                }
                                            />
                                        </li>
                                    )}
                                    {renderAppSwitcher()}
                                    <li className="list-inline-item">
                                        {renderLanguageSwitcher()}
                                    </li>
                                    {renderUserOptions()}
                                </>
                            ) : (
                                renderMobileMenu()
                            )}
                        </ul>
                    </Toolbar>
                </AppBar>
            </AppHeader>
        </>
    );
}

Header.propTypes = {
    smallScreen: PropTypes.bool,
    drawer: PropTypes.string.isRequired,
    selectGroup: PropTypes.bool.isRequired,
    toggleCollapsedNav: PropTypes.func.isRequired,
    extraInfo: PropTypes.array,
};

Header.defaultProps = {
    extraInfo: [],
    smallScreen: false,
};
