// import { darken } from 'polished';
import styled from 'styled-components';

export const Column = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
`;

export const SlidersContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
`;

export const Sliders = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    font-size: 2vh;

    .MuiSlider-root.MuiSlider-vertical {
        width: 10px;
    }

    .MuiSlider-vertical {
        .MuiSlider-rail {
            width: 10px;
            color: #ffffff;
        }
        .MuiSlider-track {
            width: 10px;
            color: #ffffff;
        }
        .MuiSlider-thumb {
            display: none;
        }
    }
`;
