/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdMoreVert } from 'react-icons/md';

import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';

import LocaleMessage from '~/components/LocaleMessage';

import icon from '~/assets/icon-white.png';

import { Container } from './styles';

export default function CardItem(props) {
    const {
        img,
        title,
        children,
        updated,
        group,
        open_all,
        hasOptions,
        optionsClick,
        position,
        fit,
        background,
        imgHeight,
        colorBackground,
    } = props;

    return (
        <Container imgBackground={colorBackground}>
            <div
                className="jr-card jr-card-widget jr-card-full text-center card"
                style={{ height: '100%' }}
            >
                {img ? (
                    <>
                        {img.url ? (
                            <img
                                className="half-img-title"
                                src={img.url}
                                alt={img.name}
                                style={{
                                    minHeight: imgHeight,
                                    maxHeight: imgHeight,
                                    objectPosition: position,
                                    objectFit: fit,
                                    ...(background && { background }),
                                }}
                            />
                        ) : (
                            <div
                                className="img-placeholder"
                                style={{
                                    minHeight: imgHeight,
                                    maxHeight: imgHeight,
                                    ...(background && { background }),
                                }}
                            >
                                <img
                                    src={icon}
                                    alt="Pluginbot"
                                    title="Pluginbot"
                                    style={{
                                        maxHeight: '60%',
                                        maxWidth: '60%',
                                        objectPosition: position,
                                        objectFit: fit,
                                    }}
                                />
                            </div>
                        )}
                    </>
                ) : null}
                <div className="card-body text-left">
                    <div className="card-title">
                        <>{title}</>
                        {hasOptions ? (
                            <IconButton
                                aria-label="more"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={optionsClick}
                            >
                                <MdMoreVert />
                            </IconButton>
                        ) : null}
                    </div>
                    {children}
                </div>
                <div
                    className={`card-bottom ${
                        group ? 'two-items' : 'one-item'
                    }`}
                >
                    <div>
                        {open_all ? (
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                label={
                                    <LocaleMessage msg="message.open_all.short" />
                                }
                                style={{ marginRight: '5px' }}
                            />
                        ) : null}
                        {group ? (
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="small"
                                label={group}
                            />
                        ) : null}
                    </div>
                    <small>{updated}</small>
                </div>
            </div>
        </Container>
    );
}

CardItem.defaultProps = {
    img: null,
    title: '',
    updated: '',
    group: '',
    hasOptions: true,
    optionsClick: () => {},
    position: 'top',
    fit: 'cover',
    background: '',
    open_all: false,
    colorBackground: false,
    imgHeight: '200px',
};

CardItem.propTypes = {
    img: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.element.isRequired,
    updated: PropTypes.string,
    group: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hasOptions: PropTypes.bool,
    open_all: PropTypes.bool,
    colorBackground: PropTypes.bool,
    optionsClick: PropTypes.func,
    position: PropTypes.string,
    fit: PropTypes.string,
    background: PropTypes.string,
    imgHeight: PropTypes.string,
};
