/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdDelete, MdDeleteSweep, MdEdit } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea, IconParameter } from '../styles';

export default function DanceList({
    settings,
    updateList,
    onAddClick,
    defaultColor,
    handleEdit,
}) {
    const [removeRows, setRemoveRows] = useState([]);

    const show_preview = settings.show_selection || false;
    const rtype_list = settings.dances || [];

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;
        const items = rtype_list || [];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);
        updateList(items);
    }

    function removeDances(list) {
        const selected_dances = rtype_list.filter(d => {
            return !list.includes(d.id);
        });

        updateList(selected_dances);
        setRemoveRows([]);
    }

    function renderItem(item, idx) {
        return (
            <div
                className="row col-12 move"
                key={item.id}
                style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => removeDances([item.id])}
                    />
                </IconParameter>
                {item.name}
                <IconParameter className="col-1">
                    {item.type === 'custom' ? (
                        <MdEdit
                            size={18}
                            onClick={() => handleEdit(idx, item)}
                        />
                    ) : null}
                </IconParameter>
            </div>
        );
    }

    function renderDnDList() {
        return (
            <>
                <span className="col-12 mt-1 mb-1 text-center">
                    <LocaleMessage msg="label.reorder" />
                </span>
                <DragDropContext onDragEnd={result => onDragEnd(result)}>
                    <Droppable droppableId="condition-list">
                        {(drop_provided, drop_snapshot) => {
                            return (
                                <div
                                    className="p-3"
                                    {...drop_provided.droppableProps}
                                    ref={drop_provided.innerRef}
                                    style={{
                                        background: drop_snapshot.isDraggingOver
                                            ? 'darkgrey'
                                            : 'lightgrey',
                                        width: '100%',
                                    }}
                                >
                                    {rtype_list.map((item, idx) => {
                                        const action_data = item.action || {};
                                        return (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={idx}
                                            >
                                                {drag_provided => {
                                                    return (
                                                        <ParameterArea
                                                            className="mb-3"
                                                            ref={
                                                                drag_provided.innerRef
                                                            }
                                                            {...drag_provided.draggableProps}
                                                            {...drag_provided.dragHandleProps}
                                                            style={{
                                                                userSelect:
                                                                    'none',
                                                                textAlign:
                                                                    'center',
                                                                background:
                                                                    '#fff',
                                                                border:
                                                                    '1px solid #ccc',
                                                                borderRadius:
                                                                    '5px',
                                                                ...drag_provided
                                                                    .draggableProps
                                                                    .style,
                                                                borderLeft: `5px ${
                                                                    action_data.button &&
                                                                    action_data
                                                                        .button
                                                                        .color
                                                                        ? action_data
                                                                              .button
                                                                              .color
                                                                        : defaultColor
                                                                } solid`,
                                                            }}
                                                        >
                                                            {renderItem(
                                                                item,
                                                                idx
                                                            )}
                                                        </ParameterArea>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {drop_provided.placeholder}
                                </div>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
            </>
        );
    }

    function renderSimpleList() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
                order_by: 'name',
            },
        ];

        const rowActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdDelete />,
                action: (e, _id) => removeDances([_id]),
            },
        ];

        const selAction = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdDeleteSweep />,
                action: () => removeDances(removeRows),
            },
        ];

        return (
            <>
                <div className="col-12 mb-3 pt-3 text-center">
                    <LocaleMessage msg="page.dances.pluginconfig.random.title" />
                </div>
                <div className="col-12 mb-3">
                    <DataTable
                        data={rtype_list}
                        orderColumn="name"
                        headerColumns={headCells}
                        rowActions={rowActions}
                        sortable
                        selectable
                        selectedActions={selAction}
                        selectedRows={removeRows}
                        setSelectedRows={s => {
                            setRemoveRows(s);
                        }}
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <span className="col-12 mt-3 mb-3 text-center">
                <LocaleMessage msg="page.dances.pluginconfig.list.title" />
            </span>
            {show_preview ? renderDnDList() : renderSimpleList()}
            <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{
                    whiteSpace: 'nowrap',
                    padding: '5px 20px',
                }}
                onClick={() => onAddClick()}
            >
                <LocaleMessage msg="page.dances.pluginconfig.list.add" />
            </Button>
        </>
    );
}

DanceList.propTypes = {
    settings: PropTypes.object,
    defaultColor: PropTypes.string,
    updateList: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
};

DanceList.defaultProps = {
    settings: {},
    defaultColor: '#000',
};
