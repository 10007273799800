/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import { UserMenu } from '../styles';

export default function UserInfoPopup(props) {
    const { onCloseMenu, profile, onLogoutClick } = props;
    const { name, permission, avatar } = profile;

    const permissionList = {
        master: <LocaleMessage msg="user.permission.master" />,
        super_admin: <LocaleMessage msg="user.permission.super_admin" />,
        admin: <LocaleMessage msg="user.permission.admin" />,
        editor: <LocaleMessage msg="user.permission.editor" />,
        viewer: <LocaleMessage msg="user.permission.viewer" />,
    };

    return (
        <UserMenu onClick={() => onCloseMenu()}>
            <div className="user-profile">
                {avatar && avatar.url ? (
                    <img
                        className="user-avatar border-0 size-40 rounded-circle"
                        src={avatar ? avatar.url : null}
                        alt="User Avatar"
                    />
                ) : (
                    <div className="MuiAvatar-root bg-secondary size-30 text-white MuiAvatar-colorDefault rounded-circle">
                        {profile && profile.name
                            ? profile.name[0].toUpperCase()
                            : 'P'}
                    </div>
                )}
                <div className="user-detail ml-2">
                    <h4 className="user-name mb-0">{name}</h4>
                    <small className="user-permission">
                        {permissionList[permission]}
                    </small>
                </div>
            </div>
            <span
                className="jr-link dropdown-item text-muted"
                onClick={() => onCloseMenu('/profile')}
            >
                <i className="zmdi zmdi-account zmdi-hc-fw mr-1" />
                <LocaleMessage msg="usermenu.profile" />
            </span>
            <span
                className="jr-link dropdown-item text-muted"
                onClick={() => onCloseMenu('/groups')}
            >
                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw mr-1" />
                <LocaleMessage msg="usermenu.my_groups" />
            </span>
            <span
                className="jr-link dropdown-item text-muted"
                onClick={() => {
                    onLogoutClick();
                }}
            >
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
                <LocaleMessage msg="usermenu.logout" />
            </span>
        </UserMenu>
    );
}

UserInfoPopup.propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
    onCloseMenu: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};
