/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
    MdDelete,
    MdPlaylistAddCheck,
    MdCheck,
    MdClose,
    MdContentCopy,
    MdImage,
    MdSave,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import { toJpeg } from 'html-to-image';
import PropTypes from 'prop-types';

import {
    Button,
    Fab,
    DialogContentText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FlowView from '~/components/FlowView';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import pluginbot_logo from '~/assets/logo-dark.png';
import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';
import lngLabels from '~/util/LangMessages';

import { FlowArea, FlowTree } from './styles';

const step_types = {
    start: 'page.survey_steps.type.start',
    question: 'page.survey_steps.type.input',
    input: 'page.survey_steps.type.input',
    display: 'page.survey_steps.type.display',
    integration: 'page.survey_steps.type.integration',
    end: 'page.survey_steps.type.end',
};

const PAGE_VIEW_KEY = 'survey';
const PAGE_VIEW_DEF = 'flow';

export default function Survey({ match }) {
    const dispatch = useDispatch();

    const componentRef = useRef();
    const { plugin_id, id } = match.params;
    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);

    const date_loc = getDateLocale(settings);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';

    const [view, setView] = useState(
        page_settings[PAGE_VIEW_KEY] || PAGE_VIEW_DEF
    );
    const [isLoading, setIsLoading] = useState(true);
    const [needSaving, setNeedSaving] = useState(false);

    const [pluginConfig, setPluginConfig] = useState(null);
    const [language, setLanguage] = useState({});

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [stepTree, setStepTree] = useState({});
    const [stepPositions, setStepPositions] = useState(null);

    const [availableLangs, setAvailableLangs] = useState([]);
    const [items, setItems] = useState([]);
    const [surveyAnalysis, setSurveyAnalysis] = useState({});
    const [copyOpen, setCopyOpen] = useState(false);

    const headCells = [
        {
            id: 'type_label',
            label: <LocaleMessage msg="table.headers.type" />,
        },
        {
            id: 'description',
            label: <LocaleMessage msg="table.headers.description" />,
        },
        {
            id: 'var_label',
            label: <LocaleMessage msg="table.headers.save_as" />,
        },
        {
            id: 'exit_conditions',
            label: <LocaleMessage msg="table.headers.exit_conditions" />,
        },
        {
            id: 'updated',
            label: <LocaleMessage msg="table.headers.updated" />,
            order_by: 'updated_timestamp',
        },
    ];

    const analysis_items = lists.survey_evaluation_list;

    const labels = lngLabels[lang];

    function saveImg() {
        if (componentRef.current === null) {
            return;
        }
        const flowControls = document.getElementsByClassName(
            'react-flow__panel react-flow__controls'
        );
        const flowBackground = document.getElementsByClassName(
            'react-flow__background'
        );
        const flowLogo = document.getElementsByClassName('flow-footer');

        if (flowControls && flowControls[0]) {
            flowControls[0].style.visibility = 'hidden';
        }
        if (flowBackground && flowBackground[0]) {
            flowBackground[0].style.visibility = 'hidden';
        }
        if (flowLogo && flowLogo[0]) {
            flowLogo[0].style.visibility = 'visible';
        }

        toast.info(<LocaleMessage msg="message.generating_file" />);

        toJpeg(componentRef.current, { cacheBust: true })
            .then(dataUrl => {
                if (flowControls && flowControls[0]) {
                    flowControls[0].style.visibility = 'visible';
                }
                if (flowBackground && flowBackground[0]) {
                    flowBackground[0].style.visibility = 'visible';
                }
                if (flowLogo && flowLogo[0]) {
                    flowLogo[0].style.visibility = 'hidden';
                }
                const link = document.createElement('a');
                link.download = `${GetFileName(pluginConfig.name, 'jpg')}`;
                link.href = dataUrl;
                link.click();
            })
            .catch(() => {
                toast.error(<LocaleMessage msg="errors.unknown_error" />);
            });
    }

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getExitSetting(condition, exit) {
        const type = 'step';

        return {
            condition,
            type,
            to: exit && exit.to ? exit.to : null,
            edge_id: exit.item_id,
        };
    }

    function getStepChildren(step) {
        const exit_settings =
            step.settings && step.settings.conditions
                ? step.settings.conditions
                : {};

        const children = [];
        Object.keys(exit_settings).forEach(k => {
            const exit = exit_settings[k];
            children.push(getExitSetting(k, exit));
        });

        return children;
    }

    function parseStepTree() {
        if (!items || items.length === 0) {
            return;
        }
        const config_params =
            pluginConfig && pluginConfig.parameters
                ? pluginConfig.parameters
                : {};
        const initial_pos =
            config_params.flow_view && config_params.flow_view.positions
                ? config_params.flow_view.positions
                : {};

        const parsed = {};
        const start_search = [];
        let step_search = [];
        items.forEach(s => {
            const exits = getStepChildren(s);

            if (s.type === 'start') {
                exits.forEach(e => {
                    start_search.push(e);
                });
            }
            parsed[s.id] = {
                id: s.id,
                type: s.type,
                name: s.description,
                exits,
                level: s.type === 'start' ? 0 : -1,
                position: initial_pos[s.id] || null,
            };
        });

        step_search = start_search;
        let lvl = 1;
        do {
            const children = [];
            step_search.forEach(s => {
                const s_id = s.to;
                const step = parsed[s_id];
                if (step && step.level === -1) {
                    const { exits: step_exits } = step;
                    parsed[s_id] = {
                        ...step,
                        level: lvl,
                    };
                    step_exits.forEach(e => {
                        children.push(e);
                    });
                }
            });
            step_search = children;
            lvl++;
        } while (lvl <= items.length && step_search && step_search.length > 0);

        const hierarchy = {};
        Object.keys(parsed).forEach(s => {
            const step = parsed[s];
            const { level } = step;
            if (hierarchy[level]) {
                hierarchy[level].push(step);
            } else {
                hierarchy[level] = [step];
            }
        });
        setStepTree(hierarchy);
    }

    function analyzeSurvey() {
        const checked = language && language.list ? language.list : [];
        const has_languages = checked.length > 0;

        const start_steps = items.filter(s => {
            return s.type === 'start';
        });

        const end_steps = items.find(s => {
            return s.type === 'end';
        });

        const non_end = items.filter(s => {
            return s.type !== 'end';
        });

        const no_exit = non_end.find(s => {
            const s_settings = s.settings || {};
            const cond = s_settings.conditions || [];
            const filtered_conditions = cond.filter(c => !!c.to);
            return filtered_conditions.length <= 0;
        });

        const analysis = {
            has_languages,
            start_step: start_steps && start_steps.length === 1,
            end_steps: !!end_steps,
            exit_conditions: !no_exit,
        };

        setSurveyAnalysis(analysis);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const lngs = response.data;

                setAvailableLangs(lngs);
            })
            .catch(error => requestError(error));
    }

    async function loadPluginconfig(survey_id) {
        setIsLoading(true);
        await api
            .get(`pluginconfigs/${survey_id}`)
            .then(async response => {
                const config = response.data;
                setPluginConfig({
                    name: config.name,
                    action_id:
                        config.action && config.action.id
                            ? config.action.id
                            : null,
                    group_id:
                        config.group && config.group.id
                            ? config.group.id
                            : null,
                    plugintemplate_id:
                        config.plugintemplate && config.plugintemplate.id
                            ? config.plugintemplate.id
                            : null,
                    type:
                        config.plugintemplate && config.plugintemplate.type
                            ? config.plugintemplate.type
                            : null,
                    parameters: config.parameters,
                });
                const parameters = config.parameters || {};
                setLanguage(parameters.language);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadItems(survey_id) {
        setIsLoading(true);
        await api
            .get(`survey/${survey_id}/steps`)
            .then(response => {
                const { data } = response;

                const steps = data.map(s => {
                    let order = 0;
                    switch (s.type) {
                        case 'start':
                            order = 0;
                            break;
                        case 'question':
                        case 'input':
                            order = 1;
                            break;
                        case 'display':
                            order = 2;
                            break;
                        case 'end':
                        default:
                            order = 10;
                    }

                    const exit_conditions = s.conditions || [];
                    const filtered_conditions = exit_conditions.filter(
                        c => !!c.to
                    );
                    const updated = new Date(s.updated);

                    return {
                        id: s.id,
                        description: s.description,
                        type: s.type,
                        timeout: s.timeout,
                        settings: s.settings,
                        var_name: s.var_name,
                        var_label: s.var_name || '---',
                        order,
                        type_label: labels[step_types[s.type]],
                        exit_conditions: filtered_conditions.length,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setItems(steps);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`survey/${id}/steps/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.survey_steps.list.delete_success" />
                );
                loadItems(id);
            })
            .catch(error => requestError(error));
    }

    async function copyItem(item) {
        setIsLoading(true);
        setCopyOpen(false);
        await api
            .post(`survey/${id}/steps/${item}/clone`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.survey_steps.list.update_success" />
                );
                loadItems(id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadPluginconfig(id);
        loadItems(id);
        loadLanguages();
    }, []);

    useEffect(() => {
        parseStepTree();
        analyzeSurvey();
    }, [pluginConfig, items, language]);

    async function handleUpdateSurvey(saveFlow) {
        const data = {
            name: pluginConfig.name,
            parameters: {
                ...pluginConfig.parameters,
                language,
                ...(saveFlow && { flow_view: { positions: stepPositions } }),
            },
        };

        setIsLoading(true);
        await api
            .put(`pluginconfigs/${id}`, data)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.survey_steps.list.update_success" />
                );
                await loadPluginconfig(id);
            })
            .catch(error => requestError(error));
        setNeedSaving(false);
        setIsLoading(false);
    }

    function handleCopyOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setCopyOpen(true);
    }

    function handleClickOpen(event, step_id) {
        setCurrItem(step_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleStepSelection(step_id) {
        setCurrItem(step_id);
        history.push(`/pluginconfig/${plugin_id}/survey/${id}/${step_id}`);
    }

    function handleTableRowClick(event, step_id) {
        event.preventDefault();
        handleStepSelection(step_id);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'copy',
                label: <LocaleMessage msg="button.copy" />,
                icon: <MdContentCopy />,
                action: handleCopyOpen,
                disable_conditions: [
                    {
                        key: 'type',
                        mode: 'equal',
                        value: 'start',
                    },
                ],
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={items || []}
                    orderColumn="order"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.survey_steps.list.delete_confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildCopyDialog() {
        return (
            <SimpleDialog
                open={copyOpen}
                onClose={() => setCopyOpen(false)}
                title={
                    <LocaleMessage msg="page.survey_steps.list.copy.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.survey_steps.list.copy.text" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setCopyOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'copy',
                        onClick: () => copyItem(currItem),
                        label: <LocaleMessage msg="button.copy" />,
                    },
                ]}
            />
        );
    }

    function buildFlowView() {
        return (
            <FlowArea>
                <FlowTree ref={componentRef}>
                    <FlowView
                        steps={items}
                        tree={stepTree || {}}
                        controlPosition="top-right"
                        onClick={(event, step_id) =>
                            handleStepSelection(step_id)
                        }
                        savePositions={p => {
                            setNeedSaving(true);
                            setStepPositions(p);
                        }}
                    />
                    <div className="flow-footer">
                        <img
                            className="pluginbot-logo"
                            src={pluginbot_logo}
                            alt="Pluginbot Logo"
                            title="Pluginbot Logo"
                        />
                    </div>
                </FlowTree>
                <div className="col-3 flow-buttons">
                    {needSaving ? (
                        <Tooltip
                            title={
                                <LocaleMessage msg="page.survey_steps.list.flow.save" />
                            }
                            placement="right"
                        >
                            <Fab
                                className="mb-3"
                                color="primary"
                                onClick={() => handleUpdateSurvey(true)}
                            >
                                <MdSave style={{ color: '#fff' }} size={24} />
                            </Fab>
                        </Tooltip>
                    ) : null}
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.survey_steps.list.flow.export" />
                        }
                        placement="right"
                    >
                        <Fab
                            className="mb-3"
                            color="primary"
                            onClick={() => saveImg()}
                        >
                            <MdImage style={{ color: '#fff' }} size={24} />
                        </Fab>
                    </Tooltip>
                </div>
            </FlowArea>
        );
    }

    function renderIndicators() {
        return (
            <CardSideBordered
                title={<LocaleMessage msg="page.survey_steps.list.rules" />}
                Icon={MdPlaylistAddCheck}
                hide
            >
                <>
                    <div style={{ padding: '15px' }}>
                        <p>
                            <LocaleMessage msg="page.survey_steps.list.rules.description" />
                        </p>
                        <List>
                            {analysis_items.map(item => {
                                return (
                                    <ListItem
                                        key={item.value}
                                        role={undefined}
                                        dense
                                        button
                                    >
                                        <ListItemIcon>
                                            {surveyAnalysis &&
                                            surveyAnalysis[item.value] ? (
                                                <MdCheck size={24} />
                                            ) : (
                                                <MdClose size={24} />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <LocaleMessage
                                                    msg={item.label_code}
                                                />
                                            }
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                </>
            </CardSideBordered>
        );
    }

    function renderSurveySettings() {
        const options = availableLangs.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={`list.languages.${l.value}`} />,
            };
        });

        return (
            <CardSideBordered
                title={<LocaleMessage msg="page.survey_steps.list.settings" />}
                Icon={MdPlaylistAddCheck}
            >
                <>
                    <div className="col-12 mb-5">
                        <LocaleMessage msg="page.survey_steps.list.settings.languages" />
                        <br />
                        <FormCheckList
                            settings={language}
                            options={options}
                            allowStar
                            onChange={l => {
                                setLanguage(l);
                            }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={() => handleUpdateSurvey(false)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </>
            </CardSideBordered>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.survey_steps.list.title" />}
            currentPage={pluginConfig ? pluginConfig.name : ''}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
                {
                    url: `/plugins/${plugin_id}`,
                    title: <LocaleMessage msg="breadcrumbs.survey" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                {copyOpen ? buildCopyDialog() : null}
                <div className="row col-12">
                    <div className="col-md-6 col-12 mb-5">
                        {renderSurveySettings()}
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        {renderIndicators()}
                    </div>
                </div>
                <div className="col-md-12 col-12">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.survey_steps.list.title" />
                        }
                        Icon={MdPlaylistAddCheck}
                        hide
                    >
                        <div className="col-12 row">
                            <div
                                className="body-top-controls"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div className="col-3 pl-0">
                                    <NewItemButton
                                        link={`/pluginconfig/${plugin_id}/survey/${id}/new`}
                                        text={
                                            <LocaleMessage msg="page.survey_steps.list.add" />
                                        }
                                    />
                                </div>
                                <ViewSwitcher
                                    size="col-3"
                                    view={view}
                                    setView={v => {
                                        setView(v);
                                    }}
                                    pageKey={PAGE_VIEW_KEY}
                                    options={[
                                        {
                                            key: 'flow',
                                            icon: 'MdAccountTree',
                                        },
                                        {
                                            key: 'list',
                                            icon: 'MdFormatListBulleted',
                                        },
                                    ]}
                                />
                            </div>

                            {view === 'list'
                                ? buildListView()
                                : buildFlowView()}
                        </div>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

Survey.propTypes = {
    match: PropTypes.object.isRequired,
};
