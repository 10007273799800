/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdCameraAlt } from 'react-icons/md';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import { Container } from './styles';

export default function AvatarInput(props) {
    const { name, defaultValue, onFileUpload } = props;
    const file = defaultValue.id || null;
    const preview = defaultValue.url || null;

    return (
        <Container>
            <label htmlFor="avatar">
                <span>
                    <MdCameraAlt />
                    <LocaleMessage msg="page.profile.form.label.change_avatar" />
                </span>
                {preview ? (
                    <img
                        src={
                            preview ||
                            'https://api.adorable.io/avatars/50/abott@adorable.png'
                        }
                        alt=""
                    />
                ) : (
                    <div className="MuiAvatar-root bg-secondary MuiAvatar-colorDefault avatar-placeholder">
                        <h1 className="m-0 text-white">
                            {name[0].toUpperCase()}
                        </h1>
                    </div>
                )}

                <input
                    type="file"
                    id="avatar"
                    accept="image/*"
                    onChange={e => onFileUpload(e)}
                    data-file={file}
                />
            </label>
        </Container>
    );
}

AvatarInput.propTypes = {
    name: PropTypes.string.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
};
