/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
    MdFormatListBulleted,
    MdWarningAmber,
    MdAdd,
    MdDelete,
    MdAccountTree,
    MdSave,
    MdImage,
    MdDeleteSweep,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import { toJpeg } from 'html-to-image';
import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    DialogContentText,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import FlowView from '~/components/FlowView';
import FormCheckList from '~/components/Form/CheckList';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import pluginbot_logo from '~/assets/logo-dark.png';
import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkAdmin from '~/util/CheckAdmin';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';

import { FlowArea, FlowTree, ParameterArea } from './styles';

const action_types = lists.script_actions || {};

const script_robots = ['cruzr', 'temi', 'nao'];

const robot_navigation = {
    cruzr: true,
    temi: true,
};

const step_types = {
    start: <LocaleMessage msg="page.tools.scripts.step.type.start" />,
    step: <LocaleMessage msg="page.tools.scripts.step.type.step" />,
    end: <LocaleMessage msg="page.tools.scripts.step.type.end" />,
};

const step_order = {
    start: 1,
    step: 2,
    end: 100,
};

const allow_hide_buttons = false;

export default function ScriptForm(props) {
    const dispatch = useDispatch();
    const componentRef = useRef();

    const user_settings = useSelector(state => state.settings || null);
    const { profile } = useSelector(state => state.user || null);
    const date_loc = getDateLocale(user_settings);
    const super_user = checkAdmin(user_settings, profile);
    const { admin_groups, active } = user_settings;

    const { match } = props;
    const { id } = match.params;

    const [operation, setOperation] = useState('create');
    const [availableRobots, setAvailableRobots] = useState([]);

    const [body, setBody] = useState({
        name: '',
        group_id: active ? active.id : null,
        group_name: active ? active.name : '',
    });

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [needSaving, setNeedSaving] = useState(false);
    const [deleteStepOpen, setDeleteStepOpen] = useState('');

    const [rawSteps, setRawSteps] = useState([]);
    const [parsedSteps, setParsedSteps] = useState([]);
    const [stepTree, setStepTree] = useState({});
    const [stepPositions, setStepPositions] = useState(null);

    const [currStep, setCurrStep] = useState('new');

    const [mapList, setMapList] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [templateFile, setTemplateFile] = useState({});
    const [selectedRobots, setSelectedRobots] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    function saveImg() {
        if (componentRef.current === null) {
            return;
        }
        const flowControls = document.getElementsByClassName(
            'react-flow__panel react-flow__controls'
        );
        const flowBackground = document.getElementsByClassName(
            'react-flow__background'
        );
        const flowLogo = document.getElementsByClassName('flow-footer');

        if (flowControls && flowControls[0]) {
            flowControls[0].style.visibility = 'hidden';
        }
        if (flowBackground && flowBackground[0]) {
            flowBackground[0].style.visibility = 'hidden';
        }
        if (flowLogo && flowLogo[0]) {
            flowLogo[0].style.visibility = 'visible';
        }

        toast.info(<LocaleMessage msg="message.generating_file" />);

        toJpeg(componentRef.current, { cacheBust: true })
            .then(dataUrl => {
                if (flowControls && flowControls[0]) {
                    flowControls[0].style.visibility = 'visible';
                }
                if (flowBackground && flowBackground[0]) {
                    flowBackground[0].style.visibility = 'visible';
                }
                if (flowLogo && flowLogo[0]) {
                    flowLogo[0].style.visibility = 'hidden';
                }
                const link = document.createElement('a');
                link.download = GetFileName(body.name, 'jpg');
                link.href = dataUrl;
                link.click();
            })
            .catch(() => {
                toast.error(<LocaleMessage msg="errors.unknown_error" />);
            });
    }

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getExitSetting(step_id, condition, exit) {
        let type = 'step';
        if (exit && exit === 'go-home') {
            type = 'end';
        }

        return {
            condition,
            type,
            to: exit || 'no-action',
            edge_id: `${step_id}_${condition}`,
        };
    }

    function getStepChildren(step) {
        const exit_settings =
            step.settings && step.settings.exits ? step.settings.exits : {};

        const children = [];
        const exits_keys = ['all', ...selectedRobots];

        exits_keys.forEach(r => {
            const rtype_exits = exit_settings[r] || {};
            const sensors_settings = rtype_exits.hardware || {};
            const timeout_settings = rtype_exits.timeout;
            const button_settings = rtype_exits.next;
            const fail_settings = rtype_exits.failed;

            const show_buttons =
                rtype_exits.show_buttons || false || !allow_hide_buttons;

            Object.keys(sensors_settings).forEach(k => {
                const exit = sensors_settings[k];
                children.push(getExitSetting(step.id, `${r}_${k}`, exit));
            });

            if (button_settings && show_buttons) {
                const exit = button_settings.action;
                children.push(getExitSetting(step.id, `${r}_next`, exit));
            }

            if (timeout_settings) {
                const exit = timeout_settings.action;
                children.push(getExitSetting(step.id, `${r}_timeout`, exit));
            }

            if (fail_settings) {
                const exit = fail_settings.action;
                children.push(getExitSetting(step.id, `${r}_failed`, exit));
            }
        });

        return children;
    }

    function parseStepTree() {
        if (!rawSteps || rawSteps.length === 0) {
            return;
        }

        const script_content = body && body.content ? body.content : {};

        const initial_pos =
            script_content.flow_view && script_content.flow_view.positions
                ? script_content.flow_view.positions
                : {};

        const parsed = {};
        const start_search = [];
        let step_search = [];

        rawSteps.forEach(s => {
            const exits = getStepChildren(s);
            if (s.type === 'start') {
                exits.forEach(e => {
                    start_search.push(e);
                });
            }
            parsed[s.id] = {
                id: s.id,
                type: s.type,
                name: s.description,
                exits,
                level: s.type === 'start' ? 0 : -1,
                position: initial_pos[s.id] || null,
            };
        });

        step_search = start_search;
        let lvl = 1;
        do {
            const children = [];
            step_search.forEach(s => {
                const s_id = s.to;
                const step = parsed[s_id];
                if (step && step.level === -1) {
                    const { exits: step_exits } = step;
                    parsed[s_id] = {
                        ...step,
                        level: lvl,
                    };
                    step_exits.forEach(e => {
                        children.push(e);
                    });
                }
            });
            step_search = children;
            lvl++;
        } while (
            lvl <= rawSteps.length &&
            step_search &&
            step_search.length > 0
        );

        const hierarchy = {};
        Object.keys(parsed).forEach(s => {
            const step = parsed[s];
            const { level } = step;
            if (hierarchy[level]) {
                hierarchy[level].push(step);
            } else {
                hierarchy[level] = [step];
            }
        });
        setStepTree(hierarchy);
    }

    function parseStepList() {
        const script_settings = body.content || {};
        const script_navigation =
            script_settings.navigation && script_settings.navigation.active
                ? script_settings.navigation
                : {};

        const items = rawSteps.map(s => {
            const order = step_order[s.type] || 10;

            const s_content = s.content ? s.content : {};
            const updated = new Date(s.updated);

            let alert = '';
            if (s_content.action) {
                if (s_content.action.type === 'navigate') {
                    const navigation = s_content.action.navigation || {};
                    selectedRobots.forEach(r => {
                        const action_map =
                            navigation[r] && navigation[r].map_id
                                ? navigation[r].map_id
                                : '';
                        const action_point =
                            navigation[r] && navigation[r].point_id
                                ? navigation[r].point_id
                                : '';
                        if (
                            !script_navigation[r] ||
                            script_navigation[r].map_id !== action_map ||
                            !action_point
                        ) {
                            alert = true;
                        }
                    });
                }
            }

            return {
                ...s,
                label: s.description || '---',
                order,
                type_label: step_types[s.type],
                action_type:
                    s_content.action &&
                    s_content.action.type &&
                    action_types[s_content.action.type] ? (
                        <>
                            {alert ? (
                                <MdWarningAmber
                                    size={20}
                                    className="mr-2"
                                    color="#f00"
                                />
                            ) : null}
                            <LocaleMessage
                                msg={
                                    action_types[s_content.action.type]
                                        .label_code
                                }
                            />
                        </>
                    ) : null,
                updated: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: date_loc,
                }),
                updated_timestamp: updated.toISOString(),
            };
        });
        setParsedSteps(items);
    }

    async function loadLanguages() {
        await api
            .get(`applications/voices`)
            .then(response => {
                const v = response.data;
                const lang_list = [];
                Object.keys(v).forEach(t => {
                    const t_voices = v[t];
                    t_voices.forEach(voice => {
                        lang_list.push({
                            type: t,
                            key: `${t}_${voice.value}`,
                            ...voice,
                        });
                    });
                });
                setLanguages(lang_list);
            })
            .catch(err => requestError(err));
    }

    async function loadAvailableRobots() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const { data } = response;
                const robots = data.filter(s_r => {
                    return script_robots.includes(s_r.slug);
                });
                setAvailableRobots(robots || []);
            })
            .catch(error => requestError(error));
    }

    async function loadScript(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`scripts/${_id}`)
                .then(response => {
                    const s = response.data;
                    const r_types = s.robot_types || [];
                    const sel_robots = r_types.map(r => {
                        return r.slug;
                    });
                    setSelectedRobots(sel_robots);

                    setBody({
                        name: s.name,
                        description: s.description,
                        robottype_id: s.robot ? s.robot.id : null,
                        group_id: s.group ? s.group.id : null,
                        group_name: s.group ? s.group.name : '---',
                        open_all: s.open_all,
                        content: s.content,
                    });
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadScriptSteps(_id) {
        if (_id !== 'new') {
            await api
                .get(`/scripts/${_id}/steps`)
                .then(response => {
                    const { data } = response;
                    setRawSteps(data);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadMaps() {
        if (body.group_id !== '*') {
            setIsLoading(true);
            await api
                .get(`map_layers`)
                .then(async response => {
                    const filtered = response.data.filter(m => {
                        return m.group && m.group.id === body.group_id;
                    });
                    const data = filtered.map(m => ({
                        ...m,
                        name: m.map ? m.map.name : '',
                        label: `${m.map ? m.map.name : ''} [${m.reference}]`,
                        r_type: m.robot_type ? m.robot_type.slug : '',
                    }));
                    setMapList(data);
                })
                .catch(error => requestError(error));
        } else {
            setMapList([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadAvailableRobots();
        loadLanguages();
    }, []);

    useEffect(() => {
        loadScript(id);
        loadScriptSteps(id);
    }, [id]);

    useEffect(() => {
        parseStepTree();
    }, [body.content, rawSteps]);

    useEffect(() => {
        parseStepList();
    }, [body.content, rawSteps, selectedRobots]);

    useEffect(() => {
        loadMaps();
    }, [body.robottype_id, body.group_id]);

    async function updateScript(data) {
        await api
            .put(`scripts/${id}`, data)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.scripts.form.update_success" />
                );
                await Promise.all([loadScript(id), loadScriptSteps(id)]);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = {
            ...body,
            robot_types: selectedRobots,
        };

        setIsLoading(true);
        if (operation === 'create') {
            await api
                .post(`scripts`, data)
                .then(response => {
                    const s_data = response.data;
                    toast.success(
                        <LocaleMessage msg="page.tools.scripts.form.create_success" />
                    );

                    history.push(`/tools/scripts/${s_data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateScript({
                ...data,
                content: {
                    ...body.content,
                    ...{ flow_view: { positions: stepPositions } },
                },
            });
        }
    }

    async function deleteStepMulti() {
        const step_list = JSON.stringify(selectedRows);
        await api
            .delete(`scripts/${id}/steps?list=${step_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.scripts.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteStep(step) {
        await api
            .delete(`scripts/${id}/steps/${step}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.scripts.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteSteps() {
        setIsLoading(true);
        if (deleteStepOpen === 'single') {
            await deleteStep(currStep);
        } else {
            await deleteStepMulti();
        }
        await loadScriptSteps(id);
        setDeleteStepOpen('');
        setSelectedRows([]);
        setIsLoading(false);
    }

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const fileContents = e.target.result;
            const template = JSON.parse(fileContents);

            setTemplateFile({
                id: fileObj.name,
                name: fileObj.name,
            });

            if (template.content_type && template.content_type === 'script') {
                setSelectedRobots(template.robot_types);
                setBody({
                    ...body,
                    ...(!body.name && { name: template.name }),
                    ...(!body.description && {
                        description: template.description,
                    }),
                    type: 'scripting',
                    content: template.content || {},
                    template: {
                        steps: template.steps,
                    },
                });
            } else {
                toast.error(
                    <LocaleMessage msg="page.tools.scripts.form.import_error" />
                );
            }
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        reader.onload = fileloaded;
        reader.readAsText(fileObj);
    };

    function setContent(key, value) {
        const content = body.content || {};

        setBody({
            ...body,
            content: {
                ...content,
                [key]: value,
            },
        });
    }

    function handleDeleteStepClose(event) {
        event.preventDefault();
        setSelectedRows([]);
        setDeleteStepOpen('');
    }

    function handleDeleteMultiOpen(event) {
        setCurrStep(null);
        event.preventDefault();
        setDeleteStepOpen('multi');
    }

    function handleDeleteOpen(event, _id) {
        setCurrStep(_id);
        event.preventDefault();
        setDeleteStepOpen('single');
        setSelectedRows([]);
    }

    function handleStepSelection(event, _id) {
        setSelectedRows([]);
        setCurrStep(_id);
        history.push(`/tools/scripts/${id}/steps/${_id}`);
    }

    function buildDeleteStepDialog() {
        return (
            <SimpleDialog
                open={!!deleteStepOpen}
                onClose={handleDeleteStepClose}
                title={
                    <LocaleMessage msg="page.tools.scripts.step.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteStepOpen(''),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteSteps(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildStepList() {
        const headCells = [
            {
                id: 'type_label',
                label: <LocaleMessage msg="table.headers.type" />,
                order_by: 'order',
            },
            {
                id: 'label',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'action_type',
                label: <LocaleMessage msg="table.headers.action" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    data={parsedSteps}
                    orderColumn="order"
                    headerColumns={headCells}
                    rowActions={rowActions}
                    handleTableRowClick={(event, _id) => {
                        handleStepSelection(event, _id);
                    }}
                    hasHeader
                    header={
                        <div className="col-6" style={{ padding: '0px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={event =>
                                    handleStepSelection(event, 'new')
                                }
                            >
                                <MdAdd
                                    size={20}
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                                <LocaleMessage msg="page.tools.scripts.step.list.add" />
                            </Button>
                        </div>
                    }
                    sortable
                    selectable
                    selectedActions={selAction}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    function renderSUConfig() {
        return operation === 'create' ? (
            <div className="col-12 mb-5">
                <FormControlLabel
                    control={
                        <Switch
                            disabled={operation === 'update'}
                            color="primary"
                            checked={body.open_all || false}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    open_all: event.target.checked,
                                    group_id: '*',
                                })
                            }
                        />
                    }
                    label={<LocaleMessage msg="label.form.open_all" />}
                />
            </div>
        ) : (
            <>
                {body.open_all ? (
                    <div
                        className="col-12 mb-5"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Chip
                            className="mb-3"
                            variant="outlined"
                            color="primary"
                            label={
                                <LocaleMessage msg="message.open_all.title" />
                            }
                        />
                    </div>
                ) : null}
            </>
        );
    }

    function renderRobotSelector() {
        return (
            <>
                <LocaleMessage msg="label.form.robot_type" />
                <br />
                <FormCheckList
                    collapsable
                    options={availableRobots}
                    optionKey="slug"
                    settings={{ list: selectedRobots }}
                    multiple
                    allowStar={false}
                    onChange={r => {
                        setSelectedRobots(r.list || []);
                    }}
                    disabled={body.use_template}
                />
            </>
        );
    }

    function renderLanguageConfig() {
        const options = languages.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={`list.languages.${l.value}`} />,
            };
        });

        const content = body.content || {};

        return (
            <>
                <LocaleMessage msg="page.tools.scripts.form.label.languages" />
                <FormCheckList
                    collapsable
                    options={options}
                    settings={content.languages || {}}
                    multiple
                    allowStar
                    onChange={l => {
                        setContent('languages', l);
                    }}
                    disabled={body.use_template}
                />
            </>
        );
    }

    function renderTypeNavigation(type, navigation) {
        const r_filtered = mapList.filter(m => {
            return !m.r_type || m.r_type === type;
        });
        const r_navigation = navigation[type] || {};

        return (
            <ParameterArea className="mb-1" key={`script_nav_${type}`}>
                <div className="col-md-4 col-12">{type.toUpperCase()}</div>

                <div className="col-md-8 col-12 mb-1">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.tools.scripts.form.label.navigation.map" />
                        </InputLabel>
                        <Select
                            value={
                                robot_navigation[type]
                                    ? r_navigation.map_id || ''
                                    : 'not_available'
                            }
                            onChange={event =>
                                setContent('navigation', {
                                    ...navigation,
                                    [type]: {
                                        map_id: event.target.value,
                                    },
                                })
                            }
                            disabled={!robot_navigation[type]}
                        >
                            {robot_navigation[type] ? (
                                r_filtered.map(m => (
                                    <MenuItem key={m.id} value={m.id}>
                                        {m.label}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="not_available">
                                    <LocaleMessage msg="label.form.not_available" />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </ParameterArea>
        );
    }

    function renderNavigationConfig() {
        const content = body.content || {};
        const navigation = content.navigation || {};

        const has_nav_robot = !!selectedRobots.find(r => {
            return robot_navigation[r];
        });

        return (
            <div className="col-12 mb-3">
                <div className="col-12 mb-1">
                    <FormSwitch
                        value={
                            has_nav_robot && navigation.active
                                ? navigation.active
                                : false
                        }
                        onChange={event =>
                            setContent('navigation', {
                                ...navigation,
                                active: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.tools.scripts.form.label.navigation.active" />
                        }
                        disabled={body.group_id === '*' || !has_nav_robot}
                    />
                </div>
                {navigation.active && body.group_id !== '*'
                    ? selectedRobots.map(r => {
                          return renderTypeNavigation(r, navigation);
                      })
                    : null}
            </div>
        );
    }

    function renderGroupSelector() {
        const group_list = admin_groups || [
            {
                id: active ? active.id : null,
                name: active ? active.name : '',
            },
        ];
        return (
            <div className="col-md-6 col-12 mb-5">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.group" />
                    </InputLabel>
                    <Select
                        id="group"
                        value={
                            body.group_id && body.group_id ? body.group_id : ''
                        }
                        onChange={event =>
                            setBody({
                                ...body,
                                group_id: event.target.value,
                            })
                        }
                        disabled={operation === 'update' || body.open_all}
                    >
                        {operation === 'create' ? (
                            group_list.map(g => (
                                <MenuItem value={g.id} key={g.id}>
                                    {g.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value={body.group_id}>
                                {body.group_name ? (
                                    body.group_name
                                ) : (
                                    <LocaleMessage msg="message.all.short" />
                                )}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function renderFlowView() {
        return (
            <FlowArea>
                <FlowTree ref={componentRef}>
                    <FlowView
                        steps={parsedSteps || []}
                        tree={stepTree || {}}
                        controlPosition="top-right"
                        onClick={(event, step_id) =>
                            handleStepSelection(event, step_id)
                        }
                        savePositions={p => {
                            setNeedSaving(true);
                            setStepPositions(p);
                        }}
                    />
                    <div className="flow-footer">
                        <img
                            className="pluginbot-logo"
                            src={pluginbot_logo}
                            alt="Pluginbot Logo"
                            title="Pluginbot Logo"
                        />
                    </div>
                </FlowTree>
                <div className="col-3 flow-buttons">
                    {needSaving ? (
                        <Tooltip
                            title={
                                <LocaleMessage msg="page.survey_steps.list.flow.save" />
                            }
                            placement="right"
                        >
                            <Fab
                                className="mb-3"
                                color="primary"
                                onClick={e => handleSubmit(e)}
                            >
                                <MdSave style={{ color: '#fff' }} size={24} />
                            </Fab>
                        </Tooltip>
                    ) : null}
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.survey_steps.list.flow.export" />
                        }
                        placement="right"
                    >
                        <Fab
                            className="mb-3"
                            color="primary"
                            onClick={() => saveImg()}
                        >
                            <MdImage style={{ color: '#fff' }} size={24} />
                        </Fab>
                    </Tooltip>
                </div>
            </FlowArea>
        );
    }

    function renderImportTemplate() {
        return (
            <>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={body.use_template || false}
                        onChange={event =>
                            setBody({
                                ...body,
                                use_template: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.tools.scripts.form.template.use" />
                        }
                    />
                </div>
                {body.use_template ? (
                    <div
                        className="col-12 mb-5"
                        style={{
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                height: 'max-content',
                            }}
                            className="mb-3"
                        >
                            <FileInput
                                defaultValue={templateFile || {}}
                                onFileUpload={onFileUpload}
                                text={
                                    <LocaleMessage msg="label.form.upload_file" />
                                }
                                type="json"
                                multiple={false}
                                showFooter={false}
                                showIcon={false}
                            />
                        </div>
                        <div className="mt-1 mb-3">
                            <LocaleMessage msg="page.tools.scripts.form.template.warning" />
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={`page.tools.scripts.form.${
                        operation === 'create' ? 'create' : 'edit'
                    }.title`}
                />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/scripts',
                    title: <LocaleMessage msg="breadcrumbs.scripts" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteStepOpen ? buildDeleteStepDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`col-md-${
                            operation === 'update' ? '6' : '8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.scripts.form.title" />
                            }
                            Icon={MdFormatListBulleted}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    {super_user ? renderSUConfig() : null}

                                    {!body.open_all
                                        ? renderGroupSelector()
                                        : null}

                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.tools.scripts.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.tools.scripts.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    {operation === 'create'
                                        ? renderImportTemplate()
                                        : null}

                                    <div className="col-12 mb-3 row">
                                        <div className="col-md-6 col-12">
                                            {renderLanguageConfig()}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            {renderRobotSelector()}
                                        </div>
                                    </div>

                                    {!(
                                        operation === 'create' &&
                                        body.use_template
                                    ) ? (
                                        <>{renderNavigationConfig()}</>
                                    ) : null}

                                    <div className="col-12">
                                        <Button
                                            className="p-3 mb-4"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.tools.scripts.step.list.title" />
                                    }
                                    hide
                                    Icon={MdFormatListBulleted}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <>{buildStepList()}</>
                                        </div>
                                    </div>
                                </CardSideBordered>
                            </div>
                            <div className="col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.tools.scripts.form.flow" />
                                    }
                                    Icon={MdAccountTree}
                                    hide
                                >
                                    <div className="col-12 row">
                                        {renderFlowView()}
                                    </div>
                                </CardSideBordered>
                            </div>
                        </>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

ScriptForm.propTypes = {
    match: PropTypes.object.isRequired,
};
