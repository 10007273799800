/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box, Typography, CircularProgress } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';

const h100 = { height: '100%' };
const w100 = { width: '100%' };

export default function OperationCard({ width, options, robot }) {
    const status = robot.status || {};
    const { operation } = status;
    const op_mode = operation && operation.mode ? operation.mode : 'idle';
    const progress =
        operation && operation.progress ? Number(operation.progress) : 0;
    const op_prog =
        progress >= 0 && progress <= 100 ? `${Math.round(progress)}%` : '---';

    const label =
        options && options.label
            ? options.label
            : 'rc3.robot.data.card.operation.mode';

    return (
        <div className={width} style={h100}>
            <Box style={h100}>
                <InfoCard>
                    <div className="desc-full info">
                        <div className="jr-revenu-col info" style={w100}>
                            <span className="jr-fs-md title">
                                <LocaleMessage msg={label} />
                            </span>
                            <span className="big-value">
                                {op_mode && op_mode !== 'idle' ? (
                                    <LocaleMessage
                                        msg={`list.operations.mode.${op_mode}`}
                                    />
                                ) : (
                                    '---'
                                )}
                            </span>
                        </div>
                    </div>
                    {op_mode && op_mode !== 'idle' ? (
                        <div className="desc-full">
                            <span className="jr-fs-md title">
                                <LocaleMessage msg="rc3.robot.data.card.operation.progress" />
                            </span>
                            <div className="info info-chart">
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress
                                        variant="determinate"
                                        value={progress}
                                        size="8vh"
                                        thickness={5}
                                    />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography
                                            variant="button"
                                            component="div"
                                            style={{ fontSize: '1.75vh' }}
                                        >
                                            {op_prog}
                                        </Typography>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    ) : null}
                </InfoCard>
            </Box>
        </div>
    );
}

OperationCard.propTypes = {
    width: PropTypes.string,
    options: PropTypes.object,
    robot: PropTypes.object,
};

OperationCard.defaultProps = {
    width: 'col-12',
    options: {},
    robot: {},
};
