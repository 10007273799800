/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdCall, MdCallEnd } from 'react-icons/md';

import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import AlertExtras from '~/pages/RC3/Alerts/Extras';
import RobotItem from '~/pages/RC3/Components/RobotItem';
import { answerCall } from '~/pages/RC3/lib/ExtFunctions';

import { Title, Container, Actions } from '../styles';

export default function CallRequest({
    user,
    robot,
    robotType,
    content,
    alert_id,
    removeAlert,
    sendNotification,
    requestError,
}) {
    const size = 20;
    const profile = user.profile || {};

    async function answerTelepCall(accept = true) {
        answerCall(
            {
                accept,
                alert_id,
            },
            requestError
        );
    }

    function acceptCall() {
        sendNotification({
            type: 'call_accepted',
            robot,
            data: { alert_id, user_id: profile.id, user: profile.name },
        });
        answerTelepCall(true);
        removeAlert(true);
    }

    function rejectCall() {
        answerTelepCall(false);
        removeAlert(true);
    }

    const { location, zone, destination } = content;
    const location_txt = `${location ? location.name : ''} ${
        location && zone && zone.name ? ` - ${zone.name}` : ''
    }`;

    const destination_txt =
        destination && destination.name ? (
            <>
                <LocaleMessage msg="list.telepresence.request.mode.contact" />
                <span>: {destination.name}</span>
            </>
        ) : (
            <LocaleMessage msg="list.telepresence.request.mode.broadcast" />
        );

    const extras = [
        {
            key: 'location',
            value: location_txt,
        },
        {
            key: 'destination',
            value: destination_txt,
        },
    ];

    return (
        <Container>
            <Title className="col-12 p-1" fontSize="1.25vh">
                <strong>
                    <LocaleMessage msg="rc3.notifications.telepresence.incoming_call.title" />
                </strong>
            </Title>
            <Actions
                className="col-12 row"
                justify="space-around"
                align="center"
            >
                <div className="col-8" style={{ padding: '0px' }}>
                    <RobotItem
                        active
                        key={robot.id}
                        robot={robot}
                        type={robotType}
                        showStatus={false}
                        size="4.5vh"
                        fontSize="1.5vh"
                    />
                </div>
                <div
                    className="col-4"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        padding: '0px 10px',
                    }}
                >
                    <IconButton
                        className="pulse"
                        style={{
                            backgroundColor: '#f00',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            rejectCall();
                        }}
                    >
                        <MdCallEnd size={size} />
                    </IconButton>
                    <IconButton
                        className="pulse"
                        style={{
                            backgroundColor: '#0f0',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => {
                            acceptCall();
                        }}
                    >
                        <MdCall size={size} />
                    </IconButton>
                </div>
                <div className="col-12" style={{ textAlign: 'center' }}>
                    <AlertExtras infos={extras} />
                </div>
            </Actions>
        </Container>
    );
}

CallRequest.propTypes = {
    user: PropTypes.object,
    robot: PropTypes.object,
    robotType: PropTypes.object,
    content: PropTypes.object,
    alert_id: PropTypes.string,
    removeAlert: PropTypes.func.isRequired,
    sendNotification: PropTypes.func,
    requestError: PropTypes.func.isRequired,
};

CallRequest.defaultProps = {
    user: {},
    robot: {},
    robotType: {},
    content: {},
    alert_id: '',
    sendNotification: () => {},
};
