/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import ApplicationInfo from '../Applications';
import BillingList from '../Billing/List';
import GroupInfo from '../Groups';
import LocationInfo from '../Locations';
import MapInfo from '../Maps';
import NullInfo from '../Null';
import RobotInfo from '../Robots/List';
import UserInfo from '../Users';
import { Container } from './styles';

const pages = {
    groups: GroupInfo,
    robots: RobotInfo,
    users: UserInfo,
    applications: ApplicationInfo,
    locations: LocationInfo,
    maps: MapInfo,
    billing: BillingList,
};

export default function SU_Info(props) {
    const { match } = props;
    const { info } = match.params;

    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };
    const formats = {
        date: date_opt,
        time: time_opt,
        format: _settings.locale.format,
        locale: date_loc,
    };

    const [isLoading, setIsLoading] = useState(true);
    const [pluginspaces, setPluginspaces] = useState([]);
    const [currPluginspace, setCurrPluginspace] = useState(null);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPluginspaces() {
        setIsLoading(true);
        await api
            .get(`admin/pluginspaces`)
            .then(response => {
                const data = response.data.map(p => {
                    const updated = new Date(p.updated);
                    return {
                        ...p,
                        status: p.active ? (
                            <LocaleMessage msg="info.status.active" />
                        ) : (
                            <LocaleMessage msg="info.status.inactive" />
                        ),
                        created: new Date(p.created).toLocaleDateString(
                            _settings.locale.format,
                            date_opt
                        ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setPluginspaces(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadPluginspaces();
    }, [date_loc]);

    function renderDialog({ open, onClose, title, content, actions }) {
        return (
            <div>
                <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>{content}</DialogContent>
                    <DialogActions>{actions}</DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderCentral() {
        const Info = pages[info] || NullInfo;

        return (
            <Info
                filter={currPluginspace}
                formats={formats}
                requestError={error => requestError(error)}
                renderDialog={settings => renderDialog(settings)}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg={`page.su_${info}.title`} />}
            breadcrumbs={[
                {
                    url: '/admin/analytics',
                    title: <LocaleMessage msg="breadcrumbs.analytics" />,
                },
            ]}
            loading={isLoading}
        >
            <div className="col-12 mb-3">
                <Container>
                    <div className="col-12 mb-3 mt-1 row">
                        <div className="col-md-4">
                            <FormSelect
                                options={pluginspaces}
                                labelKey="name"
                                label={
                                    <LocaleMessage msg="page.su_analytics.pluginspace_filter" />
                                }
                                onChange={value => {
                                    setCurrPluginspace(value);
                                }}
                                value={currPluginspace || ''}
                            />
                        </div>
                    </div>
                    <div className="col-12 row">{renderCentral()}</div>
                </Container>
            </div>
        </PageContent>
    );
}

SU_Info.propTypes = {
    match: PropTypes.object.isRequired,
};
