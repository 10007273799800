/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

export default function ScriptDestinations({
    handleError,
    robotType,
    settings,
    setSettings,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [destinations, setDestinations] = useState([]);

    async function loadScripts(r_id) {
        if (r_id) {
            setIsLoading(true);
            await api
                .get(`scripts?robot=${r_id}`)
                .then(response => {
                    const { data } = response;
                    setDestinations(data);
                })
                .catch(error => handleError(error));
        } else {
            setDestinations([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadScripts(robotType.id);
    }, [robotType]);

    function saveBody(key, value) {
        setSettings({
            ...settings,
            [key]: value,
        });
    }

    return isLoading ? (
        <ReactLoading type="bars" color="#c8c8c8" height={50} width={50} />
    ) : (
        <div
            className="row col-12"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className="col-md-5 col-12">
                <FormControl className="mb-5" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.conversation.triggers.identifiers.script" />
                    </InputLabel>
                    <Select
                        value={settings.script_id ? settings.script_id : ''}
                        onChange={event =>
                            saveBody('script_id', event.target.value)
                        }
                    >
                        {destinations.map(s => {
                            return (
                                <MenuItem value={s.id} key={s.id}>
                                    {s.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

ScriptDestinations.defaultProps = {};

ScriptDestinations.propTypes = {
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    robotType: PropTypes.object.isRequired,
};
