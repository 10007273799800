/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import api from '~/services/pluginbot-api';

import MapList from '../Components/MapList';
import { AppRow } from '../styles';
import RoomData from './RoomData';

export default function DisinfectionsPage({
    robots,
    settings,
    locations,
    zones,
    robotStatus,
    requestError,
    lastMessage,
}) {
    const [currMapId, setCurrMapId] = useQueryState('map', '');
    const [currMapObj, setCurrMapObj] = useState(null);
    const [maps, setMaps] = useState([]);

    async function loadMaps() {
        await api
            .get(`rc3/maps?application=disinfection`)
            .then(response => {
                const { data } = response;
                setMaps(data);
            })
            .catch(error => requestError(error));
    }

    function findMap(id) {
        if (maps && id) {
            const currMap = maps.find(m => {
                return m.id === id;
            });
            return currMap;
        }
        return null;
    }

    const location_zones = {};
    zones.forEach(z => {
        const loc_id = z.location && z.location.id ? z.location.id : null;
        const zone = {
            id: z.id,
            name: z.name,
        };

        if (loc_id) {
            if (!location_zones[loc_id]) {
                location_zones[loc_id] = [];
            }
            location_zones[loc_id].push(zone);
        }
    });

    const location_list = locations.map(l => {
        return {
            ...l,
            zones: location_zones[l.id] || [],
        };
    });

    useEffect(() => {
        setCurrMapObj(findMap(currMapId));
    }, [maps, currMapId]);

    useEffect(() => {
        loadMaps();
    }, []);

    return (
        <AppRow>
            <MapList
                locations={location_list}
                maps={maps}
                currentActive={currMapId || ''}
                onItemClick={(event, m) => {
                    setCurrMapId(m ? m.id : null);
                }}
            />
            <RoomData
                robots={robots}
                settings={settings}
                status={robotStatus}
                object={currMapObj}
                clearSelection={() => {
                    setCurrMapId('');
                }}
                message={lastMessage}
                requestError={error => requestError(error)}
            />
        </AppRow>
    );
}

DisinfectionsPage.propTypes = {
    robots: PropTypes.array,
    settings: PropTypes.object,
    locations: PropTypes.array,
    zones: PropTypes.array,
    robotStatus: PropTypes.object,
    requestError: PropTypes.func,
    lastMessage: PropTypes.object,
};

DisinfectionsPage.defaultProps = {
    robots: [],
    locations: [],
    zones: [],
    settings: null,
    robotStatus: null,
    requestError: () => {},
    lastMessage: null,
};
