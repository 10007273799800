/* eslint-disable no-plusplus */
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetOperationTime from '~/util/GetOperationTime';
import lng_labels from '~/util/LangMessages';

const labels = {
    all: 'label.all',
};

export default function filter(data, format, settings = {}) {
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const a_groups = {};
    const a_robots = {};
    const a_applications = {};
    const a_users = {};
    const a_contacts = {};
    const a_answered = {
        accepted: {
            id: 'accepted',
            total: 0,
        },
        rejected: {
            id: 'rejected',
            total: 0,
        },
    };
    const a_rejections = {};

    let duration_sum = 0;
    let has_time = 0;

    const list = data.map(c => {
        const c_time = c.time && c.time > 0 ? c.time : 0;
        if (c_time) {
            has_time++;
        }
        duration_sum += c_time;

        const group_id = c.group_id || '---';
        a_groups[group_id] = a_groups[group_id]
            ? {
                  ...a_groups[group_id],
                  total: a_groups[group_id].total + 1,
                  time: a_groups[group_id].time + c_time,
              }
            : {
                  id: group_id,
                  name: c.group_name,
                  total: 1,
                  time: c_time,
              };

        const robot_id = c.robot_id || '---';
        a_robots[robot_id] = a_robots[robot_id]
            ? {
                  ...a_robots[robot_id],
                  total: a_robots[robot_id].total + 1,
                  time: a_robots[robot_id].time + c_time,
              }
            : {
                  id: a_robots,
                  name: c.robot_name,
                  total: 1,
                  time: c_time,
              };

        const application_id = c.application_id || '---';
        a_applications[application_id] = a_applications[application_id]
            ? {
                  ...a_applications[application_id],
                  total: a_applications[application_id].total + 1,
                  time: a_applications[application_id].time + c_time,
              }
            : {
                  id: application_id,
                  name: c.application_name,
                  total: 1,
                  time: c_time,
              };

        const { answered, contact } = c;
        const answers = c.answers || [];
        const rejections = answers.filter(a => {
            return !a.accepted;
        });
        const req_ans = answered
            ? answers.find(a => {
                  return a.accepted;
              })
            : null;

        const ans_usr = req_ans ? req_ans.user : {};
        const ans_id = ans_usr.id || false;

        const c_key = answered ? 'accepted' : 'rejected';
        a_answered[c_key] = {
            ...a_answered[c_key],
            total: a_answered[c_key].total + 1,
        };

        if (ans_id) {
            if (!a_users[ans_id]) {
                a_users[ans_id] = {
                    id: `ans_${ans_id}`,
                    name: ans_usr.name || '',
                    total: 0,
                };
            }
            a_users[ans_id] = {
                ...a_users[ans_id],
                total: a_users[ans_id].total + 1,
            };
        }

        const to_contact = contact || {};
        const contact_id = to_contact.id || '*';

        if (contact_id) {
            if (!a_contacts[contact_id]) {
                a_contacts[contact_id] = {
                    id: `contact_${contact_id}`,
                    name: to_contact.name || '',
                    total: 0,
                };
            }
            a_contacts[contact_id] = {
                ...a_contacts[contact_id],
                total: a_contacts[contact_id].total + 1,
            };
        }

        const rejection_list = rejections
            ? rejections.map(v => {
                  const { user: a_user } = v;
                  const r_datetime = GetDateTimeLabel(new Date(v.date), {
                      format,
                  });

                  const u_id = a_user.id;
                  if (!a_rejections[u_id]) {
                      a_rejections[u_id] = {
                          id: `rej_${u_id}`,
                          name: a_user.name || '',
                          total: 0,
                      };
                  }
                  a_rejections[u_id] = {
                      ...a_rejections[u_id],
                      total: a_rejections[u_id].total + 1,
                  };

                  return {
                      id: r_datetime.timestamp,
                      date_timestamp: r_datetime.timestamp,
                      date_str: r_datetime.string,
                      date: r_datetime.date,
                      time: r_datetime.time,
                      user_id: u_id,
                      user_name: a_user ? a_user.name : '',
                  };
              })
            : [];

        return {
            ...c,
            contact_id,
            contact_name:
                to_contact && to_contact.name
                    ? to_contact.name
                    : lng_all[labels.all],
            answered_id: ans_id,
            answered_name: ans_usr ? ans_usr.name : '---',
            rejections: rejection_list,
            rejections_length: rejections.length || 0,
        };
    });

    const group_grouped = Object.keys(a_groups).map(g => {
        return a_groups[g];
    });

    const robot_grouped = Object.keys(a_robots).map(r => {
        return a_robots[r];
    });

    const application_grouped = Object.keys(a_applications).map(a => {
        return a_applications[a];
    });

    const user_grouped = Object.keys(a_users).map(d => {
        return a_users[d];
    });

    const rejection_grouped = Object.keys(a_rejections).map(d => {
        return a_rejections[d];
    });

    const contact_grouped = Object.keys(a_contacts).map(c => {
        return a_contacts[c];
    });

    const mean_duration = (
        Math.round((duration_sum / has_time) * 10 || 0) / 10
    ).toFixed(1);

    const duration_time = GetOperationTime(duration_sum);
    const mean_time = GetOperationTime(mean_duration);

    const newFilteredData = {
        duration: duration_time.label,
        mean_duration: mean_time.label,
        data: list,
        groups: group_grouped,
        robots: robot_grouped,
        applications: application_grouped,
        users: user_grouped,
        answers: a_answered,
        rejections: rejection_grouped,
        contacts: contact_grouped,
    };

    return newFilteredData;
}
