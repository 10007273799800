/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Badge, Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';
import { Item } from './styles';

const h100 = { height: '100%' };
const center = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export default function LevelsCard({ width, options, robot }) {
    const { status } = robot;

    const { style, steps, label: custom_label, key } = options;

    const curr_level =
        status[key] && status[key].level ? status[key].level : null;

    function renderStepLevel(s) {
        return (
            <Item
                active={curr_level && curr_level === s.key}
                color={s.color}
                key={s.key}
            >
                <Badge
                    overlap="rectangular"
                    className={`level-${s.key} mr-3`}
                    badgeContent=" "
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                />
                <span className="level-title">
                    <LocaleMessage msg={s.label} />
                </span>
            </Item>
        );
    }

    function renderSteps() {
        return (
            <div
                className="col-12 row"
                style={{
                    ...center,
                    padding: '0px',
                    justifyContent: 'space-around',
                }}
            >
                {steps
                    ? steps.map(s => {
                          return renderStepLevel(s);
                      })
                    : null}
            </div>
        );
    }

    const functions = {
        step: renderSteps,
    };

    const renderFunction = functions[style];

    return (
        <div className={width} style={h100}>
            <Box boxShadow={0} style={h100}>
                <InfoCard
                    style={{
                        padding: '20px 10px',
                    }}
                >
                    <div className="desc-full info">
                        <span className="jr-fs-md title mb-3">
                            <LocaleMessage
                                msg={
                                    custom_label ||
                                    'rc3.robot.data.card.state.levels'
                                }
                            />
                        </span>
                        {renderFunction()}
                    </div>
                </InfoCard>
            </Box>
        </div>
    );
}

LevelsCard.propTypes = {
    width: PropTypes.string,
    options: PropTypes.object,
    robot: PropTypes.object,
};

LevelsCard.defaultProps = {
    width: 'col-12',
    options: {},
    robot: {},
};
