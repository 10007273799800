/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import {
    MdClose,
    MdArrowDownward,
    MdArrowUpward,
    MdSearch,
} from 'react-icons/md';
import { VscWarning } from 'react-icons/vsc';

import { differenceInSeconds, formatDistanceStrict } from 'date-fns';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import {
    Box,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    TextField,
    InputAdornment,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';
// import SVGMap from '~/components/SVGMap';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import {
    CentralPanel,
    Container,
    InfoRow,
    Header,
    Body,
    InfoCard,
    RoomList,
    MapContainer,
} from './styles';

const date_opt = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

const time_opt = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
};

const order_filters = [
    {
        value: 'timestamp',
        label: (
            <LocaleMessage msg="rc3.disinfection.filter.last_disinfection" />
        ),
    },
    {
        value: 'name',
        label: <LocaleMessage msg="rc3.disinfection.filter.name" />,
    },
    {
        value: 'mode',
        label: <LocaleMessage msg="rc3.disinfection.filter.mode" />,
    },
];

const WhiteTextField = withStyles({
    root: {
        color: 'white',
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const CustomSwitch = withStyles({
    switchBase: {
        color: '#fafafa',
        '&$checked + $track': {
            backgroundColor: '#fafafa',
        },
    },
    track: {
        backgroundColor: '#BDBDBD',
    },
})(Switch);

export default function RoomData({
    clearSelection,
    requestError,
    settings,
    robots,
    object,
    status,
    message,
}) {
    const date_format = settings.locale;
    const date_loc = getDateLocale(settings);

    const map_layers = object && object.layers ? object.layers : [];

    const map_content = object && object.content ? object.content : {};
    const map_illustration = map_content.illustration;

    const hasMap = !!(map_illustration && map_illustration.objects);
    const update_time = 10 * 1000;

    const [points, setPoints] = useState([]);
    const [filter, setFilter] = useState('');
    const [orderBy, setOrderBy] = useState(order_filters[0].value);
    const [orderDir, setOrderDir] = useState('desc');
    const [filteredData, setFilteredData] = useState([]);
    const [orderedData, setOrderedData] = useState(filteredData);
    const [isLoading, setIsLoading] = useState(false);
    const [currPoint, setCurrPoint] = useState(null);
    const [lastDisinfections, setLastDisinfections] = useState(null);
    const [currDisinfections, setCurrDisinfections] = useState({});
    const [pointDisinfections, setPointDisinfections] = useState({});
    const [seeUnknown, setSeeUnknown] = useState(true);
    const [now, setNow] = useState(new Date());

    // const view_points = map_points.map(p => {
    //     const position = p.position || { x: 0, y: 0 };
    //     return {
    //         id: p.id,
    //         label: p.name,
    //         x: position.x,
    //         y: position.y,
    //         onMouseOver: event => {
    //             setCurrPoint(p.id);
    //             event.target.setAttribute('cursor', 'pointer');
    //         },
    //         onMouseOut: event => {
    //             setCurrPoint(null);
    //             event.target.setAttribute('cursor', 'default');
    //         },
    //     };
    // });

    function getPoints() {
        const map_points = [];

        map_layers.forEach(l => {
            if (l.points) {
                l.points.forEach(p => {
                    const point_content = p.content || {};
                    const limit_h = point_content.limit_hours || 0;
                    const limit_m = point_content.limit_minutes || 0;

                    const limit_time = limit_h * 60 * 60 + limit_m * 60;
                    map_points.push({
                        ...p,
                        layer_id: l.id,
                        layer: l.reference || '---',
                        limit: limit_time,
                    });
                });
            }
        });
        setPoints(map_points);
    }

    function findRobot(_id) {
        return robots.find(r => {
            return r.id === _id;
        });
    }

    function getTimestamp(ts) {
        return ts
            ? ts.toLocaleDateString(date_format.format, {
                  ...date_opt,
                  ...time_opt,
              })
            : '';
    }

    async function loadLastDisinfections() {
        if (object && object.id) {
            setIsLoading(true);
            await api
                .get(`disinfections/last?map=${object.id}`)
                .then(response => {
                    const { data } = response;

                    const last = {};
                    Object.keys(data).map(d => {
                        const disinfection = data[d];
                        const time =
                            disinfection && disinfection.end
                                ? new Date(disinfection.end)
                                : new Date();
                        last[d] = {
                            ...disinfection,
                            time,
                            timestamp: getTimestamp(time),
                        };
                        return true;
                    });
                    setLastDisinfections(last);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        }
    }

    function getPointDisinfections() {
        const disinfections = {};
        points.forEach(p => {
            const curr_disinfection =
                currDisinfections && currDisinfections[p.id]
                    ? currDisinfections[p.id]
                    : null;
            const last_disinfection =
                lastDisinfections && lastDisinfections[p.id]
                    ? lastDisinfections[p.id]
                    : null;
            const point_disinfection = curr_disinfection || last_disinfection;
            if (!point_disinfection) {
                disinfections[p.id] = null;
                return;
            }

            const current =
                curr_disinfection ||
                (point_disinfection && !point_disinfection.end);

            const last_time = last_disinfection
                ? last_disinfection.time
                : new Date(0);
            const time =
                current && curr_disinfection
                    ? curr_disinfection.time
                    : last_time;

            const mode = point_disinfection
                ? point_disinfection.mode
                : 'undefined';
            const mode_str = (
                <LocaleMessage msg={`list.operations.mode.${mode}`} />
            );

            const robot = point_disinfection ? point_disinfection.robot : null;
            disinfections[p.id] = {
                ...point_disinfection,
                current,
                mode,
                mode_str,
                robot,
                time,
            };
        });

        setPointDisinfections(disinfections);
    }

    async function handleEvent(event) {
        if (!event || !object) {
            return;
        }
        const { robot_id, data, event_type, timestamp } = event;
        const d_mode = data && data.mode ? data.mode : 'idle';
        const d_map = data && data.map ? data.map : {};
        const d_point = data && data.map_point ? data.map_point : {};
        const time = timestamp ? new Date(timestamp) : new Date();
        const time_str = getTimestamp(time);

        if (d_map.id === object.id && d_point.id) {
            const robot = findRobot(robot_id);
            const disinfection = {
                mode: d_mode,
                map: d_map,
                map_point: d_point,
                robot: {
                    id: robot ? robot.id : null,
                    name: robot ? robot.name : '',
                },
                time,
                timestamp: time_str,
            };

            switch (event_type) {
                case 'start_disinfection': {
                    setCurrDisinfections({
                        ...currDisinfections,
                        [d_point.id]: disinfection,
                    });
                    setLastDisinfections({
                        ...lastDisinfections,
                        [d_point.id]: null,
                    });
                    break;
                }
                case 'end_disinfection': {
                    const old_disinfection = {
                        ...disinfection,
                        end: time,
                    };
                    setCurrDisinfections({
                        ...currDisinfections,
                        [d_point.id]: null,
                    });

                    setLastDisinfections({
                        ...lastDisinfections,
                        [d_point.id]: old_disinfection,
                    });
                    break;
                }
                default:
                    break;
            }
        }
    }

    function filterData(val) {
        if (val === '') {
            setFilteredData(points);
            return;
        }
        const f = val.toUpperCase();
        const f_data = points.filter(r => {
            const { name } = r;
            return name.toUpperCase().includes(f);
        });
        setFilteredData(f_data);
    }

    function desc(a, b, _orderBy) {
        if (b[_orderBy] < a[_orderBy]) {
            return -1;
        }
        if (b[_orderBy] > a[_orderBy]) {
            return 1;
        }
        if (b.timestamp < a.timestamp) {
            return -1;
        }
        if (b.timestamp > a.timestamp) {
            return 1;
        }
        return 0;
    }

    function getSorting(_order, _orderBy) {
        return _order === 'desc'
            ? (a, b) => desc(a, b, _orderBy)
            : (a, b) => -desc(a, b, _orderBy);
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const _order = cmp(a[0], b[0]);
            if (_order !== 0) return _order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function orderData(by, dir) {
        const data = filteredData.map(p => {
            const disinfection = pointDisinfections[p.id] || {};
            const { mode, current, time } = disinfection;
            return {
                ...p,
                current: current ? 1 : 0,
                mode: mode || '-',
                timestamp: time ? -time.getTime() : new Date(0).getTime(),
            };
        });

        const ordered = stableSort(data, getSorting(dir, by));
        setOrderedData(ordered);
    }

    const waitForFilter = useCallback(
        debounce(val => filterData(val), 300),
        [points]
    );

    useEffect(() => {
        getPointDisinfections();
    }, [currDisinfections, lastDisinfections]);

    useEffect(() => {
        getPoints();
    }, [object]);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, update_time);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        filterData(filter);
        loadLastDisinfections();
    }, [points]);

    useEffect(() => {
        handleEvent(message);
    }, [message]);

    useEffect(() => {
        orderData(orderBy, orderDir);
    }, [filteredData, pointDisinfections, orderBy, orderDir]);

    function LinearProgressWithLabel(props) {
        return (
            <Box
                display="flex"
                alignItems="center"
                style={{ width: '100%', marginBottom: '1.5vh' }}
            >
                <Box width="100%">
                    <LinearProgress
                        variant="determinate"
                        {...props}
                        style={{ height: '1.5vh' }}
                    />
                </Box>
            </Box>
        );
    }

    function handleFilterChange(val) {
        setFilter(val);
        waitForFilter(val);
    }

    function renderPointCard(p) {
        const limit = p.limit ? p.limit : null;

        const disinfection =
            pointDisinfections && pointDisinfections[p.id]
                ? pointDisinfections[p.id]
                : {};

        const {
            current,
            time,
            robot,
            mode_str,
            timestamp,
            mode,
        } = disinfection;
        const distance = time ? differenceInSeconds(now, time) : null;

        let unit = 'minute';
        if (distance > 60 * 60 && distance <= 24 * 60 * 60) {
            unit = 'hour';
        } else if (distance > 24 * 60 * 60) {
            unit = 'day';
        }

        const last_string = time
            ? `( ${formatDistanceStrict(new Date(time), new Date(), {
                  addSuffix: true,
                  locale: date_loc,
                  unit,
                  roundingMethod: 'floor',
              })} )`
            : '';

        const operation = {
            progress: 0,
        };

        if (current && robot && robot.id) {
            const d_status = status[robot.id];
            if (
                d_status &&
                d_status.map_point &&
                d_status.map_point.id === p.id
            ) {
                if (d_status.operation) {
                    const op = d_status.operation;
                    const progress =
                        op && op.progress ? Number(op.progress) : 0;
                    operation.progress =
                        progress >= 0 && progress <= 100 ? progress : 0;
                }
            }
        }

        return mode || seeUnknown ? (
            <div
                className={`mb-5 ${hasMap ? 'col-md-4' : 'col-md-3'} col-12`}
                key={`card_${p.id}`}
            >
                <InfoCard
                    active={p.id === currPoint}
                    onMouseOver={() => setCurrPoint(p.id)}
                    onMouseOut={() => setCurrPoint(null)}
                >
                    <div className="side-padding info-area text-left">
                        <div className="title-row row">
                            <div className="col-9 title-area">
                                <span className="info-title">
                                    {p.name.toUpperCase()}
                                </span>
                                <div className="info-row">
                                    <span className="info-text">
                                        {current ? (
                                            <LocaleMessage msg="rc3.disinfection.card.in_operation" />
                                        ) : (
                                            <LocaleMessage msg="rc3.disinfection.card.last_operation" />
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="col-3 icon-area"
                                style={{
                                    color: `yellow`,
                                }}
                            >
                                {!current ? (
                                    <>
                                        {(!!limit &&
                                            distance &&
                                            distance >= limit) ||
                                        !time ? (
                                            <VscWarning
                                                style={{
                                                    fontSize: '5vh',
                                                }}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className="info-area">
                            {current ? (
                                <LinearProgressWithLabel
                                    value={
                                        operation && operation.progress
                                            ? operation.progress
                                            : 0
                                    }
                                />
                            ) : (
                                <span className="info-text">
                                    {timestamp ? (
                                        <>
                                            {timestamp}
                                            <br />
                                            {last_string}
                                        </>
                                    ) : (
                                        <>
                                            <LocaleMessage msg="label.unknown" />
                                            <br />
                                        </>
                                    )}
                                </span>
                            )}
                            <span className="info-text">
                                {robot ? (
                                    <>
                                        <LocaleMessage msg="rc3.disinfection.card.mode" />
                                        {mode_str}
                                        <span className="info-item">
                                            {robot ? (
                                                robot.name
                                            ) : (
                                                <LocaleMessage msg="label.unknown" />
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </span>
                        </div>
                    </div>
                </InfoCard>
            </div>
        ) : null;
    }

    function renderMapIllustration() {
        return (
            <MapContainer>
                {/* <SVGMap
                    height="100%"
                    illustration={map_illustration}
                    points={view_points}
                    style={{ width: '100%', height: '100%' }}
                    activePoint={currPoint}
                /> */}
            </MapContainer>
        );
    }

    function renderHeader() {
        const location = object && object.location ? object.location : null;
        const zone = object && object.zone ? object.zone : null;
        const Icon = orderDir === 'asc' ? MdArrowUpward : MdArrowDownward;

        return (
            <Header>
                <div
                    className="row"
                    style={{
                        width: '100%',
                        margin: '0px',
                        paddingRight: '0px',
                    }}
                >
                    <div className="col-md-7 col-12 header-title">
                        <h6 className="subtitle">
                            {location ? location.name : ''}
                            {zone && zone.name ? ` - ${zone.name}` : ''}
                        </h6>
                        <h3 className="title">
                            {object.name ? object.name.toUpperCase() : ''}
                        </h3>
                    </div>
                    <div
                        className="col-md-5 col-12 row header-options"
                        style={{
                            color: '#fff',
                            marginRight: '0px',
                        }}
                    >
                        <div
                            className="row col-12 mb-3"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className="col-11">
                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            color="default"
                                            size="small"
                                            checked={seeUnknown}
                                            onChange={event =>
                                                setSeeUnknown(
                                                    event.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <LocaleMessage msg="rc3.disinfection.filter.show_unknown" />
                                    }
                                />
                            </div>
                            <div className="col-1 exit">
                                <MdClose
                                    style={{
                                        fontSize: '2vh',
                                    }}
                                    onClick={() => clearSelection()}
                                />
                            </div>
                        </div>

                        <div className="row col-12">
                            <IconButton
                                className="col-1 mb-1"
                                style={{
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                }}
                                onClick={() => {
                                    setOrderDir(
                                        orderDir === 'asc' ? 'desc' : 'asc'
                                    );
                                }}
                            >
                                <Icon style={{ color: '#fff' }} />
                            </IconButton>
                            <div className="col-md-5 col-11 mb-1">
                                <FormControl variant="filled" fullWidth>
                                    <InputLabel style={{ color: '#fff' }}>
                                        <LocaleMessage msg="label.order_by" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            orderBy || order_filters[0].value
                                        }
                                        onChange={event =>
                                            setOrderBy(event.target.value)
                                        }
                                        style={{ color: '#fff' }}
                                    >
                                        {order_filters.map(o => {
                                            return (
                                                <MenuItem
                                                    value={o.value}
                                                    key={`order_by_${o.value}`}
                                                >
                                                    {o.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div
                                className="col-md-6 col-12 mb-1"
                                style={{ color: '#fff' }}
                            >
                                <WhiteTextField
                                    label={
                                        <LocaleMessage
                                            msg="rc3.disinfection.filter.name"
                                            style={{
                                                color: '#fff',
                                            }}
                                        />
                                    }
                                    value={filter || ''}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdSearch
                                                    size={20}
                                                    style={{
                                                        color: '#fff',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <>
                                                {filter !== '' ? (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleFilterChange(
                                                                    ''
                                                                )
                                                            }
                                                            style={{
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            <MdClose />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ) : null}
                                            </>
                                        ),
                                    }}
                                    onChange={event =>
                                        handleFilterChange(event.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Header>
        );
    }

    return (
        <CentralPanel>
            {object ? (
                <>
                    {renderHeader()}
                    <Body>
                        {isLoading ? (
                            <Splash />
                        ) : (
                            <>
                                <RoomList hasMap={hasMap}>
                                    <CustomScrollbars
                                        style={{ height: '100%' }}
                                    >
                                        <Container>
                                            <InfoRow className="row mb-3">
                                                {orderedData
                                                    ? orderedData.map(p => {
                                                          return renderPointCard(
                                                              p
                                                          );
                                                      })
                                                    : null}
                                            </InfoRow>
                                        </Container>
                                    </CustomScrollbars>
                                </RoomList>
                                {hasMap ? renderMapIllustration() : null}
                            </>
                        )}
                    </Body>
                </>
            ) : null}
        </CentralPanel>
    );
}

RoomData.propTypes = {
    robots: PropTypes.array,
    settings: PropTypes.object,
    object: PropTypes.object,
    status: PropTypes.object,
    clearSelection: PropTypes.func,
    requestError: PropTypes.func,
    message: PropTypes.object,
};

RoomData.defaultProps = {
    robots: [],
    settings: {},
    object: {},
    status: {},
    clearSelection: () => {},
    requestError: () => {},
    message: {},
};
