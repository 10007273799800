/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdContactPhone } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import BigButton from '~/components/BigButton';
import CardSideBordered from '~/components/CardSideBordered';
import ContactForm from '~/components/Contacts/Form';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';

const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
};

export default function SectionContact({
    section,
    requestError,
    allowEdit,
    users,
}) {
    const section_id = section && section.id ? section.id : null;

    const [operation, setOperation] = useState('create');
    const [isLoading, setIsLoading] = useState(true);
    const [contact_id, setContact_id] = useState('new');
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [userList, setUserList] = useState({});

    const defBody = {
        add_users: true,
        section_id,
        source: 'section',
        name: section.name,
        description: section.description,
    };

    const sectionHeaders = {
        headers: {
            section: section_id,
        },
    };

    function handleDialogsClose(event) {
        event.preventDefault();
        setCreateDialogOpen(false);
    }

    async function loadContactUsers(_id) {
        await api
            .get(`contacts/${_id}/users`)
            .then(async response => {
                const u_list = response.data.users;

                const section_users = users.map(u => {
                    return u.id;
                });
                const outside = u_list.filter(u => {
                    return section_users.indexOf(u.id) === -1;
                });
                const selected = u_list.map(u => {
                    return u.id;
                });

                setUserList({
                    contact: selected,
                    outside,
                });
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadSectionContact(_id) {
        const filter = `?source=section&section=${_id}`;
        await api
            .get(`contacts${filter}`)
            .then(async response => {
                const s = response.data;
                const hasContact = !!(s && s[0] && s[0].id);

                setOperation(hasContact ? 'update' : 'create');
                if (!hasContact) {
                    return;
                }

                const contact = s[0];
                const c_id = contact && contact.id ? contact.id : null;
                if (c_id) {
                    await loadContactUsers(c_id);
                }
                setContact_id(c_id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleNewContact(event) {
        event.preventDefault();

        const data = { ...defBody };
        setCreateDialogOpen(false);

        if (operation === 'create') {
            setIsLoading(true);
            await api
                .post(`contacts`, data, sectionHeaders)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.sections.contact.create_success" />
                    );
                    loadSectionContact(section_id);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (section_id) {
            loadSectionContact(section_id);
        }
    }, [section_id]);

    function buildCreateDialog() {
        return (
            <SimpleDialog
                open={createDialogOpen}
                onClose={handleDialogsClose}
                title={<LocaleMessage msg="page.sections.contact.create" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.sections.contact.create.helper" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: handleDialogsClose,
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'create',
                        onClick: handleNewContact,
                        label: <LocaleMessage msg="button.create" />,
                    },
                ]}
            />
        );
    }

    function renderNewContact() {
        return (
            <div className="row" style={centerStyle}>
                <div className="col-12 mb-5" style={centerStyle}>
                    <LocaleMessage msg="page.sections.contact.label.not_set" />
                </div>
                <div className="col-md-8 col-12">
                    <div className="col-12 mb-3">
                        <BigButton
                            disabled={!allowEdit}
                            onClick={() => setCreateDialogOpen(true)}
                            title={
                                <LocaleMessage msg="page.sections.contact.label.set_up" />
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }

    function renderContactUsers() {
        const outsiders = userList && userList.outside ? userList.outside : [];
        const show_outsiders = outsiders && outsiders.length > 0;

        return (
            <div style={{ height: '100%' }}>
                <div className="mt-3 mb-3 " style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.sections.contact.label.users.title" />
                </div>
                <ParameterArea style={{ padding: '15px' }}>
                    {show_outsiders ? (
                        <div
                            className="mt-3 mb-3 "
                            style={{ textAlign: 'center' }}
                        >
                            <LocaleMessage msg="page.sections.contact.label.users.section" />
                        </div>
                    ) : null}
                    <FormCheckList
                        disabled={!allowEdit}
                        settings={userList}
                        listKey="contact"
                        options={users}
                        onChange={list => {
                            setUserList(list);
                        }}
                    />
                </ParameterArea>
                {show_outsiders ? (
                    <ParameterArea style={{ padding: '15px' }}>
                        <div
                            className="mt-3 mb-3 "
                            style={{ textAlign: 'center' }}
                        >
                            <LocaleMessage msg="page.sections.contact.label.users.outside" />
                        </div>
                        <FormCheckList
                            settings={userList}
                            options={outsiders}
                            showShortcut={false}
                            listKey="contact"
                            disabled
                        />
                    </ParameterArea>
                ) : null}
            </div>
        );
    }

    function renderExistingContact() {
        const contactUsers =
            userList && userList.contact ? userList.contact : [];
        return (
            <div className="row col-12">
                <div className="col-md-7 col-12">
                    <ContactForm
                        defaultBody={defBody}
                        contactId={contact_id}
                        allowEdit={allowEdit}
                        linkRoot="#"
                        showReference={false}
                        users={contactUsers}
                        submitHeaders={sectionHeaders}
                    />
                </div>
                <div className="col-md-5 col-12">{renderContactUsers()}</div>
            </div>
        );
    }

    return (
        <CardSideBordered
            title={<LocaleMessage msg="titles.tools.contact_lists" />}
            Icon={MdContactPhone}
            hide
        >
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    {createDialogOpen ? buildCreateDialog() : null}
                    {!allowEdit ? (
                        <div
                            className="col-12 mb-5"
                            style={{ textAlign: 'center' }}
                        >
                            <LocaleMessage msg="message.forbidden.short" />
                        </div>
                    ) : null}
                    {operation === 'create'
                        ? renderNewContact()
                        : renderExistingContact()}
                </>
            )}
        </CardSideBordered>
    );
}

SectionContact.propTypes = {
    section: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool,
    users: PropTypes.array,
};

SectionContact.defaultProps = {
    allowEdit: false,
    users: [],
};
