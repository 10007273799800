/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdPlayCircleFilled,
    MdAndroid,
    MdDelete,
    MdAdd,
    MdContentPaste,
} from 'react-icons/md';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    IconButton,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { SettingsArea } from './styles';

export default function AppTypeForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [appOperation, setAppOperation] = useState('create');
    const [robotOperation, setRobotOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        group: 'application',
    });
    const [robots, setRobots] = useState([]);
    const [compatibleRobots, setCompatibleRobots] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [robotApp, setRobotApp] = useState(null);
    const [formOpen, setFormOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [apptypeSettings, setApptypeSettings] = useState({
        multiRobot: false,
    });
    const [availableSettings, setAvailableSettings] = useState([]);
    const [options, setOptions] = useState({
        conversation: false,
        navigation: false,
    });

    const [pasteItem, setPasteItem] = useState(null);
    const [forbidden, setForbidden] = useState(false);
    const [tempText, setTempText] = useState('');

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function handleCheckboxChange(event, key) {
        setOptions({ ...options, [key]: event.target.checked });
    }

    async function loadRobots() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const r = response.data;
                setRobots(r);
            })
            .catch(error => requestError(error));
    }

    async function loadCompatibleRobots(_id) {
        if (_id !== 'new') {
            await api
                .get(`types/${_id}/robots`)
                .then(response => {
                    const r = response.data;
                    setCompatibleRobots(r.robots);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadRobotApp(_id) {
        await api
            .get(`types/${id}/robots/${_id}`)
            .then(response => {
                const r = response.data;
                setRobotApp({
                    robottype_id: r.robottype_id,
                });
                setOptions(r.options);
            })
            .catch(error => requestError(error));
    }

    async function loadSettings(type) {
        await api
            .get(`applications/settings/${type}`)
            .then(response => {
                const s = response.data;
                setAvailableSettings(s);
            })
            .catch(error => requestError(error));
    }

    async function loadType(_id) {
        if (_id === 'new') {
            setAppOperation('create');
        } else {
            setAppOperation('update');
            await api
                .get(`types/${_id}`)
                .then(async response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        group: 'application',
                        slug: t.slug,
                    });
                    setApptypeSettings(t.settings);
                    await loadSettings(t.slug);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadRobots();
        loadType(id);
        loadCompatibleRobots(id);
    }, [id]);

    async function updateType(data) {
        await api
            .put(`types/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.apptypes.form.update_success" />
                );
                setIsLoading(false);
                loadType(id);
            })
            .catch(error => requestError(error));
    }

    async function removeRobot() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`types/${id}/robots/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.apptypes.form.action_delete_success" />
                );
                await loadCompatibleRobots(id);
                setIsLoading(false);
            })
            .catch(error => requestError(error));

        setIsLoading(true);
        setDialogOpen(false);
    }

    function getSettings(key, defaultValue = {}) {
        return apptypeSettings && apptypeSettings[key]
            ? apptypeSettings[key]
            : defaultValue;
    }

    function updateSettings(key, value) {
        setApptypeSettings({ ...apptypeSettings, [key]: value });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options: apptypeSettings };
        if (appOperation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.apptypes.form.create_success" />
                    );

                    history.push(`/apptypes/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    async function handleRobotSubmit(event) {
        event.preventDefault();

        setFormOpen(false);
        setIsLoading(true);

        if (robotOperation === 'create') {
            const data = { ...robotApp, options };
            await api
                .post(`types/${id}/robots`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.apptypes.form.robot_type_add_success" />
                    );
                    loadCompatibleRobots(id);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            const data = { options };
            await api
                .put(`types/${id}/robots/${robotApp.robottype_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.apptypes.form.update_success" />
                    );
                    loadCompatibleRobots(id);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleNewRobot() {
        setRobotOperation('create');
        setFormOpen(true);
    }

    async function handleTableRowClick(event, _id) {
        event.stopPropagation();
        setRobotOperation('update');
        setCurrItem(_id);
        setIsLoading(true);
        await loadRobotApp(_id);
        setIsLoading(false);
        setFormOpen(true);
    }

    function handleNewItem(type) {
        const empty = {
            tools: {
                tool_key: '',
                parameters: [],
            },
        };
        const items = getSettings(type);
        const item_id = (+new Date()).toString(36);
        items[item_id] = {
            item_id,
            ...empty[type],
        };
        updateSettings(type, { ...items });
    }

    function handleDeleteItem(type, idx) {
        const items = getSettings(type);
        if (!items[idx]) {
            return;
        }
        delete items[idx];
        updateSettings(type, { ...items });
    }

    function onItemChange(value, type, index, key) {
        const items = getSettings(type);
        if (items[index]) {
            items[index] = {
                ...items[index],
                [key]: value,
            };
        }
        updateSettings(type, { ...items });
    }

    function pasteJSON(type, index, text) {
        const json = JSON.parse(text);
        const items = getSettings(type);
        if (items[index]) {
            items[index] = {
                ...items[index],
                parameters: json,
            };
        }
        updateSettings(type, { ...items });
        setPasteItem(null);
    }

    function saveJSONParameters(type, index, edit) {
        const items = getSettings(type);
        if (items[index]) {
            items[index] = {
                ...items[index],
                parameters: edit.updated_src,
            };
        }
        updateSettings(type, { ...items });
    }

    function buildListView() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={compatibleRobots}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function robotList() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.apptypes.form.robot_type.title" />
                    }
                    Icon={MdAndroid}
                    hide
                >
                    <>
                        <div className="body-top-controls">
                            <Button
                                onClick={() => handleNewRobot()}
                                variant="contained"
                                color="primary"
                                startIcon={<MdAdd />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.apptypes.form.robot_type_add" />
                            </Button>
                        </div>
                        {buildListView()}
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.apptypes.form.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeRobot(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildFormDialog() {
        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <LocaleMessage msg="page.apptypes.form.robot_type_add" />
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="label.form.robot_type" />
                        </InputLabel>
                        <Select
                            id="robot-type"
                            value={robotApp ? robotApp.robottype_id : ''}
                            onChange={event =>
                                setRobotApp({
                                    ...robotApp,
                                    robottype_id: event.target.value,
                                })
                            }
                            disabled={robotOperation === 'update'}
                        >
                            {robots.map(r => (
                                <MenuItem value={r.id} key={r.id}>
                                    {r.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormLabel>
                            <LocaleMessage msg="page.apptypes.form.label.settings" />
                        </FormLabel>
                        <FormGroup>
                            {availableSettings.map(opt => (
                                <FormControlLabel
                                    key={opt.value}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            value={opt.value}
                                            checked={
                                                options[opt.value]
                                                    ? options[opt.value]
                                                    : false
                                            }
                                            onChange={event =>
                                                handleCheckboxChange(
                                                    event,
                                                    opt.value
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <LocaleMessage msg={opt.label_code} />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleRobotSubmit(event)}
                        color="primary"
                    >
                        {robotOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildPasteDialog() {
        return (
            <Dialog
                open={!!pasteItem}
                onClose={() => {
                    setTempText(null);
                    setPasteItem(null);
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <LocaleMessage msg="page.apptypes.form.label.paste_settings" />
                </DialogTitle>
                <DialogContent>
                    <div className="col-12 px-0">
                        <TextField
                            variant="outlined"
                            multiline
                            fullWidth
                            value={tempText || ''}
                            onChange={event => setTempText(event.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setTempText(null);
                            setPasteItem(null);
                        }}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={() => pasteJSON('tools', pasteItem, tempText)}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.edit" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderToolItem(item) {
        const { item_id, tool_key, parameters } = item;
        return (
            <FormGroup
                row
                key={item_id}
                className="row col-12 pt-3 pb-3"
                style={{ padding: '0px', border: '1px solid #ddd' }}
            >
                <div className="col-12 row">
                    <div className="col-6 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.apptypes.form.label.tool_settings.key" />
                            }
                            fullWidth
                            value={tool_key || ''}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'tools',
                                    item_id,
                                    'tool_key'
                                )
                            }
                        />
                    </div>
                    <div className="col-6 mb-3 text-right">
                        <IconButton
                            onClick={() => {
                                setPasteItem(item_id);
                            }}
                        >
                            <MdContentPaste size={20} />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                handleDeleteItem('tools', item.item_id)
                            }
                        >
                            <MdDelete size={20} />
                        </IconButton>
                    </div>
                    <div className="col-12">
                        <FormControl
                            component="fieldset"
                            fullWidth
                            style={{
                                border: '1px solid #ddd',
                                padding: '10px',
                            }}
                        >
                            <ReactJson
                                src={parameters || {}}
                                name={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                collapsed
                                onAdd={edit =>
                                    saveJSONParameters('tools', item_id, edit)
                                }
                                onEdit={edit =>
                                    saveJSONParameters('tools', item_id, edit)
                                }
                                onDelete={edit =>
                                    saveJSONParameters('tools', item_id, edit)
                                }
                            />
                        </FormControl>
                    </div>
                </div>
            </FormGroup>
        );
    }

    function buildToolSettings() {
        const { tools } = apptypeSettings;
        return (
            <SettingsArea className="col-12 mb-3">
                <FormControl className="mb-3" component="fieldset" fullWidth>
                    <FormLabel className="mb-3">
                        <LocaleMessage msg="page.apptypes.form.label.tool_settings" />
                    </FormLabel>
                    {tools
                        ? Object.keys(tools).map(idx => {
                              const item = tools[idx];
                              return renderToolItem(item);
                          })
                        : null}
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNewItem('tools')}
                    fullWidth
                >
                    <LocaleMessage msg="page.apptypes.form.label.tool_settings.add" />
                </Button>
            </SettingsArea>
        );
    }

    return (
        <PageContent
            title={
                appOperation === 'create' ? (
                    <LocaleMessage msg="page.apptypes.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.apptypes.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/apptypes',
                    title: <LocaleMessage msg="page.apptypes.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {pasteItem ? buildPasteDialog() : null}
                {dialogOpen ? buildConfirmDialog() : null}
                {formOpen ? buildFormDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.apptypes.form.label.settings" />
                            }
                            Icon={MdPlayCircleFilled}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.apptypes.form.label.app_name" />
                                            }
                                            fullWidth
                                            value={body.name}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="label.form.slug" />
                                            }
                                            fullWidth
                                            value={body.slug || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                apptypeSettings &&
                                                                apptypeSettings.multiRobot
                                                                    ? apptypeSettings.multiRobot
                                                                    : false
                                                            }
                                                            value="multiRobot"
                                                            onChange={event =>
                                                                setApptypeSettings(
                                                                    {
                                                                        ...apptypeSettings,
                                                                        multiRobot:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.apptypes.form.label.allow_multiple" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">{buildToolSettings()}</div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>

                    {appOperation === 'update' ? robotList() : null}
                </form>
            </>
        </PageContent>
    );
}

AppTypeForm.propTypes = {
    match: PropTypes.object.isRequired,
};
