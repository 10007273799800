/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import LocaleMessage from '~/components/LocaleMessage';

export default function Page404() {
    return (
        <div className="app-wrapper">
            <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
                <div className="page-error-content">
                    <div className="error-code mb-4 animated zoomInDown">
                        <LocaleMessage msg="page.404.title" />
                    </div>
                    <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                        <span>
                            <LocaleMessage msg="page.404.content" />
                        </span>
                    </h2>
                    <p className="text-center zoomIn animation-delay-10 animated">
                        <a className="btn btn-primary" href="/">
                            <span>
                                <LocaleMessage msg="message.go_home.title" />
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
