/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Sound from 'react-sound';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import notification from '~/assets/sounds/notification.wav';

import DefaultAlert from './Default';
import IndoorDeliveryHelpRequest from './IndoorDelivery/HelpRequest';
import TelepresenceCallAnswered from './Telepresence/CallAnswered';
import TelepresenceCallCancelled from './Telepresence/CallCancelled';
import TelepresenceCallLost from './Telepresence/CallLost';
import TelepresenceCallAlert from './Telepresence/CallRequest';
// const date_opt = {
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hourCycle: 'h23',
// };

const options = {
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: false,
};

const components = {
    call_request: {
        className: 'TELEPRESENCE',
        content: TelepresenceCallAlert,
        notify: true,
    },
    call_accepted: {
        className: 'TELEPRESENCE',
        content: TelepresenceCallAnswered,
        options: {
            type: toast.TYPE.INFO,
            autoClose: 3000,
            hideProgressBar: false,
        },
    },
    call_cancelled: {
        className: 'TELEPRESENCE',
        content: TelepresenceCallCancelled,
        options: {
            type: toast.TYPE.INFO,
        },
    },
    call_timeout: {
        className: 'TELEPRESENCE',
        content: TelepresenceCallLost,
        options: {
            type: toast.TYPE.INFO,
        },
    },
    delivery_help_request: {
        className: 'INDOOR_DELIVERY',
        content: IndoorDeliveryHelpRequest,
        options: {
            type: toast.TYPE.INFO,
        },
    },
};

export default function RC3Alerts({
    robots,
    robotTypes,
    lastAlert,
    lastNotification,
    sendNotification,
    requestError,
    changePage,
}) {
    const app_settings = useSelector(state => state.application);
    const user_settings = useSelector(state => state.user);

    const current_settings =
        app_settings && app_settings.settings ? app_settings.settings : {};

    const sound = current_settings.sound || false;

    const [alerts, setAlerts] = useState({});
    const [blacklist, setBlacklist] = useState({});

    const stateRef = useRef().current || {};

    function getAlertList(key) {
        const active_list = alerts[key] || {};
        return active_list;
    }

    function updateAlertList(key, list) {
        stateRef[key] = list;
        setAlerts(stateRef);
        return list;
    }

    function removeAlertFromQueue(list, id, block = false) {
        toast.dismiss(id);

        if (block) {
            setBlacklist({ ...blacklist, [id]: true });
        }

        if (list[id]) {
            delete list[id];
        }

        updateAlertList('active', list);
    }

    function getAlert(id, a) {
        const { type, alert_id } = a;
        const alert_component = components[type] || {
            content: DefaultAlert,
            options: {
                type: toast.TYPE.INFO,
                autoClose: 1000,
                hideProgressBar: false,
            },
        };

        const Body = alert_component.content;
        const alert_options =
            alert_component && alert_component.options
                ? alert_component.options
                : {};
        const robot = a.robot || {};
        const r_type =
            robot.type && robot.type.id && robotTypes[robot.type.id]
                ? robotTypes[robot.type.id]
                : {};

        const Component = (
            <Body
                alert_id={alert_id}
                key={id}
                robot={robot}
                robotType={r_type}
                content={a.data}
                removeAlert={block => {
                    removeAlertFromQueue(alerts.active || {}, id, block);
                }}
                sendNotification={n => sendNotification(n)}
                user={user_settings}
                requestError={error => requestError(error)}
                changePage={p => changePage(p)}
            />
        );

        return {
            Component,
            options: {
                ...options,
                ...alert_options,
                toastId: id,
                className: alert_component.className || '',
                ...(alert_options.autoClose && {
                    onClose: () => {
                        removeAlertFromQueue(alerts.active || {}, id);
                    },
                }),
            },
            notify: alert_component.notify,
        };
    }

    function addAlertToQueue(id, alert) {
        if (blacklist[id]) {
            return null;
        }

        const active_list = getAlertList('active');

        const alert_component = getAlert(id, alert);
        const Component = alert_component ? alert_component.Component : null;

        const t_id = toast(Component, alert_component.options);

        const new_list = {
            ...active_list,
            [id]: { toast_id: t_id, alert, notify: alert_component.notify },
        };

        return updateAlertList('active', new_list);
    }

    function updateAlert(id, alert) {
        const alert_component = getAlert(id, alert);
        const alert_options = alert_component ? alert_component.options : {};
        const Component = alert_component ? alert_component.Component : null;

        toast.update(id, {
            render: Component,
            ...alert_options,
            notify: alert_component.notify,
        });
    }

    function changeAlert(id, alert, block = false) {
        const active_list = getAlertList('active');
        if (!active_list[id]) {
            return null;
        }
        const { type } = alert;
        const alert_settings = components[type] || { notify: true };

        active_list[id] = {
            ...active_list[id],
            alert,
            notify: alert_settings.notify,
        };

        if (block) {
            setBlacklist({ ...blacklist, [id]: true });
        }

        return updateAlertList('active', active_list);
    }

    function handleAlertMessage(message) {
        const { alert_id, robot_id, type, data } = message;

        if (!type) {
            return null;
        }

        const robot = robots.find(r => {
            return r.id === robot_id;
        });
        const id = `${robot_id}_${alert_id}`;

        if (robot_id && robot) {
            addAlertToQueue(id, {
                alert_id,
                type,
                robot,
                data,
            });
            return true;
        }
        return false;
    }

    function handleNotificationMessage(message) {
        const { robot_id, type, data } = message;
        if (!type || !data) {
            return null;
        }

        const robot = robots.find(r => {
            return r.id === robot_id;
        });
        const id = `${robot_id}_${data.alert_id}`;

        if (robot_id && robot) {
            switch (type) {
                case 'call_cancelled':
                case 'call_timeout':
                case 'call_accepted': {
                    changeAlert(
                        id,
                        {
                            type,
                            robot,
                            data,
                        },
                        true
                    );
                    return true;
                }
                default:
                    return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (lastAlert) {
            handleAlertMessage(lastAlert);
        }
    }, [lastAlert]);

    useEffect(() => {
        if (lastNotification) {
            handleNotificationMessage(lastNotification);
        }
    }, [lastNotification]);

    function playSound() {
        return sound ? (
            <Sound
                url={notification}
                loop
                playStatus={Sound.status.PLAYING}
                debug={false}
            />
        ) : null;
    }

    function controlAlerts(current) {
        const alerts_obj = current.active || {};

        const list = Object.keys(alerts_obj);
        let notify = false;
        list.forEach(a => {
            const item = alerts_obj[a];
            if (item.notify) {
                notify = true;
            }
            updateAlert(a, item.alert);
        });
        return notify ? playSound() : null;
    }

    return <>{controlAlerts(alerts)}</>;
}

RC3Alerts.propTypes = {
    robots: PropTypes.array,
    robotTypes: PropTypes.object,
    lastAlert: PropTypes.object,
    lastNotification: PropTypes.object,
    sendNotification: PropTypes.func,
    requestError: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
};

RC3Alerts.defaultProps = {
    robots: [],
    robotTypes: {},
    lastAlert: {},
    lastNotification: {},
    sendNotification: () => {},
};
