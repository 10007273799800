/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdContacts } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import ContactLists from './ContactLists/List';
import Contacts from './Contacts/List';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

const internalPage = {
    lists: ContactLists,
    contacts: Contacts,
};

export default function ContactTool() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const { active } = settings;

    const [queryTab, setQueryTab] = useQueryState('tab', '');

    const pages = [
        {
            value: 'lists',
            label: <LocaleMessage msg="page.tools.contact_lists.title.short" />,
        },
        {
            value: 'contacts',
            label: <LocaleMessage msg="page.tools.contacts.title" />,
        },
    ];

    const [value, setValue] = useState(queryTab || pages[0].value);

    const classes = useStyles();

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        setQueryTab(newValue);
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    useEffect(() => {}, []);

    function renderInternalPage(page) {
        const Page = internalPage[page];

        return (
            <Page
                active={active}
                locale={date_loc}
                requestError={error => requestError(error)}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.tools.contact_lists.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={false}
        >
            <>
                <div className="col-12 mb-5 mt-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.tools.contact_lists.title" />
                        }
                        hide
                        Icon={MdContacts}
                    >
                        <>
                            <Tabs
                                indicatorColor="primary"
                                value={value || pages[0].value}
                                onChange={handleChangeTab}
                                className={classes.tabs}
                                centered
                            >
                                {pages.map(p => {
                                    return (
                                        <Tab
                                            key={p.value}
                                            label={p.label}
                                            value={p.value}
                                        />
                                    );
                                })}
                            </Tabs>

                            <div>{renderInternalPage(value)}</div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

ContactTool.propTypes = {};
