import { addDays, getDay } from 'date-fns';

export default function getPeriodDates(period) {
    const { start, length } = period;
    const s_date = new Date(start);

    const period_dates = [];
    let last_day = getDay(s_date);
    for (let i = 0; i <= length; i++) {
        const p_day = new Date(addDays(s_date, i));
        period_dates.push(p_day);
        last_day = getDay(p_day);
    }
    if (last_day !== getDay(new Date())) {
        period_dates.push(new Date(addDays(s_date, length + 1)));
    }
    return period_dates;
}
