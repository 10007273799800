/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function PageTitle(props) {
    const { title, currentPage, breadcrumbs } = props;
    return (
        <Container className="mb-3">
            <h1 className="title mb-10 mb-sm-0">
                <span>{title}</span>
            </h1>
            <ol className="breadcrumb">
                {breadcrumbs.map(link => (
                    <NavLink
                        to={link.url}
                        className="breadcrumb-item"
                        key={link.url}
                    >
                        {link.title}
                    </NavLink>
                ))}
                <span className="active breadcrumb-item" aria-current="page">
                    {currentPage}
                </span>
            </ol>
        </Container>
    );
}

PageTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    breadcrumbs: PropTypes.array.isRequired,
};
