import { darken } from 'polished';
import styled from 'styled-components';

export const Title = styled.div`
    padding: 30px;

    h1 {
        color: #fff;
        text-align: center;
        font-weight: bold;
    }
`;

export const Container = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        list-style-type: none;
    }

    .row {
        padding: 0px;
        width: 100%;
        margin: auto;
    }

    .full-body {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: top;
    }

    a {
        text-decoration: none !important;
        color: #495057 !important;
    }
`;

export const CardMenu = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const CardRow = styled.div`
    padding: 0px;
    width: 100%;
    min-height: 150px;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
        text-decoration: none !important;
        color: #495057 !important;
    }
`;

export const Card = styled.div`
    padding: 0px;
    margin-left: 1vw;
    margin-right: 1vw;

    .select:hover {
        background: ${props => {
            const color = props.theme.palette.primary.main;
            return `linear-gradient(45deg, ${darken(0.3, color)}, ${darken(
                0.1,
                color
            )})`;
        }};
        color: #fff;
    }

    .ribbon-primary {
        span {
            background: ${props => props.theme.palette.primary.main} !important;
        }
    }

    .title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            font-weight: bold;
        }
    }

    .action-row {
        padding-left: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .body {
        padding: 15px;

        h2 {
            font-weight: bold;
            overflow-wrap: overflow-wrap;
        }
    }

    .bg-primary {
        background-color: ${props =>
            props.theme.palette.primary.main} !important;
    }

    .text-primary {
        color: ${props => props.theme.palette.primary.main} !important;
    }
`;

export const SpecialCard = styled.div`
    border-top: 5px solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    height: 100%;

    .plugin-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        color: ${props => props.theme.palette.primary.main};
    }
`;

export const ParameterArea = styled.div`
    padding: 20px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }
`;
