import styled from 'styled-components';

export const SensorsArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const Option = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
`;
