/* eslint-disable no-plusplus */
import Papa from 'papaparse';

import lists from '~/config/Lists';

function addExamplesFromFile(contentSettings, obj) {
    const langs = Object.keys(obj);
    const curr_settings = { ...contentSettings } || {};
    langs.forEach(l => {
        const new_examples = obj[l];
        const lng_settings = curr_settings[l] || {};
        const examples = lng_settings.training || [];

        for (let j = 0; j < new_examples.length; j++) {
            const string = new_examples[j].trim();
            if (string !== '' && !examples.includes(string))
                examples.push(string);
        }

        curr_settings[l] = {
            ...lng_settings,
            training: examples,
        };
    });

    return curr_settings;
}

export default function loadFile(contentSettings, e) {
    const { qna_languages } = lists;
    const lang_keys = {};

    qna_languages.forEach(l => {
        lang_keys[l.key] = l.value;
    });

    const jsonData = {};
    const content = e.target.result;
    try {
        const parsed = Papa.parse(content);
        const data = parsed && parsed.data ? parsed.data : [];
        const headers = data[0];
        for (let i = 1; i < data.length; i++) {
            const cells = data[i];
            for (let j = 0; j < cells.length; j++) {
                const header = headers[j].toLowerCase();
                const key = lang_keys[header] || headers[j];
                if (key) {
                    const string = cells[j].trim();
                    const lngArray = jsonData[key] || [];
                    if (string !== '' && !lngArray.includes(string))
                        lngArray.push(string);
                    jsonData[key] = lngArray;
                }
            }
        }
    } catch (err) {
        console.error(err);
    }
    return addExamplesFromFile(contentSettings, jsonData);
}
