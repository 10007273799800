/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import * as MaterialDesign from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DialogContentText } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { NewCard, SpecialCard } from './styles';

export default function PluginTypes() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const [types, setTypes] = useState([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`pluginspaces/plugins`)
            .then(response => {
                const data = response.data.map(t => ({
                    ...t,
                    icon:
                        t.settings && t.settings.icon
                            ? t.settings.icon
                            : 'MdCropFree',
                }));
                setTypes(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, []);

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function pluginCard(type) {
        const mdIcon = MaterialDesign[type.icon];
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={type.id}>
                <Link to={`/plugins/${type.id}`}>
                    <SpecialCard
                        className="jr-card jr-card-widget jr-card-full text-center card"
                        style={{ height: '100%' }}
                    >
                        <div className="card-body plugin-card" style={{}}>
                            {mdIcon
                                ? React.createElement(mdIcon, { size: 50 })
                                : null}
                            <h2 className="mt-4">{type.name}</h2>
                        </div>
                    </SpecialCard>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    <div className="col-md-3 col-sm-6 mb-5">
                        <div
                            className="jr-card jr-card-widget jr-card-full text-center card new-card"
                            style={{ height: '100%' }}
                        >
                            <NewCard
                                className="card-body text-center"
                                onClick={() => setDialogOpen(true)}
                            >
                                <LocaleMessage msg="page.plugins.list.add" />
                                <MdAdd size={48} style={{ margin: '20px' }} />
                            </NewCard>
                        </div>
                    </div>
                    {types.map(type => pluginCard(type))}
                </div>
            </>
        );
    }

    function buildDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.plugins.list.add_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.plugins.list.add_content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.close" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.plugins.title" />}
            currentPage={<LocaleMessage msg="page.plugins.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildDialog() : null}
                {buildGridView()}
            </>
        </PageContent>
    );
}
