/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdTextFormat, MdFormatShapes, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function InfoPrivateFormatters() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const { active } = settings;

    const [privateFormatters, setPrivateFormatters] = useState([]);
    const [publicFormatters, setPublicFormatters] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadFormatters() {
        await api
            .get(`info_formatters`)
            .then(response => {
                const data = response.data.map(f => {
                    const updated = new Date(f.updated);
                    return {
                        ...f,
                        label: (
                            <LocaleMessage
                                msg={`tools.info_formatters.${f.name}`}
                            />
                        ),
                        group_name:
                            f.group && f.group.name ? (
                                f.group.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                const public_f = data.filter(f => {
                    return !f.private;
                });
                const private_f = data.filter(f => {
                    return f.private;
                });

                setPublicFormatters(public_f);
                setPrivateFormatters(private_f);
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    useEffect(() => {
        loadFormatters();
    }, [active]);

    async function deleteType() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`info_formatters/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.info_formatters.custom.list.delete_success" />
                );
                await loadFormatters();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/tools/info_formatters/${id}`);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event, 'delete')}
                title={
                    <LocaleMessage msg="page.tools.info_formatters.custom.list.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteType(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildPrivateFormattersList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={privateFormatters}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildPublicFormattersList() {
        const headCells = [
            { id: 'label', label: <LocaleMessage msg="table.headers.name" /> },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={publicFormatters}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={[]}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage msg="page.tools.info_formatters.custom.list.title" />
            }
            currentPage={
                <LocaleMessage msg="page.tools.info_formatters.custom.list.title" />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="row full-body">
                    <div className="col-md-6 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.info_formatters.custom.list.public" />
                            }
                            Icon={MdTextFormat}
                            hide
                        >
                            <>
                                <div
                                    style={{
                                        padding: '0px 15px',
                                    }}
                                >
                                    <LocaleMessage msg="page.tools.info_formatters.custom.list.public.description" />
                                </div>
                                {buildPublicFormattersList()}
                            </>
                        </CardSideBordered>
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.info_formatters.custom.list.custom" />
                            }
                            Icon={MdFormatShapes}
                            hide
                        >
                            <>
                                <div
                                    className="mb-3"
                                    style={{
                                        padding: '0px 15px',
                                    }}
                                >
                                    <LocaleMessage msg="page.tools.info_formatters.custom.list.custom.description" />
                                </div>
                                <div className="body-top-controls">
                                    <NewItemButton
                                        link="/tools/info_formatters/new"
                                        text={
                                            <LocaleMessage msg="page.tools.info_formatters.custom.list.custom.add" />
                                        }
                                    />
                                </div>
                                {buildPrivateFormattersList()}
                            </>
                        </CardSideBordered>
                    </div>
                </div>
            </>
        </PageContent>
    );
}
