import styled from 'styled-components';

export const Container = styled.div`
    align-self: center;
    margin-bottom: 10px;

    label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
            opacity: 0.7;
        }

        img,
        .avatar-placeholder {
            height: 120px;
            width: 120px;
            border-radius: 50%;
            border: 3px solid rgba(255, 255, 255, 0.3);
            background: #eee;
            margin-top: 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: contain;

            h1 {
                font-size: 42px;
            }
        }

        input {
            display: none;
        }

        svg {
            margin-right: 5px;
        }
    }
`;
