/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import lng_labels from '~/util/LangMessages';

export default function GroupUsers(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const user = useSelector(state => state.user);
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const { groups } = user;
    const { permission } = user.profile;

    const currGroup = groups.find(g => {
        return g.id === id;
    });

    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);
    const allowEdit =
        adminMode || (currGroup && currGroup.permission === 'admin');

    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [userDialogOpen, setUserDialogOpen] = useState(false);

    const headers_users = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
        {
            id: 'permission',
            label: <LocaleMessage msg="table.headers.group_permission" />,
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadGroupUsers() {
        setIsLoading(true);
        await api
            .get(`groups/${id}/users`)
            .then(response => {
                const { users: list } = response.data;
                const user_list = list.map(u => {
                    return {
                        ...u,
                        permission: lng_all[`user.permission.${u.permission}`],
                    };
                });
                setUsers(user_list);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadGroupUsers();
    }, [id]);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        return setUserDialogOpen(true);
    }

    function handleUserRowClick(event, user_id) {
        event.preventDefault();
        setCurrItem(user_id);
        history.push(`/groups/${id}/users/${user_id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setUserDialogOpen(false);
    }

    async function removeUser() {
        setIsLoading(true);
        setUserDialogOpen(false);

        await api
            .delete(`/groups/${id}/users/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.groupuser.users.list.delete_success" />
                );

                await loadGroupUsers();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function buildUserList() {
        const rowActions = allowEdit
            ? [
                  {
                      id: 'delete',
                      label: <LocaleMessage msg="button.delete" />,
                      icon: <MdDelete />,
                      action: (e, _id) => handleClickOpen(e, _id),
                  },
              ]
            : [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headers_users}
                    data={users}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleUserRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildUserDialog() {
        return (
            <SimpleDialog
                open={userDialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.groupuser.users.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.groupuser.users.list.delete_content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setUserDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeUser(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.groupuser.users.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {userDialogOpen ? buildUserDialog() : null}
                <div className="row full-body">
                    {allowEdit ? (
                        <div className="body-top-controls">
                            <div className="col-3" style={{ padding: '0px' }}>
                                <NewItemButton
                                    link={`/groups/${id}/users/new`}
                                    text={
                                        <LocaleMessage msg="page.groupuser.users.list.add" />
                                    }
                                />
                            </div>
                        </div>
                    ) : null}
                    <>{buildUserList()}</>
                </div>
            </>
        </PageContent>
    );
}

GroupUsers.propTypes = {
    match: PropTypes.object.isRequired,
};
