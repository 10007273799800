/* eslint-disable no-plusplus */
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetOperationTime from '~/util/GetOperationTime';

export default function filter(data, format) {
    const a_groups = {};
    const a_robots = {};
    const a_applications = {};
    const a_users = {};
    const a_invites = {};

    let duration_sum = 0;
    let has_time = 0;

    data.forEach(c => {
        const c_time = c.time && c.time > 0 ? c.time : 0;
        if (c_time) {
            has_time++;
        }
        duration_sum += c_time;

        const group_id = c.group_id || '---';
        a_groups[group_id] = a_groups[group_id]
            ? {
                  ...a_groups[group_id],
                  total: a_groups[group_id].total + 1,
                  time: a_groups[group_id].time + c_time,
              }
            : {
                  id: group_id,
                  name: c.group_name,
                  total: 1,
                  time: c_time,
              };

        const robot_id = c.robot_id || '---';
        a_robots[robot_id] = a_robots[robot_id]
            ? {
                  ...a_robots[robot_id],
                  total: a_robots[robot_id].total + 1,
                  time: a_robots[robot_id].time + c_time,
              }
            : {
                  id: a_robots,
                  name: c.robot_name,
                  total: 1,
                  time: c_time,
              };

        const application_id = c.application_id || '---';
        a_applications[application_id] = a_applications[application_id]
            ? {
                  ...a_applications[application_id],
                  total: a_applications[application_id].total + 1,
                  time: a_applications[application_id].time + c_time,
              }
            : {
                  id: application_id,
                  name: c.application_name,
                  total: 1,
                  time: c_time,
              };

        const invite = c.invite || {};
        const {
            user,
            id: invite_id,
            created: i_created,
            start: i_start,
            duration: i_duration,
        } = invite;

        const u_id = user ? user.id : null;
        const u_name = user ? user.name : '';

        if (!a_users[u_id]) {
            a_users[u_id] = {
                id: `access_${u_id}`,
                name: u_name,
                total: 0,
            };
        }
        a_users[u_id] = {
            ...a_users[u_id],
            total: a_users[u_id].total + 1,
        };

        const inv_c = i_created
            ? GetDateTimeLabel(new Date(i_created), { format })
            : {};
        const inv_s = i_start
            ? GetDateTimeLabel(new Date(i_start), { format })
            : {};
        const inv_d = GetOperationTime(i_duration * 60);

        if (!a_invites[invite_id]) {
            a_invites[invite_id] = {
                id: invite_id,
                group_id,
                group_name: c.group_name,
                robot_id,
                robot_name: c.robot_name,
                application_id,
                application_name: c.application_name,
                user_id: u_id,
                user_name: u_name,
                created: inv_c.string || '---',
                created_timestamp: inv_c.timestamp || '',
                start: inv_s.string || '---',
                start_date: inv_s.date || '---',
                start_time: inv_s.time || '---',
                start_timestamp: inv_s.timestamp || '',
                duration: i_duration,
                duration_label: inv_d.label,
                accesses: [],
            };
        }

        const invite_access = {
            id: c.id,
            time: c.time,
            duration: c.duration,
            duration_label: c.duration_label || '---',
            start: c.start || '---',
            start_date: c.start_date || '---',
            start_time: c.start_time || '---',
            start_timestamp: c.start_timestamp || '---',
            end: c.end || '---',
            end_date: c.end_date || '---',
            end_time: c.end_time || '---',
            end_timestamp: c.end_timestamp || '---',
            identification: c.identification || '---',
        };
        a_invites[invite_id].accesses.push(invite_access);
    });

    const invite_grouped = Object.keys(a_invites).map(i => {
        const inv_data = a_invites[i] || {};
        return {
            ...inv_data,
            accesses_length: inv_data.accesses.length,
        };
    });

    const group_grouped = Object.keys(a_groups).map(g => {
        return a_groups[g];
    });

    const robot_grouped = Object.keys(a_robots).map(r => {
        return a_robots[r];
    });

    const application_grouped = Object.keys(a_applications).map(a => {
        return a_applications[a];
    });

    const user_grouped = Object.keys(a_users).map(d => {
        return a_users[d];
    });

    const mean_duration = (
        Math.round((duration_sum / has_time) * 10 || 0) / 10
    ).toFixed(1);

    const duration_time = GetOperationTime(duration_sum);
    const mean_time = GetOperationTime(mean_duration);

    const newFilteredData = {
        data: invite_grouped,
        duration: duration_time.label,
        mean_duration: mean_time.label,
        groups: group_grouped,
        robots: robot_grouped,
        applications: application_grouped,
        users: user_grouped,
    };

    return newFilteredData;
}
