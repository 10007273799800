/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import pbLogoDark from '~/assets/logo-dark.png';
import pbLogoWhite from '~/assets/logo-white.png';

import { PreviewArea, Screen } from './styles';

export default function Preview({
    configuration,
    file,
    def_colors,
    onCallClick,
}) {
    const ps_logo = useSelector(state =>
        state.settings && state.settings.logo ? state.settings.logo : null
    );
    const { allow_requests, request_mode, broadcast_text } = configuration;

    const visualSettings = configuration.visual || {};
    const colorSettings = visualSettings.colors || def_colors;

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: (4.0 / 3.0) * targetRef.current.offsetWidth,
            });
        }
    }, []);

    const {
        show_logo,
        show_text,
        show_image,
        standby_text,
        text_size,
        media_width,
        dark_logo,
    } = visualSettings;

    const pbLogo = dark_logo ? pbLogoDark : pbLogoWhite;

    return (
        <PreviewArea className="mt-3 mb-3" ref={targetRef}>
            <Screen
                dimensions={dimensions}
                colors={colorSettings}
                showPSLogo={show_logo && ps_logo}
                media_width={media_width}
                text_size={text_size}
            >
                <div className="body">
                    <div className="logo mb-3">
                        <img
                            className="pluginbot-logo"
                            src={pbLogo}
                            alt="Pluginbot Logo"
                            title="Pluginbot Logo"
                        />
                        {show_logo ? (
                            <img
                                className="pluginspace-logo mt-3"
                                src={
                                    ps_logo && ps_logo.url
                                        ? ps_logo.url
                                        : pbLogo
                                }
                                alt="Pluginspace Logo"
                                title="Pluginspace Logo"
                            />
                        ) : null}
                    </div>
                    <div className="content">
                        {show_text ? (
                            <span className="standby_text mb-3">
                                {standby_text || ''}
                            </span>
                        ) : null}
                        {show_image && file && file.url ? (
                            <div className="img_container">
                                <img
                                    className="media"
                                    src={file.url}
                                    alt={file.id}
                                />
                            </div>
                        ) : null}
                        {allow_requests ? (
                            <Button
                                className="mt-3 mb-1"
                                onClick={e =>
                                    request_mode && request_mode === 'contact'
                                        ? onCallClick(e)
                                        : {}
                                }
                                variant="contained"
                                size="large"
                                style={{
                                    backgroundColor:
                                        colorSettings &&
                                        colorSettings.call_button
                                            ? colorSettings.call_button
                                            : '#000',
                                    color: '#fff',
                                    overflowWrap: 'anywhere',
                                    maxWidth: '90%',
                                }}
                            >
                                {request_mode === 'contact' ? (
                                    <LocaleMessage msg="page.applications.telepresence.preview.call.contact" />
                                ) : (
                                    <>
                                        {!broadcast_text ? (
                                            <LocaleMessage msg="page.applications.telepresence.preview.call.broadcast" />
                                        ) : (
                                            broadcast_text
                                        )}
                                    </>
                                )}
                            </Button>
                        ) : null}
                    </div>
                </div>
                <div className="footer">
                    <LocaleMessage msg="page.applications.telepresence.preview.footer" />
                </div>
            </Screen>
        </PreviewArea>
    );
}

Preview.defaultProps = {
    configuration: {},
    file: null,
};

Preview.propTypes = {
    onCallClick: PropTypes.func.isRequired,
    def_colors: PropTypes.object.isRequired,
    configuration: PropTypes.object,
    file: PropTypes.object,
};
