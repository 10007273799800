/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

import checkAdmin from '../../util/CheckAdmin';
import checkPermission from '../../util/CheckPermission';

export default function InfoFormatterSettings({
    data,
    onDataChange,
    onError,
    allowEdition,
    operation,
}) {
    const user_settings = useSelector(state => state.settings || null);
    const { profile } = useSelector(state => state.user || null);

    const super_user = checkAdmin(user_settings, profile);
    const admin_user = checkPermission(profile);

    const { admin_groups } = user_settings;

    const [options, setOptions] = useState(data.options || {});
    const [availableSettings, setAvailableSettings] = useState([]);

    const booleanSettings = availableSettings.filter(s => {
        return s.type === 'boolean';
    });

    const inputSettings = availableSettings.filter(s => {
        return s.type === 'input';
    });

    async function loadSettings() {
        await api
            .get(`/info_formatters/settings`)
            .then(response => {
                const settings = response.data;
                setAvailableSettings(settings);
            })
            .catch(error => onError(error));
    }

    useEffect(() => {
        loadSettings();
    }, []);

    useEffect(() => {
        setOptions(data.options || {});
    }, [data]);

    function handleSwitchChange(event, key) {
        onDataChange({
            ...data,
            options: { ...options, [key]: event.target.checked },
        });
    }

    function renderSUConfig() {
        return (
            <div className="col-12 mb-3">
                {operation === 'create' ? (
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={operation === 'update'}
                                color="primary"
                                checked={data.open_all || false}
                                onChange={event =>
                                    onDataChange({
                                        ...data,
                                        open_all: event.target.checked,
                                        group_id: '*',
                                    })
                                }
                            />
                        }
                        label={
                            <LocaleMessage msg="page.tools.info_formatters.form.label.open_all" />
                        }
                    />
                ) : (
                    <>
                        {!data.private ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <Chip
                                    className="mb-3"
                                    variant="outlined"
                                    color="primary"
                                    label={
                                        <LocaleMessage msg="message.open_all.title" />
                                    }
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        );
    }

    function renderGroupSelector() {
        return (
            <div className="col-md-6 col-12 mb-5">
                {admin_user ? (
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="label.form.group" />
                        </InputLabel>
                        <Select
                            id="group"
                            value={
                                data.group_id && data.group_id
                                    ? data.group_id
                                    : ''
                            }
                            onChange={event =>
                                onDataChange({
                                    ...data,
                                    group_id: event.target.value,
                                })
                            }
                            disabled={operation === 'update' || data.open_all}
                        >
                            <MenuItem value="*">
                                <LocaleMessage msg="message.all_groups.short" />
                            </MenuItem>
                            {admin_groups.map(g => (
                                <MenuItem value={g.id} key={g.id}>
                                    {g.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <>
                        <span>
                            <LocaleMessage msg="label.form.group" />
                        </span>
                        <Typography variant="body1">
                            {data.group_name}
                        </Typography>
                    </>
                )}
            </div>
        );
    }

    function renderSwitches() {
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    <LocaleMessage msg="label.settings.info_formatters.format" />
                </FormLabel>
                <FormGroup>
                    {booleanSettings.map(s => (
                        <FormControlLabel
                            key={s.value}
                            control={
                                <Switch
                                    color="primary"
                                    checked={
                                        options[s.value]
                                            ? options[s.value]
                                            : s.default
                                    }
                                    value={s.value}
                                    onChange={event =>
                                        handleSwitchChange(event, s.value)
                                    }
                                />
                            }
                            label={<LocaleMessage msg={s.label_code} />}
                            disabled={!allowEdition}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        );
    }

    function renderInputs() {
        return inputSettings.map(s => (
            <TextField
                key={s.value}
                className="col-12 mb-3"
                label={<LocaleMessage msg={s.label_code} />}
                fullWidth
                value={options[s.value] || ''}
                onChange={event =>
                    onDataChange({
                        ...data,
                        options: {
                            ...options,
                            [s.value]: event.target.value,
                        },
                    })
                }
                disabled={!allowEdition}
            />
        ));
    }

    return (
        <div className="row">
            {super_user ? renderSUConfig() : null}
            <div className="col-md-6 col-12 mb-5">
                <TextField
                    label={
                        <LocaleMessage msg="label.settings.info_formatters.name" />
                    }
                    fullWidth
                    value={data.name || ''}
                    onChange={event =>
                        onDataChange({
                            ...data,
                            name: event.target.value,
                        })
                    }
                    disabled={!allowEdition}
                />
            </div>
            {renderGroupSelector()}

            <div className="col-md-6 col-12 mb-5">{renderSwitches()}</div>

            <div className="col-md-6 col-12 mb-5">{renderInputs()}</div>
        </div>
    );
}

InfoFormatterSettings.defaultProps = {
    onDataChange: () => {},
    onError: () => {},
    allowEdition: true,
    operation: 'update',
};

InfoFormatterSettings.propTypes = {
    data: PropTypes.object.isRequired,
    onDataChange: PropTypes.func,
    onError: PropTypes.func,
    allowEdition: PropTypes.bool,
    operation: PropTypes.string,
};
