/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';

const h100 = { height: '100%' };

export default function ActionsCard({
    width,
    options,
    robot,
    allowAction,
    renderLoading,
    runFunction,
    header,
    permission,
}) {
    const status = robot.status || {};
    const { status: robot_status } = status;
    const { actions } = options;

    const status_actions =
        actions && robot_status && actions[robot_status]
            ? actions[robot_status]
            : null;

    function renderActionButton(action) {
        const c = action.backgroundColor;
        const blacklist = action.blacklist || {};
        const perm_blacklist = blacklist.permission || [];

        const allow_permission = perm_blacklist.indexOf(permission) === -1;

        const disabled = !allowAction || !allow_permission;
        const style = disabled
            ? {
                  backgroundColor: '#999',
              }
            : {
                  ...(c !== 'primary' && {
                      backgroundColor: c,
                  }),
                  color: '#fff',
              };

        return (
            <Button
                key={action.key}
                className="mb-3"
                variant="contained"
                fullWidth
                onClick={() => runFunction(action.onClick)}
                style={style}
                disabled={disabled}
                color={c === 'primary' ? 'primary' : undefined}
            >
                {action.label ? <LocaleMessage msg={action.label} /> : null}
            </Button>
        );
    }

    return robot_status !== 'disconnected' && status_actions ? (
        <div className={width} style={h100}>
            <Box style={h100}>
                <InfoCard>
                    <div className="desc-full">
                        {allowAction ? (
                            <>
                                {header || (
                                    <span className="mb-3">
                                        <LocaleMessage msg="rc3.robot.data.card.actions" />
                                    </span>
                                )}
                                {status_actions.map(a => {
                                    return renderActionButton(a);
                                })}
                            </>
                        ) : (
                            <div className="mb-3 pt-0 desc-full">
                                {renderLoading()}
                            </div>
                        )}
                    </div>
                </InfoCard>
            </Box>
        </div>
    ) : null;
}

ActionsCard.propTypes = {
    width: PropTypes.string,
    options: PropTypes.object,
    robot: PropTypes.object,
    renderLoading: PropTypes.func.isRequired,
    runFunction: PropTypes.func.isRequired,
    allowAction: PropTypes.bool,
    header: PropTypes.element,
    permission: PropTypes.string,
};

ActionsCard.defaultProps = {
    width: 'col-12',
    options: { key: 'total', style: 'bar', size: 'col-12' },
    robot: {},
    allowAction: false,
    header: null,
    permission: 'viewer',
};
