import styled from 'styled-components';

export const IconArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    :hover {
        cursor: pointer;
    }
`;
