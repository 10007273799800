/* eslint-disable react/forbid-prop-types */
import React from 'react';
import EllipsisText from 'react-ellipsis-text';
import { MdDelete, MdPlayCircleFilled } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Checkbox, IconButton, Tooltip } from '@material-ui/core';

import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';

import { Container, Item, Overlay } from './styles';

export default function GalleryView(props) {
    const {
        allowNew,
        allowDelete,
        multiple,
        fileUpload,
        media,
        deleteFile,
        onClick,
        type,
        allowDeleteArray,
        deleteArray,
        onDeleteArrayChanged,
    } = props;

    function removeItemFromDeleteArray(item_id) {
        onDeleteArrayChanged(
            deleteArray.filter(i => {
                return i !== item_id;
            })
        );
    }

    function addItemToDeleteArray(item_id) {
        onDeleteArrayChanged([...deleteArray, item_id]);
    }

    function openItem(event, idx) {
        onDeleteArrayChanged([]);
        onClick(event, idx);
    }

    function renderDeleteCheckbox(item_id) {
        return (
            <Overlay visible={deleteArray.length > 0}>
                <Checkbox
                    className="delete-checkbox"
                    color="primary"
                    checked={deleteArray.includes(item_id)}
                    onClick={event => {
                        event.stopPropagation();
                        if (event.target.checked) {
                            addItemToDeleteArray(item_id);
                        } else {
                            removeItemFromDeleteArray(item_id);
                        }
                    }}
                />
            </Overlay>
        );
    }

    function imgItem(item, idx) {
        return (
            <Item
                className="jr-card jr-card-widget jr-card-full text-center card img-card"
                onClick={event => openItem(event, idx)}
            >
                {allowDeleteArray ? renderDeleteCheckbox(item.id) : null}
                <Tooltip title={item.name}>
                    <div
                        className="card-body text-center"
                        style={{
                            background: `url(${item.url})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                        }}
                    >
                        {allowDelete ? (
                            <div className="card-title">
                                <IconButton
                                    aria-label="more"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={event =>
                                        deleteFile(event, item.id)
                                    }
                                    style={{ padding: '5px' }}
                                >
                                    <MdDelete size={16} />
                                </IconButton>
                            </div>
                        ) : null}
                    </div>
                </Tooltip>
            </Item>
        );
    }

    function audioItem(item, idx) {
        return (
            <Item
                className="jr-card jr-card-widget jr-card-full text-center card"
                onClick={event => openItem(event, idx)}
            >
                {allowDeleteArray ? renderDeleteCheckbox(item.id) : null}
                <Tooltip title={item.name}>
                    <div>
                        <div className="audio-card">
                            <EllipsisText text={item.name} length={15} />
                            <MdPlayCircleFilled
                                size={48}
                                style={{
                                    margin: '15px',
                                }}
                            />
                        </div>
                        {allowDelete ? (
                            <div className="card-title">
                                <IconButton
                                    aria-label="more"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={event =>
                                        deleteFile(event, item.id)
                                    }
                                    style={{ padding: '5px' }}
                                >
                                    <MdDelete size={16} />
                                </IconButton>
                            </div>
                        ) : null}
                    </div>
                </Tooltip>
            </Item>
        );
    }

    function renderItem(item, idx) {
        let itemCard = null;
        switch (type) {
            case 'images':
                itemCard = imgItem(item, idx);
                break;
            case 'audios':
                itemCard = audioItem(item, idx);
                break;
            default:
        }

        return (
            <div className="col-md-3 col-4 item" key={item.id}>
                <>{itemCard}</>
            </div>
        );
    }

    return (
        <>
            <Container>
                {/* <CustomScrollbars style={{ height: '100%' }}> */}
                <div className="row">
                    {allowNew ? (
                        <div className="col-md-3 col-4 item">
                            <FileInput
                                type={type}
                                onFileUpload={fileUpload}
                                multiple={multiple}
                                text={<LocaleMessage msg="button.add_file" />}
                                showFooter={false}
                            />
                        </div>
                    ) : null}
                    {media.map((item, idx) => renderItem(item, idx))}
                </div>
                {/* </CustomScrollbars> */}
            </Container>
        </>
    );
}

GalleryView.propTypes = {
    allowNew: PropTypes.bool,
    allowDelete: PropTypes.bool,
    multiple: PropTypes.bool,
    fileUpload: PropTypes.func,
    deleteFile: PropTypes.func,
    onClick: PropTypes.func,
    media: PropTypes.array.isRequired,
    type: PropTypes.string,
    allowDeleteArray: PropTypes.bool,
    deleteArray: PropTypes.array,
    onDeleteArrayChanged: PropTypes.func,
};

GalleryView.defaultProps = {
    fileUpload: null,
    onClick: () => {
        return null;
    },
    deleteFile: () => {
        return null;
    },
    multiple: false,
    allowNew: false,
    allowDelete: false,
    type: 'images',
    allowDeleteArray: false,
    deleteArray: [],
    onDeleteArrayChanged: () => {},
};
