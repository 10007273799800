import styled from 'styled-components';

export const Option = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 5px;
    }

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;
