import { ToastContainer } from 'react-toastify';

import { darken } from 'polished';
import styled from 'styled-components';

export const AppContainer = styled.div`
    height: 100%;
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-family: 'Saira Semi Condensed', sans-serif !important;
    span,
    label,
    input {
        font-family: 'Saira Semi Condensed', sans-serif !important;
    }

    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(45deg, #000, ${darken(0.25, color)})`;
    }};
    color: #fff !important;
`;

export const Splash = styled.div`
    display: flex;
    padding: 0px;
    margin: 0px;
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100vh;
    z-index: 3000;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Container = styled.div`
    background-color: #f00;
    /* background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(-45deg, ${darken(0.5, '#000')}, ${darken(
            0.4,
            color
        )})`;
    }}; */
    height: 100%;
`;

export const Title = styled.div`
    padding: 30px;

    h1 {
        text-align: center;
        color: #fff;
        font-weight: bold;
    }
`;

export const Body = styled.div`
    padding: 0px;
    width: 100%;
    margin: auto;
    min-height: 100vh;
    height: 100vh;
`;

export const NullDiv = styled.div``;

export const RC3ToastContainer = styled(ToastContainer)`
    width: 20vw;
    .Toastify__toast {
        display: block;
    }
    .DARK {
        background-color: #000;
        border-left: 0.5vw solid ${props => props.theme.palette.primary.main};
    }
    .TELEPRESENCE {
        background-color: #000;
        border-left: 0.5vw solid ${props => props.theme.palette.primary.main};
    }
    .INDOOR_DELIVERY {
        background-color: #000;
        border-left: 0.5vw solid ${props => props.theme.palette.primary.main};
    }
`;

export const DarkDialog = styled.div`
    color: #fff !important;
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-bottom: 0.5vh solid ${props => props.theme.palette.primary.main};
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `${darken(0.25, color)}`;
    }};

    .MuiDialogTitle-root {
        h2 {
            font-family: 'Saira Semi Condensed', sans-serif !important;
            color: #fff !important;
        }
    }
`;
