import { all } from 'redux-saga/effects';

import application from './application/sagas';
import auth from './auth/sagas';
import pages from './pages/sagas';
import settings from './settings/sagas';
import user from './user/sagas';

export default function* rootSaga() {
    return yield all([settings, auth, user, application, pages]);
}
