/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdLaunch } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    Tooltip,
    IconButton,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import ExitDialog from '~/components/ExitDialog';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import { IconArea } from '~/styles/custom';

import api from '~/services/pluginbot-api';

import { Preview, MessagePreview } from './styles';

const action_available = {
    cruzr: true,
    temi: true,
    nao: false,
};

const warnings = {
    cruzr: [],
};

const exitURL = `/tools/presentation_contents`;

export default function PresentationModeConfig({
    handleError,
    robotTypes,
    currType,
    setCurrType,
    appSettings,
    updateObject,
}) {
    const settings = appSettings.settings || {};
    const app_languages =
        settings.language && settings.language.list
            ? settings.language.list
            : [];

    const [isLoading, setIsLoading] = useState(false);
    const [, setNeedSaving] = useState(false);

    const [presentationMode, setPresentationMode] = useState(
        settings && settings.presentation_mode ? settings.presentation_mode : {}
    );
    const [contents, setContents] = useState([]);
    const [messages, setMessages] = useState([]);
    const [previewLang, setPreviewLang] = useState(app_languages[0] || 'pt_BR');

    const [exitTo, setExitTo] = useState(exitURL);
    const [exitDialog, setExitDialog] = useState(false);

    const r_key = currType.slug ? currType.slug : 'all';
    const r_type_settings = presentationMode[r_key] || {};
    const r_warnings = warnings[r_key] || [];

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    async function loadContentMessages(_id) {
        if (!_id) {
            setMessages([]);
            return;
        }
        setIsLoading(true);
        await api
            .get(`app_contents/${_id}`)
            .then(response => {
                const { data } = response;
                const content = data.content || {};
                const c_messages = content.texts || [];
                setMessages(c_messages);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadContents() {
        setIsLoading(true);
        await api
            .get(`app_contents?type=presentation`)
            .then(response => {
                const { data } = response;
                setContents(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadContentMessages(r_type_settings.content_id);
    }, [r_type_settings.content_id]);

    useEffect(() => {
        loadContents();
    }, []);

    function setCurrTypeSettings(key, value) {
        setNeedSaving(true);
        const updated = {
            ...presentationMode,
            [r_key]: {
                ...r_type_settings,
                [key]: value,
            },
        };
        setPresentationMode(updated);
    }

    async function handleSubmit() {
        const data = { presentation_mode: presentationMode };
        updateObject(data);
    }

    function renderExitDialog() {
        return (
            <ExitDialog
                open={exitDialog}
                onClose={() => setExitDialog(false)}
                destination={exitTo}
            />
        );
    }

    function renderMessages() {
        const m_filter = messages.filter(m => {
            return m[previewLang];
        });

        return (
            <Preview>
                {m_filter.length > 0 ? (
                    m_filter.map(m => {
                        return (
                            <Tooltip
                                title={m[previewLang]}
                                arrow
                                placement="right"
                                disableHoverListener={!m.tag}
                                key={`message_${m.item_id}`}
                                // classes={{ tooltip: classes.tooltip }}
                            >
                                <MessagePreview
                                    className="d-flex flex-nowrap shortcut-item mb-3"
                                    hasTag={!!m.tag}
                                >
                                    <div className="bubble jambo-card">
                                        <div className="message">
                                            {m.tag
                                                ? `# ${m.tag}`
                                                : m[previewLang]}
                                        </div>
                                    </div>
                                </MessagePreview>
                            </Tooltip>
                        );
                    })
                ) : (
                    <LocaleMessage msg="page.applications.conversation.presentation_mode.preview.no_messages" />
                )}
            </Preview>
        );
    }

    function renderContentPreview() {
        return r_type_settings && r_type_settings.content_id ? (
            <div
                className="col-md-7 col-12 mb-5"
                style={{ textAlign: 'center' }}
            >
                <div
                    className="sidecard-header row"
                    style={{
                        width: '100%',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        className="mb-3 row"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <h2>
                            <LocaleMessage msg="page.applications.conversation.presentation_mode.preview" />
                        </h2>
                        <div className="col-md-4 col-12 px-0">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    setExitTo(
                                        `${exitURL}/${r_type_settings.content_id}`
                                    );
                                    setExitDialog(true);
                                }}
                            >
                                <LocaleMessage msg="button.edit" />
                                <MdLaunch size={20} className="ml-1 mr-0" />
                            </Button>
                        </div>
                    </div>
                </div>
                <Box
                    style={{
                        border: '1px solid #ddd',
                    }}
                >
                    <Tabs
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={previewLang}
                        onChange={(e, v) => {
                            setPreviewLang(v);
                        }}
                    >
                        {app_languages.map(l => {
                            return (
                                <Tab
                                    key={l}
                                    value={l}
                                    label={
                                        <small>
                                            <LocaleMessage
                                                msg={`list.languages.${l}`}
                                            />
                                        </small>
                                    }
                                />
                            );
                        })}
                    </Tabs>
                    <div style={{ flex: 1, padding: '10px 20px' }}>
                        {renderMessages()}
                    </div>
                </Box>
            </div>
        ) : null;
    }

    function renderContentSettings() {
        return (
            <div className="col-md-5 col-12 row mt-3">
                <div
                    className="col-12 mt-1 row"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div className="col-12">
                        {r_warnings.map(w => {
                            return (
                                <p key={w}>
                                    <LocaleMessage msg={w} />
                                </p>
                            );
                        })}
                    </div>
                    <div className="col-12 row mb-5">
                        <div
                            className={`col-${
                                r_type_settings && r_type_settings.content_id
                                    ? '12'
                                    : '11'
                            }`}
                        >
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.conversation.presentation_mode.current" />
                                </InputLabel>
                                <Select
                                    value={
                                        r_type_settings.content_id
                                            ? r_type_settings.content_id
                                            : ''
                                    }
                                    onChange={event => {
                                        setCurrTypeSettings(
                                            'content_id',
                                            event.target.value
                                        );
                                    }}
                                >
                                    <MenuItem value="">
                                        <LocaleMessage msg="page.applications.conversation.presentation_mode.null" />
                                    </MenuItem>
                                    {contents.map(c => (
                                        <MenuItem key={c.id} value={c.id}>
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {!(r_type_settings && r_type_settings.content_id) ? (
                            <IconArea className="col-1 px-0">
                                <Tooltip
                                    title={
                                        <LocaleMessage msg="label.see_contents" />
                                    }
                                >
                                    <IconButton
                                        onClick={() => {
                                            setExitTo(exitURL);
                                            setExitDialog(true);
                                        }}
                                    >
                                        <MdLaunch size={20} />
                                    </IconButton>
                                </Tooltip>
                            </IconArea>
                        ) : null}
                    </div>
                </div>
                <div className="col-12">
                    <Button
                        className="p-3 mb-5"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            {exitDialog ? renderExitDialog() : null}

            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.presentation_mode.title" />
                </h2>
            </div>

            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 25px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.presentation_mode.tutorial_apps" />
            </div>

            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <div
                        className="col-12"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <AppConfigRobotType
                            className="col-12"
                            robotTypes={robotTypes}
                            currType={currType}
                            setCurrType={t => setCurrType(t)}
                        >
                            {action_available[r_key] ? (
                                <div className="col-12 row">
                                    {renderContentSettings()}
                                    {renderContentPreview()}
                                </div>
                            ) : (
                                <div
                                    className="col-12 mt-3 mb-5"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LocaleMessage msg="label.form.not_available.robot_type" />
                                </div>
                            )}
                        </AppConfigRobotType>
                    </div>
                )}
            </div>
        </div>
    );
}

PresentationModeConfig.defaultProps = {};

PresentationModeConfig.propTypes = {
    robotTypes: PropTypes.array.isRequired,
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
