/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdPlayCircleFilled, MdCloudDownload } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getFormats from '~/util/GetFormats';

import FileInput from './FileInput';

export default function PluginstoreAppForm(props) {
    const dispatch = useDispatch();
    const user_settings = useSelector(state => state.settings || null);
    const formats = getFormats(user_settings);
    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({});
    const [appFile, setAppFile] = useState({ id: null, url: '', name: '' });
    const [robots, setRobots] = useState([]);
    const [downloads, setDownloads] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(error.response.data.error);
        } else if (error.request) {
            toast.error('Connection Error! Please wait a moment and try again');
        } else {
            toast.error('Unknown Error! Please wait a moment and try again');
        }
        setIsLoading(false);
    }

    async function loadRobotTypes() {
        setIsLoading(true);
        await api
            .get(`types?group=robot`)
            .then(response => {
                setRobots(response.data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadAppVersion(_id) {
        if (_id === 'new') {
            setOperation('create');
            setIsLoading(false);
        } else {
            setOperation('update');
            await api
                .get(`pluginstore/apps/${id}`)
                .then(response => {
                    const { data } = response;
                    const { file } = data;
                    setBody(data);
                    setAppFile(file);
                })
                .catch(error => requestError(error));

            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        }
    }

    async function loadAppDownloads(_id) {
        if (_id !== 'new') {
            setIsLoading(true);
            await api
                .get(`pluginstore/apps/${id}/downloads`)
                .then(response => {
                    const { data } = response;
                    const list = data.map(d => {
                        const datetime = new Date(d.date);
                        return {
                            ...d,
                            pluginspace_name: d.pluginspace
                                ? d.pluginspace.name
                                : '',
                            robot_code: d.robot ? d.robot.code : '',
                            date_time: datetime.toLocaleTimeString(
                                formats.format.format,
                                formats.date
                            ),
                            date_timestamp: datetime.toISOString(),
                        };
                    });
                    setDownloads(list);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadRobotTypes();
    }, []);

    useEffect(() => {
        loadAppVersion(id);
        loadAppDownloads(id);
    }, [id]);

    async function updateApp(data) {
        await api
            .put(`pluginstore/apps/${id}`, data)
            .then(() => {
                toast.success('App Version Updated');

                loadAppVersion(id);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`pluginstore/apps/`, data)
                .then(() => {
                    toast.success('App Version Created');
                    loadAppVersion(id);
                })
                .catch(error => requestError(error));
        } else {
            await updateApp(data);
        }
    }

    async function onFileUpload(e) {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        setIsLoading(true);
        const response = await api.post(`/pluginstore/apps/${id}/file`, data);
        const appfile = response.data;

        setAppFile(appfile);

        const updated = { ...body, file_id: appfile.id };
        updateApp(updated);
    }

    function handleChangeBody(event, key) {
        const val = event.target.value;
        setBody({
            ...body,
            [key]: val,
        });
    }

    function renderDownloadList() {
        const headCells = [
            {
                id: 'date_time',
                label: <LocaleMessage msg="table.headers.date" />,
                order_by: 'date_timestamp',
            },
            {
                id: 'version',
                label: (
                    <LocaleMessage msg="page.pluginstore.form.label.app_version" />
                ),
            },
            {
                id: 'robot_code',
                label: <LocaleMessage msg="table.headers.robot" />,
            },
            {
                id: 'pluginspace_name',
                label: <LocaleMessage msg="table.headers.pluginspace" />,
            },
        ];

        return (
            <div
                className="col-12"
                style={{ minHeight: '150px', width: '100%' }}
            >
                <DataTable
                    data={downloads}
                    orderColumn="date_timestamp"
                    orderDirection="desc"
                    headerColumns={headCells}
                    rowActions={[]}
                    handleTableRowClick={() => {}}
                    hasHeader={false}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.pluginstore.create" />
                ) : (
                    <LocaleMessage msg="page.pluginstore.edit" />
                )
            }
            breadcrumbs={[
                {
                    url: '/pluginstore/apps',
                    title: <LocaleMessage msg="page.pluginstore.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`col-md-${
                            operation === 'create' ? '8' : '6'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                operation === 'create' ? (
                                    <LocaleMessage msg="page.pluginstore.create" />
                                ) : (
                                    <LocaleMessage msg="page.pluginstore.edit" />
                                )
                            }
                            Icon={MdPlayCircleFilled}
                        >
                            <>
                                <div className="row">
                                    <div
                                        className="col-md-6 col-12 mb-5"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.robot_type" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    body.robottype_id
                                                        ? body.robottype_id
                                                        : ''
                                                }
                                                disabled={
                                                    operation !== 'create'
                                                }
                                                onChange={event =>
                                                    handleChangeBody(
                                                        event,
                                                        'robottype_id'
                                                    )
                                                }
                                            >
                                                {robots.map(r => {
                                                    return (
                                                        <MenuItem
                                                            value={r.id}
                                                            key={r.id}
                                                        >
                                                            {r.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <TextField
                                            id="app-name"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.app_name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                handleChangeBody(event, 'name')
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="app-identifier"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.app_identifier" />
                                            }
                                            fullWidth
                                            value={body.identifier || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'identifier'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="app-package"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.app_package" />
                                            }
                                            fullWidth
                                            value={body.app_package || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'app_package'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 mb-5">
                                        <TextField
                                            id="robot-version"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.robot_version" />
                                            }
                                            fullWidth
                                            value={body.robot_version || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'robot_version'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 mb-5">
                                        <TextField
                                            id="app-version"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.app_version" />
                                            }
                                            fullWidth
                                            value={body.app_version || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'app_version'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3 col-6 mb-5">
                                        <TextField
                                            id="version-code"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.version_code" />
                                            }
                                            fullWidth
                                            value={body.version_code || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'version_code'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            id="description"
                                            label={
                                                <LocaleMessage msg="page.pluginstore.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                handleChangeBody(
                                                    event,
                                                    'description'
                                                )
                                            }
                                        />
                                    </div>

                                    {operation === 'update' ? (
                                        <div className="col-12 mb-5">
                                            <FileInput
                                                defaultValue={appFile}
                                                onFileUpload={_id =>
                                                    onFileUpload(_id)
                                                }
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                <div className="col-12 mb-3">
                                    <Button
                                        className="p-3"
                                        variant="contained"
                                        color="primary"
                                        onClick={event => handleSubmit(event)}
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.save" />
                                    </Button>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                hide
                                title="DOWNLOADS"
                                Icon={MdCloudDownload}
                            >
                                {renderDownloadList()}
                            </CardSideBordered>
                        </div>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

PluginstoreAppForm.propTypes = {
    match: PropTypes.object.isRequired,
};
