/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPlace, MdQueue, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import GroupSelector from '~/components/Form/GroupSelector';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function LocationForm({
    location_id,
    linkRoot,
    adminMode,
    allowEdit,
    breadcrumbs,
}) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);

    const { active } = settings;

    const date_loc = getDateLocale(settings);

    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        group_id: active ? active.id : '*',
    });
    const [address, setAddress] = useState({
        street: '',
        number: '',
        city: '',
        state: '',
        country: '',
    });
    const [file, setFile] = useState({
        id: null,
        url: null,
    });
    const [zones, setZones] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLocation(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`locations/${_id}`)
                .then(response => {
                    const l = response.data;
                    setBody({
                        name: l.name,
                        description: l.description,
                        group_id: l.group ? l.group.id : '*',
                    });
                    setAddress(l.address);
                    setFile(l.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadLocationZones(_id) {
        if (_id !== 'new') {
            await api
                .get(`/zones?location=${_id}`)
                .then(response => {
                    const data = response.data.map(z => {
                        const updated = new Date(z.updated);
                        return {
                            ...z,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setZones(data);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadLocation(location_id);
        loadLocationZones(location_id);
    }, [location_id]);

    async function updateLocation(data) {
        await api
            .put(`locations/${location_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.locations.form.update_success" />
                );
                setIsLoading(false);
                loadLocation(location_id);
            })
            .catch(error => requestError(error));
    }

    async function deleteZone() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`/zones/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.locations.zone.list.delete_success" />
                );
                await loadLocationZones(location_id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        history.push(`${linkRoot}/locations/${location_id}/zones/${_id}`);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteDialogOpen(true);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body, address };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`locations`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.locations.form.create_success" />
                    );

                    history.push(`${linkRoot}/locations`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateLocation(data);
        }
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`locations/${location_id}/media`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id, address };
                setBody(updated);
                updateLocation(updated);
            })
            .catch(error => requestError(error));
    };

    function fileInput() {
        return (
            <div
                className="col-12 mb-5"
                style={{ height: '200px', justifyContent: 'center' }}
            >
                <FileInput
                    defaultValue={file}
                    onFileUpload={onFileUpload}
                    multiple={false}
                    text={<LocaleMessage msg="message.change_file" />}
                    disabled={!allowEdit}
                    showFooter={!!(file && file.id)}
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <div>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <LocaleMessage msg="page.locations.zone.list.delete_title" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LocaleMessage msg="message.recycler.content" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteDialogOpen(false)}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={() => deleteZone(currItem)}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.remove" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderZonesList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={zones}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.locations.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.locations.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...breadcrumbs,
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`${
                            operation === 'create' ? 'col-md-8' : 'col-md-6'
                        }  col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.locations.form.title" />
                            }
                            Icon={MdPlace}
                            className="mb-5"
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    {adminMode ? (
                                        <div className="col-md-6 col-12 mb-5">
                                            <GroupSelector
                                                value={body.group_id || ''}
                                                onChange={value =>
                                                    setBody({
                                                        ...body,
                                                        group_id: value,
                                                    })
                                                }
                                                disabled={
                                                    operation !== 'create'
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-10 col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.address" />
                                            }
                                            fullWidth
                                            value={address.street || ''}
                                            onChange={event =>
                                                setAddress({
                                                    ...address,
                                                    street: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2 col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.number" />
                                            }
                                            fullWidth
                                            value={address.number || ''}
                                            onChange={event =>
                                                setAddress({
                                                    ...address,
                                                    number: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.city" />
                                            }
                                            fullWidth
                                            value={address.city || ''}
                                            onChange={event =>
                                                setAddress({
                                                    ...address,
                                                    city: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.state" />
                                            }
                                            fullWidth
                                            value={address.state || ''}
                                            onChange={event =>
                                                setAddress({
                                                    ...address,
                                                    state: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.form.label.country" />
                                            }
                                            fullWidth
                                            value={address.country || ''}
                                            onChange={event =>
                                                setAddress({
                                                    ...address,
                                                    country: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {operation === 'update' ? (
                                        <>{fileInput()}</>
                                    ) : null}
                                    <div className="col-12">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                            disabled={!allowEdit}
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.locations.zone.list.title" />
                                }
                                Icon={MdQueue}
                                hide
                            >
                                <>
                                    <div className="body-top-controls">
                                        <div
                                            className="col-6"
                                            style={{ padding: '0px' }}
                                        >
                                            <NewItemButton
                                                link={`${linkRoot}/locations/${location_id}/zones/new`}
                                                text={
                                                    <LocaleMessage msg="page.locations.zone.list.add" />
                                                }
                                                disabled={!allowEdit}
                                                disabledMessage="permission"
                                            />
                                        </div>
                                    </div>
                                    {renderZonesList()}
                                </>
                            </CardSideBordered>
                        </div>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

LocationForm.defaultProps = {
    breadcrumbs: [],
    allowEdit: false,
    adminMode: false,
};

LocationForm.propTypes = {
    location_id: PropTypes.string.isRequired,
    linkRoot: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
    allowEdit: PropTypes.bool,
    adminMode: PropTypes.bool,
};
