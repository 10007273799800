/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdBuild, MdExtension, MdDelete, MdAdd } from 'react-icons/md';
import * as MaterialDesign from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function PlatformToolForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [toolOperation, setToolOperation] = useState('create');
    const [pluginOperation, setPluginOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        group: 'tool',
    });
    const [icon, setIcon] = useState('');
    const [plugins, setPlugins] = useState([]);
    const [compatiblePlugins, setCompatiblePlugins] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [pluginType, setPluginType] = useState(null);
    const [formOpen, setFormOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [toolSettings, setToolSettings] = useState({});

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPlugins() {
        await api
            .get(`types?group=plugin`)
            .then(response => {
                const p = response.data;
                setPlugins(p);
            })
            .catch(error => requestError(error));
    }

    async function loadCompatiblePlugins(_id) {
        if (_id !== 'new') {
            await api
                .get(`types/tools/${_id}/plugins`)
                .then(response => {
                    const p = response.data;
                    setCompatiblePlugins(p.plugins);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadType(_id) {
        if (_id === 'new') {
            setToolOperation('create');
        } else {
            setToolOperation('update');
            await api
                .get(`types/${_id}`)
                .then(async response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        group: 'tool',
                        slug: t.slug,
                    });
                    setToolSettings(t.settings);
                    setIcon(
                        t.settings && t.settings.icon ? t.settings.icon : ''
                    );
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadPlugins();
        loadType(id);
        loadCompatiblePlugins(id);
    }, [id]);

    async function updateType(data) {
        await api
            .put(`types/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.platform_tools.form.update_success" />
                );
                setIsLoading(false);
                loadType(id);
            })
            .catch(error => requestError(error));
    }

    async function removePlugin() {
        setDialogOpen(false);
        setIsLoading(true);

        await api
            .delete(`types/tools/${id}/plugins/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.platform_tools.form.plugin_type_remove_success" />
                );
            })
            .catch(error => requestError(error));

        await loadCompatiblePlugins(id);
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options: { ...toolSettings, icon } };
        if (toolOperation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.platform_tools.form.create_success" />
                    );

                    history.push(`/platform_tools/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    async function handlePluginSubmit(event) {
        event.preventDefault();

        setFormOpen(false);
        setIsLoading(true);

        const data = { ...pluginType };

        await api
            .post(`types/tools/${id}/plugins`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.platform_tools.form.plugin_type_add_success" />
                );
            })
            .catch(error => requestError(error));

        await loadCompatiblePlugins(id);
        setIsLoading(false);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleNewPlugin() {
        setPluginOperation('create');
        setFormOpen(true);
    }

    function buildListView() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.plugin" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={compatiblePlugins}
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function renderPluginList() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.platform_tools.form.plugin_type.title" />
                    }
                    Icon={MdExtension}
                    hide
                >
                    <>
                        <div className="body-top-controls">
                            <Button
                                onClick={() => handleNewPlugin()}
                                variant="contained"
                                color="primary"
                                startIcon={<MdAdd />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.platform_tools.form.plugin_type_add" />
                            </Button>
                        </div>
                        {buildListView()}
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.platform_tools.form.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removePlugin(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildFormDialog() {
        const filtered_plugins = plugins.filter(p => {
            return !compatiblePlugins.find(cp => cp.id === p.id);
        });
        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <LocaleMessage msg="page.platform_tools.form.plugin_type_add" />
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.platform_tools.form.label.plugin_type" />
                        </InputLabel>
                        <Select
                            id="plugin-type"
                            value={pluginType ? pluginType.plugintype_id : ''}
                            onChange={event =>
                                setPluginType({
                                    ...pluginType,
                                    plugintype_id: event.target.value,
                                })
                            }
                            disabled={pluginOperation === 'update'}
                        >
                            {filtered_plugins.map(p => (
                                <MenuItem value={p.id} key={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handlePluginSubmit(event)}
                        color="primary"
                    >
                        {pluginOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const mdIcon = MaterialDesign[icon];
    return (
        <PageContent
            title={
                toolOperation === 'create' ? (
                    <LocaleMessage msg="page.platform_tools.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.platform_tools.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/platform_tools',
                    title: <LocaleMessage msg="page.platform_tools.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                {formOpen ? buildFormDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.platform_tools.form.label.settings" />
                            }
                            Icon={MdBuild}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="tool-name"
                                            label={
                                                <LocaleMessage msg="page.platform_tools.form.label.tool_name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="tool-slug"
                                            label={
                                                <LocaleMessage msg="label.form.slug" />
                                            }
                                            fullWidth
                                            value={body.slug || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                toolSettings &&
                                                                toolSettings.free
                                                                    ? toolSettings.free
                                                                    : false
                                                            }
                                                            value="free"
                                                            onChange={event =>
                                                                setToolSettings(
                                                                    {
                                                                        ...toolSettings,
                                                                        free:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.platform_tools.form.label.free" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mb-5">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                toolSettings &&
                                                                toolSettings.visible
                                                                    ? toolSettings.visible
                                                                    : false
                                                            }
                                                            value="visible"
                                                            onChange={event =>
                                                                setToolSettings(
                                                                    {
                                                                        ...toolSettings,
                                                                        visible:
                                                                            event
                                                                                .target
                                                                                .checked,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.platform_tools.form.label.visible" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                    {toolSettings && toolSettings.visible ? (
                                        <>
                                            <div className="col-md-6 col-10 mb-5">
                                                <TextField
                                                    id="tool-icon"
                                                    label={
                                                        <LocaleMessage msg="page.platform_tools.form.label.tool_icon" />
                                                    }
                                                    fullWidth
                                                    value={icon}
                                                    onChange={event =>
                                                        setIcon(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="col-md-6 col-2 mb-5"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {mdIcon ? (
                                                    React.createElement(
                                                        mdIcon,
                                                        {
                                                            size: 50,
                                                        }
                                                    )
                                                ) : (
                                                    <LocaleMessage msg="errors.icon_not_found" />
                                                )}
                                            </div>

                                            <div className="col-12 mb-5">
                                                <TextField
                                                    id="tool-route"
                                                    label={
                                                        <LocaleMessage msg="page.platform_tools.form.label.tool_route" />
                                                    }
                                                    fullWidth
                                                    value={
                                                        toolSettings.route || ''
                                                    }
                                                    onChange={event =>
                                                        setToolSettings({
                                                            ...toolSettings,
                                                            route:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : null}

                                    <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {toolOperation === 'update' ? renderPluginList() : null}
                </form>
            </>
        </PageContent>
    );
}

PlatformToolForm.propTypes = {
    match: PropTypes.object.isRequired,
};
