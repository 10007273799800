import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;

    .avatar-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .centered-line {
        justify-content: center;
        text-align: center;
    }
`;

export const Option = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 5px;
    }

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;
