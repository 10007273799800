/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import ShowInvite from '../ShowInvite';

export default function InviteList({ setToast, requestError, appSettings }) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const date_format = _settings.locale;
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
    };

    const { id } = appSettings;

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [inviteList, setInviteList] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [generatedURL, setGeneratedURL] = useState('');

    async function loadInvites() {
        setIsLoading(true);
        const curr_date = new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        await api
            .get(
                `/apps/invite_manager/${id}/invites?active=true&filter=end_date&from=${curr_date}`
            )
            .then(response => {
                const list = response.data;
                const invites = list.map(i => {
                    const s_date = new Date(i.start_date);
                    const e_date = new Date(i.end_date);

                    const s = s_date.toLocaleString(date_format.format, {
                        ...date_opt,
                        ...time_opt,
                    });
                    const e = e_date.toLocaleString(date_format.format, {
                        ...date_opt,
                        ...time_opt,
                    });
                    return {
                        ...i,
                        start: s,
                        end: e,
                        created_by: i.created_by || '',
                    };
                });
                setInviteList(invites);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadInvites();
    }, [date_loc]);

    async function deleteItem() {
        setIsLoading(true);
        setDeleteOpen(false);
        await api
            .delete(`/apps/invite_manager/${id}/invites/${currItem}`)
            .then(async () => {
                await loadInvites();
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.invite_manager.invite_list.deleted" />
                    ),
                });
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleDeleteItem(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        setDeleteOpen(true);
    }

    function handleTableRowClick(event, _id) {
        const invite = inviteList.find(i => {
            return i.id === _id;
        });
        if (invite) {
            setGeneratedURL(invite.url);
        }
        setDialogOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildDialog() {
        return (
            <ShowInvite
                setToast={t => setToast(t)}
                handleDialogClose={handleDialogClose}
                invite={generatedURL}
            />
        );
    }

    function renderInviteList() {
        const headCells = [
            {
                id: 'start',
                label: <LocaleMessage msg="table.headers.start_date" />,
            },
            {
                id: 'end',
                label: <LocaleMessage msg="table.headers.end_date" />,
            },
            {
                id: 'robot_name',
                label: <LocaleMessage msg="table.headers.robot" />,
            },
            {
                id: 'expected',
                label: <LocaleMessage msg="table.headers.expected" />,
            },
            {
                id: 'created_by',
                label: <LocaleMessage msg="table.headers.created_by" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteItem,
            },
        ];

        return (
            <div className="mt-3" style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    data={inviteList}
                    orderColumn="start"
                    headerColumns={headCells}
                    rowActions={rowActions}
                    hasFilter
                    hasHeader
                    header={
                        <div
                            className="sidecard-header"
                            style={{ padding: '0px' }}
                        >
                            <h2>
                                <LocaleMessage msg="page.applications.invite_manager.invite_list.current_date" />
                            </h2>
                        </div>
                    }
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.applications.invite_manager.invite_list.confirm_revoke" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <div className="col-12 mb-3" style={{ padding: '0px 20px' }}>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {dialogOpen ? buildDialog() : null}
                        {deleteOpen ? buildDeleteDialog() : null}
                        {renderInviteList()}
                    </>
                )}
            </div>
        </div>
    );
}

InviteList.propTypes = {
    setToast: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
};
