/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdPhotoSizeSelectLarge,
    MdPhotoSizeSelectActual,
} from 'react-icons/md';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    Slider,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

import { ParameterArea, MediaArea } from '../../styles';

export default function LoginPageParameters({
    pageKey,
    pageTitle,
    settings,
    setSettings,
    galleries,
    setToast,
    handleError,
    currFile,
    setCurrFile,
    updateFile,
    allowedSettings,
}) {
    const pageSettings = settings[pageKey] || {};
    const mediaSettings = pageSettings.media || {};
    const defGallery = pageSettings.gallery_id || '';

    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [currGallery, setCurrGallery] = useState(defGallery);

    function setPageSettings(key, val) {
        setSettings([pageKey], {
            ...pageSettings,
            [key]: val,
        });
    }

    async function loadAlbumFiles(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setIsLoading(false);
        } else {
            setFileList([]);
        }
    }

    function loadFile(_id) {
        const file = fileList.find(f => {
            return f.id === _id;
        });
        setCurrFile(file);
        updateFile(file);
    }

    useEffect(() => {
        if (pageSettings.show_image && !currGallery) {
            setCurrGallery(galleries && galleries[0] ? galleries[0].id : null);
        }
    }, [galleries, pageSettings.show_image]);

    useEffect(() => {
        loadAlbumFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        return loadFile(mediaSettings.file ? mediaSettings.file.file_id : '');
    }, [fileList, mediaSettings.file]);

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(() => {
                setToast({
                    color: 'success',
                    body: <LocaleMessage msg="message.media_uploaded" />,
                });
                loadAlbumFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const file_url = file.url;
        const f_properties = file_url.split('.');
        const f_format = f_properties[f_properties.length - 1];

        setPageSettings('media', {
            type: 'image',
            gallery_id: currGallery,
            file: {
                file_id: file.id,
                url: file.url,
                image: file.path,
                format: f_format,
            },
        });
        setMediaDialogOpen(false);
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
    }

    function renderGallery(type) {
        const currGalleryObj = galleries.find(g => {
            return g.id === currGallery;
        });
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.telepresence.form.gallery" />
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {!g.editable ? (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : null}
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={
                                        type === 'image' &&
                                        currGalleryObj.editable
                                    }
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type = 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    <LocaleMessage msg="page.applications.telepresence.form.media" />
                }
                content={renderGallery(g_type)}
            />
        );
    }

    return (
        <>
            {mediaDialogOpen ? renderMediaDialog() : null}
            <ParameterArea>
                <span className="mt-5 mb-3 p-3">
                    <LocaleMessage msg={pageTitle} />
                </span>
                {allowedSettings.logo ? (
                    <div className="row mt-3">
                        <div className="col-12">
                            <FormSwitch
                                value={
                                    pageSettings.show_logo
                                        ? pageSettings.show_logo
                                        : false
                                }
                                onChange={event =>
                                    setPageSettings(
                                        'show_logo',
                                        event.target.checked
                                    )
                                }
                                label={
                                    <LocaleMessage msg="page.applications.indoor_delivery.form.visual.show_logo" />
                                }
                            />
                        </div>
                    </div>
                ) : null}
                {allowedSettings.image ? (
                    <>
                        <div className="col-12 row">
                            <div className="col-6">
                                <FormSwitch
                                    fullWidth
                                    className="mb-3"
                                    value={
                                        pageSettings.show_image
                                            ? pageSettings.show_image
                                            : false
                                    }
                                    onChange={event =>
                                        setPageSettings(
                                            'show_image',
                                            event.target.checked
                                        )
                                    }
                                    label={
                                        <LocaleMessage msg="page.applications.indoor_delivery.form.visual.show_image" />
                                    }
                                />
                            </div>
                        </div>
                        {pageSettings.show_image ? (
                            <div className="col-12 row">
                                <div
                                    className="col-md-6 col-12"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span className="mb-3">
                                        <LocaleMessage msg="page.applications.telepresence.form.image_size" />
                                    </span>
                                    <Grid
                                        className="mb-3"
                                        container
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <MdPhotoSizeSelectLarge />
                                        </Grid>
                                        <Grid item xs>
                                            <Slider
                                                min={1}
                                                max={100}
                                                value={
                                                    pageSettings &&
                                                    pageSettings.media_width
                                                        ? Number(
                                                              pageSettings.media_width
                                                          )
                                                        : 50
                                                }
                                                onChange={(event, newValue) =>
                                                    setPageSettings(
                                                        'media_width',
                                                        newValue
                                                    )
                                                }
                                                aria-labelledby="continuous-slider"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <MdPhotoSizeSelectActual />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        className="mb-3"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        fullWidth
                                        onClick={() => setMediaDialogOpen(true)}
                                    >
                                        <LocaleMessage msg="label.form.change_image" />
                                    </Button>
                                </div>
                                <MediaArea className="col-md-6 col-12">
                                    {currFile ? (
                                        <Card className="img-card">
                                            <img
                                                src={currFile.url}
                                                alt={currFile.id}
                                            />
                                        </Card>
                                    ) : null}
                                </MediaArea>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </ParameterArea>
        </>
    );
}

LoginPageParameters.defaultProps = {
    settings: {},
    galleries: [],
    allowedSettings: {
        logo: true,
        image: true,
    },
    currFile: null,
};

LoginPageParameters.propTypes = {
    pageKey: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    settings: PropTypes.object,
    setSettings: PropTypes.func.isRequired,
    galleries: PropTypes.array,
    handleError: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    updateFile: PropTypes.func.isRequired,
    currFile: PropTypes.object,
    setCurrFile: PropTypes.func.isRequired,
    allowedSettings: PropTypes.object,
};
