/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDirectionsRun, MdDelete, MdAdd, MdSettings } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    FormControlLabel,
    TextField,
    Switch,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function PluginActionForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id, action_id } = match.params;

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const [actionOperation, setActionOperation] = useState('create');

    const [body, setBody] = useState({
        require_config: false,
        allow_trigger: false,
    });

    const [templates, setTemplates] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadTemplateList() {
        await api
            .get(`integrations?action_id=${action_id}`)
            .then(response => {
                const data = response.data.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        label: <LocaleMessage msg={t.name} />,
                        updated: formatDistance(
                            new Date(t.updated),
                            new Date(),
                            {
                                addSuffix: true,
                                locale: date_loc,
                            }
                        ),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setTemplates(data);
            })
            .catch(error => requestError(error));
    }

    async function loadAction(_id) {
        if (_id === 'new') {
            setActionOperation('create');
        } else {
            setActionOperation('update');
            loadTemplateList();
            await api
                .get(`actions/${_id}`)
                .then(response => {
                    setBody(response.data);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadAction(action_id);
    }, [action_id, date_loc]);

    async function removeTemplate() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`integrations/${currItem}`)
            .then(async () => {
                toast.success('Plugintemplate removed');
                await loadTemplateList();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function updateAction(data) {
        await api
            .put(`actions/${action_id}`, data)
            .then(() => {
                toast.success('Action updated');
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, plugintype_id: id };

        if (actionOperation === 'create') {
            await api
                .post(`actions`, data)
                .then(() => {
                    toast.success('Action created');

                    history.push(`/plugintypes/${id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateAction(data);
        }
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    async function handleTableRowClick(event, _id) {
        event.stopPropagation();
        history.push(
            `/plugintypes/${id}/actions/${action_id}/templates/${_id}`
        );
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildListView() {
        const headCells = [
            { id: 'label', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: 'Delete',
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={templates}
                    orderColumn="label"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function templateList() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.actions.form.template.title" />
                    }
                    hide
                    Icon={MdSettings}
                >
                    <>
                        <div className="body-top-controls">
                            <Link
                                to={`/plugintypes/${id}/actions/${action_id}/templates/new`}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<MdAdd />}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 20px',
                                    }}
                                >
                                    <LocaleMessage msg="page.actions.form.template.add" />
                                </Button>
                            </Link>
                        </div>
                        {buildListView()}
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.actions.form.template.delete" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeTemplate(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={
                actionOperation === 'create' ? (
                    <LocaleMessage msg="page.actions.create.title" />
                ) : (
                    <LocaleMessage msg="page.actions.edit.title" />
                )
            }
            currentPage={
                actionOperation === 'create' ? (
                    <LocaleMessage msg="page.actions.create.title" />
                ) : (
                    <LocaleMessage msg="page.actions.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/plugintypes',
                    title: <LocaleMessage msg="breadcrumbs.plugin_types" />,
                },
                {
                    url: `/plugintypes/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.actions" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.actions.form.label.settings" />
                            }
                            Icon={MdDirectionsRun}
                        >
                            <>
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <TextField
                                            id="action-name"
                                            label={
                                                <LocaleMessage msg="page.actions.form.label.name" />
                                            }
                                            fullWidth
                                            value={
                                                body && body.name
                                                    ? body.name
                                                    : ''
                                            }
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            id="action-function"
                                            label={
                                                <LocaleMessage msg="page.actions.form.label.function" />
                                            }
                                            fullWidth
                                            value={
                                                body && body.function_name
                                                    ? body.function_name
                                                    : ''
                                            }
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    function_name:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <FormControlLabel
                                        className="col-12 mb-3"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={
                                                    body && body.require_config
                                                        ? body.require_config
                                                        : false
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        require_config:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                            />
                                        }
                                        label={
                                            <LocaleMessage msg="page.actions.form.label.requires_config" />
                                        }
                                    />
                                    <FormControlLabel
                                        className="col-12 mb-3"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={
                                                    body && body.allow_trigger
                                                        ? body.allow_trigger
                                                        : false
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        allow_trigger:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                            />
                                        }
                                        label={
                                            <LocaleMessage msg="page.actions.form.label.allow_trigger" />
                                        }
                                    />
                                </div>
                                <div className="col-12 mb-3 mt-5">
                                    <Button
                                        className="p-3"
                                        variant="contained"
                                        color="primary"
                                        onClick={event => handleSubmit(event)}
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.save" />
                                    </Button>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {actionOperation === 'update' && body.require_config
                        ? templateList()
                        : null}
                </form>
            </>
        </PageContent>
    );
}

PluginActionForm.propTypes = {
    match: PropTypes.object.isRequired,
};
