/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';

export default function RobotInfo({
    robot,
    setRobot,
    operation,
    robotTypes,
    type,
    disabled,
}) {
    function changeRobotData(event, key) {
        setRobot({
            ...robot,
            [key]: event.target.value,
        });
    }

    return (
        <div className="row mb-1">
            <div
                className="col-md-6 col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <FormSelect
                    classes="mb-5"
                    options={robotTypes}
                    labelKey="name"
                    disabled={operation !== 'create'}
                    label={<LocaleMessage msg="label.form.robot_type" />}
                    onChange={value => {
                        setRobot({
                            ...robot,
                            type_id: value,
                        });
                    }}
                    value={robot.type_id ? robot.type_id : ''}
                />
                <TextField
                    label={<LocaleMessage msg="page.robots.form.label.code" />}
                    className="mb-5"
                    fullWidth
                    value={robot.code ? robot.code : ''}
                    onChange={event => changeRobotData(event, 'code')}
                    disabled={operation !== 'create'}
                />
                <TextField
                    label={<LocaleMessage msg="page.robots.form.label.name" />}
                    fullWidth
                    value={robot.name ? robot.name : ''}
                    onChange={event => changeRobotData(event, 'name')}
                    disabled={disabled}
                />
            </div>
            {type && type.file ? (
                <div className="col-md-6 col-12">
                    <img
                        src={type.file.url ? type.file.url : ''}
                        alt={type.file.url ? type.file.url : ''}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}

RobotInfo.defaultProps = {
    operation: 'update',
    robotTypes: [],
    type: {},
    disabled: false,
};

RobotInfo.propTypes = {
    robot: PropTypes.object.isRequired,
    setRobot: PropTypes.func.isRequired,
    robotTypes: PropTypes.array,
    type: PropTypes.object,
    operation: PropTypes.string,
    disabled: PropTypes.bool,
};
