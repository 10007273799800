/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SketchPicker } from 'react-color';
import {
    MdAdd,
    MdEdit,
    MdDelete,
    MdClose,
    MdExpandMore,
    MdExpandLess,
} from 'react-icons/md';
import ReactLoading from 'react-loading';
import reactCSS from 'reactcss';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import ColorInput from '~/components/ColorInput';
import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import {
    ParameterArea,
    MediaSelector,
    MediaArea,
    OptionArea,
    OptionCard,
    NoImageButton,
    PreviewArea,
    IconParameter,
} from '../styles';
import Preview from './Preview';

const warnings = {
    template: {
        all: [],
        cruzr: [],
    },
};

const mediatype_list = [
    {
        value: 'no-media',
        label: <LocaleMessage msg="label.form.no_media" />,
    },
    {
        value: 'image',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.media_type.gallery" />
        ),
    },
    {
        value: 'template',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.media_type.template" />
        ),
    },
    {
        value: 'face',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.media_type.face" />
        ),
    },
    {
        value: 'buttons',
        label: (
            <LocaleMessage msg="page.applications.conversation.content.media_type.buttons" />
        ),
    },
];

const textsize_list = [
    {
        value: 'small',
        label: <LocaleMessage msg="list.size.small" />,
    },
    {
        value: 'medium',
        label: <LocaleMessage msg="list.size.medium" />,
    },
    {
        value: 'large',
        label: <LocaleMessage msg="list.size.large" />,
    },
];

const available_template_settings = {
    text_only: {
        title: true,
        text: true,
    },
    img_only: {
        image: true,
    },
    overlay: {
        image: true,
        title: true,
        text: true,
    },
    side_img_left: {
        image: true,
        title: true,
        text: true,
    },
    side_img_right: {
        image: true,
        title: true,
        text: true,
    },
};

// const led_list = [
//     {
//         value: 'main',
//         label: (
//             <LocaleMessage msg="page.applications.conversation.content.led.main" />
//         ),
//     },
// ];

export default function MediaConfig({
    media,
    setBody,
    robotTypes,
    robotGalleries,
    handleError,
    setToast,
    imageGalleriesList,
    languages,
    currType,
    setCurrType,
}) {
    const theme = GetPluginspaceTheme(useTheme());
    const [isLoading, setIsLoading] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [templateList, setTemplateList] = useState([]);

    const [currGallery, setCurrGallery] = useState(null);
    const [galleryList, setGalleryList] = useState([]);

    const [currButton, setCurrButton] = useState('new');
    const [currLanguage, setCurrLanguage] = useState(languages[0]);

    const [buttonEditOpen, setButtonEditOpen] = useState(false);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    const r_key = media.different_robots ? currType.slug : 'all';
    const r_type_settings = media[r_key] || {};
    const [colorPicker, setColorPicker] = useState(false);

    const styles = reactCSS({
        default: {
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    async function loadFiles(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setIsLoading(false);
        } else {
            setFileList([]);
        }
    }

    async function loadTemplates() {
        setIsLoading(true);
        await api
            .get(`applications/templates/conversation`)
            .then(response => {
                const templates = response.data;
                setTemplateList(templates);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function filterGalleries(type) {
        const currTypeGalleries =
            currType && robotGalleries && robotGalleries[currType.slug]
                ? robotGalleries[currType.slug]
                : {};
        const faceGalleriesList =
            type === 'face' && currTypeGalleries && currTypeGalleries.faces
                ? currTypeGalleries.faces
                : [];
        const defGalleriesList =
            type === 'image' && currTypeGalleries && currTypeGalleries.images
                ? currTypeGalleries.images
                : [];

        const list =
            type === 'face'
                ? faceGalleriesList
                : [...imageGalleriesList, ...defGalleriesList];
        setGalleryList(list);
        setCurrGallery(list[0] ? list[0].id : null);
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    useEffect(() => {
        setCurrGallery(
            galleryList && galleryList[0] && galleryList[0].id
                ? galleryList[0].id
                : null
        );
    }, [galleryList]);

    useEffect(() => {
        loadFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        filterGalleries(r_type_settings.type);
    }, [robotGalleries, currType, r_key, r_type_settings.type]);

    // LED Colors
    // function setLed(l) {
    //     setBody({ led: l });
    // }

    // function handleColorClick(selector) {
    //     setColorPicker({
    //         selector,
    //         visible: !colorPicker.visible,
    //     });
    // }

    // function handleColorClose() {
    //     setColorPicker({ ...colorPicker, visible: false });
    // }

    // function handleColorChange(selector, c) {
    //     setLed({ ...led, [selector]: { ...led[selector], color: c.hex } });
    // }

    // function handleColorSwitchChange(event, selector) {
    //     setLed({
    //         ...led,
    //         [selector]: {
    //             ...led[selector],
    //             active: led[selector] ? !led[selector].active : true,
    //         },
    //     });
    // }

    function setMedia(m) {
        setBody({ media: m });
    }

    function setCurrTypeSettings(key, value) {
        const r_type_change = {
            ...r_type_settings,
            [key]: value,
            ...(key === 'type' && { file: null }),
        };

        const changes = {
            [r_key]: r_type_change,
        };

        if (r_key === 'all') {
            robotTypes.forEach(t => {
                const t_key = t.slug;
                changes[t_key] = r_type_change;
            });
        }

        setMedia({
            ...media,
            ...changes,
        });
    }

    function handleDifferentRobotsSwitch(event) {
        const allow_different = event.target.checked;

        const changes = {};

        const all_robots = media.all && media.all.type ? media.all : null;

        if (allow_different && all_robots) {
            if (all_robots.type && all_robots.type !== 'face') {
                robotTypes.forEach(r => {
                    const t_key = r.slug;
                    changes[t_key] = all_robots;
                });
            }
            changes.all = {};
        }
        setMedia({
            ...media,
            different_robots: allow_different,
            ...(allow_different && { ...changes }),
        });
    }

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(async () => {
                setToast({
                    color: 'success',
                    body: <LocaleMessage msg="message.media_uploaded" />,
                });
                await loadFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;

        const items = [...r_type_settings.items];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);

        setCurrTypeSettings('items', items);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const file_url = file.url;
        const f_properties = file_url.split('.');
        const f_format = f_properties[f_properties.length - 1];

        const file_obj = {
            file_id: file.id,
            url: file.url,
            image: file.path,
            format: file.format || f_format,
        };

        switch (r_type_settings.type) {
            case 'face': {
                const type_face = {
                    type: 'face',
                    face: file.name,
                    file: file_obj,
                };
                setMedia({
                    ...media,
                    ...(!media.different_robots && {
                        all: {
                            type: 'face',
                        },
                    }),
                    [currType.slug]: type_face,
                });
                break;
            }
            case 'image':
            case 'template': {
                setCurrTypeSettings('file', file_obj);
                break;
            }
            case 'buttons': {
                const { items } = r_type_settings;
                items[currButton] = {
                    ...items[currButton],
                    file: file_obj,
                };
                setCurrTypeSettings('items', items);
                break;
            }
            default:
                break;
        }
        setMediaDialogOpen(false);
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
    }

    function onMediaTypeChange(event) {
        const type = event.target.value;

        setCurrTypeSettings('type', type);
        if (
            (type === 'image' || type === 'face') &&
            (!r_type_settings.file || !r_type_settings.file.id)
        ) {
            setMediaDialogOpen(true);
        }
    }

    function onMediaItemChange(index, key, value) {
        const { items } = r_type_settings;
        items[index] = {
            ...items[index],
            [key]: value,
        };
        setCurrTypeSettings('items', items);
    }

    function handleBtnColorClose() {
        setColorPicker(false);
    }

    function handleBtnColorChange(index, c) {
        onMediaItemChange(index, 'background_color', c.hex);
        handleBtnColorClose();
    }

    function editOption(_id) {
        setCurrButton(_id);
        setButtonEditOpen(true);
    }

    function removeOption(idx) {
        let { items } = r_type_settings;
        if (!items) {
            items = [];
        }
        if (!items[idx]) {
            return;
        }
        items.splice(idx, 1);
        setCurrTypeSettings('items', items);
        setButtonEditOpen(false);
    }

    function handleNewOptionClick() {
        let { items } = r_type_settings;
        if (!items) {
            items = [];
        }
        const item_id = (+new Date()).toString(36);
        items.push({ item_id, file_id: null, text: '', message: '' });
        setCurrTypeSettings('items', items);
    }

    function handleOptionImageClick(_id) {
        setCurrButton(_id);
        setMediaDialogOpen(true);
    }

    function renderGallery(type) {
        const currGalleryObj = galleryList.find(g => {
            return g.id === currGallery;
        });

        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        {type === 'face' ? (
                            <LocaleMessage msg="page.applications.conversation.condition.face" />
                        ) : (
                            <LocaleMessage msg="label.form.gallery" />
                        )}
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleryList.map(g => {
                            return (
                                <MenuItem key={g.id} value={g.id}>
                                    {!g.editable ? (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                <LocaleMessage msg="message.open_all.short" />
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    ) : (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                g.group &&
                                                g.group.id &&
                                                g.group.id !== '*' ? (
                                                    g.group.name
                                                ) : (
                                                    <LocaleMessage msg="message.all_groups.short" />
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    )}

                                    {g.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={currGalleryObj.editable}
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type =
            r_type_settings.type && r_type_settings.type === 'face'
                ? 'face'
                : 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    g_type === 'face' ? (
                        <LocaleMessage msg="page.applications.conversation.condition.face" />
                    ) : (
                        <LocaleMessage msg="page.applications.conversation.condition.media" />
                    )
                }
                content={renderGallery(g_type)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderCurrImage() {
        const file = r_type_settings.file || {};
        return (
            <MediaArea className="col-md-6 col-12">
                {file && file.url ? (
                    <Card className="img-card">
                        <img src={file.url} alt={file.id} />
                    </Card>
                ) : null}

                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setMediaDialogOpen(true)}
                >
                    <LocaleMessage msg="label.form.change_image" />
                </Button>
            </MediaArea>
        );
    }

    function renderRobotFace() {
        const curr_robot_face = media[currType.slug] || {};
        const face_file = curr_robot_face.file || {};
        return (
            <MediaArea className="col-md-6 col-12">
                {face_file.url ? (
                    <Card className="img-card">
                        <img src={face_file.url} alt={face_file.name} />
                    </Card>
                ) : null}

                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setMediaDialogOpen(true)}
                >
                    <LocaleMessage msg="page.applications.conversation.condition.face" />
                </Button>
            </MediaArea>
        );
    }

    function renderCurrFaces() {
        return !media.different_robots ? (
            <AppConfigRobotType
                className="col-12 mb-5"
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={t => setCurrType(t)}
            >
                <div
                    className="col-12 row mt-2"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {isLoading ? (
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={50}
                            width={50}
                        />
                    ) : (
                        renderRobotFace()
                    )}
                </div>
            </AppConfigRobotType>
        ) : (
            renderRobotFace()
        );
    }

    function renderLanguageInputs(index, key, label) {
        const item = r_type_settings.items[index] || {};

        const inputs =
            item[key] && typeof item[key] === 'object'
                ? item[key]
                : {
                      pt_BR: item[key],
                  };
        return (
            <ParameterArea className="col-12 mt-3">
                <TextField
                    className="mb-1"
                    fullWidth
                    label={label}
                    value={
                        inputs && inputs[currLanguage]
                            ? inputs[currLanguage]
                            : ''
                    }
                    onChange={event =>
                        onMediaItemChange(index, key, {
                            ...inputs,
                            [currLanguage]: event.target.value,
                        })
                    }
                />
            </ParameterArea>
        );
    }

    function renderOptionSettings() {
        const item =
            r_type_settings &&
            r_type_settings.items &&
            r_type_settings.items[currButton]
                ? r_type_settings.items[currButton]
                : null;

        return item ? (
            <div className="col-7 px-0 card-content">
                <div
                    className="col-12 px-0"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'top',
                    }}
                >
                    <div className="col-8 p-0">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="label.form.language" />
                            </InputLabel>
                            <Select
                                value={currLanguage}
                                onChange={event =>
                                    setCurrLanguage(event.target.value)
                                }
                            >
                                {languages.map(l => (
                                    <MenuItem value={l} key={`lang_${l}`}>
                                        <LocaleMessage
                                            msg={`list.languages.${l}`}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <IconButton
                        className="col-2 p-0"
                        onClick={() => {
                            setButtonEditOpen(false);
                            setCurrButton('');
                        }}
                    >
                        <MdClose size={20} color="primary" />
                    </IconButton>
                </div>
                {renderLanguageInputs(
                    currButton,
                    'text',
                    <LocaleMessage msg="page.applications.conversation.condition.btn_text" />
                )}
                {renderLanguageInputs(
                    currButton,
                    'message',
                    <LocaleMessage msg="page.applications.conversation.condition.btn_msg" />
                )}
            </div>
        ) : null;
    }

    function renderOptionCard(item, index) {
        const curr_edit = buttonEditOpen && currButton === index;
        const card_class = curr_edit ? 'card-edit' : 'card-body';
        const image_col = curr_edit ? 'col-5 edit' : '';
        const item_file = item.file || {};
        const item_color = item.background_color || '#fff';

        return (
            <OptionCard>
                {colorPicker && curr_edit ? (
                    <div style={styles.popover}>
                        <div
                            style={styles.cover}
                            onClick={() => handleBtnColorClose()}
                        />
                        <SketchPicker
                            disableAlpha
                            style={styles.cover}
                            color={item_color}
                            onChange={color =>
                                handleBtnColorChange(currButton, color)
                            }
                            onChangeComplete={color => {
                                handleBtnColorChange(currButton, color);
                            }}
                            presetColors={
                                theme
                                    ? [
                                          theme.primary,
                                          theme.secondary,
                                          ...lists.default_colors,
                                      ]
                                    : null
                            }
                        />
                    </div>
                ) : null}
                <Card
                    className={`${card_class} ${!curr_edit ? 'card-move' : ''}`}
                >
                    <div
                        className={`img-col ${image_col} ${
                            curr_edit ? 'card-edit' : ''
                        }`}
                    >
                        <div
                            className={`card-image ${
                                curr_edit ? 'card-edit' : ''
                            }`}
                            onClick={() =>
                                curr_edit ? handleOptionImageClick(index) : {}
                            }
                        >
                            {item_file.url ? (
                                <img
                                    src={`${item_file.url}`}
                                    alt={`${item_file.file_id}`}
                                    style={{
                                        backgroundColor: item_color,
                                        objectFit: item.expand_img
                                            ? 'cover'
                                            : 'contain',
                                    }}
                                />
                            ) : (
                                <NoImageButton>
                                    {curr_edit ? (
                                        <>
                                            <LocaleMessage msg="page.applications.conversation.condition.add_image" />
                                            <MdAdd
                                                size={48}
                                                style={{
                                                    margin: '15px',
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </NoImageButton>
                            )}
                        </div>

                        {curr_edit ? (
                            <>
                                <div className="mt-3">
                                    <FormSwitch
                                        size="small"
                                        value={item.expand_img || false}
                                        onChange={event => {
                                            onMediaItemChange(
                                                currButton,
                                                'expand_img',
                                                event.target.checked
                                            );
                                        }}
                                        label={
                                            <LocaleMessage msg="page.applications.conversation.condition.expand_img" />
                                        }
                                    />
                                </div>
                                <div className="mt-1" style={{ width: '100%' }}>
                                    <ColorInput
                                        title={
                                            <LocaleMessage msg="page.applications.conversation.condition.background_color" />
                                        }
                                        margin="mb-0"
                                        color={item_color}
                                        handleColorClick={() =>
                                            setColorPicker(true)
                                        }
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>

                    {curr_edit ? (
                        renderOptionSettings()
                    ) : (
                        <div className="col-12 row mt-1 pt-1">
                            <Button
                                className="col-6"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    removeOption(index);
                                }}
                            >
                                <MdDelete size={24} color="primary" />
                            </Button>
                            <Button
                                className="col-6"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    editOption(index);
                                }}
                            >
                                <MdEdit size={24} color="primary" />
                            </Button>
                        </div>
                    )}
                </Card>
            </OptionCard>
        );
    }

    function renderOption(item, index) {
        const curr_edit = buttonEditOpen && currButton === index;
        const card_size = curr_edit ? 'col-md-8' : 'col-md-4';
        return (
            <div
                className={`${card_size} col-12 option-card`}
                key={item.item_id}
            >
                <Draggable
                    key={item.item_id}
                    draggableId={item.item_id}
                    index={index}
                    className="card-body"
                >
                    {drag_provided => {
                        return (
                            <div
                                className="card-body"
                                ref={drag_provided.innerRef}
                                {...drag_provided.draggableProps}
                                {...drag_provided.dragHandleProps}
                                style={{
                                    userSelect: 'none',
                                    ...drag_provided.draggableProps.style,
                                }}
                            >
                                {renderOptionCard(item, index)}
                            </div>
                        );
                    }}
                </Draggable>
            </div>
        );
    }

    function renderCurrOptions() {
        return (
            <OptionArea>
                <div className="col-12" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="label.reorder" />
                </div>
                <DragDropContext onDragEnd={result => onDragEnd(result)}>
                    <Droppable droppableId="condition-list">
                        {(drop_provided, drop_snapshot) => {
                            return (
                                <Card
                                    className="area-content"
                                    {...drop_provided.droppableProps}
                                    ref={drop_provided.innerRef}
                                    style={{
                                        background: drop_snapshot.isDraggingOver
                                            ? 'lightgrey'
                                            : '#ccc',
                                        width: '100%',
                                    }}
                                >
                                    <div className="row option-row">
                                        {r_type_settings &&
                                        r_type_settings.items
                                            ? r_type_settings.items.map(
                                                  (item, index) => {
                                                      return renderOption(
                                                          item,
                                                          index
                                                      );
                                                  }
                                              )
                                            : null}
                                    </div>
                                    {drop_provided.placeholder}
                                </Card>
                            );
                        }}
                    </Droppable>
                </DragDropContext>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => handleNewOptionClick()}
                    startIcon={
                        <MdAdd
                            style={{
                                color: '#fff',
                            }}
                        />
                    }
                >
                    <LocaleMessage msg="page.applications.conversation.condition.add_opt" />
                </Button>
            </OptionArea>
        );
    }

    function renderTextInput({ key, label, value, changeKey, lng }) {
        const template_settings =
            r_type_settings && r_type_settings.settings
                ? r_type_settings.settings
                : {};
        const texts = template_settings[changeKey] || {};

        return (
            <div className="col-12 mb-3" key={key}>
                <TextField
                    label={label}
                    fullWidth
                    value={value || ''}
                    onChange={event =>
                        setCurrTypeSettings('settings', {
                            ...template_settings,
                            [changeKey]: {
                                ...texts,
                                [lng]: event.target.value,
                            },
                        })
                    }
                    multiline
                />
            </div>
        );
    }

    function renderTemplate() {
        const template_warnings = warnings.template;
        const r_warnings = template_warnings[r_key] || [];

        const template_type =
            r_type_settings && r_type_settings.template
                ? r_type_settings.template
                : '';

        const template_settings =
            r_type_settings && r_type_settings.settings
                ? r_type_settings.settings
                : {};
        const title = template_settings.title || {};
        const info = template_settings.info || {};

        const type_settings = available_template_settings[template_type] || {};

        return (
            <div className="col-12 mt-3">
                <div className="mb-3">
                    {r_warnings.map(w => {
                        return (
                            <p key={w}>
                                <LocaleMessage msg={w} />
                            </p>
                        );
                    })}
                </div>
                <div className="col-12 row">
                    <div className="col-md-6 col-12 pt-3">
                        <ParameterArea className="col-12 pt-3">
                            <FormControl fullWidth className="mb-3">
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.conversation.condition.template" />
                                </InputLabel>
                                <Select
                                    value={template_type}
                                    onChange={event => {
                                        setCurrTypeSettings(
                                            'template',
                                            event.target.value
                                        );
                                    }}
                                >
                                    {templateList.map(m => (
                                        <MenuItem key={m.value} value={m.value}>
                                            <LocaleMessage msg={m.label_code} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </ParameterArea>
                        {r_type_settings.template ? (
                            <>
                                {type_settings.image ? (
                                    <ParameterArea className="col-12 pt-3">
                                        <h5>
                                            <LocaleMessage msg="page.applications.conversation.condition.template.image" />
                                        </h5>
                                        <Button
                                            className="mt-3 mb-3"
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            fullWidth
                                            onClick={() =>
                                                setMediaDialogOpen(true)
                                            }
                                        >
                                            <LocaleMessage msg="label.form.change_image" />
                                        </Button>

                                        <div className="col-12 mb-1 row">
                                            <FormSwitch
                                                value={
                                                    template_settings.expand_img ||
                                                    false
                                                }
                                                onChange={event => {
                                                    setCurrTypeSettings(
                                                        'settings',
                                                        {
                                                            ...template_settings,
                                                            expand_img:
                                                                event.target
                                                                    .checked,
                                                        }
                                                    );
                                                }}
                                                label={
                                                    <LocaleMessage msg="page.applications.conversation.condition.expand_img" />
                                                }
                                            />
                                        </div>
                                    </ParameterArea>
                                ) : null}
                                {type_settings.text ? (
                                    <ParameterArea className="col-12 pt-3">
                                        <h5>
                                            <LocaleMessage msg="page.applications.conversation.condition.template.texts" />
                                        </h5>
                                        <div className="col-12 mb-3">
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    <LocaleMessage msg="page.applications.conversation.condition.template.title_size" />
                                                </InputLabel>
                                                <Select
                                                    value={
                                                        template_settings &&
                                                        template_settings.title_size
                                                            ? template_settings.title_size
                                                            : ''
                                                    }
                                                    onChange={event =>
                                                        setCurrTypeSettings(
                                                            'settings',
                                                            {
                                                                ...template_settings,
                                                                title_size:
                                                                    event.target
                                                                        .value,
                                                            }
                                                        )
                                                    }
                                                >
                                                    {textsize_list.map(s => {
                                                        return (
                                                            <MenuItem
                                                                value={s.value}
                                                                key={`size_title_${s.value}`}
                                                            >
                                                                {s.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    <LocaleMessage msg="page.applications.conversation.condition.template.text_size" />
                                                </InputLabel>
                                                <Select
                                                    value={
                                                        template_settings &&
                                                        template_settings.text_size
                                                            ? template_settings.text_size
                                                            : 'disabled'
                                                    }
                                                    onChange={event =>
                                                        setCurrTypeSettings(
                                                            'settings',
                                                            {
                                                                ...template_settings,
                                                                text_size:
                                                                    event.target
                                                                        .value,
                                                            }
                                                        )
                                                    }
                                                >
                                                    <MenuItem value="disabled">
                                                        <LocaleMessage msg="list.size.disabled" />
                                                    </MenuItem>
                                                    {textsize_list.map(s => {
                                                        return (
                                                            <MenuItem
                                                                value={s.value}
                                                                key={`size_text_${s.value}`}
                                                            >
                                                                {s.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {languages.map(l => {
                                            return (
                                                <ParameterArea
                                                    className="col-12"
                                                    key={`lang_${l}`}
                                                >
                                                    <div className="col-12 row lang-text-params">
                                                        <h5>
                                                            <LocaleMessage
                                                                msg={`list.languages.${l}`}
                                                            />
                                                        </h5>

                                                        <IconParameter className="col-1">
                                                            {currLanguage ===
                                                            l ? (
                                                                <MdExpandLess
                                                                    size={22}
                                                                    onClick={() =>
                                                                        setCurrLanguage(
                                                                            ''
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <MdExpandMore
                                                                    size={22}
                                                                    onClick={() =>
                                                                        setCurrLanguage(
                                                                            l
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </IconParameter>
                                                    </div>
                                                    {currLanguage === l ? (
                                                        <>
                                                            {renderTextInput({
                                                                key: `${l}_title`,
                                                                label: (
                                                                    <LocaleMessage msg="page.applications.conversation.condition.template.title" />
                                                                ),
                                                                value:
                                                                    title &&
                                                                    title[l]
                                                                        ? title[
                                                                              l
                                                                          ]
                                                                        : '',
                                                                changeKey:
                                                                    'title',
                                                                lng: l,
                                                            })}
                                                            {template_settings &&
                                                            template_settings.text_size &&
                                                            template_settings &&
                                                            template_settings.text_size !==
                                                                'disabled'
                                                                ? renderTextInput(
                                                                      {
                                                                          key: `${l}_info`,
                                                                          label: (
                                                                              <LocaleMessage msg="page.applications.conversation.condition.template.info" />
                                                                          ),
                                                                          value:
                                                                              info &&
                                                                              info[
                                                                                  l
                                                                              ]
                                                                                  ? info[
                                                                                        l
                                                                                    ]
                                                                                  : '',
                                                                          changeKey:
                                                                              'info',
                                                                          lng: l,
                                                                      }
                                                                  )
                                                                : null}
                                                        </>
                                                    ) : null}
                                                </ParameterArea>
                                            );
                                        })}
                                    </ParameterArea>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <PreviewArea className="col-md-6 col-12">
                        {r_type_settings.template ? (
                            <>
                                <LocaleMessage msg="page.applications.conversation.condition.template.preview" />
                                <Preview
                                    configuration={r_type_settings}
                                    language={currLanguage || 'pt_BR'}
                                />
                            </>
                        ) : null}
                    </PreviewArea>
                </div>
            </div>
        );
    }

    function renderMedia() {
        switch (r_type_settings.type) {
            case 'image':
                return renderCurrImage();
            case 'face':
                return renderCurrFaces();
            case 'buttons':
                return renderCurrOptions();
            case 'template':
                return renderTemplate();
            default:
                return null;
        }
    }

    function renderMediaSettings() {
        return (
            <>
                <div className="col-12 row mt-2 mb-4 justify-content-center">
                    <div className="col-md-6 col-12 row mt-2">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.condition.media_type" />
                            </InputLabel>
                            <Select
                                value={r_type_settings.type || 'no-media'}
                                onChange={event => onMediaTypeChange(event)}
                            >
                                {mediatype_list.map(m => (
                                    <MenuItem key={m.value} value={m.value}>
                                        {m.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <MediaSelector className="col-12 row mt-2 mb-5">
                    {r_type_settings.type ? renderMedia() : null}
                </MediaSelector>
            </>
        );
    }

    // function renderLEDs() {
    //     return (
    //         <>
    //             <div className="col-12 row mt-5 justify-content-center">
    //                 <div className="col-6">
    //                     <h4>
    //                         <LocaleMessage msg="page.applications.conversation.condition.led" />
    //                     </h4>
    //                 </div>
    //             </div>
    //             <div className="col-12 row mt-1 justify-content-center">
    //                 <div className="col-md-6 col-12">
    //                     {led_list.map(s => (
    //                         <div key={s.value}>
    //                             <FormControlLabel
    //                                 className="mb-3"
    //                                 control={
    //                                     <Switch
    //                                         color="primary"
    //                                         checked={
    //                                             led && led[s.value]
    //                                                 ? led[s.value].active
    //                                                 : false
    //                                         }
    //                                         value={s.value}
    //                                         onChange={event =>
    //                                             handleColorSwitchChange(
    //                                                 event,
    //                                                 s.value
    //                                             )
    //                                         }
    //                                     />
    //                                 }
    //                                 label={s.label}
    //                             />
    //                             {led && led[s.value] && led[s.value].active ? (
    //                                 <ColorInput
    //                                     className="mb-5"
    //                                     title={
    //                                         <LocaleMessage msg="page.applications.conversation.condition.color" />
    //                                     }
    //                                     color={
    //                                         led &&
    //                                         led[s.value] &&
    //                                         led[s.value].color
    //                                             ? led[s.value].color
    //                                             : '#fff'
    //                                     }
    //                                     handleColorClick={() =>
    //                                         handleColorClick(led[s.value])
    //                                     }
    //                                 />
    //                             ) : null}
    //                         </div>
    //                     ))}
    //                     {colorPicker.visible ? (
    //                         <div style={styles.popover}>
    //                             <div
    //                                 style={styles.cover}
    //                                 onClick={() => handleColorClose()}
    //                             />
    //                             <SketchPicker
    //                                 color={
    //                                     led &&
    //                                     led[colorPicker.selector] &&
    //                                     led[colorPicker.selector].color
    //                                         ? led[colorPicker.selector].color
    //                                         : '#fff'
    //                                 }
    //                                 onChange={color =>
    //                                     handleColorChange('main', color)
    //                                 }
    //                                 onChangeComplete={color => {
    //                                     handleColorChange('main', color);
    //                                 }}
    //                                 disableAlpha
    //                             />
    //                         </div>
    //                     ) : null}
    //                 </div>
    //             </div>
    //         </>
    //     );
    // }

    return (
        <>
            {renderMediaDialog()}
            <div
                className="col-12 row mt-2 mb-4"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {robotTypes.length > 1 ? (
                    <div className="col-12 row mt-2 mb-4 justify-content-center">
                        <div className="col-md-6 col-12 row mt-2">
                            <FormSwitch
                                value={
                                    media && media.different_robots
                                        ? media.different_robots
                                        : false
                                }
                                onChange={event => {
                                    handleDifferentRobotsSwitch(event);
                                }}
                                label={
                                    <LocaleMessage msg="page.applications.conversation.condition.media.different" />
                                }
                            />
                        </div>
                    </div>
                ) : null}

                {media.different_robots ? (
                    <AppConfigRobotType
                        className="col-12 mb-5"
                        robotTypes={robotTypes}
                        currType={currType}
                        setCurrType={t => setCurrType(t)}
                    >
                        {renderMediaSettings()}
                    </AppConfigRobotType>
                ) : (
                    renderMediaSettings()
                )}
            </div>
        </>
    );
}

MediaConfig.defaultProps = {
    media: {},
    imageGalleriesList: [],
    languages: ['pt_BR'],
    robotGalleries: {},
};

MediaConfig.propTypes = {
    media: PropTypes.object,
    setBody: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    imageGalleriesList: PropTypes.array,
    languages: PropTypes.array,
    robotGalleries: PropTypes.object,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
