/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { TimelineEvent } from 'react-event-timeline';
import {
    MdWifiTethering,
    MdPortableWifiOff,
    MdTextsms,
    MdRecordVoiceOver,
    MdPlace,
    MdNavigation,
    MdPlayArrow,
    MdPause,
    MdStop,
    MdCall,
    MdCallEnd,
    MdDeviceHub,
    MdExitToApp,
    MdFormatListBulleted,
    MdMusicNote,
} from 'react-icons/md';

import { shade } from 'polished';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Content } from './styles';

const styles = {
    icon: {
        color: '#eee',
    },
    bubble: {
        border: '0px',
    },
};

const date_opt = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
};

const icons = {
    connected: MdWifiTethering,
    disconnected: MdPortableWifiOff,
    'disconnected-LWT': MdPortableWifiOff,
    start_conversation: MdTextsms,
    start_localization: MdPlace,
    start_navigation: MdNavigation,
    start_survey_flow: MdDeviceHub,
    start_disinfection: MdPlayArrow,
    pause_disinfection: MdPause,
    end_disinfection: MdStop,
    start_call: MdCall,
    end_call: MdCallEnd,
    start_delivery: MdPlace,
    start_movement: MdNavigation,
    start_presentation_mode: MdRecordVoiceOver,
    start_external_app: MdExitToApp,
    start_script_mode: MdFormatListBulleted,
    start_dances: MdMusicNote,
};

export default function TimelineItem({ item, dateFormat }) {
    const colors = GetPluginspaceTheme(useTheme());
    const { event_type } = item;
    const time = item.timestamp;
    const content = item.content || {};

    const Icon = icons[event_type] || MdPause;

    function renderDeliveryCard() {
        const { mode, map, map_point, destination } = content;
        return (
            <>
                <LocaleMessage msg="rc3.robot.data.card.operation.mode.short" />
                <LocaleMessage msg={`list.operations.mode.${mode}`} />
                {map && map.name ? (
                    <>
                        <br />
                        {`${map.name} - `}
                        <span>
                            {map_point && map_point.name
                                ? map_point.name
                                : destination.name || destination.to}
                        </span>
                    </>
                ) : null}
            </>
        );
    }

    function renderDisinfectionCard() {
        const { mode, map, map_point } = content;
        return (
            <>
                <LocaleMessage msg="rc3.robot.data.card.operation.mode.short" />
                <LocaleMessage msg={`list.operations.mode.${mode}`} />
                {map || map_point ? (
                    <>
                        {map.name ? (
                            <>
                                <br />
                                {map.name}
                            </>
                        ) : null}
                        {map_point.name ? (
                            <span>
                                {map.name ? ' - ' : ''}
                                {map_point.name}
                            </span>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    function renderDanceCard() {
        const name = content ? content.name : '';
        return <>{name || ''}</>;
    }

    function renderSurveyFlowCard() {
        const { survey } = content;
        const name = survey ? survey.name : content.name;

        return (
            <>
                <LocaleMessage msg="rc3.survey.card.flow" />
                {name || ''}
            </>
        );
    }

    function renderExternalAppCard(type) {
        const { config, user } = content;
        const name = config ? config.app_name : config.ap_name;

        return (
            <>
                {name || ''}
                {type === 'end_external_app' && user ? (
                    <p>
                        <LocaleMessage msg="label.ended_by" />
                        {`: ${user.name || ''}`}
                    </p>
                ) : null}
            </>
        );
    }

    function renderScriptModeCard() {
        const { config } = content;
        const name = config ? config.name : content.name;

        return (
            <>
                <LocaleMessage msg="rc3.script.card.name" />
                {name || ''}
            </>
        );
    }

    function renderTelepresenceCallCard(type) {
        const { mode, user, ended_by } = content;
        return (
            <>
                <LocaleMessage msg="rc3.robot.data.card.operation.mode.short" />
                <LocaleMessage msg={`list.operations.mode.call_${mode}`} />
                {user ? (
                    <p>
                        <LocaleMessage msg="list.operations.labels.by" />
                        {user}
                    </p>
                ) : null}
                {type === 'end_call' && ended_by ? (
                    <p>
                        <LocaleMessage msg="label.ended_by" />
                        {`: ${ended_by.name || ''}`}
                    </p>
                ) : null}
            </>
        );
    }

    function renderPresentationModeCard() {
        const { user } = content;
        return (
            <>
                <p>
                    <LocaleMessage msg="list.operations.labels.by" />
                    {user ? user.name : null}
                </p>
            </>
        );
    }

    function renderNavigationCard() {
        const { mode, map, point } = content;

        return (
            <>
                {mode ? (
                    <>
                        <LocaleMessage msg="rc3.robot.data.card.operation.mode.short" />
                        <LocaleMessage
                            msg={`list.operations.mode.navigation_${mode}`}
                        />
                        <br />
                    </>
                ) : null}
                {map || point ? (
                    <>
                        {map && map.name ? <>{map.name.toUpperCase()}</> : null}
                        {point && point.name ? (
                            <span>
                                {map && map.name ? ' - ' : ''}
                                {point.name.toUpperCase()}
                            </span>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    function renderCardContent() {
        switch (event_type) {
            case 'start_call':
            case 'end_call': {
                return renderTelepresenceCallCard(event_type);
            }
            case 'start_disinfection':
            case 'end_disinfection': {
                return renderDisinfectionCard();
            }
            case 'start_delivery':
            case 'end_delivery': {
                return renderDeliveryCard(event_type);
            }
            case 'start_navigation':
            case 'end_navigation': {
                return renderNavigationCard();
            }
            case 'start_presentation_mode':
            case 'end_presentation_mode': {
                return renderPresentationModeCard();
            }
            case 'start_survey_flow':
            case 'end_survey_flow': {
                return renderSurveyFlowCard(event_type);
            }
            case 'start_external_app':
            case 'end_external_app': {
                return renderExternalAppCard(event_type);
            }
            case 'start_script_mode':
            case 'end_script_mode': {
                return renderScriptModeCard(event_type);
            }
            case 'start_dances':
            case 'end_dances': {
                return renderDanceCard(event_type);
            }
            default:
                return null;
        }
    }

    function renderCardTitle() {
        const title_code = event_type
            ? `rc3.robot.events.label.${event_type}`
            : `rc3.robot.events.label.unknown`;

        const datetime = new Date(time);
        const timestamp = datetime.toLocaleDateString(
            dateFormat.format,
            date_opt
        );

        return (
            <div className="item-header">
                <span className="title">
                    <LocaleMessage msg={title_code} />
                </span>
                <br />
                <span className="time">{timestamp}</span>
            </div>
        );
    }

    return (
        <TimelineEvent
            title={false}
            style={{ marginRight: '5px' }}
            icon={<Icon size={16} />}
            iconStyle={styles.icon}
            bubbleStyle={{
                ...styles.bubble,
                backgroundColor: colors.primary || '#333',
            }}
            contentStyle={{
                backgroundColor: shade(0.9, colors.primary || '#333'),
            }}
        >
            <Content>
                {renderCardTitle()}
                <span className="content text">{renderCardContent()}</span>
            </Content>
        </TimelineEvent>
    );
}

TimelineItem.propTypes = {
    item: PropTypes.object,
    dateFormat: PropTypes.object,
};

TimelineItem.defaultProps = {
    item: {},
    dateFormat: {},
};
