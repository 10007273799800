/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAccountBox, MdDone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageBody from '~/components/PageBody';

import api from '~/services/pluginbot-api';

export default function ResetPassword(props) {
    const { match } = props;
    const { token } = match.params;

    const settings = useSelector(state => state.settings);
    const { pluginspace } = settings;

    const [body, setBody] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(!pluginspace);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function checkToken() {
        if (token) {
            setIsLoading(true);
            await api
                .get(`/users/check_token`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        checkToken();
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body };

        setIsLoading(true);

        await api
            .put(`/users/reset_password`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setIsLoading(false);
                setSubmitted(true);
            })
            .catch(error => {
                requestError(error);
            });
    }

    function confirmSubmit() {
        return (
            <div className="row full-body">
                <div className="col-md-6 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.reset_password.form.label.title" />
                        }
                        Icon={MdAccountBox}
                        hide
                    >
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <MdDone size={60} style={{ margin: '20px' }} />
                                <h2 className="mt-4">
                                    <LocaleMessage msg="page.reset_password.form.label.submitted" />
                                </h2>
                            </div>
                            <div
                                className="mt-4"
                                style={{ textAlign: 'center' }}
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    <Link to="/login">
                                        <LocaleMessage msg="page.reset_password.form.label.login" />
                                    </Link>
                                </Button>
                            </div>
                        </>
                    </CardSideBordered>
                </div>
            </div>
        );
    }

    return (
        <PageBody loading={isLoading}>
            <>
                {!submitted ? (
                    <form
                        className="row full-body"
                        noValidate
                        autoComplete="off"
                        onSubmit={event => handleSubmit(event)}
                    >
                        <div className="col-md-8 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.reset_password.form.label.title" />
                                }
                                Icon={MdAccountBox}
                                hide
                            >
                                <>
                                    <div
                                        className="row"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            className="col-md-8 col-12 mb-5"
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Link to="/login">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isLoading}
                                                >
                                                    <LocaleMessage msg="page.reset_password.form.label.login" />
                                                </Button>
                                            </Link>
                                        </div>
                                        <div className="col-md-8 col-12 mb-5">
                                            <TextField
                                                label={
                                                    <LocaleMessage msg="page.reset_password.form.label.new_password" />
                                                }
                                                fullWidth
                                                value={body.password || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        password:
                                                            event.target.value,
                                                    })
                                                }
                                                type="password"
                                            />
                                        </div>
                                        <div className="col-md-8 col-12 mb-5">
                                            <TextField
                                                id="user-email"
                                                label={
                                                    <LocaleMessage msg="page.reset_password.form.label.confirm_password" />
                                                }
                                                fullWidth
                                                value={
                                                    body.confirmPassword || ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        confirmPassword:
                                                            event.target.value,
                                                    })
                                                }
                                                type="password"
                                            />
                                        </div>
                                        <div className="col-md-8 col-12 mb-5">
                                            <Button
                                                className="p-3"
                                                variant="contained"
                                                color="primary"
                                                onClick={event =>
                                                    handleSubmit(event)
                                                }
                                                fullWidth
                                                size="large"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <LocaleMessage msg="page.reset_password.form.label.submitting" />
                                                ) : (
                                                    <LocaleMessage msg="page.reset_password.form.label.title" />
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            </CardSideBordered>
                        </div>
                    </form>
                ) : (
                    confirmSubmit()
                )}
            </>
        </PageBody>
    );
}

ResetPassword.propTypes = {
    match: PropTypes.object.isRequired,
};
