import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px 10px;
    border: 1px solid #ddd;
    background-color: #fff;

    .row {
        margin: 10px 0px;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    color: ${props => props.theme.palette.primary.main};

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;

export const TextParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px;
    width: 100%;
`;

export const EditableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
`;
