/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';

import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    DialogContentText,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea, IconParameter } from '../../styles';

const week_days = [
    {
        value: '0',
        label_key: 'label.day.sunday',
    },
    {
        value: '1',
        label_key: 'label.day.monday',
    },
    {
        value: '2',
        label_key: 'label.day.tuesday',
    },
    {
        value: '3',
        label_key: 'label.day.wednesday',
    },
    {
        value: '4',
        label_key: 'label.day.thursday',
    },
    {
        value: '5',
        label_key: 'label.day.friday',
    },
    {
        value: '6',
        label_key: 'label.day.saturday',
    },
];

export default function ConditionsSetup({ settings, onChange, locale }) {
    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const condition_types = [
        {
            value: 'true',
            label: <LocaleMessage msg="list.comparison.true" />,
        },
        {
            value: 'question',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.question" />
            ),
        },
        {
            value: 'subject',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.subject" />
            ),
        },
        {
            value: 'context',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.context" />
            ),
        },
        {
            value: 'current_time',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.current_time" />
            ),
        },
        {
            value: 'day_month',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.day_month" />
            ),
        },
        {
            value: 'day_week',
            label: (
                <LocaleMessage msg="page.answer_groups.form.conditions.label.type.day_week" />
            ),
        },
    ];

    const comparison_types = {
        exists: {
            value: 'value-exists',
            label: <LocaleMessage msg="list.comparison.key_exists" />,
        },
        equals: {
            value: 'value-equals',
            label: <LocaleMessage msg="list.comparison.value_equals" />,
        },
        different: {
            value: 'value-different',
            label: <LocaleMessage msg="list.comparison.value_different" />,
        },
        less: {
            value: 'value-less',
            label: <LocaleMessage msg="list.comparison.value_less" />,
        },
        less_equal: {
            value: 'value-less-equal',
            label: <LocaleMessage msg="list.comparison.value_less_equal" />,
        },
        greater: {
            value: 'value-greater',
            label: <LocaleMessage msg="list.comparison.value_greater" />,
        },
        greater_equal: {
            value: 'value-greater-equal',
            label: <LocaleMessage msg="list.comparison.value_greater_equal" />,
        },
    };

    const condition_setup = {
        true: {},
        question: {
            comparisons: [comparison_types.equals, comparison_types.different],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.value" />
                ),
                component: 'text',
            },
        },
        subject: {
            comparisons: [comparison_types.equals, comparison_types.different],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.value" />
                ),
                component: 'text',
            },
        },
        context: {
            parameter: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.variable" />
                ),
                key: 'variable',
            },
            comparisons: [comparison_types.equals, comparison_types.different],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.value" />
                ),
                component: 'text',
            },
        },
        current_time: {
            comparisons: [comparison_types.less, comparison_types.greater],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.time" />
                ),
                component: 'time',
            },
        },
        day_month: {
            comparisons: [
                comparison_types.equals,
                comparison_types.different,
                comparison_types.less,
                comparison_types.less_equal,
                comparison_types.greater,
                comparison_types.greater_equal,
            ],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.day" />
                ),
                component: 'number',
            },
        },
        day_week: {
            comparisons: [
                comparison_types.equals,
                comparison_types.different,
                comparison_types.less,
                comparison_types.less_equal,
                comparison_types.greater,
                comparison_types.greater_equal,
            ],
            value: {
                label: (
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.day" />
                ),
                component: 'week',
            },
        },
    };

    function handleNewCondition() {
        const items = settings || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            type: '',
            comparison: '',
            value: '',
        });
        onChange([...items]);
    }

    function handleDeleteCondition(idx) {
        setDialogOpen(false);
        const old_items = settings || [];
        const items = old_items.filter(c => {
            return c.item_id !== idx;
        });
        onChange([...items]);
    }

    function onConditionChange(event, idx, key) {
        const items = settings || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: event.target.value,
            };

            if (key === 'type') {
                items[idx].value = '';
            }
        }
        onChange([...items]);
    }

    function onDatetimeChange(value, idx, key) {
        const items = settings || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: value,
            };
        }
        onChange([...items]);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleDialogOpen(event, index) {
        setCurrItem(index);
        event.preventDefault();
        setDialogOpen(true);
    }

    function renderValueNumber(_settings, item, index) {
        return (
            <TextField
                label={_settings.label}
                fullWidth
                value={item && item.value ? item.value : ''}
                onChange={event => onConditionChange(event, index, 'value')}
                type="number"
                inputProps={{
                    min: _settings.min || 1,
                    max: _settings.max || 31,
                    step: _settings.step || 1,
                }}
            />
        );
    }

    function renderValueText(_settings, item, index) {
        return (
            <TextField
                label={_settings.label}
                fullWidth
                value={item && item.value ? item.value : ''}
                onChange={event => onConditionChange(event, index, 'value')}
            />
        );
    }

    function renderTimePicker(_settings, item, index) {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <TimePicker
                    fullWidth
                    variant="inline"
                    label={_settings.label}
                    format="HH:mm"
                    value={
                        item && item.value ? new Date(item.value) : new Date()
                    }
                    onChange={date => {
                        onDatetimeChange(date, index, 'value');
                    }}
                    disablePast
                    autoOk
                    ampm={false}
                />
            </MuiPickersUtilsProvider>
        );
    }

    function renderWeekSelect(_settings, item, index) {
        return (
            <FormControl fullWidth>
                <InputLabel>Day of the Week</InputLabel>
                <Select
                    value={item && item.value ? item.value : ''}
                    onChange={event => onConditionChange(event, index, 'value')}
                >
                    {week_days.map(d => {
                        return (
                            <MenuItem value={d.value} key={d.value}>
                                <LocaleMessage msg={d.label_key} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    function renderConditionValue(_settings, item, index) {
        const components = {
            text: renderValueText,
            time: renderTimePicker,
            number: renderValueNumber,
            week: renderWeekSelect,
        };

        const type =
            _settings && _settings.component ? _settings.component : null;
        const component = type && components[type] ? components[type] : null;

        return component ? component(_settings, item, index) : null;
    }

    function renderConditionSettings(type, item, index) {
        const c_settings =
            type && condition_setup[type] ? condition_setup[type] : {};
        const c_parameter = c_settings.parameter;
        const c_value = c_settings.value;
        const c_comparisons = c_settings.comparisons || [];
        return (
            <div className="col-8 row" style={{ margin: '0px' }}>
                {c_settings.comparisons ? (
                    <>
                        {c_parameter ? (
                            <div className="col-4">
                                <TextField
                                    label={
                                        <LocaleMessage
                                            msg={c_parameter.label}
                                        />
                                    }
                                    fullWidth
                                    value={
                                        item && item[c_parameter.key]
                                            ? item[c_parameter.key]
                                            : ''
                                    }
                                    onChange={event =>
                                        onConditionChange(
                                            event,
                                            index,
                                            c_parameter.key
                                        )
                                    }
                                />
                            </div>
                        ) : null}
                        <div className={c_parameter ? 'col-4' : 'col-6'}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.answer_groups.form.conditions.label.title" />
                                </InputLabel>
                                <Select
                                    value={
                                        item.comparison ? item.comparison : ''
                                    }
                                    onChange={event =>
                                        onConditionChange(
                                            event,
                                            index,
                                            'comparison'
                                        )
                                    }
                                >
                                    {c_comparisons.map(c => {
                                        return (
                                            <MenuItem
                                                value={c.value}
                                                key={c.value}
                                            >
                                                {c.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {item.comparison !== 'value-exists' ? (
                            <div className={c_parameter ? 'col-4' : 'col-6'}>
                                {renderConditionValue(c_value, item, index)}
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }

    function renderConditionRow(type, item, index) {
        return (
            <div
                className="row col-12 my-0"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <div className="col-1">
                    <LocaleMessage
                        msg={`page.answer_groups.form.conditions.label.${
                            index === 0 ? 'if' : 'and'
                        }`}
                    />
                </div>
                <div className="col-11 row">
                    <div className="col-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.answer_groups.form.conditions.label.type" />
                            </InputLabel>
                            <Select
                                value={item.type ? item.type : ''}
                                onChange={event =>
                                    onConditionChange(event, index, 'type')
                                }
                            >
                                {condition_types.map(c => {
                                    return (
                                        <MenuItem value={c.value} key={c.value}>
                                            {c.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {renderConditionSettings(type, item, index)}
                    <IconParameter className="col-1">
                        <MdDelete
                            size={18}
                            onClick={event =>
                                handleDialogOpen(event, item.item_id)
                            }
                        />
                    </IconParameter>
                </div>
            </div>
        );
    }

    function renderConditionList(conditions) {
        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                {conditions.map((item, idx) => {
                    const { type } = item;
                    return (
                        <ParameterArea>
                            {renderConditionRow(type, item, idx)}
                        </ParameterArea>
                    );
                })}
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => handleDeleteCondition(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {dialogOpen ? buildDeleteDialog() : null}
            <>
                <div
                    style={{
                        padding: '20px 15px',
                    }}
                >
                    <LocaleMessage msg="page.answer_groups.form.conditions.label.info" />
                </div>
                <div style={{ padding: '0px 15px' }}>
                    <ParameterArea className="row mt-1 mb-5">
                        {renderConditionList(settings || [])}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewCondition()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="page.answer_groups.form.conditions.label.add" />
                        </Button>
                    </ParameterArea>
                </div>
            </>
        </>
    );
}

ConditionsSetup.defaultProps = {};

ConditionsSetup.propTypes = {
    settings: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    locale: PropTypes.object.isRequired,
};
