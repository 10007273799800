/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
    MdClose,
    MdExpandMore,
    MdExpandLess,
    MdAddCircle,
    MdAutoAwesome,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea, IconParameter } from './styles';

export default function MultipleInputs({
    language,
    onChange,
    texts,
    open,
    margin,
    allowSuggestions,
    suggestItems,
}) {
    const [show, setShow] = useState(open);

    function handleNewItem() {
        const items = texts || [];
        items.push('');
        onChange([...items]);
    }

    function handleDeleteItem(idx) {
        const items = texts || [];
        items.splice(idx, 1);
        onChange([...items]);
    }

    function onItemChange(event, idx) {
        const items = texts || [];
        items[idx] = event.target.value;
        onChange([...items]);
    }

    function renderInputLine(lang, item, index) {
        return (
            <TextField
                key={`input_${lang}_${index}`}
                className="col-12 mb-4"
                fullWidth
                multiline
                value={item || ''}
                onChange={event => onItemChange(event, index)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                style={{
                                    marginLeft: '10px',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                }}
                                onClick={() => handleDeleteItem(index)}
                            >
                                <MdClose size={22} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    return (
        <ParameterArea className={`col-12 justify-content-center ${margin}`}>
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter
                    className="col-2"
                    style={{ justifyContent: 'left' }}
                >
                    {show ? (
                        <MdExpandLess
                            size={22}
                            onClick={() => setShow(false)}
                        />
                    ) : (
                        <MdExpandMore size={22} onClick={() => setShow(true)} />
                    )}
                </IconParameter>
                <span>
                    <LocaleMessage msg={`list.languages.${language}`} />
                </span>
                <div
                    className="col-2 row pr-0"
                    style={{ justifyContent: 'right' }}
                >
                    {show ? (
                        <>
                            {allowSuggestions ? (
                                <Tooltip
                                    title={
                                        <LocaleMessage msg="page.qna.content.training.form.examples.suggest" />
                                    }
                                    placement="top"
                                >
                                    <IconParameter className="col-md-4 col-12">
                                        <MdAutoAwesome
                                            size={22}
                                            onClick={event =>
                                                suggestItems(event)
                                            }
                                        />
                                    </IconParameter>
                                </Tooltip>
                            ) : null}
                            <Tooltip
                                title={
                                    <LocaleMessage msg="label.input.type.text.add" />
                                }
                                placement="top"
                            >
                                <IconParameter className="col-md-4 col-12">
                                    <MdAddCircle
                                        size={22}
                                        onClick={event => handleNewItem(event)}
                                    />
                                </IconParameter>
                            </Tooltip>
                        </>
                    ) : null}
                </div>
            </div>

            {show ? (
                <div
                    className="col-12 px-3 pt-3 mb-3"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {texts.map((t, i) => {
                        return renderInputLine(language, t, i);
                    })}
                </div>
            ) : null}
        </ParameterArea>
    );
}

MultipleInputs.defaultProps = {
    language: '',
    texts: [],
    margin: 'mb-5',
    open: false,
    allowSuggestions: false,
    suggestItems: () => {},
};

MultipleInputs.propTypes = {
    language: PropTypes.string,
    texts: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    margin: PropTypes.string,
    open: PropTypes.bool,
    allowSuggestions: PropTypes.bool,
    suggestItems: PropTypes.func,
};
