/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';

import { ParameterArea, DeleteParameter } from './styles';

const conditionTypes = [
    {
        value: 'subject',
        label: (
            <LocaleMessage msg="page.applications.conversation.identifiers.label.subject" />
        ),
    },
    {
        value: 'context',
        label: (
            <LocaleMessage msg="page.applications.conversation.identifiers.label.context" />
        ),
    },
];

export default function ConversationIdentifiers({
    size,
    dialog_id,
    identifiers,
    setIdentifiers,
}) {
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];
    const any_subject_tag =
        lng_all['page.applications.conversation.identifiers.label.subject.any'];

    const [isLoading, setIsLoading] = useState(true);
    const [subjectList, setSubjectList] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadConversationContent() {
        await api
            .get(`dialogs/${dialog_id}/content?subjects=true`)
            .then(response => {
                const { data } = response;
                const { subjects } = data;

                const values = subjects.map(s => {
                    return s.value;
                });

                setSubjectList(values);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadConversationContent();
    }, []);

    function handleNewIdentifier() {
        let items =
            identifiers && Array.isArray(identifiers) ? identifiers : [];
        if (!items) {
            items = [];
        }
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            type: conditionTypes[0].value,
            value: '',
        });
        setIdentifiers(identifiers);
    }

    function handleDeleteIdentifier(idx) {
        let items = identifiers;
        if (!items) {
            items = [];
        }
        if (!items[idx]) {
            return;
        }
        items.splice(idx, 1);
        setIdentifiers(identifiers);
    }

    function onIdentifierChange(value, index, key) {
        const items = identifiers;
        items[index] = {
            ...items[index],
            [key]: value,
        };
        if (key === 'type') {
            items[index].value = '';
        }
        setIdentifiers(identifiers);
    }

    function renderIdentifier(item, index) {
        const type = item.type || 'subject';
        const subject_obj = subjectList.map(s => {
            return {
                value: s,
                label: s,
            };
        });
        subject_obj.unshift({
            value: '**',
            label: any_subject_tag,
        });

        return (
            <div className="col-12 row mb-3" key={item.item_id}>
                <div className="col-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.identifiers.label.identifier_type" />
                        </InputLabel>
                        <Select
                            value={item && item.type ? item.type : 'subject'}
                            onChange={event =>
                                onIdentifierChange(
                                    event.target.value,
                                    index,
                                    'type'
                                )
                            }
                        >
                            {conditionTypes.map(c => (
                                <MenuItem key={c.value} value={c.value}>
                                    {c.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {type === 'subject' ? (
                    <>
                        <div className="col-8">
                            <FormSelect
                                options={subject_obj || []}
                                objOption
                                labelKey="label"
                                optionKey="value"
                                label={
                                    <LocaleMessage msg="page.applications.conversation.identifiers.label.subject" />
                                }
                                onChange={value => {
                                    onIdentifierChange(value, index, 'value');
                                }}
                                value={item && item.value ? item.value : ''}
                            />
                        </div>
                    </>
                ) : null}
                {type === 'context' ? (
                    <>
                        <div className="col-4">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.applications.conversation.identifiers.label.var_name" />
                                }
                                fullWidth
                                value={
                                    item && item.var_name ? item.var_name : ''
                                }
                                onChange={event =>
                                    onIdentifierChange(
                                        event.target.value,
                                        index,
                                        'var_name'
                                    )
                                }
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.applications.conversation.identifiers.label.var_value" />
                                }
                                fullWidth
                                value={item && item.value ? item.value : ''}
                                onChange={event =>
                                    onIdentifierChange(
                                        event.target.value,
                                        index,
                                        'value'
                                    )
                                }
                            />
                        </div>
                    </>
                ) : null}
                <DeleteParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => handleDeleteIdentifier(index)}
                    />
                </DeleteParameter>
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <ParameterArea className={`${size || 'col-12'} row mt-1 mb-5`}>
            {identifiers
                ? identifiers.map((item, index) => {
                      return renderIdentifier(item, index);
                  })
                : null}
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => handleNewIdentifier()}
                startIcon={
                    <MdAdd
                        style={{
                            color: '#fff',
                        }}
                    />
                }
            >
                <LocaleMessage msg="page.applications.conversation.identifiers.label.add_identifier" />
            </Button>
        </ParameterArea>
    );
}

ConversationIdentifiers.defaultProps = {
    identifiers: [],
    size: 'col-md-10 col-12',
};

ConversationIdentifiers.propTypes = {
    size: PropTypes.string,
    dialog_id: PropTypes.string.isRequired,
    identifiers: PropTypes.array,
    setIdentifiers: PropTypes.func.isRequired,
};
