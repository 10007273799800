import React from 'react';
import { MdAdd, MdWarning } from 'react-icons/md';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { Container } from './styles';

const messages = {
    permission: 'message.new_button.permission',
    group: 'message.new_button.select_group',
    admin: 'message.new_button.admin',
};

export default function NewItemButton(props) {
    const { link, text, disabled, disabledMessage } = props;

    const placeholder = messages[disabledMessage || 'group'];

    return (
        <Container>
            <Link to={link} className={`${disabled ? 'disabled' : ''}`}>
                <Button
                    fullWidth
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    startIcon={disabled ? <MdWarning /> : <MdAdd />}
                    style={{
                        whiteSpace: 'nowrap',
                        padding: '5px 20px',
                    }}
                >
                    {disabled ? <LocaleMessage msg={placeholder} /> : text}
                </Button>
            </Link>
        </Container>
    );
}

NewItemButton.defaultProps = {
    disabled: false,
    disabledMessage: 'group',
};

NewItemButton.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
};
