/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Item } from './styles';

export default function MapItem({ object, active, onListItemClick }) {
    return (
        <Item
            className={`${active ? 'active' : ''}`}
            onClick={event => onListItemClick(event, object)}
        >
            <div className="row">
                <div className="map-info col-8">
                    <div className="group">
                        <small>
                            {object.location ? object.location.name : '---'}
                        </small>
                        <br />
                        <small />
                    </div>
                    <span className="name">{object.name.toUpperCase()}</span>
                </div>
            </div>
        </Item>
    );
}

MapItem.propTypes = {
    object: PropTypes.object,
    active: PropTypes.bool,
    onListItemClick: PropTypes.func,
};

MapItem.defaultProps = {
    object: {},
    active: false,
    onListItemClick: () => {},
};
