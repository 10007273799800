/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

export default function ContactDestinations({
    group,
    handleError,
    settings,
    setSettings,
    appSettings,
}) {
    const hive_settings = appSettings.hive_apps || {};
    const hive_telepresence = hive_settings.telepresence || {};

    const [isLoading, setIsLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [destinations, setDestinations] = useState([]);

    async function loadApplications() {
        setIsLoading(true);
        await api
            .get(`applications?type=telepresence`)
            .then(response => {
                const filtered = response.data.filter(a => {
                    return a.group && a.group.id === group;
                });
                const data = filtered.map(a => ({
                    id: a.id,
                    name: a.name,
                }));
                setApplications(data);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function loadAppContacts(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`contacts/application/${_id}`)
                .then(response => {
                    const data = response.data.map(c => ({
                        id: c.id,
                        name: c.name,
                    }));
                    setDestinations(data);
                })
                .catch(error => handleError(error));
        } else {
            setDestinations([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadApplications();
    }, []);

    useEffect(() => {
        loadAppContacts(hive_telepresence.app_id);
    }, [hive_telepresence.app_id]);

    function saveBody(key, value) {
        setSettings({
            ...settings,
            [key]: value,
        });
    }

    return isLoading ? (
        <ReactLoading type="bars" color="#c8c8c8" height={50} width={50} />
    ) : (
        <div
            className="row col-12"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className="col-md-5 col-12">
                <FormControl className="mb-5" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.conversation.triggers.identifiers.application" />
                    </InputLabel>
                    <Select
                        value={
                            hive_telepresence.app_id
                                ? hive_telepresence.app_id
                                : ''
                        }
                        disabled
                    >
                        {applications.map(a => {
                            return (
                                <MenuItem value={a.id} key={a.id}>
                                    {a.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            {hive_telepresence.active && hive_telepresence.app_id ? (
                <div className="col-md-5 col-12">
                    <FormControl className="mb-5" fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.triggers.identifiers.contact" />
                        </InputLabel>
                        <Select
                            value={
                                settings.contact_id ? settings.contact_id : ''
                            }
                            onChange={event =>
                                saveBody('contact_id', event.target.value)
                            }
                        >
                            <MenuItem value="*">
                                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.contact.all" />
                            </MenuItem>
                            {destinations.map(c => {
                                return (
                                    <MenuItem value={c.id} key={c.id}>
                                        {c.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            ) : null}
        </div>
    );
}

ContactDestinations.defaultProps = {};

ContactDestinations.propTypes = {
    group: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    // robotType: PropTypes.object.isRequired,
    // appSettings: PropTypes.object.isRequired,
};
