/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

export default function AlertExtras({ infos }) {
    return infos && infos.length > 0 ? (
        <div className="group">
            {infos
                ? infos.map(i => {
                      const { key, value } = i;
                      return (
                          <div key={key}>
                              <small>{value}</small>
                          </div>
                      );
                  })
                : null}
        </div>
    ) : null;
}

AlertExtras.propTypes = {
    infos: PropTypes.array,
};

AlertExtras.defaultProps = {
    infos: [],
};
