/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import { MenuList, MenuItem } from '../styles';

export default function SidebarContent({
    permission,
    pluginspace,
    menu,
    onMenuItemClick,
    open,
    type,
}) {
    const {
        master_menu,
        super_menu,
        // trainer_menu,
        admin_menu,
        group_menu,
        group_submenu,
        user_menu,
    } = menu;

    const pluginspaceSettings = useSelector(state => state.settings);
    const p_admin = ['master', 'super_admin', 'admin'].includes(permission);

    const { active } = pluginspaceSettings;

    function buildMenu(items) {
        return items.map(item => (
            <MenuItem
                className="no-arrow menu"
                type={type}
                key={item.route}
                onClick={event => {
                    onMenuItemClick(event);
                }}
            >
                <NavLink to={item.route}>
                    {item.icon ? (
                        <i className={`zmdi zmdi-hc-fw ${item.icon}`} />
                    ) : null}
                    <span className="nav-text">
                        <LocaleMessage msg={item.key} />
                    </span>
                </NavLink>
            </MenuItem>
        ));
    }

    function masterMenu() {
        return (
            <>
                <li className="nav-header">
                    <LocaleMessage msg="sidebar.title.master" />
                </li>
                {buildMenu(master_menu)}
            </>
        );
    }

    function superMenu() {
        return (
            <>
                <li className="nav-header">
                    <LocaleMessage msg="sidebar.title.super_admin" />
                </li>
                {buildMenu(super_menu)}
            </>
        );
    }

    // function trainerMenu() {
    //     return (
    //         <>
    //             <li className="nav-header">
    //                 <LocaleMessage msg="sidebar.title.trainer" />
    //             </li>
    //             {buildMenu(trainer_menu)}
    //         </>
    //     );
    // }

    function adminMenu() {
        return (
            <>
                <li className="nav-header">
                    <LocaleMessage msg="sidebar.title.admin" />
                </li>
                {buildMenu(admin_menu)}
            </>
        );
    }

    function groupMenu() {
        const currGroup =
            active && active.id && active.id !== '*' ? active : null;

        const gPermission =
            currGroup && currGroup.permission ? currGroup.permission : null;

        const sub_items_admin = group_submenu.admin.map(i => {
            const link = i.route;
            return {
                ...i,
                route: link.replace('{id}', currGroup ? currGroup.id : ''),
            };
        });

        const sub_items_viewer = group_submenu.viewer;

        return (
            <>
                <li className="nav-header">
                    <LocaleMessage msg="sidebar.title.group" />
                </li>
                {buildMenu(group_menu)}

                {currGroup ? (
                    <>
                        <li className="nav-header group-name">
                            {currGroup.name.toUpperCase()}
                        </li>
                        {open ? (
                            <li className="menu">
                                <ul className="nav-menu">
                                    {p_admin ||
                                    (gPermission && gPermission === 'admin')
                                        ? buildMenu(sub_items_admin)
                                        : buildMenu(sub_items_viewer)}
                                </ul>
                            </li>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    function userMenu() {
        return (
            <>
                <li className="nav-header">
                    <LocaleMessage msg="sidebar.title.menu" />
                </li>
                {buildMenu(user_menu)}
            </>
        );
    }

    return (
        <CustomScrollbars className="scrollbar">
            <MenuList className="nav-menu">
                {['master'].includes(permission) && pluginspace === 'admin'
                    ? masterMenu()
                    : null}

                {['master', 'super_admin'].includes(permission) &&
                pluginspace === 'admin'
                    ? superMenu()
                    : null}

                {/* {['master', 'super_admin'].includes(permission) &&
                pluginspace === 'admin'
                    ? trainerMenu()
                    : null} */}

                {p_admin ? adminMenu() : null}

                {groupMenu()}

                {userMenu()}
            </MenuList>
        </CustomScrollbars>
    );
}

SidebarContent.propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool,
    menu: PropTypes.object.isRequired,
    permission: PropTypes.string.isRequired,
    pluginspace: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
};

SidebarContent.defaultProps = {
    open: false,
    type: 'permanent',
};
