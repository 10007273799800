/* eslint-disable consistent-return */
import produce from 'immer';

import menuApps from '~/config/Apps';

const INITIAL_STATE = {
    app_type: 'platform',
    app_home: '/',
    settings: {},
};

export default function settings(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@auth/SIGN_IN_SUCCESS': {
                const { app } = action.payload;

                if (app === 'platform') {
                    draft.app_type = INITIAL_STATE.app_type;
                    draft.app_home = INITIAL_STATE.app_home;
                    draft.settings = INITIAL_STATE.settings;
                    break;
                }

                const redirect = menuApps.apps.find(a => {
                    return a.app === app;
                });

                if (redirect) {
                    draft.app_type = redirect.app;
                    draft.app_home = redirect.home;
                    draft.settings = redirect.settings;
                }

                break;
            }
            case '@application/CHANGE_APP_SUCCESS': {
                const { app } = action.payload;
                draft.app_type = app.app;
                draft.app_home = app.home;
                draft.settings = app.settings;
                break;
            }
            case '@application/CHANGE_APP_SETTINGS': {
                const { settings: app_settings } = action.payload;
                draft.settings = app_settings;
                break;
            }
            default:
                return state;
        }
    });
}
