import styled from 'styled-components';

export const Container = styled.div`
    .card {
        height: 100%;
        color: #999;
        background: ${props => {
            const color = props.theme.palette.primary.main;
            return color;
        }};

        a {
            height: 100%;

            .card-body {
                border: 0.5vh solid
                    ${props => {
                        const color = props.theme.palette.primary.main;
                        return color;
                    }};
                height: 100%;
                display: flex;
                flex-direction: column;
                background: #fff;
                color: ${props => {
                    const color = props.theme.palette.primary.main;
                    return color;
                }};
            }
        }

        .group-warning {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            color: #fff;
            background: ${props => {
                const color = props.theme.palette.primary.main;
                return color;
            }};
        }

        .disabled {
            pointer-events: none;
            .card,
            .card-body {
                border: none;
                color: #999;
                background: #ddd;
            }
        }
    }
`;
