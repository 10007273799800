/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import numeral from 'numeral';
import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';

import {
    ChartsContainer,
    TotalContainer,
    InfoContainer,
    TableArea,
} from '../style';

export default function OperationReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    CustomTimeTooltip,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawOperationData, setRawOperationData] = useState({});
    const [operationList, setOperationList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const [, setCurrOperationType] = useState(null);
    const [, setFilteredOperationList] = useState([]);

    const headers_file = [
        { key: 'group', label: 'Group' },
        { key: 'robot', label: 'Robot' },
        { key: 'robottype', label: 'Robot Type' },
        { key: 'application', label: 'Application' },
        { key: 'apptype', label: 'App Type' },
        { key: 'type', label: 'Operation' },
        {
            key: 'start_date',
            label: 'Start Date',
        },
        {
            key: 'start_time',
            label: 'Start Time',
        },
        {
            key: 'end_date',
            label: 'End Date',
        },
        {
            key: 'end_time',
            label: 'End Time',
        },
    ];

    const headers_actions = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.occupation_type" />,
        },
        {
            id: 'time_label',
            label: <LocaleMessage msg="table.headers.occupation_time" />,
        },
    ];

    const headers_active = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'active_label',
            label: <LocaleMessage msg="table.headers.active_time" />,
        },
        {
            id: 'occupied_label',
            label: <LocaleMessage msg="table.headers.occupation_time" />,
        },
        {
            id: 'occupation_rate',
            label: <LocaleMessage msg="page.reports.operations.label.rate" />,
        },
    ];

    function filterRawOperationData(data) {
        const {
            app_types,
            robot_types,
            robots,
            groups,
            applications,
        } = counterData;
        const { period, operations } = data;

        const o_types = [];
        const o_active = [];
        const o_occupied = [];
        const o_robots = [];
        const o_a_types = [];
        const o_r_types = [];
        const o_groups = [];
        const o_applications = [];
        const o_st_date = [];
        const o_st_time = [];

        const olist = operations.list.map((o, idx) => {
            const op_time = o.time;

            const start = GetDateTimeLabel(new Date(o.start), { format });
            const end = GetDateTimeLabel(new Date(o.end), { format });

            const group = groups[o.group_id];
            const robot = robots[o.robot_id];
            const r_type = robot_types[o.robottype_id];
            const application = applications[o.application_id];

            const a_type = app_types[o.apptype_id];

            if (o.type !== 'active') {
                o_types[o.type] = o_types[o.type]
                    ? o_types[o.type] + op_time
                    : op_time;

                o_groups[o.group_id] = o_groups[o.group_id]
                    ? o_groups[o.group_id] + op_time
                    : op_time;

                o_robots[o.robot_id] = o_robots[o.robot_id]
                    ? o_robots[o.robot_id] + op_time
                    : op_time;

                o_r_types[o.robottype_id] = o_r_types[o.robottype_id]
                    ? o_r_types[o.robottype_id] + op_time
                    : op_time;

                o_applications[o.application_id] = o_applications[
                    o.application_id
                ]
                    ? o_applications[o.application_id] + op_time
                    : op_time;

                o_a_types[o.apptype_id] = o_a_types[o.apptype_id]
                    ? o_a_types[o.apptype_id] + op_time
                    : op_time;

                o_st_date[start.date] = o_st_date[start.date]
                    ? o_st_date[start.date] + op_time
                    : op_time;

                o_st_time[start.hour] = o_st_time[start.hour]
                    ? o_st_time[start.hour] + op_time
                    : op_time;

                o_occupied[o.robot_id] = o_occupied[o.robot_id]
                    ? o_occupied[o.robot_id] + op_time
                    : op_time;
            } else {
                o_active[o.robot_id] = o_active[o.robot_id]
                    ? o_active[o.robot_id] + op_time
                    : op_time;
            }

            if (!robots[o.robot_id]) {
                robots[o.robot_id] = { code: o.robot_code || '---', name: '' };
            }

            return {
                ...o,
                id: idx,
                group: group ? group.name : '---',
                application: application ? application.name : '---',
                robot: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${o.robot_code || '---'}]`,
                robottype: r_type ? r_type.name : '---',
                apptype: a_type ? a_type.slug : '---',
                start_date: start.date,
                start_time: start.time,
                started: start.string,
                end_date: end.date,
                end_time: end.time,
                ended: end.string,
            };
        });

        const group_grouped = Object.keys(o_groups).map(g => {
            const val = o_groups[g];
            const obj = groups[g];
            const parsed_time = GetOperationTime(val);

            return {
                name: obj ? obj.name : '---',
                value: Math.round(val / 60),
                time_label: parsed_time.label,
            };
        });

        const robot_grouped = Object.keys(o_robots).map(r => {
            const obj = robots[r];
            const r_operation = o_robots[r];
            const r_active = o_active[r];
            const operation_time = GetOperationTime(r_operation);
            const active_time = GetOperationTime(r_active);

            return {
                id: r,
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                value: Math.round(r_operation / 60),
                time_label: operation_time.label,
                active: {
                    value: Math.round(r_active / 60),
                    active: r_active,
                    time_label: active_time.label,
                },
            };
        });

        const active_grouped = Object.keys(o_active).map(r => {
            const obj = robots[r];
            const r_active = o_active[r] || 0;
            const r_occupied = o_occupied[r] || 0;
            const active_time = GetOperationTime(r_active);
            const occupied_time = GetOperationTime(r_occupied);

            return {
                id: r,
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                value: Math.round(r_active / 60),
                active: r_active,
                occupied: r_occupied,
                active_label: active_time.label,
                occupied_label: occupied_time.label,
                occupation_rate: numeral(
                    r_active ? r_occupied / r_active : 0
                ).format('0.00%'),
                rate_ordering: numeral(
                    r_active ? r_occupied / r_active : 0
                ).format('00.0000'),
            };
        });

        const rtype_grouped = Object.keys(o_r_types).map(t => {
            const val = o_r_types[t];
            const obj = robot_types[t];
            const parsed_time = GetOperationTime(val);

            return {
                name: obj ? obj.name : '---',
                value: Math.round(val / 60),
                time_label: parsed_time.label,
            };
        });

        const application_grouped = Object.keys(o_applications).map(a => {
            const val = o_applications[a];
            const obj = applications[a];
            const parsed_time = GetOperationTime(val);

            return {
                name: obj ? obj.name : '---',
                value: Math.round(val / 60),
                time_label: parsed_time.label,
            };
        });

        const atype_grouped = Object.keys(o_a_types).map(t => {
            const val = o_a_types[t];
            const obj = app_types[t];
            const parsed_time = GetOperationTime(val);

            return {
                name: obj ? obj.name : '---',
                value: Math.round(val / 60),
                time_label: parsed_time.label,
            };
        });

        const optype_grouped = Object.keys(o_types).map(t => {
            const val = o_types[t];
            const name_key = `list.operations.type.${t}`;
            const parsed_time = GetOperationTime(val);
            return {
                id: t,
                name: <LocaleMessage msg={name_key} />,
                value: Math.round(val / 60),
                time_label: parsed_time.label,
            };
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            d_labels.push(label_date);
            const parsed_time = GetOperationTime(o_st_date[formatted_date]);
            date_grouped.push({
                name: label_date,
                date: label_date,
                value: Math.round(o_st_date[formatted_date] / 60) || 0,
                time_label: parsed_time.label,
            });
        });

        const t_labels = [];
        const time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = t < 10 ? `0${t}h` : `${t}h`;
            const parsed_time = GetOperationTime(o_st_time[t]);
            t_labels.push(label_time);
            time_grouped.push({
                name: label_time,
                hour: label_time,
                value: Math.round(o_st_time[t] / 60) || 0,
                time_label: parsed_time.label,
            });
        }

        setGroupedData({
            robots: robot_grouped,
            robot_types: rtype_grouped,
            applications: application_grouped,
            app_types: atype_grouped,
            groups: group_grouped,
            date: date_grouped,
            time: time_grouped,
            types: optype_grouped,
            active_time: active_grouped,
            d_labels,
            t_labels,
        });

        setOperationList(olist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        await api
            .get(
                `reports/operations?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                filterRawOperationData(data);
                setRawOperationData(data.operations);
                setCurrOperationType(null);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadData();
    }, [settings, fromISO, untilISO, locale]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function handleTypeSelection(type) {
        const type_list = groupedData.types || [];

        const newCurrType = type_list.find(a => {
            return a.name === type;
        });
        setCurrOperationType(newCurrType);

        const currActionLog = operationList.filter(o => {
            return o.type === type;
        });

        setFilteredOperationList(currActionLog);
    }

    function CSVButton() {
        return (
            <ExportCSVButton
                data={operationList}
                headers={headers_file}
                filename={GetFileName(`REPORTS-operation_logs`, 'csv')}
            />
        );
    }

    // function buildOccupationTable(title, headers, data, order_by = 'value') {
    //     return (
    //         <>
    //             <div>
    //                 <p className="card-title">{title}</p>
    //             </div>
    //             <DataTable
    //                 headerColumns={headers}
    //                 data={data || []}
    //                 orderDirection="desc"
    //                 orderColumn={order_by}
    //                 handleTableRowClick={() => {}}
    //                 hasActions={false}
    //                 sortable
    //                 defaultRowsPerPage={10}
    //             />
    //         </>
    //     );
    // }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
                customization={{ tooltip: CustomTimeTooltip }}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'app_types',
            title: 'per_app_type',
            color: colors.dashboard_graphs,
            data: groupedData.app_types,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: groupedData.applications,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            color: colors.dashboard_graphs,
            data: groupedData.robot_types,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
    ];

    const total_time =
        rawOperationData && rawOperationData.total
            ? rawOperationData.total
            : {};

    const total_active = GetOperationTime(
        total_time.active ? total_time.active : 0
    );

    const total_occupied = GetOperationTime(
        total_time.occupied ? total_time.occupied : 0
    );

    const time_rate = numeral(
        total_time.occupied && total_time.active
            ? total_time.occupied / total_time.active
            : 0
    ).format('0.00%');

    const total_times = {
        active: `${total_active.hours || '0'}:${total_active.minutes ||
            '00'}:${total_active.seconds || '00'}`,
        occupied: `${total_occupied.hours || '0'}:${total_occupied.minutes ||
            '00'}:${total_occupied.seconds || '00'}`,
    };

    return (
        <>
            <>
                <ReportHeader
                    date_props={{
                        locale,
                    }}
                    period={{
                        minDate,
                        fromDate: new Date(fromISO),
                        untilDate: new Date(untilISO),
                    }}
                    handleFromDateChange={handleFromDateChange}
                    handleUntilDateChange={handleUntilDateChange}
                    reloadData={() => loadData()}
                    exportButton={CSVButton()}
                    shortcuts={shortcuts}
                    handleShortcutClick={_id => handlePeriodShortcut(_id)}
                />
            </>
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5 row">
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.operations.label.active" />
                                }
                                value={total_times.active}
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.operations.label.occupied" />
                                }
                                value={total_times.occupied}
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.operations.label.rate" />
                                }
                                value={time_rate}
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <TableArea className="col-md-8 col-12 mb-3 table-responsive-material">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.operations.label.active" />
                                    </p>
                                </div>
                                <DataTable
                                    headerColumns={headers_active}
                                    data={groupedData.active_time || []}
                                    orderDirection="desc"
                                    orderColumn="value"
                                    hasActions={false}
                                    sortable
                                    defaultRowsPerPage={10}
                                />
                            </TableArea>
                        </InfoContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.operations.label.per_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.operations.label.per_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.operations.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            tooltip: CustomTimeTooltip,
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <TableArea className="col-md-6 col-12 mb-3 table-responsive-material">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.operations.label.occupation_time" />
                                    </p>
                                </div>
                                <DataTable
                                    headerColumns={headers_actions}
                                    data={groupedData.types || []}
                                    orderDirection="desc"
                                    orderColumn="value"
                                    handleTableRowClick={(event, id) => {
                                        handleTypeSelection(id);
                                    }}
                                    hasActions={false}
                                    sortable
                                    defaultRowsPerPage={10}
                                />
                            </TableArea>
                        </InfoContainer>
                    </>
                )}
            </div>
        </>
    );
}

OperationReports.defaultProps = {
    options: {},
};

OperationReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    options: PropTypes.object,
};
