/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

const center = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

const available_voices = {
    male: {
        value: 'male',
        label: 'list.genders.male',
    },
    female: {
        value: 'female',
        label: 'list.genders.female',
    },
};

const type_voices = {
    cruzr: [available_voices.male, available_voices.female],
    temi: [available_voices.male, available_voices.female],
    nao: [available_voices.male],
};

const warnings = {
    cruzr: [],
    nao: ['page.applications.conversation.warning.languages.single'],
    temi: ['page.applications.conversation.warning.languages.single'],
};

export default function LanguageConfig({
    appSettings,
    handleError,
    robotTypes,
    updateObject,
    currType,
    setCurrType,
}) {
    const { settings } = appSettings;

    const langSettings = settings && settings.language ? settings.language : {};

    const [language, setLanguage] = useState(langSettings);
    const [voices, setVoices] = useState([]);
    const [, setNeedSaving] = useState(false);

    async function handleSubmit() {
        const data = { language };
        updateObject(data);
        setNeedSaving(false);
    }

    function handlecurrTypeEdit(type, key, value) {
        const type_settings = language[type] || {};
        type_settings[key] = value;
        setLanguage({ ...language, [type]: type_settings });
        setNeedSaving(true);
    }

    async function loadVoices() {
        await api
            .get(`applications/voices`)
            .then(response => {
                const v = response.data;
                const r_voices = [];
                Object.keys(v).forEach(t => {
                    const t_voices = v[t];
                    t_voices.forEach(voice => {
                        r_voices.push({
                            type: t,
                            key: `${t}_${voice.value}`,
                            ...voice,
                        });
                    });
                });
                setVoices(r_voices);
            })
            .catch(e => {
                handleError(e);
            });
    }

    useEffect(() => {
        loadVoices();
    }, []);

    function renderLanguages() {
        const options = voices.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={`list.languages.${l.value}`} />,
            };
        });

        return (
            <div>
                <LocaleMessage msg="page.applications.conversation.language.list" />
                <br />
                <FormCheckList
                    settings={language}
                    options={options}
                    allowStar
                    multiple
                    starKey="main"
                    onChange={l => {
                        setLanguage({
                            ...language,
                            ...l,
                        });
                        setNeedSaving(true);
                    }}
                />
            </div>
        );
    }

    function renderLanguageSwitcher() {
        const r_type = currType ? currType.slug : '';
        const r_warnings = warnings[r_type] || [];
        const r_voices = type_voices[r_type] || [];

        return (
            <div className="col-12" style={center}>
                <div className="col-md-5 col-12 mb-3">{renderLanguages()}</div>
                <AppConfigRobotType
                    className="col-12 mb-5"
                    robotTypes={robotTypes}
                    currType={currType}
                    setCurrType={t => setCurrType(t)}
                >
                    <div style={center}>
                        {r_warnings.map(w => {
                            return (
                                <p key={w}>
                                    <LocaleMessage msg={w} />
                                </p>
                            );
                        })}
                        <div className="col-md-5 col-12 mt-1 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.conversation.language.gender" />
                                </InputLabel>
                                <Select
                                    value={
                                        language &&
                                        language[r_type] &&
                                        language[r_type].gender
                                            ? language[r_type].gender
                                            : ''
                                    }
                                    onChange={event =>
                                        handlecurrTypeEdit(
                                            r_type,
                                            'gender',
                                            event.target.value
                                        )
                                    }
                                >
                                    {r_voices.map(g => (
                                        <MenuItem
                                            value={g.value}
                                            key={`lang_${g.value}`}
                                        >
                                            <LocaleMessage msg={g.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </AppConfigRobotType>
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.language.title" />
                </h2>
            </div>
            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 20px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.warning.languages" />
            </div>
            <div className="sidecard-body">
                <div className="row" style={center}>
                    {renderLanguageSwitcher()}
                    <div className="col-md-6 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                            // disabled={!needSaving}
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

LanguageConfig.propTypes = {
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
