/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import CustomScrollbars from '~/components/CustomScrollBar';
import Header from '~/components/Header';
import Sidebar from '~/components/Sidebar';

import { AppContainer } from './styles';

export default function LocalLayout({ children, screen }) {
    const { isSmallScreen, isMobile } = screen;
    const drawerStyle = 'collapsible-drawer';

    const [navCollapsed, setNavCollapsed] = useState(false);

    function onToggleCollapsedNav() {
        setNavCollapsed(!navCollapsed);
    }

    return (
        <AppContainer
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header
                selectGroup
                drawer={drawerStyle}
                smallScreen={isMobile || isSmallScreen}
                toggleCollapsedNav={() => onToggleCollapsedNav()}
            />
            <Sidebar
                drawer={drawerStyle}
                open={navCollapsed}
                toggleCollapsedNav={() => onToggleCollapsedNav()}
                hideNav={() => setNavCollapsed(false)}
            />
            <ToastContainer toastClassName="toast-container" autoClose={3000} />
            <CustomScrollbars className="pt-3 pb-5">
                <>{children}</>
            </CustomScrollbars>
        </AppContainer>
    );
}

LocalLayout.propTypes = {
    children: PropTypes.element.isRequired,
    screen: PropTypes.object,
};

LocalLayout.defaultProps = {
    screen: {},
};
