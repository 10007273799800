/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdMap, MdPinDrop, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function NavMapForm(props) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { match } = props;
    const { id } = match.params;

    const [operation, setOperation] = useState(
        id === 'new' ? 'create' : 'update'
    );
    const [locations, setLocations] = useState([]);
    const [locationZones, setLocationZones] = useState([]);

    const [body, setBody] = useState({
        name: '',
    });

    const [file, setFile] = useState({
        id: null,
        url: null,
    });

    const [layers, setLayers] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLocations() {
        setIsLoading(true);
        await api
            .get(`locations`)
            .then(response => {
                const data = response.data.map(l => ({
                    id: l.id,
                    name: l.name,
                }));
                setLocations(data);
            })
            .catch(error => requestError(error));
    }

    async function loadZones() {
        setIsLoading(true);
        await api
            .get(`zones`)
            .then(response => {
                const data = response.data.map(z => ({
                    id: z.id,
                    name: z.name,
                    location_id: z.location ? z.location.id : '',
                }));
                setLocationZones(data);
            })
            .catch(error => requestError(error));
    }

    async function loadMap() {
        if (id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`maps/${id}`)
                .then(response => {
                    const m = response.data;
                    setBody({
                        name: m.name,
                        description: m.description,
                        location_id: m.location ? m.location.id : false,
                        zone_id: m.zone ? m.zone.id : false,
                    });
                    setFile(m.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadMapLayers() {
        if (id && id !== 'new') {
            setIsLoading(true);
            await api
                .get(`map_layers?map=${id}`)
                .then(response => {
                    const { data } = response;
                    const m_layers = data.map(l => {
                        const updated = new Date(l.updated);
                        return {
                            id: l.id,
                            reference: l.reference,
                            robot:
                                l.robot_type && l.robot_type.name ? (
                                    l.robot_type.name
                                ) : (
                                    <LocaleMessage msg="message.all.short" />
                                ),
                            application: l.app_type ? l.app_type.name : null,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setLayers(m_layers);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadLocations();
        loadZones();
        loadMap();
        loadMapLayers();
    }, []);

    async function updateMap(data) {
        await api
            .put(`maps/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.form.update_success" />
                );
                setIsLoading(false);
                loadMap(id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`maps`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.navmaps.form.create_success" />
                    );

                    history.push(`/tools/maps`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateMap(data);
        }
    }

    async function deleteLayer() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`/map_layers/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.layer.list.delete_success" />
                );
                await loadMapLayers();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        history.push(`/tools/maps/${id}/layer/${_id}`);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteDialogOpen(true);
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`maps/${id}/media`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id };
                setBody(updated);
                updateMap(updated);
            })
            .catch(error => requestError(error));
    };

    function renderFileInput() {
        return (
            <div
                className="col-12 mb-5"
                style={{ height: '200px', justifyContent: 'center' }}
            >
                <FileInput
                    defaultValue={file}
                    onFileUpload={onFileUpload}
                    multiple={false}
                    text={<LocaleMessage msg="label.form.change_image" />}
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={
                    <LocaleMessage msg="page.navmaps.layer.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteLayer(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildLayerList() {
        const headCells = [
            {
                id: 'robot',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
            {
                id: 'reference',
                label: <LocaleMessage msg="table.headers.layer_name" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={layers}
                    orderColumn="robot"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div
                                className="col-md-6 col-12"
                                style={{ padding: '0px' }}
                            >
                                <NewItemButton
                                    link={`/tools/maps/${id}/layer/new`}
                                    text={
                                        <LocaleMessage msg="page.navmaps.layer.list.add" />
                                    }
                                />
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    const filtered_zones = locationZones.filter(z => {
        return z.location_id === body.location_id;
    });

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.navmaps.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.navmaps.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/maps',
                    title: <LocaleMessage msg="breadcrumbs.nav_maps" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-6 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.navmaps.form.title" />
                            }
                            Icon={MdMap}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.location" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    body.location_id
                                                        ? body.location_id
                                                        : false
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        location_id:
                                                            event.target.value,
                                                        zone_id: false,
                                                    })
                                                }
                                            >
                                                <MenuItem value={false}>
                                                    <LocaleMessage msg="label.form.no_location" />
                                                </MenuItem>
                                                {locations.map(l => {
                                                    return (
                                                        <MenuItem
                                                            value={l.id}
                                                            key={l.id}
                                                        >
                                                            {l.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.location_zone" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    body.zone_id
                                                        ? body.zone_id
                                                        : false
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        zone_id:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                <MenuItem value={false}>
                                                    <LocaleMessage msg="label.form.no_location_zone" />
                                                </MenuItem>
                                                {filtered_zones.map(z => {
                                                    return (
                                                        <MenuItem
                                                            value={z.id}
                                                            key={z.id}
                                                        >
                                                            {z.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.navmaps.form.label.title" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            helperText={
                                                <LocaleMessage msg="page.navmaps.form.label.title.helper" />
                                            }
                                        />
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.navmaps.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {operation === 'update'
                                        ? renderFileInput()
                                        : null}
                                    <div className="col-12">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.navmaps.layer.list.title" />
                                }
                                Icon={MdPinDrop}
                                hide
                            >
                                <>{buildLayerList()}</>
                            </CardSideBordered>
                        </div>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

NavMapForm.propTypes = {
    match: PropTypes.object.isRequired,
};
