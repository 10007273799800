import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';

import history from '~/services/history';

import { UserAvatar } from './styles';
import UserInfoPopup from './UserInfoPopup/index';

export default function UserInfo({ onLogoutClick }) {
    const [openMenu, setOpenMenu] = useState(false);
    const profile = useSelector(state => state.user.profile);
    const { avatar } = profile;

    function onCloseMenu(link) {
        setOpenMenu(false);
        history.push(link);
    }

    return (
        <UserAvatar className="user-profile d-flex flex-row align-items-center">
            <Dropdown
                className="quick-menu"
                isOpen={openMenu}
                toggle={() => setOpenMenu(!openMenu)}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    <IconButton className="icon-btn size-30">
                        {avatar && avatar.url ? (
                            <img
                                className="avatar-placeholder"
                                alt="..."
                                src={avatar ? avatar.url : null}
                            />
                        ) : (
                            <div className="MuiAvatar-root bg-secondary size-30 text-white MuiAvatar-colorDefault rounded-circle">
                                {profile && profile.name
                                    ? profile.name[0].toUpperCase()
                                    : 'P'}
                            </div>
                        )}
                    </IconButton>
                </DropdownToggle>

                <DropdownMenu right style={{ width: '250px', top: '15px' }}>
                    <UserInfoPopup
                        profile={profile}
                        onCloseMenu={link => onCloseMenu(link)}
                        onLogoutClick={event => onLogoutClick(event)}
                    />
                </DropdownMenu>
            </Dropdown>
        </UserAvatar>
    );
}

UserInfo.propTypes = {
    onLogoutClick: PropTypes.func.isRequired,
};
