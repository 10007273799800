/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdList, MdLaunch } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Popover,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import ExitDialog from '~/components/ExitDialog';
import ColorPicker from '~/components/Form/ColorPicker';
import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';

import DanceEdit from './Edit';
import DanceList from './List';
import Preview from './Preview';
import { Column, SettingsArea, ParameterArea } from './styles';

const robot_options = {
    cruzr: {
        screen: true,
    },
};

export default function DanceConfig({ match }) {
    const { plugin_id, id } = match.params;
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const [groupId, setGroupId] = useState('*');
    const [isLoading, setIsLoading] = useState(false);
    const [internalLoading, setInternalLoading] = useState(false);

    const [pluginConfig, setPluginConfig] = useState(null);
    const [robotTypes, setRobotTypes] = useState([]);
    const [currType, setCurrType] = useState({});

    const [currSettings, setCurrSettings] = useState([]);
    const [danceList, setDanceList] = useState([]);
    const [customDanceList, setCustomDanceList] = useState([]);
    const [defaultGalleries, setDefaultGalleries] = useState([]);

    const [exitDialogOpen, setExitDialogOpen] = useState(false);
    const [bindDialogOpen, setBindDialogOpen] = useState(false);

    const [danceSource, setDanceSource] = useState('pluginspace');
    const [bindingRows, setBindingRows] = useState([]);
    const [imageGalleriesList, setImageGalleriesList] = useState([]);

    const [currEdit, setCurrEdit] = useState({});

    const [tooltipAnchor, setTooltipAnchor] = useState(null);
    const [availableLanguages, setAvailableLanguages] = useState([]);

    const tooltipOpen = Boolean(tooltipAnchor);

    const rtype_settings =
        currSettings && currType && currSettings[currType.slug]
            ? currSettings[currType.slug]
            : {};

    const show_preview =
        currType &&
        robot_options[currType.slug] &&
        robot_options[currType.slug].screen &&
        rtype_settings.show_selection
            ? robot_options[currType.slug].screen
            : false;

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const langs = response.data;
                setAvailableLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadRobotTypes() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const list = response.data;
                const filtered_robots = list.filter(r => {
                    return !!robot_options[r.slug];
                });
                setCurrType(filtered_robots[0] || {});
                setRobotTypes(filtered_robots);
            })
            .catch(error => requestError(error));
    }

    async function loadGalleryDances(gallery_id) {
        setInternalLoading(true);
        if (gallery_id) {
            if (gallery_id === 'pluginspace') {
                setDanceList(customDanceList);
            } else {
                await api
                    .get(`albums/${gallery_id}/files`)
                    .then(response => {
                        const items = response.data;
                        const dances = items.map(i => {
                            const object = i.object || {};
                            const content = object.content || {};
                            return {
                                id: object.id,
                                name: object.name,
                                type: 'preset',
                                action: {
                                    button: {
                                        color:
                                            content.cover && content.cover.color
                                                ? content.cover.color
                                                : '',
                                        file: object.illustration || {},
                                    },
                                },
                            };
                        });
                        setDanceList(dances);
                    })
                    .catch(error => requestError(error));
            }
        }
        setInternalLoading(false);
    }

    async function loadRobotDefaults(robottype_id) {
        await api
            .get(`types/${robottype_id}/defaults?album_type=dances`)
            .then(response => {
                const defaults = response.data;
                setDefaultGalleries(defaults);
            })
            .catch(error => requestError(error));
    }

    async function loadCustomDances(robottype_id) {
        setIsLoading(true);
        await api
            .get(`dances?robottype_id=${robottype_id}`)
            .then(async response => {
                const list = response.data.map(d => {
                    return {
                        ...d,
                        group_name: d.group ? d.group.name : '',
                    };
                });
                setCustomDanceList(list);
                setDanceList(list);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadPluginconfig(config_id) {
        setIsLoading(true);
        await api
            .get(`pluginconfigs/${config_id}`)
            .then(async response => {
                const config = response.data;
                const group_id = config.group ? config.group.id : '*';
                setGroupId(group_id);
                setPluginConfig({
                    name: config.name,
                    parameters: config.parameters,
                });

                const r_settings = config.parameters || {};

                setCurrSettings(r_settings);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group &&
                        (g.group.id === '*' || g.group.id === groupId)
                    );
                });
                setImageGalleriesList(filtered);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadLanguages();
        loadRobotTypes();
    }, []);

    useEffect(() => {
        loadPluginconfig(id);
    }, [id]);

    useEffect(() => {
        loadGalleryList();
    }, [groupId]);

    useEffect(() => {
        if (currType && currType.id) {
            loadRobotDefaults(currType.id);
            loadCustomDances(currType.id);
        } else {
            setDefaultGalleries([]);
        }
    }, [robotTypes, currType]);

    useEffect(() => {
        loadGalleryDances(danceSource);
    }, [customDanceList, danceSource]);

    function updateRTypeSettings(key, value) {
        setCurrSettings({
            ...currSettings,
            [currType.slug]: {
                ...rtype_settings,
                [key]: value,
            },
        });
    }

    async function handleSubmit() {
        const data = {
            ...pluginConfig,
            parameters: currSettings,
        };

        setIsLoading(true);
        await api
            .put(`pluginconfigs/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.plugins.form.config_updated" />
                );
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleColorChange(c) {
        const sel_color = c.hex;
        updateRTypeSettings('background_color', sel_color);
    }

    function addDances(objects, selected) {
        const obj_map = {};
        objects.forEach(o => {
            obj_map[o.id] = {
                id: o.id,
                name: o.name,
                type: danceSource === 'pluginspace' ? 'custom' : 'preset',
                action: o.action || {},
            };
        });

        const selected_objs = [];
        selected.forEach(o => {
            selected_objs.push(obj_map[o]);
        });

        const rtype_list = rtype_settings.dances || [];
        const selected_dances = [...rtype_list, ...selected_objs];

        updateRTypeSettings('dances', selected_dances);
        setBindingRows([]);
        setBindDialogOpen(false);
    }

    function renderAddDances() {
        const rtype_list = (rtype_settings.dances || []).map(d => {
            return d.id;
        });

        const available_dances = danceList.filter(d => {
            return !rtype_list.includes(d.id);
        });

        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="label.form.name" />,
            },
        ];

        const sources = defaultGalleries.map(g => {
            return {
                id: g.id,
                label: g.name,
            };
        });

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div className="col-md-8 col-12">
                    <FormSelect
                        classes="mb-3"
                        options={[
                            ...sources,
                            {
                                id: 'pluginspace',
                                label:
                                    lng_all[
                                        'page.dances.pluginconfig.list.custom'
                                    ] || 'CUSTOM DANCES',
                            },
                        ]}
                        labelKey="label"
                        label={
                            <LocaleMessage msg="page.dances.pluginconfig.list.choose" />
                        }
                        onChange={value => {
                            setDanceSource(value);
                        }}
                        value={danceSource || ''}
                    />
                </div>
                {internalLoading ? (
                    <Splash />
                ) : (
                    <div className="col-12 row mb-3">
                        {danceSource ? (
                            <div
                                style={{
                                    minHeight: '150px',
                                    width: '100%',
                                }}
                            >
                                <DataTable
                                    headerColumns={headCells}
                                    data={available_dances}
                                    orderDirection="asc"
                                    orderColumn="value"
                                    handleTableRowClick={() => {}}
                                    hasActions={false}
                                    hasFilter={false}
                                    sortable
                                    selectable
                                    selectedActions={[]}
                                    selectedRows={bindingRows}
                                    setSelectedRows={s => {
                                        setBindingRows(s);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }

    function buildBindDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={bindDialogOpen}
                onClose={() => setBindDialogOpen(false)}
                title={<LocaleMessage msg="page.dances.pluginconfig.edit" />}
                content={renderAddDances()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            setBindingRows([]);
                            setBindDialogOpen(false);
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => {
                            addDances(danceList, bindingRows);
                        },
                        label: <LocaleMessage msg="button.add" />,
                    },
                ]}
            />
        );
    }

    function buildExitDialog() {
        return (
            <ExitDialog
                open={exitDialogOpen}
                onClose={() => setExitDialogOpen(false)}
                destination="/tools/dances"
            />
        );
    }

    function renderListConfig() {
        return currType ? (
            <AppConfigRobotType
                className="col-12"
                robotTypes={robotTypes}
                forceRobotTitle
                currType={currType}
                setCurrType={t => setCurrType(t)}
            >
                <div
                    className="col-12 row"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ParameterArea className="col-12 row py-3 mb-3">
                        <div className="col-12">
                            <FormSwitch
                                className="col-12"
                                value={rtype_settings.show_selection || false}
                                onChange={event =>
                                    updateRTypeSettings(
                                        'show_selection',
                                        event.target.checked
                                    )
                                }
                                label={
                                    <LocaleMessage msg="page.dances.pluginconfig.show_selection" />
                                }
                            />
                        </div>

                        {show_preview ? (
                            <div className="col-12 mt-1">
                                <ColorPicker
                                    label={
                                        <LocaleMessage msg="page.dances.pluginconfig.background_color" />
                                    }
                                    colorKey="background_color"
                                    settings={rtype_settings}
                                    handleChange={c => {
                                        handleColorChange(c);
                                    }}
                                />
                            </div>
                        ) : null}
                    </ParameterArea>

                    <ParameterArea className="col-12 mb-3 p-3">
                        <FormControl>
                            <FormLabel className="mb-3">
                                <LocaleMessage msg="page.dances.pluginconfig.random.limit.condition" />
                            </FormLabel>
                            <RadioGroup
                                value={
                                    rtype_settings.stop_condition
                                        ? rtype_settings.stop_condition
                                        : 'quantity'
                                }
                                onChange={event =>
                                    updateRTypeSettings(
                                        'stop_condition',
                                        event.target.value
                                    )
                                }
                                color="primary"
                            >
                                <FormControlLabel
                                    value="until_failure"
                                    label={
                                        <LocaleMessage msg="page.dances.pluginconfig.random.limit.until_failure" />
                                    }
                                    control={<Radio color="primary" />}
                                />
                                <FormControlLabel
                                    value="quantity"
                                    label={
                                        <TextField
                                            fullWidth
                                            value={
                                                rtype_settings.quantity
                                                    ? rtype_settings.quantity
                                                    : 1
                                            }
                                            onChange={event =>
                                                updateRTypeSettings(
                                                    'quantity',
                                                    event.target.value
                                                )
                                            }
                                            type="number"
                                            inputProps={{
                                                min: 1,
                                                step: 1,
                                                style: { textAlign: 'center' },
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocaleMessage msg="page.dances.pluginconfig.random.limit.value" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    }
                                    control={<Radio color="primary" />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </ParameterArea>

                    <ParameterArea className="col-12 row mt-1 mb-3">
                        <DanceEdit
                            languages={availableLanguages}
                            settings={rtype_settings}
                            currType={currType}
                            updateList={list => {
                                updateRTypeSettings('dances', list);
                            }}
                            defaultColor={
                                rtype_settings.background_color || '#000'
                            }
                            handleError={e => requestError(e)}
                            galleries={imageGalleriesList}
                            currEdit={currEdit}
                            updateItem={i => {
                                setCurrEdit({
                                    ...currEdit,
                                    item: i,
                                });
                            }}
                            closeEdit={() => {
                                setCurrEdit({});
                            }}
                        />
                        <DanceList
                            settings={rtype_settings}
                            currType={currType}
                            updateList={list => {
                                updateRTypeSettings('dances', list);
                            }}
                            onAddClick={() => setBindDialogOpen(true)}
                            defaultColor={
                                rtype_settings.background_color || '#000'
                            }
                            handleEdit={(idx, item) =>
                                setCurrEdit({
                                    index: idx,
                                    item,
                                })
                            }
                            galleries={imageGalleriesList}
                        />
                    </ParameterArea>
                </div>
            </AppConfigRobotType>
        ) : null;
    }

    return (
        <PageContent
            title={pluginConfig ? pluginConfig.name.toUpperCase() : ''}
            currentPage={pluginConfig ? pluginConfig.name.toUpperCase() : ''}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
                {
                    url: `/plugins/${plugin_id}`,
                    title: <LocaleMessage msg="breadcrumbs.robot_functions" />,
                },
            ]}
            loading={isLoading}
        >
            <div className="col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.dances.pluginconfig.title" />
                    }
                    Icon={MdList}
                    hide
                >
                    <>
                        <div className="sidecard-body">
                            {exitDialogOpen ? buildExitDialog() : null}
                            {bindDialogOpen ? buildBindDialog() : null}
                            {tooltipOpen ? (
                                <Popover
                                    open={tooltipOpen}
                                    tooltipAnchor={tooltipAnchor}
                                    onClose={() => setTooltipAnchor(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <div style={{ padding: '15px' }}>
                                        <LocaleMessage msg="message.need_save.content" />
                                    </div>
                                </Popover>
                            ) : null}
                            <Column>
                                <SettingsArea className="row col-12 mt-3 mb-3">
                                    <div
                                        className={`col-md-${
                                            show_preview ? 5 : 6
                                        } col-12`}
                                        style={{
                                            margin: '20px',
                                        }}
                                    >
                                        <Button
                                            className="mb-3"
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                padding: '5px 20px',
                                            }}
                                            onClick={() =>
                                                setExitDialogOpen(true)
                                            }
                                        >
                                            <LocaleMessage msg="page.dances.pluginconfig.edit" />
                                            <MdLaunch
                                                size={20}
                                                className="ml-1"
                                            />
                                        </Button>
                                        {renderListConfig()}
                                    </div>
                                    {show_preview ? (
                                        <div className="col-md-6 col-12 container">
                                            <LocaleMessage msg="label.preview" />
                                            <Preview
                                                configuration={rtype_settings}
                                            />
                                        </div>
                                    ) : null}
                                    <Button
                                        className="col-md-8 col-12 p-3 mt-3"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        onClick={() => handleSubmit()}
                                    >
                                        <LocaleMessage msg="button.save" />
                                    </Button>
                                </SettingsArea>
                            </Column>
                        </div>
                    </>
                </CardSideBordered>
            </div>
        </PageContent>
    );
}

DanceConfig.propTypes = {
    match: PropTypes.object.isRequired,
};
