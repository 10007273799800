import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-bottom: 20px;

    .card-side-color {
        background: ${props => props.theme.palette.primary.main};
        color: #fff;
        padding: 30px 20px;
        display: flex;
        justify-content: center;
        border-radius: 0.375rem 0px 0px 0.375rem;
    }

    .sidecard {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .sidecard-header {
        padding: 20px 25px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 15px;
            color: ${props =>
                props.theme.palette.pluginspace
                    ? props.theme.palette.pluginspace.primary
                    : props.theme.palette.primary.main};
        }

        h2 {
            color: ${props =>
                props.theme.palette.pluginspace
                    ? props.theme.palette.pluginspace.primary
                    : props.theme.palette.primary.main};
            margin: 0px;
        }
    }

    .sidecard-body {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
`;
