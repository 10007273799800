/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    token: null,
    signed: false,
    expired: false,
};

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@auth/SIGN_IN_REQUEST': {
                break;
            }
            case '@auth/SIGN_IN_SUCCESS': {
                draft.token = action.payload.token;
                break;
            }
            case '@auth/SIGN_IN_COMPLETE': {
                draft.signed = true;
                draft.expired = false;
                break;
            }
            case '@auth/SIGN_FAILURE': {
                draft.token = null;
                draft.signed = false;
                draft.expired = false;
                break;
            }
            case '@auth/SESSION_EXPIRE': {
                draft.expired = true;
                break;
            }
            case '@auth/SIGN_OUT_REQUEST': {
                break;
            }
            case '@auth/SIGN_OUT': {
                draft.token = null;
                draft.signed = false;
                draft.expired = false;
                break;
            }
            default:
                return state;
        }
    });
}
