/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
} from '@material-ui/core';

import FormPasswordInput from '~/components/Form/PasswordInput';
import LocaleMessage from '~/components/LocaleMessage';

export default function UserForm({ user, setUser }) {
    const [randomPW, setRandomPW] = useState(true);

    function handleSwitchChange(event, key) {
        const val = event.target.checked;
        if (key === 'randomPW') {
            setRandomPW(val);
            setUser({ ...user, password: '', confirmPassword: '' });
        } else if (key === 'notify') {
            setUser({ ...user, notify: val });
        }
    }

    return (
        <>
            <div className="col-md-6 col-12 mb-5">
                <TextField
                    label={
                        <LocaleMessage msg="component.user_form.label.name" />
                    }
                    fullWidth
                    value={user.name || ''}
                    onChange={event =>
                        setUser({
                            ...user,
                            name: event.target.value,
                        })
                    }
                />
            </div>
            <div className="col-md-6 col-12 mb-5">
                <TextField
                    label={
                        <LocaleMessage msg="component.user_form.label.email" />
                    }
                    fullWidth
                    value={user.email || ''}
                    onChange={event =>
                        setUser({
                            ...user,
                            email: event.target.value,
                        })
                    }
                />
            </div>
            <div className="col-12 mb-5">
                <FormControl fullWidth component="fieldset">
                    <FormGroup style={{ width: '100%' }}>
                        <FormControlLabel
                            className="mb-3"
                            control={
                                <Switch
                                    color="primary"
                                    checked={randomPW}
                                    onChange={event =>
                                        handleSwitchChange(event, 'randomPW')
                                    }
                                />
                            }
                            label={
                                <LocaleMessage msg="component.user_form.label.random_password" />
                            }
                        />
                    </FormGroup>
                </FormControl>
                <FormControl fullWidth component="fieldset">
                    <FormGroup style={{ width: '100%' }}>
                        <FormControlLabel
                            className="mb-3"
                            control={
                                <Switch
                                    name="notify"
                                    color="primary"
                                    checked={user.notify || randomPW}
                                    onChange={event =>
                                        handleSwitchChange(event, 'notify')
                                    }
                                />
                            }
                            label={
                                <LocaleMessage msg="component.user_form.label.notify_password" />
                            }
                            disabled={randomPW}
                        />
                    </FormGroup>
                </FormControl>
            </div>
            {!randomPW ? (
                <div
                    className="col-12 row"
                    style={{ display: 'flex', alignItems: 'flex-start' }}
                >
                    <div className="col-md-6 col-12 mb-5">
                        <FormPasswordInput
                            label={
                                <LocaleMessage msg="component.user_form.label.password" />
                            }
                            fullWidth
                            value={user.password || ''}
                            onChange={event =>
                                setUser({
                                    ...user,
                                    password: event.target.value,
                                })
                            }
                            helper={
                                <LocaleMessage msg="component.user_form.label.empty_password" />
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <FormPasswordInput
                            label={
                                <LocaleMessage msg="component.user_form.label.confirm_password" />
                            }
                            fullWidth
                            value={user.confirmPassword || ''}
                            onChange={event =>
                                setUser({
                                    ...user,
                                    confirmPassword: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
}

UserForm.defaultProps = {};

UserForm.propTypes = {
    user: PropTypes.object.isRequired,
    setUser: PropTypes.func.isRequired,
};
