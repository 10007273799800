/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    Stepper,
    Step,
    StepContent,
    StepButton,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function FormStepper(props) {
    const {
        steps,
        activeStep,
        handleStepClick,
        handleBack,
        handleNext,
        handleSubmit,
    } = props;

    return (
        <Stepper
            orientation="vertical"
            nonLinear
            activeStep={activeStep}
            style={{ width: '100%' }}
        >
            {steps.map((s, index) => (
                <Step key={s.key}>
                    <StepButton onClick={() => handleStepClick(index)}>
                        {s.label}
                    </StepButton>
                    <StepContent>
                        {s.content}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                disabled={activeStep === 0}
                                onClick={() => handleBack()}
                            >
                                <LocaleMessage msg="button.back" />
                            </Button>

                            {activeStep !== steps.length - 1 ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        activeStep === steps.length - 1
                                            ? () => handleSubmit()
                                            : () => handleNext()
                                    }
                                >
                                    <LocaleMessage msg="button.next" />
                                </Button>
                            ) : null}
                        </div>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}

FormStepper.defaultProps = {
    steps: [],
    handleStepClick: () => {},
    handleBack: () => {},
    handleNext: () => {},
    handleSubmit: () => {},
};

FormStepper.propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number.isRequired,
    handleStepClick: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    handleSubmit: PropTypes.func,
};
