/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdFormatListBulleted } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkAdmin from '~/util/CheckAdmin';

import DanceAudioContent from './AudioContent';
import DanceBehaviourContent from './BehaviourContent';
import { ParameterArea } from './styles';

const dance_robots = ['cruzr', 'nao'];

export default function DanceForm(props) {
    const dispatch = useDispatch();

    const user_settings = useSelector(state => state.settings || null);
    const { profile } = useSelector(state => state.user || null);

    const super_user = checkAdmin(user_settings, profile);
    const { admin_groups, active } = user_settings;

    const { match } = props;
    const { id } = match.params;

    const [operation, setOperation] = useState('create');
    const [availableRobots, setAvailableRobots] = useState([]);

    const [body, setBody] = useState({
        name: '',
        group_id: active ? active.id : null,
        group_name: active ? active.name : '',
    });

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [robotGalleries, setRobotGalleries] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadAvailableRobots() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const { data } = response;
                const robots = data.filter(s_r => {
                    return dance_robots.includes(s_r.slug);
                });
                setAvailableRobots(robots || []);
            })
            .catch(error => requestError(error));
    }

    async function loadDance(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`dances/${_id}`)
                .then(response => {
                    const d = response.data;
                    setBody({
                        name: d.name,
                        robottype_id: d.robot ? d.robot.id : null,
                        group_id: d.group ? d.group.id : null,
                        group_name: d.group ? d.group.name : '---',
                        open_all: d.open_all,
                        content: d.content,
                    });
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadRobotDefaults(_id) {
        if (_id) {
            await api
                .get(`types/${_id}/defaults`)
                .then(response => {
                    const defaults = response.data;
                    const default_galleries = {};
                    defaults.forEach(g => {
                        const gallery_type = g.type;

                        const g_body = { ...g };
                        delete g_body.robot_type;

                        if (!default_galleries[gallery_type]) {
                            default_galleries[gallery_type] = [];
                        }
                        default_galleries[gallery_type].push(g_body);
                    });
                    setRobotGalleries(default_galleries);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadAvailableRobots();
    }, []);

    useEffect(() => {
        loadDance(id);
    }, [id]);

    useEffect(() => {
        loadRobotDefaults(body.robottype_id);
    }, [body.robottype_id]);

    async function handleSubmit(event) {
        event.preventDefault();

        const data = {
            ...body,
        };

        setIsLoading(true);
        if (operation === 'create') {
            await api
                .post(`dances`, data)
                .then(response => {
                    const s_data = response.data;
                    toast.success(
                        <LocaleMessage msg="page.dances.form.create_success" />
                    );

                    history.push(`/tools/dances/${s_data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`dances/${id}`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.dances.form.update_success" />
                    );
                    await Promise.all([loadDance(id)]);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    // const onFileUpload = async event => {
    //     const fileObj = event.target.files[0];
    //     const reader = new FileReader();

    //     let fileloaded = e => {
    //         const fileContents = e.target.result;
    //         const template = JSON.parse(fileContents);

    //         setTemplateFile({
    //             id: fileObj.name,
    //             name: fileObj.name,
    //         });

    //         if (template.content_type && template.content_type === 'script') {
    //             setBody({
    //                 ...body,
    //                 ...(!body.name && { name: template.name }),
    //                 ...(!body.description && {
    //                     description: template.description,
    //                 }),
    //                 type: 'dance',
    //                 content: template.content || {},
    //                 template: {
    //                     steps: template.steps,
    //                 },
    //             });
    //         } else {
    //             toast.error(
    //                 <LocaleMessage msg="page.dances.form.import_error" />
    //             );
    //         }
    //     };

    //     // Mainline of the method
    //     fileloaded = fileloaded.bind(this);
    //     reader.onload = fileloaded;
    //     reader.readAsText(fileObj);
    // };

    // function renderImportTemplate() {
    //     return (
    //         <>
    //             <div className="col-12 mb-3">
    //                 <FormSwitch
    //                     value={body.use_template || false}
    //                     onChange={event =>
    //                         setBody({
    //                             ...body,
    //                             use_template: event.target.checked,
    //                         })
    //                     }
    //                     label={
    //                         <LocaleMessage msg="page.dances.form.template.use" />
    //                     }
    //                 />
    //             </div>
    //             {body.use_template ? (
    //                 <div
    //                     className="col-12 mb-5"
    //                     style={{
    //                         justifyContent: 'center',
    //                         flexDirection: 'column',
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             height: 'max-content',
    //                         }}
    //                         className="mb-3"
    //                     >
    //                         <FileInput
    //                             defaultValue={templateFile || {}}
    //                             onFileUpload={onFileUpload}
    //                             text={
    //                                 <LocaleMessage msg="label.form.upload_file" />
    //                             }
    //                             type="json"
    //                             multiple={false}
    //                             showFooter={false}
    //                             showIcon={false}
    //                         />
    //                     </div>
    //                     <div className="mt-1 mb-3">
    //                         <LocaleMessage msg="page.dances.form.template.warning" />
    //                     </div>
    //                 </div>
    //             ) : null}
    //         </>
    //     );
    // }

    function renderSUConfig() {
        return operation === 'create' ? (
            <div className="col-12 mb-5">
                <FormControlLabel
                    control={
                        <Switch
                            disabled={operation === 'update'}
                            color="primary"
                            checked={body.open_all || false}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    open_all: event.target.checked,
                                    group_id: '*',
                                })
                            }
                        />
                    }
                    label={<LocaleMessage msg="label.form.open_all" />}
                />
            </div>
        ) : (
            <>
                {body.open_all ? (
                    <div
                        className="col-12 mb-5"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Chip
                            className="mb-3"
                            variant="outlined"
                            color="primary"
                            label={
                                <LocaleMessage msg="message.open_all.title" />
                            }
                        />
                    </div>
                ) : null}
            </>
        );
    }

    function renderGroupSelector() {
        const group_list = admin_groups || [
            {
                id: active ? active.id : null,
                name: active ? active.name : '',
            },
        ];
        return (
            <div className="col-12 mb-5">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.group" />
                    </InputLabel>
                    <Select
                        id="group"
                        value={
                            body.group_id && body.group_id ? body.group_id : ''
                        }
                        onChange={event =>
                            setBody({
                                ...body,
                                group_id: event.target.value,
                            })
                        }
                        disabled={operation === 'update' || body.open_all}
                    >
                        <MenuItem value="*">
                            <LocaleMessage msg="message.all.short" />
                        </MenuItem>
                        {group_list.map(g => (
                            <MenuItem value={g.id} key={g.id}>
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function renderDanceContent() {
        return (
            <div className="col-12">
                <ParameterArea>
                    <DanceAudioContent
                        group={body.group_id}
                        robotType={body.robottype_id || ''}
                        content={body.content}
                        handleError={e => requestError(e)}
                        updateContent={c =>
                            setBody({
                                ...body,
                                content: c,
                            })
                        }
                    />
                    <DanceBehaviourContent
                        group={body.group_id}
                        robotType={body.robottype_id || ''}
                        galleries={robotGalleries || {}}
                        content={body.content}
                        handleError={e => requestError(e)}
                        updateContent={c =>
                            setBody({
                                ...body,
                                content: c,
                            })
                        }
                    />
                </ParameterArea>
            </div>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={`page.dances.form.${
                        operation === 'create' ? 'create' : 'edit'
                    }.title`}
                />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/dances',
                    title: <LocaleMessage msg="page.dances.list.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.dances.form.title" />
                            }
                            Icon={MdFormatListBulleted}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    {super_user ? renderSUConfig() : null}

                                    {!body.open_all
                                        ? renderGroupSelector()
                                        : null}

                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.dances.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.robot_type" />
                                            </InputLabel>
                                            <Select
                                                id="robot_type"
                                                value={
                                                    body.robottype_id &&
                                                    body.robottype_id
                                                        ? body.robottype_id
                                                        : ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        robottype_id:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    operation === 'update'
                                                }
                                            >
                                                {availableRobots.map(r => (
                                                    <MenuItem
                                                        value={r.id}
                                                        key={r.id}
                                                    >
                                                        {r.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {/* {operation === 'create'
                                        ? renderImportTemplate()
                                        : null} */}

                                    {renderDanceContent()}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3 mb-4"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

DanceForm.propTypes = {
    match: PropTypes.object.isRequired,
};
