/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdLaunch, MdWarningAmber } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import DataTable from '~/components/DataTable';
import ExitDialog from '~/components/ExitDialog';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import { IconArea } from '~/styles/custom';

import api from '~/services/pluginbot-api';

import { ParameterArea } from './styles';

const warnings = {
    cruzr: [],
};

const lang_usage = {
    cruzr: 'multi',
    temi: 'single',
};

const exitURL = `/tools/autonomous_actions`;

export default function AutonomousModeConfig({
    handleError,
    robotTypes,
    currType,
    setCurrType,
    appSettings,
    updateObject,
}) {
    const settings = appSettings.settings || {};

    const [isLoading, setIsLoading] = useState(false);
    const [, setNeedSaving] = useState(false);

    const [autonomousMode, setAutonomousMode] = useState(
        settings && settings.autonomous_mode ? settings.autonomous_mode : {}
    );
    const [packList, setPackList] = useState([]);
    const [currPack, setCurrPack] = useState({});

    const [rawList, setRawList] = useState([]);
    const [actionList, setActionList] = useState([]);
    const [actionAlerts, setActionAlerts] = useState(false);

    const [exitTo, setExitTo] = useState(exitURL);
    const [exitDialog, setExitDialog] = useState(false);

    const r_key = currType.slug ? currType.slug : 'all';
    const r_type_settings = autonomousMode[r_key] || {};
    const r_warnings = warnings[r_key] || [];

    const app_languages = settings.language ? settings.language.list : [];
    const app_navigation = settings.map_navigation || {};
    const r_type_navigation = app_navigation[r_key] || {};

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    function getConditionLabel(c) {
        switch (c.type) {
            case 'timeout':
                return `T - ${c.value}${c.unit === 'minutes' ? 'm' : 's'}`;
            case 'battery_lower':
                return `B < ${c.value}%`;
            case 'charging_higher':
                return `B > ${c.value}%`;
            default:
                return c.type;
        }
    }

    function getAlert(item) {
        const lang_mode = lang_usage[r_key] || 'single';
        const main_lang = settings.language ? settings.language.main : '';
        const a_type = item.action_type || '';
        const a_settings = item.settings || {};

        const available_langs =
            lang_mode === 'multi' ? app_languages : [main_lang];

        switch (a_type) {
            case 'speak': {
                const speech_langs = Object.keys(a_settings.texts || {}).map(
                    s => {
                        return s;
                    }
                );
                return !!available_langs.find(l => {
                    return !speech_langs.includes(l);
                });
            }
            case 'speak_sequence': {
                const seq_langs = (a_settings.speech || []).map(s => {
                    return s.language;
                });
                return !!seq_langs.find(l => {
                    return !available_langs.includes(l);
                });
            }
            case 'navigate': {
                return (
                    !r_type_navigation.active ||
                    !a_settings.map_id ||
                    r_type_navigation.map_layer_id !== a_settings.map_id
                );
            }
            case 'run_script':
                break;
            default:
                return false;
        }
        return false;
    }

    function parseList() {
        let has_alert = false;
        const parsed_list = rawList.map(a => {
            const pack_settings = a.settings || {};
            const condition_list = pack_settings.conditions
                ? pack_settings.conditions
                : [];

            const condition_str = condition_list
                .map(c => {
                    return getConditionLabel(c);
                })
                .join(', ');
            const alert = getAlert(a);

            if (alert) has_alert = true;

            const label = (
                <>
                    {alert ? (
                        <MdWarningAmber
                            size={20}
                            className="mr-2"
                            color="#f00"
                        />
                    ) : null}
                    <LocaleMessage
                        msg={`page.tools.autonomous_actions.actions.${a.action_type}`}
                    />
                    {a.description ? <span>: {a.description}</span> : null}
                </>
            );

            return {
                id: a.id,
                description: a.description,
                action_type: a.action_type,
                label,
                settings: a.settings,
                condition_list,
                condition_label: condition_str,
            };
        });
        setActionAlerts(has_alert);
        setActionList(parsed_list);
    }

    async function loadPacks() {
        setIsLoading(true);
        if (currType && currType.id) {
            await api
                .get(`autonomous_mode?robot=${currType.id}`)
                .then(response => {
                    const { data } = response;
                    const packs = data.map(p => {
                        const label = <span>{p.name}</span>;

                        return {
                            ...p,
                            label,
                        };
                    });
                    setPackList(packs);
                })
                .catch(error => requestError(error));
        } else {
            setPackList([]);
        }
        setIsLoading(false);
    }

    async function loadActions(_id) {
        if (_id && r_key && r_key !== 'all') {
            setIsLoading(true);
            await api
                .get(`autonomous_mode/${_id}/actions`)
                .then(response => {
                    setRawList(response.data || []);
                })
                .catch(error => requestError(error));
        } else {
            setRawList([]);
        }
        setIsLoading(false);
    }

    function setCurrContent(_id) {
        const content = (packList || []).find(p => {
            return p.id === _id;
        });
        setCurrPack(content || null);
        loadActions(_id);
    }

    useEffect(() => {
        loadPacks();
    }, [currType]);

    useEffect(() => {
        setCurrContent(r_type_settings.content_id);
    }, [packList, r_type_settings.content_id]);

    useEffect(() => {
        parseList();
    }, [rawList, r_type_settings.mode]);

    async function handleSubmit() {
        const data = { autonomous_mode: autonomousMode };
        updateObject(data);
    }

    function setCurrTypeSettings(key, value) {
        setNeedSaving(true);
        const updated = {
            ...autonomousMode,
            [r_key]: {
                ...r_type_settings,
                [key]: value,
            },
        };
        setAutonomousMode(updated);
    }

    function renderExitDialog() {
        return (
            <ExitDialog
                open={exitDialog}
                onClose={() => setExitDialog(false)}
                destination={exitTo}
            />
        );
    }

    function renderContentDetails(open) {
        const pack_settings =
            currPack && currPack.content ? currPack.content : {};

        const random_cond =
            !pack_settings.mode || pack_settings.mode === 'random';

        const action_headers = [
            {
                id: 'label',
                label: <LocaleMessage msg="table.headers.action" />,
            },
            ...(!random_cond
                ? [
                      {
                          id: 'condition_label',
                          label: (
                              <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions" />
                          ),
                      },
                  ]
                : []),
        ];

        return open ? (
            <div className="col-md-7 col-12 mb-3">
                <div
                    className="sidecard-header row"
                    style={{
                        width: '100%',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        className="mb-3 row"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <h2>
                            <LocaleMessage msg="page.applications.conversation.autonomous_mode.content.preview" />
                        </h2>
                        <div className="col-md-4 col-12 px-0">
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    setExitTo(
                                        `${exitURL}/${r_type_settings.content_id}`
                                    );
                                    setExitDialog(true);
                                }}
                            >
                                <LocaleMessage msg="button.edit" />
                                <MdLaunch size={20} className="ml-1 mr-0" />
                            </Button>
                        </div>
                    </div>
                </div>
                <ParameterArea className="p-3 py-5">
                    <div
                        className="col-12 mb-5"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <span>
                            <LocaleMessage
                                msg={`page.tools.autonomous_actions.form.mode.${
                                    pack_settings && pack_settings.mode
                                        ? pack_settings.mode
                                        : 'random'
                                }.description`}
                            />
                        </span>
                    </div>
                    <div style={{ minHeight: '150px', width: '100%' }}>
                        <DataTable
                            headerColumns={action_headers}
                            data={actionList}
                            orderColumn="title"
                            handleTableRowClick={() => {}}
                            rowActions={[]}
                            hasHeader={false}
                        />
                    </div>
                </ParameterArea>
            </div>
        ) : null;
    }

    function renderContentSettings(open) {
        return (
            <div className={`col-md-${open ? '5' : '6'} col-12 row mt-5`}>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={
                            r_type_settings.active
                                ? r_type_settings.active
                                : false
                        }
                        onChange={event =>
                            setCurrTypeSettings('active', event.target.checked)
                        }
                        label={
                            <LocaleMessage msg="page.applications.conversation.autonomous_mode.use" />
                        }
                    />
                </div>

                {r_type_settings.active ? (
                    <div className="col-12 row mb-5">
                        <div
                            className={`col-${
                                r_type_settings && r_type_settings.content_id
                                    ? '12'
                                    : '11'
                            }`}
                        >
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.conversation.autonomous_mode.content" />
                                </InputLabel>
                                <Select
                                    className="mb-3"
                                    value={
                                        r_type_settings &&
                                        r_type_settings.content_id
                                            ? r_type_settings.content_id
                                            : ''
                                    }
                                    onChange={event =>
                                        setCurrTypeSettings(
                                            'content_id',
                                            event.target.value
                                        )
                                    }
                                >
                                    <MenuItem value="">
                                        <LocaleMessage msg="page.applications.conversation.autonomous_mode.content.none" />
                                    </MenuItem>
                                    {packList.map(p => {
                                        return (
                                            <MenuItem
                                                value={p.id}
                                                key={`autonomous_pack_${p.id}`}
                                            >
                                                {p.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {actionAlerts ? (
                                    <FormHelperText>
                                        <MdWarningAmber
                                            size={20}
                                            className="mr-2"
                                            color="#f00"
                                        />
                                        <LocaleMessage msg="page.applications.conversation.autonomous_mode.warning" />
                                    </FormHelperText>
                                ) : null}
                            </FormControl>
                        </div>
                        {!(r_type_settings && r_type_settings.content_id) ? (
                            <IconArea className="col-1 px-0">
                                <Tooltip
                                    title={
                                        <LocaleMessage msg="label.see_contents" />
                                    }
                                >
                                    <IconButton
                                        onClick={() => {
                                            setExitTo(exitURL);
                                            setExitDialog(true);
                                        }}
                                    >
                                        <MdLaunch size={20} />
                                    </IconButton>
                                </Tooltip>
                            </IconArea>
                        ) : null}
                    </div>
                ) : null}

                <div className="col-12 mb-3">
                    <Button
                        fullWidth
                        className="p-3 mb-5"
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit()}
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    const show_actions = r_type_settings.active && r_type_settings.content_id;

    return (
        <div className="col-12 mb-3 row">
            {exitDialog ? renderExitDialog() : null}

            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.autonomous_mode.title" />
                </h2>
            </div>
            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 25px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.autonomous_mode.tutorial_apps" />
                {r_warnings.map(w => {
                    return (
                        <p key={w}>
                            <LocaleMessage msg={w} />
                        </p>
                    );
                })}
            </div>

            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <AppConfigRobotType
                                className="col-12 mb-5"
                                robotTypes={robotTypes}
                                currType={currType}
                                setCurrType={t => setCurrType(t)}
                            >
                                <div
                                    className="col-12 row"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div className="col-12 row">
                                        {renderContentSettings(show_actions)}
                                        {renderContentDetails(show_actions)}
                                    </div>
                                </div>
                            </AppConfigRobotType>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

AutonomousModeConfig.defaultProps = {};

AutonomousModeConfig.propTypes = {
    robotTypes: PropTypes.array.isRequired,
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
