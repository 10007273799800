import styled from 'styled-components';

export const DatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;

    .picker-row {
        margin: 5px 0px;
    }

    .shortcuts-row {
        margin: 15px 0px;
    }
`;
