/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import {
    GoogleMap,
    Marker,
    useLoadScript,
    InfoWindow,
    MarkerClusterer,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Badge } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import mapStyle from '~/styles/map/map_style';

import m3 from '~/assets/markers/m3.png';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Container, StatusItem, LocationInfo } from './styles';

const containerStyle = {
    width: '100%',
    height: '100%',
};

const defZoom = 3;
const defPosition = { lat: -50, lng: -130 };

export default function Locations({
    robotLocations,
    handleChangeTab,
    // smallScreen,
}) {
    const colors = GetPluginspaceTheme(useTheme());

    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState(defZoom);
    const [selected, setSelected] = useState([]);
    const [rLoc, setRLoc] = useState({});

    const [, setCurrRobotId] = useQueryState('robot', '');

    function filterLocations() {
        const r_loc = {};
        let mx_robots = 0;
        let r_center = { lat: 0, lng: 0 };
        Object.keys(robotLocations).forEach(k => {
            const l = robotLocations[k];
            const { robots: l_robots, address } = l;
            if (l.geolocation && l_robots.length > mx_robots) {
                mx_robots = l_robots.length;
                r_center = l.geolocation;
            }
            l_robots.forEach(r => {
                r_loc[r.id] = {
                    ...r,
                    location_id: l.id,
                    location: {
                        id: l.id,
                        name: l.name,
                        geolocation: l.geolocation,
                        address: address
                            ? `${address.street} - ${address.number}`
                            : '---',
                    },
                };
            });
        });
        setCenter(r_center);
        setRLoc(r_loc);
    }

    useEffect(() => {
        filterLocations();
    }, [robotLocations]);

    const options = {
        styles: mapStyle(colors),
        disableDefaultUI: true,
        minZoom: 3,
        maxZoom: 14,
        restriction: {
            latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        },
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    });

    if (loadError) {
        return <div>Error...</div>;
    }

    if (!isLoaded) {
        return <div>Waiting...</div>;
    }

    function handleRobotClick(event, robot) {
        setCurrRobotId(robot ? robot.id : null);
        handleChangeTab(event, 'robots');
    }

    function renderClustererHover(c) {
        const markers = c.markers || [];

        const m_robots = markers.map(m => {
            return m.label;
        });
        setSelected(m_robots);
    }

    function renderLocationDetails(loc, size) {
        const r_list = robotLocations[loc.id !== '0' ? loc.id : 'no-location'];

        return (
            <LocationInfo key={loc.id} className={size === 1 ? '' : 'mb-3'}>
                {r_list.type && r_list.type === 'address' ? (
                    <>
                        <h3 className="title-name">{loc.name}</h3>
                        <p className="title-address">
                            <small>{loc.address}</small>
                        </p>
                    </>
                ) : null}
                <div>
                    {r_list && r_list.robots
                        ? r_list.robots.map(r => {
                              const r_status = r.status;
                              return (
                                  <StatusItem
                                      key={`robot-${r.id}`}
                                      className="status"
                                      size={size === 1 ? 'large' : 'normal'}
                                      onClick={event =>
                                          handleRobotClick(event, r)
                                      }
                                  >
                                      <div>
                                          <Badge
                                              className={`status-${
                                                  r_status
                                                      ? r_status.status
                                                      : 'disconnected'
                                              }`}
                                              overlap="rectangular"
                                              badgeContent=" "
                                              variant="dot"
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'left',
                                              }}
                                          />
                                          <span className="robot">
                                              {r.name}
                                          </span>
                                      </div>
                                  </StatusItem>
                              );
                          })
                        : null}
                </div>
            </LocationInfo>
        );
    }

    function renderDetails(sel) {
        const selLocations = {};

        let position = defPosition;
        sel.forEach(r => {
            const robot = rLoc[r];
            if (!robot) return;

            const { location_id: l_id, location, status } = robot;
            selLocations[l_id] = location;
            if (
                status &&
                status.geolocation &&
                status.geolocation.lat &&
                status.geolocation.lng
            ) {
                position = status.geolocation;
            } else if (location.geolocation) {
                position = location.geolocation;
            }
        });

        return position && position.lat && position.lng ? (
            <InfoWindow
                position={{
                    lat: position.lat,
                    lng: position.lng,
                }}
                onCloseClick={() => setSelected([])}
            >
                <>
                    {Object.keys(selLocations).map(l => {
                        return renderLocationDetails(
                            selLocations[l],
                            sel ? sel.length : 0
                        );
                    })}
                </>
            </InfoWindow>
        ) : null;
    }

    function renderLocationRobots(clusterer, loc) {
        const { geolocation } = loc;
        const l_robots = loc.robots || [];

        return l_robots.map(r => {
            return (
                <Marker
                    key={r.id}
                    className="marker"
                    position={
                        geolocation && geolocation.lat && geolocation.lng
                            ? geolocation
                            : defPosition
                    }
                    title={r.name}
                    label={r.id}
                    clusterer={clusterer}
                    onClick={() => renderClustererHover(clusterer)}
                />
            );
        });
    }

    return (
        <Container>
            <GoogleMap
                onLoad={m => {
                    setMap(m);
                }}
                onUnmount={() => setMap(null)}
                mapContainerStyle={containerStyle}
                zoom={zoom}
                center={center}
                options={options}
                onClick={() => setSelected([])}
                onZoomChanged={() => {
                    setZoom(map ? map.getZoom() : defZoom);
                    setSelected([]);
                }}
                onRightClick={() => setZoom(defZoom)}
            >
                {selected.length > 0 ? renderDetails(selected) : null}
                <MarkerClusterer
                    averageCenter
                    onMouseOver={e => renderClustererHover(e)}
                    minimumClusterSize={1}
                    options={{
                        styles: [
                            {
                                url: m3,
                                width: 66,
                                height: 66,
                                textColor: '#fff',
                            },
                        ],
                    }}
                    zoomOnClick
                >
                    {clusterer =>
                        Object.keys(robotLocations).map(l => {
                            return renderLocationRobots(
                                clusterer,
                                robotLocations[l]
                            );
                        })
                    }
                </MarkerClusterer>
            </GoogleMap>
        </Container>
    );
}

Locations.propTypes = {
    smallScreen: PropTypes.bool,
    locations: PropTypes.array.isRequired,
    robotLocations: PropTypes.object,
    handleChangeTab: PropTypes.func,
};

Locations.defaultProps = {
    smallScreen: false,
    robotLocations: {},
    handleChangeTab: () => {},
};
