/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
// import Splash from '~/components/Splash/Inside';

// import getDateLocale from '~/util/GetDateLocale';

const center = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function TelepresenceAppIntegration({
    notifyChange,
    // appSettings,
    telepresenceApp,
    setTelepresenceApp,
    apps,
}) {
    // const _settings = useSelector(state => state.settings || null);
    // const date_loc = getDateLocale(_settings);

    // const { application_content } = appSettings;
    // const { dialog_id } = application_content;

    // const [identiferList, setIdentiferList] = useState([]);
    // const [currIdentifier, setCurrIdentifier] = useState(false);
    // const [deleteIdentifierOpen, setDeleteIdentifierOpen] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);

    const tp_apps = apps.filter(a => {
        return a.type_name === 'telepresence';
    });

    const {
        active: telepresence_active,
        app_id: telepresence_app,
    } = telepresenceApp;

    // async function loadIdentifiers(_id) {
    //     if (_id) {
    //         setIsLoading(true);
    //         await api
    //             .get(
    //                 `contact_identifiers?application=${appSettings.id}&destination=${_id}&dialog=${dialog_id}`
    //             )
    //             .then(async response => {
    //                 const data = response.data.map(c => {
    //                     const identifiers =
    //                         c.identifiers.map(i => {
    //                             return i.value;
    //                         }) || [];
    //                     const identifiers_str = identifiers.join(', ');

    //                     const updated = new Date(c.updated);
    //                     return {
    //                         ...c,
    //                         contact_id:
    //                             c.contact && c.contact.id ? c.contact.id : '*',
    //                         contact_name:
    //                             c.contact && c.contact.name ? (
    //                                 c.contact.name
    //                             ) : (
    //                                 <LocaleMessage msg="message.all.short" />
    //                             ),
    //                         identifiers_str,
    //                         updated: formatDistance(updated, new Date(), {
    //                             addSuffix: true,
    //                             locale: date_loc,
    //                         }),
    //                         updated_timestamp: updated.toISOString(),
    //                     };
    //                 });
    //                 setIdentiferList(data);
    //             })
    //             .catch(error => handleError(error));
    //     } else {
    //         setIdentiferList([]);
    //     }
    //     setIsLoading(false);
    // }

    // async function deleteIdentifier() {
    //     setIsLoading(true);
    //     setDeleteIdentifierOpen(false);
    //     await api
    //         .delete(`point_identifiers/${currIdentifier}`)
    //         .then(async () => {
    //             setToast({
    //                 color: 'success',
    //                 body: (
    //                     <LocaleMessage msg="page.applications.conversation.hive_apps.location_deleted" />
    //                 ),
    //             });
    //             await loadIdentifiers(telepresence_app);
    //         })
    //         .catch(error => handleError(error));
    //     setIsLoading(false);
    // }

    // useEffect(() => {
    //     loadIdentifiers(telepresence_app);
    // }, [telepresence_app]);

    function handleEditSettings(key, value) {
        if (value !== telepresenceApp[key]) {
            notifyChange();
        }
        setTelepresenceApp({
            ...telepresenceApp,
            [key]: value,
        });
    }

    // function handleDeleteIdentifierClose(event) {
    //     event.preventDefault();
    //     setDeleteIdentifierOpen(false);
    // }

    // function handleSelectContactIdentifier(event, _id) {
    //     if (!hasChange) {
    //         setCurrIdentifier(_id);
    //         setInnerObj(_id);
    //         setOpenSettings('contact_identifier');
    //     } else {
    //         setToast({
    //             color: 'warning',
    //             body: <LocaleMessage msg="label.save_first" />,
    //         });
    //     }
    // }

    // function handleDeleteIdentifier(event, _id) {
    //     setInnerObj(_id);
    //     event.preventDefault();
    //     setDeleteIdentifierOpen(true);
    // }

    // function buildDeleteIdentifierDialog() {
    //     return (
    //         <SimpleDialog
    //             open={deleteIdentifierOpen}
    //             onClose={handleDeleteIdentifierClose}
    //             title={
    //                 <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.contact_identifier.delete" />
    //             }
    //             content={
    //                 <DialogContentText>
    //                     <LocaleMessage msg="message.undone.content" />
    //                 </DialogContentText>
    //             }
    //             actions={[
    //                 {
    //                     key: 'cancel',
    //                     onClick: () => setDeleteIdentifierOpen(false),
    //                     label: <LocaleMessage msg="button.cancel" />,
    //                 },
    //                 {
    //                     key: 'delete',
    //                     onClick: () => deleteIdentifier(),
    //                     label: <LocaleMessage msg="button.delete" />,
    //                 },
    //             ]}
    //         />
    //     );
    // }

    // function renderIdentifierList() {
    //     const headCells = [
    //         {
    //             id: 'contact_name',
    //             label: <LocaleMessage msg="table.headers.to_contact" />,
    //         },
    //         {
    //             id: 'identifiers_str',
    //             label: <LocaleMessage msg="table.headers.identifiers" />,
    //         },
    //         {
    //             id: 'updated',
    //             label: <LocaleMessage msg="table.headers.updated" />,
    //             order_by: 'updated_timestamp',
    //         },
    //     ];

    //     const rowActions = [
    //         {
    //             id: 'delete',
    //             label: <LocaleMessage msg="button.delete" />,
    //             icon: <MdDelete />,
    //             action: handleDeleteIdentifier,
    //         },
    //     ];

    //     const tp_apps = apps.filter(a => {
    //         return a.type_name === 'telepresence';
    //     });

    //     return (
    //         <DataTable
    //             data={identiferList}
    //             orderColumn="name"
    //             headerColumns={headCells}
    //             rowActions={rowActions}
    //             handleTableRowClick={(e, _id) =>
    //                 handleSelectContactIdentifier(e, _id)
    //             }
    //             hasHeader
    //             headerDirection="row"
    //             header={
    //                 <>
    //                     <div className="col-md-4">
    //                         <FormSelect
    //                             options={tp_apps || []}
    //                             labelKey="name"
    //                             label={
    //                                 <LocaleMessage msg="page.applications.conversation.hive_apps.bind" />
    //                             }
    //                             onChange={value => {
    //                                 handleEditSettings('app_id', value);
    //                             }}
    //                             value={telepresence_app || ''}
    //                         />
    //                     </div>
    //                     <div className="col-md-4">
    //                         <Button
    //                             variant="contained"
    //                             color="primary"
    //                             size="large"
    //                             onClick={e =>
    //                                 handleSelectContactIdentifier(e, 'new')
    //                             }
    //                             fullWidth
    //                             disabled={!telepresence_app || hasChange}
    //                         >
    //                             <MdAdd
    //                                 size={20}
    //                                 style={{
    //                                     color: '#fff',
    //                                 }}
    //                             />
    //                             <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.contact_identifier.add" />
    //                         </Button>
    //                     </div>
    //                 </>
    //             }
    //         />
    //     );
    // }

    return (
        <>
            <Divider />
            <div className="sidecard-header mb-3">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.title" />
                </h2>
            </div>
            <div
                className={`row ${
                    !telepresence_active ? 'col-md-8' : ''
                } col-12 mb-5`}
                style={center}
            >
                <div
                    className={`${
                        telepresence_active ? 'col-md-4' : 'col-md-6'
                    } col-12`}
                >
                    <FormSwitch
                        value={telepresence_active || false}
                        onChange={event => {
                            handleEditSettings('active', event.target.checked);
                        }}
                        label={
                            <LocaleMessage msg="page.applications.conversation.hive_apps.telepresence.use" />
                        }
                    />
                </div>
                {telepresence_active ? (
                    <div className="col-md-4">
                        <FormSelect
                            options={tp_apps || []}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.applications.conversation.hive_apps.bind" />
                            }
                            onChange={value => {
                                handleEditSettings('app_id', value);
                            }}
                            value={telepresence_app || ''}
                        />
                    </div>
                ) : null}
            </div>
            <Divider />
        </>
    );
}

TelepresenceAppIntegration.propTypes = {
    notifyChange: PropTypes.func.isRequired,
    apps: PropTypes.array.isRequired,
    telepresenceApp: PropTypes.object.isRequired,
    setTelepresenceApp: PropTypes.func.isRequired,
    // setToast: PropTypes.func.isRequired,
    // handleError: PropTypes.func.isRequired,
    // hasChange: PropTypes.bool.isRequired,
    // appSettings: PropTypes.object.isRequired,
    // setInnerObj: PropTypes.func.isRequired,
    // setOpenSettings: PropTypes.func.isRequired,
};
