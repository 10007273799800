import styled from 'styled-components';

export const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.35vh;

    .level-title {
        color: ${props => {
            return props.active ? '#fff' : '#444';
        }};
        text-align: left;
    }

    .active {
        color: #fff;
    }

    .MuiBadge-root .MuiBadge-dot {
        height: 16px;
        margin: 0px 0.5vw 0px 0.25vw;
        background-color: ${props => {
            return props.active ? props.color : '#444';
        }};
    }

    :not(:last-child) {
        margin-bottom: 0.5vh;
    }
`;
