/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdBatteryChargingFull,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Badge,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from '@material-ui/core';

import { StatusItem } from '../styles';

export default function CustomTableRow(props) {
    const { row, onClick } = props;
    const [open, setOpen] = useState(false);

    function renderRobotInfo(robot) {
        const status = robot.status || {};
        const { battery } = status;
        return (
            <TableRow
                key={robot.id}
                onClick={event => onClick(event, robot.id)}
            >
                <TableCell width="50%" className="row-ellipsis">
                    <StatusItem
                        style={{
                            paddingLeft: '0.15vw',
                            justifyContent: 'start',
                        }}
                    >
                        <Badge
                            className={`status-${
                                status ? status.status : 'disconnected'
                            }`}
                            overlap="rectangular"
                            badgeContent=" "
                            variant="dot"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        />
                        {robot.name}
                    </StatusItem>
                </TableCell>
                <TableCell align="right" width="15%" className="row-ellipsis">
                    {status ? (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <MdBatteryChargingFull
                                size={12}
                                style={{ margin: '0px 0.5vw' }}
                            />
                            {battery &&
                            battery.level &&
                            battery.level != null &&
                            battery.level !== 'None'
                                ? `${battery.level} %`
                                : '---'}
                        </span>
                    ) : (
                        ' '
                    )}
                </TableCell>
                <TableCell align="right" width="35%" className="row-ellipsis">
                    {robot.zone ? robot.zone : '---'}
                </TableCell>
            </TableRow>
        );
    }

    return row.robots && row.robots.length > 0 ? (
        <>
            <TableRow className="outer-row" onClick={() => setOpen(!open)}>
                <TableCell width="60%" className="row-ellipsis">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        style={{ padding: '0.15vh 0px', marginRight: '0.5vw' }}
                    >
                        {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                    </IconButton>
                    {row.name ? row.name : 'No Location'}
                </TableCell>
                <TableCell width="40%" align="right" className="row-ellipsis">
                    {row && row.robots ? row.robots.length : 0}
                </TableCell>
            </TableRow>
            <TableRow className="inner-row">
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    align="right"
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableBody>
                                {row && row.robots
                                    ? row.robots.map(robot =>
                                          renderRobotInfo(robot)
                                      )
                                    : null}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    ) : null;
}

CustomTableRow.propTypes = {
    row: PropTypes.object,
    onClick: PropTypes.func,
};

CustomTableRow.defaultProps = {
    row: {},
    onClick: () => {},
};
