import styled from 'styled-components';

export const AppHeader = styled.div`
    background: ${props => props.theme.palette.primary.main};
    min-height: 56px;
    height: ${props => (props.smallScreen ? '56px' : '70px')}};

    .MuiAppBar-root {
        height: ${props => (props.smallScreen ? '56px' : '70px')}};
        justify-content: center !important;
    }

    .MuiToolbar-root {
        min-height: 56px !important;
        height: ${props => (props.smallScreen ? '56px' : '70px')}};
        justify-content: center !important;
    }

    .app-main-header {
        background: ${props => props.theme.palette.primary.main} !important;
    }
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
`;
