/* eslint-disable react/forbid-prop-types */
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {
    MdSettings,
    MdEdit,
    MdDelete,
    MdClose,
    MdChevronLeft,
    MdChevronRight,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    IconButton,
    Dialog,
    DialogContent,
    Menu,
    MenuItem,
    MobileStepper,
    Paper,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import { Container, Content } from './styles';

export default function CarouselDialog(props) {
    const {
        open,
        media,
        handleCarouselClose,
        current,
        allowed,
        editFile,
        deleteFile,
        type,
    } = props;
    const [currIndex, setCurrIndex] = React.useState(current || 0);

    const maxFiles = media.length;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleNext = prevItem => {
        const nextItem = prevItem >= maxFiles - 1 ? 0 : prevItem + 1;
        setCurrIndex(nextItem);
    };

    const handleBack = prevItem => {
        const nextItem = prevItem > 0 ? prevItem - 1 : maxFiles - 1;
        setCurrIndex(nextItem);
    };

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleEditItem(event, _id) {
        setAnchorEl(null);
        editFile(event, _id);
    }

    function handleDeleteItem(event, _id, operation) {
        setAnchorEl(null);
        switch (operation) {
            case 'edit':
                editFile(event, _id);
                break;
            case 'delete':
                deleteFile(event, _id);
                break;
            default:
        }
    }

    function imgCard(_media) {
        return (
            <div className="image-view">
                <img src={_media.url} alt={_media.id} />
            </div>
        );
    }

    function audioCard(_media) {
        return (
            <div className="audio-view">
                <ReactAudioPlayer src={_media.url} controls />
            </div>
        );
    }

    function renderCard(_media) {
        switch (type) {
            case 'images':
                return imgCard(_media);
            case 'audios':
                return audioCard(_media);
            default:
                return null;
        }
    }

    return media[currIndex] ? (
        <Container>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCarouselClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent
                    style={{
                        padding: '0px',
                        width: '600px',
                        maxWidth: '100%',
                        background: 'rgba(255,255,255,0.2)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Content>
                        <Paper square elevation={0} className="header">
                            <IconButton
                                aria-label="more"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={event =>
                                    setAnchorEl(event.currentTarget)
                                }
                                style={{ padding: '5px' }}
                            >
                                <MdSettings size={20} />
                            </IconButton>
                            <Typography>{media[currIndex].name}</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={handleCarouselClose}
                                style={{ padding: '5px' }}
                            >
                                <MdClose size={20} />
                            </IconButton>
                        </Paper>
                        {renderCard(media[currIndex])}
                        <MobileStepper
                            steps={maxFiles}
                            activeStep={currIndex}
                            position="static"
                            variant="text"
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={() => handleNext(currIndex)}
                                    // disabled={currIndex === maxFiles - 1}
                                >
                                    <LocaleMessage msg="button.next" />
                                    <MdChevronRight size={24} />
                                </Button>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    onClick={() => handleBack(currIndex)}
                                    // disabled={currIndex === 0}
                                >
                                    <MdChevronLeft size={24} />
                                    <LocaleMessage msg="button.back" />
                                </Button>
                            }
                        />
                    </Content>
                </DialogContent>
            </Dialog>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={handleMenuClose}
            >
                {allowed.edit ? (
                    <MenuItem
                        onClick={e =>
                            handleEditItem(e, media[currIndex].id, 'edit')
                        }
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <MdEdit style={{ marginRight: '5px' }} />
                        <LocaleMessage msg="button.edit" />
                    </MenuItem>
                ) : null}
                {allowed.delete ? (
                    <MenuItem
                        onClick={e =>
                            handleDeleteItem(e, media[currIndex].id, 'delete')
                        }
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <MdDelete style={{ marginRight: '5px' }} />
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                ) : null}
            </Menu>
        </Container>
    ) : null;
}

CarouselDialog.defaultProps = {
    deleteFile: null,
    editFile: null,
    type: 'images',
};

CarouselDialog.propTypes = {
    allowed: PropTypes.object.isRequired,
    deleteFile: PropTypes.func,
    editFile: PropTypes.func,
    handleCarouselClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    media: PropTypes.array.isRequired,
    current: PropTypes.number.isRequired,
    type: PropTypes.string,
};
