import { darken } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `linear-gradient(45deg, ${darken(0.25, color)}, ${color})`;
    }};
    display: flex;
    justify-content: center;
    align-items: center;

    .app-logo-content {
        background: ${props => props.theme.palette.primary.main};
    }
`;
