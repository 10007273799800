/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import SectionsList from '~/components/Sections/List';

import CheckGroupPermission from '~/util/CheckGroupPermission';

export default function Sections({ match }) {
    const user = useSelector(state => state.user);

    const { id } = match.params;
    const permissions = CheckGroupPermission(user || {}, id);

    const allowNew = permissions.allow;

    return (
        <SectionsList
            breadcrumbs={[
                {
                    url: '/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
            ]}
            allowNew={allowNew}
            linkRoot={`/groups/${id}`}
            filter={`?group=${id}`}
            infos={{ members: true, permission: true }}
        />
    );
}

Sections.propTypes = {
    match: PropTypes.object.isRequired,
};
