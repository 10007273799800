/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportQuantityChart from '~/components/Reports/ReportQuantityChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import {
    TotalContainer,
    ChartsContainer,
    InfoContainer,
    TableArea,
    RowArea,
} from '../../style';

export default function DanceRuns({
    settings,
    headerSettings,
    requestError,
    counterData,
    language,
}) {
    const lng_all = lng_labels[language] || {};

    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [subTables, setSubTables] = useState({});

    const headers_file = {
        main: [
            { key: 'group_name', label: 'Group' },
            { key: 'settings_name', label: 'Settings' },
            { key: 'robot_name', label: 'Robot' },
            { key: 'application_name', label: 'Application' },
            {
                key: 'start_date',
                label: 'Start Date',
            },
            {
                key: 'start_time',
                label: 'Start Time',
            },
        ],
        sub: [
            { key: 'start_date', label: 'Execution Date' },
            { key: 'start_time', label: 'Execution Time' },
            { key: 'dance_name', label: 'Dance' },
            { key: 'dance_type_label', label: 'Type' },
        ],
    };

    const headers_table = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'settings_name',
            label: (
                <LocaleMessage msg="page.reports.actions.dance_runs.label.settings" />
            ),
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'dances_length',
            label: (
                <LocaleMessage msg="page.reports.actions.dance_runs.label.runs" />
            ),
        },
    ];

    const headers_sub = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'dance_name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'dance_type_label',
            label: <LocaleMessage msg="table.headers.type" />,
        },
    ];

    const header_dances = [
        {
            id: 'name',
            label: (
                <LocaleMessage msg="page.reports.actions.dance_runs.label.dance" />
            ),
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    function getSubList(_id, rows) {
        return {
            id: _id,
            headers: headers_sub,
            rows,
        };
    }

    function filterData(data) {
        const dance_list = {};
        const sub_list = {};

        const { robots, robot_types, groups, applications } = counterData;
        const { dance_runs, period } = data;

        if (!dance_runs) return;

        const sessions_list = dance_runs ? dance_runs.list : [];

        const d_groups = [];
        const d_robots = [];
        const d_r_types = [];
        const d_applications = [];
        const d_configs = [];
        const d_quantity = [];
        const d_s_date = [];
        const d_s_time = [];

        const d_runs = [];
        const d_dances = [];
        const d_d_date = [];
        const d_d_time = [];

        let max_quantity = 0;

        const s_list = sessions_list.map(s => {
            const start = GetDateTimeLabel(new Date(s.created_at), { format });

            const robot = robots[s.robot_id];
            const r_type = robot_types[s.robottype_id];
            const group = groups[s.group_id];
            const application = applications[s.application_id];

            if (!robots[s.robot_id] || robots[s.robot_id].code === '---') {
                robots[s.robot_id] = { code: s.robot_code || '---', name: '' };
            }

            d_groups[s.group_id] = {
                total: d_groups[s.group_id]
                    ? d_groups[s.group_id].total + 1
                    : 1,
            };

            d_robots[s.robot_id] = {
                total: d_robots[s.robot_id]
                    ? d_robots[s.robot_id].total + 1
                    : 1,
            };

            d_r_types[s.robottype_id] = d_r_types[s.robottype_id]
                ? d_r_types[s.robottype_id] + 1
                : 1;

            d_applications[s.application_id] = {
                total: d_applications[s.application_id]
                    ? d_applications[s.application_id].total + 1
                    : 1,
            };
            const pluginconfig_id = s.pluginconfig ? s.pluginconfig.id : '---';
            const pluginconfig_name = s.pluginconfig
                ? s.pluginconfig.name
                : '---';

            d_configs[pluginconfig_id] = {
                total: d_configs[pluginconfig_id]
                    ? d_configs[pluginconfig_id].total + 1
                    : 1,
                name: pluginconfig_name,
            };

            d_s_date[start.date] = d_s_date[start.date]
                ? d_s_date[start.date] + 1
                : 1;
            d_s_time[start.hour] = d_s_time[start.hour]
                ? d_s_time[start.hour] + 1
                : 1;

            const session_runs = s.runs || [];
            const session_qty = session_runs.length || 0;
            max_quantity =
                session_qty > max_quantity ? session_qty : max_quantity;
            d_quantity[session_qty] = {
                total: d_quantity[session_qty]
                    ? d_quantity[session_qty].total + 1
                    : 1,
                name: session_qty,
            };

            const d_s_runs = session_runs.map(r => {
                const r_start = GetDateTimeLabel(new Date(r.created_at), {
                    format,
                });

                const r_dance = r.dance || {};

                d_dances[r_dance.id] = {
                    total: d_dances[r_dance.id]
                        ? d_dances[r_dance.id].total + 1
                        : 1,
                    name: r_dance.name,
                };

                d_d_date[r_start.date] = d_d_date[r_start.date]
                    ? d_d_date[r_start.date] + 1
                    : 1;
                d_d_time[r_start.hour] = d_d_time[r_start.hour]
                    ? d_d_time[r_start.hour] + 1
                    : 1;

                const d_type = r_dance.type;
                const d_type_label = `page.dances.type.${d_type}`;

                const dance = {
                    id: r.id,
                    dance_id: r_dance.id,
                    dance_name: r_dance.name,
                    dance_type: d_type,
                    dance_type_label:
                        lng_all[d_type_label] || d_type.toUpperCase(),
                    start_date: r_start.date,
                    start_time: r_start.time,
                    start: r_start.string,
                    start_timestamp: r_start.timestamp,
                };

                d_runs.push(dance);

                return dance;
            });

            dance_list[s.id] = d_s_runs;
            sub_list[s.id] = getSubList(s.id, d_s_runs);

            return {
                ...s,
                id: s.id,
                group_name: group ? group.name : '---',
                robot_name: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${s.robot_code || '---'}]`,
                application_name: application ? application.name : '---',
                settings_name: pluginconfig_name,
                robottype: r_type ? r_type.name : '---',
                dances_length: s.runs ? s.runs.length : 0,
                dances: d_s_runs,
                start_date: start.date,
                start_time: start.time,
                start: start.string,
                start_timestamp: start.timestamp,
            };
        });

        const group_grouped = Object.keys(d_groups).map(g => {
            const val = d_groups[g];
            const obj = groups[g];
            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
            };
        });

        const robot_grouped = Object.keys(d_robots).map(r => {
            const val = d_robots[r];
            const obj = robots[r];
            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                total: val ? val.total : 0,
            };
        });

        const rtype_grouped = Object.keys(d_r_types).map(t => {
            const val = d_r_types[t];
            const obj = robot_types[t];
            return {
                name: obj ? obj.name : '---',
                total: val,
            };
        });

        const application_grouped = Object.keys(d_applications).map(a => {
            const val = d_applications[a];
            const obj = applications[a];
            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
            };
        });

        const config_grouped = Object.keys(d_configs).map(s => {
            const val = d_configs[s];
            return {
                id: s,
                name: val ? val.name : '---',
                total: val ? val.total : 0,
            };
        });

        const dance_grouped = Object.keys(d_dances).map(d => {
            const val = d_dances[d];
            return {
                id: d,
                name: val ? val.name : '---',
                total: val ? val.total : 0,
            };
        });

        const quantity_grouped = Object.keys(d_quantity).map(d => {
            const val = d_quantity[d];
            return {
                id: d,
                name: `${d} ${lng_all['page.reports.actions.dance_runs.label.run']}`,
                total: val ? val.total : 0,
            };
        });

        const period_dates = GetPeriodDates(period);
        const s_labels = [];
        const s_date_grouped = [];
        const r_date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            s_labels.push(label_date);
            s_date_grouped.push({
                date: label_date,
                value: d_s_date[formatted_date] || 0,
            });
            r_date_grouped.push({
                date: label_date,
                value: d_d_date[formatted_date] || 0,
            });
        });
        const t_labels = [];
        const s_time_grouped = [];
        const r_time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);
            s_time_grouped.push({
                hour: label_time,
                value: d_s_time[t] || 0,
            });
            r_time_grouped.push({
                hour: label_time,
                value: d_d_time[t] || 0,
            });
        }
        setGroupedData({
            groups: group_grouped,
            robots: robot_grouped,
            robot_types: rtype_grouped,
            applications: application_grouped,
            configs: config_grouped,
            dances: dance_grouped,
            runs: d_runs,
            session_date: s_date_grouped,
            session_time: s_time_grouped,
            runs_date: r_date_grouped,
            runs_time: r_time_grouped,
            s_labels,
            t_labels,
            max_quantity,
            quantity: quantity_grouped,
            quantity_obj: d_quantity,
        });
        setList(s_list);
        setSubTables({});
        // setSubTables(sub_list);
    }

    async function loadData() {
        setIsLoading(true);

        const url = `reports/dance_runs?from=${fromISO}&until=${untilISO}`;
        await api
            .get(url)
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function onRowClick(event, _id) {
        const session = list.find(i => {
            return i.id === _id;
        });

        if (session) {
            const sub = !subTables[_id]
                ? getSubList(_id, session.dances)
                : false;

            setSubTables({
                ...subTables,
                [_id]: sub,
            });
        }
    }

    function getSubValues(l, subKey) {
        const full_list = [];

        const sub_headers = headers_file.sub || [];
        const sub_headers_file = sub_headers.map(h => {
            return {
                ...h,
                key: `${subKey}_${h.key}`,
            };
        });

        list.forEach(r => {
            full_list.push(r);

            const sub_list = r[subKey] || [];
            sub_list.forEach(v => {
                const sub_obj = {};
                sub_headers.forEach((k, i) => {
                    const sub_h = sub_headers[i] || {};
                    const file_h = sub_headers_file[i] || {};
                    sub_obj[file_h.key] = v[sub_h.key];
                });
                full_list.push(sub_obj);
            });
        });

        return {
            headers: [...headers_file.main, ...sub_headers_file],
            list: full_list,
        };
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '200px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="start_timestamp"
                    handleTableRowClick={(event, _id) => onRowClick(event, _id)}
                    hasActions={false}
                    sortable
                    hasFilter
                    subTables={subTables}
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildQuantityChart(title, data) {
        const quantity = [];
        for (let i = 0; i <= groupedData.max_quantity; i++) {
            quantity.push({
                label: i,
                value: data[i] ? data[i].total : 0,
            });
        }

        return (
            <ReportQuantityChart
                title={title}
                color={colors.dashboard_cards}
                data={quantity}
                dataKey="value"
                xAxisKey="label"
                classes="col-md-10 col-12 mb-3"
            />
        );
    }

    function renderCSVButton() {
        const full_list = getSubValues(list, 'dances');

        return (
            <ExportCSVButton
                data={full_list.list}
                headers={full_list.headers}
                filename={GetFileName(`REPORTS-action_dances`, 'csv')}
            />
        );
    }

    useEffect(() => {
        filterData(rawData);
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const show_group_card = active && active.id === '*';

    const def_class = `col-md-4 col-12 mb-5`;

    const session_pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'sessions.per_group',
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'sessions.per_application',
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'sessions.per_robot',
            data: groupedData.robots,
        },
        {
            key: 'configs',
            title: 'sessions.per_dance_pack',
            data: groupedData.configs,
        },
    ];

    const dance_sessions = list ? list.length : 0;
    const dance_runs =
        groupedData && groupedData.runs ? groupedData.runs.length : 0;

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={renderCSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.dance_runs.label.sessions" />
                                }
                                value={dance_sessions}
                            />
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.dance_runs.label.total" />
                                }
                                value={dance_runs}
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <div className="mt-3">
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.actions.dance_runs.label.sessions.title" />
                                </p>
                            </div>
                        </InfoContainer>
                        <ChartsContainer className="row mt-5 mb-3">
                            {session_pie_charts.map((c, idx) => {
                                return (
                                    <ReportPieChart
                                        key={`${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.dance_runs.label.${c.title}`}
                                            />
                                        }
                                        color={
                                            idx % 2 === 0
                                                ? colors.dashboard_cards
                                                : colors.dashboard_graphs
                                        }
                                        data={c.data}
                                        dataKey="total"
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.dance_runs.label.sessions.per_date" />,
                                groupedData.session_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.dance_runs.label.sessions.per_hour" />,
                                groupedData.session_time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <div className="mt-3">
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.actions.dance_runs.label.runs.per_dance" />
                                </p>
                            </div>
                            <ChartsContainer className="row mt-5 mb-3">
                                <ReportPieChart
                                    classes="col-md-5 col-12 mb-5"
                                    color={colors.dashboard_graphs}
                                    data={groupedData.dances}
                                    dataKey="total"
                                    customization={{
                                        outerRadius: 90,
                                        innerRadius: 50,
                                        height: 300,
                                    }}
                                />
                                <TableArea className="col-md-5 col-12 mb-3 table-responsive-material">
                                    <DataTable
                                        maxHeight="100%"
                                        sortable
                                        orderDirection="desc"
                                        orderColumn="total"
                                        headerColumns={header_dances}
                                        data={groupedData.dances || []}
                                        hasActions={false}
                                        hasFooter={false}
                                        defaultRowsPerPage={10}
                                        headerData={{
                                            id: null,
                                            name: (
                                                <LocaleMessage msg="page.reports.actions.dance_runs.label.total" />
                                            ),
                                            total: dance_runs,
                                        }}
                                    />
                                </TableArea>
                            </ChartsContainer>
                        </InfoContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.dance_runs.label.runs.per_date" />,
                                groupedData.runs_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.dance_runs.label.runs.per_hour" />,
                                groupedData.runs_time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            {buildQuantityChart(
                                <LocaleMessage msg="page.reports.actions.dance_runs.label.runs.per_session" />,
                                groupedData.quantity_obj
                            )}
                        </InfoContainer>
                        <Divider />
                        <RowArea className="mt-3 mb-5">
                            <div className="mt-3">
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.actions.dance_runs.label.all" />
                                </p>
                            </div>
                            {buildListView()}
                        </RowArea>
                    </>
                )}
            </div>
        </>
    );
}

DanceRuns.defaultProps = {
    language: 'pt_BR',
};

DanceRuns.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    language: PropTypes.string,
};
