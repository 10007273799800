/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAdd, MdDelete, MdWarningAmber } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { Button, DialogContentText } from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';
import lng_labels from '~/util/LangMessages';

import TriggerIdentifier from './TriggerIdentifier';

const identifier_type = {
    mapNavigation: 'map_point',
    runScript: 'script',
    callTelepresenceIntegration: 'contact',
};

export default function IdentifiersConfig({
    setToast,
    handleError,
    robotTypes,
    appSettings,
    currType,
    setCurrType,
}) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const loc = _settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];
    const any_subject_tag =
        lng_all['page.applications.conversation.identifiers.label.subject.any'];

    const { settings, group, application_content } = appSettings;
    const group_id = group ? group.id : '*';

    const app_languages =
        settings && settings.language ? settings.language : {};

    const map_navigation_settings =
        settings && settings.map_navigation ? settings.map_navigation : {};

    const { dialog_id } = application_content;

    const [isLoading, setIsLoading] = useState(true);
    const [needSaving] = useState(false);

    const [actionList, setActionList] = useState([]);
    const [identiferList, setIdentiferList] = useState([]);
    const [currIdentifier, setCurrIdentifier] = useState('new');
    const [deleteIdentifierOpen, setDeleteIdentifierOpen] = useState(false);
    const [identifierOpen, setIdentifierOpen] = useState(false);

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    function getItemDetails(item) {
        const r_type = currType && currType.slug ? currType.slug : 'all';

        const { type } = item;
        const identifiers =
            item.identifiers.map(i => {
                return i.value === '**' ? any_subject_tag : i.value;
            }) || [];
        const identifiers_str = identifiers.join(', ');
        const updated = new Date(item.updated);
        const destination = item[type] || {};
        const destination_name = destination.name || '---';
        const action_name = item.action ? item.action.name : '';

        let alert = '';
        if (item.map_point) {
            const rtype_navigation = map_navigation_settings[r_type] || {};
            const action_point =
                item.map_point && item.map_point.id ? item.map_point.id : '';
            const action_map =
                item.map_point && item.map_point.map_layer_id
                    ? item.map_point.map_layer_id
                    : '';
            if (
                !rtype_navigation ||
                rtype_navigation.map_layer_id !== action_map ||
                !action_point
            ) {
                alert = true;
            }
        }

        const label = (
            <>
                {action_name ? (
                    <>
                        <span>
                            {alert ? (
                                <MdWarningAmber
                                    size={20}
                                    className="mr-2"
                                    color="#f00"
                                />
                            ) : null}
                            <LocaleMessage msg={action_name} />
                        </span>
                        <span>: </span>
                    </>
                ) : null}
                <span>{destination_name.toUpperCase()}</span>
            </>
        );

        return {
            ...item,
            action: label,
            action_order: `${action_name} - ${destination_name}`,
            type: item.type.toUpperCase(),
            identifiers_str,
            updated: formatDistance(updated, new Date(), {
                addSuffix: true,
                locale: date_loc,
            }),
            updated_timestamp: updated.toISOString(),
        };
    }

    async function loadTriggers() {
        if (!dialog_id) {
            setToast({
                color: 'error',
                body: (
                    <LocaleMessage msg="page.applications.conversation.triggers.error.application_content" />
                ),
            });
            return;
        }
        await api
            .get(`triggers?dialog_id=${dialog_id}`)
            .then(response => {
                const filtered = response.data.filter(t => {
                    return t.group_id === group_id;
                });

                const data = {};
                filtered.forEach(t => {
                    const { action_id } = t;
                    const action_key = t.function_name;
                    if (!t.require_config && identifier_type[action_key]) {
                        data[action_id] = {
                            id: action_id,
                            action: action_key,
                            label: t.action,
                            identifier_type: identifier_type[action_key] || '',
                        };
                    }
                });

                const actions = Object.keys(data).map(a => {
                    return data[a];
                });

                setActionList(actions);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadIdentifiers(r_id) {
        if (r_id) {
            setIsLoading(true);
            await api
                .get(
                    `conversation_identifiers?application=${appSettings.id}&dialog=${dialog_id}&robot_type=${r_id}`
                )
                .then(async response => {
                    const data = response.data.map(item => {
                        return getItemDetails(item);
                    });
                    setIdentiferList(data);
                })
                .catch(error => requestError(error));
        } else {
            setIdentiferList([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadTriggers();
    }, []);

    useEffect(() => {
        loadIdentifiers(currType.id);
    }, [currType]);

    function closeIdentifierPage() {
        setIdentifierOpen(false);
        loadIdentifiers(currType.id);
    }

    async function deleteIdentifier() {
        setIsLoading(true);
        setDeleteIdentifierOpen(false);
        await api
            .delete(`conversation_identifiers/${currIdentifier}`)
            .then(async () => {
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.conversation.triggers.identifiers.deleted" />
                    ),
                });
                await loadIdentifiers(currType.id);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    function handleDeleteIdentifierClose(event) {
        event.preventDefault();
        setDeleteIdentifierOpen(false);
    }

    function handleIdentifierSelection(event, _id) {
        setCurrIdentifier(_id);
        if (!needSaving) {
            setIdentifierOpen(true);
        } else {
            setToast({
                color: 'warning',
                body: <LocaleMessage msg="label.save_first" />,
            });
        }
    }

    function handleDeleteIdentifier(event, _id) {
        setCurrIdentifier(_id);
        event.preventDefault();
        setDeleteIdentifierOpen(true);
    }

    function renderDeleteIdentifierDialog() {
        return (
            <SimpleDialog
                open={deleteIdentifierOpen}
                onClose={handleDeleteIdentifierClose}
                title={
                    <LocaleMessage msg="page.applications.conversation.triggers.identifiers.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteIdentifierOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteIdentifier(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderIdentifierList() {
        const headCells = [
            {
                id: 'action',
                order_by: 'action_order',
                label: <LocaleMessage msg="table.headers.action" />,
            },
            {
                id: 'identifiers_str',
                label: <LocaleMessage msg="table.headers.identifiers" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteIdentifier,
            },
        ];

        return (
            <div className="col-12 mb-5">
                <DataTable
                    data={identiferList}
                    orderColumn="action_order"
                    headerColumns={headCells}
                    rowActions={rowActions}
                    handleTableRowClick={(event, _id) =>
                        handleIdentifierSelection(event, _id)
                    }
                    hasHeader
                    header={
                        <>
                            <div className="col-md-8 col-12 px-0">
                                <h2 className="mb-3">
                                    <LocaleMessage msg="page.applications.conversation.triggers.identifiers.list" />
                                </h2>
                            </div>
                            <div className="col-md-4 col-12 px-0">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={event =>
                                        handleIdentifierSelection(event, 'new')
                                    }
                                    disabled={needSaving}
                                >
                                    <MdAdd
                                        size={20}
                                        style={{
                                            color: '#fff',
                                        }}
                                    />
                                    <LocaleMessage msg="page.applications.conversation.triggers.identifiers.add" />
                                </Button>
                            </div>
                        </>
                    }
                    headerDirection="row"
                />
            </div>
        );
    }

    function renderIdentifierSettings() {
        const identifier_settings = {
            id: currIdentifier,
            dialog_id,
            application_id: appSettings.id,
            robot_type: currType.id,
        };

        return (
            <div className="col-12">
                <TriggerIdentifier
                    languages={app_languages}
                    robotType={currType}
                    appSettings={appSettings}
                    actionList={actionList}
                    settings={identifier_settings}
                    setToast={msg => setToast(msg)}
                    handleError={err => requestError(err)}
                    closePage={() => closeIdentifierPage()}
                />
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            {deleteIdentifierOpen ? renderDeleteIdentifierDialog() : null}
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.triggers.identifiers.title" />
                </h2>
            </div>
            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 25px',
                }}
            >
                <br />
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.tutorial" />
                <br />
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.caution" />
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {identifierOpen ? (
                            renderIdentifierSettings()
                        ) : (
                            <div className="col-12">
                                <AppConfigRobotType
                                    className="col-12 mb-5"
                                    robotTypes={robotTypes}
                                    currType={currType}
                                    setCurrType={t => setCurrType(t)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {renderIdentifierList()}
                                    </div>
                                </AppConfigRobotType>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

IdentifiersConfig.propTypes = {
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
