import api from '~/services/pluginbot-api';

export async function joinCall(call, errorHandler) {
    if (!call) return false;
    const { join } = call;
    if (!join) {
        errorHandler({ code: 'invalid_call' });
    }

    switch (join.type) {
        case 'url': {
            const { url, self } = join;
            return window.open(url, self ? '_self' : '_blank');
        }
        default:
            return false;
    }
}

export async function connectCall(params, errorHandler) {
    const { robot } = params;
    if (!robot) return false;

    await api
        .post(`apps/telepresence/calls/access`, {
            robot_id: robot.id,
        })
        .then(response => {
            return joinCall(response.data, errorHandler);
        })
        .catch(error => errorHandler(error));
    return true;
}

export async function answerCall(params, errorHandler) {
    const { alert_id, accept } = params;
    const body = {
        accept,
    };
    await api
        .post(`apps/telepresence/calls/answer/${alert_id}`, body)
        .then(response => {
            if (accept) {
                joinCall(response.data, errorHandler);
            }
        })
        .catch(error => errorHandler(error));
}
