/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import { MdSearch, MdClose } from 'react-icons/md';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import {
    Checkbox,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function DataTable({
    data,
    sortable,
    orderDirection,
    orderColumn,
    headerColumns,
    handleTableRowClick,
    hasActions,
    rowActions,
    hasFilter,
    hasHeader,
    header,
    headerData,
    headerDirection,
    hasFooter,
    defaultRowsPerPage,
    subTables,
    maxHeight,
    selectable,
    selectedRows,
    setSelectedRows,
    selectedActions,
}) {
    const [rawData, setRawData] = useState(data);
    const [order, setOrder] = useState(orderDirection);
    const [orderBy, setOrderBy] = useState(orderColumn);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 25);
    const [filter, setFilter] = useState('');
    const [filterStr, setFilterStr] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    function removeItemFromSelectedArray(item_id) {
        setSelectedRows(
            selectedRows.filter(i => {
                return i !== item_id;
            })
        );
    }

    function addItemToSelectedArray(item_id) {
        setSelectedRows([...selectedRows, item_id]);
    }

    function clearSelectedArray() {
        setSelectedRows([]);
    }

    function addAllToSelectedArray() {
        const sel = filteredData.map(i => {
            return i.id;
        });
        setSelectedRows(sel);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function desc(a, b, _orderBy) {
        if (b[_orderBy] < a[_orderBy]) {
            return -1;
        }
        if (b[_orderBy] > a[_orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const _order = cmp(a[0], b[0]);
            if (_order !== 0) return _order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function getSorting(_order, _orderBy) {
        return _order === 'desc'
            ? (a, b) => desc(a, b, _orderBy)
            : (a, b) => -desc(a, b, _orderBy);
    }

    function handleRequestSort(event, property) {
        if (sortable) {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }
    }

    function filterData(val) {
        if (val === '') {
            setFilteredData(data);
            return;
        }
        const VAL = val.toUpperCase();
        const f_data = data.filter(row => {
            return headerColumns.find(column => {
                if (column.id !== 'id') {
                    const d = row[column.id];
                    if (Array.isArray(d)) {
                        return (
                            d.findIndex(element =>
                                element.toUpperCase().includes(VAL)
                            ) !== -1
                        );
                    }
                    if (
                        String(d)
                            .toUpperCase()
                            .includes(VAL)
                    ) {
                        return true;
                    }
                }
                return false;
            });
        });
        setFilteredData(f_data);
    }

    const waitForFilter = useCallback(
        debounce(val => setFilter(val), 300),
        []
    );

    useEffect(() => {
        setRawData(data);
    }, [data]);

    useEffect(() => {
        filterData(filter);
    }, [rawData, filter]);

    function handleFilterChange(val) {
        setFilterStr(val);
        waitForFilter(val);
    }

    function isOrderingHeader(headCell) {
        return (
            orderBy === headCell.id ||
            (headCell.order_by && orderBy === headCell.order_by)
        );
    }

    function renderSubTable(table) {
        const headers = table.headers || [];
        const rows = table.rows || [];

        return rows.length > 0 ? (
            <TableRow>
                <TableCell colSpan={12}>
                    <Table>
                        {table.title ? (
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>
                                        {table.title}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        ) : null}
                        <TableHead>
                            <TableRow>
                                {headers.map(h => (
                                    <TableCell
                                        key={`sub_${table.id}_${h.id}`}
                                        width={h.width ? h.width : 'auto'}
                                    >
                                        {h.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(r => {
                                return (
                                    <TableRow key={`sub_${table.id}_${r.id}`}>
                                        {headers.map(field => (
                                            <TableCell
                                                key={`sub_${table.id}_${r.id}_${field.id}`}
                                            >
                                                {r[field.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableCell>
            </TableRow>
        ) : null;
    }

    function parseCondition(row, condition) {
        const { key, value, mode } = condition;
        const item_value = key ? `${row[key]}` : `false`;
        const str_value = `${value}`;

        switch (mode) {
            case 'equal': {
                return item_value === str_value;
            }
            case 'different': {
                return item_value !== str_value;
            }
            default:
                return false;
        }
    }

    function headerAction(action) {
        return (
            <IconButton
                key={`header__${action.id}`}
                aria-label={action.label}
                size="small"
                style={{
                    marginLeft: '10px',
                }}
                onClick={event => action.action(event)}
            >
                {action.icon}
            </IconButton>
        );
    }

    function getAction(row, action) {
        const disable_conditions = action.disable_conditions || [];

        const disable = disable_conditions.find(c => {
            return parseCondition(row, c);
        });

        return !disable ? (
            <Tooltip key={`${row.id}__${action.id}`} title={action.label}>
                <IconButton
                    aria-label={action.label}
                    size="small"
                    style={{
                        marginLeft: '10px',
                    }}
                    onClick={event => {
                        event.stopPropagation();
                        action.action(event, row.id);
                    }}
                >
                    {action.icon}
                </IconButton>
            </Tooltip>
        ) : null;
    }

    const showSelAction = selectedActions && selectedRows.length > 0;

    return (
        <>
            {hasFilter || hasHeader ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '10px 0px',
                        flexDirection: headerDirection,
                    }}
                    className="mb-3"
                >
                    {hasHeader ? header : null}
                    {hasFilter ? (
                        <TextField
                            id="outlined-basic"
                            label={<LocaleMessage msg="table.label.filter" />}
                            value={filterStr}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch
                                            size={20}
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.87)',
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {filter !== '' ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    style={{
                                                        marginLeft: '10px',
                                                        color:
                                                            'rgba(0, 0, 0, 0.87)',
                                                    }}
                                                    onClick={() =>
                                                        handleFilterChange('')
                                                    }
                                                >
                                                    <MdClose />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null}
                                    </>
                                ),
                            }}
                            onChange={event =>
                                handleFilterChange(event.target.value)
                            }
                        />
                    ) : null}
                </div>
            ) : null}
            {headerData ? (
                <TableContainer className="bordered-table" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow
                                className="data-row"
                                tabIndex={-1}
                                hover
                                onClick={event =>
                                    handleTableRowClick(
                                        event,
                                        headerData.id || null
                                    )
                                }
                            >
                                {headerColumns.map(field => (
                                    <TableCell
                                        key={`header_data_${field.id}`}
                                        align={field.align}
                                    >
                                        {headerData[field.id]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            ) : null}
            <TableContainer className="bordered-table" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {selectable ? (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color={
                                            selectedRows.length ===
                                            filteredData.length
                                                ? 'primary'
                                                : 'default'
                                        }
                                        checked={selectedRows.length !== 0}
                                        onClick={event => {
                                            event.stopPropagation();

                                            if (selectedRows.length !== 0) {
                                                clearSelectedArray();
                                            } else {
                                                addAllToSelectedArray();
                                            }
                                        }}
                                    />
                                </TableCell>
                            ) : null}
                            {headerColumns.map(headCell => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={
                                        isOrderingHeader(headCell)
                                            ? order
                                            : false
                                    }
                                    width={
                                        headCell.width ? headCell.width : 'auto'
                                    }
                                    align={headCell.align}
                                >
                                    {sortable ? (
                                        <TableSortLabel
                                            active={isOrderingHeader(headCell)}
                                            direction={
                                                isOrderingHeader(headCell)
                                                    ? order
                                                    : 'asc'
                                            }
                                            onClick={event =>
                                                handleRequestSort(
                                                    event,
                                                    headCell.order_by ||
                                                        headCell.id
                                                )
                                            }
                                        >
                                            {headCell.label}
                                        </TableSortLabel>
                                    ) : (
                                        headCell.label
                                    )}
                                </TableCell>
                            ))}
                            {hasActions ? (
                                <TableCell align="right">
                                    {showSelAction || selectedActions ? (
                                        <>
                                            {selectedRows.length ? (
                                                <>
                                                    <span>
                                                        {selectedRows.length}
                                                    </span>
                                                    {selectedActions.map(
                                                        action =>
                                                            headerAction(action)
                                                    )}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ maxHeight: maxHeight || '300px' }}>
                        {stableSort(filteredData, getSorting(order, orderBy))
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map(row => {
                                const sub = subTables[row.id];
                                const isItemSelected = selectedRows.includes(
                                    row.id
                                );
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            className="data-row"
                                            hover
                                            tabIndex={-1}
                                            onClick={event =>
                                                handleTableRowClick(
                                                    event,
                                                    row.id
                                                )
                                            }
                                        >
                                            {selectable ? (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            if (
                                                                event.target
                                                                    .checked
                                                            ) {
                                                                addItemToSelectedArray(
                                                                    row.id
                                                                );
                                                            } else {
                                                                removeItemFromSelectedArray(
                                                                    row.id
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                            ) : null}
                                            {headerColumns.map(field => {
                                                return (
                                                    <TableCell
                                                        key={`${row.id}_${field.id}`}
                                                        width={
                                                            field.width
                                                                ? field.width
                                                                : 'auto'
                                                        }
                                                        align={field.align}
                                                    >
                                                        {row[field.id]}
                                                    </TableCell>
                                                );
                                            })}
                                            {hasActions ? (
                                                <TableCell
                                                    key={`${row.id}_actions`}
                                                    align="right"
                                                >
                                                    {!showSelAction
                                                        ? rowActions.map(
                                                              action =>
                                                                  getAction(
                                                                      row,
                                                                      action
                                                                  )
                                                          )
                                                        : null}
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                        {sub ? renderSubTable(sub) : null}
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                </Table>
                {hasFooter ? (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                ) : null}
            </TableContainer>
        </>
    );
}

DataTable.defaultProps = {
    hasActions: true,
    hasFilter: false,
    hasHeader: false,
    hasFooter: true,
    sortable: true,
    rowActions: [],
    orderDirection: 'asc',
    orderColumn: '',
    header: <></>,
    defaultRowsPerPage: 25,
    headerDirection: 'row',
    handleTableRowClick: () => {},
    subTables: {},
    headerData: null,
    selectable: false,
    selectedRows: [],
    setSelectedRows: () => {},
    selectedActions: [],
    maxHeight: '300px',
};

DataTable.propTypes = {
    maxHeight: PropTypes.string,
    sortable: PropTypes.bool,
    hasActions: PropTypes.bool,
    hasFilter: PropTypes.bool,
    hasFooter: PropTypes.bool,
    data: PropTypes.array.isRequired,
    headerColumns: PropTypes.array.isRequired,
    rowActions: PropTypes.array,
    orderDirection: PropTypes.string,
    orderColumn: PropTypes.string,
    handleTableRowClick: PropTypes.func,
    hasHeader: PropTypes.bool,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultRowsPerPage: PropTypes.number,
    headerDirection: PropTypes.string,
    subTables: PropTypes.object,
    headerData: PropTypes.object,
    selectable: PropTypes.bool,
    selectedRows: PropTypes.array,
    setSelectedRows: PropTypes.func,
    selectedActions: PropTypes.array,
};
