/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    MdMap,
    MdCloudUpload,
    MdCode,
    MdCreate,
    MdRemove,
    MdCheckBoxOutlineBlank,
    MdPanTool,
    MdClose,
    MdImage,
    MdPhotoSizeSelectLarge,
    MdRotateLeft,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, ButtonGroup, Tooltip } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { EditorArea, MapArea, ToolArea } from './styles';

export default function MapIllustration(props) {
    const dispatch = useDispatch();

    const { match } = props;
    const { id } = match.params;

    const size = {
        width: 1000,
        height: 1000,
    };
    const lineWidth = 3;

    const [body, setBody] = useState(null);
    const [imgProps, setImgProps] = useState({});
    const [file, setFile] = useState({
        id: null,
        url: null,
    });

    const [zoom, setZoom] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [currTool, setCurrTool] = useState('');
    const [bgProps, setBGProps] = useState({});

    const [drag, setDrag] = useState({});
    const [currObject, setCurrObject] = useState(null);
    const [objects, setObjects] = useState([]);

    const svgRef = React.createRef();
    const hiddenFileInput = React.useRef(null);

    const buttons = [
        {
            key: 'save_map',
            icon: MdCloudUpload,
            label: 'Save Map',
        },
        {
            key: 'insert_line',
            icon: MdRemove,
            label: 'Insert Line',
        },
        {
            key: 'insert_rect',
            icon: MdCheckBoxOutlineBlank,
            label: 'Insert Rect',
        },
        {
            key: 'edit',
            icon: MdCreate,
            label: 'Edit Point',
        },
        {
            key: 'move',
            icon: MdPanTool,
            label: 'Edit Wall',
        },
        {
            key: 'delete',
            icon: MdClose,
            label: 'Remove Wall',
        },
        {
            key: 'image',
            icon: MdImage,
            label: 'Add Background Image',
        },
        {
            key: 'resize_image',
            icon: MdPhotoSizeSelectLarge,
            label: 'Resize Image',
        },
        {
            key: 'rotate_image',
            icon: MdRotateLeft,
            label: 'Rotate Image',
        },
        // {
        //     key: 'zoom_in',
        //     icon: MdZoomIn,
        //     label: 'Zoom In',
        // },
        // {
        //     key: 'zoom_out',
        //     icon: MdZoomOut,
        //     label: 'Zoom Out',
        // },
        // {
        //     key: 'move',
        //     icon: MdGames,
        //     label: 'Move',
        // },
        // {
        //     key: 'reset',
        //     icon: MdFullscreen,
        //     label: 'Reset',
        // },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadMap() {
        setIsLoading(true);
        await api
            .get(`maps/${id}`)
            .then(response => {
                const m = response.data;
                const content = m.content || {};
                const illustration = content.illustration || {};
                const background = content.background || {};
                setBody({
                    name: m.name,
                    description: m.description,
                    location_id: m.location ? m.location.id : false,
                    zone_id: m.zone ? m.zone.id : false,
                });
                setBGProps(background || {});
                setObjects(illustration.objects || []);
                setFile(m.file);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function getMeta(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject();
            img.src = url;
        });
    }

    async function getImageProps(f) {
        if (f && f.url) {
            const img = await getMeta(f.url);
            const w = img.width;
            const h = img.height;
            setImgProps({
                width: w,
                height: h,
            });
            const { scale } = bgProps;
            if (!scale || !scale.width || !scale.height) {
                setBGProps({
                    ...bgProps,
                    scale: {
                        width: w,
                        height: h,
                    },
                });
            }
            return;
        }
        setImgProps(null);
    }

    useEffect(() => {
        getImageProps(file);
    }, [file]);

    useEffect(() => {
        loadMap();
    }, []);

    async function updateMap(update) {
        setIsLoading(true);

        await api
            .put(`maps/${id}`, update)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.form.update_success" />
                );
                loadMap(id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function saveMap() {
        if (body) {
            const data = {
                ...body,
                content: {
                    background: {
                        file_id: file ? file.id : null,
                        ...bgProps,
                    },
                    illustration: {
                        objects,
                    },
                },
            };
            updateMap(data);
        }
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`maps/${id}/media`, data)
            .then(response => {
                const updated = {
                    ...body,
                    content: {
                        background: {
                            file_id: file ? file.id : null,
                            ...bgProps,
                        },
                        illustration: {
                            objects,
                        },
                    },
                    file_id: response.data.id,
                };
                setBody(updated);
                updateMap(updated);
            })
            .catch(error => requestError(error));
    };

    function changeZoom(mode) {
        if (mode === '+') {
            if (zoom < 10) {
                setZoom(zoom + 1);
            }
        } else if (zoom > 1) {
            setZoom(zoom - 1);
        }
    }

    function handleToolClick(tool) {
        setDrag(null);

        switch (tool) {
            case 'image':
                hiddenFileInput.current.click();
                break;
            case 'save_map':
                saveMap();
                setCurrTool('');
                setCurrObject(null);
                break;
            case 'zoom_in':
                setCurrTool('');
                changeZoom('-');
                break;
            case 'zoom_out':
                setCurrTool('');
                changeZoom('+');
                break;
            case 'reset': {
                setCurrTool('');
                setZoom(5);
                break;
            }
            default:
                if (currObject) {
                    setObjects([...objects, { ...currObject }]);
                    setCurrObject(null);
                }
                setCurrTool(tool);
                break;
        }
    }

    function getCoords(event) {
        const pt = svgRef.current.createSVGPoint();
        pt.x = event.clientX;
        pt.y = event.clientY;

        // The cursor point, translated into svg coordinates
        const cursorpt = pt.matrixTransform(
            svgRef.current.getScreenCTM().inverse()
        );
        return cursorpt;
    }

    function getRectWalls(r) {
        const start = {
            x: r.xStart,
            y: r.yStart,
        };
        const end = {
            x: r.xEnd,
            y: r.yEnd,
        };
        const r_id = r.id;

        const p1 = start;
        const p2 = { x: start.x, y: end.y };
        const p3 = { x: end.x, y: start.y };
        const p4 = end;

        const w1 = {
            id: `${r_id}_w1`,
            xStart: p1.x,
            yStart: p1.y,
            xEnd: p2.x,
            yEnd: p2.y,
        };
        const w2 = {
            id: `${r_id}_w2`,
            xStart: p2.x,
            yStart: p2.y,
            xEnd: p4.x,
            yEnd: p4.y,
        };
        const w3 = {
            id: `${r_id}_w3`,
            xStart: p4.x,
            yStart: p4.y,
            xEnd: p3.x,
            yEnd: p3.y,
        };
        const w4 = {
            id: `${r_id}_w4`,
            xStart: p3.x,
            yStart: p3.y,
            xEnd: p1.x,
            yEnd: p1.y,
        };

        return [w1, w2, w3, w4];
    }

    function getWallProps(obj) {
        const p1 = {
            x: obj.xStart,
            y: obj.yStart,
        };
        const p2 = {
            x: obj.xEnd,
            y: obj.yEnd,
        };
        const length = (
            Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2) / 100
        ).toFixed(1);

        const angle =
            (180 / Math.PI) * Math.atan((p2.y - p1.y) / (p2.x - p1.x));

        return { length, angle };
    }

    function selectPoint(e, _id, dir) {
        const index = objects.findIndex(o => o.id === _id);
        setCurrObject({ ...objects[index], dir });
        setObjects([...objects.slice(0, index), ...objects.slice(index + 1)]);
    }

    function selectLine(e, _id) {
        if (_id) {
            const index = objects.findIndex(o => o.id === _id);
            setCurrObject({ ...objects[index] });

            setObjects([
                ...objects.slice(0, index),
                ...objects.slice(index + 1),
            ]);
        }
    }

    function addObject(e, type) {
        const { x: xStart, y: yStart } = getCoords(e);
        const obj = {
            id: Date.now(),
            type,
            xStart,
            yStart,
            xEnd: xStart,
            yEnd: yStart,
        };

        setCurrObject(obj);
    }

    function finishRect(r) {
        const { length } = getWallProps(r);
        if (length <= 0.25) {
            return;
        }
        const r_walls = getRectWalls(r);

        setObjects([...objects, ...r_walls]);
    }

    function moveObj(e) {
        if (drag && drag.origin) {
            const { type, origin } = drag;
            const { x: xDrag, y: yDrag } = origin;
            const { x, y } = getCoords(e);
            const e_x = x;
            const e_y = y;
            const dx = e_x - xDrag;
            const dy = e_y - yDrag;

            setDrag({
                ...drag,
                origin: {
                    x: e_x,
                    y: e_y,
                },
            });

            switch (type) {
                case 'line': {
                    if (currObject) {
                        const { xStart, yStart, xEnd, yEnd } = currObject;

                        setCurrObject({
                            ...currObject,
                            xStart: xStart + dx,
                            yStart: yStart + dy,
                            xEnd: xEnd + dx,
                            yEnd: yEnd + dy,
                        });
                    }
                    break;
                }
                case 'image': {
                    const position =
                        bgProps && bgProps.position
                            ? bgProps.position
                            : { x: 0, y: 0 };
                    setBGProps({
                        ...bgProps,
                        position: {
                            x: position.x + dx,
                            y: position.y + dy,
                        },
                    });
                    break;
                }
                default:
                    break;
            }
        }
    }

    function selectMoveObj(e, opt) {
        const { x, y } = getCoords(e);
        const { type, id: _id } = opt;
        if (!type) {
            return;
        }
        setDrag({ type, origin: { x, y } });

        switch (type) {
            case 'line':
                selectLine(e, _id);
                break;
            default:
                setCurrObject(null);
                break;
        }
    }

    function insertRect(e) {
        if (currObject) {
            const { x, y } = getCoords(e);
            const e_x = x;
            const e_y = y;

            setCurrObject({ ...currObject, xEnd: e_x, yEnd: e_y });
        }
    }

    function insertLine(e) {
        if (currObject) {
            const { x, y } = getCoords(e);
            let e_x = x;
            let e_y = y;
            const { dir, xStart, yStart, xEnd, yEnd } = currObject;

            const xRef = !dir || dir === 'end' ? xStart : xEnd;
            const yRef = !dir || dir === 'end' ? yStart : yEnd;

            const { shiftKey } = e;
            if (shiftKey) {
                const dx = Math.abs(xRef - x);
                const dy = Math.abs(yRef - y);
                if (dx <= dy) {
                    e_x = xRef;
                } else {
                    e_y = yRef;
                }
            }

            setCurrObject({ ...currObject, xEnd: e_x, yEnd: e_y });
        }
    }

    function editLine(e) {
        if (currObject) {
            const { x, y } = getCoords(e);
            let e_x = x;
            let e_y = y;
            const { dir, xStart, yStart, xEnd, yEnd } = currObject;

            const xRef = !dir || dir === 'end' ? xStart : xEnd;
            const yRef = !dir || dir === 'end' ? yStart : yEnd;

            const { shiftKey } = e;
            if (shiftKey) {
                const dx = Math.abs(xRef - x);
                const dy = Math.abs(yRef - y);
                if (dx <= dy) {
                    e_x = xRef;
                } else {
                    e_y = yRef;
                }
            }
            if (dir === 'start') {
                setCurrObject({
                    ...currObject,
                    xStart: e_x,
                    yStart: e_y,
                });
            } else if (dir === 'end') {
                setCurrObject({ ...currObject, xEnd: e_x, yEnd: e_y });
            }
        }
    }

    function resizeObj(e) {
        if (drag && drag.origin) {
            const { origin, corner } = drag;
            const { y: yDrag } = origin;
            const { x, y } = getCoords(e);
            const e_x = x;
            const e_y = y;
            const dy = e_y - yDrag;

            const position =
                bgProps && bgProps.position ? bgProps.position : { x: 0, y: 0 };

            const scale =
                bgProps && bgProps.scale
                    ? bgProps.scale
                    : { width: imgProps.width, height: imgProps.height };
            const { width: w, height: h } = scale;

            setDrag({
                ...drag,
                origin: {
                    x: e_x,
                    y: e_y,
                },
            });

            let newW = w;
            let newH = h;

            const factor = corner < 2 ? -1 : 1;

            newH = h + factor * dy;
            const proportion = newH / h;
            newW = proportion * w;
            scale.width = newW;
            scale.height = newH;

            setBGProps({
                ...bgProps,
                position,
                scale,
            });
        }
    }

    function rotateObj(e) {
        if (drag && drag.origin) {
            const { origin } = drag;
            const { x: o_x, y: o_y } = origin;
            const { x, y } = getCoords(e);
            const e_x = x;
            const e_y = y;

            setDrag({
                ...drag,
                origin: {
                    x: e_x,
                    y: e_y,
                },
            });

            const { rotation, scale } = bgProps;
            const midX = scale.width / 2;
            const midY = scale.height / 2;

            const o1 = Math.atan((o_y - midY) / (o_x - midX));
            const o2 = Math.atan((e_y - midY) / (e_x - midX));
            const ang = (180 / Math.PI) * (o2 - o1);

            const oldRotation = rotation || 0;

            setBGProps({
                ...bgProps,
                rotation: oldRotation - ang,
            });
        }
    }

    function selectImgObj(e, opt) {
        const { x, y } = getCoords(e);
        const { type, corner } = opt;
        if (currTool === 'resize_image') {
            if (!type || corner === undefined) {
                return;
            }
        }
        setCurrObject(null);
        setDrag({ type, origin: { x, y }, corner });
    }

    function handleMouseDown(e, opt) {
        switch (currTool) {
            case 'insert_line': {
                addObject(e, 'line');
                break;
            }
            case 'insert_rect': {
                addObject(e, 'rect');
                break;
            }
            case 'move': {
                selectMoveObj(e, opt);
                break;
            }
            case 'delete': {
                const { id: _id } = opt;
                selectLine(e, _id);
                break;
            }
            case 'resize_image':
            case 'rotate_image': {
                selectImgObj(e, opt);
                break;
            }
            default:
                break;
        }
    }

    function handleMouseMove(e) {
        switch (currTool) {
            case 'insert_rect':
                insertRect(e);
                break;
            case 'insert_line':
                insertLine(e);
                break;
            case 'edit':
                editLine(e);
                break;
            case 'move':
                moveObj(e);
                break;
            case 'resize_image':
                resizeObj(e);
                break;
            case 'rotate_image':
                rotateObj(e);
                break;
            default:
                break;
        }
    }

    function handleMouseUp() {
        const obj = currObject;

        switch (currTool) {
            case 'insert_line': {
                if (!obj) {
                    return;
                }
                const { length } = getWallProps(obj);
                setCurrObject(null);
                if (length <= 0.1) {
                    return;
                }
                setObjects([...objects, { ...obj }]);
                break;
            }
            case 'insert_rect': {
                if (!obj) {
                    return;
                }
                setCurrObject(null);
                finishRect(obj);
                break;
            }
            case 'edit': {
                if (!obj) {
                    return;
                }
                setCurrObject(null);
                setObjects([...objects, { ...obj }]);
                break;
            }
            case 'move':
                setDrag(null);
                setCurrObject(null);
                if (obj) {
                    setObjects([...objects, { ...obj }]);
                }
                break;
            case 'resize_image':
                setDrag(null);
                setCurrObject(null);
                break;
            case 'rotate_image':
                setDrag(null);
                setCurrObject(null);
                break;
            case 'delete': {
                setCurrObject(null);
                break;
            }
            default:
                setCurrObject(null);
                break;
        }
    }

    function renderWall(obj, selected = false) {
        const p1 = {
            x: obj.xStart,
            y: obj.yStart,
        };
        const p2 = {
            x: obj.xEnd,
            y: obj.yEnd,
        };
        // const { angle } = getWallProps(obj);

        // const mid = {
        //     x: (p1.x + p2.x) / 2,
        //     y: (p1.y + p2.y) / 2,
        // };

        // const sin = Math.sin((Math.PI * angle) / 180) * 5;
        // const cos = Math.cos((Math.PI * angle) / 180) * 5;

        // const label_pos = {
        //     x: mid.x + sin,
        //     y: mid.y - cos,
        // };

        return (
            <g
                onMouseDown={event =>
                    handleMouseDown(event, { type: 'line', id: obj.id })
                }
                key={`line_${obj.id}`}
            >
                <line
                    x1={p1.x}
                    y1={p1.y}
                    x2={p2.x}
                    y2={p2.y}
                    stroke={selected ? 'red' : 'white'}
                    strokeWidth={lineWidth}
                    onMouseOver={event => {
                        if (currTool === 'move' || currTool === 'delete') {
                            event.target.setAttribute('stroke', 'red');
                        }
                    }}
                    onMouseOut={event =>
                        event.target.setAttribute(
                            'stroke',
                            selected ? 'red' : 'white'
                        )
                    }
                />
                {currTool === 'edit' ? (
                    <g>
                        <circle
                            cx={p1.x}
                            cy={p1.y}
                            r={lineWidth}
                            fill="white"
                            onMouseOver={event => {
                                event.target.setAttribute('fill', 'red');
                            }}
                            onMouseOut={event =>
                                event.target.setAttribute('fill', 'white')
                            }
                            onMouseDown={event =>
                                selectPoint(event, obj.id, 'start')
                            }
                        />
                        <circle
                            cx={p2.x}
                            cy={p2.y}
                            r={lineWidth}
                            fill="white"
                            onMouseOver={event => {
                                event.target.setAttribute('fill', 'red');
                            }}
                            onMouseOut={event =>
                                event.target.setAttribute('fill', 'white')
                            }
                            onMouseDown={event =>
                                selectPoint(event, obj.id, 'end')
                            }
                        />
                    </g>
                ) : null}
                {/* {length >= 0.5 ? (
                    <text
                        x={label_pos.x}
                        y={label_pos.y}
                        fill="white"
                        transform={`rotate(${angle}, ${label_pos.x}, ${label_pos.y})`}
                    >
                        {length} m
                    </text>
                ) : null} */}
            </g>
        );
    }

    function renderRect(obj) {
        const rect = getRectWalls(obj);
        return <>{rect.map(p => renderWall(p))}</>;
    }

    function renderBGCorner({ x, y }, corner) {
        const resize_cursor =
            corner > 0 && corner < 3 ? 'sw-resize' : 'nw-resize';
        const cursor =
            currTool === 'resize_image' ? resize_cursor : 'ew-resize';

        return (
            <circle
                cx={x}
                cy={y}
                r={lineWidth}
                fill="white"
                onMouseMove={event => handleMouseMove(event)}
                onMouseDown={event =>
                    handleMouseDown(event, {
                        type: 'image',
                        corner,
                    })
                }
                onMouseOver={event => {
                    event.target.setAttribute('fill', 'red');
                    event.target.setAttribute('cursor', cursor);
                }}
                onMouseOut={event => {
                    event.target.setAttribute('fill', 'white');
                }}
                onMouseUp={event => {
                    handleMouseUp(event);
                    event.target.setAttribute('fill', 'white');
                    event.target.setAttribute('cursor', 'default');
                }}
            />
        );
    }

    function renderBGImg() {
        const { rotation, position, scale } = bgProps;
        const x = position && position.x ? position.x : 0;
        const y = position && position.y ? position.y : 0;
        const w = scale && scale.width ? scale.width : 500;
        const h = scale && scale.height ? scale.height : 500;
        const midX = w / 2;
        const midY = h / 2;

        return (
            <g
                onMouseOver={event => {
                    event.target.setAttribute('fill', 'red');
                    event.target.setAttribute('cursor', 'move');
                }}
                onMouseOut={event => {
                    event.target.setAttribute('fill', 'white');
                    event.target.setAttribute('cursor', 'default');
                }}
                onMouseUp={event => {
                    handleMouseUp(event);
                    event.target.setAttribute('fill', 'white');
                }}
                onMouseMove={event => handleMouseMove(event)}
                onMouseDown={event => handleMouseDown(event, { type: 'image' })}
                transform={`translate(${x},${y}) rotate(${rotation ||
                    0} ${midX} ${midY})`}
            >
                <image
                    href={file.url}
                    opacity="0.5"
                    width={w}
                    height={h}
                    onMouseDown={event =>
                        handleMouseDown(event, { type: 'image' })
                    }
                    onMouseMove={event => handleMouseMove(event)}
                    onMouseUp={event => {
                        handleMouseUp(event);
                        event.target.setAttribute('fill', 'white');
                    }}
                />
                {currTool === 'resize_image' ? (
                    <>
                        {renderBGCorner({ x: 0, y: 0 }, 0)}
                        {renderBGCorner({ x: w, y: 0 }, 1)}
                        {renderBGCorner({ x: 0, y: h }, 2)}
                        {renderBGCorner({ x: w, y: h }, 3)}
                    </>
                ) : null}
                {currTool === 'rotate_image' ? (
                    <circle cx={midX} cy={midY} r={lineWidth} fill="red" />
                ) : null}
            </g>
        );
    }

    function drawCurrObj() {
        const { type } = currObject;
        switch (type) {
            case 'rect':
                return renderRect(currObject);
            default:
                return renderWall(currObject, currTool === 'move');
        }
    }

    function drawMap() {
        let handler = 'default';
        switch (currTool) {
            case 'move':
                handler = 'move';
                break;
            case 'edit':
                handler = 'move';
                break;
            case 'insert_line':
            case 'insert_rect':
                handler = 'crosshair';
                break;
            case 'delete':
                handler = 'not-allowed';
                break;
            default:
                break;
        }

        const s_x = size.width;
        const s_y = size.height;
        return (
            <MapArea handler={handler}>
                <Scrollbars autoHide>
                    <svg
                        viewBox={`0 0 ${s_x} ${s_y}`}
                        onMouseDown={event => handleMouseDown(event, {})}
                        onMouseUp={event => handleMouseUp(event)}
                        onMouseMove={event => handleMouseMove(event)}
                        ref={svgRef}
                    >
                        {file ? renderBGImg() : null}
                        {objects.map(o => renderWall(o))}
                        {currObject ? drawCurrObj() : null}
                    </svg>
                </Scrollbars>
            </MapArea>
        );
    }

    function renderButton(b) {
        const Icon = b.icon || MdCode;
        return (
            <Tooltip key={b.key} title={b.label}>
                <Button
                    onClick={() => handleToolClick(b.key)}
                    variant={
                        currTool && currTool === b.key
                            ? 'contained'
                            : 'outlined'
                    }
                    disabled={!!b.condition}
                >
                    <Icon style={{ fontSize: '3vh' }} />
                    {b.key === 'image' ? (
                        <input
                            type="file"
                            accept={'image/*'}
                            onChange={onFileUpload}
                            multiple={false}
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                        />
                    ) : null}
                </Button>
            </Tooltip>
        );
    }

    return (
        <PageContent
            title="Map Illustration"
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/maps',
                    title: <LocaleMessage msg="breadcrumbs.nav_maps" />,
                },
                {
                    url: `/tools/maps/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.map_layers" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <div className="col-12 mb-5">
                <CardSideBordered title="MAP NAME" hide Icon={MdMap}>
                    <div className="row" style={{ height: '70vh' }}>
                        <EditorArea className="col-12 mb-3">
                            <ToolArea>
                                <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                >
                                    {buttons.map(b => {
                                        return renderButton(b);
                                    })}
                                </ButtonGroup>
                            </ToolArea>
                            {drawMap()}
                        </EditorArea>
                    </div>
                </CardSideBordered>
            </div>
        </PageContent>
    );
}

MapIllustration.propTypes = {
    match: PropTypes.object.isRequired,
};
