/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    List,
    ListItem,
    ListItemText,
    Chip,
    IconButton,
    ListItemAvatar,
    Avatar,
    Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import pbLogo from '~/assets/logo-white.png';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { PreviewArea, Screen, ListsBody, ContactList } from './styles';

export default function Preview({
    configuration,
    onCancelClick,
    lists,
    contacts,
    currList,
    setCurrList,
    mainContact,
}) {
    const theme = GetPluginspaceTheme(useTheme());
    const mainColor = theme && theme.primary ? theme.primary : '#000';
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const visualSettings = configuration.visual || {};
    const colorSettings = visualSettings.colors || { call_button: mainColor };

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: (4.0 / 3.0) * targetRef.current.offsetWidth,
            });
        }
    }, []);

    function renderContact(c) {
        const avatar = c.file && c.file.url ? c.file.url : null;
        return (
            <ListItem key={c.id} style={{ width: '100%' }}>
                <ListItemAvatar>
                    <Avatar alt={c.name} src={avatar} className="avatar" />
                </ListItemAvatar>
                <ListItemText primary={c.name} secondary={c.description} />
            </ListItem>
        );
    }

    function renderFilteredContacts() {
        const filtered_contacts = {};

        contacts.forEach(c => {
            if (filtered_contacts[c.id]) {
                return true;
            }

            filtered_contacts[c.id] = {
                id: c.id,
                name: c.name || '',
                description: c.description || '',
                file: c.file || null,
            };
            return true;
        });

        const contact_keys = Object.keys(filtered_contacts);
        return contact_keys.length > 0 ? (
            <ContactList dimensions={dimensions} colors={colorSettings}>
                <List>
                    {contact_keys.map(k => {
                        return renderContact(filtered_contacts[k]);
                    })}
                </List>
            </ContactList>
        ) : (
            <LocaleMessage msg="page.applications.telepresence.preview.no_contacts" />
        );
    }

    function renderMainContact() {
        const mainID = mainContact.value;
        const useAll = mainID === '*';

        return (
            <Button
                className="mb-1"
                variant="contained"
                size="large"
                style={{
                    backgroundColor:
                        colorSettings && colorSettings.call_button
                            ? colorSettings.call_button
                            : mainColor,
                    color: '#fff',
                    overflowWrap: 'anywhere',
                    maxWidth: '90%',
                }}
            >
                {useAll ? (
                    mainContact.broadcast_text || (
                        <LocaleMessage msg="page.applications.telepresence.preview.call.broadcast" />
                    )
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <small>
                            <LocaleMessage msg="page.applications.telepresence.preview.call" />
                        </small>
                        {mainContact.name}
                    </div>
                )}
            </Button>
        );
    }

    function renderListBody() {
        const mainID = mainContact.value;

        const useMain = mainID !== 'false';

        return (
            <ListsBody hasMain={useMain} dimensions={dimensions}>
                <div className="lists">
                    {/* <Chip
                        className="mx-1"
                        label="All Contacts"
                        onClick={() => setCurrList(null)}
                        variant={currList ? 'outlined' : undefined}
                    /> */}
                    {lists.map(l => {
                        return (
                            <Chip
                                key={l.id}
                                className="mx-1"
                                label={l.name}
                                variant={
                                    currList && currList === l.id
                                        ? undefined
                                        : 'outlined'
                                }
                                onClick={() => setCurrList(l.id)}
                            />
                        );
                    })}
                </div>
                <div className="contacts col-12 row">
                    {renderFilteredContacts()}
                </div>
                <div className="footer">
                    {useMain ? renderMainContact() : null}
                    <IconButton
                        onClick={e => onCancelClick(e)}
                        style={{
                            color: '#ddd',
                        }}
                    >
                        <MdClose />
                    </IconButton>
                </div>
            </ListsBody>
        );
    }

    return (
        <PreviewArea className="mt-3 mb-3" ref={targetRef}>
            <Screen dimensions={dimensions} colors={colorSettings}>
                <div className="contacts">
                    <div className="header">
                        <img
                            className="pluginbot-logo mb-3"
                            src={pbLogo}
                            alt="Pluginbot Logo"
                            title="Pluginbot Logo"
                        />
                    </div>
                    <div className="content">{renderListBody()}</div>
                </div>
            </Screen>
        </PreviewArea>
    );
}

Preview.defaultProps = {
    configuration: {},
    contacts: [],
    lists: [],
    currList: null,
    mainContact: {},
};

Preview.propTypes = {
    configuration: PropTypes.object,
    contacts: PropTypes.array,
    lists: PropTypes.array,
    onCancelClick: PropTypes.func.isRequired,
    currList: PropTypes.string,
    setCurrList: PropTypes.func.isRequired,
    mainContact: PropTypes.object,
};
