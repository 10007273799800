/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdPlaylistAddCheck, MdContentCopy } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { Button, DialogContentText } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import AnswerSetup from '../Form/Components/AnswerSetup';

export default function Answers({ match }) {
    const dispatch = useDispatch();

    const { plugin_id, id } = match.params;
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const [isLoading, setIsLoading] = useState(true);

    const [pluginConfig, setPluginConfig] = useState(null);
    // const [configLanguages, setConfigLanguages] = useState([]);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [defaultAnswers, setDefaultAnswers] = useState({});

    const [items, setItems] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const langs = response.data.map(l => {
                    return {
                        value: l.value,
                        label: `list.languages.${l.value}`,
                    };
                });
                setAvailableLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadPluginconfig(_id) {
        setIsLoading(true);
        await api
            .get(`pluginconfigs/${_id}`)
            .then(async response => {
                const config = response.data;

                const parameters = config.parameters || {};
                const answers = parameters.answers || {};

                setDefaultAnswers(answers);

                // const langs = parameters.languages || [];
                // setConfigLanguages(langs);

                setPluginConfig({
                    name: config.name,
                    group_id:
                        config.group && config.group.id
                            ? config.group.id
                            : null,
                    action_id:
                        config.action && config.action.id
                            ? config.action.id
                            : null,
                    plugintemplate_id:
                        config.plugintemplate && config.plugintemplate.id
                            ? config.plugintemplate.id
                            : null,
                    type:
                        config.plugintemplate && config.plugintemplate.type
                            ? config.plugintemplate.type
                            : null,
                    parameters,
                });
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadItems(_id) {
        setIsLoading(true);
        await api
            .get(`answer_collections/${_id}/answers`)
            .then(response => {
                const { data } = response;
                const ans_groups = data.map(s => {
                    const updated = new Date(s.updated);
                    return {
                        ...s,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setItems(ans_groups);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
        loadPluginconfig(id);
        loadItems(id);
    }, []);

    async function handleSubmit() {
        const parameters = pluginConfig.parameters || {};
        const data = {
            ...pluginConfig,
            parameters: {
                ...parameters,
                answers: defaultAnswers,
            },
        };

        setIsLoading(true);
        await api
            .put(`pluginconfigs/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.answer_groups.list.update_success" />
                );
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleCopy(event, item_id) {
        setIsLoading(true);
        await api
            .post(`answer_collections/${id}/clone/${item_id}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.answer_groups.list.update_success" />
                );
                loadItems(id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleClickOpen(event, item_id) {
        setCurrItem(item_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, item_id) {
        event.preventDefault();
        setCurrItem(item_id);
        history.push(`/pluginconfig/${plugin_id}/answers/${id}/${item_id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`answer_collections/${id}/answers/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.answer_groups.list.delete_success" />
                );
                loadItems(id);
            })
            .catch(error => requestError(error));
    }

    function buildListView() {
        const headCells = [
            {
                id: 'description',
                label: <LocaleMessage msg="table.headers.description" />,
            },
            {
                id: 'condition_count',
                label: <LocaleMessage msg="table.headers.conditions" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'clone',
                label: 'Clonar',
                icon: <MdContentCopy />,
                action: handleCopy,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items || []}
                    orderColumn="condition_count"
                    orderDirection="desc"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.answer_groups.list.delete_confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildHeader() {
        const options = availableLanguages.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={l.label} />,
            };
        });

        const parameters =
            pluginConfig && pluginConfig.parameters
                ? pluginConfig.parameters
                : {};

        const languages =
            parameters && parameters.languages ? parameters.languages : [];

        return (
            <CardSideBordered
                title={<LocaleMessage msg="page.answer_groups.list.title" />}
                Icon={MdPlaylistAddCheck}
            >
                <>
                    <div style={{ padding: '15px' }}>
                        <p>
                            <LocaleMessage msg="page.answer_groups.list.languages.description" />
                        </p>
                        <FormCheckList
                            options={options}
                            settings={parameters || {}}
                            listKey="languages"
                            onChange={p => {
                                setPluginConfig({
                                    ...pluginConfig,
                                    parameters: p,
                                });
                            }}
                        />
                        <div className="col-12 row mt-5">
                            <div
                                className="col-12 mb-3"
                                style={{ textAlign: 'center' }}
                            >
                                <LocaleMessage msg="page.answer_groups.list.texts.no_match" />
                            </div>
                            <div className="col-12 px-0">
                                <AnswerSetup
                                    settings={defaultAnswers}
                                    onChange={s => {
                                        setDefaultAnswers(s);
                                    }}
                                    languages={languages.map(l => {
                                        return {
                                            value: l,
                                        };
                                    })}
                                    handleError={err => requestError(err)}
                                />
                            </div>
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => handleSubmit()}
                            disabled={!languages.length}
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </>
            </CardSideBordered>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.answer_groups.list.title" />}
            currentPage={pluginConfig ? pluginConfig.name : ''}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
                {
                    url: `/plugins/${plugin_id}`,
                    title: <LocaleMessage msg="breadcrumbs.answers" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}

                <div className="row col-12">
                    <div className="col-md-6 col-12 mb-5">{buildHeader()}</div>
                    <div className="col-md-6 col-12">
                        <div className="body-top-controls">
                            <NewItemButton
                                link={`/pluginconfig/${plugin_id}/answers/${id}/new`}
                                text={
                                    <LocaleMessage msg="page.answer_groups.list.add" />
                                }
                            />
                        </div>
                        <>{buildListView()}</>
                    </div>
                </div>
            </>
        </PageContent>
    );
}

Answers.propTypes = {
    match: PropTypes.object.isRequired,
};
