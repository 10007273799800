/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import SectionsList from '~/components/Sections/List';

export default function Sections() {
    const settings = useSelector(state => state.settings || {});

    const { active } = settings;
    const group_id = active && active.id !== '*' ? active.id : null;
    return (
        <SectionsList
            breadcrumbs={[]}
            allowNew={false}
            linkRoot=""
            filter={`/me?group=${group_id}`}
            infos={{ members: false, permission: true }}
        />
    );
}
