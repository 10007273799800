/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import TimeslotForm from './TimeslotForm';

const sources = [
    {
        value: 'other',
        label_key: 'list.contact.source.other',
    },
    {
        value: 'section',
        label_key: 'list.contact.source.section',
    },
];

export default function ContactForm({
    contactId,
    linkRoot,
    allowEdit,
    defaultBody,
    showReference,
    updateContact,
    users,
    submitHeaders,
}) {
    const dispatch = useDispatch();

    const user_settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(user_settings);

    const [currSource, setCurrSource] = useState(sources[0]);

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState(defaultBody);

    const [sections, setSections] = useState([]);

    const [timeslots, setTimeslots] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadSections() {
        await api
            .get(`/sections`)
            .then(response => {
                const l_sections = response.data.map(s => {
                    return {
                        id: s.id,
                        name: s.name,
                    };
                });
                setSections(l_sections);
            })
            .catch(error => requestError(error));
    }

    async function loadContact(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/contacts/${_id}`)
                .then(response => {
                    const c = response.data;

                    const { source, content } = c;
                    const formBody = {
                        name: c.name,
                        description: c.description,
                        source,
                        section_id: null,
                        always_available: c.always_available,
                    };

                    if (source === 'section') {
                        formBody.section_id =
                            c.section && c.section.id ? c.section.id : null;
                    }

                    updateContact({
                        ...formBody,
                        group_id: c.group ? c.group.id : null,
                    });

                    setBody(formBody);
                    setTimeslots(
                        content && content.timeslots ? content.timeslots : []
                    );
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    function handleSourceChange(val) {
        const source = sources.find(s => {
            return s.value === val;
        });

        setCurrSource(source);
    }

    useEffect(() => {
        loadSections();
    }, []);

    useEffect(() => {
        loadContact(contactId);
    }, [contactId]);

    useEffect(() => {
        handleSourceChange(body.source);
    }, [body.source]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            ...body,
            timeslots,
            ...(users && { users }),
        };

        if (operation === 'create') {
            await api
                .post(`/contacts`, data, {
                    ...(submitHeaders && { ...submitHeaders }),
                })
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.contacts.form.create_success" />
                    );
                    setIsLoading(false);
                    history.push(linkRoot);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`/contacts/${contactId}`, data, {
                    ...(submitHeaders && { ...submitHeaders }),
                })
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.contacts.form.update_success" />
                    );
                    loadContact(contactId);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function renderSourceList(type) {
        const source_list = {
            section: {
                key: 'section_id',
                list: sections,
            },
        };

        const source = source_list[type];
        const { key, list } = source;

        return (
            <div className="col-md-6 col-12">
                <FormSelect
                    classes="mb-5"
                    options={list}
                    labelKey="name"
                    disabled={operation === 'update'}
                    label={
                        <LocaleMessage msg="page.tools.contacts.form.label.source_bind" />
                    }
                    onChange={value => {
                        setBody({
                            ...body,
                            [key]: value,
                        });
                    }}
                    value={body[key] || ''}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            {showReference ? (
                <div className="col-12 row">
                    <div className="col-md-6 col-12">
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.contacts.form.label.source_type" />
                            </InputLabel>
                            <Select
                                value={body.source || 'other'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        source: event.target.value,
                                    })
                                }
                                disabled={operation === 'update' || !allowEdit}
                            >
                                {sources.map(s => (
                                    <MenuItem value={s.value} key={s.value}>
                                        <LocaleMessage msg={s.label_key} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {currSource && currSource.value !== 'other'
                        ? renderSourceList(currSource.value)
                        : null}
                </div>
            ) : null}
            <div className="col-md-6 col-12">
                <TextField
                    className="mb-5"
                    label={
                        <LocaleMessage msg="page.tools.contacts.form.label.name" />
                    }
                    fullWidth
                    value={body.name || ''}
                    onChange={event =>
                        setBody({
                            ...body,
                            name: event.target.value,
                        })
                    }
                    disabled={!allowEdit}
                />
            </div>
            <div className="col-12">
                <TextField
                    className="mb-5"
                    label={
                        <LocaleMessage msg="page.tools.contacts.form.label.description" />
                    }
                    fullWidth
                    value={body.description || ''}
                    onChange={event =>
                        setBody({
                            ...body,
                            description: event.target.value,
                        })
                    }
                    disabled={!allowEdit}
                />
            </div>
            <div className="col-12 mb-3">
                <FormSwitch
                    value={body.always_available || false}
                    onChange={event =>
                        setBody({
                            ...body,
                            always_available: event.target.checked,
                        })
                    }
                    label={
                        <LocaleMessage msg="page.tools.contacts.form.label.always_available" />
                    }
                    disabled={!allowEdit}
                />
            </div>

            {!body.always_available ? (
                <TimeslotForm
                    locale={date_loc}
                    timeslots={timeslots}
                    updateTimeslots={t => {
                        return setTimeslots(t);
                    }}
                    allowEdit={allowEdit}
                />
            ) : null}

            {allowEdit ? (
                <div className="col-12">
                    <Button
                        className="p-3 mb-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            ) : null}
        </>
    );
}

ContactForm.defaultProps = {
    allowEdit: false,
    showReference: true,
    linkRoot: '#',
    defaultBody: {},
    updateContact: () => {},
    users: null,
    submitHeaders: null,
};

ContactForm.propTypes = {
    contactId: PropTypes.string.isRequired,
    showReference: PropTypes.bool,
    linkRoot: PropTypes.string,
    allowEdit: PropTypes.bool,
    defaultBody: PropTypes.object,
    updateContact: PropTypes.func,
    users: PropTypes.array,
    submitHeaders: PropTypes.object,
};
