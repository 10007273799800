/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdMap, MdPinDrop, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import SVGMap from '~/components/SVGMap';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function NavMapForm(props) {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { match } = props;
    const { id, layer_id } = match.params;

    const [illustration, setIllustration] = useState(null);
    const [operation, setOperation] = useState('create');
    const [appRobotTypes, setAppRobotTypes] = useState({});
    const [appTypes, setAppTypes] = useState([]);

    const [body, setBody] = useState({
        name: '',
        map_id: id,
    });

    const [file, setFile] = useState({
        id: null,
        url: null,
    });

    const [points, setPoints] = useState([]);
    const [viewPoints, setViewPoints] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadAppTypes() {
        setIsLoading(true);
        await api
            .get(`types?group=application`)
            .then(response => {
                const { data } = response;
                setAppTypes(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadAppRobotTypes() {
        setIsLoading(true);
        await api
            .get(`types/app_robots`)
            .then(response => {
                const { data } = response;
                const app_robot = {};
                data.forEach(a => {
                    const app = a.application;
                    const { robot } = a;
                    if (!app_robot[app.id]) {
                        app_robot[app.id] = [];
                    }
                    app_robot[app.id].push(robot);
                });
                setAppRobotTypes(app_robot);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadMapLayer() {
        if (layer_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`map_layers/${layer_id}`)
                .then(response => {
                    const l = response.data;
                    setBody({
                        reference: l.reference,
                        map_id: l.map ? l.map.id : null,
                        robot_type_id:
                            l.robot_type && l.robot_type.id
                                ? l.robot_type.id
                                : '*',
                        app_type_id: l.app_type ? l.app_type.id : null,
                    });
                    setFile(l.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadMapPoints() {
        if (id && layer_id && layer_id !== 'new') {
            setIsLoading(true);
            await api
                .get(`map_points?map=${id}&map_layer=${layer_id}`)
                .then(response => {
                    const data = response.data.map((z, idx) => {
                        const updated = new Date(z.updated);
                        return {
                            index: idx,
                            id: z.id,
                            name: z.name,
                            reference: z.reference,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    const views = response.data.map(p => {
                        const position = p.position || {};
                        return {
                            id: p.id,
                            label: p.name,
                            x: position.x || 500,
                            y: position.y || 500,
                        };
                    });
                    setPoints(data);
                    setViewPoints(views);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadMap() {
        setIsLoading(true);
        await api
            .get(`maps/${id}`)
            .then(response => {
                const m = response.data;
                const map_content = m.content || {};
                const map_illustration = map_content.illustration || {};

                setIllustration(map_illustration);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadMap();
        loadAppTypes();
        loadAppRobotTypes();
        loadMapLayer();
        loadMapPoints();
    }, []);

    async function updateLayer(data) {
        await api
            .put(`map_layers/${layer_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.layer.form.update_success" />
                );
                setIsLoading(false);
                loadMapLayer();
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`map_layers`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.navmaps.layer.form.create_success" />
                    );

                    history.push(`/tools/maps/${id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateLayer(data);
        }
    }

    async function deletePoint() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`/map_points/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.navmaps.point.list.delete_success" />
                );
                await loadMapPoints(id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        history.push(`/tools/maps/${id}/layer/${layer_id}/points/${_id}`);
    }

    function handleDialogsClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteDialogOpen(true);
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage msg="page.navmaps.point.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deletePoint(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildPointList() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'reference',
                label: <LocaleMessage msg="table.headers.point_name" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
                className="mb-3"
            >
                <DataTable
                    headerColumns={headCells}
                    data={points}
                    orderColumn="title"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div
                                className="col-md-6 col-12"
                                style={{ padding: '0px' }}
                            >
                                <NewItemButton
                                    link={`/tools/maps/${id}/layer/${layer_id}/points/new`}
                                    text={
                                        <LocaleMessage msg="page.navmaps.point.list.add" />
                                    }
                                />
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`map_layers/${id}/media`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id };
                setBody(updated);
                updateLayer(updated);
            })
            .catch(error => requestError(error));
    };

    function renderFileInput() {
        return (
            <div
                className="col-12 mb-5"
                style={{ height: '200px', justifyContent: 'center' }}
            >
                <FileInput
                    defaultValue={file}
                    onFileUpload={onFileUpload}
                    multiple={false}
                    text={<LocaleMessage msg="label.form.change_image" />}
                />
            </div>
        );
    }

    const filteredRobots =
        body.app_type_id && appRobotTypes[body.app_type_id]
            ? appRobotTypes[body.app_type_id]
            : [];

    function renderIllustration() {
        return (
            <div className="col-10 mb-5" style={{ height: 'fit-content' }}>
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.navmaps.layer.form.illustration.title" />
                    }
                    hide
                    Icon={MdPinDrop}
                    className="mb-5"
                    style={{ height: 'fit-content' }}
                >
                    <div className="row" style={{ height: '100%' }}>
                        <SVGMap
                            illustration={illustration}
                            points={viewPoints}
                        />
                    </div>
                </CardSideBordered>
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.navmaps.layer.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.navmaps.layer.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/maps',
                    title: <LocaleMessage msg="breadcrumbs.nav_maps" />,
                },
                {
                    url: `/tools/maps/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.map_layers" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`${
                            operation === 'update' ? 'col-md-6' : 'col-md-8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.navmaps.layer.form.title" />
                            }
                            Icon={MdMap}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.app_type" />
                                            </InputLabel>
                                            <Select
                                                required
                                                value={
                                                    body.app_type_id
                                                        ? body.app_type_id
                                                        : ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        app_type_id:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    operation === 'update'
                                                }
                                            >
                                                {appTypes.map(app => {
                                                    return (
                                                        <MenuItem
                                                            value={app.id}
                                                            key={app.id}
                                                        >
                                                            <LocaleMessage
                                                                msg={`apps.${app.slug}`}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.form.robot_type" />
                                            </InputLabel>
                                            <Select
                                                required
                                                value={
                                                    body.robot_type_id
                                                        ? body.robot_type_id
                                                        : '*'
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        robot_type_id:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                <MenuItem value="*">
                                                    <LocaleMessage msg="message.all_robots.title" />
                                                </MenuItem>
                                                {filteredRobots.map(r => {
                                                    return (
                                                        <MenuItem
                                                            value={r.id}
                                                            key={r.id}
                                                        >
                                                            {r.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.navmaps.layer.form.label.reference" />
                                            }
                                            fullWidth
                                            value={body.reference || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    reference:
                                                        event.target.value,
                                                })
                                            }
                                            helperText={
                                                <LocaleMessage msg="page.navmaps.layer.form.label.reference.helper" />
                                            }
                                        />
                                    </div>

                                    {operation === 'update' ? (
                                        <>{renderFileInput()}</>
                                    ) : null}
                                    {/* <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="page.navmaps.layer.form.sync.from_robot" />
                                        </Button>
                                    </div> */}
                                    <div className="col-12">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.navmaps.point.list.title" />
                                }
                                Icon={MdPinDrop}
                                hide
                            >
                                <>
                                    {buildPointList()}
                                    <div className="col-12 mt-3">
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                history.push(
                                                    `/tools/maps/${id}/layer/${layer_id}/qrcodes`
                                                )
                                            }
                                        >
                                            <LocaleMessage msg="page.navmaps.layer.qr_code.generate" />
                                        </Button>
                                    </div>
                                </>
                            </CardSideBordered>
                        </div>
                    ) : null}
                </form>

                {/* {operation === 'update' && illustration && illustration.objects
                    ? renderIllustration()
                    : null} */}
            </>
        </PageContent>
    );
}

NavMapForm.propTypes = {
    match: PropTypes.object.isRequired,
};
