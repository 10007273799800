/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdMap, MdClose } from 'react-icons/md';
import QRCode from 'react-qr-code';
import { useSelector, useDispatch } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    AppBar,
    Button,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import { ParametersForm, PreviewArea, PrintableArea, QRArea } from './styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: '#fff',
    },
}));

export default function NavMapForm(props) {
    const classes = useStyles();
    const targetRef = useRef();
    const printRef = useRef();
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { match } = props;
    const { id, layer_id } = match.params;

    const [mapLayer, setMapLayer] = useState({
        name: '',
        map_id: id,
    });

    const [points, setPoints] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [qrDialogOpen, setQRDialogOpen] = useState(false);
    const [qrParameters, setQRParameters] = useState({
        map_point_id: 'all',
        type: 'text',
        label_type: 'name',
    });

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadMapLayer() {
        await api
            .get(`map_layers/${layer_id}`)
            .then(response => {
                const l = response.data;
                setMapLayer({
                    reference: l.reference,
                    map_id: l.map ? l.map.id : null,
                    map_name: l.map ? l.map.name : null,
                });
            })
            .catch(error => requestError(error));
    }

    async function loadMapPoints() {
        if (id && layer_id && layer_id !== 'new') {
            setIsLoading(true);
            await api
                .get(`map_points?map=${id}&map_layer=${layer_id}`)
                .then(response => {
                    const data = response.data.map((z, idx) => {
                        const updated = new Date(z.updated);
                        return {
                            index: idx,
                            id: z.id,
                            name: z.name,
                            reference: z.reference,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setPoints(data);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadMapLayer();
        loadMapPoints();
    }, []);

    function handleDialogsClose(event) {
        event.preventDefault();
        setQRDialogOpen(false);
    }

    function renderQRCode(qr, width) {
        return (
            <QRArea key={qr.key}>
                <QRCode className="mt-3 mb-3" value={qr.content} size={width} />
                {qr.show_label && qr.label ? qr.label : null}
            </QRArea>
        );
    }

    function getQRBody(params) {
        const { name, map_id, id: map_point_id } = params;
        const { type: qr_type, label_type, label: qr_label } = qrParameters;
        let label = '';
        switch (label_type) {
            case 'name':
                label = name;
                break;
            case 'custom':
                label = qr_label;
                break;
            default:
                break;
        }

        let content = '';
        switch (qr_type) {
            case 'text':
                content = `map_id=${map_id}/map_point_id=${map_point_id}`;
                break;
            case 'json':
                content = JSON.stringify({
                    map_id,
                    map_point_id,
                });
                break;
            default:
                break;
        }
        return {
            key: `qr_${map_point_id}`,
            type: qrParameters.type,
            content,
            show_label: qrParameters.show_label,
            label,
        };
    }

    function renderPreview() {
        const qr = getQRBody({
            id: 'MAP_POINT_ID',
            map_id: 'MAP_ID',
            name: <LocaleMessage msg="page.navmaps.layer.qr_code.label.name" />,
        });

        const dimensions =
            targetRef && targetRef.current ? targetRef.current : null;
        const width = dimensions ? dimensions.offsetWidth : 0;

        return (
            <PreviewArea className="col-md-6 mb-3" ref={targetRef}>
                <div className="col-12 title mb-3">
                    <LocaleMessage msg="page.navmaps.layer.qr_code.preview" />
                </div>
                {renderQRCode(qr, width * 0.4 || 200)}
                <div className="col-12 title mt-5 mb-3">
                    <LocaleMessage msg="page.navmaps.layer.qr_code.preview.content" />
                </div>
                <div className="col-12 content mb-3">{qr.content}</div>
            </PreviewArea>
        );
    }

    function renderLabelSettings() {
        return (
            <>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={
                            qrParameters.show_label
                                ? qrParameters.show_label
                                : false
                        }
                        onChange={event =>
                            setQRParameters({
                                ...qrParameters,
                                show_label: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.navmaps.layer.qr_code.label.show" />
                        }
                    />
                </div>
                {qrParameters.show_label ? (
                    <>
                        <div className="col-12 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.navmaps.layer.qr_code.label.type" />
                                </InputLabel>
                                <Select
                                    required
                                    value={
                                        qrParameters.label_type
                                            ? qrParameters.label_type
                                            : ''
                                    }
                                    onChange={event =>
                                        setQRParameters({
                                            ...qrParameters,
                                            label_type: event.target.value,
                                        })
                                    }
                                >
                                    <MenuItem value="name">
                                        <LocaleMessage msg="page.navmaps.layer.qr_code.label.name" />
                                    </MenuItem>
                                    <MenuItem value="custom">
                                        <LocaleMessage msg="page.navmaps.layer.qr_code.label.custom" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {qrParameters.label_type &&
                        qrParameters.label_type === 'custom' ? (
                            <div className="col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.navmaps.layer.qr_code.label.custom" />
                                    }
                                    fullWidth
                                    value={
                                        qrParameters.label
                                            ? qrParameters.label
                                            : ''
                                    }
                                    onChange={event =>
                                        setQRParameters({
                                            ...qrParameters,
                                            label: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </>
        );
    }

    function renderForm() {
        return (
            <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.navmaps.layer.qr_code.type" />
                        </InputLabel>
                        <Select
                            required
                            value={qrParameters.type ? qrParameters.type : ''}
                            onChange={event =>
                                setQRParameters({
                                    ...qrParameters,
                                    type: event.target.value,
                                })
                            }
                        >
                            <MenuItem value="text">
                                <LocaleMessage msg="page.navmaps.layer.qr_code.type.text" />
                            </MenuItem>
                            <MenuItem value="json">
                                <LocaleMessage msg="page.navmaps.layer.qr_code.type.json" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.navmaps.layer.qr_code.points" />
                        </InputLabel>
                        <Select
                            required
                            value={
                                qrParameters.map_point_id
                                    ? qrParameters.map_point_id
                                    : 'all'
                            }
                            onChange={event =>
                                setQRParameters({
                                    ...qrParameters,
                                    map_point_id: event.target.value,
                                })
                            }
                        >
                            <MenuItem value="all">
                                <LocaleMessage msg="page.navmaps.layer.qr_code.points.all" />
                            </MenuItem>
                            {points.map(p => {
                                return (
                                    <MenuItem value={p.id} key={p.id}>
                                        {p.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                {renderLabelSettings()}
            </div>
        );
    }

    function buildQRList() {
        const p_list =
            qrParameters.map_point_id === 'all'
                ? points
                : points.filter(p => {
                      return p.id === qrParameters.map_point_id;
                  });
        return (
            <PrintableArea ref={printRef}>
                {p_list.map(p => {
                    const qr = getQRBody({ ...p, map_id: mapLayer.map_id });
                    return (
                        <div key={qr.key}>
                            <div className="page-break" />
                            <div className="item">{renderQRCode(qr, 200)}</div>
                        </div>
                    );
                })}
            </PrintableArea>
        );
    }

    function buildQRDialog() {
        return (
            <Dialog open={qrDialogOpen} onClose={handleDialogsClose} fullScreen>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleDialogsClose}
                            aria-label="close"
                        >
                            <MdClose />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {mapLayer ? mapLayer.map_name : ''} - QR Codes
                        </Typography>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    autoFocus
                                    color="inherit"
                                    onClick={handleDialogsClose}
                                >
                                    <LocaleMessage msg="button.print" />
                                </Button>
                            )}
                            content={() => printRef.current}
                        />
                    </Toolbar>
                </AppBar>
                {buildQRList()}
            </Dialog>
        );
    }

    return (
        <PageContent
            title={`${
                mapLayer && mapLayer.map_name ? mapLayer.map_name : ''
            } - QR Codes`}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/maps',
                    title: <LocaleMessage msg="breadcrumbs.nav_maps" />,
                },
                {
                    url: `/tools/maps/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.map_layers" />,
                },
                {
                    url: `/tools/maps/${id}/layer/${layer_id}`,
                    title:
                        mapLayer && mapLayer.map_name ? mapLayer.map_name : '',
                },
            ]}
            loading={isLoading}
        >
            <>
                {qrDialogOpen ? buildQRDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                mapLayer && mapLayer.map_name
                                    ? mapLayer.map_name
                                    : ''
                            }
                            Icon={MdMap}
                        >
                            <>
                                <div
                                    className="col-12 row mb-3"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'top',
                                    }}
                                >
                                    <ParametersForm className="col-md-6 col-12 mb-3">
                                        {renderForm()}
                                    </ParametersForm>
                                    {renderPreview()}
                                </div>
                                <div className="col-12">
                                    <Button
                                        className="p-3"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        onClick={() => {
                                            toast.info(
                                                <LocaleMessage msg="message.generating_file" />
                                            );

                                            setTimeout(() => {
                                                setQRDialogOpen(true);
                                            }, 100);
                                        }}
                                    >
                                        <LocaleMessage msg="page.navmaps.layer.qr_code.generate" />
                                    </Button>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

NavMapForm.propTypes = {
    match: PropTypes.object.isRequired,
};
