/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';

import DataCard from '../DataCard';

export default function RC3OperationsCard({
    dataValues,
    requestError,
    dateSettings,
    userSettings,
}) {
    const loc = dateSettings.locale;
    const date_options = dateSettings.date;
    const label_options = dateSettings.label;

    const { active, colors } = userSettings;

    const availableFilters = [
        { label: 'page.dashboard.applications', value: 'application_id' },
        { label: 'page.dashboard.robots', value: 'robot_id' },
    ];

    const [isLoading, setIsLoading] = useState(true);

    const [rawData, setRawData] = useState({ list: [], period: {} });
    const [parsedData, setParsedData] = useState({});
    const [groupedData, setGroupedData] = useState([]);

    const [activeFilter, setActiveFilter] = useState(availableFilters[0].value);

    function parseData(data) {
        const { list, period, total } = data;

        const period_dates = GetPeriodDates(period);

        const grouped_active = {};
        const grouped_occupied = {};
        list.forEach(o => {
            const date = new Date(o.createdAt).toLocaleDateString(
                loc.format,
                date_options
            );
            if (o.type === 'active') {
                grouped_active[date] = grouped_active[date]
                    ? grouped_active[date] + o.time
                    : o.time;
            } else {
                grouped_occupied[date] = grouped_occupied[date]
                    ? grouped_occupied[date] + o.time
                    : o.time;
            }
        });

        const labels = [];
        const grouped_list = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                loc.format,
                date_options
            );
            const label_date = new Date(p).toLocaleDateString(
                loc.format,
                label_options
            );
            labels.push(label_date);
            grouped_list.push({
                date: label_date,
                active: Math.round(grouped_active[formatted_date] / 60) || '0',
                occupied:
                    Math.round(grouped_occupied[formatted_date] / 60) || '0',
            });
        });

        setParsedData({
            total: {
                active:
                    total && total.active ? Math.round(total.active / 60) : 0,
                occupied:
                    total && total.occupied
                        ? Math.round(total.occupied / 60)
                        : 0,
            },
            list: grouped_list,
            labels,
        });
    }

    async function loadData() {
        setIsLoading(true);
        await api
            .get(`dashboards/operations/period`)
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function filterData(filter) {
        let infoData = [];
        switch (filter) {
            case 'group_id':
                infoData = dataValues.groups;
                break;
            case 'robot_id':
                infoData = dataValues.robots;
                break;
            case 'application_id':
                infoData = dataValues.applications;
                break;
            default:
                break;
        }

        const f_data = {};
        const rawList = rawData && rawData.list ? rawData.list : [];
        rawList.forEach(o => {
            const idx = o[filter];
            if (o.type === 'active') {
                f_data[idx] = f_data[idx] ? f_data[idx] + o.time : o.time;
            }
        });

        if (infoData) {
            const grouped = Object.keys(f_data).map(f => {
                const val = f_data[f];
                const obj = infoData.find(i => {
                    return i.id.toString() === f;
                });
                const parsed_time = GetOperationTime(val);
                const time_label = `${parsed_time.hours ||
                    0}:${parsed_time.minutes || '00'}:${parsed_time.seconds ||
                    '00'}`;
                return {
                    name: obj ? obj.name : '---',
                    value: Math.round(val / 60),
                    time_label,
                };
            });

            setGroupedData(grouped);
        }
    }

    function handleDateFilterChange(value) {
        setActiveFilter(value);
    }

    const CustomTimeTooltip = data => {
        const { payload } = data;
        const payload_name =
            payload[0] && payload[0].name ? payload[0].name : '';
        const payload_content =
            payload[0] && payload[0].payload ? payload[0].payload : {};

        if (active && payload) {
            return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ddd',
                        color: '#000',
                    }}
                >
                    <span>
                        {`${payload_name}: ${
                            payload_content.time_label
                                ? payload_content.time_label
                                : ''
                        }`}
                    </span>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        filterData(activeFilter);
    }, [dataValues, activeFilter, rawData]);

    useEffect(() => {
        parseData(rawData);
    }, [rawData, date_options]);

    useEffect(() => {
        loadData();
    }, [active]);

    const value = `${parsedData.total ? parsedData.total.active : '0'}`;

    const card_title = {
        title: <LocaleMessage msg="page.dashboard.title.operation_logs" />,
        subtitle: <LocaleMessage msg="page.dashboard.operations_period_30" />,
        value,
        color: colors.dashboard_graphs,
        link: '/reports/operations',
    };

    const card_data = {
        data: parsedData.list || [],
        labels: parsedData.labels || [],
        key: 'active',
    };

    const card_filter = {
        available: availableFilters,
        current: activeFilter || 'robot_id',
        grouped: groupedData,
    };

    return (
        <DataCard
            loading={isLoading}
            card={card_title}
            data={card_data}
            filter={card_filter}
            handleDateFilterChange={f => handleDateFilterChange(f)}
            customTooltip={CustomTimeTooltip}
        />
    );
}

RC3OperationsCard.defaultProps = {
    requestError: () => {},
    dateSettings: {},
    userSettings: {},
    dataValues: {},
};

RC3OperationsCard.propTypes = {
    requestError: PropTypes.func,
    dateSettings: PropTypes.object,
    userSettings: PropTypes.object,
    dataValues: PropTypes.object,
};
