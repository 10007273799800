/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

const allow_movement = {
    cruzr: true,
    temi: false,
    robios: false,
};

export default function MovementConfig({
    handleError,
    robotTypes,
    robotGalleries,
    config,
    setConfig,
    currType,
    setCurrType,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const [currAlbum, setCurrAlbum] = useState(null);
    const [movementList, setMovementList] = useState([]);
    const [showMovement, setShowMovement] = useState(false);
    const type_settings = config[currType.slug] || {};

    async function loadMovementList(m_class) {
        setShowMovement(m_class !== 'random' && m_class !== 'no-movement');

        if (!m_class || m_class === 'random' || m_class === 'no-movement') {
            setConfig({ ...config, class: m_class, id: m_class });
        } else {
            setIsLoading(true);
            await api
                .get(`types/${currType.id}/albums/${m_class}/content`)
                .then(response => {
                    const items = response.data;
                    const list = items.map(m => {
                        const movement = m.object;
                        return {
                            id: m.object_id,
                            name: movement.name,
                        };
                    });
                    setMovementList(list);
                    setIsLoading(false);
                })
                .catch(error => handleError(error));
        }
    }

    useEffect(() => {
        loadMovementList(currAlbum);
    }, [currAlbum]);

    useEffect(() => {
        setCurrAlbum(type_settings.class);
    }, [config, currType]);

    function handleCurrTypeEdit(type, key, value) {
        type_settings[key] = value;
        setConfig({ ...config, [type]: type_settings });
        setCurrAlbum(type_settings.class);
    }

    function renderAnimation() {
        return null;
        // return (
        //     <Card className="img-card">
        //         {currMovement ? (
        //             <>
        //                 {currMovement.illustration &&
        //                 currMovement.illustration.url ? (
        //                     <img
        //                         src={currMovement.illustration.url}
        //                         alt={currMovement.illustration.name}
        //                     />
        //                 ) : (
        //                     <div
        //                         className="image"
        //                         style={{
        //                             padding: '20px',
        //                         }}
        //                     >
        //                         <div className="image-placeholder">
        //                             <div
        //                                 className="jr-separator"
        //                                 style={{
        //                                     background: '#999',
        //                                 }}
        //                             />
        //                             <h2
        //                                 style={{
        //                                     color: '#999',
        //                                 }}
        //                             >
        //                                 <LocaleMessage msg="page.applications.conversation.condition.illustration_not_available" />
        //                             </h2>
        //                             <div
        //                                 className="jr-separator"
        //                                 style={{
        //                                     background: '#999',
        //                                 }}
        //                             />
        //                         </div>
        //                     </div>
        //                 )}
        //             </>
        //         ) : null}
        //     </Card>
        // );
    }

    function renderSettings(type) {
        const type_galleries = robotGalleries[type] || {};

        const mov_galleries = type_galleries.movements || [];

        return (
            <>
                <div
                    className="col-md-8 col-12 row mb-5"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {allow_movement[type] ? (
                        <>
                            <div className="col-6 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.applications.conversation.condition.movement.class" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            type_settings.class || 'no-movement'
                                        }
                                        onChange={event =>
                                            handleCurrTypeEdit(
                                                type,
                                                'class',
                                                event.target.value
                                            )
                                        }
                                    >
                                        <MenuItem value="no-movement">
                                            <LocaleMessage msg="label.form.no_movement" />
                                        </MenuItem>
                                        {mov_galleries.map(m => (
                                            <MenuItem key={m.id} value={m.id}>
                                                {m.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {showMovement &&
                            type_settings.class &&
                            type_settings.class !== 'no-movement' ? (
                                <div className="col-6 mb-3">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="label.form.movement" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                type_settings.movement_id
                                                    ? type_settings.movement_id
                                                    : 'no-movement'
                                            }
                                            onChange={event =>
                                                handleCurrTypeEdit(
                                                    type,
                                                    'movement_id',
                                                    event.target.value
                                                )
                                            }
                                        >
                                            <MenuItem value="random">
                                                <LocaleMessage msg="label.form.random_movement" />
                                            </MenuItem>
                                            {movementList.map(m => (
                                                <MenuItem
                                                    key={m.id}
                                                    value={m.id}
                                                >
                                                    {m.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            ) : null}
                            {renderAnimation()}
                        </>
                    ) : (
                        <LocaleMessage msg="page.applications.conversation.condition.movement.unavailable.robot" />
                    )}
                </div>
            </>
        );
    }

    return (
        <div
            className="col-12 row mt-2 mb-4"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <AppConfigRobotType
                className="col-12 mb-5"
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={t => setCurrType(t)}
            >
                {isLoading ? (
                    <div
                        className="col-12 row mt-2 mb-5"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={50}
                            width={50}
                        />
                    </div>
                ) : (
                    renderSettings(currType.slug)
                )}
            </AppConfigRobotType>
        </div>
    );
}

MovementConfig.defaultProps = {
    robotGalleries: {},
    config: {},
};

MovementConfig.propTypes = {
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    robotGalleries: PropTypes.object,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
