/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdContactPhone,
    MdFormatListBulleted,
    MdCancel,
    MdAdd,
    MdPeople,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import ContactForm from '~/components/Contacts/Form';
import DataTable from '~/components/DataTable';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import ContactUsers from './ContactUsers';

export default function ContactsForm({ match }) {
    const dispatch = useDispatch();
    const contact_id = match.params.id;
    const bindingBody = {
        contact_id,
    };

    const user_settings = useSelector(state => state.settings || null);
    const { active } = user_settings;

    const [allowEdit, setAllowEdit] = useState(
        contact_id !== 'new' || active.id !== '*'
    );

    const [operation, setOperation] = useState('create');

    const [bindingOperation, setBindingOperation] = useState('create');
    const [currListID, setCurrListID] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [availableLists, setAvailableLists] = useState([]);
    const [contactLists, setContactLists] = useState([]);
    const [contact, setContact] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const parentPage = `/tools/contacts#tab=contacts`;
    const defBody = { name: '', source: 'other' };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadAvailableLists() {
        await api
            .get(`/contact_lists`)
            .then(response => {
                const c_list = response.data;
                const lists = c_list.map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${c.source}`}
                            />
                        ),
                    };
                });
                setAvailableLists(lists);
            })
            .catch(error => requestError(error));
    }

    async function loadContactLists(_id) {
        await api
            .get(`/contacts/${_id}/lists`)
            .then(response => {
                const c_list = response.data.lists;
                const lists = c_list.map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${c.source}`}
                            />
                        ),
                    };
                });
                setContactLists(lists);
            })
            .catch(error => requestError(error));
    }

    async function loadContact(_id) {
        if (_id === 'new') {
            setOperation('create');
            setAllowEdit(true);
        } else {
            setOperation('update');
            loadContactLists(_id);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadAvailableLists();
    }, []);

    useEffect(() => {
        loadContact(contact_id);
    }, [contact_id]);

    async function bindContact() {
        return api
            .post(`/contact_lists/${currListID}/contacts`, bindingBody)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function unbindContact() {
        return api
            .delete(`/contact_lists/${currListID}/contacts/${contact_id}`)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function submitBinding() {
        setIsLoading(true);
        setDialogOpen(false);

        let success = true;
        if (bindingOperation === 'create') {
            success = await bindContact();
        } else {
            success = await unbindContact();
        }

        if (success) {
            toast.success(
                <LocaleMessage msg="page.tools.contacts.form.update_success" />
            );
        }

        loadContactLists(contact_id);
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setCurrListID('');
        setDialogOpen(false);
    }

    function handleDialogOpen(event, _id) {
        event.preventDefault();
        setCurrListID(_id === 'new' ? '' : _id);
        setBindingOperation(_id === 'new' ? 'create' : 'delete');
        setDialogOpen(true);
    }

    function renderBindingForm() {
        const used = {};
        contactLists.forEach(l => {
            used[l.id] = true;
        });
        const notBound = availableLists.filter(l => {
            return !used[l.id];
        });

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-8 col-12">
                    {notBound && notBound.length > 0 ? (
                        <FormSelect
                            classes="mb-5"
                            options={notBound}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.tools.contacts.form.label.contact.choose" />
                            }
                            onChange={value => {
                                setCurrListID(value);
                            }}
                            value={currListID || ''}
                        />
                    ) : (
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.contacts.form.label.contact.choose" />
                            </InputLabel>
                            <Select>
                                <MenuItem value="" disabled>
                                    <LocaleMessage msg="page.tools.contacts.form.label.contact.no_free_lists" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </div>
            </div>
        );
    }

    function renderDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event)}
                title={
                    <LocaleMessage
                        msg={`page.tools.contacts.form.label.contact.${
                            bindingOperation === 'create' ? 'bind' : 'unbind'
                        }`}
                    />
                }
                content={
                    bindingOperation === 'create' ? renderBindingForm() : null
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitBinding(),
                        label: (
                            <LocaleMessage
                                msg={
                                    bindingOperation === 'create'
                                        ? 'button.save'
                                        : 'button.delete'
                                }
                            />
                        ),
                    },
                ]}
            />
        );
    }

    function renderLists() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'source_type',
                label: <LocaleMessage msg="table.headers.source" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdCancel />,
                action: handleDialogOpen,
            },
        ];

        return (
            <>
                <div className="body-top-controls">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAdd />}
                        style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                        }}
                        onClick={event => handleDialogOpen(event, 'new')}
                    >
                        <LocaleMessage msg="page.tools.contacts.form.label.contact.add" />
                    </Button>
                </div>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={contactLists}
                        orderColumn="name"
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    function renderContactForm() {
        return (
            <ContactForm
                defaultBody={defBody}
                contactId={contact_id}
                allowEdit={allowEdit}
                linkRoot={parentPage}
                updateContact={c => setContact(c)}
            />
        );
    }

    function renderContactUsers() {
        return (
            <ContactUsers
                contact_id={contact_id}
                contact={contact}
                requestError={e => requestError(e)}
                allowEdit={allowEdit}
                group_id={contact.group_id}
            />
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.contacts.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.contacts.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: parentPage,
                    title: <LocaleMessage msg="breadcrumbs.contact_lists" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? renderDialog() : null}
                <div className="row full-body mt-5">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.contacts.title" />
                            }
                            Icon={MdContactPhone}
                        >
                            <>{renderContactForm()}</>
                        </CardSideBordered>
                    </div>
                </div>
                {operation === 'update' ? (
                    <div className="row full-body">
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.tools.contacts.form.label.users" />
                                }
                                hide
                                Icon={MdPeople}
                            >
                                <>{renderContactUsers()}</>
                            </CardSideBordered>
                        </div>
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.tools.contacts.form.label.bound" />
                                }
                                hide
                                Icon={MdFormatListBulleted}
                            >
                                <>{renderLists()}</>
                            </CardSideBordered>
                        </div>
                    </div>
                ) : null}
            </>
        </PageContent>
    );
}

ContactsForm.propTypes = {
    match: PropTypes.object.isRequired,
};
