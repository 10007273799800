/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdContacts } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import Page404 from '~/pages/Open/Page404';
import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import InviteList from './InviteList';
import InviteManager from './InviteManager';
import Settings from './Settings';

const useStyles = makeStyles(theme => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
            textAlign: 'left',
        },
    };
});

const settings = [
    {
        label: (
            <LocaleMessage msg="label.settings.app.invite_manager.application" />
        ),
        value: 'application',
    },
    {
        label: (
            <LocaleMessage msg="label.settings.app.invite_manager.invites" />
        ),
        value: 'invites',
    },
    {
        label: (
            <LocaleMessage msg="label.settings.app.invite_manager.settings" />
        ),
        value: 'settings',
    },
];

const pages = {
    settings: Settings,
    invites: InviteList,
    application: InviteManager,
};

export default function InviteManagerApp({
    location,
    setToast,
    appSettings,
    updateObject,
}) {
    const classes = useStyles();

    const page_settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(page_settings);
    const { search } = location;

    const app_id = appSettings ? appSettings.id : null;

    const [isLoading, setIsLoading] = useState(false);
    const [queryTab, setQueryTab] = useQueryState('tab', 'application');

    const [appFormatters, setAppFormatters] = useState([]);
    const [value, setValue] = useState(queryTab || settings[0].value);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

        if (search !== newValue) {
            setQueryTab(newValue);
        }
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            setToast({
                color: 'error',
                body: message,
            });
        } else if (error.request) {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.request" />,
            });
        } else {
            setToast({
                color: 'error',
                body: <LocaleMessage msg="errors.unknown" />,
            });
        }
        setIsLoading(false);
    }

    async function loadAppFormatters() {
        if (app_id) {
            await api
                .get(`applications/formatters/${app_id}`)
                .then(response => {
                    const l = response.data;
                    const formatters = l.map(f => {
                        const formatter = f.formatter || {};
                        const updated = new Date(f.updated);
                        return {
                            ...f,
                            formatter: formatter.private ? (
                                formatter.name
                            ) : (
                                <LocaleMessage
                                    msg={`tools.info_formatters.${formatter.name}`}
                                />
                            ),
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc.locale,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setAppFormatters(formatters);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadAppFormatters();
    }, [app_id]);

    function renderInternalPage(page) {
        const Page = pages[page] || Page404;
        return (
            <Page
                updateObject={data => updateObject(data)}
                appSettings={appSettings}
                setToast={message => setToast(message)}
                requestError={error => requestError(error)}
                formatters={appFormatters}
                loadFormatters={() => loadAppFormatters()}
            />
        );
    }

    return (
        <div className="col-12 mb-5 mt-5">
            {isLoading ? (
                <Splash />
            ) : (
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.applications.invite_manager.title" />
                    }
                    Icon={MdContacts}
                    hide
                >
                    <>
                        <div className="sidecard-body">
                            <>
                                <Tabs
                                    indicatorColor="primary"
                                    value={value || '0'}
                                    onChange={handleChangeTab}
                                    className={classes.tabs}
                                    centered
                                >
                                    {settings.map(s => {
                                        return (
                                            <Tab
                                                key={s.value}
                                                label={s.label}
                                                value={s.value}
                                            />
                                        );
                                    })}
                                </Tabs>

                                <div style={{ flex: 1 }}>
                                    {renderInternalPage(value)}
                                </div>
                            </>
                        </div>
                    </>
                </CardSideBordered>
            )}
        </div>
    );
}

InviteManagerApp.propTypes = {
    appSettings: PropTypes.object.isRequired,
    updateObject: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
};
