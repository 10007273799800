/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSettings, MdAdd, MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { ParameterArea, DeleteParameter } from './styles';

export default function PlugintemplateForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id, action_id, template_id } = match.params;

    const operation = template_id === 'new' ? 'create' : 'update';

    const [body, setBody] = useState({
        type: 'parameters',
        content: {},
        slug: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const types = [
        {
            value: 'parameters',
            label: <LocaleMessage msg="label.form.parameters" />,
        },
        {
            value: 'route',
            label: <LocaleMessage msg="page.template.form.label.route" />,
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadTemplate(_id) {
        if (operation === 'update') {
            setIsLoading(true);
            await api
                .get(`integrations/${_id}`)
                .then(response => {
                    const t = response.data;
                    setBody(t);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    useEffect(() => {
        loadTemplate(template_id);
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, action_id };

        if (operation === 'create') {
            await api
                .post(`integrations`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.template.form.create_success" />
                    );

                    history.push(`/plugintypes/${id}/actions/${action_id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`integrations/${template_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.template.form.update_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    function handleTemplateTypeChange(type) {
        if (body.type !== type) {
            setBody({
                ...body,
                type,
                content: {},
            });
        }
    }

    function handleNewParameter() {
        const items = body.content || {};
        const item_id = (+new Date()).toString(36);
        items[item_id] = { item_id, name: '', description: '' };

        setBody({ ...body, content: items });
    }

    function handleDeleteParameter(idx) {
        const items = body.content || {};
        if (!items[idx]) {
            return;
        }
        delete items[idx];
        setBody({ ...body, content: items });
    }

    function onParameterChange(event, index, key) {
        const items = body.content || {};
        if (items[index]) {
            items[index] = {
                ...items[index],
                [key]: event.target.value,
            };
        }

        setBody({ ...body, content: items });
    }

    function buildParameterList(parameters) {
        return Object.keys(parameters).map(idx => {
            const item = parameters[idx];
            return (
                <div className="col-12 row mb-3" key={item.item_id}>
                    <div className="col-11 row">
                        <div className="col-4">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.template.form.label.name" />
                                }
                                fullWidth
                                value={item && item.name ? item.name : ''}
                                onChange={event =>
                                    onParameterChange(
                                        event,
                                        item.item_id,
                                        'name'
                                    )
                                }
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.template.form.label.default_value" />
                                }
                                fullWidth
                                value={
                                    item && item.default_value
                                        ? item.default_value
                                        : ''
                                }
                                onChange={event =>
                                    onParameterChange(
                                        event,
                                        item.item_id,
                                        'default_value'
                                    )
                                }
                            />
                        </div>
                        <div className="col-5">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.template.form.label.description" />
                                }
                                fullWidth
                                value={
                                    item && item.description
                                        ? item.description
                                        : ''
                                }
                                onChange={event =>
                                    onParameterChange(
                                        event,
                                        item.item_id,
                                        'description'
                                    )
                                }
                            />
                        </div>
                    </div>

                    <DeleteParameter className="col-1">
                        <MdDelete
                            size={18}
                            onClick={() => handleDeleteParameter(item.item_id)}
                        />
                    </DeleteParameter>
                </div>
            );
        });
    }

    function buildParameterForm() {
        return (
            <>
                <div style={{ padding: '20px 15px' }}>
                    <h3>
                        <LocaleMessage msg="label.form.parameters" />
                    </h3>
                    <ParameterArea className="row mt-1 mb-5">
                        {buildParameterList(body.content)}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewParameter()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="label.form.add_parameter" />
                        </Button>
                    </ParameterArea>
                </div>
            </>
        );
    }

    function buildRouteForm() {
        return (
            <div className="col-12 mb-5">
                <TextField
                    id="action-config"
                    label={
                        <LocaleMessage msg="page.actions.form.label.config_route" />
                    }
                    fullWidth
                    value={
                        body.content && body.content.route
                            ? body.content.route
                            : ''
                    }
                    onChange={event =>
                        setBody({
                            ...body,
                            content: {
                                ...body.content,
                                route: event.target.value,
                            },
                        })
                    }
                />
            </div>
        );
    }

    function buildTemplateContent() {
        switch (body.type) {
            case 'parameters':
                return buildParameterForm();
            case 'route':
                return buildRouteForm();
            default:
                return null;
        }
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.template.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.template.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/plugintypes',
                    title: <LocaleMessage msg="breadcrumbs.plugin_types" />,
                },
                {
                    url: `/plugintypes/${id}`,
                    title: <LocaleMessage msg="breadcrumbs.actions" />,
                },
                {
                    url: `/plugintypes/${id}/actions/${action_id}`,
                    title: <LocaleMessage msg="breadcrumbs.plugintemplates" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.template.form.label.settings" />
                            }
                            Icon={MdSettings}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.template.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="label.form.slug" />
                                            }
                                            fullWidth
                                            value={body.slug || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.template.form.label.type" />
                                            </InputLabel>
                                            <Select
                                                id="template-type"
                                                disabled={
                                                    operation === 'update'
                                                }
                                                value={
                                                    body.type
                                                        ? body.type
                                                        : 'parameters'
                                                }
                                                onChange={event =>
                                                    handleTemplateTypeChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                {types.map(l => {
                                                    return (
                                                        <MenuItem
                                                            value={l.value}
                                                            key={l.value}
                                                        >
                                                            {l.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {buildTemplateContent()}
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

PlugintemplateForm.propTypes = {
    match: PropTypes.object.isRequired,
};
