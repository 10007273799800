import React from 'react';
import ReactLoading from 'react-loading';

import icon from '~/assets/icon-white.png';

import { PageSplash } from './styles';

export default function SplashOutside() {
    return (
        <PageSplash>
            <img alt="Pluginbot Icon" src={icon} style={{ height: 60 }} />
            <ReactLoading type="bubbles" height={128} width={128} />
        </PageSplash>
    );
}
