/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
// import { MdStarBorder, MdStar } from 'react-icons/md';
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import ExitDialog from '~/components/ExitDialog';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { Column, SettingsArea, ParameterArea } from '../styles';

export default function Settings({
    app,
    group,
    handleError,
    updateObject,
    needSaving,
    setNeedSaving,
}) {
    const { settings: appConfig, app_type } = app;
    const appRobots = app.robot_types || [];

    const appSettings =
        appConfig && appConfig.settings ? appConfig.settings : {};

    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState(appSettings);

    const [maps, setMaps] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const appMap = config && config.map_id ? config.map_id : '';

    async function loadMaps(app_type_id) {
        setIsLoading(true);
        await api
            .get(`map_layers?application=${app_type_id}`)
            .then(async response => {
                const filtered = response.data.filter(m => {
                    const r_type = m.robot_type;
                    const r_filter =
                        r_type.id === null ||
                        appRobots.find(t => {
                            return t.id === r_type.id;
                        });
                    const g_filter = m.group && m.group.id === group;
                    return r_filter && g_filter;
                });

                const data = filtered.map(m => ({
                    ...m,
                    name: m.map ? m.map.name : '',
                }));
                setMaps(data);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function setSettings(key, val) {
        setConfig({
            ...config,
            [key]: val,
        });
        setNeedSaving(true);
    }

    function handleSubmit() {
        updateObject({ ...app, settings: config });
    }

    function buildConfirmDialog() {
        return (
            <ExitDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                destination="/tools/maps"
            />
        );
    }

    useEffect(() => {
        loadMaps(app_type.id);
    }, [app_type]);

    function renderMapConfig() {
        return (
            <div
                className="col-12 row"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <div className="col-md-6 col-12">
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.applications.indoor_delivery.maps" />
                        </InputLabel>
                        <Select
                            value={appMap}
                            onChange={event =>
                                setSettings('map_id', event.target.value)
                            }
                        >
                            {maps.map(m => (
                                <MenuItem value={m.id} key={m.id}>
                                    {m.name} [{m.reference}]
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-6 col-12 mb-5">
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                        }}
                        onClick={() => setDialogOpen(true)}
                    >
                        <LocaleMessage msg="page.applications.indoor_delivery.maps.edit" />
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Column>
            {dialogOpen ? buildConfirmDialog() : null}
            <SettingsArea className="row col-12 mt-5 mb-5">
                <div className="col-md-8 col-12 row">
                    {isLoading ? (
                        <Splash />
                    ) : (
                        <ParameterArea className="row pt-5 px-3">
                            {renderMapConfig()}
                        </ParameterArea>
                    )}
                </div>
                <Button
                    className="col-md-8 col-12 p-3 mt-5"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => handleSubmit()}
                    disabled={!needSaving}
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </SettingsArea>
        </Column>
    );
}

Settings.defaultProps = {};

Settings.propTypes = {
    app: PropTypes.object.isRequired,
    group: PropTypes.string.isRequired,
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    needSaving: PropTypes.bool.isRequired,
    setNeedSaving: PropTypes.func.isRequired,
};
