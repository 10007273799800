/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPlaylistAdd } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { TextField, Button } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FormStepper from '~/components/Form/Stepper';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import AnswerSetup from './Components/AnswerSetup';
import ConditionsSetup from './Components/ConditionsSetup';

export default function AnswerForm({ match }) {
    const dispatch = useDispatch();

    const s_settings = useSelector(state => state.settings);
    const date_loc = getDateLocale(s_settings);

    const { plugin_id, id, answer_id } = match.params;

    const defaultBody = {};
    const defaultAnswers = {};
    const defaultConditions = [];

    const [isLoading, setIsLoading] = useState(true);
    const [operation, setOperation] = useState('create');

    const [pluginConfig, setPluginConfig] = useState(null);
    const [configLanguages, setConfigLanguages] = useState([]);

    const [activeStep, setActiveStep] = useState(0);

    const [body, setBody] = useState(defaultBody);
    const [answerSetup, setAnswerSetup] = useState(defaultAnswers);
    const [conditionsSetup, setConditionsSetup] = useState(defaultConditions);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPluginconfig(_id) {
        await api
            .get(`pluginconfigs/${_id}`)
            .then(async response => {
                const config = response.data;
                const parameters = config.parameters || {};

                const langs = parameters.languages || [];
                const languages = langs.map(l => {
                    return {
                        value: l,
                        label: `list.languages.${l}`,
                    };
                });

                setConfigLanguages(languages);
                setPluginConfig({
                    name: config.name,
                    action_id:
                        config.action && config.action.id
                            ? config.action.id
                            : null,
                    group_id:
                        config.group && config.group.id
                            ? config.group.id
                            : null,
                    plugintemplate_id:
                        config.plugintemplate && config.plugintemplate.id
                            ? config.plugintemplate.id
                            : null,
                    type:
                        config.plugintemplate && config.plugintemplate.type
                            ? config.plugintemplate.type
                            : null,
                });
            })
            .catch(error => requestError(error));
    }

    async function loadAnswerGroup(_id) {
        if (_id !== 'new') {
            setOperation('update');
            await api
                .get(`answer_collections/${id}/answers/${_id}`)
                .then(response => {
                    const { data } = response;
                    setBody({
                        description: data.description,
                    });
                    setAnswerSetup(data.answers || defaultAnswers);
                    setConditionsSetup(data.conditions || defaultConditions);
                })
                .catch(error => requestError(error));
        } else {
            setOperation('create');
        }
    }

    async function loadAll() {
        Promise.all([loadPluginconfig(id), loadAnswerGroup(answer_id)]).then(
            () => {
                setIsLoading(false);
            }
        );
    }

    useEffect(() => {
        loadAll();
    }, []);

    async function handleSubmit() {
        const data = {
            ...body,
            answers: answerSetup,
            conditions: conditionsSetup,
        };
        setIsLoading(true);
        if (operation === 'create') {
            await api
                .post(`answer_collections/${id}/answers`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.answer_groups.form.create_success" />
                    );
                    history.push(`/pluginconfig/${plugin_id}/answers/${id}`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`answer_collections/${id}/answers/${answer_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.answer_groups.form.update_success" />
                    );
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function handleStepClick(step) {
        setActiveStep(step);
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    // =========================================================================
    // STEP COMPONENTS
    // =========================================================================

    function renderDescription() {
        return (
            <div className="row">
                <div className="col-12 mb-5">
                    <TextField
                        label={<LocaleMessage msg="label.form.description" />}
                        fullWidth
                        value={body.description || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                description: event.target.value,
                            })
                        }
                    />
                </div>
            </div>
        );
    }

    function renderConditionSetup() {
        return (
            <ConditionsSetup
                locale={date_loc}
                settings={conditionsSetup}
                onChange={s => setConditionsSetup(s)}
            />
        );
    }

    function renderAnswerSetup() {
        return (
            <>
                {/* <div className="col-12 mb-3">
                    <LocaleMessage msg="page.answer_groups.form.label.texts.variables" />
                </div> */}
                <AnswerSetup
                    settings={answerSetup}
                    onChange={s => setAnswerSetup(s)}
                    languages={configLanguages}
                    handleError={err => requestError(err)}
                />
            </>
        );
    }

    const steps = [
        {
            key: 'description',
            label: (
                <LocaleMessage msg="page.answer_groups.form.step.description" />
            ),
            content: renderDescription(),
        },
        {
            key: 'conditions_setup',
            label: (
                <LocaleMessage msg="page.answer_groups.form.step.conditions_setup" />
            ),
            content: renderConditionSetup(),
        },
        {
            key: 'answers_setup',
            label: (
                <LocaleMessage msg="page.answer_groups.form.step.answers_setup" />
            ),
            content: renderAnswerSetup(),
        },
    ];

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.answer_groups.form.title.create" />
                ) : (
                    <LocaleMessage msg="page.answer_groups.form.title.edit" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/plugins',
                    title: <LocaleMessage msg="breadcrumbs.plugins" />,
                },
                {
                    url: `/plugins/${plugin_id}`,
                    title: (
                        <LocaleMessage msg="page.answer_groups.list.title" />
                    ),
                },
                {
                    url: `/pluginconfig/${plugin_id}/answers/${id}`,
                    title: pluginConfig ? pluginConfig.name : '---',
                },
            ]}
            loading={isLoading}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-10 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.answer_groups.form.title" />
                            }
                            Icon={MdPlaylistAdd}
                        >
                            <FormStepper
                                steps={steps}
                                activeStep={activeStep}
                                handleStepClick={index =>
                                    handleStepClick(index)
                                }
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                handleSubmit={() => handleSubmit()}
                            />
                            <Button
                                className="mt-3 mb-3"
                                variant="contained"
                                color="primary"
                                onClick={event => handleSubmit(event)}
                                fullWidth
                                size="large"
                            >
                                <LocaleMessage msg="button.save" />
                            </Button>
                        </CardSideBordered>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

AnswerForm.propTypes = {
    match: PropTypes.object.isRequired,
};
