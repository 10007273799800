/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdAdd } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    DialogContentText,
    Chip,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { OptionArea, ParameterArea } from '../../styles';

const center = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const layout_settings = {
    step_default: {
        max_rating: 10,
    },
    step_side_by_side: {
        max_rating: 5,
    },
};

export default function AnswerSetup({
    colors,
    visual,
    mainSettings,
    setMainSettings,
    settings,
    onChange,
    languages,
    template,
}) {
    const defColor = colors && colors.primary ? colors.primary : '#000';
    const stepColor = visual && visual.color ? visual.color : defColor;
    const template_settings = layout_settings[template] || {};

    const [currLang, setCurrLang] = useState(languages.default || 'pt_BR');
    const [currItem, setCurrItem] = useState('');
    const [tempItem, setTempItem] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [optionDialogOpen, setOptionDialogOpen] = useState(false);

    const answer_modes = {
        voice_first: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.mode.voice_first" />
            ),
            defaults: {
                tries: 2,
            },
        },
        text_first: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.mode.text_first" />
            ),
            defaults: {},
        },
        text_options: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.mode.text_options" />
            ),
            defaults: {
                cols: 1,
                multi: 'single',
            },
        },
        binary_options: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.mode.binary_options" />
            ),
            defaults: {
                cols: 2,
                multi: 'single',
                multilanguage: true,
                options: [
                    {
                        id: 1,
                        label: {
                            pt_BR: 'NÃO',
                            pt_PT: 'NÃO',
                            es_ES: 'NO',
                            en_US: 'NO',
                            en_GB: 'NO',
                        },
                        value: {
                            pt_BR: 'false',
                            pt_PT: 'false',
                            es_ES: 'false',
                            en_US: 'false',
                            en_GB: 'false',
                        },
                    },
                    {
                        id: 2,
                        label: {
                            pt_BR: 'SIM',
                            pt_PT: 'SIM',
                            es_ES: 'SI',
                            en_US: 'YES',
                            en_GB: 'YES',
                        },
                        value: {
                            pt_BR: 'true',
                            pt_PT: 'true',
                            es_ES: 'true',
                            en_US: 'true',
                            en_GB: 'true',
                        },
                    },
                ],
            },
        },
        rating: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.mode.rating" />
            ),
            defaults: {
                max_rating: 5,
            },
        },
    };

    const answer_types = [
        {
            value: 'text',
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.type.text" />
            ),
        },
        {
            value: 'number',
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.type.number" />
            ),
        },
        {
            value: 'date',
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.type.date" />
            ),
        },
    ];

    const answer_number = [
        {
            value: 'single',
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.multiplicity.single" />
            ),
        },
        {
            value: 'multi',
            label: (
                <LocaleMessage msg="page.survey_steps.form.input.label.multiplicity.multi" />
            ),
        },
    ];

    function onSaveTextOption(event, index) {
        const { options } = settings;
        options[index] = tempItem;

        onChange({ ...settings, options });
        setCurrItem('');
        setTempItem(null);
        setOptionDialogOpen(false);
    }

    function removeOption(idx) {
        setDeleteDialogOpen(false);
        setOptionDialogOpen(false);
        let { options } = settings;
        if (!options) {
            options = [];
        }
        if (!options[idx]) {
            return;
        }
        options.splice(idx, 1);
        onChange({ ...settings, options });
    }

    function handleModeSelection(event) {
        const mode = event.target.value;
        const mode_settings = answer_modes[mode] || {};

        const mode_defaults = mode_settings.defaults || {};

        onChange({
            ...settings,
            mode,
            ...mode_defaults,
        });
    }

    function handleNewOptionClick() {
        let { options } = settings;
        if (!options) {
            options = [];
        }
        const item_id = (+new Date()).toString(36);
        options.push({ item_id, label: {}, value: {} });
        onChange({ ...settings, options });
    }

    function handleDialogsClose() {
        setCurrItem('');
        setTempItem(null);
        setOptionDialogOpen(false);
        setDeleteDialogOpen(false);
    }

    function handleOptionDialogOpen(event, index) {
        const item = settings.options[index];
        setCurrItem(index);
        setTempItem(item);
        event.preventDefault();
        setOptionDialogOpen(true);
    }

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;

        const list = [...settings.options];
        const [removed] = list.splice(source.index, 1);
        list.splice(destination.index, 0, removed);

        onChange({ ...settings, options: list });
    }

    function renderDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage msg="page.survey_steps.form.input.label.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => handleDialogsClose(),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removeOption(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderLanguageSelector() {
        const lang_list = languages.list || [];
        return (
            <FormControl fullWidth>
                <InputLabel>
                    <LocaleMessage msg="page.survey_steps.form.input.label.options.multilanguage.see_as" />
                </InputLabel>
                <Select
                    value={currLang}
                    onChange={event => setCurrLang(event.target.value)}
                >
                    {lang_list
                        ? lang_list.map(l => {
                              return (
                                  <MenuItem value={l} key={l}>
                                      <LocaleMessage
                                          msg={`list.languages.${l}`}
                                      />
                                  </MenuItem>
                              );
                          })
                        : null}
                </Select>
            </FormControl>
        );
    }

    function renderLanguageInputs(key, label) {
        const inputs =
            tempItem[key] && typeof tempItem[key] === 'object'
                ? tempItem[key]
                : {
                      pt_BR: tempItem[key],
                  };
        return (
            <ParameterArea className="col-12 mt-3">
                <TextField
                    className="mb-1"
                    fullWidth
                    label={label}
                    value={inputs[currLang] || ''}
                    onChange={event =>
                        setTempItem({
                            ...tempItem,
                            [key]: {
                                ...inputs,
                                [currLang]: event.target.value,
                            },
                        })
                    }
                />
            </ParameterArea>
        );
    }

    function renderOptionDialog() {
        return (
            <SimpleDialog
                open={optionDialogOpen}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage msg="page.survey_steps.form.input.label.options.edit" />
                }
                content={
                    <>
                        {settings.multilanguage ? (
                            <div className="col-12" style={center}>
                                <div className="col-md-8">
                                    {renderLanguageSelector()}
                                </div>
                            </div>
                        ) : null}
                        {renderLanguageInputs(
                            'label',
                            <LocaleMessage msg="page.survey_steps.form.input.label.options.text" />
                        )}
                        {renderLanguageInputs(
                            'value',
                            <LocaleMessage msg="page.survey_steps.form.input.label.options.value" />
                        )}
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => handleDialogsClose(),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    ...(settings.mode === 'text_options'
                        ? [
                              {
                                  key: 'remove',
                                  onClick: () => setDeleteDialogOpen(true),
                                  label: <LocaleMessage msg="button.remove" />,
                              },
                          ]
                        : []),
                    {
                        key: 'edit',
                        onClick: event => onSaveTextOption(event, currItem),
                        label: <LocaleMessage msg="button.edit" />,
                    },
                ]}
            />
        );
    }

    function renderOptionButton(size, item, index) {
        const texts =
            item.label && typeof item.label === 'object'
                ? item.label
                : {
                      pt_BR: item.label,
                  };

        return (
            <Draggable
                key={item.item_id}
                draggableId={item.item_id}
                index={index}
                className={`${size} col-12 option-card`}
            >
                {drag_provided => {
                    return (
                        <div
                            className={`${size} col-12 option-card`}
                            ref={drag_provided.innerRef}
                            {...drag_provided.draggableProps}
                            {...drag_provided.dragHandleProps}
                            style={{
                                userSelect: 'none',
                                ...drag_provided.draggableProps.style,
                            }}
                        >
                            <Chip
                                className="mb-3 option-button"
                                label={texts[currLang] || ''}
                                variant="outlined"
                                color="primary"
                                onClick={event =>
                                    handleOptionDialogOpen(event, index)
                                }
                            />
                        </div>
                    );
                }}
            </Draggable>
        );
    }

    function renderOptionHeader() {
        return (
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #ccc',
                }}
            >
                <div className="col-md-6 col-12">
                    <FormSwitch
                        value={settings.multilanguage || false}
                        onChange={event =>
                            onChange({
                                ...settings,
                                multilanguage: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.survey_steps.form.input.label.options.multilanguage" />
                        }
                    />
                </div>
                {settings.multilanguage ? (
                    <div className="col-md-3 col-12 mb-3">
                        {renderLanguageSelector()}
                    </div>
                ) : null}
            </div>
        );
    }

    function renderOptionButtons() {
        const cols = `${settings.cols || '1'}`;
        let size = 'col-md-8';

        switch (cols) {
            case '1':
                size = 'col-md-8';
                break;
            case '2':
                size = 'col-md-6';
                break;
            case '3':
                size = 'col-md-4';
                break;
            case '4':
                size = 'col-md-3';
                break;
            default:
                size = 'col-md-4';
                break;
        }

        return (
            <div className="row">
                <div className="col-12" style={center}>
                    <OptionArea color={stepColor}>
                        {settings.dynamic ? (
                            <Card
                                className="area-content p-5"
                                style={{
                                    width: '100%',
                                    ...center,
                                }}
                            >
                                <LocaleMessage msg="page.survey_steps.form.input.label.options.dynamic.helper" />
                            </Card>
                        ) : (
                            <>
                                <DragDropContext
                                    onDragEnd={result => onDragEnd(result)}
                                >
                                    <Droppable droppableId="condition-list">
                                        {(drop_provided, drop_snapshot) => {
                                            return (
                                                <Card
                                                    className="area-content"
                                                    {...drop_provided.droppableProps}
                                                    ref={drop_provided.innerRef}
                                                    style={{
                                                        background: drop_snapshot.isDraggingOver
                                                            ? 'lightgrey'
                                                            : 'white',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {renderOptionHeader()}
                                                    <div className="row option-row mt-3">
                                                        {settings &&
                                                        settings.options
                                                            ? settings.options.map(
                                                                  (
                                                                      item,
                                                                      index
                                                                  ) =>
                                                                      renderOptionButton(
                                                                          size,
                                                                          item,
                                                                          index
                                                                      )
                                                              )
                                                            : null}
                                                    </div>
                                                    {drop_provided.placeholder}
                                                </Card>
                                            );
                                        }}
                                    </Droppable>
                                </DragDropContext>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={() => handleNewOptionClick()}
                                    disabled={settings.mode !== 'text_options'}
                                    startIcon={
                                        <MdAdd
                                            style={{
                                                color: '#fff',
                                            }}
                                        />
                                    }
                                >
                                    <LocaleMessage msg="page.survey_steps.form.input.label.options.add" />
                                </Button>
                            </>
                        )}
                    </OptionArea>
                </div>
            </div>
        );
    }

    function renderTextInputSettings() {
        return (
            <>
                <div className="col-md-4 col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.survey_steps.form.input.label.type" />
                        </InputLabel>
                        <Select
                            id="answer-data-type"
                            value={settings.type ? settings.type : 'text'}
                            onChange={event =>
                                onChange({
                                    ...settings,
                                    type: event.target.value,
                                })
                            }
                        >
                            {answer_types.map(v => {
                                return (
                                    <MenuItem value={v.value} key={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-4 col-12 mb-5">
                    <TextField
                        label={
                            <LocaleMessage msg="page.survey_steps.form.input.label.default" />
                        }
                        fullWidth
                        value={settings.default_value || ''}
                        onChange={event =>
                            onChange({
                                ...settings,
                                default_value: event.target.value,
                            })
                        }
                    />
                </div>
            </>
        );
    }

    function renderVoiceInputSettings() {
        return (
            <>
                <div className="col-md-4 col-12 mb-5">
                    <TextField
                        label={
                            <LocaleMessage msg="page.survey_steps.form.input.label.mode.speech.tries" />
                        }
                        fullWidth
                        value={settings.tries || 2}
                        onChange={event =>
                            onChange({
                                ...settings,
                                tries: event.target.value,
                            })
                        }
                        helperText={
                            <LocaleMessage msg="page.survey_steps.form.input.label.mode.speech.tries.helper" />
                        }
                    />
                </div>
                {renderTextInputSettings()}
            </>
        );
    }

    function renderOptionInputSettings() {
        return (
            <>
                <div className="row" style={center}>
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.input.label.mode.option.columns" />
                            </InputLabel>
                            <Select
                                id="layout-columns"
                                value={settings.cols ? settings.cols : '1'}
                                onChange={event =>
                                    onChange({
                                        ...settings,
                                        cols: event.target.value,
                                    })
                                }
                            >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                            </Select>
                        </FormControl>
                    </div>{' '}
                    {}
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.input.label.multiplicity" />
                            </InputLabel>
                            <Select
                                id="answer-multiplicity"
                                value={
                                    settings.multi ? settings.multi : 'single'
                                }
                                onChange={event =>
                                    onChange({
                                        ...settings,
                                        multi: event.target.value,
                                    })
                                }
                                disabled={settings.mode === 'binary_options'}
                            >
                                {answer_number.map(v => {
                                    return (
                                        <MenuItem value={v.value} key={v.value}>
                                            {v.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {settings.mode === 'text_options' ? (
                    <div className="row" style={center}>
                        <div className="col-md-4 col-12 mb-3">
                            <FormSwitch
                                value={settings.dynamic || false}
                                onChange={event =>
                                    onChange({
                                        ...settings,
                                        dynamic: event.target.checked,
                                    })
                                }
                                label={
                                    <LocaleMessage msg="page.survey_steps.form.input.label.options.dynamic" />
                                }
                            />
                        </div>
                        {settings.dynamic ? (
                            <div className="col-md-4 col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.survey_steps.form.input.label.options.var_name" />
                                    }
                                    fullWidth
                                    value={settings.var_name || ''}
                                    onChange={event =>
                                        onChange({
                                            ...settings,
                                            var_name: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {renderOptionButtons()}
            </>
        );
    }

    function renderRatingSettings() {
        const max_rating =
            template_settings && template_settings.max_rating
                ? template_settings.max_rating
                : 5;
        return (
            <>
                <div className="col-md-4 col-12 mb-5">
                    <TextField
                        fullWidth
                        label={
                            <LocaleMessage msg="page.survey_steps.form.input.label.rating.max" />
                        }
                        value={settings.max_rating || ''}
                        onChange={event =>
                            onChange({
                                ...settings,
                                max_rating:
                                    event.target.value <= max_rating
                                        ? event.target.value
                                        : max_rating,
                            })
                        }
                        type="number"
                        inputProps={{
                            min: 1,
                            max: max_rating,
                            step: 1,
                        }}
                        helperText={
                            <>
                                <LocaleMessage msg="page.survey_steps.form.input.label.rating.helper" />
                                {` ${max_rating}`}
                            </>
                        }
                    />
                </div>
            </>
        );
    }

    function renderInputSettings() {
        const mode = settings && settings.mode ? settings.mode : '';
        const input_settings = {
            voice_first: renderVoiceInputSettings(),
            text_first: renderTextInputSettings(),
            text_options: renderOptionInputSettings(),
            binary_options: renderOptionInputSettings(),
            rating: renderRatingSettings(),
        };

        return input_settings[mode] || null;
    }

    return (
        <>
            {deleteDialogOpen ? renderDeleteDialog() : null}
            {optionDialogOpen ? renderOptionDialog() : null}
            <div className="mt-3 row mb-3">
                <div
                    className="col-12 row"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <div className="col-md-4 col-12 mb-5">
                        <TextField
                            id="var-name"
                            label={
                                <LocaleMessage msg="page.survey_steps.form.input.label.step_var" />
                            }
                            fullWidth
                            value={mainSettings.var_name || ''}
                            onChange={event =>
                                setMainSettings({
                                    ...mainSettings,
                                    var_name: event.target.value,
                                })
                            }
                            helperText={
                                <LocaleMessage msg="page.survey_steps.form.input.label.step_var.warning" />
                            }
                        />
                    </div>
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.input.label.mode" />
                            </InputLabel>
                            <Select
                                id="answer-mode"
                                value={
                                    settings.mode
                                        ? settings.mode
                                        : 'voice_first'
                                }
                                onChange={event => handleModeSelection(event)}
                            >
                                {Object.keys(answer_modes).map(m => {
                                    const mode = answer_modes[m];
                                    return (
                                        <MenuItem value={m} key={m}>
                                            {mode.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div
                    className="col-12 row"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {renderInputSettings()}
                </div>
            </div>
        </>
    );
}

AnswerSetup.propTypes = {
    mainSettings: PropTypes.object.isRequired,
    setMainSettings: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    stepSettings: PropTypes.object,
    visual: PropTypes.object,
    colors: PropTypes.object,
    languages: PropTypes.object,
    template: PropTypes.string,
};

AnswerSetup.defaultProps = {
    stepSettings: {},
    visual: {},
    colors: {},
    languages: {},
    template: '',
};
