/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdLayers,
    MdAndroid,
    MdPeople,
    MdCheck,
    MdClose,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const iconSize = 18;

export default function RC3Clients() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);
    const [pluginspaces, setPluginspaces] = useState([]);
    const [currPluginspace, setCurrPluginspace] = useState(null);

    const [rc3Available, setRC3Available] = useState([]);
    const [groups, setGroups] = useState([]);
    const [robots, setRobots] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPluginspaces() {
        setIsLoading(true);
        await api
            .get(`pluginspaces`)
            .then(response => {
                const { data } = response;
                setPluginspaces(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function verifyRC3() {
        if (currPluginspace) {
            await api
                .get(`pluginspaces/addons/rc3`)
                .then(response => {
                    const { data } = response;
                    setRC3Available(!!(data && data.id));
                    return true;
                })
                .catch(() => {
                    setRC3Available(false);
                });
        } else {
            setRC3Available(false);
        }
        return false;
    }

    async function loadGroups() {
        if (currPluginspace) {
            await api
                .get(`rc3/server/groups?pluginspace_id=${currPluginspace}`)
                .then(response => {
                    const data = response.data.map(g => {
                        return {
                            ...g,
                            rc3_available:
                                g.active &&
                                g.certificate &&
                                g.certificate.id ? (
                                    <MdCheck size={iconSize} />
                                ) : (
                                    <MdClose size={iconSize} />
                                ),
                        };
                    });
                    setGroups(data);
                })
                .catch(error => requestError(error));
        } else {
            setGroups([]);
        }
    }

    async function loadRobots() {
        if (currPluginspace) {
            await api
                .get(`rc3/server/robots?pluginspace_id=${currPluginspace}`)
                .then(response => {
                    const data = response.data.map(r => {
                        return {
                            ...r,
                            rc3_available:
                                r.active && r.rc3 && r.rc3.id ? (
                                    <MdCheck size={iconSize} />
                                ) : (
                                    <MdClose size={iconSize} />
                                ),
                        };
                    });
                    setRobots(data);
                })
                .catch(error => requestError(error));
        } else {
            setRobots([]);
        }
    }

    async function loadAll() {
        setIsLoading(true);
        await Promise.all([verifyRC3(), loadGroups(), loadRobots()]);
        setIsLoading(false);
    }

    useEffect(() => {
        loadAll();
    }, [currPluginspace]);

    useEffect(() => {
        loadPluginspaces();
    }, [date_loc]);

    function handleTableRowClick(event) {
        event.preventDefault();
    }

    function buildGroupList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'rc3_available',
                label: <LocaleMessage msg="table.headers.rc3_available" />,
            },
        ];

        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={groups}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildRobotList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'rc3_available',
                label: <LocaleMessage msg="table.headers.rc3_available" />,
            },
        ];

        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={robots}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    // function buildConfirmDialog() {
    //     return (
    //         <div>
    //             <Dialog
    //                 open={dialogOpen}
    //                 onClose={handleDialogClose}
    //                 aria-labelledby="alert-dialog-title"
    //                 aria-describedby="alert-dialog-description"
    //                 maxWidth="sm"
    //                 fullWidth
    //             >
    //                 <DialogTitle id="alert-dialog-title">
    //                     <LocaleMessage msg="page.su_pluginspace.list.delete" />
    //                 </DialogTitle>
    //                 <DialogContent>
    //                     <DialogContentText id="alert-dialog-description">
    //                         <LocaleMessage msg="message.undone.content" />
    //                     </DialogContentText>
    //                 </DialogContent>
    //                 <DialogActions>
    //                     <Button
    //                         onClick={() => setDialogOpen(false)}
    //                         color="primary"
    //                     >
    //                         <LocaleMessage msg="button.cancel" />
    //                     </Button>
    //                     <Button
    //                         onClick={() => deletePluginspace(currPluginspace)}
    //                         color="primary"
    //                         autoFocus
    //                     >
    //                         <LocaleMessage msg="button.delete" />
    //                     </Button>
    //                 </DialogActions>
    //             </Dialog>
    //         </div>
    //     );
    // }

    return (
        <PageContent
            title={<LocaleMessage msg="page.rc3_settings.clients.title" />}
            loading={false}
        >
            <>
                {/* {dialogOpen ? buildConfirmDialog() : null} */}
                <div className="col-12 mb-3">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.rc3_settings.pluginspace.title" />
                        }
                        Icon={MdLayers}
                        hide
                    >
                        <div
                            className="row"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div className="col-md-6 col-12 mb-3">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.rc3_settings.pluginspace.select" />
                                    </InputLabel>
                                    <Select
                                        value={currPluginspace || false}
                                        onChange={event => {
                                            setCurrPluginspace(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <MenuItem value={false}>
                                            <LocaleMessage msg="page.rc3_settings.pluginspace.select" />
                                        </MenuItem>
                                        {pluginspaces.map(p => {
                                            return (
                                                <MenuItem
                                                    key={p.id}
                                                    value={p.id}
                                                >
                                                    {p.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {currPluginspace ? (
                                <div
                                    className="col-md-6 col-12"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {rc3Available ? (
                                        <MdCheck
                                            size={40}
                                            style={{ marginRight: '10px' }}
                                        />
                                    ) : (
                                        <MdClose
                                            size={40}
                                            style={{ marginRight: '10px' }}
                                        />
                                    )}
                                    <Typography variant="h6">
                                        <LocaleMessage msg="page.rc3_settings.rc3.available" />
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                    </CardSideBordered>
                </div>
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {rc3Available ? (
                            <div className="row full-body">
                                <div className="col-md-6 col-12 mb-5">
                                    <CardSideBordered
                                        title={
                                            <LocaleMessage msg="page.rc3_settings.groups.title" />
                                        }
                                        Icon={MdPeople}
                                        hide
                                    >
                                        {buildGroupList()}
                                    </CardSideBordered>
                                </div>
                                <div className="col-md-6 col-12 mb-5">
                                    <CardSideBordered
                                        title={
                                            <LocaleMessage msg="page.rc3_settings.robots.title" />
                                        }
                                        Icon={MdAndroid}
                                        hide
                                    >
                                        {buildRobotList()}
                                    </CardSideBordered>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </>
        </PageContent>
    );
}
