/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import ColorPicker from '~/components/Form/ColorPicker';
import LanguageInputs from '~/components/Form/LanguageInputs';
import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import icon from '~/assets/icon-white.png';
import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';
import { PreviewArea, ItemCard } from './styles';

export default function DanceEdit({
    settings,
    updateList,
    defaultColor,
    handleError,
    galleries,
    currEdit,
    updateItem,
    closeEdit,
    languages,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [currGallery, setCurrGallery] = useState(
        galleries[0] ? galleries[0].id : ''
    );

    const rtype_list = settings.dances || [];
    const editItem = currEdit.item || {};

    async function loadAlbumFiles(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setIsLoading(false);
        } else {
            setFileList([]);
        }
    }

    useEffect(() => {
        loadAlbumFiles(currGallery);
    }, [currGallery]);

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(() => {
                toast.success(<LocaleMessage msg="message.media_uploaded" />);
                loadAlbumFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function handleClose() {
        closeEdit();
    }

    function updateItems() {
        const currIdx = currEdit.index;
        const items = rtype_list || [];
        items[currIdx] = currEdit.item;
        updateList(items);
        handleClose();
    }

    function handleItemEdit(key, value) {
        const updated = {
            ...editItem,
            [key]: value,
        };
        updateItem(updated);
    }

    function handleActionEdit(key, value) {
        const action_settings = editItem.action ? editItem.action : {};
        const updated = {
            ...action_settings,
            [key]: value,
        };
        handleItemEdit('action', updated);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const action_settings = editItem.action ? editItem.action : {};
        const button_settings = action_settings.button
            ? action_settings.button
            : {};

        handleActionEdit('button', {
            ...button_settings,
            file: {
                id: file.id,
                name: file.name,
                url: file.url,
            },
        });
        setMediaDialogOpen(false);
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
    }

    function renderActionMessages(key, object, label) {
        const action_settings = editItem.action ? editItem.action : {};
        return (
            <LanguageInputs
                title={label}
                languages={languages.map(l => l.value)}
                texts={action_settings[key] || {}}
                onChange={texts => {
                    handleActionEdit(key, texts);
                }}
            />
        );
    }

    function renderEditDance(item) {
        const action_settings = item.action ? item.action : {};
        const button_settings = action_settings.button
            ? action_settings.button
            : {};
        const button_file = button_settings.file ? button_settings.file : {};
        return (
            <div className="row">
                <div className="col-md-7 col-12">
                    <div className="mb-3">
                        <ColorPicker
                            label={
                                <LocaleMessage msg="page.dances.pluginconfig.edit.button.color" />
                            }
                            colorKey="color"
                            defaultColor={defaultColor}
                            settings={button_settings}
                            handleChange={c => {
                                handleActionEdit('button', {
                                    ...button_settings,
                                    color: c.hex,
                                });
                            }}
                        />
                    </div>
                    <Button
                        className="mb-3"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => setMediaDialogOpen(true)}
                    >
                        <LocaleMessage msg="label.form.change_image" />
                    </Button>
                    <div>
                        <FormSwitch
                            className="mb-1"
                            value={button_settings.expand || false}
                            onChange={event => {
                                handleActionEdit('button', {
                                    ...button_settings,
                                    expand: event.target.checked,
                                });
                            }}
                            label={
                                <LocaleMessage msg="page.dances.pluginconfig.edit.button.media.expand" />
                            }
                        />
                    </div>

                    <div>
                        <FormSwitch
                            className="mb-3"
                            value={action_settings.custom_sentences || false}
                            onChange={event =>
                                handleActionEdit(
                                    'custom_sentences',
                                    event.target.checked
                                )
                            }
                            label={
                                <LocaleMessage msg="page.dances.pluginconfig.edit.custom_sentences" />
                            }
                        />
                    </div>

                    {action_settings.custom_sentences ? (
                        <ParameterArea>
                            {renderActionMessages(
                                'on_start',
                                item.on_start || {},
                                <LocaleMessage msg="page.dances.pluginconfig.edit.on_start" />
                            )}
                            {renderActionMessages(
                                'on_finish',
                                item.on_finish || {},
                                <LocaleMessage msg="page.dances.pluginconfig.edit.on_finish" />
                            )}
                        </ParameterArea>
                    ) : null}
                </div>
                <PreviewArea className="col-md-5 col-12">
                    <ItemCard
                        key={item.id}
                        color={button_settings.color || defaultColor}
                        expand={button_settings.expand || false}
                    >
                        <img
                            className="card-img"
                            alt={button_file.name || 'Pluginbot Icon'}
                            src={button_file.url || icon}
                        />
                        <span className="card-txt">
                            {(item.name || '').toUpperCase()}
                        </span>
                    </ItemCard>
                </PreviewArea>
            </div>
        );
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="md"
                open={!!currEdit.item}
                onClose={() => {
                    handleClose();
                }}
                title={
                    <LocaleMessage msg="page.dances.pluginconfig.edit.title" />
                }
                content={renderEditDance(currEdit.item)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            handleClose();
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => {
                            updateItems();
                        },
                        label: <LocaleMessage msg="button.edit" />,
                    },
                ]}
            />
        );
    }

    function renderGallery(type) {
        const currGalleryObj = galleries.find(g => {
            return g.id === currGallery;
        });
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.gallery" />
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {!g.editable ? (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : null}
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={
                                        type === 'image' &&
                                        currGalleryObj.editable
                                    }
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type = 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    <LocaleMessage msg="page.dances.pluginconfig.edit.button.media" />
                }
                content={renderGallery(g_type)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {currEdit.item ? renderEditDialog() : null}
            {mediaDialogOpen ? renderMediaDialog() : null}
        </>
    );
}

DanceEdit.propTypes = {
    settings: PropTypes.object,
    defaultColor: PropTypes.string,
    updateList: PropTypes.func.isRequired,
    currEdit: PropTypes.object.isRequired,
    updateItem: PropTypes.func.isRequired,
    closeEdit: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    galleries: PropTypes.array,
    languages: PropTypes.array,
};

DanceEdit.defaultProps = {
    settings: {},
    defaultColor: '#000',
    galleries: [],
    languages: [],
};
