/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

export default function SUMaps({ requestError, filter, formats }) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const headers_file = [
        { key: 'active', label: 'Is Active' },
        { key: 'pluginspace_name', label: 'Pluginspace' },
        { key: 'group_name', label: 'Group' },
        { key: 'name', label: 'Name' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
        {
            key: 'updated_date',
            label: 'Last Update Date',
        },
        {
            key: 'created_time',
            label: 'Last Update Time',
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(a => {
            const created = new Date(a.created);
            const updated = new Date(a.updated);
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);

            return {
                ...a,
                pluginspace_name:
                    a.pluginspace && a.pluginspace.name
                        ? a.pluginspace.name
                        : '---',
                group_name: a.group && a.group.name ? a.group.name : '---',
                status_name: a.active ? (
                    <LocaleMessage msg="info.status.active" />
                ) : (
                    <LocaleMessage msg="info.status.inactive" />
                ),
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
            };
        });
        setItems(data);
    }

    async function loadMaps() {
        setIsLoading(true);
        const filter_url = filter ? `?pluginspace=${filter}` : '';
        await api
            .get(`admin/maps${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadMaps();
    }, [formats, filter]);

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headers_file}
                filename={GetFileName(`admin_analytics_maps`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'status_name',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'pluginspace_name',
                label: <LocaleMessage msg="table.headers.pluginspace" />,
            },
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'created_date',
                label: <LocaleMessage msg="table.headers.created" />,
                order_by: 'created_timestamp',
            },
            {
                id: 'updated_distance',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    orderColumn="name"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            <div style={{ padding: '15px' }}>{buildExportButton()}</div>
            <>{buildListView()}</>
        </>
    );
}

SUMaps.defaultProps = {
    filter: '',
    requestError: () => {},
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SUMaps.propTypes = {
    filter: PropTypes.string,
    requestError: PropTypes.func,
    formats: PropTypes.object,
};
