/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { IoLockClosedSharp } from 'react-icons/io5';
import { MdSwapVert, MdBatteryFull } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import pluginbot_logo from '~/assets/logo-dark.png';
import pluginbot_logo_white from '~/assets/logo-white.png';

import { PreviewArea, Screen, LoginPage, DeliveryPage } from './styles';

export default function Preview({
    robot,
    configuration,
    defColors,
    refreshedImage,
}) {
    const ps_logo = useSelector(state =>
        state.settings && state.settings.logo ? state.settings.logo : null
    );
    const robot_img =
        robot && robot.file && robot.file.url ? robot.file.url : '';

    const visualSettings = configuration.visual || {};
    const loginSettings = configuration.login_page || {};
    const deliverySettings = configuration.delivery_page || {};
    const colorSettings = visualSettings.colors || defColors;

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: (9.0 / 16.0) * targetRef.current.offsetWidth,
            });
        }
    }, []);

    return (
        <>
            <span className="mt-3 mb-3">
                <LocaleMessage msg="page.applications.indoor_delivery.form.preview.login.title" />
            </span>
            <PreviewArea className="mb-3" ref={targetRef}>
                <Screen dimensions={dimensions} colors={colorSettings}>
                    <LoginPage
                        dimensions={dimensions}
                        settings={loginSettings}
                        className="row"
                    >
                        <div className="col-7 logo-bg">
                            {loginSettings.show_logo ? (
                                <img
                                    className="pluginspace-logo"
                                    src={ps_logo ? ps_logo.url : ''}
                                    alt="Pluginspace Logo"
                                    title="Pluginspace Logo"
                                />
                            ) : null}
                            {loginSettings.show_image ? (
                                <img
                                    className="login-img"
                                    src={
                                        refreshedImage && refreshedImage.url
                                            ? refreshedImage.url
                                            : ''
                                    }
                                    alt="Login Page Img"
                                    title="Login Page Img"
                                />
                            ) : null}
                        </div>
                        <div className="col-5 white-bg p-3">
                            <img
                                className="pluginbot-logo"
                                src={pluginbot_logo}
                                alt="Pluginbot Logo"
                                title="Pluginbot Logo"
                            />
                            <div className="inputs">
                                <TextField
                                    className="mb-1"
                                    variant="outlined"
                                    disabled
                                />
                                <TextField
                                    className="mb-3"
                                    variant="outlined"
                                    disabled
                                />
                                <Button
                                    className="p-1"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                >
                                    <LocaleMessage msg="button.sign_in" />
                                </Button>
                            </div>
                        </div>
                    </LoginPage>
                </Screen>
            </PreviewArea>
            <span className="mt-3 mb-3">
                <LocaleMessage msg="page.applications.indoor_delivery.form.preview.delivery.title" />
            </span>
            <PreviewArea className="mb-3" ref={targetRef}>
                <Screen dimensions={dimensions} colors={colorSettings}>
                    <DeliveryPage
                        className="row"
                        dimensions={dimensions}
                        colors={colorSettings}
                    >
                        <div className="header row">
                            <img
                                className="pluginbot-logo"
                                src={pluginbot_logo_white}
                                alt="Pluginbot Logo"
                                title="Pluginbot Logo"
                            />
                        </div>
                        <div className="content row">
                            <div className="col-1 icon-area">
                                <MdSwapVert size={22} className="mb-3" />
                                <MdBatteryFull size={18} className="mb-3" />
                                <IoLockClosedSharp size={18} className="mb-3" />
                            </div>

                            <div className="col-5 img-area">
                                <img
                                    className="robot-img"
                                    src={robot_img}
                                    alt="Robot Type"
                                    title="Robot Type"
                                />
                                <span className="robot-name">
                                    [1234]{' '}
                                    <LocaleMessage msg="label.form.name" />
                                </span>
                            </div>
                            <div className="col-6 options-area p-3">
                                {deliverySettings.show_logo ? (
                                    <img
                                        className="pluginspace-logo"
                                        src={ps_logo ? ps_logo.url : ''}
                                        alt="Pluginspace Logo"
                                        title="Pluginspace Logo"
                                    />
                                ) : null}
                                <div className="options">
                                    <Button
                                        className="p-1 mb-3"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                    >
                                        <LocaleMessage msg="page.applications.indoor_delivery.form.preview.action" />
                                        {` 1`}
                                    </Button>
                                    <Button
                                        className="p-1"
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                    >
                                        <LocaleMessage msg="page.applications.indoor_delivery.form.preview.action" />
                                        {` 2`}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DeliveryPage>
                </Screen>
            </PreviewArea>
        </>
    );
}

Preview.defaultProps = {
    configuration: {},
    robot: {},
    refreshedImage: null,
};

Preview.propTypes = {
    defColors: PropTypes.object.isRequired,
    configuration: PropTypes.object,
    robot: PropTypes.object,
    refreshedImage: PropTypes.object,
};
