import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
`;

export const ParameterArea = styled.div`
    padding: 20px 10px;
    border: 1px solid #ddd;
    width: 100%;

    .row {
        margin: 10px 0px;
    }
`;

export const Panel = styled.div`
    display: flex;
    width: 100%;
    min-height: 70vh;
    height: 100%;
`;

export const ConversationStateArea = styled.div`
    border-bottom: ${props => {
        return `1px solid ${props.theme.palette.primary.main}`;
    }};
    width: 100%;
    height: min-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .MuiTable-root {
        border: ${props => {
            return `1px solid ${props.theme.palette.primary.main}`;
        }};
    }
`;
export const MessageArea = styled.div`
    /* border-top: ${props => {
        return `2px solid ${props.theme.palette.primary.main}`;
    }}; */
    width: 100%;
    height: min-content;
    padding: 20px;
    text-align: center;
    color: ${props => {
        return props.theme.palette.primary.main;
    }};

    .main-input {
        font-weight: bold;
    }
`;

export const AnswerArea = styled.div`
    border-bottom: ${props => {
        return `1px solid ${props.theme.palette.primary.main}`;
    }};
    width: 100%;
    height: min-content;
    padding: 20px;
    text-align: center;
    background-color: ${props => {
        return props.theme.palette.primary.main;
    }};
    color: #fff;
`;

export const FlowArea = styled.div`
    width: 100%;
    height: 100%;
    position: inherit;

    .flow-buttons {
        text-align: right;
        right: ${props => {
            return props.isFullScreen ? '5vw' : '5vw';
        }};
        bottom: 10px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .rd3t-svg {
        min-height: 70vh;
        height: 100%;
    }
`;

export const TreeArea = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;
    background: #fff;
    background-image: radial-gradient(#ccc 1px, transparent 0);
    background-size: 30px 30px;
    background-position: 0px 0px;
`;

export const TestArea = styled.div`
    border-left: ${props => {
        return `1px solid ${props.theme.palette.primary.main}`;
    }};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f;
`;

export const MessageBubble = styled.div``;

export const TestBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    height: 100%;

    .chat-main-content {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .chat-item .bubble {
        background: #fff;
        border: 1px solid ${props => props.theme.palette.primary.main};
        padding: 12px;
    }

    .qna-response .bubble {
        background: ${props => props.theme.palette.primary.main};
        color: #fff;
        :hover {
            cursor: pointer;
        }
    }
`;

export const TestHeader = styled.div`
    border-bottom: ${props => {
        return `1px solid ${props.theme.palette.primary.main}`;
    }};
    background-color: #fff;
    padding: 20px 10px;
    display: flex;
    width: 100%;
    min-height: 10%;
    align-items: center;
    justify-content: space-between;
`;

export const TestFooter = styled.div`
    border-top: ${props => {
        return `1px solid ${props.theme.palette.primary.main}`;
    }};
    background-color: #fff;
    padding: 20px 10px;
    display: flex;
    width: 100%;
    min-height: 10%;
    align-items: center;
    justify-content: space-between;

    .MuiInputBase-root {
        padding-left: 0px;
        .MuiSelect-root {
            padding: 0px;
        }
        .MuiSelect-icon {
            display: none;
        }
        .MuiInput-underline:before {
            display: none;
        }

        .MuiInputAdornment-positionEnd {
            :hover {
                cursor: pointer;
            }
        }
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;
