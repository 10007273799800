/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import PropTypes from 'prop-types';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';

export default function PasswordInput({
    label,
    value,
    disabled,
    onChange,
    helper,
    allowShow,
}) {
    const [visible, setVisible] = useState(false);

    return (
        <TextField
            label={label}
            helperText={helper}
            fullWidth
            value={value || ''}
            onChange={event => onChange(event)}
            type={!visible ? 'password' : 'text'}
            disabled={disabled}
            InputProps={
                allowShow
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setVisible(!visible)}
                                      edge="end"
                                  >
                                      {visible ? (
                                          <MdVisibilityOff />
                                      ) : (
                                          <MdVisibility />
                                      )}
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : null
            }
        />
    );
}

PasswordInput.defaultProps = {
    label: '',
    value: '',
    disabled: false,
    helper: null,
    allowShow: true,
};

PasswordInput.propTypes = {
    label: PropTypes.element,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allowShow: PropTypes.bool,
    disabled: PropTypes.bool,
    helper: PropTypes.element,
};
