/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdAccountBalance } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { startOfMonth } from 'date-fns';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function PluginspaceBilling() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings.pluginspace);
    const { id } = settings;

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getPeriods(start) {
        return start;
    }

    async function loadPluginspace(_id) {
        if (id !== 'new') {
            await api
                .get(`pluginspaces/${_id}`)
                .then(response => {
                    const p = response.data;
                    const { created } = p;
                    const start_date = startOfMonth(new Date(created));
                    getPeriods(start_date);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadPluginspace(id);
    }, [id]);

    return (
        <PageContent
            title="Pluginspace Billing"
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="col-12 mb-5">
                    <CardSideBordered
                        title="Pluginspace Billing"
                        Icon={MdAccountBalance}
                        hide
                    >
                        <>
                            <div className="row" />
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

PluginspaceBilling.propTypes = {};
