/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from './styles';

const h100 = { height: '100%' };

export default function InfoGridCard({ width, options, status, format }) {
    const title = options.label || 'rc.robot.data.status';
    const subtitle = options.subtitle || null;

    const infos = options.infos || [];
    const def_width = options.default_size || 'col-6';

    const obj_key = options.key || 'status';
    const card_info = status[obj_key] || {};

    function renderSubtitle() {
        if (!subtitle) {
            return null;
        }

        const sub_label = subtitle.label;
        const sub_key = subtitle.key;
        const sub_type = subtitle.type;

        const value = sub_key && card_info[sub_key] ? card_info[sub_key] : '';
        let sub_value = value;

        if (sub_type === 'time') {
            sub_value = value
                ? new Date(value).toLocaleDateString(
                      format.format,
                      format.label
                  )
                : '';
        }

        return sub_value ? (
            <div className="subtitle mt-3">
                <LocaleMessage msg={sub_label} />
                <span>{sub_value}</span>
            </div>
        ) : null;
    }

    function renderInfo(info) {
        const scale = info.scale || '';
        const value = card_info[info.key]
            ? `${card_info[info.key]}${scale}`
            : '---';
        return (
            <div
                className={`info ${info.width || def_width}`}
                key={`info_grid_${info.key}`}
            >
                <span className="title">
                    <LocaleMessage msg={info.label} />
                </span>
                <span className="value">{value}</span>
            </div>
        );
    }

    return (
        <div className={width} style={h100}>
            <Box boxShadow={0} style={h100}>
                <InfoCard
                    style={{
                        padding: '20px 10px',
                    }}
                >
                    <div className="desc-full info">
                        <span className="jr-fs-md title mb-3">
                            <LocaleMessage msg={title} />
                        </span>

                        <div
                            className="col-12 row px-0"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {infos.map(info => renderInfo(info))}
                            {renderSubtitle()}
                        </div>
                    </div>
                </InfoCard>
            </Box>
        </div>
    );
}

InfoGridCard.propTypes = {
    width: PropTypes.string,
    options: PropTypes.object,
    status: PropTypes.object,
    format: PropTypes.object,
};

InfoGridCard.defaultProps = {
    width: 'col-md-4',
    options: {},
    status: {},
    format: {},
};
