/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdExtension, MdDirectionsRun, MdDelete, MdAdd } from 'react-icons/md';
import * as MaterialDesign from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function PluginTypeForm(props) {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const { match } = props;
    const { id } = match.params;
    const operation = id === 'new' ? 'create' : 'update';

    const [options, setOptions] = useState({});
    const [availability, setAvailability] = useState({});
    const settings = lists.plugin_availability_list;

    const [body, setBody] = useState({
        name: '',
        group: 'plugin',
    });
    const [icon, setIcon] = useState('');
    const [actions, setActions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [currItem, setCurrItem] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadActionList() {
        if (operation === 'update') {
            await api
                .get(`actions?type=${id}`)
                .then(response => {
                    const data = response.data.map(t => {
                        const updated = new Date(t.updated);
                        return {
                            ...t,
                            label: <LocaleMessage msg={t.name} />,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setActions(data);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadType() {
        if (operation === 'update') {
            setIsLoading(true);
            await api
                .get(`types/${id}`)
                .then(async response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        group: 'plugin',
                        slug: t.slug,
                    });
                    setOptions(t.settings ? t.settings : {});
                    setAvailability(
                        t.settings && t.settings.availability
                            ? t.settings.availability
                            : {}
                    );
                    setIcon(
                        t.settings && t.settings.icon ? t.settings.icon : ''
                    );
                    await loadActionList();
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 100);
                })
                .catch(error => requestError(error));
        }

        setIsLoading(false);
    }

    useEffect(() => {
        loadType();
    }, [date_loc]);

    async function removeAction() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`actions/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.plugin_type.form.action_removed" />
                );
                await loadActionList();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function updateType(data) {
        await api
            .put(`types/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.plugin_type.form.update_success" />
                );
                setIsLoading(false);
                loadType(id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options: { ...options, icon } };
        if (operation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.plugin_type.form.create_success" />
                    );

                    history.push(`/plugintypes/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    function handleCheckboxChange(event, key) {
        const av = { ...availability, [key]: event.target.checked };
        setAvailability(av);
        setOptions({ ...options, availability: av });
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, _id) {
        event.stopPropagation();
        setCurrItem(id);
        history.push(`/plugintypes/${id}/actions/${_id}`);
    }

    function handleNewAction() {
        history.push(`/plugintypes/${id}/actions/new`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildListView() {
        const headCells = [
            { id: 'label', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={actions}
                    orderColumn="label"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function actionList() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.plugin_type.form.action_list" />
                    }
                    hide
                    Icon={MdDirectionsRun}
                >
                    <>
                        <div className="body-top-controls">
                            <Button
                                onClick={() => handleNewAction()}
                                variant="contained"
                                color="primary"
                                startIcon={<MdAdd />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.plugin_type.form.add_action" />
                            </Button>
                        </div>
                        {buildListView()}
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.plugin_type.form.delete_action" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removeAction(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    const mdIcon = MaterialDesign[icon];

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.plugin_type.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.plugin_type.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/plugintypes',
                    title: <LocaleMessage msg="breadcrumbs.plugin_types" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.plugin_type.form.label.settings" />
                            }
                            Icon={MdExtension}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.plugin_type.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.plugin_type.form.label.slug" />
                                            }
                                            fullWidth
                                            value={body.slug}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    slug: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-10 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.plugin_type.form.label.icon" />
                                            }
                                            fullWidth
                                            value={icon}
                                            onChange={event =>
                                                setIcon(event.target.value)
                                            }
                                        />
                                    </div>
                                    <div
                                        className="col-md-6 col-2 mb-5"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {mdIcon ? (
                                            React.createElement(mdIcon, {
                                                size: 50,
                                            })
                                        ) : (
                                            <LocaleMessage msg="errors.icon_not_found" />
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl component="fieldset">
                                            <FormLabel className="mb-3">
                                                <LocaleMessage msg="page.plugin_type.form.label.availability" />
                                            </FormLabel>
                                            {settings.map(opt => (
                                                <FormGroup row key={opt.value}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                value={
                                                                    opt.value
                                                                }
                                                                checked={
                                                                    availability[
                                                                        opt
                                                                            .value
                                                                    ] || false
                                                                }
                                                                onChange={event =>
                                                                    handleCheckboxChange(
                                                                        event,
                                                                        opt.value
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <LocaleMessage
                                                                msg={
                                                                    opt.label_code
                                                                }
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            ))}
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? actionList() : null}
                </form>
            </>
        </PageContent>
    );
}

PluginTypeForm.propTypes = {
    match: PropTypes.object.isRequired,
};
