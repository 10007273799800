/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdPerson, MdSatellite } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import RC3UserSettings from '~/components/RC3Settings/User';
import UserForm from '~/components/UserForm';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function PluginspaceUserForm(props) {
    const dispatch = useDispatch();

    const { match } = props;
    const { id } = match.params;
    const defaultPermission = 'editor';

    const [operation, setOperation] = useState(
        id === 'new' ? 'create' : 'update'
    );
    const [currState, setCurrState] = useState('');
    const [body, setBody] = useState({
        permission: defaultPermission,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [userActive, setUserActive] = useState(false);

    const [allowRC3, setAllowRC3] = useState(false);

    const statusList = [
        { label: <LocaleMessage msg="user.status.active" />, value: 'active' },
        {
            label: <LocaleMessage msg="user.status.deactivated" />,
            value: 'deactivated',
        },
    ];

    const permissions = [
        {
            label: <LocaleMessage msg="user.permission.admin" />,
            value: 'admin',
            enabled: true,
        },
        {
            label: <LocaleMessage msg="user.permission.editor" />,
            value: 'editor',
            enabled: true,
        },
    ];

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function verifyRC3() {
        await api
            .get(`rc3/pluginspaces/verify`)
            .then(response => {
                const { data } = response;
                setAllowRC3(data.active);
            })
            .catch(error => requestError(error));
    }

    async function loadUser(_id) {
        if (id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`users/${_id}`)
                .then(response => {
                    const u = response.data;
                    verifyRC3();
                    setBody({
                        name: u.name,
                        email: u.email,
                        permission: u.permission,
                        status: u.status,
                    });

                    setCurrState(u.status);
                    if (u.status === 'active') {
                        setUserActive(true);
                    }
                })
                .catch(error => requestError(error));
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadUser(id);
    }, [id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`users`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.users.form.create_success" />
                    );
                    history.push(`/pluginspace/users`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`users/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.users.form.update_success" />
                    );
                    loadUser(id);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    function onStatusChange(event) {
        const val = event.target.value;

        if (val === 'active') {
            setUserActive(true);
        } else {
            setUserActive(false);
        }

        setBody({
            ...body,
            status: val,
            ...(val === 'active' && { permission: defaultPermission }),
        });
    }

    function onPermissionChange(event) {
        setBody({ ...body, permission: event.target.value });
    }

    function renderRC3Options() {
        return <RC3UserSettings user={id} level="pluginspace" />;
    }

    function renderSaveButton() {
        return (
            <div className="col-12 mt-5 mb-3">
                <Button
                    className="p-3"
                    variant="contained"
                    color="primary"
                    onClick={event => handleSubmit(event)}
                    fullWidth
                    size="large"
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </div>
        );
    }

    function renderPermission() {
        const perm =
            body && body.permission ? body.permission : defaultPermission;

        const currVal =
            permissions.find(p => {
                return p.value === perm;
            }) || {};
        return (
            <div className="col-md-6 col-12 mb-5">
                <InputLabel>
                    <LocaleMessage msg="page.users.form.label.permission" />
                </InputLabel>
                <Select
                    value={perm}
                    onChange={event => onPermissionChange(event)}
                    fullWidth
                    disabled={currVal && !currVal.enabled}
                >
                    {currVal && currVal.enabled ? (
                        permissions.map(p => {
                            if (p.enabled) {
                                return (
                                    <MenuItem value={p.value} key={p.value}>
                                        {p.label}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <MenuItem value={currVal.value} key={currVal.value}>
                            {currVal.label}
                        </MenuItem>
                    )}
                </Select>
            </div>
        );
    }

    function renderEditUser() {
        return (
            <form className="row full-body" noValidate autoComplete="off">
                <div className="col-md-8 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.users.form.label.settings" />
                        }
                        Icon={MdPerson}
                    >
                        <>
                            <div className="row">
                                <div className="col-md-6 col-12 mb-5">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="page.users.form.label.name" />
                                        }
                                        fullWidth
                                        value={body.name}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                name: event.target.value,
                                            })
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-5">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="page.users.form.label.email" />
                                        }
                                        fullWidth
                                        value={body.email}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                email: event.target.value,
                                            })
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="label.status" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                body.status
                                                    ? body.status
                                                    : 'waiting'
                                            }
                                            onChange={event =>
                                                onStatusChange(event)
                                            }
                                        >
                                            {statusList.map(s => {
                                                return (
                                                    <MenuItem
                                                        value={s.value}
                                                        key={s.value}
                                                    >
                                                        {s.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                {userActive ? renderPermission() : null}
                            </div>
                            {renderSaveButton()}
                        </>
                    </CardSideBordered>
                </div>

                {currState === 'active' && userActive && allowRC3 ? (
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.users.form.rc3.settings" />
                            }
                            hide
                            Icon={MdSatellite}
                        >
                            {renderRC3Options()}
                        </CardSideBordered>
                    </div>
                ) : null}
            </form>
        );
    }

    function renderNewUser() {
        return (
            <>
                <div className="col-md-8 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.users.form.label.settings" />
                        }
                        Icon={MdPerson}
                    >
                        <>
                            <div className="row">
                                <div
                                    className="col-12 row"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {operation === 'create' ? (
                                        <div
                                            className="col-12 row"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div className="col-md-6 col-12 mb-5">
                                                <FormControl fullWidth>
                                                    <InputLabel>
                                                        <LocaleMessage msg="user.source" />
                                                    </InputLabel>
                                                    <Select
                                                        value={
                                                            body.new_user
                                                                ? body.new_user
                                                                : false
                                                        }
                                                        onChange={event =>
                                                            setBody({
                                                                ...body,
                                                                new_user:
                                                                    event.target
                                                                        .value,
                                                            })
                                                        }
                                                    >
                                                        <MenuItem value={false}>
                                                            <LocaleMessage msg="user.source.existing" />
                                                        </MenuItem>
                                                        <MenuItem value>
                                                            <LocaleMessage msg="user.source.new" />
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : null}
                                    {operation === 'create' &&
                                    !body.new_user ? (
                                        <div className="col-md-6 col-12 mb-5">
                                            <TextField
                                                label={
                                                    <LocaleMessage msg="user.email" />
                                                }
                                                value={body.email || ''}
                                                fullWidth
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        email:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <UserForm
                                            user={body}
                                            setUser={u => setBody(u)}
                                        />
                                    )}
                                    {renderPermission()}
                                </div>
                            </div>
                            {renderSaveButton()}
                        </>
                    </CardSideBordered>
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={
                        operation === 'update'
                            ? 'page.users.form.edit.title'
                            : 'page.users.form.create.title'
                    }
                />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginspace/users',
                    title: <LocaleMessage msg="breadcrumbs.users" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>{operation === 'update' ? renderEditUser() : renderNewUser()}</>
        </PageContent>
    );
}

PluginspaceUserForm.propTypes = {
    match: PropTypes.object.isRequired,
};
