export default function getColorList() {
    return [
        {
            id: 'primary',
            default: '#3f51b5',
            title: 'page.pluginspace.form.label.colors.primary',
        },
        {
            id: 'secondary',
            default: '#E91E63',
            title: 'page.pluginspace.form.label.colors.secondary',
        },
        {
            id: 'dashboard_cards',
            default: '#3f51b5',
            title: 'page.pluginspace.form.label.colors.dashboard_cards',
        },
        {
            id: 'dashboard_graphs',
            default: '#3f51b5',
            title: 'page.pluginspace.form.label.colors.dashboard_graphs',
        },
    ];
}
