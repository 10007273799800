/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { VscWarning } from 'react-icons/vsc';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import AlertExtras from '~/pages/RC3/Alerts/Extras';
import RobotItem from '~/pages/RC3/Components/RobotItem';

import { Title, Container } from '../styles';

export default function HelpRequest({
    robot,
    robotType,
    content,
    removeAlert,
    changePage,
}) {
    const { map_layer, point } = content;
    const delivery_destination = `${map_layer ? map_layer.name : ''} ${
        map_layer && point && point.name ? ` - ${point.name}` : ''
    }`;

    const extras = [
        {
            key: 'destination',
            value: delivery_destination,
        },
    ];

    function onClick() {
        const r_id = robot.id;
        removeAlert();
        changePage({
            tab: 'robots',
            robot: r_id,
        });
    }

    return (
        <Container onClick={() => onClick()}>
            <Title className="col-12 p-1" fontSize="1.25vh">
                <strong>
                    <LocaleMessage msg="rc3.notifications.indoor_delivery.help_request.title" />
                </strong>
            </Title>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <RobotItem
                    active
                    robot={robot}
                    type={robotType}
                    showStatus={false}
                    key={robot.id}
                    size="5vh"
                    fontSize="1.5vh"
                />
                <div style={{ padding: '0px 10px' }}>
                    <VscWarning
                        style={{
                            fontSize: '5vh',
                        }}
                    />
                </div>
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <AlertExtras infos={extras} />
            </div>
        </Container>
    );
}

HelpRequest.propTypes = {
    robot: PropTypes.object,
    robotType: PropTypes.object,
    content: PropTypes.object,
    removeAlert: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
};

HelpRequest.defaultProps = {
    robot: {},
    robotType: {},
    content: {},
};
