/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import lng_labels from '~/util/LangMessages';

export default function GroupUsers({ groupId: id }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const { groups } = user;
    const { permission } = user.profile;

    const currGroup = groups.find(g => {
        return g.id === id;
    });

    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);
    const allowEdit =
        adminMode || (currGroup && currGroup.permission === 'admin');

    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`groups/${id}/users`)
            .then(response => {
                const { users } = response.data;
                const user_list = users.map(u => {
                    return {
                        ...u,
                        permission: lng_all[`user.permission.${u.permission}`],
                    };
                });
                setItems(user_list);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, []);

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, user_id) {
        event.preventDefault();
        setCurrItem(user_id);
        history.push(`/pluginspace/groups/${id}/users/${user_id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`/groups/${id}/users/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.groupuser.users.list.delete_success" />
                );

                await loadItems();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
            {
                id: 'permission',
                label: <LocaleMessage msg="table.headers.permission" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    orderColumn="name"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.groupuser.users.list.delete_title" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteItem(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {dialogOpen ? buildConfirmDialog() : null}
            {allowEdit ? (
                <div className="body-top-controls">
                    <div className="col-12" style={{ padding: '0px' }}>
                        <NewItemButton
                            link={`/pluginspace/groups/${id}/users/new`}
                            text={
                                <LocaleMessage msg="page.groupuser.users.list.add" />
                            }
                        />
                    </div>
                </div>
            ) : null}
            <>{isLoading ? <Splash /> : buildListView()}</>
        </>
    );
}

GroupUsers.propTypes = {
    groupId: PropTypes.string.isRequired,
};
