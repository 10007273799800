/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { Box, LinearProgress } from '@material-ui/core';

export default function BarInfo({ infoKey, labels, value }) {
    const progress = value || 0;

    return (
        <div className="row mb-3">
            <div
                className="info-text mb-1"
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {labels.map((l, idx) => {
                    return (
                        <span
                            className="info-text mb-1"
                            key={`${infoKey}_bar_${idx}`}
                        >
                            {l}
                        </span>
                    );
                })}
            </div>

            <Box width="100%">
                <LinearProgress
                    variant="determinate"
                    value={progress >= 100 ? 100 : progress}
                    style={{ height: '0.75vh' }}
                />
            </Box>
        </div>
    );
}

BarInfo.propTypes = {
    infoKey: PropTypes.string,
    labels: PropTypes.array,
    value: PropTypes.number,
};

BarInfo.defaultProps = {
    infoKey: '',
    labels: [],
    value: 0,
};
