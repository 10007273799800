import styled from 'styled-components';

export const VersionArea = styled.div`
    width: 100%;
    .version {
        padding: 7.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    border: ${props => {
        if (props.selected)
            return `2px solid ${props.theme.palette.primary.main}`;
        return 'none';
    }};

    :hover {
        cursor: pointer;
    }
`;

export const FlowArea = styled.div`
    width: 100%;
    min-height: 70vh;
    height: 100%;
    position: inherit;

    .flow-buttons {
        text-align: right;
        right: ${props => {
            return props.isFullScreen ? '5vw' : '5vw';
        }};
        bottom: 10px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .rd3t-svg {
        min-height: 70vh;
        height: 100%;
    }
`;

export const TreeArea = styled.div`
    width: 100%;
    height: 100%;
    min-height: 70vh;
    background: #fff;
    background-image: radial-gradient(#ccc 1px, transparent 0);
    background-size: 30px 30px;
    background-position: 0px 0px;
`;

export const ExamplesArea = styled.div`
    display: flex;

    .remove-option {
        :hover {
            cursor: pointer;
        }
    }
`;

export const ParameterArea = styled.div`
    padding: 20px 10px;
    border: 1px solid #ddd;
    width: 100%;

    .row {
        margin: 10px 0px;
    }
`;
