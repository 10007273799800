import styled from 'styled-components';

export const ListArea = styled.div`
    border-right: 1px solid #ddd;
    padding-left: 0px;

    .properties-area {
        border: 1px solid #ddd;
    }
`;

export const EditorArea = styled.div`
    padding: 0px;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ToolArea = styled.div`
    width: fit-content;
    height: fit-content;
`;

export const MapArea = styled.div`
    background-color: #333333;
    height: 100%;
    width: inherit;
    max-height: 100%;
    max-width: 100%;

    svg {
        :hover {
            cursor: ${props => props.handler};
        }
    }

    g {
        line,
        circle {
            fill: white,
            :hover {
                cursor: ${props => props.handler};
                fill: blue;
            }
        }
    }

    :hover {
        cursor: ${props => props.handler};
    }
`;
