/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import {
    MdSettings,
    MdPalette,
    MdDeleteForever,
    // MdOutlineTranslate,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, TextField, Menu } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import ColorInput from '~/components/ColorInput';
// import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import { loadPluginspaceSettingsRequest } from '~/store/modules/settings/actions';
import ColorList from '~/util/ColorList';

import LogoInput from './LogoInput';
import { Option } from './styles';

export default function PluginspaceForm() {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings.pluginspace);
    const { id } = settings;

    const colors = ColorList();

    const c_array = {};
    colors.forEach(c => {
        c_array[c.id] = c.default;
    });

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [body, setBody] = useState({
        name: '',
    });

    const [colorPicker, setColorPicker] = useState({
        visible: false,
        colors: c_array,
        selector: 'primary',
    });

    const [pluginspaceLogo, setPluginspaceLogo] = useState({
        id: null,
        url: null,
    });

    const [, setAvailableLanguages] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages`)
            .then(response => {
                const langs = response.data;
                setAvailableLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadPluginspace() {
        if (id !== 'new') {
            await api
                .get(`pluginspaces/${id}`)
                .then(response => {
                    const p = response.data;
                    const { theme, logo } = p;

                    setBody({
                        name: p.name,
                        theme,
                        logo_id: logo.id,
                        settings: p.settings || {},
                    });

                    setPluginspaceLogo({
                        id: logo.id,
                        url: logo.url,
                    });

                    const ps_colors = {};
                    colors.forEach(c => {
                        ps_colors[c.id] = theme[c.id] || '#000';
                    });

                    setColorPicker({
                        ...colorPicker,
                        colors: ps_colors,
                    });
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadPluginspace();
        loadLanguages();
    }, []);

    function handleColorClick(event, selector) {
        setAnchorEl(event.currentTarget);

        setColorPicker({
            ...colorPicker,
            selector,
            visible: !colorPicker.visible,
        });
    }

    function handleColorClose() {
        setColorPicker({ ...colorPicker, visible: false });
    }

    function handleColorChange(c) {
        const _colors = colorPicker.colors;
        _colors[colorPicker.selector] = c.hex;
        setColorPicker({ ...colorPicker, _colors });
    }

    async function removeLogo() {
        setIsLoading(true);

        await api
            .delete(`pluginspaces/logo`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.pluginspace.form.update_success" />
                );
                dispatch(loadPluginspaceSettingsRequest(id));
                await loadPluginspace();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function updatePluginspace(data) {
        setIsLoading(true);

        await api
            .put(`pluginspaces/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.pluginspace.form.update_success" />
                );
                dispatch(loadPluginspaceSettingsRequest(id));
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = { ...body, theme: colorPicker.colors };
        updatePluginspace(data);
    }

    async function onFileUpload(e) {
        const data = new FormData();
        data.append('file', e.target.files[0]);

        setIsLoading(true);
        const response = await api.post('pluginspaces/logo', data);
        const resp = response.data;

        const updated = {
            ...body,
            theme: colorPicker.colors,
            logo_id: resp.id,
        };

        setPluginspaceLogo({
            id: resp.id,
            url: resp.url,
        });
        await setBody(updated);

        updatePluginspace(updated);
    }

    function renderVisualEditor() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.pluginspace.form.label.visual" />
                    }
                    Icon={MdPalette}
                >
                    <>
                        {colorPicker.visible && anchorEl ? (
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={colorPicker.visible}
                                onClose={() => handleColorClose()}
                            >
                                <SketchPicker
                                    color={
                                        colorPicker.colors[colorPicker.selector]
                                    }
                                    onChange={color => handleColorChange(color)}
                                    onChangeComplete={color => {
                                        handleColorChange(color);
                                    }}
                                    disableAlpha
                                />
                            </Menu>
                        ) : null}
                        <div className="row">
                            <div className="col-md-6 col-12">
                                {colors.map(c => (
                                    <ColorInput
                                        key={`color_${c.id}`}
                                        title={<LocaleMessage msg={c.title} />}
                                        color={
                                            colorPicker.colors[c.id] ||
                                            c.default
                                        }
                                        handleColorClick={event =>
                                            handleColorClick(event, c.id)
                                        }
                                    />
                                ))}
                            </div>
                            <div
                                className="col-md-6 col-12"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <LogoInput
                                    color={`${colorPicker.colors.main}`}
                                    defaultValue={pluginspaceLogo}
                                    onFileUpload={_id => onFileUpload(_id)}
                                />
                                {pluginspaceLogo && pluginspaceLogo.id ? (
                                    <Option onClick={() => removeLogo()}>
                                        <MdDeleteForever size={18} />
                                        <LocaleMessage msg="page.pluginspace.form.label.remove_logo" />
                                    </Option>
                                ) : null}
                            </div>
                        </div>
                    </>
                </CardSideBordered>
            </div>
        );
    }

    // function renderLanguages() {
    //     const options = availableLanguages.map(l => {
    //         return {
    //             id: l.value,
    //             name: <LocaleMessage msg={`list.languages.${l.value}`} />,
    //         };
    //     });
    //     const body_settings = body && body.settings ? body.settings : {};
    //     const languages =
    //         body_settings && body_settings.app_languages
    //             ? body_settings.app_languages
    //             : [];

    //     return (
    //         <div className="col-12">
    //             <FormCheckList
    //                 multiple
    //                 allowStar={false}
    //                 options={options}
    //                 settings={{ list: languages }}
    //                 onChange={l => {
    //                     setBody({
    //                         ...body,
    //                         settings: {
    //                             ...body_settings,
    //                             app_languages: l && l.list ? l.list : [],
    //                         },
    //                     });
    //                 }}
    //             />
    //         </div>
    //     );
    // }

    return (
        <PageContent
            title={<LocaleMessage msg="page.pluginspace.form.edit.title" />}
            currentPage={
                <LocaleMessage msg="page.pluginspace.form.edit.title" />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.pluginspace.form.title" />
                            }
                            Icon={MdSettings}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="pluginspace-name"
                                            label={
                                                <LocaleMessage msg="page.pluginspace.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                    {renderVisualEditor()}

                    {/* <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.pluginspace.form.label.languages.title" />
                            }
                            Icon={MdOutlineTranslate}
                        >
                            <>{renderLanguages()}</>
                        </CardSideBordered>
                    </div> */}

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

PluginspaceForm.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
