/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { MdSend } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Dialog,
    IconButton,
    MenuItem,
    Select,
    TextareaAutosize,
    Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

import { DarkDialog } from '../../../styles';
import { Card, ChatDialog, MessageShortcut } from './styles';

const h100 = { height: '100%' };

const useStyles = makeStyles({
    tooltip: {
        padding: '1em',
        fontSize: '0.85em',
    },
});

export default function PresentationCard({
    options,
    width,
    permission,
    user,
    robot,
    robotType,
    application,
    sendAction,
    renderLoading,
}) {
    const classes = useStyles();
    const status = robot.status || {};
    const { robot_id, operation, language: def_language } = status;
    const op_user = operation && operation.user ? operation.user : null;

    const is_op_user = op_user && user && op_user.id === user.id;
    const allow_permission = permission !== 'viewer';

    const messagesEndRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const [userMessages, setUserMessages] = useState([]);
    const [, setAppSettings] = useState(null);
    const [languages, setLanguages] = useState([]);

    const [currLanguage, setCurrLanguage] = useState(def_language || 'pt_BR');
    const [currMessage, setCurrMessage] = useState('');
    const [defMessages, setDefMessages] = useState([]);

    async function loadContent(settings) {
        const all_rtype = settings.all || {};
        const rtype_settings = settings[robotType] || all_rtype;

        if (rtype_settings && rtype_settings.content_id) {
            await api
                .get(`app_contents/${rtype_settings.content_id}`)
                .then(response => {
                    const c = response.data;
                    const content = c.content || {};
                    const texts = content.texts || [];
                    setDefMessages(texts);
                })
                .catch(() => {});
        }
    }

    async function loadAppSettings(_id) {
        await api
            .get(`applications/${_id}`)
            .then(async response => {
                const { data } = response;
                const settings = data && data.settings ? data.settings : {};
                const lang =
                    settings.language && settings.language.list
                        ? settings.language.list
                        : [];
                setLanguages(lang);

                const presentation_settings =
                    settings && settings.presentation_mode
                        ? settings.presentation_mode
                        : {};
                await loadContent(presentation_settings);

                setAppSettings(data);
            })
            .catch(() => {});
        setIsLoading(false);
    }

    async function saveMessage(data) {
        const body = {
            robot_id,
            application_id: application ? application.id : '',
            message: data,
        };

        await api
            .post(`/apps/presentation/message`, body)
            .then(response => {
                sendAction({
                    action: 'speak_message',
                    data: response.data,
                });
            })
            .catch(() => {});
    }

    function onExitClick() {
        setIsLoading(true);
        setUserMessages([]);
        setCurrMessage('');
        const exit_params = options.exit_mode || {};
        sendAction(exit_params);
    }

    const handleTabClosing = () => {
        onExitClick();
    };

    const scrollToBottom = () => {
        return messagesEndRef && messagesEndRef.current
            ? messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
            : null;
    };

    useEffect(() => {
        scrollToBottom();
    }, [userMessages]);

    useEffect(() => {
        window.addEventListener('unload', handleTabClosing);
        return () => {
            onExitClick();
            window.removeEventListener('unload', handleTabClosing);
        };
    }, []);

    useEffect(() => {
        if (application && application.id && is_op_user) {
            loadAppSettings(application.id);
        }
    }, [application.id, is_op_user]);

    function sendMessage(message) {
        if (message === '') return;
        const data = {
            language: currLanguage,
            text: message,
            timestamp: new Date(),
        };

        setUserMessages([...userMessages, data]);
        saveMessage(data);
        setCurrMessage('');
    }

    function onTextEdit(event) {
        setCurrMessage(event.target.value);
    }

    function renderInUseCard() {
        return (
            <div className={width} style={h100}>
                <Box style={h100}>
                    <Card>
                        <div className="content mb-3">
                            <span className="jr-fs-md title mb-3">
                                <LocaleMessage msg="rc3.robot.data.card.presentation.title" />
                            </span>
                            <div className="info">
                                <span className="big-value">
                                    {op_user ? op_user.name : '---'}
                                </span>
                            </div>
                        </div>
                        <Button
                            fullWidth
                            className="mb-3"
                            variant="contained"
                            color="primary"
                            disabled={!allow_permission}
                            onClick={() => onExitClick()}
                        >
                            <LocaleMessage msg="rc3.robot.data.card.presentation.exit" />
                        </Button>
                    </Card>
                </Box>
            </div>
        );
    }

    function renderContentShortcut(shortcut) {
        const l_message =
            currLanguage && shortcut[currLanguage]
                ? shortcut[currLanguage]
                : null;

        const is_shortcut = !!shortcut.tag;
        return l_message ? (
            <Tooltip
                title={l_message}
                placement="right"
                arrow
                disableHoverListener={!is_shortcut}
                classes={{ tooltip: classes.tooltip }}
                key={`message_${shortcut.item_id}`}
            >
                <MessageShortcut
                    className="d-flex flex-nowrap shortcut-item mb-3"
                    bold={is_shortcut}
                    onClick={() => {
                        sendMessage(l_message);
                    }}
                >
                    <div className="bubble jambo-card">
                        <div className="message">
                            {is_shortcut ? `# ${shortcut.tag}` : l_message}
                        </div>
                    </div>
                </MessageShortcut>
            </Tooltip>
        ) : null;
    }

    function renderMessage(message) {
        const timestamp = new Date(message.timestamp);

        return (
            <div
                className="d-flex flex-nowrap chat-item"
                key={`message_${timestamp.getUTCMilliseconds()}`}
            >
                <div className="bubble jambo-card px-3">
                    <div className="message px-2 py-1">{message.text}</div>
                    <div className="time text-muted text-right mt-2">
                        {timestamp.toLocaleTimeString(undefined)}
                    </div>
                </div>
            </div>
        );
    }

    function renderInner() {
        const show_shortcuts = defMessages.length > 0;

        return (
            <ChatDialog className="chat-box">
                <div className="chat-box-main">
                    <div className="chat-main-header">
                        <div className="chat-main-header-info">
                            <div className="chat-contact-name">
                                <LocaleMessage msg="rc3.robot.data.card.presentation.title" />
                            </div>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => onExitClick()}
                            >
                                <LocaleMessage msg="rc3.robot.data.card.presentation.exit" />
                            </Button>
                        </div>
                    </div>

                    <div className="chat-main mx-0 px-0">
                        <div
                            className={`col-md-${
                                show_shortcuts ? '9' : '12'
                            } px-0`}
                        >
                            <CustomScrollbars className="chat-list-scroll scrollbar">
                                <div className="chat-main-content">
                                    {userMessages.map(m => renderMessage(m))}
                                </div>
                                <div ref={messagesEndRef} />
                            </CustomScrollbars>
                        </div>

                        {show_shortcuts ? (
                            <div className="chat-side col-md-3 px-0">
                                <CustomScrollbars className="scrollbar">
                                    <div className="chat-side-title">
                                        <LocaleMessage msg="rc3.robot.data.card.presentation.shortcuts" />
                                    </div>
                                    <div className="chat-side-content">
                                        {defMessages.map((m, i) =>
                                            renderContentShortcut(m, i)
                                        )}
                                    </div>
                                </CustomScrollbars>
                            </div>
                        ) : null}
                    </div>
                    <div className="chat-main-footer">
                        <div
                            className="d-flex flex-row align-items-center"
                            style={{ height: '100%' }}
                        >
                            <div className="chat-lang">
                                <Select
                                    value={currLanguage}
                                    onChange={event =>
                                        setCurrLanguage(event.target.value)
                                    }
                                >
                                    {languages.map(l => {
                                        const l_split = l.split('_');
                                        const l_country = l_split[1]
                                            ? l_split[1].toLowerCase()
                                            : 'br';
                                        return (
                                            <MenuItem
                                                key={`lang_${l}`}
                                                value={l}
                                            >
                                                <IconButton className="icon-btn">
                                                    <i
                                                        className={`flag flag-24 flag-${l_country}`}
                                                    />
                                                </IconButton>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <TextareaAutosize
                                        className="border-0 form-control chat-textarea"
                                        value={currMessage}
                                        color="primary"
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                sendMessage(currMessage);
                                            }
                                        }}
                                        onChange={event => {
                                            onTextEdit(event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="chat-sent">
                                <IconButton
                                    onClick={() => sendMessage(currMessage)}
                                >
                                    <MdSend color="primary" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ChatDialog>
        );
    }

    function renderInputDialog() {
        return (
            <Dialog open onClose={() => onExitClick()} fullWidth maxWidth="md">
                <DarkDialog>
                    {!isLoading ? renderInner() : renderLoading()}
                </DarkDialog>
            </Dialog>
        );
    }

    function renderContent() {
        return is_op_user ? renderInputDialog() : renderInUseCard();
    }

    return operation && operation.mode === 'presentation'
        ? renderContent()
        : null;
}

PresentationCard.propTypes = {
    width: PropTypes.string,
    permission: PropTypes.string,
    options: PropTypes.object,
    user: PropTypes.object,
    robot: PropTypes.object,
    robotType: PropTypes.string,
    application: PropTypes.object,
    sendAction: PropTypes.func.isRequired,
    renderLoading: PropTypes.func.isRequired,
};

PresentationCard.defaultProps = {
    width: 'col-12',
    permission: '',
    user: {},
    robot: {},
    application: {},
    options: {},
    robotType: '',
};
