/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPersonAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import UserForm from '~/components/UserForm';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function GroupUserForm(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const { match } = props;
    const { id, user_id } = match.params;

    const { groups } = user;
    const { permission } = user.profile;
    const currGroup = groups.find(g => {
        return g.id === id;
    });

    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);
    const allowEdit =
        adminMode || (currGroup && ['admin'].includes(currGroup.permission));

    const [operation, setOperation] = useState('create');
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [body, setBody] = useState({
        group_id: id,
        user_id: null,
        permission: 'viewer',
        new_user: false,
    });
    const [newUser, setNewUser] = useState({
        permission: 'editor',
    });

    const permissions = [
        {
            label: <LocaleMessage msg="user.permission.admin" />,
            value: 'admin',
        },
        {
            label: <LocaleMessage msg="user.permission.editor" />,
            value: 'editor',
        },
        {
            label: <LocaleMessage msg="user.permission.viewer" />,
            value: 'viewer',
        },
        {
            label: <LocaleMessage msg="user.permission.operator" />,
            value: 'operator',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadData() {
        if (user_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`groups/${id}/users/${user_id}`)
                .then(resp => {
                    const u = resp.data.user;
                    setBody({
                        group_id: id,
                        user_id: u.id,
                        email: u.email,
                        permission: u.permission,
                    });
                })
                .catch(error => requestError(error));
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadData();
    }, []);

    function onUserOpChange(event) {
        const new_user = event.target.value;
        return setBody({ ...body, new_user });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = {
            ...body,
            ...(body.new_user && { user_id: null, user: newUser }),
        };

        setIsLoading(true);
        if (operation === 'create') {
            await api
                .post(`groups/users`, data)
                .then(() => {
                    setIsLoading(false);

                    toast.success(
                        <LocaleMessage msg="page.groupuser.form.create_success" />
                    );
                    history.push(`/groups/${id}/users`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`groups/${id}/users/${user_id}`, data)
                .then(() => {
                    setIsLoading(false);
                    toast.success(
                        <LocaleMessage msg="page.groupuser.form.update_success" />
                    );
                })
                .catch(error => requestError(error));
        }
    }

    function renderExistingUser() {
        return (
            <div className="col-md-6 col-12 mb-5">
                <TextField
                    label={<LocaleMessage msg="user.email" />}
                    value={body.email || ''}
                    fullWidth
                    onChange={event =>
                        setBody({
                            ...body,
                            email: event.target.value,
                        })
                    }
                    disabled={operation !== 'create'}
                />
            </div>
        );
    }

    function renderNewUser() {
        return <UserForm user={newUser} setUser={u => setNewUser(u)} />;
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.groupuser.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.groupuser.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
                {
                    url: `/groups/${id}/users`,
                    title: <LocaleMessage msg="breadcrumbs.usergroup" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.groupuser.form.label.title" />
                            }
                            Icon={MdPersonAdd}
                        >
                            <>
                                {operation === 'create' ? (
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-5">
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    <LocaleMessage msg="user.source" />
                                                </InputLabel>
                                                <Select
                                                    value={
                                                        body.new_user
                                                            ? body.new_user
                                                            : false
                                                    }
                                                    onChange={event =>
                                                        onUserOpChange(event)
                                                    }
                                                >
                                                    <MenuItem value={false}>
                                                        <LocaleMessage msg="user.source.existing" />
                                                    </MenuItem>
                                                    <MenuItem value>
                                                        <LocaleMessage msg="user.source.new" />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="row">
                                    {body.new_user
                                        ? renderNewUser()
                                        : renderExistingUser()}

                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl
                                            error={body.permission === null}
                                            fullWidth
                                        >
                                            <InputLabel>
                                                <LocaleMessage msg="page.groupuser.form.label.privileges" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    body.permission
                                                        ? body.permission
                                                        : 'viewer'
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        permission:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                {permissions.map(p => {
                                                    return (
                                                        <MenuItem
                                                            value={p.value}
                                                            key={p.value}
                                                        >
                                                            {p.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                            disabled={!allowEdit}
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

GroupUserForm.propTypes = {
    match: PropTypes.object.isRequired,
};
