import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        list-style-type: none;
    }

    .body-top-controls {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        margin-bottom: 20px;
    }

    .view-control {
        width: 100%;
        padding: 0px;
        text-align: right;

        .active {
            background: ${props => props.theme.palette.primary.main};
            color: #fff;
        }
    }

    .row {
        padding: 0px;
        width: 100%;
        margin: auto;
    }

    .full-body {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: top;
    }

    .new-card {
        :hover {
            background: rgba(0, 0, 0, 0.02);
        }
    }

    a {
        text-decoration: none !important;
        color: #495057 !important;
    }

    .bordered-table {
        border-top: 5px solid ${props => props.theme.palette.primary.main};
        border-radius: 0px 0px 0.375em 0.375em !important;

        .data-row {
            :hover {
                cursor: pointer;
            }
        }
    }

    .simple-card-bordered {
        border-top: 5px solid ${props => props.theme.palette.primary.main};
        border-radius: 0px 0px 0.375em 0.375em !important;
    }
`;
