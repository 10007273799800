import styled from 'styled-components';

export const Logo = styled.div`
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => (props.open ? '5px' : '40px')};

    img {
        max-width: ${props => (props.open ? '130px' : '40px')};
        max-height: 40px;
    }
`;

export const MenuList = styled.ul`
    .group-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .no-arrow {
        ::before {
            content: none !important;
            display: none !important;
        }
    }
`;

export const MenuItem = styled.li`
    padding: 5px 0px;
    font-size: 16px;
    width: 100%;
    text-overflow: ${props => {
        return props.type === 'temporary' ? 'ellipsis' : '';
    }};

    ::before {
        content: none !important;
        display: none !important;
    }

    span {
        font-size: 14px;
        vertical-align: middle;
    }

    i {
        font-size: 16px;
    }

    a {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
    }

    .no-arrow {
        ::before {
            content: none !important;
            display: none !important;
        }
    }

    .nav-text {
        max-width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0px;
    }
`;
