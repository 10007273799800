import styled from 'styled-components';

export const UserAvatar = styled.div`
    .quick-menu {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            width: 40px !important;
            height: 40px !important;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            .MuiAvatar-circle {
                width: 40px !important;
                height: 40px !important;
            }
        }

        .user-profile {
            padding: 20px;

            .user-detail {
                margin-left: 15px !important;
            }

            img {
                margin-bottom: 0px;
            }
        }
    }

    .dropdown-menu {
        ::before {
            right: 15px;
        }

        ::after {
            right: 15px;
        }
    }

    img,
    .avatar-placeholder {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;

        h1 {
            font-size: 42px;
        }
    }
`;

export const UserMenu = styled.div`
    .MuiAvatar-root {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        padding: 10px 20px;

        .zmdi {
            margin-right: 10px !important;
        }
    }

    a {
        text-decoration: none !important;
        color: #6c757d !important;
    }

    .user-permission {
        padding: 20px 0px;

        span {
            padding: 0px;
        }
    }
`;

export const Avatar = styled.div`
    img,
    .avatar-placeholder {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 3px solid rgba(255, 255, 255, 0.3);
        background: #eee;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;

        h1 {
            font-size: 42px;
        }
    }
`;
