/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
    MdCheckBoxOutlineBlank,
    MdLibraryBooks,
    MdCollectionsBookmark,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import LocaleMessage from '~/components/LocaleMessage';

import { CardMenu, CardRow, SpecialCard, Title } from './styles';

export default function QnAIndex() {
    const cards = [
        {
            key: 'training',
            route: '/pluginbot_qna/training',
            name: <LocaleMessage msg="page.qna.training.short" />,
            icon: MdLibraryBooks,
        },
        {
            key: 'templates',
            route: '/pluginbot_qna/templates',
            name: <LocaleMessage msg="page.qna.templates.short" />,
            icon: MdCollectionsBookmark,
        },
    ];

    function itemCard(card) {
        const Icon = card.icon || MdCheckBoxOutlineBlank;
        return (
            <div className="col-md-5 col-sm-6 mb-5" key={card.key}>
                <Link to={card.route}>
                    <SpecialCard
                        className="jr-card jr-card-widget jr-card-full text-center card"
                        style={{ height: '100%' }}
                    >
                        <div
                            className="card-body plugin-card"
                            style={{ textTransform: 'uppercase' }}
                        >
                            <Icon size={50} />
                            <h2 className="mt-4">{card.name}</h2>
                        </div>
                    </SpecialCard>
                </Link>
            </div>
        );
    }

    return (
        <>
            <Title className="mb-5">
                <h1 className="title mt-5 mb-5 mb-sm-0">
                    <LocaleMessage msg="page.qna.title" />
                </h1>
            </Title>

            <CardMenu className="col-12">
                <CardRow className="col-md-10 col-12 row mt-3">
                    {cards.map(c => itemCard(c))}
                </CardRow>
            </CardMenu>
        </>
    );
}
