import styled from 'styled-components';

export const Item = styled.li`
    padding: 10px 10px !important;
    & {
        border-bottom: 1px solid #dee2e6;
    }

    :hover {
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: bold;

    .badge {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
        margin-bottom: 0px !important;
        width: 40px !important;
    }
`;

export const List = styled.span`
    padding: 10px 20px;
    minwidth: 200px;
    maxwidth: 250px;
`;

export const Selected = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
