/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdEdit } from 'react-icons/md';

import PropTypes from 'prop-types';

// import LocaleMessage from '~/components/LocaleMessage';

import { Container } from './styles';

export default function FileInput(props) {
    const { defaultValue, onFileUpload } = props;
    const file = defaultValue ? defaultValue.id : null;
    const name = defaultValue ? defaultValue.name : null;

    return (
        <Container style={{ background: '#ddd', height: '100%' }}>
            <label htmlFor="file-input">
                <div className="file-input">
                    <div className="file-placeholder">
                        <div
                            className="jr-separator mt-3"
                            style={{ background: '#999' }}
                        />
                        <h2 style={{ color: '#999' }}>
                            <MdEdit />
                            {file ? name : 'App File'}
                        </h2>
                        <div
                            className="jr-separator"
                            style={{ background: '#999' }}
                        />
                    </div>
                </div>

                <input
                    type="file"
                    id="file-input"
                    accept="*"
                    onChange={e => onFileUpload(e)}
                    data-file={file}
                />
            </label>
        </Container>
    );
}

FileInput.propTypes = {
    onFileUpload: PropTypes.func.isRequired,
    defaultValue: PropTypes.object.isRequired,
};
