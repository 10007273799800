/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdDirectionsRun } from 'react-icons/md';
import ReactJson from 'react-json-view';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import {
    Button,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import FileInput from './FileInput';

const action_types = [
    {
        value: 'preset',
        label: 'Preset (Default library)',
    },
    {
        value: 'sequence',
        label: 'Sequence (Default library)',
    },
    {
        value: 'timeline',
        label: 'Timeline',
    },
];
const movement_status = [
    {
        value: 'unavailable',
        label: <LocaleMessage msg="list.status.unavailable" />,
    },
    {
        value: 'development',
        label: <LocaleMessage msg="list.status.development" />,
    },
    {
        value: 'testing',
        label: <LocaleMessage msg="list.status.testing" />,
    },
    {
        value: 'available',
        label: <LocaleMessage msg="list.status.available" />,
    },
];

export default function RobotMovements() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);

    const [movements, setMovements] = useState([]);
    const [types, setTypes] = useState([]);
    const [filter, setFilter] = useState('');

    const [currMovement, setCurrMovement] = useState(null);
    const [movementDeleteOpen, setMovementDeleteOpen] = useState(false);

    const [formOpen, setFormOpen] = useState(false);
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({});
    const [content, setContent] = useState({});
    const [illustration, setIllustration] = useState({
        id: '',
        url: '',
    });

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadTypes() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const t = response.data;
                setTypes(t);
            })
            .catch(error => requestError(error));
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`movements?status=all`)
            .then(response => {
                const data = response.data.map(m => {
                    const updated = new Date(m.updated);
                    return {
                        ...m,
                        robot_type_str: m.type.name,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setMovements(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadMovement(_id) {
        if (_id === 'new') {
            setOperation('create');
            setBody({});
            setContent({});
        } else {
            setOperation('update');

            await api
                .get(`movements/${_id}`)
                .then(response => {
                    const m = response.data;
                    setBody({
                        name: m.name,
                        status: m.status,
                        action_type: m.action_type,
                        type_id: m.type ? m.type.id : null,
                    });
                    setContent(m.content);
                    setIllustration(m.illustration);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadTypes();
    }, []);

    useEffect(() => {
        if (currMovement) {
            loadMovement(currMovement);
        }
    }, [currMovement]);

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    async function deleteMovement() {
        setIsLoading(true);
        setMovementDeleteOpen(false);
        await api
            .delete(`movements/${currMovement}`)
            .then(() => {
                loadItems();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    function handleClickOpen(event, _id) {
        setCurrMovement(_id);
        event.preventDefault();
        setMovementDeleteOpen(true);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrMovement(_id);
        setFormOpen(true);
    }

    function handleDeleteMovementClose(event) {
        event.preventDefault();
        setMovementDeleteOpen(false);
    }

    async function updateMovement(data) {
        await api
            .put(`movements/${currMovement}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.robot_movements.form.update_success" />
                );
                loadItems();
                loadMovement(currMovement);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, content };

        if (operation === 'create') {
            await api
                .post(`movements`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robot_movements.form.create_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateMovement(data);
        }
        loadItems();
        setFormOpen(false);
        setCurrMovement(null);
    }

    async function onFileUpload(e) {
        const data = new FormData();
        data.append('file', e.target.files[0]);

        setIsLoading(true);
        const response = await api.post(
            `movements/${currMovement}/illustration`,
            data
        );
        const resp = response.data;

        const updated = { ...body, file_id: resp.id };

        setIllustration({
            id: resp.id,
            url: resp.url,
        });
        setBody(updated);

        updateMovement(updated);
    }

    function buildListView() {
        const headCells = [
            {
                id: 'robot_type_str',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'status',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'action_type',
                label: <LocaleMessage msg="table.headers.movement_type" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        const filtered = movements.filter(m => {
            return filter === '' || m.type.id === filter;
        });

        return (
            <DataTable
                headerColumns={headCells}
                data={filtered}
                // orderColumn="robot_type_str"
                handleTableRowClick={(event, _id) =>
                    handleTableRowClick(event, _id)
                }
                rowActions={rowActions}
            />
        );
    }

    function buildMovementDeleteDialog() {
        return (
            <SimpleDialog
                open={movementDeleteOpen}
                onClose={handleDeleteMovementClose}
                title={
                    <LocaleMessage msg="page.robot_movements.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMovementDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteMovement(currMovement),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderPresetContent() {
        return (
            <TextField
                label={
                    <LocaleMessage msg="page.robot_movements.form.label.command" />
                }
                fullWidth
                value={content && content.name ? content.name : ''}
                onChange={event =>
                    setContent({
                        ...content,
                        name: event.target.value,
                    })
                }
            />
        );
    }

    function renderSequenceContent() {
        const cover = content && content.cover ? content.cover : {};
        return (
            <div className="row">
                <div className="col-md-6 col-12">
                    <TextField
                        className="mb-3"
                        label={
                            <LocaleMessage msg="page.robot_movements.form.label.command" />
                        }
                        fullWidth
                        value={
                            content && content.sequence ? content.sequence : ''
                        }
                        onChange={event =>
                            setContent({
                                ...content,
                                sequence: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-md-6 col-12">
                    <TextField
                        className="mb-3"
                        label={
                            <LocaleMessage msg="page.robot_movements.form.label.cover.color" />
                        }
                        fullWidth
                        value={cover.color ? cover.color : ''}
                        onChange={event =>
                            setContent({
                                ...content,
                                cover: {
                                    ...cover,
                                    color: event.target.value,
                                },
                            })
                        }
                    />
                </div>
            </div>
        );
    }

    function renderJSONContent() {
        return (
            <ReactJson
                src={content}
                name={false}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
            />
        );
    }

    function renderContentEditor() {
        switch (body.action_type) {
            case 'preset':
                return renderPresetContent();
            case 'sequence':
                return renderSequenceContent();
            case 'timeline':
                return renderJSONContent();
            default:
                return null;
        }
    }

    function buildMovementForm() {
        return (
            <form noValidate autoComplete="off">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.robot_movements.form.label.settings" />
                    }
                    hide
                    Icon={MdDirectionsRun}
                >
                    <>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <TextField
                                    className="mb-5"
                                    label={
                                        <LocaleMessage msg="page.robot_movements.form.label.name" />
                                    }
                                    fullWidth
                                    value={body.name ? body.name : ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            name: event.target.value,
                                        })
                                    }
                                />
                                <FormControl fullWidth className="mb-5">
                                    <InputLabel>
                                        <LocaleMessage msg="label.form.robot_type" />
                                    </InputLabel>
                                    <Select
                                        value={body.type_id ? body.type_id : ''}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                type_id: event.target.value,
                                            })
                                        }
                                    >
                                        {types.map(t => {
                                            return (
                                                <MenuItem
                                                    value={t.id}
                                                    key={t.id}
                                                >
                                                    {t.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth className="mb-5">
                                    <InputLabel>
                                        <LocaleMessage msg="page.robot_movements.form.label.type" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            body.action_type
                                                ? body.action_type
                                                : ''
                                        }
                                        disabled={operation === 'update'}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                action_type: event.target.value,
                                            })
                                        }
                                    >
                                        {action_types.map(t => {
                                            return (
                                                <MenuItem
                                                    value={t.value}
                                                    key={t.value}
                                                >
                                                    {t.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                {operation === 'update' ? (
                                    <FormControl fullWidth className="mb-5">
                                        <InputLabel>
                                            <LocaleMessage msg="page.robot_movements.form.label.status" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                body.status ? body.status : ''
                                            }
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    status: event.target.value,
                                                })
                                            }
                                        >
                                            {movement_status.map(t => {
                                                return (
                                                    <MenuItem
                                                        value={t.value}
                                                        key={t.value}
                                                    >
                                                        {t.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                ) : null}
                            </div>
                            <div className="col-md-6 col-12 mb-5">
                                <FileInput
                                    background={
                                        content &&
                                        content.cover &&
                                        content.cover.color
                                            ? content.cover.color
                                            : ''
                                    }
                                    defaultValue={illustration || {}}
                                    onFileUpload={_id => onFileUpload(_id)}
                                />
                            </div>
                            <div className="col-md-12 col-12 mb-5">
                                {renderContentEditor()}
                            </div>
                        </div>
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </>
                </CardSideBordered>
            </form>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.robot_movements.list.title" />}
            breadcrumbs={[]}
            loading={isLoading}
        >
            <>
                {movementDeleteOpen ? buildMovementDeleteDialog() : null}
                <div className="row">
                    <div className="col-md-6 col-12 mb-5">
                        <div className="row mb-3">
                            <FormControl className="col-6">
                                <InputLabel>Robot Type Filter</InputLabel>
                                <Select
                                    value={filter || ''}
                                    onChange={event =>
                                        setFilter(event.target.value)
                                    }
                                >
                                    <MenuItem value="">No Filter</MenuItem>
                                    {types.map(t => {
                                        return (
                                            <MenuItem value={t.id} key={t.id}>
                                                {t.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                className="col-6"
                                variant="contained"
                                color="primary"
                                onClick={event =>
                                    handleTableRowClick(event, 'new')
                                }
                            >
                                <LocaleMessage msg="page.robot_movements.list.add" />
                            </Button>
                        </div>
                        {buildListView()}
                    </div>

                    <div className="col-md-6 col-12 mb-5">
                        {formOpen ? buildMovementForm() : null}
                    </div>
                </div>
            </>
        </PageContent>
    );
}

RobotMovements.propTypes = {};
