/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import Forbidden from '~/components/Forbidden';
import Splash from '~/components/Splash/Inside';

import { Container } from './styles';

export default function PageBody(props) {
    const { loading, forbidden, children } = props;
    return (
        <Container>
            {loading ? <Splash /> : <>{forbidden ? <Forbidden /> : children}</>}
        </Container>
    );
}

PageBody.defaultProps = {
    loading: true,
    forbidden: false,
};

PageBody.propTypes = {
    loading: PropTypes.bool,
    forbidden: PropTypes.bool,
    children: PropTypes.element.isRequired,
};
