/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdAccountBox,
    MdAdd,
    MdCloudDownload,
    MdSearch,
    MdSatellite,
    MdHelp,
    MdLockOpen,
    MdLockOutline,
    MdLaunch,
} from 'react-icons/md';
import InjectIntl from 'react-intl-inject';
import ReactLoading from 'react-loading';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Input,
    InputAdornment,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    Chip,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import ExitDialog from '~/components/ExitDialog';
import FormPasswordInput from '~/components/Form/PasswordInput';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import RobotInfo from '~/components/RobotInfo';

import { IconArea } from '~/styles/custom';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkPermission from '~/util/CheckPermission';

import AppBehaviourParameters from '../AppBehaviourParameters';
import { SearchInput, AppConfigArea } from './styles';

const { transfer_status } = lists;

const centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const locales = {
    en_US: 'en-US',
    en_GB: 'en-GB',
    pt_BR: 'pt-BR',
};

export default function RobotForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const settings = useSelector(state => state.settings);
    const { profile } = useSelector(state => state.user || null);
    const { active } = settings;
    const admin_user =
        checkPermission(profile) || active.permission === 'admin';
    const date_format = settings.locale;
    const admin_groups = admin_user ? settings.admin_groups : [active];

    const [operation, setOperation] = useState('create');

    const [allowRC3, setAllowRC3] = useState(false);
    const [RC3Group, setRC3Group] = useState(true);
    const [RC3DialogOpen, setRC3DialogOpen] = useState(false);
    const [rc3Operation, setRC3Operation] = useState('create');
    const [rc3Thing, setRC3Thing] = useState(null);

    const [isBound, setIsBound] = useState(false);
    const [body, setBody] = useState({});
    const [transfer, setTransfer] = useState({});
    const [transferCode, setTransferCode] = useState('');

    const [types, setTypes] = useState([]);
    const [currType, setCurrType] = useState({});
    const [currApp, setCurrApp] = useState({});
    const [currAppType] = useState({});
    const [availableLocations, setAvailableLocations] = useState([]);
    const [availableZones, setAvailableZones] = useState([]);
    const [availableApps, setAvailableApps] = useState([]);
    const [compatibleAppTypes, setCompatibleAppTypes] = useState([]);
    const [robotExtras, setRobotExtras] = useState({});
    const [allowExtras, setAllowExtras] = useState({});
    const [robotApps, setRobotApps] = useState({});
    const [robotLocation, setRobotLocation] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(true);
    const [behaviourDialogOpen, setBehaviourDialogOpen] = useState(false);
    const [forbidden, setForbidden] = useState(false);

    const [exitConfirmOpen, setExitConfirmOpen] = useState(false);
    const [exitAppId, setExitAppId] = useState('');

    const [transferInfo, setTransferInfo] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setInternalLoading(false);
        setIsLoading(false);
    }

    async function verifyRC3() {
        await api
            .get(`rc3/pluginspaces/verify`)
            .then(response => {
                const { data } = response;
                setAllowRC3(data.active);
            })
            .catch(error => requestError(error));
    }

    async function verifyRC3Group(_id) {
        await api
            .get(`rc3/groups/${_id}`)
            .then(response => {
                const { data } = response;
                if (data.id && data.pluginspace && data.pluginspace.active) {
                    setRC3Group(true);
                } else {
                    setRC3Group(false);
                }
            })
            .catch(error => requestError(error));
    }

    async function loadRC3Register(_id) {
        setRC3Operation();
        let rc3_active = false;
        setInternalLoading(true);
        await api
            .get(`rc3/robots/${_id}`)
            .then(response => {
                const c = response.data;
                setRC3Thing(c);
                rc3_active = c.active;
            })
            .catch(error => requestError(error));
        setInternalLoading(false);
        setRC3Operation(rc3_active ? 'update' : 'create');
    }

    async function loadRobotTypes() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                setTypes(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadLocations() {
        await api
            .get(`locations`)
            .then(response => {
                setAvailableLocations(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadLocationZones() {
        await api
            .get(`zones`)
            .then(response => {
                setAvailableZones(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadCompatibleAppTypes(_id) {
        if (_id !== 'new') {
            await api
                .get(`types/robots/${_id}/apps`)
                .then(response => {
                    const a = response.data;
                    setCompatibleAppTypes(a.applications);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadAvailableApps(_id, group_id) {
        setIsLoading(true);
        await api
            .get(`applications?robot=${_id}`)
            .then(response => {
                const filtered = response.data.filter(a => {
                    return a.group && a.group.id === group_id;
                });
                setAvailableApps(filtered);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadRobot(_id) {
        await api
            .get(`robots/${_id}`)
            .then(response => {
                const robot = response.data;
                const r_type = robot.type || {};
                const r = {
                    ...robot,
                    type_id: r_type.id,
                    type_name: r_type.name,
                };
                const r_settings = robot.settings;
                const r_apps = r_settings.applications || {};
                const r_loc = r_settings.location || {};
                const r_extras = r_settings.extras || {};

                const transfer_limit =
                    r.transfer && r.transfer.until
                        ? new Date(r.transfer.until)
                        : null;

                const transfer_ended =
                    transfer_limit && isAfter(new Date(), transfer_limit);

                setBody(r);
                setIsBound(!!r.group_id);

                loadRC3Register(_id);
                loadCompatibleAppTypes(r.type_id);
                loadAvailableApps(r.type_id, r.group_id);
                setRobotApps(r_apps);
                setRobotLocation(r_loc);
                setRobotExtras(r_extras);
                setTransferInfo({
                    limit: transfer_limit,
                    ended: transfer_ended,
                });

                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    const transfer_ended =
        transferInfo && transferInfo.ended ? transferInfo.ended : false;

    const allow_edit_settings =
        operation === 'update' &&
        body.status === 'active' &&
        isBound &&
        !transfer_ended;

    const allow_rc3_settings =
        allowRC3 &&
        body.status === 'active' &&
        operation === 'update' &&
        isBound &&
        !transfer_ended;

    useEffect(() => {
        verifyRC3();
        loadRobotTypes();
    }, []);

    useEffect(() => {
        if (id === 'new') {
            setOperation('create');
            setIsLoading(false);
        } else {
            setOperation('update');
            loadRobot(id);
            loadLocations();
            loadLocationZones();
        }
    }, [id]);

    useEffect(() => {
        const type = types.find(t => {
            return t.id === body.type_id;
        });
        setCurrType(type);
        if (type) {
            loadAvailableApps(type.id, body.group_id);
        }
    }, [types, body.type_id]);

    useEffect(() => {
        if (isBound && operation === 'update' && body.group_id) {
            verifyRC3Group(body.group_id);
        }
    }, [operation, isBound, body.group_id]);

    useEffect(() => {
        if (availableApps) {
            if (body.application_id) {
                const robot_app = availableApps.find(a => {
                    return a.id === body.application_id;
                });
                setCurrApp(robot_app || {});
            } else {
                setCurrApp({});
            }
        }
    }, [availableApps, body.application_id]);

    function handleOperationChange(op) {
        setOperation(op);
        setTransfer({});
        setBody({});
        setCurrType({});
        setTransferCode('');
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setBehaviourDialogOpen(false);
        setRC3DialogOpen(false);
    }

    async function handleRevokeRC3() {
        setRC3DialogOpen(false);
        if (rc3Thing && rc3Thing.active) {
            setInternalLoading(true);

            await api
                .delete(`rc3/robots/${id}`)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.robots.form.rc3.delete_success" />
                    );
                    await loadRC3Register(id);
                    setInternalLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    async function handleSetupRC3(event, op) {
        event.preventDefault();
        if (id !== 'new') {
            if (op === 'create') {
                const data = { robot_id: id };
                setInternalLoading(true);

                await api
                    .post(`rc3/robots`, data)
                    .then(async () => {
                        toast.success(
                            <LocaleMessage msg="page.robots.form.rc3.create_success" />
                        );
                        await loadRC3Register(id);
                    })
                    .catch(error => requestError(error));
            } else {
                setInternalLoading(true);

                await api
                    .put(`rc3/robots/${id}`)
                    .then(async () => {
                        toast.success(
                            <LocaleMessage msg="page.robots.form.rc3.update_success" />
                        );
                        await loadRC3Register(id);
                    })
                    .catch(error => requestError(error));
            }
            setInternalLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`robots`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.robots.form.create_success" />
                    );

                    history.push(`/robots/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else if (operation === 'update') {
            data.applications = robotApps;
            data.location = robotLocation;
            data.extras = robotExtras;
            await api
                .put(`robots/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robots.form.update_success" />
                    );
                    setIsLoading(false);
                    loadRobot(id);
                })
                .catch(error => requestError(error));
        } else if (operation === 'receive') {
            await api
                .post(`robots/transfers/${transfer.id}`)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robots.form.receive_success" />
                    );
                    history.push(`/robots`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }

        setIsLoading(false);
    }

    async function handleSearchTransfer(event) {
        event.preventDefault();

        setIsLoading(true);

        await api
            .get(`robots/transfers/${transferCode}`)
            .then(async response => {
                const t = response.data;
                setTransfer(t);
                await loadRobot(t.robot.id);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    function handleRevokeOpen(event, _id) {
        event.preventDefault();
        setRC3DialogOpen(true);
    }

    // function handleAppBehaviour(apptype_id, app_id) {
    //     const r_apptype = compatibleAppTypes.find(a => {
    //         return a.id === apptype_id;
    //     });

    //     const r_app = availableApps.find(a => {
    //         return a.id === app_id;
    //     });

    //     setCurrApp(r_app || null);
    //     setCurrAppType(r_apptype || null);
    //     setBehaviourDialogOpen(true);
    //     return null;
    // }

    function renderExitDialog() {
        return (
            <ExitDialog
                open={exitConfirmOpen}
                onClose={() => setExitConfirmOpen(false)}
                destination={`/applications/${
                    exitAppId ? `${exitAppId}/edit` : ''
                }`}
            />
        );
    }

    function renderTransferCode() {
        return (
            <div className="row mb-5 justify-content-center">
                <div className="col-md-8 col-12">
                    <form onSubmit={event => handleSearchTransfer(event)}>
                        <SearchInput>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="transfer-code">
                                    <LocaleMessage msg="page.robots.form.label.transfer_code" />
                                </InputLabel>
                                <Input
                                    id="transfer-code"
                                    fullWidth
                                    value={transferCode || ''}
                                    onChange={event =>
                                        setTransferCode(event.target.value)
                                    }
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            onClick={event =>
                                                handleSearchTransfer(event)
                                            }
                                        >
                                            <MdSearch size={24} />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </SearchInput>
                    </form>
                </div>
            </div>
        );
    }

    function renderTransfer() {
        return (
            <>
                {transfer && transfer.id ? (
                    <>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-5">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.transfer.form.label.status" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            transfer.status
                                                ? transfer.status
                                                : 'open'
                                        }
                                        disabled
                                    >
                                        {transfer_status.map(s => {
                                            return (
                                                <MenuItem
                                                    value={s.value}
                                                    key={s.value}
                                                >
                                                    <LocaleMessage
                                                        msg={s.label_code}
                                                    />
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.robots.form.label.origin_group" />
                                    }
                                    fullWidth
                                    value={
                                        transfer.origin && transfer.origin.group
                                            ? transfer.origin.group
                                            : ''
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.robots.form.label.destination_group" />
                                    }
                                    fullWidth
                                    value={
                                        transfer.destination &&
                                        transfer.destination.group
                                            ? transfer.destination.group
                                            : ''
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-5">
                                <FormControl fullWidth>
                                    <InputLabel id="transfer-type">
                                        <LocaleMessage msg="page.robots.form.label.transfer_type" />
                                    </InputLabel>
                                    <Select
                                        labelId="transfer-type"
                                        id="transfer-type"
                                        value={
                                            transfer && transfer.type
                                                ? transfer.type
                                                : ''
                                        }
                                        disabled
                                    >
                                        <MenuItem value="temporary">
                                            <LocaleMessage msg="page.robots.form.label.temp" />
                                        </MenuItem>
                                        <MenuItem value="definitive">
                                            <LocaleMessage msg="page.robots.form.label.definitive" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {transfer.type === 'temporary' ? (
                                <div className="col-md-6 col-12 mb-5">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="label.form.until" />
                                        }
                                        fullWidth
                                        value={
                                            transfer.until
                                                ? new Date(
                                                      transfer.until
                                                  ).toLocaleString(
                                                      locales.pt_BR
                                                  )
                                                : ''
                                        }
                                        disabled
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </>
        );
    }

    function renderRobot() {
        const { limit: transfer_limit } = transferInfo;

        return (
            <>
                {operation !== 'receive' || (body && body.id) ? (
                    <>
                        {operation === 'update' &&
                        body.status &&
                        body.status !== 'active' ? (
                            <div className="row">
                                <div
                                    className="col-12 mb-5"
                                    style={{ textAlign: 'center' }}
                                >
                                    <Chip
                                        variant="outlined"
                                        label={
                                            <LocaleMessage
                                                msg={`robot.status.${body.status}`}
                                            />
                                        }
                                    />
                                </div>
                                <div
                                    className="col-12 mb-5"
                                    style={{ textAlign: 'center' }}
                                >
                                    <LocaleMessage
                                        msg={`robot.status.${body.status}.info`}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {body.binding === 'in_transfer' ||
                        (body.transfer &&
                            body.transfer.status === 'running') ? (
                            <div className="row">
                                <div
                                    className="col-12"
                                    style={{ textAlign: 'center' }}
                                >
                                    {transfer_ended ? (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            label={
                                                <LocaleMessage msg="page.robots.form.label.expired_transfer" />
                                            }
                                        />
                                    ) : (
                                        <>
                                            {transfer_limit ? (
                                                <Chip
                                                    variant="outlined"
                                                    color="primary"
                                                    label={
                                                        <>
                                                            <LocaleMessage msg="page.robots.form.label.transfered_until" />
                                                            {` ${transfer_limit.toLocaleString(
                                                                date_format.format
                                                            )}`}
                                                        </>
                                                    }
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null}
                        <div className="row mb-1">
                            <RobotInfo
                                robot={body}
                                setRobot={r => setBody(r)}
                                robotTypes={types}
                                type={currType}
                                operation={
                                    operation === 'create' ? 'create' : 'update'
                                }
                                disabled={
                                    operation === 'receive' || transfer_ended
                                }
                            />
                        </div>
                    </>
                ) : null}
            </>
        );
    }

    function renderGroup() {
        return (
            <div
                className="col-12"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <div className="col-md-6 col-12">
                    <FormSelect
                        classes="mb-5"
                        options={admin_groups}
                        labelKey="name"
                        disabled={operation !== 'update' && isBound}
                        label={<LocaleMessage msg="label.form.group" />}
                        onChange={value => {
                            setBody({
                                ...body,
                                group_id: value,
                            });
                        }}
                        value={body.group_id ? body.group_id : ''}
                    />
                </div>
            </div>
        );
    }

    function renderOperationSelector() {
        return (
            <div
                className="view-control mt-1 mb-5"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                >
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.robots.form.bind.title" />
                        }
                        placement="top"
                        arrow
                    >
                        <Button
                            onClick={() => handleOperationChange('create')}
                            className={operation === 'create' ? 'active' : ''}
                        >
                            <MdAdd
                                size={20}
                                style={{
                                    marginRight: '10px',
                                }}
                            />
                            <LocaleMessage msg="page.robots.form.bind.short" />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.robots.form.receive.title" />
                        }
                        placement="top"
                        arrow
                    >
                        <Button
                            onClick={() => handleOperationChange('receive')}
                            className={operation === 'receive' ? 'active' : ''}
                        >
                            <MdCloudDownload
                                size={20}
                                style={{
                                    marginRight: '10px',
                                }}
                            />
                            <LocaleMessage msg="page.robots.form.receive.short" />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </div>
        );
    }

    function renderAppList() {
        return compatibleAppTypes && Array.isArray(compatibleAppTypes)
            ? compatibleAppTypes.map(a_type => {
                  const type_app_id =
                      robotApps && robotApps[a_type.slug]
                          ? robotApps[a_type.slug]
                          : false;

                  return (
                      <div className="col-12 row mb-3" key={a_type.id}>
                          <div className="col-6">
                              <FormControl fullWidth>
                                  <InputLabel id="appconfig-label">
                                      <LocaleMessage msg="page.robots.form.label.application.type" />
                                  </InputLabel>
                                  <Select
                                      value={a_type.id}
                                      onChange={() => {}}
                                      disabled
                                  >
                                      {compatibleAppTypes.map(a => {
                                          return (
                                              <MenuItem value={a.id} key={a.id}>
                                                  <LocaleMessage
                                                      msg={`apps.${a.slug}`}
                                                  />
                                              </MenuItem>
                                          );
                                      })}
                                  </Select>
                              </FormControl>
                          </div>
                          <div
                              className={`col-${
                                  robotApps && robotApps[a_type.slug]
                                      ? '5'
                                      : '6'
                              }`}
                          >
                              <FormControl fullWidth>
                                  <InputLabel id="appconfig-label">
                                      <LocaleMessage msg="page.robots.form.label.application" />
                                  </InputLabel>
                                  <Select
                                      value={type_app_id}
                                      onChange={event =>
                                          setRobotApps({
                                              ...robotApps,
                                              [a_type.slug]: event.target.value,
                                          })
                                      }
                                      disabled={body.status !== 'active'}
                                  >
                                      <MenuItem value={false}>
                                          <LocaleMessage msg="page.robots.form.label.no_application" />
                                      </MenuItem>
                                      {availableApps.map(a => {
                                          if (
                                              a.app_type &&
                                              a.app_type.id === a_type.id
                                          ) {
                                              return (
                                                  <MenuItem
                                                      value={a.id}
                                                      key={a.id}
                                                  >
                                                      {a.name}
                                                  </MenuItem>
                                              );
                                          }
                                          return null;
                                      })}
                                  </Select>
                              </FormControl>
                          </div>
                          {robotApps && robotApps[a_type.slug] ? (
                              <IconArea className="col-1 px-0">
                                  <Tooltip
                                      title={
                                          <LocaleMessage msg="page.robots.form.label.application.go" />
                                      }
                                      placement="top"
                                  >
                                      <IconButton
                                          onClick={() => {
                                              setExitAppId(type_app_id);
                                              setExitConfirmOpen(true);
                                          }}
                                      >
                                          <MdLaunch size={20} />
                                      </IconButton>
                                  </Tooltip>
                              </IconArea>
                          ) : null}
                      </div>
                  );
              })
            : null;
    }

    function renderLocationSettings() {
        return (
            <div className="col-12 row mb-3">
                <div className="col-6">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="label.form.location" />
                        </InputLabel>
                        <Select
                            value={
                                robotLocation && robotLocation.location_id
                                    ? robotLocation.location_id
                                    : false
                            }
                            onChange={event =>
                                setRobotLocation({
                                    ...robotLocation,
                                    location_id: event.target.value,
                                })
                            }
                            disabled={body.status !== 'active'}
                        >
                            <MenuItem value={false}>
                                <LocaleMessage msg="label.form.no_location" />
                            </MenuItem>
                            {availableLocations.map(l => {
                                return (
                                    <MenuItem value={l.id} key={l.id}>
                                        {l.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-6">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="label.form.location_zone" />
                        </InputLabel>
                        <Select
                            value={
                                robotLocation && robotLocation.zone_id
                                    ? robotLocation.zone_id
                                    : false
                            }
                            onChange={event =>
                                setRobotLocation({
                                    ...robotLocation,
                                    zone_id: event.target.value,
                                })
                            }
                            disabled={body.status !== 'active'}
                        >
                            <MenuItem value={false}>
                                <LocaleMessage msg="label.form.no_location_zone" />
                            </MenuItem>
                            {availableZones.map(z => {
                                if (
                                    robotLocation &&
                                    z.location &&
                                    z.location.id === robotLocation.location_id
                                ) {
                                    return (
                                        <MenuItem value={z.id} key={z.id}>
                                            {z.name}
                                        </MenuItem>
                                    );
                                }
                                return null;
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }

    function renderExtraSettings(extras) {
        return extras.map(item => {
            const { key, label, info, type, show_form } = item;

            if (!show_form) {
                return null;
            }

            const item_label = <LocaleMessage msg={`${label}`} />;
            const item_value =
                robotExtras && robotExtras[key] ? robotExtras[key] : '';

            const TextInput =
                type === 'password' ? FormPasswordInput : TextField;

            return (
                <div className="col-12 row" key={item.item_id}>
                    <IconArea className="col-1">
                        <IconButton
                            aria-label="edit"
                            onClick={() =>
                                setAllowExtras({
                                    ...allowExtras,
                                    [key]: !allowExtras[key],
                                })
                            }
                        >
                            {allowExtras[key] ? (
                                <MdLockOpen size={22} />
                            ) : (
                                <MdLockOutline size={22} />
                            )}
                        </IconButton>
                    </IconArea>
                    <div className={`${info ? 'col-10' : 'col-11'} mb-3`}>
                        <TextInput
                            fullWidth
                            label={item_label}
                            value={item_value}
                            onChange={event =>
                                setRobotExtras({
                                    ...robotExtras,
                                    [key]: event.target.value,
                                })
                            }
                            disabled={!allowExtras[key]}
                        />
                    </div>
                    {info ? (
                        <IconArea className="col-1">
                            <InjectIntl>
                                {({ intl }) => (
                                    <Tooltip
                                        title={intl.formatMessage({
                                            id: info,
                                        })}
                                        arrow
                                    >
                                        <IconButton aria-label="help">
                                            <MdHelp size={22} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </InjectIntl>
                        </IconArea>
                    ) : null}
                </div>
            );
        });
    }

    function renderAdditionalSettings() {
        const type_settings =
            currType && currType.settings ? currType.settings : {};
        const extras = type_settings.extras || [];

        const extra_items = Object.keys(extras).map(i => {
            return extras[i];
        });

        const extra_form = extra_items.filter(i => {
            return i.show_form;
        });

        return (
            <div style={{ padding: '20px 15px' }}>
                {extra_form && extra_form.length > 0 ? (
                    <div className="row col-12 mb-3">
                        <h3>
                            <LocaleMessage msg="page.robots.form.label.extras.title" />
                        </h3>
                        <AppConfigArea className="row mt-1 mb-3 p-3">
                            {renderExtraSettings(extra_form)}
                        </AppConfigArea>
                    </div>
                ) : null}
                <div className="row col-12 mb-3">
                    <h3>
                        <LocaleMessage msg="page.robots.form.label.application.list" />
                    </h3>
                    <AppConfigArea className="row mt-1 mb-3 p-3">
                        {renderAppList()}
                    </AppConfigArea>
                </div>

                <div className="col-12 row">
                    <h3>
                        <LocaleMessage msg="page.robots.form.label.location.binding" />
                    </h3>
                    <AppConfigArea className="row mt-1 mb-3 p-3">
                        {renderLocationSettings()}
                    </AppConfigArea>
                </div>

                <LocaleMessage msg="page.robots.form.label.restart_app" />
            </div>
        );
    }

    function renderBehaviourDialog() {
        return (
            <div>
                <Dialog
                    open={behaviourDialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <LocaleMessage msg="page.robots.form.edit_behaviour" />
                    </DialogTitle>
                    <AppBehaviourParameters
                        app_type={currAppType}
                        application={currApp}
                        robot_id={id}
                        handleDialogClose={() => setBehaviourDialogOpen(false)}
                        requestError={error => requestError(error)}
                    />
                </Dialog>
            </div>
        );
    }

    function renderActionButton() {
        return (
            <div className="col-12 mt-5 mb-3">
                {operation !== 'receive' || (body && body.id) ? (
                    <Button
                        className="p-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                        disabled={
                            (operation === 'receive' && !admin_user) ||
                            (operation === 'update' &&
                                body.status !== 'active') ||
                            transfer_ended
                        }
                    >
                        {operation === 'receive' ? (
                            <LocaleMessage msg="page.robots.form.receive.short" />
                        ) : (
                            <LocaleMessage msg="button.save" />
                        )}
                    </Button>
                ) : null}
            </div>
        );
    }

    function renderRC3Options() {
        // Verifies if robot type is allowed in RC3
        const type_settings =
            currType && currType.settings ? currType.settings : {};
        const { allow_rc3: robot_rc3 } = type_settings;
        return robot_rc3 ? (
            <>
                {internalLoading ? (
                    <div className="row" style={centerStyle}>
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={50}
                            width={50}
                        />
                    </div>
                ) : (
                    <>
                        {RC3Group ? (
                            <div className="row" style={centerStyle}>
                                {rc3Operation === 'create' ? (
                                    <>
                                        <div
                                            className="col-12 mb-3"
                                            style={centerStyle}
                                        >
                                            <Typography variant="body1">
                                                <LocaleMessage msg="page.robots.form.rc3.not_set" />
                                            </Typography>
                                        </div>
                                        {admin_user ? (
                                            <div className="col-md-8 col-12 mb-3">
                                                <Button
                                                    className="p-3"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={event =>
                                                        handleSetupRC3(
                                                            event,
                                                            'create'
                                                        )
                                                    }
                                                    fullWidth
                                                    size="large"
                                                >
                                                    <LocaleMessage msg="button.set_up" />
                                                </Button>
                                            </div>
                                        ) : (
                                            <>
                                                <LocaleMessage msg="page.robots.form.rc3.admin" />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="col-12 mb-3"
                                            style={{
                                                ...centerStyle,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography variant="body1">
                                                <LocaleMessage msg="page.robots.form.rc3.already_set" />
                                            </Typography>

                                            <Typography
                                                variant="h4"
                                                style={{ margin: '15px' }}
                                            >
                                                {rc3Thing && rc3Thing.principals
                                                    ? rc3Thing.principals.length
                                                    : 0}
                                            </Typography>
                                            <Typography variant="body1">
                                                <LocaleMessage msg="page.robots.form.rc3.certificates" />
                                            </Typography>
                                        </div>

                                        <div className="col-md-8 col-12 mb-3 row">
                                            <Button
                                                className="p-3"
                                                variant="contained"
                                                color="secondary"
                                                onClick={event =>
                                                    handleRevokeOpen(event)
                                                }
                                                fullWidth
                                                size="large"
                                                disabled={!admin_user}
                                            >
                                                <LocaleMessage msg="button.revoke_access" />
                                            </Button>
                                        </div>
                                        {!admin_user ? (
                                            <>
                                                <LocaleMessage msg="page.robots.form.rc3.admin" />
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="body1">
                                    <LocaleMessage msg="page.robots.form.rc3.unavailable_group" />
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </>
        ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body1">
                    <LocaleMessage msg="page.robots.form.rc3.incompatible" />
                </Typography>
            </div>
        );
    }

    function buildRC3Dialog() {
        return (
            <Dialog
                open={RC3DialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <LocaleMessage msg="page.robots.form.rc3.revoke_confirm" />
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setRC3DialogOpen(false)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={() => handleRevokeRC3()}
                        color="secondary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.revoke_access" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function getTitle() {
        switch (operation) {
            case 'create':
                return <LocaleMessage msg="page.robots.form.bind.title" />;
            case 'update':
                return <LocaleMessage msg="page.robots.form.edit_title" />;
            case 'receive':
                return <LocaleMessage msg="page.robots.form.receive.title" />;
            default:
                return <LocaleMessage msg="page.robots.form.settings" />;
        }
    }

    return (
        <PageContent
            title={getTitle()}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/robots',
                    title: <LocaleMessage msg="breadcrumbs.robots" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {RC3DialogOpen ? buildRC3Dialog() : null}
                {exitConfirmOpen ? renderExitDialog() : null}
                {behaviourDialogOpen ? renderBehaviourDialog() : null}
                <div className="row full-body">
                    {operation !== 'update' ? renderOperationSelector() : null}

                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.robots.form.label.settings" />
                            }
                            Icon={MdAccountBox}
                        >
                            <>
                                {operation === 'receive'
                                    ? renderTransferCode()
                                    : null}

                                {operation === 'update' &&
                                !isBound &&
                                (body.binding === 'waiting' ||
                                    body.binding === 'not_bound')
                                    ? renderGroup()
                                    : null}
                                <form noValidate autoComplete="off">
                                    {renderRobot()}
                                    {operation === 'receive'
                                        ? renderTransfer()
                                        : null}
                                    {allow_edit_settings
                                        ? renderAdditionalSettings()
                                        : null}
                                </form>
                                {renderActionButton()}
                            </>
                        </CardSideBordered>
                    </div>

                    {allow_rc3_settings ? (
                        <div className="col-md-8 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.robots.form.rc3.settings" />
                                }
                                hide
                                Icon={MdSatellite}
                            >
                                {renderRC3Options()}
                            </CardSideBordered>
                        </div>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

RobotForm.propTypes = {
    match: PropTypes.object.isRequired,
};
