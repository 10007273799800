/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSpellcheck, MdFormatShapes, MdDelete } from 'react-icons/md';
import ReactJson from 'react-json-view';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const formatterHeaders = [
    { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
    {
        id: 'updated',
        label: <LocaleMessage msg="table.headers.updated" />,
        order_by: 'updated_timestamp',
    },
];

export default function ExtractionTypes() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);
    const [testLoading, setTestLoading] = useState(false);

    const [types, setTypes] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currFormatter, setCurrFormatter] = useState(null);
    const [formatterDialogOpen, setFormatterDialogOpen] = useState(false);

    const [testOpen, setTestOpen] = useState(false);
    const [testObj, setTestObj] = useState({});
    const [testResult, setTestResult] = useState(null);

    const [formatters, setFormatters] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`types?group=extraction`)
            .then(response => {
                const data = response.data.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        label: `extractions.${t.slug}`,
                        updated: formatDistance(
                            new Date(t.updated),
                            new Date(),
                            {
                                addSuffix: true,
                                locale: date_loc,
                            }
                        ),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setTypes(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function loadFormatters() {
        await api
            .get(`info_formatters/public`)
            .then(response => {
                const data = response.data.map(f => {
                    const updated = new Date(f.updated);
                    return {
                        ...f,
                        name: (
                            <LocaleMessage
                                msg={`tools.info_formatters.${f.name}`}
                            />
                        ),
                        updated: formatDistance(
                            new Date(f.updated),
                            new Date(),
                            {
                                addSuffix: true,
                                locale: date_loc,
                            }
                        ),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setFormatters(data);
            })
            .catch(err => requestError(err));
    }

    useEffect(() => {
        loadItems();
        loadFormatters();
    }, [date_loc]);

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/extractions/${id}`);
    }

    function handleDialogClose(event, dialog) {
        event.preventDefault();
        switch (dialog) {
            case 'delete':
                setDialogOpen(false);
                break;
            case 'test':
                setTestOpen(false);
                break;
            case 'formatter':
                setFormatterDialogOpen(false);
                break;
            default:
                setDialogOpen(false);
                setTestOpen(false);
                setFormatterDialogOpen(false);
        }
    }

    async function deleteFormatter() {
        setIsLoading(true);
        setFormatterDialogOpen(false);

        await api
            .delete(`info_formatters/public/${currFormatter}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.info_formatters.custom.list.delete_success" />
                );
                await loadFormatters();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleFormatterDeleteOpen(event, _id) {
        setCurrFormatter(_id);
        event.preventDefault();
        setFormatterDialogOpen(true);
    }

    function handleFormatterTableRowClick(event, id) {
        event.preventDefault();
        setCurrFormatter(id);
        history.push(`/tools/info_formatters/${id}`);
    }

    async function submitTestExtraction(event) {
        event.preventDefault();
        setTestLoading(true);
        await api
            .post(`extractions/test/${currItem}`, testObj)
            .then(response => {
                setTestResult({ ...response.data });
            })
            .catch(error => requestError(error));

        setTestLoading(false);
    }

    async function deleteType() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`types/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.extractions.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            { id: 'slug', label: <LocaleMessage msg="table.headers.slug" /> },
            { id: 'label', label: <LocaleMessage msg="table.headers.label" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            // {
            //     id: 'test',
            //     label: <LocaleMessage msg="button.try_it" />,
            //     icon: <MdLaunch />,
            //     action: handleTestOpen,
            // },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    headerColumns={headCells}
                    data={types}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event, 'delete')}
                title={
                    <LocaleMessage msg="page.extractions.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteType(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildFormatterDialog() {
        return (
            <SimpleDialog
                open={formatterDialogOpen}
                onClose={event => handleDialogClose(event, 'formatter')}
                title={
                    <LocaleMessage msg="page.extractions.list.info_formatter.delete.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'formatter_cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'formatter_delete',
                        onClick: () => deleteFormatter(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildTestDialog() {
        return (
            <div>
                <Dialog
                    open={testOpen}
                    onClose={event => handleDialogClose(event, 'test')}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <LocaleMessage msg="page.extractions.list.try_it" />
                    </DialogTitle>
                    {!testLoading ? (
                        <>
                            <DialogContent>
                                <form
                                    onSubmit={event =>
                                        submitTestExtraction(event)
                                    }
                                >
                                    <TextField
                                        className="mb-3"
                                        label={
                                            <LocaleMessage msg="page.extractions.list.test.sentence" />
                                        }
                                        fullWidth
                                        value={testObj.text ? testObj.text : ''}
                                        onChange={event =>
                                            setTestObj({
                                                ...testObj,
                                                text: event.target.value,
                                            })
                                        }
                                    />
                                </form>

                                {testResult ? (
                                    <>
                                        <ReactJson
                                            src={testResult}
                                            name={false}
                                            enableClipboard={false}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                        />
                                    </>
                                ) : null}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={event =>
                                        handleDialogClose(event, 'test')
                                    }
                                    color="primary"
                                >
                                    <LocaleMessage msg="button.cancel" />
                                </Button>
                                <Button
                                    onClick={event =>
                                        submitTestExtraction(event)
                                    }
                                    color="primary"
                                >
                                    <LocaleMessage msg="button.try_it" />
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <Splash />
                    )}
                </Dialog>
            </div>
        );
    }

    function buildFormattersList() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleFormatterDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    headerColumns={formatterHeaders}
                    data={formatters}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleFormatterTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.extractions.title" />}
            currentPage={<LocaleMessage msg="page.extractions.title.short" />}
            breadcrumbs={[]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                {formatterDialogOpen ? buildFormatterDialog() : null}
                {testOpen ? buildTestDialog() : null}
                <div className="row full-body">
                    <div className="col-md-6 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.extractions.form.label.settings" />
                            }
                            Icon={MdSpellcheck}
                            hide
                        >
                            <>
                                <div className="body-top-controls">
                                    <NewItemButton
                                        link="/extractions/new"
                                        text={
                                            <LocaleMessage msg="page.extractions.list.add" />
                                        }
                                    />
                                </div>
                                <>{buildListView()}</>
                            </>
                        </CardSideBordered>
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.extractions.list.info_formatter.list" />
                            }
                            Icon={MdFormatShapes}
                            hide
                        >
                            <>
                                <div className="body-top-controls">
                                    <NewItemButton
                                        link="tools/info_formatters/new"
                                        text={
                                            <LocaleMessage msg="page.extractions.list.info_formatter.add_formatter" />
                                        }
                                    />
                                </div>
                                {buildFormattersList()}
                            </>
                        </CardSideBordered>
                    </div>
                </div>
            </>
        </PageContent>
    );
}
