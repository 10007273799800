/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { MdAndroid, MdDelete } from 'react-icons/md';
import ReactJson from 'react-json-view';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    FormControl,
    FormGroup,
    FormLabel,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FileInput from '~/components/FileInput';
import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { RowOptions, SettingsArea, RC3StatusView, Placeholder } from './styles';

const { rc3_status_list } = lists;

export default function RobotTypeForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [robotSettings, setRobotSettings] = useState({});

    const [body, setBody] = useState({
        name: '',
        group: 'robot',
    });
    const [file, setFile] = useState({
        id: null,
        url: null,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [galleries, setGalleries] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [currGallery, setCurrGallery] = useState(null);
    const [currStatus, setCurrStatus] = useState('');

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadAlbumFiles(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        } else {
            setFileList([]);
        }
    }

    async function loadGalleries(_id) {
        setIsLoading(true);
        await api
            .get(`types/${_id}/albums`)
            .then(response => {
                const data = response.data.map(g => ({
                    id: g.id,
                    name: g.name,
                }));
                setGalleries(data);
                setCurrGallery(data && data[0] ? data[0].id : null);
            })
            .catch(error => requestError(error));
    }

    async function loadType(_id) {
        if (_id === 'new') {
            setOperation('create');
            setIsLoading(false);
        } else {
            setOperation('update');

            await api
                .get(`types/${_id}`)
                .then(async response => {
                    const t = response.data;

                    setBody({
                        name: t.name,
                        file_id: t.file.id,
                        slug: t.slug,
                    });
                    setFile({
                        id: t.file.id,
                        url: t.file.url,
                    });
                    setRobotSettings(t.settings || {});
                    await loadGalleries(_id);
                })
                .catch(error => requestError(error));
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function updateType(data) {
        await api
            .put(`types/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.robot_types.form.update_success" />
                );
                setIsLoading(false);
                loadType(id);
            })
            .catch(error => requestError(error));
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`types/robot/files`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id };
                setBody(updated);
                updateType(updated);
            })
            .catch(error => requestError(error));
    };

    function getSettings(key, defaultValue = {}) {
        return robotSettings && robotSettings[key]
            ? robotSettings[key]
            : defaultValue;
    }

    function updateSettings(key, value) {
        setRobotSettings({ ...robotSettings, [key]: value });
    }

    useEffect(() => {
        loadType(id);
    }, [id]);

    useEffect(() => {
        loadAlbumFiles(currGallery);
    }, [currGallery]);

    function saveRC3Images(images) {
        setRobotSettings({
            ...robotSettings,
            rc3_images: images,
        });
        setMediaDialogOpen(false);
    }

    function handleUnsetImage() {
        const curr_files = robotSettings.rc3_images || {};
        curr_files[currStatus] = null;

        saveRC3Images(curr_files);
    }

    function handleImageSelection(event, index) {
        const sel_file = fileList[index] || {};

        const rc3_file = {
            id: sel_file.id,
            url: sel_file.url,
        };

        const curr_files = robotSettings.rc3_images || {};
        curr_files[currStatus] = rc3_file;

        saveRC3Images(curr_files);
    }

    function handleRC3ItemClick(event, item) {
        setMediaDialogOpen(true);
        setCurrStatus(item);
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
        setCurrStatus('');
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options: robotSettings };

        if (operation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.robot_types.form.create_success" />
                    );

                    history.push(`/robottypes/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    function handleNewItem(type) {
        const empty = {
            languages: {
                key: '',
                label: '',
                parameters: {},
            },
            hardware: {
                value: '',
                label: '',
                parameters: {},
            },
            extras: {
                key: '',
                label: '',
                type: 'text',
                parameters: {},
            },
        };
        const items = getSettings(type);
        const item_id = (+new Date()).toString(36);
        items[item_id] = {
            item_id,
            ...empty[type],
        };
        updateSettings(type, { ...items });
    }

    function handleDeleteItem(type, idx) {
        const items = getSettings(type);
        if (!items[idx]) {
            return;
        }
        delete items[idx];
        updateSettings(type, { ...items });
    }

    function onItemChange(value, type, index, key) {
        const items = getSettings(type);
        if (items[index]) {
            items[index] = {
                ...items[index],
                [key]: value,
            };
        }
        updateSettings(type, { ...items });
    }

    function saveJSONParameters(type, index, edit) {
        const items = getSettings(type);
        if (items[index]) {
            items[index] = {
                ...items[index],
                parameters: edit.updated_src,
            };
        }
        updateSettings(type, { ...items });
    }

    function fileInput() {
        return (
            <div className="col-md-4 col-12 mb-5" style={{ height: '200px' }}>
                <FileInput
                    defaultValue={file}
                    onFileUpload={onFileUpload}
                    multiple={false}
                    text={<LocaleMessage msg="label.form.change_image" />}
                />
            </div>
        );
    }

    function renderGallery() {
        const currGalleryObj = galleries.find(g => {
            return g.id === currGallery;
        });
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.telepresence.form.gallery" />
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {!g.editable ? (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : null}
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={false}
                                    onClick={(e, _id) =>
                                        handleImageSelection(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    <LocaleMessage msg="page.applications.telepresence.form.media" />
                }
                content={renderGallery()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'default',
                        onClick: () => handleUnsetImage(),
                        label: <LocaleMessage msg="button.default" />,
                    },
                ]}
            />
        );
    }

    function renderHardwareItem(item) {
        const { item_id, order, value, label, parameters } = item;
        return (
            <FormGroup
                row
                key={item_id}
                className="row col-12 pt-3 pb-3"
                style={{ padding: '0px', border: '1px solid #ddd' }}
            >
                <div className="col-11 row">
                    <div className="col-1 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.sensor_order" />
                            }
                            fullWidth
                            value={order}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'hardware',
                                    item_id,
                                    'order'
                                )
                            }
                        />
                    </div>
                    <div className="col-5 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.sensor_key" />
                            }
                            fullWidth
                            value={value}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'hardware',
                                    item_id,
                                    'value'
                                )
                            }
                        />
                    </div>
                    <div className="col-6 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.sensor_name" />
                            }
                            fullWidth
                            value={label}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'hardware',
                                    item_id,
                                    'label'
                                )
                            }
                        />
                    </div>
                    <div className="col-12">
                        <FormControl
                            component="fieldset"
                            fullWidth
                            style={{
                                border: '1px solid #ddd',
                                padding: '10px',
                            }}
                        >
                            <FormLabel className="mb-1">
                                <LocaleMessage msg="label.form.parameters" />
                            </FormLabel>
                            <ReactJson
                                src={parameters || {}}
                                name={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                onAdd={edit =>
                                    saveJSONParameters(
                                        'hardware',
                                        item_id,
                                        edit
                                    )
                                }
                                onEdit={edit =>
                                    saveJSONParameters(
                                        'hardware',
                                        item_id,
                                        edit
                                    )
                                }
                                onDelete={edit =>
                                    saveJSONParameters(
                                        'hardware',
                                        item_id,
                                        edit
                                    )
                                }
                            />
                        </FormControl>
                    </div>
                </div>
                <RowOptions className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() =>
                            handleDeleteItem('hardware', item.item_id)
                        }
                    />
                </RowOptions>
            </FormGroup>
        );
    }

    function renderExtraItem(item) {
        const {
            item_id,
            key,
            label,
            type,
            info,
            show_form,
            send_robot,
            parameters,
        } = item;
        return (
            <FormGroup
                row
                key={item_id}
                className="row col-12 pt-3 pb-3"
                style={{ padding: '0px', border: '1px solid #ddd' }}
            >
                <div className="col-11 row">
                    <div className="col-6 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.extras.key" />
                            }
                            fullWidth
                            value={key || ''}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'extras',
                                    item_id,
                                    'key'
                                )
                            }
                        />
                    </div>
                    <div className="col-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.robot_types.form.label.extras.type" />
                            </InputLabel>
                            <Select
                                value={type || ''}
                                onChange={event => {
                                    onItemChange(
                                        event.target.value,
                                        'extras',
                                        item_id,
                                        'type'
                                    );
                                }}
                            >
                                <MenuItem value="text">
                                    <LocaleMessage msg="label.input.type.text" />
                                </MenuItem>
                                <MenuItem value="password">
                                    <LocaleMessage msg="label.input.type.password" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-12 mb-1">
                        <FormSwitch
                            value={show_form || false}
                            onChange={event =>
                                onItemChange(
                                    event.target.checked,
                                    'extras',
                                    item_id,
                                    'show_form'
                                )
                            }
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.extras.show_form" />
                            }
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <FormSwitch
                            value={send_robot || false}
                            onChange={event =>
                                onItemChange(
                                    event.target.checked,
                                    'extras',
                                    item_id,
                                    'send_robot'
                                )
                            }
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.extras.send_robot" />
                            }
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.extras.name" />
                            }
                            fullWidth
                            value={label}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'extras',
                                    item_id,
                                    'label'
                                )
                            }
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.robot_types.form.label.extras.info" />
                            }
                            fullWidth
                            value={info || ''}
                            onChange={event =>
                                onItemChange(
                                    event.target.value,
                                    'extras',
                                    item_id,
                                    'info'
                                )
                            }
                        />
                    </div>
                    <div className="col-12">
                        <FormControl
                            component="fieldset"
                            fullWidth
                            style={{
                                border: '1px solid #ddd',
                                padding: '10px',
                            }}
                        >
                            <FormLabel className="mb-1">
                                <LocaleMessage msg="page.robot_types.form.label.extras.settings" />
                            </FormLabel>
                            <ReactJson
                                src={parameters || {}}
                                name={false}
                                displayObjectSize={false}
                                displayDataTypes={false}
                                onAdd={edit =>
                                    saveJSONParameters('extras', item_id, edit)
                                }
                                onEdit={edit =>
                                    saveJSONParameters('extras', item_id, edit)
                                }
                                onDelete={edit =>
                                    saveJSONParameters('extras', item_id, edit)
                                }
                            />
                        </FormControl>
                    </div>
                </div>
                <RowOptions className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => handleDeleteItem('extras', item.item_id)}
                    />
                </RowOptions>
            </FormGroup>
        );
    }

    function renderRC3ImageSelection(key, item) {
        const rc3_item = rc3_status_list[key] || {};
        const image = item || file;

        return (
            <RC3StatusView
                key={`rc3_image_${key}`}
                className="col-md-3 col-6 mb-3"
                color={rc3_item.color}
                onClick={e => handleRC3ItemClick(e, key)}
            >
                {image && image.url ? (
                    <img
                        src={image.url}
                        alt={`RC3 Status: ${key}`}
                        style={{
                            objectPosition: 'top',
                        }}
                    />
                ) : (
                    <Placeholder className="mb-3" />
                )}
                <LocaleMessage msg={rc3_item.label_code} />
            </RC3StatusView>
        );
    }

    function renderRC3Images() {
        const rc3_images = robotSettings.rc3_images || {};
        const rc3_status = Object.keys(rc3_status_list);

        return (
            <div className="col-12 row mt-3">
                {rc3_status.map(s => renderRC3ImageSelection(s, rc3_images[s]))}
            </div>
        );
    }

    function renderSettings() {
        const { allow_rc3, hardware, extras } = robotSettings;

        return (
            <>
                <SettingsArea className="col-12 mb-5">
                    <FormControl
                        className="mb-3"
                        component="fieldset"
                        fullWidth
                    >
                        <FormLabel className="mb-3">
                            <LocaleMessage msg="page.robot_types.form.label.hardware" />
                        </FormLabel>
                        {hardware
                            ? Object.keys(hardware).map(idx => {
                                  const item = hardware[idx];
                                  return renderHardwareItem(item);
                              })
                            : null}
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNewItem('hardware')}
                        fullWidth
                    >
                        <LocaleMessage msg="page.robot_types.form.label.add_sensor" />
                    </Button>
                </SettingsArea>
                <SettingsArea className="col-12 mb-5">
                    <FormControl
                        className="mb-3"
                        component="fieldset"
                        fullWidth
                    >
                        <FormLabel className="mb-3">
                            <LocaleMessage msg="page.robot_types.form.label.extras" />
                        </FormLabel>
                        {extras
                            ? Object.keys(extras).map(idx => {
                                  const item = extras[idx];
                                  return renderExtraItem(item);
                              })
                            : null}
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNewItem('extras')}
                        fullWidth
                    >
                        <LocaleMessage msg="page.robot_types.form.label.extras.add" />
                    </Button>
                </SettingsArea>
                <SettingsArea className="col-12 mb-5">
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel className="mb-3">
                            <LocaleMessage msg="page.robot_types.form.label.settings.rc3" />
                        </FormLabel>
                        <FormGroup className="mb-3">
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={allow_rc3 || false}
                                        value="allow_rc3"
                                        onChange={event =>
                                            updateSettings(
                                                'allow_rc3',
                                                event.target.checked
                                            )
                                        }
                                    />
                                }
                                label={
                                    <LocaleMessage msg="page.robot_types.form.label.allow_rc3" />
                                }
                            />
                        </FormGroup>
                        {allow_rc3 ? renderRC3Images() : null}
                    </FormControl>
                </SettingsArea>
            </>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.robot_types.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.robot_types.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/robottypes',
                    title: <LocaleMessage msg="breadcrumbs.robot_types" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {mediaDialogOpen ? renderMediaDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.robot_types.form.label.settings" />
                            }
                            Icon={MdAndroid}
                        >
                            <>
                                <div className="row">
                                    {operation === 'update'
                                        ? fileInput()
                                        : null}

                                    <div className="col-md-8 col-12 mb-5">
                                        <TextField
                                            id="robottype-name"
                                            label={
                                                <LocaleMessage msg="page.robot_types.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            className="mb-5"
                                        />
                                        {operation === 'update' ? (
                                            <TextField
                                                id="robottype-slug"
                                                label={
                                                    <LocaleMessage msg="label.form.slug" />
                                                }
                                                fullWidth
                                                value={body.slug || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        slug:
                                                            event.target.value,
                                                    })
                                                }
                                                className="mb-5"
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">{renderSettings()}</div>
                            </>
                        </CardSideBordered>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

RobotTypeForm.propTypes = {
    match: PropTypes.object.isRequired,
};
