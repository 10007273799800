import { addLocaleData } from 'react-intl';

import enGBLang from './entries/en_GB';
import enLang from './entries/en_US';
import esLang from './entries/es_ES';
import brLang from './entries/pt_BR';

const AppLocale = {
    br: brLang,
    es: esLang,
    en: enLang,
    enGB: enGBLang,
};

addLocaleData(AppLocale.br.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.enGB.data);

export default AppLocale;
