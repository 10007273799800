import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 0px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;
