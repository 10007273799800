/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function FormatterSetup({ formatters, settings, onChange }) {
    function onFormatterChange(event, key) {
        const val = event.target.value;
        if (key !== 'type') {
            onChange({ ...settings, [key]: val });
        } else {
            onChange({ type: val });
        }
    }

    return (
        <div
            className="mt-3"
            style={{
                display: 'flex',
                alignItems: 'top',
                justifyContent: 'space-around',
            }}
        >
            <div className="col-md-4 col-12 mb-5">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.survey_steps.form.label.formatter" />
                    </InputLabel>
                    <Select
                        id="answer-formatter"
                        value={settings.type ? settings.type : 'no-formatter'}
                        onChange={event => onFormatterChange(event, 'type')}
                    >
                        <MenuItem value="no-formatter">
                            <LocaleMessage msg="page.survey_steps.form.label.formatter.no-formatter" />
                        </MenuItem>
                        {formatters.map(v => {
                            return (
                                <MenuItem value={v.id} key={v.id}>
                                    {v.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

FormatterSetup.defaultProps = {
    formatters: [],
};

FormatterSetup.propTypes = {
    formatters: PropTypes.array,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
