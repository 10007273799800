import { enGB, enUS, ptBR } from 'date-fns/locale';

const locales = { en_GB: enGB, en_US: enUS, pt_BR: ptBR };

export default function GetMaterialLocale(settings) {
    const loc = settings ? settings.locale : null;
    const mat_loc = locales[loc ? loc.code : 'en_US'];

    return mat_loc;
}
