import React from 'react';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

const ChartCard = ({ title, value, children, desc, icon, onClick }) => {
    return (
        <Card
            className="jr-card jr-card-widget"
            style={{
                height: '100%',
            }}
            onClick={onClick}
        >
            <div className="jr-actchart px-3 pt-3">
                <div
                    className="d-flex mb-2"
                    style={{ justifyContent: 'center' }}
                >
                    <h1 className="mr-2 mb-0 jr-font-weight-bold">{title}</h1>
                </div>
                <div className="d-flex">
                    <h1 className="mr-2 mb-0 jr-fs-xxl jr-font-weight-medium">
                        {value}
                    </h1>
                    <i className={`zmdi zmdi-${icon} zmdi-hc-fw zmdi-hc-2x`} />
                </div>
                <p className="mb-0 jr-fs-11 text-grey">{desc}</p>
            </div>
            {children}
        </Card>
    );
};

export default ChartCard;

ChartCard.defaultProps = {
    onClick: () => {},
};

ChartCard.propTypes = {
    onClick: PropTypes.func,
};
