/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import reactCSS from 'reactcss';

import PropTypes from 'prop-types';

export default function ColorInput(props) {
    const { title, color, handleColorClick, margin } = props;
    const styles = reactCSS({
        default: {
            input: {
                display: 'flex',
                alignItems: 'center',
            },
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: color || '#fff',
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                marginRight: '10px',
            },
        },
    });

    return (
        <div style={styles.input} className={margin}>
            <div style={styles.swatch} onClick={handleColorClick}>
                <div style={styles.color} />
            </div>
            {title}
        </div>
    );
}

ColorInput.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    color: PropTypes.string.isRequired,
    handleColorClick: PropTypes.func.isRequired,
    margin: PropTypes.string,
};

ColorInput.defaultProps = {
    margin: 'mb-5',
};
