import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1.5vh 1vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
            color: #fff;
            font-size: 1.35vh !important;
        }

        .mid-value {
            font-size: 2.25vh;
            span {
                margin-top: 0.5vh;
            }
        }

        .big-value {
            font-size: 2.75vh;
            span {
                margin-top: 0.5vh;
            }
        }
    }
`;

export const ChatDialog = styled.div`
    .chat-main-header {
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        padding: 16px 16px 16px 32px;

        .chat-main-header-info {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .chat-main-scroll {
        background-color: #000;
    }

    .chat-main-content {
        padding-top: 30px;
    }

    .chat-item .bubble {
        background-color: ${props => props.theme.palette.primary.main};
        padding: 12px;

        .text-muted {
            color: #ccc !important;
            font-size: smaller;
        }
    }

    .chat-main-footer {
        border-top: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        z-index: 1000;
    }

    textarea {
        border-radius: 1.5vh;
        padding: 1vh 2vh 1vh 2vh;
    }

    .MuiIconButton-label {
        color: ${props => props.theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
        padding-right: 0px;
    }

    .MuiSelect-icon {
        display: none;
    }
`;
