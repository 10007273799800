/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    Stepper,
    Step,
    StepContent,
    StepButton,
    Divider,
    TextField,
} from '@material-ui/core';

import ConversationIdentifiers from '~/components/ConversationIdentifiers';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';

import { ParameterArea } from './styles';

const center = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export default function ContactIdentifier({
    settings,
    setToast,
    handleError,
    closePage,
    language,
    languageList,
}) {
    const lng_mode = lng_labels[language];
    const { id, dialog_id, application_id, telepresence } = settings;
    const telepresence_app = telepresence.app_id || '';

    const operation = id === 'new' ? 'create' : 'update';

    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);

    const [body, setBody] = useState({});
    const [starterList, setStarterList] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    async function loadIdentifier(_id) {
        if (_id !== 'new') {
            setIsLoading(true);
            await api
                .get(`contact_identifiers/${_id}`)
                .then(async response => {
                    const { data } = response;
                    setBody({
                        ...data,
                        contact_id:
                            data.contact && data.contact.id
                                ? data.contact.id
                                : '*',
                    });
                    setStarterList(data.starters);
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
    }

    async function loadAppContacts(_id) {
        setIsLoading(true);
        await api
            .get(`contacts/application/${_id}`)
            .then(response => {
                const data = response.data.map(c => ({
                    id: c.id,
                    name: c.name,
                }));
                setContacts(data);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadIdentifier(id);
    }, [id]);

    useEffect(() => {
        if (telepresence_app) {
            loadAppContacts(telepresence_app);
        }
    }, [telepresence_app]);

    async function handleSubmit() {
        const data = {
            ...body,
            application_id,
            dialog_id,
            destination_app: telepresence_app,
            starters: starterList,
        };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`contact_identifiers/`, data)
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.contact_identifier.created" />
                        ),
                    });
                })
                .catch(error => handleError(error));
        } else {
            await api
                .put(`contact_identifiers/${id}`, data)
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.contact_identifier.updated" />
                        ),
                    });
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
        closePage();
    }

    const handleStep = step => () => {
        setActiveStep(step);
    };

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function onStarterChange(event, lang) {
        setStarterList({ ...starterList, [lang]: event.target.value });
    }

    function renderHeader() {
        return (
            <div
                className="col-12 row mt-3 mb-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => closePage()}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => handleSubmit()}
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    function renderStartMessage(lang) {
        return (
            <div className="col-12 row mb-3" key={`starter_${lang}`}>
                <TextField
                    id={`${lang}_starter`}
                    label={<LocaleMessage msg={`list.languages.${lang}`} />}
                    fullWidth
                    value={
                        starterList && starterList[lang]
                            ? starterList[lang]
                            : ''
                    }
                    onChange={event => onStarterChange(event, lang)}
                />
            </div>
        );
    }

    function renderContactForm() {
        const contactList = [
            {
                id: '*',
                name:
                    lng_mode['list.telepresence.request.mode.broadcast'] || '*',
            },
            ...contacts,
        ];

        return (
            <div
                className="row col-12"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-5 col-12">
                    <FormSelect
                        classes="mb-5"
                        options={contactList || []}
                        labelKey="name"
                        label={
                            <LocaleMessage msg="page.applications.conversation.contact_identifier.label.contact" />
                        }
                        onChange={value =>
                            setBody({
                                ...body,
                                contact_id: value,
                            })
                        }
                        value={body.contact_id ? body.contact_id : ''}
                    />
                </div>
            </div>
        );
    }

    function renderIdentifiers() {
        return (
            <>
                <div
                    className="row mt-3"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ConversationIdentifiers
                        size="col-md-10 col-12"
                        dialog_id={dialog_id}
                        identifiers={body.identifiers || []}
                        setIdentifiers={i =>
                            setBody({ ...body, identifiers: i })
                        }
                    />
                </div>
            </>
        );
    }

    function renderStarters() {
        return (
            <div style={center}>
                <h5>
                    <LocaleMessage msg="page.applications.conversation.point_identifier.label.start" />
                </h5>
                <ParameterArea className="col-md-10 col-12 row mt-1 mb-5 p-4">
                    {languageList.map(l => {
                        return renderStartMessage(l);
                    })}
                </ParameterArea>
            </div>
        );
    }

    const steps = [
        {
            key: 'contact',
            label: (
                <LocaleMessage msg="page.applications.conversation.contact_identifier.label.sel_contact" />
            ),
            content: renderContactForm(),
        },
        {
            key: 'identifier',
            label: (
                <LocaleMessage msg="page.applications.conversation.contact_identifier.label.sel_identifier" />
            ),
            content: renderIdentifiers(),
        },
        {
            key: 'starters',
            label: (
                <LocaleMessage msg="page.applications.conversation.point_identifier.label.sel_starter" />
            ),
            content: renderStarters(),
        },
    ];

    return (
        <>
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <Divider />
                    {renderHeader()}
                    <Divider />
                    <form
                        className="row full-body mt-5"
                        noValidate
                        autoComplete="off"
                    >
                        <div style={{ flex: '1' }}>
                            <Stepper
                                orientation="vertical"
                                nonLinear
                                activeStep={activeStep}
                            >
                                {steps.map((s, index) => (
                                    <Step key={s.key}>
                                        <StepButton onClick={handleStep(index)}>
                                            {s.label}
                                        </StepButton>
                                        <StepContent>
                                            {s.content}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={() => handleBack()}
                                                >
                                                    <LocaleMessage msg="button.back" />
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                        activeStep ===
                                                        steps.length - 1
                                                            ? () =>
                                                                  handleSubmit()
                                                            : () => handleNext()
                                                    }
                                                >
                                                    {activeStep ===
                                                    steps.length - 1 ? (
                                                        <LocaleMessage msg="button.save" />
                                                    ) : (
                                                        <LocaleMessage msg="button.next" />
                                                    )}
                                                </Button>
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}

ContactIdentifier.defaultProps = {
    language: 'en_US',
    languageList: [],
};

ContactIdentifier.propTypes = {
    languageList: PropTypes.array,
    settings: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    closePage: PropTypes.func.isRequired,
    language: PropTypes.string,
};
