/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Switch,
    TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import ColorInput from '~/components/ColorInput';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

const styles = reactCSS({
    default: {
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    },
});

const color_list = [
    {
        value: 'app_main',
        label: (
            <LocaleMessage msg="page.applications.conversation.colors.app_main" />
        ),
    },
];

const robot_settings = {
    cruzr: ['color', 'recognition', 'hardware', 'context_timeout', 'kill_tts'],
    nao: ['color', 'context_timeout', 'kill_tts'],
    temi: ['color', 'recognition', 'hardware', 'context_timeout', 'kill_tts'],
};

const DEF_CONTEXT_TIMEOUT = 15;

export default function HardwareConfig({
    handleError,
    appSettings,
    updateObject,
    robotTypes,
    currType,
    setCurrType,
}) {
    const { settings } = appSettings;

    const theme = GetPluginspaceTheme(useTheme());

    const [isLoading, setIsLoading] = useState(false);
    const [, setNeedSaving] = useState(false);
    const [availableSettings, setAvailableSettings] = useState([]);
    const [colorPicker, setColorPicker] = useState({
        visible: false,
        selector: 'app_main',
    });

    const [hardware, setHardware] = useState(
        settings && settings.hardware ? settings.hardware : {}
    );

    const [colors, setColors] = useState(
        settings && settings.colors ? settings.colors : {}
    );

    async function loadSettings(type) {
        if (type) {
            await api
                .get(`types/${type.id}`)
                .then(response => {
                    const type_settings = response.data.settings;
                    const type_hardware = type_settings.hardware || {};
                    const available_hardware = Object.keys(type_hardware).map(
                        idx => {
                            const sensor = type_hardware[idx];
                            return {
                                value: sensor.value,
                                label_code: sensor.label,
                            };
                        }
                    );
                    setAvailableSettings(available_hardware);
                })
                .catch(e => {
                    handleError(e);
                });
        } else {
            setAvailableSettings([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadSettings(currType);
    }, [currType]);

    const curr_type = currType ? currType.slug : '';
    const type_options = robot_settings[curr_type] || [];
    const type_hardware = hardware[curr_type] || {};
    const type_color = colors[curr_type] || {};

    async function handleSubmit() {
        const data = { hardware, colors };
        updateObject(data);
        setNeedSaving(false);
    }

    function handleCurrTypeEdit(type, key, value) {
        const type_settings = hardware[type] || {};
        type_settings[key] = value;
        setHardware({ ...hardware, [type]: type_settings });
        setNeedSaving(true);
    }

    function handleColorClose() {
        setColorPicker({ ...colorPicker, visible: false });
    }

    function handleColorClick(selector) {
        setColorPicker({
            selector,
            visible: !colorPicker.visible,
        });
    }

    function handleColorChange(selector, c) {
        const type_settings = colors[curr_type] || {};
        setColors({
            ...colors,
            [curr_type]: {
                ...type_settings,
                [selector]: c.hex,
            },
        });
        setNeedSaving(true);
    }

    function renderColors() {
        const def_color = theme && theme.primary ? theme.primary : '#fff';
        const warnings = {
            cruzr: [],
            temi: [],
        };
        const r_warnings = warnings[curr_type] || [];

        return (
            <>
                <div className="col-md-5 col-12">
                    {r_warnings.map(w => {
                        return (
                            <p key={w}>
                                <small>
                                    <LocaleMessage msg={w} />
                                </small>
                            </p>
                        );
                    })}
                    {color_list.map(c => (
                        <div key={c.value} className="mb-5">
                            <ColorInput
                                className="mb-5"
                                title={
                                    <>
                                        {c.label}
                                        {r_warnings.length > 0 ? ' *' : ''}
                                    </>
                                }
                                color={type_color[c.value] || def_color}
                                handleColorClick={() =>
                                    handleColorClick(c.value)
                                }
                            />
                        </div>
                    ))}
                </div>
                {colorPicker.visible ? (
                    <div style={styles.popover}>
                        <div
                            style={styles.cover}
                            onClick={() => handleColorClose()}
                        />
                        <SketchPicker
                            style={styles.cover}
                            disableAlpha
                            color={
                                type_color && type_color[colorPicker.selector]
                                    ? type_color[colorPicker.selector]
                                    : def_color
                            }
                            onChange={color =>
                                handleColorChange(colorPicker.selector, color)
                            }
                            onChangeComplete={color => {
                                handleColorChange(colorPicker.selector, color);
                            }}
                            presetColors={
                                theme
                                    ? [
                                          theme.primary,
                                          theme.secondary,
                                          ...lists.default_colors,
                                      ]
                                    : null
                            }
                        />
                    </div>
                ) : null}
            </>
        );
    }

    function renderRecognitionTries() {
        return (
            <div
                className="col-md-5 col-12 mb-5"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <TextField
                    label={
                        <LocaleMessage msg="page.applications.conversation.hardware.speech_recognition_tries" />
                    }
                    fullWidth
                    type="number"
                    inputProps={{
                        min: '1',
                        step: '1',
                        style: { textAlign: 'center' },
                    }}
                    value={
                        type_hardware && type_hardware.voice_tries
                            ? type_hardware.voice_tries
                            : 2
                    }
                    onChange={event =>
                        handleCurrTypeEdit(
                            curr_type,
                            'voice_tries',
                            event.target.value
                        )
                    }
                />
            </div>
        );
    }

    function renderContextTimeout() {
        return (
            <div
                className="col-md-5 col-12 mb-5"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <TextField
                    label={
                        <LocaleMessage msg="page.applications.conversation.hardware.context_timeout" />
                    }
                    fullWidth
                    value={
                        type_hardware && type_hardware.context_timeout
                            ? type_hardware.context_timeout
                            : DEF_CONTEXT_TIMEOUT
                    }
                    onChange={event =>
                        handleCurrTypeEdit(
                            curr_type,
                            'context_timeout',
                            event.target.value
                        )
                    }
                    type="number"
                    inputProps={{
                        min: '0',
                        step: '5',
                        style: { textAlign: 'center' },
                    }}
                />
            </div>
        );
    }

    function renderKillTTS() {
        return (
            <div className="col-md-5 col-12 mb-5">
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={
                                        type_hardware &&
                                        type_hardware.end_speaking_touch
                                            ? type_hardware.end_speaking_touch
                                            : false
                                    }
                                    onChange={event =>
                                        handleCurrTypeEdit(
                                            curr_type,
                                            'end_speaking_touch',
                                            event.target.checked
                                        )
                                    }
                                />
                            }
                            label={
                                <LocaleMessage msg="page.applications.conversation.hardware.end_speaking_touch" />
                            }
                        />
                    </FormGroup>
                </FormControl>
            </div>
        );
    }

    function renderHardwareSetup() {
        return (
            <div className="col-md-5 col-12 mb-5">
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        <LocaleMessage msg="page.applications.conversation.hardware.use" />
                    </FormLabel>
                    <FormGroup>
                        {availableSettings.map(s => (
                            <FormControlLabel
                                key={s.value}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={
                                            type_hardware[s.value]
                                                ? type_hardware[s.value]
                                                : false
                                        }
                                        value={s.value}
                                        onChange={event =>
                                            handleCurrTypeEdit(
                                                curr_type,
                                                s.value,
                                                event.target.checked
                                            )
                                        }
                                    />
                                }
                                label={<LocaleMessage msg={s.label_code} />}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            <div className="sidecard-header mb-3">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.hardware.title" />
                </h2>
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <div
                        className="col-12"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <AppConfigRobotType
                            className="col-12 mb-5"
                            robotTypes={robotTypes}
                            currType={currType}
                            setCurrType={t => setCurrType(t)}
                        >
                            <div
                                className="row"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {type_options.includes('color')
                                    ? renderColors()
                                    : null}

                                {type_options.includes('recognition')
                                    ? renderRecognitionTries()
                                    : null}

                                {type_options.includes('hardware')
                                    ? renderHardwareSetup()
                                    : null}

                                {type_options.includes('context_timeout')
                                    ? renderContextTimeout()
                                    : null}

                                {type_options.includes('kill_tts')
                                    ? renderKillTTS()
                                    : null}
                            </div>
                        </AppConfigRobotType>

                        <div className="col-md-6 col-12 mb-5">
                            <Button
                                className="p-3"
                                variant="contained"
                                color="primary"
                                onClick={event => handleSubmit(event)}
                                fullWidth
                                size="large"
                                // disabled={!needSaving}
                            >
                                <LocaleMessage msg="button.save" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

HardwareConfig.defaultProps = {
    currType: null,
};

HardwareConfig.propTypes = {
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object,
    setCurrType: PropTypes.func.isRequired,
};
