/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdLaunch } from 'react-icons/md';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';

import { TotalContainer, InfoContainer, TableArea } from './style';

const report_links = {
    MAPNAVIGATION: '/reports/navigation_triggers',
    OPENFORM: '/reports/survey_triggers',
    RUNSCRIPT: '/reports/script_triggers',
    RUNDANCE: '/reports/dance_triggers',
};

export default function ActionReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawActionLogData, setRawActionLogData] = useState({});
    const [actionLogList, setActionLogList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    // const [currAction, setCurrAction] = useState(null);
    // const [filteredActionList, setFilteredActionList] = useState([]);

    const headers_file = [
        { key: 'group', label: 'Group' },
        { key: 'dialog', label: 'Dialog' },
        { key: 'robot', label: 'Robot' },
        { key: 'robottype', label: 'Robot Type' },
        {
            key: 'language',
            label: 'Language',
        },
        { key: 'plugin', label: 'Plugin' },
        { key: 'action', label: 'Action' },
        { key: 'pluginconfig', label: 'Integration' },
        { key: 'key_value', label: 'Value' },
        {
            key: 'created_date',
            label: 'Date',
        },
        {
            key: 'created_time',
            label: 'Time',
        },
    ];

    const headers_actions = [
        {
            id: 'value',
            label: <LocaleMessage msg="table.headers.quantity" />,
            width: '20%',
        },
        {
            id: 'action',
            label: <LocaleMessage msg="table.headers.action" />,
        },
        {
            id: 'plugin',
            label: <LocaleMessage msg="table.headers.plugin" />,
        },
        {
            id: 'has_link',
            width: '10%',
        },
    ];

    function filterRawActionData(data) {
        const {
            app_types,
            robot_types,
            robots,
            groups,
            dialogs,
            applications,
            plugin_types,
            pluginconfigs,
            plugintemplates,
            actions,
        } = counterData;
        const { period, action_logs } = data;

        const a_actions = [];
        const a_robots = [];
        const a_a_types = [];
        const a_r_types = [];
        const a_p_types = [];
        const a_groups = [];
        const a_dialogs = [];
        const a_applications = [];
        const a_configs = [];
        const a_templates = [];
        const a_date = [];
        const a_time = [];

        const alist = action_logs.list.map((a, idx) => {
            const datetime = GetDateTimeLabel(new Date(a.created), { format });

            const group = groups[a.group_id];
            const robot = robots[a.robot_id];
            const r_type = robot_types[a.robottype_id];
            const application = applications[a.application_id];
            const a_type = app_types[a.apptype_id];
            const dialog = dialogs ? dialogs[a.dialog_id] : {};
            const p_type = plugin_types ? plugin_types[a.plugintype_id] : {};
            const action = actions ? actions[a.action_id] : {};
            const pluginconfig = pluginconfigs
                ? pluginconfigs[a.pluginconfig_id]
                : {};
            const plugintemplate = plugintemplates
                ? plugintemplates[a.plugintemplate_id]
                : {};
            const action_name = action.label ? action.label.toUpperCase() : '';

            if (!robots[a.robot_id] || robots[a.robot_id].code === '---') {
                robots[a.robot_id] = { code: a.robot_code || '---', name: '' };
            }

            a_groups[a.group_id] = a_groups[a.group_id]
                ? a_groups[a.group_id] + 1
                : 1;

            a_robots[a.robot_id] = a_robots[a.robot_id]
                ? a_robots[a.robot_id] + 1
                : 1;

            a_r_types[a.robottype_id] = a_r_types[a.robottype_id]
                ? a_r_types[a.robottype_id] + 1
                : 1;

            a_applications[a.application_id] = a_applications[a.application_id]
                ? a_applications[a.application_id] + 1
                : 1;

            a_a_types[a.apptype_id] = a_a_types[a.apptype_id]
                ? a_a_types[a.apptype_id] + 1
                : 1;

            a_p_types[a.plugintype_id] = a_p_types[a.plugintype_id]
                ? a_p_types[a.plugintype_id] + 1
                : 1;

            a_actions[a.action_id] = a_actions[a.action_id]
                ? {
                      ...a_actions[a.action_id],
                      value: a_actions[a.action_id].value + 1,
                  }
                : {
                      id: a.action_id,
                      dialog: dialog ? dialog.name : '---',
                      dialog_id: dialog ? dialog.id : '---',
                      action: <LocaleMessage msg={action.name} />,
                      name: action_name,
                      plugin: p_type ? p_type.name : '---',
                      link: report_links[action_name],
                      has_link: report_links[action_name] ? (
                          <MdLaunch size={20} />
                      ) : null,
                      value: 1,
                  };

            if (a.dialog_id) {
                a_dialogs[a.dialog_id] = a_dialogs[a.dialog_id]
                    ? a_dialogs[a.dialog_id] + 1
                    : 1;
            } else {
                a_dialogs['---'] = a_dialogs['---'] ? a_dialogs['---'] + 1 : 1;
            }

            if (a.pluginconfig_id) {
                a_configs[a.pluginconfig_id] = a_configs[a.pluginconfig_id]
                    ? a_configs[a.pluginconfig_id] + 1
                    : 1;
            } else {
                a_configs['---'] = a_configs['---'] ? a_configs['---'] + 1 : 1;
            }

            if (a.plugintemplate_id) {
                a_templates[a.plugintemplate_id] = a_templates[
                    a.plugintemplate_id
                ]
                    ? a_templates[a.plugintemplate_id] + 1
                    : 1;
            } else {
                a_templates['---'] = a_templates['---']
                    ? a_templates['---'] + 1
                    : 1;
            }

            a_date[datetime.date] = a_date[datetime.date]
                ? a_date[datetime.date] + 1
                : 1;
            a_time[datetime.hour] = a_time[datetime.hour]
                ? a_time[datetime.hour] + 1
                : 1;

            const { content } = a;

            const template_name =
                plugintemplate && plugintemplate.name
                    ? plugintemplate.name
                    : '---';
            const template_key = template_name.split('.').reverse()[0];

            return {
                ...a,
                id: idx,
                group: group ? group.name : '---',
                dialog: dialog ? dialog.name : '---',
                application: application ? application.name : '---',
                robot: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${a.robot_code || '---'}]`,
                robottype: r_type ? r_type.name : '---',
                apptype: a_type ? a_type.slug : '---',
                plugin: p_type ? p_type.name : '---',
                action: action_name || '---',
                pluginconfig: pluginconfig ? pluginconfig.name : '---',
                plugintemplate: template_name,
                plugintemplate_key: template_key,
                key_value:
                    content && content.key_value ? content.key_value : '---',
                created_date: datetime.date,
                created_time: datetime.time,
                created: datetime.string,
                timestamp: datetime.timestamp,
            };
        });

        const robot_grouped = Object.keys(a_robots).map(r => {
            const val = a_robots[r];
            const obj = robots[r];

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                value: val,
            };
        });

        const rtype_grouped = Object.keys(a_r_types).map(t => {
            const val = a_r_types[t];
            const obj = robot_types[t];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const application_grouped = Object.keys(a_applications).map(a => {
            const val = a_applications[a];
            const obj = applications[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const atype_grouped = Object.keys(a_a_types).map(t => {
            const val = a_a_types[t];
            const obj = app_types[t];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const group_grouped = Object.keys(a_groups).map(g => {
            const val = a_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const dialog_grouped = Object.keys(a_dialogs).map(d => {
            const val = a_dialogs[d];
            const obj = dialogs && dialogs[d] ? dialogs[d] : {};

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const plugin_grouped = Object.keys(a_p_types).map(p => {
            const val = a_p_types[p];
            const obj = plugin_types && plugin_types[p] ? plugin_types[p] : {};

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const action_grouped = Object.keys(a_actions).map(a => {
            return a_actions[a] || {};
        });

        const config_grouped = Object.keys(a_configs).map(c => {
            const val = a_configs[c];
            const obj =
                pluginconfigs && pluginconfigs[c] ? pluginconfigs[c] : {};

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const template_grouped = Object.keys(a_templates).map(t => {
            const val = a_templates[t];
            const obj =
                plugintemplates && plugintemplates[t] ? plugintemplates[t] : {};

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            d_labels.push(label_date);
            date_grouped.push({
                date: label_date,
                value: a_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = t < 10 ? `0${t}h` : `${t}h`;
            t_labels.push(label_time);
            time_grouped.push({
                hour: label_time,
                value: a_time[t] || 0,
            });
        }

        setGroupedData({
            actions: action_grouped,
            robots: robot_grouped,
            robot_types: rtype_grouped,
            applications: application_grouped,
            app_types: atype_grouped,
            plugin_types: plugin_grouped,
            groups: group_grouped,
            dialogs: dialog_grouped,
            pluginconfigs: config_grouped,
            plugintemplates: template_grouped,
            date: date_grouped,
            time: time_grouped,
            d_labels,
            t_labels,
        });

        setActionLogList(alist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        await api
            .get(
                `reports/pluginlogs?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                filterRawActionData(data);
                setRawActionLogData(data.action_logs);
                // setCurrAction(null);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        if (mountedRef.current) {
            loadData();
        }
    }, [settings, fromISO, untilISO, locale]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function handleActionSelection(action_id) {
        const action_list = groupedData.actions || [];

        const newCurrAction = action_list.find(a => {
            return a.id === action_id;
        });

        const action_name = newCurrAction.name;

        const report_link = report_links[action_name];

        if (report_link) {
            history.push(`${report_link}#from=${fromISO}&until=${untilISO}`);
        }

        // setCurrAction(newCurrAction);

        // const currActionLog = actionLogList.filter(a => {
        //     return a.action_id === action_id;
        // });

        // setFilteredActionList(currActionLog);
    }

    function CSVButton() {
        return (
            <ExportCSVButton
                data={actionLogList}
                headers={headers_file}
                filename={GetFileName(`REPORT-conversation_actions`, 'csv')}
            />
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    // function buildPieChart(title, color, data) {
    //     return (
    //         <>
    //             <div>
    //                 <p className="card-title">{title}</p>
    //             </div>
    //             {data && data.length > 0 ? (
    //                 <ResponsiveContainer
    //                     className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-1"
    //                     width="100%"
    //                     height={200}
    //                 >
    //                     <PieChart>
    //                         <Pie
    //                             dataKey="value"
    //                             nameKey="name"
    //                             data={data || []}
    //                             innerRadius={40}
    //                             outerRadius={70}
    //                             fill={`${hexToRgba(color, 0.5)}`}
    //                             label
    //                             labelLine
    //                         />
    //                         <Tooltip />
    //                     </PieChart>
    //                 </ResponsiveContainer>
    //             ) : (
    //                 <NullData>
    //                     <p>
    //                         <LocaleMessage msg="label.no_data" />
    //                     </p>
    //                 </NullData>
    //             )}
    //         </>
    //     );
    // }

    // function buildActionTable(title, headers, data, order_by = 'value') {
    //     return (
    //         <>
    //             <div>
    //                 <p className="card-title">{title}</p>
    //             </div>
    //             <DataTable
    //                 headerColumns={headers}
    //                 data={data || []}
    //                 orderDirection="desc"
    //                 orderColumn={order_by}
    //                 handleTableRowClick={() => {}}
    //                 hasActions={false}
    //                 sortable
    //                 defaultRowsPerPage={10}
    //             />
    //         </>
    //     );
    // }

    // function renderActionDetails() {
    //     const Page = currAction ? pages[currAction.name] : null;

    //     return (
    //         <>
    //             {Page ? (
    //                 <Page
    //                     settings={settings}
    //                     data={filteredActionList}
    //                     active={active}
    //                     colors={colors}
    //                     buildPieChart={buildPieChart}
    //                     buildActionTable={buildActionTable}
    //                 />
    //             ) : null}
    //         </>
    //     );
    // }

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'plugin_types',
            title: 'per_plugin_type',
            color: colors.dashboard_graphs,
            data: groupedData.plugin_types,
        },
        {
            key: 'actions',
            title: 'per_action_type',
            color: colors.dashboard_cards,
            data: groupedData.actions,
        },
        {
            key: 'integration',
            title: 'per_integration',
            color: colors.dashboard_graphs,
            data: groupedData.pluginconfigs,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: groupedData.applications,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            color: colors.dashboard_graphs,
            data: groupedData.robot_types,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
        {
            key: 'dialogs',
            title: 'per_dialog',
            color: colors.dashboard_graphs,
            data: groupedData.dialogs,
        },
    ];

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={CSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.actions.label.triggered" />
                                }
                                value={rawActionLogData.total || '0'}
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{}}
                                    />
                                );
                            })}
                        </InfoContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.label.per_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.label.per_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <TableArea className="col-md-8 col-12 mb-3 table-responsive-material">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.actions.label.top_actions" />
                                    </p>
                                </div>
                                <DataTable
                                    maxHeight="100%"
                                    headerColumns={headers_actions}
                                    data={groupedData.actions || []}
                                    orderDirection="desc"
                                    orderColumn="value"
                                    hasActions={false}
                                    handleTableRowClick={(event, id) => {
                                        handleActionSelection(id);
                                    }}
                                    defaultRowsPerPage={10}
                                    sortable
                                />
                            </TableArea>
                        </InfoContainer>
                        {/* {currAction ? (
                            <>
                                <Divider />
                                {renderActionDetails()}
                            </>
                        ) : null} */}
                    </>
                )}
            </div>
        </>
    );
}

ActionReports.defaultProps = {
    options: {},
};

ActionReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    options: PropTypes.object,
};
