import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px 20px 0px 20px;

    .breadcrumb {
        background: none !important;
        padding: 20px 0px;
        margin-bottom: 0px;

        a {
            color: ${props =>
                props.theme.palette.pluginspace.primary} !important;
        }
    }

    h1 {
        font-weight: bold;
    }
`;
