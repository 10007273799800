/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';

import LocaleMessage from '~/components/LocaleMessage';

import { ChartCard, NullData } from './style';

const RADIAN = Math.PI / 180;

export default function ReportPieChart({
    classes,
    title,
    color,
    data,
    dataKey,
    customization = {},
}) {
    const CustomPieLabel = d => {
        const { cx, cy, midAngle, fill, outerRadius, name } = d;
        const radius = outerRadius + 20;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const ellipsed =
            name && name.length > 10 ? `${name.substring(0, 9)}...` : name;

        return (
            <text
                x={x}
                y={y}
                fill={fill}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {ellipsed}
            </text>
        );
    };

    const { height, tooltip, innerRadius, outerRadius } = customization;
    return (
        <ChartCard className={classes}>
            <div>
                <p className="card-title">{title}</p>
            </div>
            {data && data.length > 0 ? (
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-1"
                    width="100%"
                    height={height || 200}
                >
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey={dataKey}
                            nameKey="name"
                            innerRadius={innerRadius || 45}
                            outerRadius={outerRadius || 70}
                            fill={`${hexToRgba(color, 0.5)}`}
                            label={CustomPieLabel}
                            labelLine
                        />
                        <Tooltip content={tooltip} />
                    </PieChart>
                </ResponsiveContainer>
            ) : (
                <NullData>
                    <p>
                        <LocaleMessage msg="label.no_data" />
                    </p>
                </NullData>
            )}
        </ChartCard>
    );
}

ReportPieChart.defaultProps = {
    data: [],
    classes: '',
    title: null,
    color: '',
    dataKey: 'value',
    customization: {},
};

ReportPieChart.propTypes = {
    data: PropTypes.array,
    classes: PropTypes.string,
    title: PropTypes.element,
    color: PropTypes.string,
    dataKey: PropTypes.string,
    customization: PropTypes.object,
};
