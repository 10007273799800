/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function CardSideBordered(props) {
    const { title, children, Icon, hide } = props;

    return (
        <Container>
            {window.innerWidth > 1024 && !hide ? (
                <div className="card-side-color">
                    <Icon size={30} />
                </div>
            ) : null}
            <div className="sidecard-body">
                <div className="sidecard-header">
                    {window.innerWidth <= 1024 || hide ? (
                        <Icon size={24} />
                    ) : null}
                    <h2>{title}</h2>
                </div>
                <div className="sidecard-body">{children}</div>
            </div>
        </Container>
    );
}

CardSideBordered.defaultProps = {
    hide: false,
};

CardSideBordered.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    Icon: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    hide: PropTypes.bool,
};
