import { shade } from 'polished';
import styled from 'styled-components';

export const InfoCard = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .desc-full {
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 1vh 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.35vh !important;
        }

        .subtitle {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.15vh !important;
        }

        .value {
            font-size: 1.5vh;
            font-weight: bold;

            span {
                margin-top: 10px;
            }
        }
    }
`;
