/* eslint-disable no-plusplus */
import GetOperationTime from '~/util/GetOperationTime';

export default function filter(data) {
    const a_groups = {};
    const a_robots = {};
    const a_applications = {};
    const a_users = {};

    let duration_sum = 0;
    let has_time = 0;

    const list = data.map(c => {
        const c_time = c.time && c.time > 0 ? c.time : 0;
        if (c_time) {
            has_time++;
        }
        duration_sum += c_time;

        const group_id = c.group_id || '---';
        a_groups[group_id] = a_groups[group_id]
            ? {
                  ...a_groups[group_id],
                  total: a_groups[group_id].total + 1,
                  time: a_groups[group_id].time + c_time,
              }
            : {
                  id: group_id,
                  name: c.group_name,
                  total: 1,
                  time: c_time,
              };

        const robot_id = c.robot_id || '---';
        a_robots[robot_id] = a_robots[robot_id]
            ? {
                  ...a_robots[robot_id],
                  total: a_robots[robot_id].total + 1,
                  time: a_robots[robot_id].time + c_time,
              }
            : {
                  id: a_robots,
                  name: c.robot_name,
                  total: 1,
                  time: c_time,
              };

        const application_id = c.application_id || '---';
        a_applications[application_id] = a_applications[application_id]
            ? {
                  ...a_applications[application_id],
                  total: a_applications[application_id].total + 1,
                  time: a_applications[application_id].time + c_time,
              }
            : {
                  id: application_id,
                  name: c.application_name,
                  total: 1,
                  time: c_time,
              };

        const access = c.access || {};
        const { user } = access;

        const u_id = user ? user.id : null;
        const u_name = user ? user.name : '';

        if (!a_users[u_id]) {
            a_users[u_id] = {
                id: `access_${u_id}`,
                name: u_name,
                total: 0,
                time: 0,
            };
        }
        a_users[u_id] = {
            ...a_users[u_id],
            total: a_users[u_id].total + 1,
            time: a_users[u_id].time + c_time,
        };

        return {
            ...c,
            user_id: u_id,
            user_name: u_name,
        };
    });

    const group_grouped = Object.keys(a_groups).map(g => {
        return a_groups[g];
    });

    const robot_grouped = Object.keys(a_robots).map(r => {
        return a_robots[r];
    });

    const application_grouped = Object.keys(a_applications).map(a => {
        return a_applications[a];
    });

    const user_grouped = Object.keys(a_users).map(d => {
        return a_users[d];
    });

    const mean_duration = (
        Math.round((duration_sum / has_time) * 10 || 0) / 10
    ).toFixed(1);

    const duration_time = GetOperationTime(duration_sum);
    const mean_time = GetOperationTime(mean_duration);

    const newFilteredData = {
        duration: duration_time.label,
        mean_duration: mean_time.label,
        data: list,
        groups: group_grouped,
        robots: robot_grouped,
        applications: application_grouped,
        users: user_grouped,
    };

    return newFilteredData;
}
