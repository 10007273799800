/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdCollectionsBookmark } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const form_url = `/pluginbot_qna/templates`;

export default function QnATemplateList() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const locale = getDateLocale(settings);

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [currList, setCurrList] = useState(null);
    const [deleteListOpen, setDeleteListOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    async function loadTemplateList() {
        setIsLoading(true);
        await api
            .get(`qna/admin/templates`)
            .then(response => {
                const templates = response.data.map(t => {
                    const updated = new Date(t.updated);
                    return {
                        ...t,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setList(templates);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadTemplateList();
    }, []);

    async function deleteList() {
        setIsLoading(true);
        setDeleteListOpen(false);

        await api
            .delete(`qna/admin/templates/${currList}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.qna.template.delete_success" />
                );
                loadTemplateList();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteListOpen(false);
    }

    function handleDeleteListOpen(event, _id) {
        setCurrList(_id);
        event.preventDefault();
        setDeleteListOpen(true);
    }

    function handleListRowClick(event, id) {
        event.preventDefault();
        setCurrList(id);
        history.push(`${form_url}/${id}`);
    }

    function renderDeleteListDialog() {
        return (
            <SimpleDialog
                open={deleteListOpen}
                onClose={event => handleDialogClose(event, 'delete')}
                title={<LocaleMessage msg="page.qna.template.delete.confirm" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteListOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteList(currList),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderLists() {
        const headCells = [
            {
                id: 'identifier',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'content_count',
                label: <LocaleMessage msg="page.qna.content.all" />,
            },
            {
                id: 'status',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteListOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={list}
                    orderColumn="identifier"
                    handleTableRowClick={(event, id) =>
                        handleListRowClick(event, id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div className="col-3" style={{ padding: '0px' }}>
                                <NewItemButton
                                    link={`${form_url}/new`}
                                    text={
                                        <LocaleMessage msg="page.qna.template.new" />
                                    }
                                />
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.qna.templates.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginbot_qna',
                    title: <LocaleMessage msg="page.qna.title" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {deleteListOpen ? renderDeleteListDialog() : null}
                <div className="col-12 mt-3 mb-5">
                    <CardSideBordered
                        title={<LocaleMessage msg="page.qna.templates.title" />}
                        hide
                        Icon={MdCollectionsBookmark}
                    >
                        <>
                            <div className="row full-body">{renderLists()}</div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

QnATemplateList.propTypes = {};
