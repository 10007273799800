import styled from 'styled-components';

export const ChartsContainer = styled.div`
    display: flex;
    justify-content: center;

    .period-header {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: -15px;
    }
`;

export const ChartCard = styled.div`
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-title {
        font-weight: bold;
    }
`;

export const TotalContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

    .counter-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 5px solid ${props => props.theme.palette.primary.main};

        .card-heading {
            color: ${props => props.theme.palette.primary.main};
        }

        .value {
            font-weight: bold;
            font-size: 42px;
            color: ${props => props.theme.palette.primary.main};
        }
    }
`;

export const CardContainer = styled.div`
    height: 100%;

    .card {
        margin-bottom: 0px;
    }

    .area-chart-container {
        .recharts-responsive-container {
            margin-left: -30px;
            margin-bottom: -10px;
        }
    }
`;

export const RowArea = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-title {
        font-weight: bold;
    }
`;

export const TableArea = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-title {
        font-weight: bold;
    }

    tbody {
        display: block;
        min-height: 150px;
        max-height: 250px;
        overflow-y: auto;

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        .col-sm {
            width: 15%;
            max-width: 100px;
        }
    }
`;

export const NullData = styled.div`
    height: 100%;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    padding: 30px;
    margin-bottom: 30px;
`;

export const InfoContainer = styled.div`
    display: flex;
    justify-content: center;

    .period-header {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: -15px;
    }
`;
