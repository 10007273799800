/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdCheck } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

const centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function RC3SettingsForm({
    pluginspace_id,
    requestError,
    showLoading,
}) {
    const [internalLoading, setInternalLoading] = useState(true);
    const [policy, setPolicy] = useState(null);
    const [revokeOpen, setRevokeOpen] = useState(false);

    async function loadRC3Policy() {
        setInternalLoading(true);
        if (!pluginspace_id) {
            setPolicy({ active: false });
        } else {
            await api
                .get(`rc3/pluginspaces/${pluginspace_id}`)
                .then(response => {
                    const c = response.data;

                    if (c.id) {
                        setPolicy(c);
                    } else {
                        setPolicy({ active: false });
                    }
                })
                .catch(error => requestError(error));
        }
        setInternalLoading(false);
    }

    useEffect(() => {
        loadRC3Policy();
    }, []);

    function handleDialogClose(event) {
        event.preventDefault();
        setRevokeOpen(false);
    }

    async function handleRevokeRC3() {
        setRevokeOpen(false);
        setInternalLoading(true);

        await api
            .delete(`rc3/pluginspaces/${pluginspace_id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.rc3.delete_success" />
                );
                await loadRC3Policy();
            })
            .catch(error => requestError(error));
        setInternalLoading(false);
    }

    function handleRevokeOpen(event, _id) {
        event.preventDefault();
        setRevokeOpen(true);
    }

    async function handleSetupRC3(event, op) {
        event.preventDefault();
        setInternalLoading(true);

        if (op === 'create') {
            const data = { pluginspace_id };

            await api
                .post(`rc3/pluginspaces`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.su_pluginspace.form.rc3.create_success" />
                    );
                    await loadRC3Policy();
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`rc3/pluginspaces/${pluginspace_id}`)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.su_pluginspace.form.rc3.create_success" />
                    );
                    await loadRC3Policy();
                })
                .catch(error => requestError(error));
        }
        setInternalLoading(false);
    }

    function renderRevokeDialog() {
        return (
            <SimpleDialog
                open={revokeOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.rc3.revoke_confirm" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setRevokeOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'revoke_access',
                        onClick: () => handleRevokeRC3(),
                        label: <LocaleMessage msg="button.revoke_access" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {revokeOpen ? renderRevokeDialog() : null}
            {internalLoading ? (
                showLoading()
            ) : (
                <div className="row" style={centerStyle}>
                    {policy && policy.active ? (
                        <>
                            <div
                                className="col-12 mb-5"
                                style={{
                                    ...centerStyle,
                                    flexDirection: 'column',
                                }}
                            >
                                <MdCheck size={42} style={{ margin: '10px' }} />
                                <Typography variant="body1">
                                    <LocaleMessage msg="page.su_pluginspace.form.rc3.already_set" />
                                </Typography>
                            </div>
                            <div className="col-12 mb-3 row">
                                <Button
                                    className="p-3"
                                    variant="outlined"
                                    color="primary"
                                    onClick={event => handleRevokeOpen(event)}
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.revoke_access" />
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-12 mb-3" style={centerStyle}>
                                <Typography variant="body1">
                                    <LocaleMessage msg="page.su_pluginspace.form.rc3.not_set" />
                                </Typography>
                            </div>
                            <div className="col-12 mb-3">
                                <Button
                                    className="p-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={event =>
                                        handleSetupRC3(event, 'create')
                                    }
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.set_up" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

RC3SettingsForm.propTypes = {
    pluginspace_id: PropTypes.string.isRequired,
    requestError: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
};
