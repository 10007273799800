import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
    Button,
    // Tooltip
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { Container } from './styles';

export default function NewItemCard(props) {
    const { link, text, disabled } = props;
    return (
        <Container className="col-md-3 col-sm-6 mb-5">
            <span className="card">
                {disabled ? (
                    <div className="group-warning">
                        <LocaleMessage msg="message.new_button.select_group" />
                    </div>
                ) : null}
                <Link to={link} className={`${disabled ? 'disabled' : ''}`}>
                    <Button
                        className="card-body"
                        fullWidth
                        disabled={disabled}
                        variant="contained"
                    >
                        <MdAdd size={48} />
                        {text}
                    </Button>
                </Link>
            </span>
        </Container>
    );
}

NewItemCard.defaultProps = {
    disabled: false,
};

NewItemCard.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    disabled: PropTypes.bool,
};
