/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
    MdAdd,
    MdClose,
    MdAutoAwesome,
    MdDone,
    MdDoneAll,
    MdClear,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Badge,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Divider,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { ExamplesArea } from './styles';

export default function QnAExamples({
    size,
    content,
    contentId,
    language,
    examples,
    handleError,
    updateExamples,
    allowSuggestions,
}) {
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function requestSuggestions() {
        const data = {
            id: contentId,
            identifier: content && content.identifier ? content.identifier : '',
            grouping: content && content.grouping ? content.grouping : '',
            language,
            examples,
        };

        let added_examples = 0;
        setIsLoading(true);
        await api
            .post(`/qna/generate/${contentId}/training`, data)
            .then(r => {
                const generated = r.data;
                const generated_suggestions =
                    generated && generated.suggestions
                        ? generated.suggestions
                        : [];
                const new_suggestions = [];
                generated_suggestions.forEach(s => {
                    if (!examples.includes(s)) {
                        added_examples++;
                        new_suggestions.push(s);
                    }
                });
                setSuggestions(new_suggestions);
                toast.success(
                    <>
                        <span>{`${added_examples} `}</span>
                        <LocaleMessage msg="page.qna.content.training.form.examples.suggested" />
                    </>
                );
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function addOptions(items = []) {
        items.forEach(i => {
            examples.push(i);
        });
        updateExamples([...examples]);
    }

    function removeOption(idx) {
        examples.splice(idx, 1);
        updateExamples([...examples]);
    }

    function editOption(idx, value) {
        examples[idx] = value;
        updateExamples([...examples]);
    }

    function removeSuggestion(idx) {
        suggestions.splice(idx, 1);
        setSuggestions([...suggestions]);
    }

    function editSuggestion(idx, value) {
        suggestions[idx] = value;
        setSuggestions([...suggestions]);
    }

    return (
        <ExamplesArea className="mt-3 mb-3 col-12 row">
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    {examples.map((s, i) => {
                        return (
                            <div
                                className={`${size || 'col-md-4'} col-12 mb-3`}
                                key={`example_${i}`}
                            >
                                <Badge
                                    style={{
                                        width: '100%',
                                    }}
                                    overlap="rectangular"
                                    color="primary"
                                    badgeContent={
                                        <MdClose
                                            className="remove-option"
                                            size={14}
                                            onClick={() => {
                                                removeOption(i);
                                            }}
                                        />
                                    }
                                >
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        label=""
                                        fullWidth
                                        value={s || ''}
                                        onChange={e =>
                                            editOption(i, e.target.value)
                                        }
                                    />
                                </Badge>
                            </div>
                        );
                    })}
                    <div
                        className={`${size || 'col-md-4'} col-12 mb-3 mt-0`}
                        style={{ display: 'flex' }}
                    >
                        <div className="col-md-6 px-0">
                            <Tooltip
                                title={
                                    <LocaleMessage msg="page.qna.content.training.form.examples.add" />
                                }
                                placement="bottom"
                            >
                                <Button
                                    style={{ height: '100%' }}
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => addOptions([''])}
                                >
                                    <MdAdd size={20} />
                                </Button>
                            </Tooltip>
                        </div>
                        {allowSuggestions ? (
                            <div className="col-md-6 px-0">
                                <Tooltip
                                    title={
                                        <LocaleMessage msg="page.qna.content.training.form.examples.suggest" />
                                    }
                                    placement="bottom"
                                >
                                    <Button
                                        style={{ height: '100%' }}
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        disabled={!allowSuggestions}
                                        onClick={() => requestSuggestions()}
                                    >
                                        <MdAutoAwesome size={20} />
                                    </Button>
                                </Tooltip>
                            </div>
                        ) : null}
                    </div>
                    {suggestions.length > 0 ? (
                        <>
                            <Divider />
                            <div
                                className="mt-3 mb-4 col-12"
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <LocaleMessage msg="page.qna.content.suggestions" />
                            </div>
                            {suggestions.map((s, i) => {
                                return (
                                    <div
                                        className={`${size ||
                                            'col-md-4'} col-12 mb-3`}
                                        key={`example_${i}`}
                                    >
                                        <TextField
                                            size="small"
                                            className="py-0"
                                            variant="outlined"
                                            fullWidth
                                            value={s}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton
                                                            className="p-0"
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                removeSuggestion(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            <MdClose
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className="p-0"
                                                            color="primary"
                                                            onClick={() => {
                                                                addOptions([s]);
                                                                removeSuggestion(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            <MdDone size={20} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={event =>
                                                editSuggestion(
                                                    i,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </div>
                                );
                            })}
                            <div
                                className={`${size ||
                                    'col-md-4'} col-12 mb-3 mt-0`}
                                style={{ display: 'flex' }}
                            >
                                <div className="col-md-6 px-0">
                                    <Tooltip
                                        title={
                                            <LocaleMessage msg="page.qna.content.training.form.examples.remove.all" />
                                        }
                                        placement="bottom"
                                    >
                                        <Button
                                            style={{ height: '100%' }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setSuggestions([]);
                                            }}
                                        >
                                            <MdClear size={20} />
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className="col-md-6 px-0">
                                    <Tooltip
                                        title={
                                            <LocaleMessage msg="page.qna.content.training.form.examples.add.all" />
                                        }
                                        placement="bottom"
                                    >
                                        <Button
                                            style={{ height: '100%' }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                addOptions(suggestions);
                                                setSuggestions([]);
                                            }}
                                        >
                                            <MdDoneAll size={20} />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </>
                    ) : null}
                </>
            )}
        </ExamplesArea>
    );
}

QnAExamples.propTypes = {
    content: PropTypes.object,
    size: PropTypes.string,
    examples: PropTypes.array,
    allowSuggestions: PropTypes.bool,
    handleError: PropTypes.func.isRequired,
    updateExamples: PropTypes.func.isRequired,
};

QnAExamples.defaultProps = {
    content: {},
    size: '',
    examples: [],
    allowSuggestions: false,
};
