import styled from 'styled-components';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const BorderedArea = styled.div`
    padding: 20px;
    border: 1px solid ${props => props.theme.palette.primary.main};

    .row {
        margin: 10px 0px;
    }

    .area-title {
        margin-bottom: 30px;

        h4 {
            color: ${props => props.theme.palette.primary.main};
        }
    }
`;
