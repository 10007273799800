import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px 20px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;

    .row {
        margin: 10px;
    }

    .lang-text-params {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            margin: 0px;
        }
    }
`;

export const MediaSelector = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MediaArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px;

    .img-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        aspect-ratio: 16 / 9;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
`;

export const OptionArea = styled.div`
    width: 100%;

    .area-content {
        background-color: #eee;
        width: 100%;
        padding: 10px;
        margin: 10px 0px;
    }

    .option-row {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .option-card {
        margin: 10px 0px;
        width: 100%;
    }
`;

export const OptionCard = styled.div`
    .card-body {
        min-height: 200px;
        width: 100%;
        display: flex;
        align-items: flex-top;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
    }

    .card-edit {
        min-height: 200px;
        width: 100%;
        display: flex;
        align-items: flex-top;
        justify-content: space-between;
        padding: 10px;
    }

    .card-move {
        :hover {
            cursor: move;
        }
    }

    .img-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #eee;
        border: 1px solid #ccc;
    }

    .card-image {
        width: 100%;
        min-height: 100px;
        padding: 0px;

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            border: 1px solid #ccc;
            background-color: #fff;
            aspect-ratio: 4 / 3;
        }
    }

    .card-edit {
        img {
            :hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

    .card-content {
        padding: 0px 0px 0px 10px !important;
    }
`;

export const NoImageButton = styled.div`
    background: rgba(0, 0, 0, 0.03);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    border: 1px solid #ccc;
    aspect-ratio: 4 / 3;
    cursor: pointer;

    :hover {
        opacity: 0.7;
    }
`;

export const PreviewArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;
