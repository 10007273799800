/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import PropTypes from 'prop-types';

import { Menu } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ColorInput from '~/components/ColorInput';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { ParameterArea } from '../../styles';

export default function ColorParameters({ settings, setSettings, colors }) {
    const v_colors = {};
    colors.forEach(c => {
        v_colors[c.id] =
            settings.colors && settings.colors[c.id]
                ? settings.colors[c.id]
                : c.default;
    });
    const theme = GetPluginspaceTheme(useTheme());

    const [anchorEl, setAnchorEl] = useState(null);
    const [colorSettings, setColorSettings] = useState(v_colors);
    const [colorPicker, setColorPicker] = useState({
        visible: false,
        colors: v_colors,
        selector: 'top',
    });

    function handleColorClick(event, selector) {
        setAnchorEl(event.currentTarget);

        setColorPicker({
            ...colorPicker,
            selector,
            visible: !colorPicker.visible,
        });
    }

    function handleColorClose() {
        setColorPicker({ ...colorPicker, visible: false });
    }

    function handleColorChange(c) {
        const sel_color = c.hex;
        const _colors = colorPicker.colors;
        _colors[colorPicker.selector] = sel_color;
        setColorPicker({ ...colorPicker, colors: _colors });
        setColorSettings({
            ...colorSettings,
            [colorPicker.selector]: sel_color,
        });
        setSettings('colors', {
            ...colorSettings,
            [colorPicker.selector]: sel_color,
        });
    }

    return (
        <>
            {colorPicker.visible && anchorEl ? (
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={colorPicker.visible}
                    onClose={() => handleColorClose()}
                >
                    <SketchPicker
                        color={
                            colorSettings[colorPicker.selector] ||
                            colorPicker.colors[colorPicker.selector]
                        }
                        onChange={color => handleColorChange(color)}
                        onChangeComplete={color => {
                            handleColorChange(color);
                        }}
                        disableAlpha
                        presetColors={
                            theme
                                ? [
                                      theme.primary,
                                      theme.secondary,
                                      ...lists.default_colors,
                                  ]
                                : null
                        }
                    />
                </Menu>
            ) : null}
            <ParameterArea>
                <div className="row">
                    <div className="col-12 mt-3 mb-3">
                        <LocaleMessage msg="page.applications.telepresence.form.colors" />
                    </div>
                    <div className="col-md-6 col-12">
                        {colors.map(c => (
                            <ColorInput
                                margin="mb-3"
                                key={`color_${c.id}`}
                                title={<LocaleMessage msg={c.title} />}
                                color={colorSettings[c.id] || c.default}
                                handleColorClick={event =>
                                    handleColorClick(event, c.id)
                                }
                            />
                        ))}
                    </div>
                    <div className="col-12">
                        <FormSwitch
                            value={
                                settings.dark_logo ? settings.dark_logo : false
                            }
                            onChange={event => {
                                setSettings('dark_logo', event.target.checked);
                            }}
                            label={
                                <LocaleMessage msg="page.applications.telepresence.form.colors.dark_logo" />
                            }
                        />
                    </div>
                </div>
            </ParameterArea>
        </>
    );
}

ColorParameters.defaultProps = {
    settings: {},
    colors: [],
};

ColorParameters.propTypes = {
    colors: PropTypes.array,
    settings: PropTypes.object,
    setSettings: PropTypes.func.isRequired,
};
