class Apps {
    constructor() {
        this.main_app = {
            app: 'platform',
            key: 'apps.item.platform',
            icon: 'zmdi-settings',
            home: '/',
        };
        this.apps = [
            {
                app: 'rc3',
                key: 'apps.item.rc3',
                icon: 'zmdi-satellite',
                home: '/apps/rc3',
                settings: {
                    sound: true,
                },
            },
        ];
    }

    getMainApp() {
        return this.main_app;
    }

    createAppMenu(list) {
        const appList = [];

        list.forEach(a => {
            const app = this.apps.find(p => {
                return p.app === a.slug;
            });
            if (app) {
                appList.push(app);
            }
        });

        return appList;
    }
}

export default new Apps();
