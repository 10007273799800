import styled from 'styled-components';

export const PreviewArea = styled.div`
    border: 2px solid #999;
    padding: 10px;
    width: 100%;
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #999;
    width: 100%;
    height: ${props => {
        const w = props.dimensions.width;
        const h = (4.0 / 3.0) * w;
        return `${parseInt(h, 10) || 0}px`;
    }};
    background: ${props => {
        const { colors } = props;
        const c1 = colors ? colors.top : '#000';
        const c2 = colors ? colors.bottom : '#000';
        return `linear-gradient(180deg, ${c1}, ${c2})`;
    }};
    font-family: 'Saira Semi Condensed', sans-serif !important;
    color: #eee;

    .body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .contacts {
        width: 80%;
        border-radius: 5px;
        padding: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.025, 10) || 0}px ${parseInt(
                h * 0.0125,
                10
            ) || 0}px`;
        }};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #000 !important;
        height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.9, 10) || 0}px`;
        }};
        max-height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.9, 10) || 0}px`;
        }};

        .header {
            min-height: 5%;
            max-height: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .pluginbot-logo {
                max-width: 30%;
                width: ${props => {
                    const ps_logo = props.showPSLogo;
                    return ps_logo ? '20%' : '50%';
                }};
            }
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 95%;
            max-height: 95%;
            width: 100%;
            overflow-y: hidden;
            padding: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.025, 10) || 0}px 0px`;
            }};
        }

        .exit {
            min-height: 10%;
            max-height: 10%;
        }
    }
`;

export const Contact = styled.div`
    font-family: 'Saira Semi Condensed', sans-serif !important;

    .card-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 0px;
    }

    .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        flex: 1 0 auto;
        padding: 10px 5px;
    }
`;

export const ListsBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .lists {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding: 0px 10px;

        .MuiChip-root {
            font-size: 1vh !important;
            height: 2.25vh;
        }

        .MuiChip-label {
            padding-left: 15px;
            padding-right: 15px;
            font-size: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.0175, 10) || 0}px !important`;
            }};
        }

        .MuiChip-clickable {
            color: #000 !important;
            background-color: #fff !important;
        }

        .MuiChip-outlined {
            border: 1px solid #fff;
            color: #fff !important;
            background-color: #00000000 !important;
        }
    }

    .contacts {
        width: 100%;
        min-height: ${props => {
            const main = props.hasMain;
            return main ? '70%' : '85%';
        }};
        max-height: ${props => {
            const main = props.hasMain;
            return main ? '70%' : '85%';
        }};
        overflow-y: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
    }

    .footer {
        width: 100%;
        height: ${props => {
            const main = props.hasMain;
            return main ? '20%' : '5%';
        }};
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px 10px;
    }
`;

export const ContactList = styled.div`
    width: 100%;
    height: 100%;
    font-family: 'Saira Semi Condensed', sans-serif !important;

    .MuiList-root {
        border: 1px solid #999;
        padding: 0px;
    }

    .MuiListItem-root {
        padding-left: 20px;
        padding-right: 20px;
        :not(:last-child) {
            border-bottom: 1px solid #999;
        }
    }

    .MuiListItemText-root {
        span {
            font-size: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.0225, 10) || 0}px !important`;
            }};
        }
        margin-left: ${props => {
            const w = props.dimensions.width;
            return `${parseInt(w * 0.015, 10) || 0}px`;
        }};
    }

    .MuiTypography-colorTextSecondary {
        color: #ddd;
        font-size: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.0175, 10) || 0}px !important`;
        }};
    }

    .MuiAvatar-root {
        width: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.07, 10) || 0}px`;
        }};
        height: ${props => {
            const h = props.dimensions.height;
            return `${parseInt(h * 0.07, 10) || 0}px`;
        }};
        border: 1px solid #fff;
    }

    .MuiAvatar-colorDefault {
        background-color: ${props => {
            const colors = props.colors ? props.colors : {};
            const { call_button } = colors;
            return call_button || '#000';
        }};
    }
`;
