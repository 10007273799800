const common_actions = {
    test_signal: {
        key: 'test_signal',
        label: 'rc3.robot.data.actions.test_signal',
        icon: 'MdAllInclusive',
        backgroundColor: 'primary',
        onClick: {
            function: 'sendAction',
            params: {
                action: 'test_signal',
                data: {
                    session_id: 'c5a09b7e-b27a-43d9-9461-1dfe86713237',
                    dances: { id: 'lt65fznrDrwXnpNWTXA0TilyAJdXb', name: '' },
                },
            },
        },
    },
    sync_app: {
        key: 'sync_app',
        label: 'rc3.robot.data.actions.sync_app',
        icon: 'MdSync',
        backgroundColor: 'primary',
        onClick: {
            function: 'sendAction',
            params: { action: 'sync_app' },
        },
    },
    start_localization: {
        key: 'start_localization',
        label: 'rc3.robot.data.actions.start_localization',
        backgroundColor: 'primary',
        onClick: {
            function: 'sendAction',
            params: { action: 'start_localization' },
        },
    },
    switch_modes: {
        key: 'switch_modes',
        label: 'rc3.robot.data.actions.switch_modes.presentation',
        backgroundColor: 'primary',
        onClick: {
            function: 'sendAction',
            params: {
                action: 'switch_mode',
                data: {
                    mode: 'presentation',
                },
            },
        },
    },
    // switch_modes: {
    //     key: 'switch_mode',
    //     label: 'rc3.robot.data.actions.switch_modes',
    //     backgroundColor: 'primary',
    //     onClick: {
    //         function: 'openAction',
    //         params: {
    //             label: 'rc3.robot.data.actions.switch_modes',
    //             action: 'switch_mode',
    //             options: [
    //                 {
    //                     key: 'mode',
    //                     values: [
    //                         {
    //                             value: 'conversation',
    //                             label:
    //                                 'rc3.robot.data.actions.switch_modes.conversation',
    //                         },
    //                         {
    //                             value: 'presentation',
    //                             label:
    //                                 'rc3.robot.data.actions.switch_modes.presentation',
    //                         },
    //                     ],
    //                 },
    //             ],
    //         },
    //     },
};

const telepresence_actions = {
    start_call: {
        key: 'telepresence_start_call',
        label: 'rc3.robot.data.telepresence.start_call',
        icon: 'MdCall',
        backgroundColor: 'primary',
        onClick: {
            function: 'connectCall',
            params: {},
        },
    },
    end_call: {
        key: 'telepresence_end_call',
        label: 'rc3.robot.data.telepresence.end_call',
        icon: 'MdCallEnd',
        backgroundColor: '#f00',
        onClick: {
            function: 'sendAction',
            params: { action: 'end_call' },
        },
        blacklist: {
            permission: ['viewer'],
        },
    },
};

const robot_card = { card: 'robot', width: 'col-lg-5 col-12' };

const presentation_mode_card = {
    card: 'presentation',
    width: 'col-lg-3 col-12',
    options: {
        label: 'rc3.conversation.card.presentation_mode',
        exit_mode: {
            action: 'switch_mode',
            data: {
                mode: 'conversation',
            },
        },
    },
};

const conversation_action_card = {
    card: 'conversation_action',
    width: 'col-lg-3 col-12',
    options: {
        label: 'rc3.conversation.card.conversation_action',
        onClick: {
            function: 'sendAction',
            params: {
                action: 'end_action',
            },
        },
        blacklist: {
            permission: ['viewer'],
        },
    },
};

const cards_jaci_multi = [
    [
        robot_card,
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
    ],
    [
        {
            card: 'info_grid',
            width: 'col-lg-3 col-12',
            options: {
                label: 'rc3.robot.data.card.state.air_quality',
                key: 'air_quality',
                subtitle: {
                    key: 'timestamp',
                    type: 'time',
                    label: 'rc3.robot.data.last_updated',
                },
                default_size: 'col-4',
                infos: [
                    {
                        key: 'temperature',
                        label:
                            'rc3.robot.data.card.state.air_quality.temperature',
                        scale: '° C',
                    },
                    {
                        key: 'humidity',
                        label: 'rc3.robot.data.card.state.air_quality.humidity',
                        scale: '%',
                    },
                    {
                        key: 'co2',
                        label: 'rc3.robot.data.card.state.air_quality.co2',
                    },
                    {
                        key: 'pm10',
                        label: 'rc3.robot.data.card.state.air_quality.pm10',
                    },
                    {
                        key: 'pm25',
                        label: 'rc3.robot.data.card.state.air_quality.pm25',
                    },
                    {
                        key: 'pm100',
                        label: 'rc3.robot.data.card.state.air_quality.pm100',
                    },
                    {
                        key: 'base',
                        label: 'rc3.robot.data.card.state.air_quality.base',
                    },
                    {
                        key: 'tvoc',
                        label: 'rc3.robot.data.card.state.air_quality.tvoc',
                    },
                ],
            },
        },
        {
            card: 'hardware_usage',
            width: 'col-lg-4 col-12',
            options: {
                label: 'rc3.robot.data.card.state.hardware.usage.lamps',
                key: 'total',
                style: 'bar',
                size: 'col-12',
                format: '0.00',
            },
        },
        {
            card: 'level_indicator',
            width: 'col-lg-2 col-12',
            options: {
                label: 'rc3.robot.data.card.state.levels.water',
                key: 'water',
                style: 'step',
                steps: [
                    {
                        key: 'full',
                        color: '#ff0',
                        label: 'rc3.robot.status.levels.full',
                    },
                    {
                        key: 'medium',
                        color: '#0f0',
                        label: 'rc3.robot.status.levels.medium',
                    },
                    {
                        key: 'low',
                        color: '#f00',
                        label: 'rc3.robot.status.levels.low',
                    },
                ],
            },
        },
        {
            card: 'operation',
            width: 'col-lg-3 col-12',
            options: {
                label: 'rc3.disinfection.card.disinfection_mode',
            },
        },
    ],
    [
        { card: 'battery', width: 'col-lg-3 col-6' },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
        { card: 'map', width: 'col-lg-3 col-6' },
    ],
    // [
    //     { card: 'active', width: 'col-3', options: { occupation: false } },
    //     { card: 'robot', width: 'col-5' },
    //     { card: 'operation', width: 'col-3' },
    // ],
    // [
    //     {
    //         card: 'hardware_usage',
    //         width: 'col-11',
    //         options: {
    //             key: 'total',
    //             style: 'gauge',
    //             size: 'col-4',
    //             format: '0.0',
    //             orientation: 'r2l',
    //         },
    //     },
    // ],
];

const cards_double_3 = [
    [
        robot_card,
        {
            card: 'telepresence',
            width: 'col-lg-3 col-6',
            options: {
                actions: {
                    connected: [
                        common_actions.sync_app,
                        telepresence_actions.start_call,
                    ],
                    alert: [
                        common_actions.sync_app,
                        telepresence_actions.start_call,
                    ],
                    occupied: [telepresence_actions.end_call],
                },
            },
        },
        {
            card: 'control',
            width: 'col-lg-3 col-6',
            options: {},
        },
    ],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        {
            card: 'battery',
            width: 'col-lg-3 col-6',
            options: { charging: true },
        },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

const cards_cruzr = [
    [
        robot_card,
        {
            card: 'actions',
            width: 'col-lg-3 col-8',
            options: {
                actions: {
                    connected: [
                        // common_actions.test_signal,
                        common_actions.sync_app,
                        common_actions.start_localization,
                        common_actions.switch_modes,
                    ],
                    alert: [
                        // common_actions.test_signal,
                        common_actions.sync_app,
                        common_actions.start_localization,
                        common_actions.switch_modes,
                    ],
                },
            },
        },
        {
            card: 'control',
            width: 'col-lg-2 col-4',
            options: {},
        },
        presentation_mode_card,
        conversation_action_card,
    ],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        {
            card: 'battery',
            width: 'col-lg-3 col-6',
            options: { charging: true },
        },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

const cards_temi = [
    [
        robot_card,
        {
            card: 'actions',
            width: 'col-lg-3 col-8',
            options: {
                actions: {
                    connected: [
                        common_actions.sync_app,
                        common_actions.switch_modes,
                        // {
                        //     key: 'start_following',
                        //     label: 'rc3.robot.data.actions.start_following',
                        //     icon: 'MdSync',
                        //     backgroundColor: 'primary',
                        //     onClick: {
                        //         function: 'sendAction',
                        //         params: { action: 'start_following' },
                        //     },
                        // },
                    ],
                    alert: [
                        common_actions.sync_app,
                        common_actions.switch_modes,
                    ],
                },
            },
        },
        {
            card: 'control',
            width: 'col-lg-2 col-4',
            options: {},
        },
        presentation_mode_card,
    ],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        {
            card: 'battery',
            width: 'col-lg-3 col-6',
            options: { charging: true },
        },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

const cards_pudubot = [
    [robot_card],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        { card: 'battery', width: 'col-lg-3 col-6' },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

const cards_synkar_sd02 = [
    [robot_card],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        { card: 'battery', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

const cards_default = [
    [
        { card: 'robot', width: 'col-lg-5 col-12' },
        {
            card: 'actions',
            width: 'col-lg-3 col-6',
            options: {
                actions: {
                    connected: [common_actions.sync_app],
                    alert: [common_actions.sync_app],
                },
            },
        },
    ],
    [
        { card: 'application', width: 'col-lg-3 col-6' },
        {
            card: 'battery',
            width: 'col-lg-3 col-6',
            options: { charging: true },
        },
        { card: 'wifi', width: 'col-lg-3 col-6' },
        { card: 'location', width: 'col-lg-3 col-6' },
    ],
    [
        {
            card: 'active',
            width: 'col-lg-3 col-12',
            options: { occupation: false },
        },
        { card: 'interactions', width: 'col-lg-9 col-12' },
    ],
];

export default {
    robot_cards: {
        jaci_v1: cards_jaci_multi,
        jaci_module: cards_jaci_multi,
        jaci_teleop: cards_jaci_multi,
        jaci_amr: cards_jaci_multi,
        cruzr: cards_cruzr,
        double_3: cards_double_3,
        pudubot: cards_pudubot,
        temi: cards_temi,
        synkar_sd02: cards_synkar_sd02,
        default: cards_default,
    },
};
