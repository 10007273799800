import styled from 'styled-components';

export const Title = styled.div`
    padding: 30px;

    h1 {
        text-align: center;
        color: #fff;
        font-weight: bold;
    }
`;
