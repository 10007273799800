/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import {
    TotalContainer,
    ChartsContainer,
    ChartCard,
    InfoContainer,
    TableArea,
    RowArea,
} from '../style';
import CallAccessView from './CallAccessView';
import CallInviteView from './CallInviteView';
import CallOriginalView from './CallOriginalView';
import CallRequestView from './CallRequestView';

const operation_mode_labels = {
    request: 'list.operations.mode.call_request',
    access: 'list.operations.mode.call_access',
    invite: 'list.operations.mode.call_invite',
    original: 'list.operations.mode.call_original',
};

const pages = {
    request: CallRequestView,
    access: CallAccessView,
    invite: CallInviteView,
    original: CallOriginalView,
};

export default function TelepresenceReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    CustomTimeTooltip,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions } = settings;

    const lng_txts = lng_labels[format ? format.code : 'pt_BR'];

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const [currMode, setCurrMode] = useState(null);
    const [filteredCallList, setFilteredCallList] = useState([]);

    const headers_table = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'mode_str',
            label: <LocaleMessage msg="table.headers.mode" />,
        },
        {
            id: 'end',
            order_by: 'end_timestamp',
            label: <LocaleMessage msg="table.headers.end_date" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
    ];

    const headers_file = [
        { key: 'group_name', label: 'Group' },
        { key: 'robot_name', label: 'Robot' },
        { key: 'application_name', label: 'Application' },
        { key: 'mode_str', label: 'Mode' },
        {
            key: 'start_date',
            label: 'Start Date',
        },
        {
            key: 'start_time',
            label: 'Start Time',
        },
        {
            key: 'end_date',
            label: 'End Date',
        },
        {
            key: 'end_time',
            label: 'End Time',
        },
        {
            key: 'duration_label',
            label: 'Duration',
        },
    ];

    const headers_modes = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.mode" />,
        },
        {
            id: 'total',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
        {
            id: 'time_label',
            label: <LocaleMessage msg="table.headers.duration" />,
            ordered_by: 'time',
        },
    ];

    function filterData(data) {
        const { robots, groups, applications } = counterData;

        const { telepresence_calls, period } = data;
        if (!telepresence_calls) return;
        const call_list = telepresence_calls ? telepresence_calls.list : [];

        const c_robots = [];
        const c_modes = [];
        const c_groups = [];
        const c_applications = [];
        const c_e_date = [];
        const c_e_time = [];
        const c_s_date = [];
        const c_s_time = [];
        const c_m_time = [];
        let duration_sum = 0;
        let has_time = 0;
        let longest = 0;

        const dlist = call_list.map((d, idx) => {
            const c_time = d.time && d.time > 0 ? d.time : 0;
            if (c_time) {
                has_time++;
            }
            duration_sum += c_time;

            const start = GetDateTimeLabel(new Date(d.start), { format });
            const end = d.end
                ? GetDateTimeLabel(new Date(d.end), { format })
                : null;

            const robot = robots[d.robot_id];
            const group = groups[d.group_id];
            const application = applications[d.application_id];

            if (!robots[d.robot_id] || robots[d.robot_id].code === '---') {
                robots[d.robot_id] = { code: d.robot_code || '---', name: '' };
            }

            const m_time = c_time / 60;
            const m_time_label = Math.floor(m_time);
            if (m_time_label > longest) {
                longest = m_time_label;
            }

            c_robots[d.robot_id] = {
                total: c_robots[d.robot_id]
                    ? c_robots[d.robot_id].total + 1
                    : 1,
                time: c_robots[d.robot_id]
                    ? c_robots[d.robot_id].time + c_time
                    : c_time,
            };

            c_groups[d.group_id] = {
                total: c_groups[d.group_id]
                    ? c_groups[d.group_id].total + 1
                    : 1,
                time: c_groups[d.group_id]
                    ? c_groups[d.group_id].time + c_time
                    : c_time,
            };

            c_applications[d.application_id] = {
                total: c_applications[d.application_id]
                    ? c_applications[d.application_id].total + 1
                    : 1,
                time: c_applications[d.application_id]
                    ? c_applications[d.application_id].time + c_time
                    : c_time,
            };

            c_modes[d.mode || '---'] = {
                total: c_modes[d.mode || '---']
                    ? c_modes[d.mode || '---'].total + 1
                    : 1,
                time: c_modes[d.mode || '---']
                    ? c_modes[d.mode || '---'].time + c_time
                    : c_time,
            };

            if (end) {
                c_e_date[end.date] = c_e_date[end.date]
                    ? c_e_date[end.date] + 1
                    : 1;
                c_e_time[end.hour] = c_e_time[end.hour]
                    ? c_e_time[end.hour] + 1
                    : 1;
            }

            c_s_date[start.date] = c_s_date[start.date]
                ? c_s_date[start.date] + 1
                : 1;
            c_s_time[start.hour] = c_s_time[start.hour]
                ? c_s_time[start.hour] + 1
                : 1;

            const duration = GetOperationTime(d.time);

            const mode = operation_mode_labels[d.mode];
            return {
                ...d,
                id: idx,
                group_name: group ? group.name : '---',
                robot_name: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${d.robot_code || '---'}]`,
                application_name: application ? application.name : '---',
                mode: d.mode || '---',
                mode_str: lng_txts[mode],
                start_date: start.date,
                start_time: start.time,
                start: start.string,
                start_timestamp: start.timestamp,
                end_date: end ? end.date : '',
                end_time: end ? end.time : '',
                end: end ? end.string : '',
                end_timestamp: end ? end.timestamp : '',
                duration: c_time || 0,
                duration_label: end && c_time ? duration.label : '---',
            };
        });

        let extra_time = 0;
        const limit_time = longest > 0 && longest <= 60 ? longest + 10 : 60;
        const duration_spacer = limit_time > 20 ? 5 : 1;

        dlist.forEach(d => {
            const time = Math.floor(d.duration / 60);
            const time_label =
                Math.floor(time / duration_spacer) * duration_spacer || 0;

            if (time_label > limit_time) {
                extra_time++;
                return;
            }
            c_m_time[time_label] = c_m_time[time_label]
                ? c_m_time[time_label] + 1
                : 1;
        });

        const robot_grouped = Object.keys(c_robots).map(r => {
            const val = c_robots[r];
            const obj = robots[r];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const group_grouped = Object.keys(c_groups).map(g => {
            const val = c_groups[g];
            const obj = groups[g];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const application_grouped = Object.keys(c_applications).map(a => {
            const val = c_applications[a];
            const obj = applications[a];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const mode_grouped = Object.keys(c_modes).map(m => {
            const val = c_modes[m];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);
            return {
                id: m,
                name: m.toUpperCase(),
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const period_dates = GetPeriodDates(period);

        const c_labels = [];
        const s_date_grouped = [];
        const e_date_grouped = [];

        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });

            c_labels.push(label_date);

            s_date_grouped.push({
                date: label_date,
                value: c_s_date[formatted_date] || 0,
            });

            e_date_grouped.push({
                date: label_date,
                value: c_e_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const s_time_grouped = [];
        const e_time_grouped = [];
        const m_time_grouped = [];

        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);

            s_time_grouped.push({
                hour: label_time,
                value: c_s_time[t] || 0,
            });

            e_time_grouped.push({
                hour: label_time,
                value: c_e_time[t] || 0,
            });
        }

        let end_label = '';
        for (let t = 0; t <= limit_time; t += duration_spacer) {
            end_label = t + duration_spacer;
            const label_time = `${t} - ${end_label} min`;

            m_time_grouped.push({
                time: label_time,
                value: c_m_time[t] || 0,
            });
        }
        if (extra_time) {
            m_time_grouped.push({
                time: `> ${end_label} min`,
                value: extra_time || 0,
            });
        }

        const mean_duration = (
            Math.round((duration_sum / has_time) * 10 || 0) / 10
        ).toFixed(1);

        const duration_time = GetOperationTime(duration_sum);
        const mean_time = GetOperationTime(mean_duration);

        // Seconds to Minutes
        const mean_time_txt = `${Number(mean_time.hours) * 60 +
            Number(mean_time.minutes)}:${mean_time.seconds}`;

        setGroupedData({
            duration: duration_time,
            mean_duration: mean_time_txt,
            robots: robot_grouped,
            groups: group_grouped,
            applications: application_grouped,
            start_date: s_date_grouped,
            start_time: s_time_grouped,
            end_date: e_date_grouped,
            end_time: e_time_grouped,
            op_time: m_time_grouped,
            modes: mode_grouped,
            c_labels,
            t_labels,
        });

        setList(dlist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        await api
            .get(
                `reports/telepresence_calls?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleModeSelection(mode) {
        setCurrMode(mode);
        if (!mode) {
            setFilteredCallList(list);
            return;
        }

        const currList = list.filter(c => {
            return c.mode === mode;
        });
        setFilteredCallList(currList);
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="start_timestamp"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    sortable
                    hasFilter
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildDurationChart(title, data) {
        return (
            <ChartCard className="col-md-8 col-12 area-chart-container">
                <div>
                    <p className="card-title">{title}</p>
                </div>
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                    width="100%"
                    height={300}
                >
                    <BarChart data={data || []}>
                        <CartesianGrid />
                        <XAxis dataKey="time" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar
                            dataKey="value"
                            fill={`${hexToRgba(colors.dashboard_cards, 0.7)}`}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>
        );
    }

    function renderModeDetails() {
        const Page = currMode ? pages[currMode] : null;

        return (
            <>
                {Page ? (
                    <Page
                        settings={settings}
                        data={filteredCallList}
                        active={active}
                        colors={colors}
                        timeTooltip={CustomTimeTooltip}
                    />
                ) : null}
            </>
        );
    }

    function renderCSVButton() {
        return (
            <ExportCSVButton
                data={list}
                headers={headers_file}
                filename={GetFileName(
                    `REPORTS-telepresence_calls-general`,
                    'csv'
                )}
            />
        );
    }

    useEffect(() => {
        filterData(rawData);
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const telepresence_calls =
        rawData && rawData.telepresence_calls
            ? rawData.telepresence_calls.total
            : 0;

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
    ];

    const mode_filter =
        groupedData && groupedData.modes ? groupedData.modes : [];

    return (
        <>
            <>
                <ReportHeader
                    date_props={{
                        locale,
                    }}
                    period={{
                        minDate,
                        fromDate: new Date(fromISO),
                        untilDate: new Date(untilISO),
                    }}
                    handleFromDateChange={handleFromDateChange}
                    handleUntilDateChange={handleUntilDateChange}
                    reloadData={() => loadData()}
                    exportButton={renderCSVButton()}
                    shortcuts={shortcuts}
                    handleShortcutClick={_id => handlePeriodShortcut(_id)}
                />
            </>
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.telepresence.label.total_calls" />
                                }
                                value={telepresence_calls}
                            />
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.telepresence.label.total_duration" />
                                }
                                value={
                                    groupedData.duration
                                        ? groupedData.duration.text
                                        : '00:00'
                                }
                                footer={
                                    <LocaleMessage msg="label.time.hours_minutes" />
                                }
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'total';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.telepresence.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'time';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.telepresence.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            tooltip: CustomTimeTooltip,
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.telepresence.label.per_s_date" />,
                                groupedData.start_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.telepresence.label.per_s_hour" />,
                                groupedData.start_time
                            )}
                        </div>
                        <Divider />
                        <TotalContainer
                            className="mt-3 mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.telepresence.label.mean_duration" />
                                }
                                value={
                                    groupedData.mean_duration
                                        ? groupedData.mean_duration
                                        : '00:00'
                                }
                                footer={
                                    <LocaleMessage msg="label.time.minutes_seconds" />
                                }
                            />
                            {buildDurationChart(
                                <LocaleMessage msg="page.reports.telepresence.label.per_duration" />,
                                groupedData.op_time
                            )}
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <ChartsContainer className="row mt-5 mb-3">
                                <ReportPieChart
                                    classes="col-md-4 col-12 mb-5"
                                    title={
                                        <LocaleMessage msg="page.reports.telepresence.label.per_mode.total" />
                                    }
                                    color={colors.dashboard_graphs}
                                    data={groupedData.modes}
                                    dataKey="total"
                                />
                                <TableArea className="col-md-4 col-12 mb-3 table-responsive-material">
                                    <DataTable
                                        maxHeight="100%"
                                        headerColumns={headers_modes}
                                        data={mode_filter}
                                        orderDirection="desc"
                                        orderColumn="total"
                                        handleTableRowClick={(event, id) => {
                                            handleModeSelection(id);
                                        }}
                                        hasActions={false}
                                        sortable
                                        defaultRowsPerPage={10}
                                        hasFooter={false}
                                        headerData={{
                                            id: null,
                                            name: 'TOTAL',
                                            time: 0,
                                            time_label: groupedData.duration
                                                ? groupedData.duration.label
                                                : '00:00:00',
                                            total: telepresence_calls,
                                        }}
                                    />
                                </TableArea>
                                <ReportPieChart
                                    classes="col-md-4 col-12 mb-5"
                                    title={
                                        <LocaleMessage msg="page.reports.telepresence.label.per_mode.time" />
                                    }
                                    color={colors.dashboard_graphs}
                                    data={groupedData.modes}
                                    dataKey="time"
                                    customization={{
                                        tooltip: CustomTimeTooltip,
                                    }}
                                />
                            </ChartsContainer>
                        </InfoContainer>
                        <Divider />
                        {currMode ? (
                            <>{renderModeDetails()}</>
                        ) : (
                            <RowArea className="mt-3 mb-5">
                                <div>
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.telepresence.label.all_calls" />
                                    </p>
                                </div>
                                {buildListView()}
                            </RowArea>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

TelepresenceReports.defaultProps = {
    options: {},
};

TelepresenceReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    options: PropTypes.object,
};
