/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

export default function SUGroups({
    requestError,
    renderDialog,
    filter,
    formats,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [currGroup, setCurrGroup] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const headersFile = [
        { key: 'active', label: 'Is Active' },
        { key: 'rc3_status', label: 'RC3 Active' },
        { key: 'pluginspace_name', label: 'Pluginspace' },
        { key: 'name', label: 'Name' },
        {
            key: 'created_date',
            label: 'Creation Date',
        },
        {
            key: 'created_time',
            label: 'Creation Time',
        },
    ];

    const headerCells = [
        {
            id: 'pluginspace_name',
            label: <LocaleMessage msg="table.headers.pluginspace" />,
        },
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'status',
            order_by: 'active',
            label: <LocaleMessage msg="table.headers.status" />,
        },
        {
            id: 'rc3_status',
            order_by: 'rc3_status_tag',
            label: <LocaleMessage msg="table.headers.rc3_available" />,
        },
        {
            id: 'created_date',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(g => {
            const created = new Date(g.created);
            const updated = new Date(g.updated);
            const c_time = created.toLocaleTimeString([], formats.time);
            const u_time = updated.toLocaleTimeString([], formats.time);
            return {
                ...g,
                pluginspace_name:
                    g.pluginspace && g.pluginspace.name
                        ? g.pluginspace.name
                        : '---',
                status: g.active ? (
                    <LocaleMessage msg="info.status.active" />
                ) : (
                    <LocaleMessage msg="info.status.inactive" />
                ),
                rc3_status_tag: !!g.principal_id,
                rc3_status: g.principal_id ? (
                    <LocaleMessage msg="info.status.active" />
                ) : (
                    <LocaleMessage msg="info.status.inactive" />
                ),
                created_date: created.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                created_time: c_time,
                created_timestamp: created.toISOString(),
                updated_distance: formatDistance(updated, new Date(), {
                    addSuffix: true,
                    locale: formats.locale,
                }),
                updated_date: updated.toLocaleDateString(
                    formats.format,
                    formats.date
                ),
                updated_time: u_time,
                updated_timestamp: updated.toISOString(),
            };
        });
        setItems(data);
    }

    async function loadGroups() {
        setIsLoading(true);
        const filter_url = filter ? `?pluginspace=${filter}` : '';
        await api
            .get(`admin/groups${filter_url}`)
            .then(response => {
                filterData(response.data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function updateGroup(event) {
        event.preventDefault();
        setDialogOpen(false);
        setCurrGroup(null);

        if (!currGroup) {
            return toast.error(<LocaleMessage msg="errors.group_not_found" />);
        }
        setIsLoading(true);

        const data = { active: currGroup.active || false };

        await api
            .put(`admin/groups/${currGroup.id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.groups.form.update_success" />
                );
            })
            .catch(error => requestError(error));
        loadGroups();
        return setIsLoading(false);
    }

    useEffect(() => {
        loadGroups();
    }, [formats, filter]);

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
        setCurrGroup(null);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        const group = items.find(g => {
            return g.id === id;
        });
        setCurrGroup(group);
        setDialogOpen(true);
    }

    function buildExportButton() {
        return (
            <CSVLink
                data={items}
                headers={headersFile}
                filename={GetFileName(`admin_analytics_groups`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="button.export_raw" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    function buildListView() {
        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headerCells}
                    data={items}
                    orderColumn="name"
                    rowActions={rowActions}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return renderDialog({
            open: dialogOpen,
            title: <LocaleMessage msg="page.su_groups.list.edit" />,
            content: (
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={currGroup.active || false}
                        onChange={event =>
                            setCurrGroup({
                                ...currGroup,
                                active: event.target.checked,
                            })
                        }
                        label={<LocaleMessage msg="info.status.active" />}
                    />
                </div>
            ),
            actions: (
                <>
                    <Button onClick={handleDialogClose} color="primary">
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={e => updateGroup(e)}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.edit" />
                    </Button>
                </>
            ),
        });
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            {dialogOpen ? buildConfirmDialog() : null}
            <div style={{ padding: '15px' }}>{buildExportButton()}</div>
            <>{buildListView()}</>
        </>
    );
}

SUGroups.defaultProps = {
    filter: '',
    formats: {
        date: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hourCycle: 'h23',
        },
    },
};

SUGroups.propTypes = {
    filter: PropTypes.string,
    formats: PropTypes.object,
    requestError: PropTypes.func.isRequired,
    renderDialog: PropTypes.func.isRequired,
};
