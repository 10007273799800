import { darken } from 'polished';
import styled from 'styled-components';

export const PreviewArea = styled.div`
    border: 2px solid #999;
    padding: 10px;
    width: 100%;
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #999;
    width: 100%;
    height: ${props => {
        const w = props.dimensions.width;
        const h = (9.0 / 16.0) * w;
        return `${parseInt(h, 10) || 0}px`;
    }};

    background: ${props => {
        const { colors } = props;
        const c1 = colors ? colors.top : '#000';
        const c2 = colors ? colors.bottom : '#000';

        return `linear-gradient(180deg, ${c1}, ${c2})`;
    }};

    font-family: 'Saira Semi Condensed', sans-serif !important;
    color: #eee;

    button {
        background-color: ${props => {
            const { colors } = props;
            const c = colors ? colors.buttons : '#000';
            return c;
        }};
    }

    .MuiButton-containedPrimary:hover {
        background-color: ${props => {
            const { colors } = props;
            const c = colors ? colors.buttons : '#000';

            return darken(0.25, c);
        }};
    }
`;

export const LoginPage = styled.div`
    display: flex;
    height: 100%;

    .content {
        width: 100%;
        height: 100%;
    }

    .logo-bg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 0px;

        .pluginspace-logo {
            max-width: 40%;
        }

        .login-img {
            max-width: 80%;
            max-height: 80%;
            width: ${props => {
                const { settings } = props;
                const w = settings.media_width ? settings.media_width : 50;
                return `${w}%`;
            }};
            object-fit: contain;
        }
    }

    .white-bg {
        background-color: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .pluginbot-logo {
            max-width: 40%;
        }

        .MuiInputBase-input {
            padding: 6px !important;
        }
    }
`;

export const DeliveryPage = styled.div`
    display: flex;
    height: 100%;

    .header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => {
            const { colors } = props;
            const c1 = colors ? colors.buttons : '#000';
            return c1;
        }};

        border-bottom: 1px solid #000000aa;

        .pluginbot-logo {
            max-height: 50%;
        }
    }

    .content {
        width: 100%;
        height: 90%;
    }

    .icon-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-right: 0px;
    }

    .img-area {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-right: 0px;

        .robot-img {
            max-width: 80%;
            max-height: 60%;
        }

        .pluginspace-logo {
            max-width: 40%;
        }

        .robot-name {
            font-size: ${props => {
                const h = props.dimensions.height;
                return `${parseInt(h * 0.05, 10) || 0}px`;
            }};
        }
    }

    .options-area {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .pluginspace-logo {
            max-width: 90%;
        }

        .pluginbot-logo {
            max-width: 40%;
        }

        .options {
            width: 80%;
        }

        button {
            span {
                font-size: ${props => {
                    const h = props.dimensions.height;
                    return `${parseInt(h * 0.05, 10) || 0}px`;
                }};
            }
        }
    }
`;
