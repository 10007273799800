/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    MarkerType,
} from 'reactflow';

import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import 'reactflow/dist/style.css';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

const nodeH = 50;
const nodeW = 120;
const spaceY = 40;
const spaceX = 60;

const node_types = {
    start: 'input',
    end: 'output',
    input: 'default',
    output: 'output',
    question: 'default',
};

export default function FlowView({
    tree,
    orientation,
    onClick,
    onConnectEnd,
    showControls,
    controlPosition,
    savePositions,
    fit,
    connectable,
    nodeTypes,
    onToolbarClick,
}) {
    const theme = GetPluginspaceTheme(useTheme());
    const levelsW = Object.keys(tree).map(l => {
        return tree[l].length || 0;
    });
    const maxLvl = tree ? Object.keys(tree).length : 0;
    const maxWidth = Math.max(...levelsW);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const onConnect = useCallback(
        params => setEdges(eds => addEdge(params, eds)),
        [setEdges]
    );

    function buildTreeLevelV(level, items) {
        const levelWidth = items.length;
        const widthDiff = Math.abs(maxWidth - levelWidth);
        const xSize = nodeW + spaceX;
        const ySize = nodeH + spaceY;

        const xOffset = (widthDiff * xSize + spaceX) / 2;

        const used = level >= 0;
        const posY = (used ? level + 1 : maxLvl) * ySize;

        const l_edges = [];
        const l_nodes = items.map((n, idx) => {
            const n_id = n.id;
            const posX = xOffset + idx * xSize;

            const defPos = { x: posX, y: posY };

            const pos = n && n.position ? n.position : defPos;
            const exits = n.exits || [];

            exits.forEach(e => {
                l_edges.push({
                    id: `${e.edge_id}_${n_id}_${e.to}`,
                    source: n_id,
                    target: e.to,
                    type: 'smoothstep',
                    style: {
                        color: '#000',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                });
            });

            return {
                id: n_id,
                position: pos,
                data: {
                    label: n.name,
                },
                type: node_types[n.type] || 'default',
                style: {
                    border: `1px solid ${theme.primary}`,
                    ...(n.type === 'end' && {
                        background: theme.primary,
                        color: 'white',
                    }),
                    ...(!used && {
                        border: `1px dashed #999`,
                        color: '#999',
                    }),
                },
            };
        });

        return {
            nodes: l_nodes,
            edges: l_edges,
        };
    }

    function buildQnATree(level, items) {
        const xSize = nodeW + spaceX * 2;
        const ySize = nodeH + spaceY;

        let yOffset = level === 0 ? -ySize : 0;

        const used = level >= 0;
        const posX = (used ? level + 1 : maxLvl) * xSize;

        const l_edges = [];

        const l_nodes = items.map(n => {
            const n_id = n.id;
            const exits = n.exits || [];
            const posY = yOffset;
            yOffset += ySize;

            const origin = n.origin || {};
            const oX = origin.x ? origin.x : 0;
            const oY = origin.y || 0;
            const defPos = { x: oX + posX, y: oY + posY };

            const pos = n && n.position ? n.position : defPos;

            exits.forEach(e => {
                l_edges.push({
                    id: `${e.edge_id}_${n_id}_${e.to}`,
                    source: n_id,
                    target: e.to,
                    type: 'smoothstep',
                    style: {
                        color: '#000',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                    },
                });
            });

            return {
                id: n_id,
                position: pos,
                data: {
                    label: n.name,
                    type: n.type,
                    size: nodeW + spaceX,
                    special: n.special || n.type === 'end',
                    targetPosition: n.targetPosition || 'left',
                    sourcePosition: n.sourcePosition || 'right',
                    blockToolbar: n.blockToolbar || false,
                    onDeleteClick: () => onToolbarClick('delete', n.id),
                    onEditClick: () => onToolbarClick('edit', n.id),
                    onExpandClick: () => onToolbarClick('add', n.id),
                },
                type: 'qna',
                draggable: n.draggable || false,
            };
        });

        return {
            nodes: l_nodes,
            edges: l_edges,
        };
    }

    function buildTree() {
        let tree_nodes = [];
        let tree_edges = [];

        Object.keys(tree).forEach(l => {
            const level = tree[l];
            const tree_level =
                orientation === 'vertical'
                    ? buildTreeLevelV(Number(l), level)
                    : buildQnATree(Number(l), level);
            const l_nodes =
                tree_level && tree_level.nodes ? tree_level.nodes : [];
            const l_edges =
                tree_level && tree_level.edges ? tree_level.edges : [];
            tree_nodes = [...tree_nodes, ...l_nodes];
            tree_edges = [...tree_edges, ...l_edges];
        });

        setNodes(tree_nodes);
        setEdges(tree_edges);
    }

    function savePositionList() {
        const list = {};
        nodes.forEach(n => {
            list[n.id] = n.position;
        });
        savePositions(list);
    }

    useEffect(() => {
        buildTree();
    }, [tree]);

    return tree ? (
        <ReactFlow
            fitView={fit}
            style={{ width: '100%' }}
            onConnect={onConnect}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={(e, n) => {
                onClick(e, n.id, n);
            }}
            proOptions={{ hideAttribution: true }}
            fitViewOptions={{
                padding: 0.35,
            }}
            onNodeDragStop={() => {
                savePositionList();
            }}
            snapToGrid
            snapGrid={[10, 10]}
            nodesConnectable={connectable}
            onConnectEnd={onConnectEnd}
        >
            {showControls ? <Controls position={controlPosition} /> : null}
            <Background />
        </ReactFlow>
    ) : (
        <div>
            <span>
                <LocaleMessage msg="label.no_data" />
            </span>
        </div>
    );
}

FlowView.defaultProps = {
    fit: true,
    connectable: false,
    onClick: () => {},
    onConnectEnd: () => {},
    savePositions: () => {},
    showControls: true,
    controlPosition: 'bottom-right',
    orientation: 'vertical',
    nodeTypes: {},
    onToolbarClick: () => {},
};

FlowView.propTypes = {
    tree: PropTypes.object.isRequired,
    savePositions: PropTypes.func,
    onClick: PropTypes.func,
    fit: PropTypes.bool,
    connectable: PropTypes.bool,
    showControls: PropTypes.bool,
    controlPosition: PropTypes.string,
    orientation: PropTypes.string,
    onConnectEnd: PropTypes.func,
    nodeTypes: PropTypes.object,
    onToolbarClick: PropTypes.func,
};
