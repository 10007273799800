/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import UserForm from '~/components/UserForm';

export default function SectionUserForm({
    body,
    setBody,
    operation,
    permissions,
}) {
    function onUserOpChange(event) {
        const new_user = event.target.value;
        return setBody({ ...body, new_user });
    }

    function renderExistingUser() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <TextField
                    label={<LocaleMessage msg="user.email" />}
                    value={body.email || ''}
                    fullWidth
                    onChange={event =>
                        setBody({
                            ...body,
                            email: event.target.value,
                        })
                    }
                    disabled={operation === 'update'}
                />
            </div>
        );
    }

    function renderNewUser() {
        return (
            <div className="row col-12 p-0">
                <UserForm
                    user={body.user || {}}
                    setUser={u => setBody({ ...body, user: u })}
                />
            </div>
        );
    }

    const { group: g_permissions, section: s_permissions } = permissions;

    return (
        <div
            className="col-12 mt-3"
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px',
            }}
        >
            {operation === 'create' ? (
                <div className="col-md-6 col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="user.source" />
                        </InputLabel>
                        <Select
                            value={body.new_user ? body.new_user : false}
                            onChange={event => onUserOpChange(event)}
                        >
                            <MenuItem value={false}>
                                <LocaleMessage msg="user.source.existing" />
                            </MenuItem>
                            <MenuItem value>
                                <LocaleMessage msg="user.source.new" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            ) : null}

            {body.new_user ? renderNewUser() : renderExistingUser()}

            {operation === 'update' || !body.new_user ? null : (
                <div className="col-md-6 col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.sections.form.label.user.group_privileges" />
                        </InputLabel>
                        <Select
                            value={
                                body.group_permission
                                    ? body.group_permission
                                    : 'viewer'
                            }
                            onChange={event =>
                                setBody({
                                    ...body,
                                    group_permission: event.target.value,
                                })
                            }
                        >
                            {g_permissions.map(p => {
                                return (
                                    <MenuItem value={p.value} key={p.value}>
                                        {p.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            )}

            <div className="col-md-6 col-12 mb-5">
                <FormControl error={body.permission === null} fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.sections.form.label.user.section_privileges" />
                    </InputLabel>
                    <Select
                        value={body.permission ? body.permission : 'viewer'}
                        onChange={event =>
                            setBody({
                                ...body,
                                permission: event.target.value,
                            })
                        }
                    >
                        {s_permissions.map(p => {
                            return (
                                <MenuItem value={p.value} key={p.value}>
                                    {p.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

SectionUserForm.propTypes = {
    permissions: PropTypes.object,
    operation: PropTypes.string.isRequired,
    body: PropTypes.object.isRequired,
    setBody: PropTypes.func.isRequired,
};

SectionUserForm.defaultProps = {
    permissions: {
        group: [],
        section: [],
    },
};
