/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdAccountBox,
    MdCompareArrows,
    MdVerifiedUser,
    MdLock,
    MdPauseCircleFilled,
    MdCancel,
    MdPhonelinkOff,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    DialogContentText,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import RobotInfo from '~/components/RobotInfo';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { StatusArea } from './styles';

const status_list = [
    { label: 'robot.status.active', value: 'active' },
    { label: 'robot.status.frozen', value: 'frozen' },
    { label: 'robot.status.removed', value: 'removed' },
];

const status_info = {
    active: {
        icon: MdVerifiedUser,
        label: 'robot.status.active.info',
        allow: ['active', 'frozen', 'blocked', 'removed'],
    },
    frozen: {
        icon: MdPauseCircleFilled,
        label: 'robot.status.frozen.info',
        allow: ['active', 'frozen', 'blocked', 'removed'],
    },
    blocked: {
        icon: MdCancel,
        label: 'robot.status.blocked.info',
        allow: ['blocked', 'removed'],
    },
    removed: {
        icon: MdPhonelinkOff,
        label: 'robot.status.removed.info',
        allow: ['removed'],
    },
    deleted: {
        icon: MdCancel,
        label: 'robot.status.deleted.info',
    },
};

export default function SURobotForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;

    const [types, setTypes] = useState([]);
    const [currType, setCurrType] = useState({});
    const [pluginspaces, setPluginspaces] = useState([]);
    const [groups, setGroups] = useState([]);

    const [robot, setRobot] = useState({});

    const [transferBody, setTransferBody] = useState({
        robot_id: id,
        type: 'definitive',
    });

    const [transferDialogOpen, setTransferDialogOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [allowedStatus, setAllowedStatus] = useState(true);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPluginspaces() {
        setIsLoading(true);
        await api
            .get(`admin/pluginspaces`)
            .then(response => {
                const data = response.data.map(p => {
                    return {
                        id: p.id,
                        name: p.name,
                        active: p.active,
                    };
                });
                setPluginspaces(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function loadGroups(pluginspace_id) {
        await api
            .get(`admin/groups?pluginspace=${pluginspace_id}`)
            .then(response => {
                const data = response.data.map(g => {
                    return {
                        id: g.id,
                        name: g.name,
                        active: g.active,
                    };
                });
                setGroups(data);
            })
            .catch(error => requestError(error));
    }

    async function loadRobotTypes() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                setTypes(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadRobot(_id) {
        await api
            .get(`admin/robots/${_id}`)
            .then(response => {
                const robot_data = response.data;

                const r_status = robot_data.status;
                const r_status_info = status_info[r_status] || {};
                setAllowedStatus(r_status_info.allow || []);

                const r_type = robot_data.type || {};
                const r_pluginspace = robot_data.pluginspace;

                const r_group = robot_data.group;

                const r = {
                    ...robot_data,
                    type_id: r_type.id,
                    type_name: r_type.name,
                };

                setRobot(r);

                setTransferBody({
                    ...transferBody,
                    origin: {
                        pluginspace_id: r_pluginspace ? r_pluginspace.id : null,
                        pluginspace_name: r_pluginspace
                            ? r_pluginspace.name
                            : '',
                        group_id: r_group ? r_group.id : null,
                        group_name: r_group ? r_group.name : '',
                    },
                });

                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadPluginspaces();
        loadRobotTypes();
    }, []);

    useEffect(() => {
        loadRobot(id);
    }, [id]);

    useEffect(() => {
        const type = types.find(t => {
            return t.id === robot.type_id;
        });
        setCurrType(type);
    }, [types, robot.type_id]);

    useEffect(() => {
        const p_id =
            transferBody &&
            transferBody.destination &&
            transferBody.destination.pluginspace_id
                ? transferBody.destination.pluginspace_id
                : null;
        loadGroups(p_id);
    }, [transferBody.destination]);

    async function handleRemoveSubmit(event) {
        event.preventDefault();

        setStatusDialogOpen(false);
        setIsLoading(true);

        await api
            .delete(`admin/robots/${id}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.robots.form.delete_success" />
                );
                history.push(`/admin/robots`);
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    async function handleEditSubmit(event) {
        event.preventDefault();

        setStatusDialogOpen(false);
        setIsLoading(true);

        const data = { ...robot };
        await api
            .put(`admin/robots/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.robots.form.update_success" />
                );
                loadRobot(id);
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    async function handleTransferSubmit(event) {
        event.preventDefault();
        setTransferDialogOpen(false);
        setIsLoading(true);

        const data = { ...transferBody };

        await api
            .post(`admin/robots/${id}/transfer`, data)
            .then(() => {
                history.push('/admin/robots');
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setTransferDialogOpen(false);
        setStatusDialogOpen(false);
        setRemoveDialogOpen(false);
    }

    function renderRobot() {
        return (
            <RobotInfo
                robot={robot}
                setRobot={r => setRobot(r)}
                robotTypes={types}
                type={currType}
                operation="update"
                disabled
            />
        );
    }

    function renderAdminOptions() {
        const info = status_info[robot.status] || {};
        const Icon = info.icon || MdAccountBox;
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title="Platform Connection Status"
                    Icon={MdLock}
                >
                    <>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <RadioGroup
                                    className="mb-3"
                                    value={robot.status || 'allowed'}
                                    onChange={event => {
                                        setRobot({
                                            ...robot,
                                            status: event.target.value,
                                        });
                                    }}
                                >
                                    {status_list.map(s => {
                                        return (
                                            <FormControlLabel
                                                disabled={
                                                    !allowedStatus.includes(
                                                        s.value
                                                    )
                                                }
                                                key={`status_${s.value}`}
                                                value={s.value}
                                                label={
                                                    <LocaleMessage
                                                        msg={s.label}
                                                    />
                                                }
                                                control={
                                                    <Radio color="primary" />
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </div>
                            <StatusArea className="col-md-6 col-12 mb-5">
                                <div className="mb-5">
                                    <Icon size="7em" color="primary" />
                                </div>
                                <LocaleMessage msg={info.label} />
                            </StatusArea>
                        </div>
                        <div className="col-12 mb-3">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setStatusDialogOpen(true)}
                                fullWidth
                                disabled={robot.status === 'deleted'}
                            >
                                <LocaleMessage msg="button.save" />
                            </Button>
                        </div>
                        <div className="col-12 mb-3">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setRemoveDialogOpen(true)}
                                fullWidth
                                disabled={robot.status === 'deleted'}
                            >
                                <LocaleMessage msg="button.exclude_robot" />
                            </Button>
                        </div>
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function renderTransfer() {
        return (
            <>
                <div className="row">
                    <div className="col-md-6 col-12 mb-5">
                        <TextField
                            label={
                                <LocaleMessage msg="page.transfer.form.label.origin_pluginspace" />
                            }
                            fullWidth
                            value={
                                transferBody.origin &&
                                transferBody.origin.pluginspace_name
                                    ? transferBody.origin.pluginspace_name
                                    : ''
                            }
                            disabled
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <TextField
                            label={
                                <LocaleMessage msg="page.transfer.form.label.origin_group" />
                            }
                            fullWidth
                            value={
                                transferBody.origin &&
                                transferBody.origin.group_name
                                    ? transferBody.origin.group_name
                                    : ''
                            }
                            disabled
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.transfer.form.label.destination_pluginspace" />
                            </InputLabel>
                            <Select
                                value={
                                    transferBody.destination &&
                                    transferBody.destination.pluginspace_id
                                        ? transferBody.destination
                                              .pluginspace_id
                                        : ''
                                }
                                onChange={event =>
                                    setTransferBody({
                                        ...transferBody,
                                        destination: {
                                            ...transferBody.destination,
                                            pluginspace_id: event.target.value,
                                            group_id: null,
                                        },
                                    })
                                }
                            >
                                {pluginspaces.map(p => {
                                    return (
                                        <MenuItem
                                            value={p.id}
                                            key={`destination_${p.id}`}
                                        >
                                            {p.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.transfer.form.label.destination_group" />
                            </InputLabel>
                            <Select
                                value={
                                    transferBody.destination &&
                                    transferBody.destination.group_id
                                        ? transferBody.destination.group_id
                                        : ''
                                }
                                onChange={event =>
                                    setTransferBody({
                                        ...transferBody,
                                        destination: {
                                            ...transferBody.destination,
                                            group_id: event.target.value,
                                        },
                                    })
                                }
                            >
                                {groups.map(g => {
                                    return (
                                        <MenuItem
                                            value={g.id}
                                            key={`destination_${g.id}`}
                                        >
                                            {g.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </>
        );
    }

    function renderRemoveDialog() {
        return (
            <SimpleDialog
                open={removeDialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.su_robots.remove.dialog.title" />
                }
                content={
                    <DialogContentText>
                        {' '}
                        <LocaleMessage msg="page.su_robots.remove.dialog.body" />
                        <br />
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setRemoveDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: event => handleRemoveSubmit(event),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderConfirmStatusDialog() {
        return (
            <SimpleDialog
                open={statusDialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.su_robots.status.dialog.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.su_robots.status.dialog.body" />
                        {robot.status !== 'allowed' &&
                        robot.status !== 'frozen' ? (
                            <>
                                <br />
                                <LocaleMessage msg="message.undone.content" />
                            </>
                        ) : null}
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setStatusDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'save',
                        onClick: event => handleEditSubmit(event),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderConfirmTransferDialog() {
        return (
            <SimpleDialog
                open={transferDialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.transfer.form.unavailable.content" />
                        <br />
                        <LocaleMessage msg="page.transfer.form.reset.rc3" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setTransferDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'transfer',
                        onClick: event => handleTransferSubmit(event),
                        label: <LocaleMessage msg="button.transfer" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.robots.form.edit_title" />}
            breadcrumbs={[
                {
                    url: '/admin/analytics',
                    title: <LocaleMessage msg="breadcrumbs.analytics" />,
                },
                {
                    url: '/admin/robots',
                    title: <LocaleMessage msg="breadcrumbs.robots" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {transferDialogOpen ? renderConfirmTransferDialog() : null}
                {statusDialogOpen ? renderConfirmStatusDialog() : null}
                {removeDialogOpen ? renderRemoveDialog() : null}
                {robot && robot.id ? (
                    <>
                        <div className="row full-body">
                            <div className="col-md-8 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.robots.form.label.settings" />
                                    }
                                    Icon={MdAccountBox}
                                >
                                    <>{renderRobot()}</>
                                </CardSideBordered>
                            </div>
                            {renderAdminOptions()}
                            {robot.status !== 'deleted' ? (
                                <div className="col-md-8 col-12 mb-5">
                                    <CardSideBordered
                                        title={
                                            <LocaleMessage msg="page.transfer.form.label.new_transfer" />
                                        }
                                        Icon={MdCompareArrows}
                                    >
                                        <>
                                            {renderTransfer()}
                                            <div className="col-12 mb-1">
                                                <Button
                                                    className="p-3"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        setTransferDialogOpen(
                                                            true
                                                        )
                                                    }
                                                    fullWidth
                                                    size="large"
                                                >
                                                    <LocaleMessage msg="button.transfer_robot" />
                                                </Button>
                                            </div>
                                        </>
                                    </CardSideBordered>
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </>
        </PageContent>
    );
}

SURobotForm.propTypes = {
    match: PropTypes.object.isRequired,
};
