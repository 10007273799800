/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    DialogContentText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import FormPasswordInput from '~/components/Form/PasswordInput';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea, IconParameter } from '../../styles';

const auth_types = [
    {
        value: 'no_auth',
        label: (
            <LocaleMessage msg="page.survey_steps.form.integration.label.auth.none" />
        ),
    },
    {
        value: 'basic',
        label: (
            <LocaleMessage msg="page.survey_steps.form.integration.label.auth.basic" />
        ),
    },
    // {
    //     value: 'apikey',
    //     label: (
    //         <LocaleMessage msg="page.survey_steps.form.integration.label.auth.apikey" />
    //     ),
    // },
];

export default function IntegrationSetup({ settings, onChange }) {
    const response_settings = settings.response || [];

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleNewVariable() {
        const items = response_settings || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            body_key: '',
            response_key: '',
        });
        onChange({
            ...settings,
            response: [...items],
        });
    }

    function handleDeleteCondition(idx) {
        setDialogOpen(false);
        const old_items = response_settings || [];
        const items = old_items.filter(c => {
            return c.item_id !== idx;
        });
        onChange({
            ...settings,
            response: [...items],
        });
    }

    function onVariableChange(event, idx, key) {
        const items = response_settings || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: event.target.value,
            };
        }
        onChange({
            ...settings,
            response: [...items],
        });
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleDialogOpen(event, index) {
        setCurrItem(index);
        event.preventDefault();
        setDialogOpen(true);
    }

    function renderVariableRow(type, item, index) {
        return (
            <div className="row col-12">
                <div className="col-11 row mx-0">
                    <div className="col-md-6 col-12">
                        <TextField
                            label={
                                <LocaleMessage msg="page.survey_steps.form.response.label.response.parameter" />
                            }
                            fullWidth
                            value={
                                item && item.response_key
                                    ? item.response_key
                                    : ''
                            }
                            onChange={event =>
                                onVariableChange(event, index, 'response_key')
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <TextField
                            label={
                                <LocaleMessage msg="page.survey_steps.form.response.label.save_as" />
                            }
                            fullWidth
                            value={item && item.body_key ? item.body_key : ''}
                            onChange={event =>
                                onVariableChange(event, index, 'body_key')
                            }
                        />
                    </div>
                </div>
                <IconParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={event => handleDialogOpen(event, item.item_id)}
                    />
                </IconParameter>
            </div>
        );
    }

    function renderVariablesList(values) {
        return (
            <div
                style={{
                    width: '100%',
                }}
            >
                {values.map((item, idx) => {
                    const { type } = item;
                    return (
                        <ParameterArea
                            key={item.item_id}
                            style={{
                                userSelect: 'none',
                            }}
                        >
                            {renderVariableRow(type, item, idx)}
                        </ParameterArea>
                    );
                })}
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.survey_steps.form.response.label.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => handleDeleteCondition(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderResponseSetup() {
        return (
            <>
                {dialogOpen ? buildDeleteDialog() : null}
                <>
                    <div
                        style={{
                            padding: '20px 15px',
                        }}
                    >
                        <LocaleMessage msg="page.survey_steps.form.response.label.info" />
                        <br />
                        <LocaleMessage msg="page.survey_steps.form.response.label.warning" />
                    </div>
                    <div style={{ padding: '0px 15px' }}>
                        <ParameterArea className="row mt-1 mb-5">
                            {renderVariablesList(response_settings || [])}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleNewVariable()}
                                startIcon={
                                    <MdAdd
                                        style={{
                                            color: '#fff',
                                        }}
                                    />
                                }
                            >
                                <LocaleMessage msg="page.survey_steps.form.response.label.add" />
                            </Button>
                        </ParameterArea>
                    </div>
                </>
            </>
        );
    }

    function renderBasicAuth() {
        return (
            <div className="row">
                <div className="col-md-6 col-12 mb-5">
                    <FormPasswordInput
                        id="auth-username"
                        label={
                            <LocaleMessage msg="page.survey_steps.form.integration.label.auth.params.username" />
                        }
                        fullWidth
                        value={settings.username || ''}
                        onChange={event =>
                            onChange({
                                ...settings,
                                username: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-md-6 col-12 mb-5">
                    <FormPasswordInput
                        id="auth-password"
                        label={
                            <LocaleMessage msg="page.survey_steps.form.integration.label.auth.params.password" />
                        }
                        fullWidth
                        value={settings.password || ''}
                        onChange={event =>
                            onChange({
                                ...settings,
                                password: event.target.value,
                            })
                        }
                    />
                </div>
            </div>
        );
    }

    function renderAPIKeyAuth() {
        return (
            <>
                <div className="col-12 mb-5">
                    <FormPasswordInput
                        id="auth-apikey"
                        label={
                            <LocaleMessage msg="page.survey_steps.form.integration.label.auth.params.apikey" />
                        }
                        fullWidth
                        value={settings.apikey || ''}
                        onChange={event =>
                            onChange({
                                ...settings,
                                apikey: event.target.value,
                            })
                        }
                    />
                </div>
            </>
        );
    }

    const auth_forms = {
        basic: renderBasicAuth,
        apikey: renderAPIKeyAuth,
    };

    const renderAuth =
        settings.auth_type && auth_forms[settings.auth_type]
            ? auth_forms[settings.auth_type]
            : null;

    return (
        <div className="mt-3">
            <div className="col-12 mb-3">
                <FormSwitch
                    value={settings.allow || false}
                    onChange={event =>
                        onChange({
                            ...settings,
                            allow: event.target.checked,
                        })
                    }
                    label={
                        <LocaleMessage msg="page.survey_steps.form.integration.label.use" />
                    }
                />
            </div>
            {settings.allow ? (
                <>
                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.integration.label.auth" />
                            </InputLabel>
                            <Select
                                id="auth-type"
                                value={
                                    settings.auth_type
                                        ? settings.auth_type
                                        : 'none'
                                }
                                onChange={event =>
                                    onChange({
                                        ...settings,
                                        auth_type: event.target.value,
                                    })
                                }
                            >
                                {auth_types.map(l => {
                                    return (
                                        <MenuItem value={l.value} key={l.value}>
                                            {l.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-12 mb-5">
                        <TextField
                            id="integration-url"
                            label={
                                <LocaleMessage msg="page.survey_steps.form.integration.label.url" />
                            }
                            fullWidth
                            value={settings.url || ''}
                            onChange={event =>
                                onChange({
                                    ...settings,
                                    url: event.target.value,
                                })
                            }
                        />
                    </div>
                    {renderAuth ? renderAuth() : null}

                    {renderResponseSetup()}
                </>
            ) : null}
        </div>
    );
}

IntegrationSetup.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
