/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Button, Divider, TextField } from '@material-ui/core';

import FormStepper from '~/components/Form/Stepper';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import ConditionConfig from './ConditionConfig';
import MediaConfig from './MediaConfig';
import MovementConfig from './MovementConfig';
import WakeUpConfig from './WakeUpConfig';

export default function DialogConditionConfig({
    settings,
    setToast,
    closePage,
    handleError,
    robotTypes,
    currType,
    setCurrType,
    imageGalleriesList,
    robotGalleries,
    languages,
}) {
    const { id, application_content } = settings;
    const { id: application_content_id, dialog_id } = application_content;

    const operation = id === 'new' ? 'create' : 'update';

    const mountedRef = React.useRef(true);
    const [body, setBody] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [questionList, setQuestionList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [topicList, setTopicList] = useState([]);

    const [activeStep, setActiveStep] = useState(0);

    async function loadConversationContent(refresh = false) {
        setIsLoading(true);
        await api
            .get(
                `dialogs/${dialog_id}/content?intents=true&subjects=true&refresh=${refresh}`
            )
            .then(response => {
                const { data } = response;
                const questions = data.questions || [];
                const subjects = data.subjects || [];
                const topics = data.topics || [];

                setQuestionList(
                    questions.map(q => {
                        return q.intent;
                    })
                );

                setSubjectList(
                    subjects.map(s => {
                        return s.value;
                    })
                );

                setTopicList(
                    topics.map(t => {
                        return t.value;
                    })
                );
            })
            .catch(() => {});
        setIsLoading(false);
    }

    async function loadSettings() {
        if (operation === 'update') {
            await api
                .get(
                    `conversation_contents/${application_content_id}/conditions/${id}`
                )
                .then(response => {
                    const { data } = response;
                    setBody(data);
                })
                .catch(error => handleError(error));
        } else {
            setIsLoading(false);
        }
    }

    async function loadAll() {
        setIsLoading(true);
        await loadConversationContent();
        await loadSettings();
        setIsLoading(false);
    }

    useEffect(() => {
        loadAll();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function handleStepClick(step) {
        setActiveStep(step);
    }

    async function handleSubmit() {
        const data = { ...body };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(
                    `conversation_contents/${application_content_id}/conditions`,
                    data
                )
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.condition.created" />
                        ),
                    });
                    closePage();
                })
                .catch(error => handleError(error));
        } else {
            await api
                .put(
                    `conversation_contents/${application_content_id}/conditions/${id}`,
                    data
                )
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.condition.updated" />
                        ),
                    });
                    closePage();
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    const condition = body && body.condition ? body.condition : {};
    const condition_body = condition.condition || {};

    function renderHeader() {
        return (
            <div
                className="col-12 row mt-3 mb-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => closePage()}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => handleSubmit()}
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    function renderConditionForm() {
        return (
            <ConditionConfig
                settings={settings}
                dialogContent={{
                    questions: questionList,
                    subjects: subjectList,
                    topics:
                        topicList && topicList.length > 0 ? topicList : null,
                }}
                config={body.condition}
                setConfig={c => setBody({ ...body, condition: c })}
                handleError={error => handleError(error)}
                reloadConversationContent={() => loadConversationContent(true)}
                isLoading={isLoading}
            />
        );
    }

    function renderWakeUpForm() {
        const texts = body.texts || {};

        return (
            <WakeUpConfig
                texts={texts}
                languages={languages}
                setBody={config =>
                    setBody({
                        ...body,
                        texts: config,
                    })
                }
            />
        );
    }

    function renderMovementForm() {
        return condition_body && condition_body.state !== 'standby' ? (
            <MovementConfig
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={r => setCurrType(r)}
                robotGalleries={robotGalleries}
                config={body.movement}
                setConfig={c => setBody({ ...body, movement: c })}
                handleError={error => handleError(error)}
            />
        ) : (
            <p>
                <LocaleMessage msg="page.applications.conversation.condition.option_unavailable" />
            </p>
        );
    }

    function renderMediaSelector() {
        return (
            <MediaConfig
                media={body.media}
                led={body.led}
                setBody={config => setBody({ ...body, ...config })}
                handleError={error => handleError(error)}
                setToast={toast => setToast(toast)}
                robotTypes={robotTypes}
                currType={currType}
                setCurrType={r => setCurrType(r)}
                robotGalleries={robotGalleries}
                imageGalleriesList={imageGalleriesList}
                languages={languages}
            />
        );
    }

    function renderAfterCondition() {
        return (
            <div
                className="col-12 row mt-2 mb-4"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <FormSwitch
                    value={body.end_conversation || false}
                    onChange={event => {
                        setBody({
                            ...body,
                            end_conversation: event.target.checked,
                        });
                    }}
                    label={
                        <LocaleMessage msg="page.applications.conversation.condition.end_conversation" />
                    }
                />
            </div>
        );
    }

    function renderDescription() {
        return (
            <div
                className="col-12 row mt-2 mb-4"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <div className="col-md-6 col-12 mb-3">
                    <TextField
                        id="description"
                        label={
                            <LocaleMessage msg="page.applications.conversation.condition.description" />
                        }
                        fullWidth
                        value={body && body.description ? body.description : ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                description: event.target.value,
                            })
                        }
                    />
                </div>
            </div>
        );
    }

    const steps = [
        {
            key: 'sel_condition',
            label: (
                <LocaleMessage msg="page.applications.conversation.condition.sel_condition" />
            ),
            content: renderConditionForm(),
        },
        ...(condition_body.state && condition_body.state === 'wake_up'
            ? [
                  {
                      key: 'sel_wakeup',
                      label: (
                          <LocaleMessage msg="page.applications.conversation.condition.sel_wakeup" />
                      ),
                      content: renderWakeUpForm(),
                  },
              ]
            : []),
        {
            key: 'sel_movement',
            label: (
                <LocaleMessage msg="page.applications.conversation.condition.sel_movement" />
            ),
            content: renderMovementForm(),
        },
        {
            key: 'sel_media',
            label: (
                <LocaleMessage msg="page.applications.conversation.condition.sel_media" />
            ),
            content: renderMediaSelector(),
        },
        ...(condition.type && condition.type !== 'robot_state'
            ? [
                  {
                      key: 'after_conversation',
                      label: (
                          <LocaleMessage msg="page.applications.conversation.condition.after_condition" />
                      ),
                      content: renderAfterCondition(),
                  },
              ]
            : []),
        {
            key: 'sel_description',
            label: (
                <LocaleMessage msg="page.applications.conversation.condition.sel_description" />
            ),
            content: renderDescription(),
        },
    ];

    return (
        <>
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <Divider />
                    {renderHeader()}
                    <Divider />
                    <form
                        className="row full-body"
                        noValidate
                        autoComplete="off"
                    >
                        <div style={{ flex: '1' }}>
                            <FormStepper
                                steps={steps}
                                activeStep={activeStep}
                                handleStepClick={index =>
                                    handleStepClick(index)
                                }
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                handleSubmit={() => handleSubmit()}
                            />
                            <Button
                                className="mt-3 mb-3"
                                variant="contained"
                                color="primary"
                                onClick={event => handleSubmit(event)}
                                fullWidth
                                size="large"
                            >
                                <LocaleMessage msg="button.save" />
                            </Button>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}

DialogConditionConfig.defaultProps = {
    imageGalleriesList: [],
    robotGalleries: {},
    languages: ['pt_BR'],
};

DialogConditionConfig.propTypes = {
    settings: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    closePage: PropTypes.func.isRequired,
    imageGalleriesList: PropTypes.array,
    robotGalleries: PropTypes.object,
    languages: PropTypes.array,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};
