const languageData = [
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'gb',
        code: 'en_GB',
        format: 'en-GB',
    },
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
        code: 'en_US',
        format: 'en-US',
    },
    {
        languageId: 'brazilian',
        locale: 'br',
        name: 'Português',
        icon: 'br',
        code: 'pt_BR',
        format: 'pt-BR',
    },
    {
        languageId: 'spanish',
        locale: 'es',
        name: 'Español',
        icon: 'es',
        code: 'es_ES',
        format: 'es-ES',
    },
];
export default languageData;
