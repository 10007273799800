/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
    Button,
    Stepper,
    Step,
    StepContent,
    StepButton,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import ConversationIdentifiers from '~/components/ConversationIdentifiers';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import ContactDestination from './Destinations/Contacts';
import PointDestination from './Destinations/MapPoints';
import ScriptDestination from './Destinations/Scripts';
import OnFinishActionSetup from './OnFinishAction';
import OnStartActionSetup from './OnStartAction';

const parameters = {
    map_point: ['onStart', 'onFinish'],
    script: ['onFinish'],
    contact: ['onStart'],
};

export default function TriggerIdentifier({
    actionList,
    settings,
    setToast,
    handleError,
    closePage,
    robotType,
    appSettings,
    languages,
}) {
    const { id, dialog_id, application_id } = settings;
    const { group } = appSettings;

    const operation = id === 'new' ? 'create' : 'update';

    const [isLoading, setIsLoading] = useState(true);

    const [body, setBody] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const destinations = {
        script: ScriptDestination,
        map_point: PointDestination,
        contact: ContactDestination,
    };

    async function loadIdentifier(_id) {
        if (_id !== 'new') {
            await api
                .get(`conversation_identifiers/${_id}`)
                .then(async response => {
                    const { data } = response;
                    const identifier_body = {
                        ...data,
                        action_id: data.action ? data.action.id : '',
                    };
                    setBody(identifier_body);
                })
                .catch(error => handleError(error));
        } else {
            setBody({});
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadIdentifier(id);
    }, [id]);

    function setActionType(event) {
        const action_id = event.target.value;
        const action = actionList.find(a => {
            return a.id === action_id;
        });
        if (action) {
            setBody({
                ...body,
                action_id,
                type: action.identifier_type,
            });
        }
    }

    async function handleSubmit() {
        const data = {
            ...body,
            application_id,
            dialog_id,
            robot_type_id: robotType.id,
        };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`conversation_identifiers/`, data)
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.triggers.identifiers.created" />
                        ),
                    });
                })
                .catch(error => handleError(error));
        } else {
            await api
                .put(`conversation_identifiers/${id}`, data)
                .then(() => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.triggers.identifiers.updated" />
                        ),
                    });
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
        closePage();
    }

    const handleStep = step => () => {
        setActiveStep(step);
    };

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function renderHeader() {
        return (
            <div
                className="col-12 row mt-3 mb-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => closePage()}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
                <div className="col-3">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={() => handleSubmit()}
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        );
    }

    function renderIdentifierType() {
        const destination = body.destination || {};
        const DestinationSettings = destinations[body.type] || null;

        return (
            <>
                <div
                    className="row col-12"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="col-md-5 col-12">
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.action" />
                            </InputLabel>
                            <Select
                                disabled={operation === 'update'}
                                value={body.action_id || ''}
                                onChange={event => setActionType(event)}
                            >
                                {actionList.map(a => {
                                    return (
                                        <MenuItem value={a.id} key={a.id}>
                                            <LocaleMessage msg={a.label} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div
                    className="row col-12 mt-5"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {destinations[body.type] ? (
                        <DestinationSettings
                            group={group ? group.id : ''}
                            robotType={robotType}
                            appSettings={appSettings.settings || {}}
                            setToast={msg => setToast(msg)}
                            handleError={err => handleError(err)}
                            settings={destination}
                            setSettings={data => {
                                setBody({
                                    ...body,
                                    destination: data,
                                });
                            }}
                        />
                    ) : null}
                </div>
            </>
        );
    }

    function renderIdentifiers() {
        return (
            <>
                <div
                    className="row mt-3"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ConversationIdentifiers
                        size="col-md-10 col-12"
                        dialog_id={dialog_id}
                        identifiers={body.identifiers || []}
                        setIdentifiers={i =>
                            setBody({ ...body, identifiers: i })
                        }
                    />
                </div>
            </>
        );
    }

    function renderOnStartAction() {
        const content = body.content || {};

        return (
            <OnStartActionSetup
                actionType={body.type}
                languages={languages}
                settings={content.on_start || {}}
                onChange={e => {
                    setBody({
                        ...body,
                        content: {
                            ...content,
                            on_start: e,
                        },
                    });
                }}
            />
        );
    }

    function renderOnFinishAction() {
        const content = body.content || {};

        return (
            <OnFinishActionSetup
                actionType={body.type}
                languages={languages}
                settings={content.on_finish || {}}
                onChange={e => {
                    setBody({
                        ...body,
                        content: {
                            ...content,
                            on_finish: e,
                        },
                    });
                }}
            />
        );
    }

    const steps = [
        {
            key: 'type',
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.action.title" />
            ),
            content: renderIdentifierType(),
        },
        ...(body.type
            ? [
                  {
                      key: 'identifier',
                      label: (
                          <LocaleMessage msg="page.applications.conversation.triggers.identifiers" />
                      ),
                      content: renderIdentifiers(),
                  },
              ]
            : []),
        ...(body.type && (parameters[body.type] || []).includes('onStart')
            ? [
                  {
                      key: 'onStart',
                      label: (
                          <LocaleMessage msg="page.applications.conversation.triggers.identifiers.on_start.title" />
                      ),
                      content: renderOnStartAction(),
                  },
              ]
            : []),
        ...(body.type && (parameters[body.type] || []).includes('onFinish')
            ? [
                  {
                      key: 'onFinish',
                      label: (
                          <LocaleMessage msg="page.applications.conversation.triggers.identifiers.on_finish.title" />
                      ),
                      content: renderOnFinishAction(),
                  },
              ]
            : []),
    ];

    return (
        <>
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <Divider />
                    {renderHeader()}
                    <Divider />
                    <form
                        className="row full-body mt-5"
                        noValidate
                        autoComplete="off"
                    >
                        <div style={{ flex: '1' }}>
                            <Stepper
                                orientation="vertical"
                                nonLinear
                                activeStep={activeStep}
                            >
                                {steps.map((s, index) => (
                                    <Step key={s.key}>
                                        <StepButton onClick={handleStep(index)}>
                                            {s.label}
                                        </StepButton>
                                        <StepContent>
                                            {s.content}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={() => handleBack()}
                                                >
                                                    <LocaleMessage msg="button.back" />
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={
                                                        activeStep ===
                                                        steps.length - 1
                                                            ? () =>
                                                                  handleSubmit()
                                                            : () => handleNext()
                                                    }
                                                >
                                                    {activeStep ===
                                                    steps.length - 1 ? (
                                                        <LocaleMessage msg="button.save" />
                                                    ) : (
                                                        <LocaleMessage msg="button.next" />
                                                    )}
                                                </Button>
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}

TriggerIdentifier.defaultProps = {
    languages: {},
    actionList: [],
};

TriggerIdentifier.propTypes = {
    languages: PropTypes.object,
    actionList: PropTypes.array,
    settings: PropTypes.object.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    closePage: PropTypes.func.isRequired,
    robotType: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
};
