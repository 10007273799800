/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import SectionsForm from '~/components/Sections/Form';

import CheckGroupPermission from '~/util/CheckGroupPermission';

export default function GroupSectionForm({ match }) {
    const user = useSelector(state => state.user);
    const settings = useSelector(state => state.settings || {});

    const { active } = settings;
    const group_id = active && active.id !== '*' ? active.id : null;

    const { section_id } = match.params;
    const permissions = CheckGroupPermission(user || {}, group_id);

    const allowEdit = permissions.allow;
    const adminMode = permissions.admin;

    return (
        <SectionsForm
            breadcrumbs={[
                {
                    url: '/sections',
                    title: <LocaleMessage msg="breadcrumbs.sections" />,
                },
            ]}
            adminMode={adminMode}
            allowEdit={allowEdit}
            linkRoot=""
            section_id={section_id}
            group_id={group_id}
        />
    );
}

GroupSectionForm.propTypes = {
    match: PropTypes.object.isRequired,
};
