/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';
import BarInfo from '../../BarInfo';
import GaugeInfo from '../../GaugeInfo';

const h100 = { height: '100%' };
const center = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

const def_scale = 'hours';

export default function HardwareCard({
    width,
    options,
    robot,
    robotType,
    parseTime,
}) {
    const { style, format, size, label: custom_label } = options;

    // Current Robot Hardware State
    const state = robot.state || {};
    const robot_hardware = state.hardware || {};

    // Robot Type Hardware Defaults
    const type_settings =
        robotType && robotType.settings ? robotType.settings : {};
    const type_hardware_settings = type_settings.hardware || {};

    const hw_items = Object.keys(type_hardware_settings).map(hw => {
        const type_hw_group = type_hardware_settings[hw];
        const type_hw_params = type_hw_group.parameters || {};

        const { limit, limit_scale } = type_hw_params;

        const limit_time = limit ? Number(limit) : 0;
        let type_hw_limit = limit_time;

        // Transform limit to seconds
        switch (limit_scale) {
            case 'hours': {
                type_hw_limit = limit_time * 60 * 60;
                break;
            }
            case 'minutes': {
                type_hw_limit = limit_time * 60;
                break;
            }
            default:
                break;
        }

        // ToDO: Include robot limit override

        // Get type value scale
        const value_scale = limit_scale || def_scale;

        // Get Robot Hardware value
        const robot_hw = robot_hardware[type_hw_group.value] || null;
        const robot_hw_value =
            robot_hw && robot_hw.total ? Number(robot_hw.total) : 0;

        // Transform value to scale
        const robot_hw_time = parseTime(robot_hw_value, value_scale, format);

        // Get progress from seconds
        const progress = type_hw_limit
            ? Number(robot_hw_value / type_hw_limit)
            : 0;

        return {
            key: hw,
            label: type_hw_group.label,
            value: Number(robot_hw_time.text || 0),
            value_label: robot_hw_time.text,
            value_scale,
            limit: limit_time,
            limit_value: limit,
            limit_scale,
            progress,
        };
    });

    function renderHardwareUsageBars(items) {
        return (
            <>
                {items.map(hw => {
                    const progress = hw.progress * 100 || 0;
                    return (
                        <BarInfo
                            key={hw.key}
                            infoKey={hw.key}
                            labels={[
                                <LocaleMessage msg={hw.label} />,
                                <>
                                    {`${hw.value_label} / ${hw.limit_value} [`}
                                    <LocaleMessage
                                        msg={`rc3.info.scale.${hw.limit_scale}`}
                                    />
                                    ]
                                </>,
                            ]}
                            value={progress}
                        />
                    );
                })}
            </>
        );
    }

    function renderHardwareGauges(
        items,
        opt = { orientation: 'l2r', size: 'col-md-4' }
    ) {
        const gauge_options = {
            ...opt,
            n_colors: 3,
            // color: colors.primary,
        };

        return items.map(hw => {
            const {
                key: hw_key,
                label,
                value_label,
                value_scale,
                limit_value,
                limit_scale,
                progress,
            } = hw;

            const legend =
                limit_value && limit_scale ? (
                    <span>
                        {`${value_label} / ${limit_value} [`}
                        <LocaleMessage msg={`rc3.info.scale.${limit_scale}`} />]
                    </span>
                ) : (
                    <div className="info">
                        <LocaleMessage
                            className="mb-3"
                            msg={`rc3.info.scale.${value_scale}`}
                        />
                        <span className="info-text">
                            <LocaleMessage msg="rc3.robot.data.card.state.hardware.no_limit" />
                        </span>
                    </div>
                );

            return (
                <GaugeInfo
                    key={`hw_gauge_${hw_key}`}
                    options={gauge_options}
                    show={limit_value && limit_scale}
                    item={{ title: label, progress, value: value_label }}
                    label={legend}
                />
            );
        });
    }

    const functions = {
        bar: (items, opt) => renderHardwareUsageBars(items, opt),
        gauge: (items, opt) => renderHardwareGauges(items, opt),
    };

    const renderFunction = functions[style];

    return (
        <div className={width} style={h100}>
            <Box boxShadow={0} style={h100}>
                <InfoCard
                    style={{
                        padding: '20px 10px',
                    }}
                >
                    <div className="desc-full info">
                        <span className="jr-fs-md title mb-3">
                            <LocaleMessage
                                msg={
                                    custom_label ||
                                    'rc3.robot.data.card.state.hardware.usage'
                                }
                            />
                        </span>

                        <div className="col-12" style={center}>
                            {renderFunction
                                ? renderFunction(hw_items, {
                                      size,
                                      orientation: options.orientation || 'l2r',
                                  })
                                : null}
                        </div>
                    </div>
                </InfoCard>
            </Box>
        </div>
    );
}

HardwareCard.propTypes = {
    width: PropTypes.string,
    options: PropTypes.object,
    robot: PropTypes.object,
    robotType: PropTypes.object,
    parseTime: PropTypes.func.isRequired,
};

HardwareCard.defaultProps = {
    width: 'col-12',
    options: { key: 'total', style: 'bar', size: 'col-12' },
    robot: {},
    robotType: {},
};
