/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

const LanguageItem = ({ language, switchLanguage, handleRequestClose }) => {
    const { icon, name } = language;

    return (
        <li
            className="pointer"
            onClick={() => {
                handleRequestClose();
                switchLanguage(language);
            }}
        >
            <div className="d-flex align-items-center">
                <i className={`flag flag-24 flag-${icon}`} />
                <h4 className="mb-0 ml-2">{name}</h4>
            </div>
        </li>
    );
};

export default LanguageItem;

LanguageItem.propTypes = {
    language: PropTypes.object.isRequired,
    switchLanguage: PropTypes.func.isRequired,
    handleRequestClose: PropTypes.func.isRequired,
};
