import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    // border-radius: 0px 0px 0.375em 0.375em !important;
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1vw;
    margin: 0px;
    display: flex;
    align-items: center;

    img {
        max-height: 300px;
        height: 15vh;
        width: auto;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }

    .status-connected {
        border: 0.5vh solid #0f0;
    }

    .status-occupied {
        border: 0.5vh solid #ff0;
    }

    .status-alert {
        border: 0.5vh solid #f00;
    }

    .status-disconnected {
        border: 0.5vh solid #999;
    }

    .status-undefined {
        border: 0.5vh solid #999;
    }

    .card-body {
        height: 100%;
        padding: 0px 1vw;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-around;

        .card-title {
            font-size: 1.675vh;

            .robot-name {
                font-size: 1.875vh;
                font-weight: bold;
            }
        }

        .card-bottom {
            font-size: 1.35vh;

            > div {
                display: flex;
                flex-direction: column;
            }

            span {
                color: #999;
            }

            .robot-status {
                display: flex;
                align-items: center;
            }
        }
    }
`;

export const Placeholder = styled.div`
    max-width: 100%;
    max-height: 250px;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
    color: #fff;
    padding: 5px;
`;
