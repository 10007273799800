import appLocaleData from 'react-intl/locale-data/es';

import messages from '../messages/es_ES.json';

const Lang = {
    messages: {
        ...messages,
    },
    locale: 'es',
    format: 'es_ES',
    data: appLocaleData,
};
export default Lang;
