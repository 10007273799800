/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdCheck, MdClose, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const iconSize = 18;

export default function RobotDefaults(props) {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const { match } = props;
    const { id } = match.params;

    const [isLoading, setIsLoading] = useState(true);

    const [type, setType] = useState('');
    const [contents, setContents] = useState([]);

    const [currContent, setCurrContent] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadType(_id) {
        await api
            .get(`types/${_id}`)
            .then(response => {
                const t = response.data;
                setType(t.name);
            })
            .catch(error => requestError(error));
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`types/${id}/albums`)
            .then(response => {
                const data = response.data.map(g => {
                    const updated = new Date(g.updated);
                    return {
                        ...g,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        type_str: (
                            <LocaleMessage
                                msg={`list.galleries.type.${g.type}`}
                            />
                        ),
                        free_to_use: g.free ? (
                            <MdCheck size={iconSize} />
                        ) : (
                            <MdClose size={iconSize} />
                        ),
                        visible_all: g.visible ? (
                            <MdCheck size={iconSize} />
                        ) : (
                            <MdClose size={iconSize} />
                        ),
                        status_str: (
                            <LocaleMessage msg={`list.status.${g.status}`} />
                        ),
                    };
                });
                setContents(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadType(id);
    }, []);

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    async function deleteContent() {
        setIsLoading(true);
        setDeleteOpen(false);
        await api
            .delete(`types/${id}/albums/${currContent}`)
            .then(() => {
                loadItems();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    function handleClickOpen(event, _id) {
        setCurrContent(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrContent(_id);
        history.push(`/robotdefaults/${id}/albums/${_id}`);
    }

    function handleDeleteContentClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleDeleteContentClose}
                title={
                    <LocaleMessage msg="page.robot_types.defaults.list.delete" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteContent(currContent),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'type_str',
                label: <LocaleMessage msg="table.headers.type" />,
            },
            {
                id: 'free_to_use',
                label: <LocaleMessage msg="table.headers.free" />,
            },
            {
                id: 'visible_all',
                label: <LocaleMessage msg="table.headers.visible" />,
            },
            {
                id: 'status_str',
                label: <LocaleMessage msg="table.headers.status" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '15px 15px 0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={contents}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.robot_types.defaults.list.title" />}
            currentPage={`${type}`}
            breadcrumbs={[
                {
                    url: '/robotdefaults',
                    title: <LocaleMessage msg="breadcrumbs.robot_types" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {deleteOpen ? buildDeleteDialog() : null}
                <div className="body-top-controls">
                    <NewItemButton
                        link={`/robotdefaults/${id}/albums/new`}
                        text={
                            <LocaleMessage msg="page.robot_types.defaults.list.add" />
                        }
                    />
                </div>
                {buildListView()}
            </>
        </PageContent>
    );
}

RobotDefaults.propTypes = {
    match: PropTypes.object.isRequired,
};
