/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';

const center = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

export default function DialogSettings({
    dialogSettings,
    reloadSettings,
    handleError,
}) {
    const { id } = dialogSettings;

    const [body, setBody] = useState(dialogSettings);
    const [languages, setLanguages] = useState([]);

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const list = response.data;
                setLanguages(list);
            })
            .catch(e => {
                handleError(e);
            });
    }

    useEffect(() => {
        loadLanguages();
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body };

        await api
            .put(`dialogs/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.dialogs.form.update_success" />
                );
            })
            .catch(error => handleError(error));
        reloadSettings();
    }

    function renderLanguages() {
        const { qna_languages } = lists;
        const qna_lng_key = qna_languages.find(l => {
            return l.value === body.language;
        });

        const options = languages
            .filter(
                l =>
                    qna_lng_key &&
                    l.main !== qna_lng_key.key &&
                    l.value !== qna_lng_key.value
            )
            .map(l => {
                return {
                    id: l.value,
                    name: <LocaleMessage msg={`list.languages.${l.value}`} />,
                };
            });

        const curr_settings = body.settings || {};
        const curr_langs = curr_settings.answer_languages
            ? curr_settings.answer_languages
            : [];

        return (
            <div className="col-12">
                <div className="col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.dialogs.info.settings.main_language" />
                        </InputLabel>
                        <Select
                            value={body.language ? body.language : ''}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    language: event.target.value,
                                })
                            }
                            disabled
                        >
                            {languages.map(l => {
                                return (
                                    <MenuItem
                                        key={`dialog_lng_${l.value}`}
                                        value={l.value}
                                    >
                                        <LocaleMessage
                                            msg={`list.languages.${l.main}`}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12 mb-5">
                    <LocaleMessage msg="page.dialogs.info.settings.answer_languages" />
                    <br />
                    <FormCheckList
                        settings={{ list: curr_langs }}
                        options={options}
                        multiple
                        onChange={l => {
                            setBody({
                                ...body,
                                settings: {
                                    ...curr_settings,
                                    answer_languages: l.list,
                                },
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="sidecard-body">
            <div className="row mt-5" style={center}>
                <div className="col-md-4 col-12">{renderLanguages()}</div>
                <div className="col-md-6 col-12 mb-5">
                    <Button
                        className="p-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

DialogSettings.propTypes = {
    reloadSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
