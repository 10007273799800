/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdRefresh } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import { changeGroupRequest } from '~/store/modules/settings/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';
import lng_labels from '~/util/LangMessages';

import checkPermission from '../../util/CheckPermission';
import GroupItem from './GroupItem/index';
import { List, Selected } from './styles';

export default function GroupMenu({ open, toggle, hideMenu }) {
    const { profile, groups } = useSelector(state => state.user || null);
    const settings = useSelector(state => state.settings || null);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels && lng_labels[lang] ? lng_labels[lang] : {};

    const { admin_groups, active } = settings;

    const dispatch = useDispatch();

    const su = checkPermission(profile);

    const list = su ? admin_groups : groups;
    const selected = active || { id: null, name: '' };
    const all_groups = {
        id: '*',
        name: lng_all['message.all_groups.short'],
    };

    function onChangeGroup(g) {
        dispatch(changeGroupRequest(g));
        hideMenu();
    }

    function loadGroups() {
        dispatch(loadGroupsRequest());
    }

    function renderGroupList() {
        return (
            <>
                <div className="jr-card-header d-flex align-items-center">
                    <div
                        style={{
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h4 style={{ marginBottom: '0px' }}>
                            <LocaleMessage msg="message.group_selector.title" />
                        </h4>
                        <Button onClick={() => loadGroups()}>
                            <MdRefresh size={20} />
                        </Button>
                    </div>
                </div>
                <CustomScrollbars
                    className="messages-list scrollbar"
                    style={{ height: 280 }}
                >
                    <ul className="list-unstyled">
                        {su && all_groups ? (
                            <GroupItem
                                group={all_groups}
                                onClick={() => onChangeGroup(all_groups)}
                            />
                        ) : null}
                        {list
                            ? list.map(group => (
                                  <GroupItem
                                      key={group.id}
                                      group={group}
                                      onClick={g => onChangeGroup(g)}
                                  />
                              ))
                            : null}
                    </ul>
                </CustomScrollbars>
            </>
        );
    }

    return (
        <>
            <Dropdown
                className="quick-menu app-notification"
                isOpen={open}
                toggle={() => toggle()}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    <List
                        className="app-notification-menu"
                        style={{
                            padding: '10px 20px',
                            minWidth: '200px',
                            maxWidth: '250px',
                        }}
                    >
                        {selected && selected.id ? (
                            <Selected
                                style={{
                                    marginRight: '10px',
                                }}
                            >
                                {selected.id === '*' ? (
                                    <LocaleMessage msg="message.all_groups.short" />
                                ) : (
                                    selected.name
                                )}
                            </Selected>
                        ) : (
                            <Selected>
                                <i className="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-accounts" />
                                <span
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    <LocaleMessage msg="message.group_selector.title" />
                                </span>
                            </Selected>
                        )}
                    </List>
                </DropdownToggle>

                <DropdownMenu
                    right
                    style={{
                        width: '250px',
                        top: '15px',
                    }}
                >
                    {renderGroupList()}
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

GroupMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
};
