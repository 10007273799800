/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import ShowInvite from '../ShowInvite';
import { BorderedArea, Column } from '../styles';

export default function InviteManager({
    requestError,
    appSettings,
    setToast,
    formatters,
}) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { id, settings } = appSettings;
    const settings_obj = settings.settings || {};

    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const def_lang = date_loc.code.replace('-', '_');

    const [actionList, setActionList] = useState([]);
    const [robotList, setRobotList] = useState({
        available: [],
        unavailable: [],
    });

    const integrations = settings_obj.integrations || {};

    const [body, setBody] = useState({
        robot_id: '',
        duration: 15,
        match_type: 'free',
        language: def_lang,
    });
    const [duration, setDuration] = useState({
        hours: 0,
        minutes: 15,
    });
    const [sendOptions, setSendOptions] = useState({});
    const [fromDate, setFromDate] = useState(new Date());

    const [dialogOpen, setDialogOpen] = useState(false);
    const [generatedURL, setGeneratedURL] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [languages, setLanguages] = useState([]);

    const match_types = [
        {
            value: 'free',
            label: (
                <LocaleMessage msg="page.applications.invite_manager.invites.identification.match.free" />
            ),
        },
        {
            value: 'contain',
            label: (
                <LocaleMessage msg="page.applications.invite_manager.invites.identification.match.contain" />
            ),
        },
        {
            value: 'exact',
            label: (
                <LocaleMessage msg="page.applications.invite_manager.invites.identification.match.exact" />
            ),
        },
    ];

    const hours = [];
    const minutes = [];
    for (let i = 0; i <= 24; i++) {
        hours.push(i);
    }
    const interval = 5;
    const min_values = 60 / 5;
    for (let i = 0; i < min_values; i++) {
        minutes.push(i * interval);
    }

    async function loadLanguages() {
        await api
            .get(`languages/invites`)
            .then(response => {
                setLanguages(response.data);
            })
            .catch(err => requestError(err));
    }

    async function loadFilteredRobots() {
        const groupedList = { available: [], unavailable: [] };
        await api
            .get(`/apps/invite_manager/${id}/robots`)
            .then(response => {
                const list = response.data;
                list.forEach(r => {
                    if (r.hasKey) {
                        groupedList.available.push(r);
                    } else {
                        groupedList.unavailable.push(r);
                    }
                });
                setRobotList(groupedList);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadCommunicationActions() {
        await api
            .get(`pluginspaces/actions?plugin=communication`)
            .then(response => {
                const r = response.data;
                const list = r.filter(a => {
                    return a.status;
                });
                setActionList(list);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadLanguages();
        loadCommunicationActions();
        loadFilteredRobots();
    }, []);

    async function handleSubmit() {
        setIsLoading(true);
        const formattedDate = new Date(fromDate).toISOString();
        const dur = duration.hours * 60 + duration.minutes;
        const invite_data = {
            ...body,
            date: formattedDate,
            duration: dur,
            options: sendOptions,
            timezone: tz,
            identification: {
                required: body.request_identification,
                type: body.identification_type,
                match: {
                    type: body.match_type,
                    expected: body.match_value,
                },
            },
        };

        await api
            .post(`/apps/invite_manager/${id}/invites`, invite_data)
            .then(response => {
                const { data } = response;
                setGeneratedURL(data.url || '');
                setDialogOpen(true);

                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.invite_manager.invites.created" />
                    ),
                });
            })
            .catch(error => requestError(error));
        setIsLoading(false);
        return true;
    }

    function handleCheckboxChange(event, key) {
        const val = event.target.checked;
        setSendOptions({
            ...sendOptions,
            [key]: {
                ...sendOptions[key],
                use: val,
            },
        });
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function buildDialog() {
        return (
            <ShowInvite
                setToast={t => setToast(t)}
                handleDialogClose={handleDialogClose}
                invite={generatedURL}
            />
        );
    }

    function renderActionRow(a) {
        const f_name = a.function_name || '';
        const a_integration = integrations[f_name] || {};
        const a_options = sendOptions[f_name] || {};

        return (
            <>
                {a_integration.active ? (
                    <FormGroup
                        row
                        className="row"
                        style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormControlLabel
                            className="mb-3 col-md-4 col-12"
                            control={
                                <Checkbox
                                    color="primary"
                                    value={a.function_name}
                                    checked={
                                        a_options.use ? a_options.use : false
                                    }
                                    onChange={event =>
                                        handleCheckboxChange(event, f_name)
                                    }
                                />
                            }
                            label={<LocaleMessage msg={a.name} />}
                        />
                        {a_options && a_options.use ? (
                            <TextField
                                className="mb-3 col-md-6 col-12"
                                label={
                                    <LocaleMessage msg="page.applications.invite_manager.invites.to" />
                                }
                                fullWidth
                                value={a_options.to ? a_options.to : ''}
                                onChange={event =>
                                    setSendOptions({
                                        ...sendOptions,
                                        [f_name]: {
                                            ...a_options,
                                            to: event.target.value,
                                        },
                                    })
                                }
                            />
                        ) : null}
                    </FormGroup>
                ) : null}
            </>
        );
    }

    function renderRobotSelection() {
        return (
            <div className="col-md-5 col-12">
                <FormControl className="mb-5" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.invite_manager.invites.robot" />
                    </InputLabel>
                    <Select
                        required
                        value={body.robot_id || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                robot_id: event.target.value,
                            })
                        }
                    >
                        <ListSubheader>
                            <LocaleMessage msg="page.applications.invite_manager.invites.available_robot" />
                        </ListSubheader>
                        {robotList.available.map(r => {
                            return r ? (
                                <MenuItem value={r.id} key={r.id}>
                                    {`[${r.code}] ${r.name}`}
                                </MenuItem>
                            ) : null;
                        })}
                        {robotList.unavailable &&
                        robotList.unavailable.length > 0 ? (
                            <ListSubheader>
                                <LocaleMessage msg="page.applications.invite_manager.invites.unavailable_robot" />
                            </ListSubheader>
                        ) : null}
                        {robotList.unavailable &&
                        robotList.unavailable.length > 0
                            ? robotList.unavailable.map(r => {
                                  return r ? (
                                      <MenuItem
                                          value={r.id}
                                          key={r.id}
                                          disabled
                                      >
                                          {`[${r.code}] ${r.name}`}
                                      </MenuItem>
                                  ) : null;
                              })
                            : null}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function renderLangSelection() {
        return (
            <div className="col-md-5 col-12">
                <FormControl className="mb-5" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.invite_manager.invites.language" />
                    </InputLabel>
                    <Select
                        required
                        value={body.language || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                language: event.target.value,
                            })
                        }
                    >
                        {languages.map(l => {
                            return l ? (
                                <MenuItem value={l.value} key={l.value}>
                                    <LocaleMessage
                                        msg={`list.languages.${l.value}`}
                                    />
                                </MenuItem>
                            ) : null;
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function renderDateAndDuration() {
        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div className="col-md-5 col-12 mb-3">
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={date_loc}
                    >
                        <DateTimePicker
                            label={
                                <LocaleMessage msg="page.applications.invite_manager.invites.date" />
                            }
                            fullWidth
                            variant="inline"
                            inputVariant="outlined"
                            value={fromDate || new Date()}
                            onChange={date => setFromDate(date)}
                            disablePast
                            autoOk
                            ampm={false}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div
                    className="col-md-6 col-12"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FormControl
                        className="col-md-6 col-6 mb-3"
                        variant="outlined"
                    >
                        <InputLabel>
                            <LocaleMessage msg="page.applications.invite_manager.invites.hours" />
                        </InputLabel>
                        <Select
                            fullWidth
                            label={
                                <LocaleMessage msg="page.applications.invite_manager.invites.hours" />
                            }
                            value={duration.hours || 0}
                            onChange={event =>
                                setDuration({
                                    ...duration,
                                    hours: event.target.value,
                                })
                            }
                        >
                            {hours.map(h => {
                                return (
                                    <MenuItem value={h} key={`hours_${h}`}>
                                        {h}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl
                        className="col-md-6 col-6 mb-3"
                        variant="outlined"
                    >
                        <InputLabel>
                            <LocaleMessage msg="page.applications.invite_manager.invites.minutes" />
                        </InputLabel>
                        <Select
                            fullWidth
                            label={
                                <LocaleMessage msg="page.applications.invite_manager.invites.minutes" />
                            }
                            value={
                                duration.minutes !== null &&
                                duration.minutes !== undefined
                                    ? duration.minutes
                                    : 15
                            }
                            onChange={event =>
                                setDuration({
                                    ...duration,
                                    minutes: event.target.value,
                                })
                            }
                        >
                            {minutes.map(m => {
                                return (
                                    <MenuItem value={m} key={`minutes_${m}`}>
                                        {m}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        );
    }

    function renderIdentification() {
        const {
            request_identification,
            identification_type,
            match_type,
        } = body;
        return (
            <div className="col-12 row mb-3">
                <div className="col-12">
                    <FormSwitch
                        value={request_identification || false}
                        onChange={event =>
                            setBody({
                                ...body,
                                request_identification: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.applications.invite_manager.invites.request_identification" />
                        }
                    />
                </div>
                {request_identification ? (
                    <>
                        <div className="col-md-6 col-12 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.invite_manager.invites.identification.type" />
                                </InputLabel>
                                <Select
                                    value={body.identification_type || ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            identification_type:
                                                event.target.value,
                                        })
                                    }
                                >
                                    <MenuItem value="free-identification">
                                        <LocaleMessage msg="page.applications.invite_manager.invites.identification.free" />
                                    </MenuItem>
                                    {formatters.map(f => {
                                        return (
                                            <MenuItem value={f.id} key={f.id}>
                                                {f.formatter}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {identification_type &&
                        identification_type !== 'free-identification' ? (
                            <>
                                <div className="col-md-6 col-12 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="page.applications.invite_manager.invites.identification.match" />
                                        </InputLabel>
                                        <Select
                                            value={body.match_type || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    match_type:
                                                        event.target.value,
                                                })
                                            }
                                        >
                                            {match_types.map(t => {
                                                return (
                                                    <MenuItem
                                                        value={t.value}
                                                        key={t.value}
                                                    >
                                                        {t.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                {match_type === 'exact' ||
                                match_type === 'contain' ? (
                                    <div className="col-12 mb-3">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.applications.invite_manager.invites.identification.expected" />
                                            }
                                            fullWidth
                                            value={body.match_value || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    match_value:
                                                        event.target.value,
                                                })
                                            }
                                            helperText={
                                                <Link to="/tools/info_formatters">
                                                    <LocaleMessage msg="page.applications.invite_manager.invites.identification.expected.helper" />
                                                </Link>
                                            }
                                        />
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }

    function renderInviteCreation() {
        return (
            <BorderedArea className="col-md-8 col-12 mb-5">
                <Column className="col-12 p-3">
                    <div className="area-title">
                        <h4>
                            <LocaleMessage msg="page.applications.invite_manager.invites.create_invite" />
                        </h4>
                    </div>
                    <div
                        className="row"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {renderRobotSelection()}
                        {renderLangSelection()}
                    </div>
                    {renderDateAndDuration()}
                    {renderIdentification()}
                </Column>
            </BorderedArea>
        );
    }

    function renderDestination() {
        return (
            <BorderedArea className="col-md-8 col-12 mb-5">
                <Column className="col-12 p-3">
                    <div className="area-title">
                        <h4>
                            <LocaleMessage msg="page.applications.invite_manager.invites.send" />
                        </h4>
                    </div>
                    <FormControl fullWidth component="fieldset">
                        <>
                            {actionList.map(a => (
                                <div key={a.id}>{renderActionRow(a)}</div>
                            ))}
                        </>
                    </FormControl>
                </Column>
                <div className="config-area" />
            </BorderedArea>
        );
    }

    return (
        <div className="col-12 mb-3 mt-5">
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {dialogOpen ? buildDialog() : null}

                        <Column>
                            {renderInviteCreation()}
                            {renderDestination()}
                            <Button
                                className="col-md-8 col-12 p-3"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={event => handleSubmit(event)}
                            >
                                <LocaleMessage msg="page.applications.invite_manager.invites.send" />
                            </Button>
                        </Column>
                    </>
                )}
            </div>
        </div>
    );
}

InviteManager.defaultProps = {
    requestError: () => {},
    setToast: () => {},
    formatters: [],
};

InviteManager.propTypes = {
    setToast: PropTypes.func,
    requestError: PropTypes.func,
    appSettings: PropTypes.object.isRequired,
    formatters: PropTypes.array,
};
