/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    MdClose,
    MdExpandMore,
    MdExpandLess,
    MdAddCircle,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { IconParameter } from '../styles';

export default function QnANodeContext({ settings, saveSettings }) {
    const [show, setShow] = useState(
        settings && settings.items && settings.items.length > 0
    );

    function setItems(list) {
        saveSettings({
            ...settings,
            items: [...list],
        });
    }

    function addOption() {
        setShow(true);
        const list = settings.items || [];
        const item_id = (+new Date()).toString(36);
        list.push({ id: item_id, key: '', value: '' });
        setItems(list);
    }

    function editOption(item_id, update) {
        const list = settings.items || [];

        if (item_id) {
            const idx = list.findIndex(i => {
                return i.id === item_id;
            });
            list[idx] = update;
        }

        setItems([...list]);
    }

    function removeOptions(_id) {
        const list = settings.items || [];
        const filtered = list.filter(c => {
            return c.id !== _id;
        });
        setItems(filtered);
    }

    function renderContextItem(item) {
        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <TextField
                    className="mb-1 col-4"
                    label={<LocaleMessage msg="page.qna.context.form.key" />}
                    fullWidth
                    value={item.key || ''}
                    onChange={e => {
                        editOption(item.id, {
                            ...item,
                            key: e.target.value,
                        });
                    }}
                />
                <TextField
                    className="mb-1 col-6"
                    label={<LocaleMessage msg="page.qna.context.form.value" />}
                    fullWidth
                    value={item.value || ''}
                    onChange={e => {
                        editOption(item.id, {
                            ...item,
                            value: e.target.value,
                        });
                    }}
                />
                <IconParameter className="mb-1 col-1">
                    <MdClose size={20} onClick={() => removeOptions(item.id)} />
                </IconParameter>
            </div>
        );
    }

    function renderContextList() {
        const list = settings.items || [];

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {list.map(item => renderContextItem(item))}
            </div>
        );
    }

    return (
        <>
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter className="col-1">
                    {show ? (
                        <MdExpandLess
                            size={22}
                            onClick={() => setShow(false)}
                        />
                    ) : (
                        <MdExpandMore size={22} onClick={() => setShow(true)} />
                    )}
                </IconParameter>
                <strong>
                    <LocaleMessage msg="page.qna.nodes.form.answer.context" />
                </strong>
                <IconParameter className="col-1">
                    <MdAddCircle size={22} onClick={() => addOption()} />
                </IconParameter>
            </div>
            {show ? renderContextList() : null}
        </>
    );
}

QnANodeContext.defaultProps = {
    settings: {},
};

QnANodeContext.propTypes = {
    settings: PropTypes.object,
    saveSettings: PropTypes.func.isRequired,
};
