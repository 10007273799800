/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdOutlineFileDownload } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkAdmin from '~/util/CheckAdmin';
import generateDownloadableFile from '~/util/GenerateDownloadableFile';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';

const PAGE_VIEW_KEY = 'scripts';
const PAGE_VIEW_DEF = 'grid';

export default function Scripts() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const { profile } = useSelector(state => state.user || null);

    const super_user = checkAdmin(settings, profile);

    const date_loc = getDateLocale(settings);
    const { active } = settings;
    const allowNew = !(active && active.id === '*');

    const [isLoading, setIsLoading] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [view, setView] = useState(
        page_settings[PAGE_VIEW_KEY] || PAGE_VIEW_DEF
    );

    const [scripts, setScripts] = useState([]);
    const [filteredData, setFilteredData] = useState(scripts);

    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`scripts`)
            .then(response => {
                const data = response.data.map(s => {
                    const created = new Date(s.created);
                    const updated = new Date(s.updated);
                    const robot_list = s.robot_types;
                    let robots = '';
                    robot_list.forEach((r, idx) => {
                        if (idx !== 0) {
                            robots += ` / `;
                        }
                        robots += `${r.name}`;
                    });

                    return {
                        ...s,
                        robots,
                        created: formatDistance(created, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        created_timestamp: created.toISOString(),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        group_name: (s.group && s.group.name) || (
                            <LocaleMessage msg="message.all_groups.short" />
                        ),
                        robot_type: s.robot ? s.robot.name : '---',
                    };
                });
                setScripts(data);
            })
            .catch(error => requestError(error));
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadItems();
    }, [active, date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`/tools/scripts/${_id}`);
    }

    async function handleExportScript(event, _id) {
        setAnchorEl(null);
        setIsLoading(true);
        const currScript = scripts.find(s => {
            return s.id === _id;
        });
        toast.info(<LocaleMessage msg="message.generating_file" />);
        const filename = GetFileName(`SCRIPT-${currScript.name}`, 'json');

        await api
            .get(`scripts/${_id}/export`)
            .then(async response => {
                const config = response.data;
                generateDownloadableFile(event, {
                    filename,
                    data: config,
                });
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/tools/scripts/${id}`);
    }

    async function deleteScript() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`scripts/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.scripts.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function scriptCard(s) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={s.id}>
                <Link to={`/tools/scripts/${s.id}`}>
                    <CardItem
                        title={s.name}
                        optionsClick={event => handleClick(event, s.id)}
                        updated={s.updated}
                        position="center"
                        group={s.group_name}
                        open_all={s.open_all}
                    >
                        <>
                            <div className="card-subtitle">
                                <small>{s.robots}</small>
                            </div>
                            <p
                                className="card-text"
                                style={{ minHeight: '30px' }}
                            >
                                <small>{s.description}</small>
                            </p>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {filteredData.map(s => scriptCard(s))}
                </div>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleExportScript(e, currItem)}>
                        <LocaleMessage msg="button.download_file" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={
                    <LocaleMessage msg="page.tools.scripts.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteScript(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'robots',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'export',
                label: <LocaleMessage msg="button.export_settings" />,
                icon: <MdOutlineFileDownload />,
                action: handleExportScript,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <>
                <div
                    className="mb-5"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        orderColumn="name"
                        setCurrItem={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.tools.scripts.list.title" />}
            currentPage={<LocaleMessage msg="page.tools.scripts.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div
                    className="body-top-controls"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="col-3 pl-0">
                        <NewItemButton
                            disabled={!allowNew && !super_user}
                            link="/tools/scripts/new"
                            text={
                                <LocaleMessage msg="page.tools.scripts.list.add" />
                            }
                        />
                    </div>
                    <div className="col-3">
                        <DataFilter
                            rawData={scripts}
                            headerColumns={['name', 'robot_type']}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_VIEW_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
