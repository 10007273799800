/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Button } from '@material-ui/core';

import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';

const headersFile = [
    { key: 'name', label: 'Name' },
    { key: 'subdomain', label: 'Subdomain' },
    { key: 'active', label: 'Is Active' },
    { key: 'language', label: 'Main Language' },
    {
        key: 'created_date',
        label: 'Creation Date',
    },
    {
        key: 'created_time',
        label: 'Creation Time',
    },
];

export default function Pluginspaces() {
    const dispatch = useDispatch();
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    const [isLoading, setIsLoading] = useState(true);
    const [pluginspaces, setPluginspaces] = useState([]);
    const [filteredData, setFilteredData] = useState(pluginspaces);

    const headerCells = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        {
            id: 'subdomain',
            label: <LocaleMessage msg="table.headers.subdomain" />,
        },
        {
            id: 'language',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'status',
            label: <LocaleMessage msg="table.headers.status" />,
            order_by: 'active',
        },
        {
            id: 'created_date',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadPluginspaces() {
        setIsLoading(true);
        await api
            .get(`admin/pluginspaces`)
            .then(response => {
                const data = response.data.map(p => {
                    const created = new Date(p.created);
                    const updated = new Date(p.updated);
                    const c_time = created.toLocaleTimeString([], time_opt);
                    const u_time = updated.toLocaleTimeString([], time_opt);
                    return {
                        ...p,
                        status: p.active ? (
                            <LocaleMessage msg="info.status.active" />
                        ) : (
                            <LocaleMessage msg="info.status.inactive" />
                        ),
                        created_date: created.toLocaleDateString(
                            _settings.locale.format,
                            date_opt
                        ),
                        created_time: c_time,
                        created_timestamp: created.toISOString(),
                        updated_distance: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_date: updated.toLocaleDateString(
                            _settings.locale.format,
                            date_opt
                        ),
                        updated_time: u_time,
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setPluginspaces(data);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadPluginspaces();
    }, [date_loc]);

    const [currPluginspace, setCurrPluginspace] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    function handleClickOpen(event, _id) {
        setCurrPluginspace(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrPluginspace(id);
        history.push(`/admin/pluginspaces/${id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deletePluginspace() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`admin/pluginspaces/${currPluginspace}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.list.delete_success" />
                );
                await loadPluginspaces();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.su_pluginspace.list.delete" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deletePluginspace(currPluginspace),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headerCells}
                    data={filteredData}
                    orderColumn="name"
                    setcurrPluginspace={_id => setCurrPluginspace(_id)}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div className="col-3" style={{ padding: '0px' }}>
                                <NewItemButton
                                    link="/admin/pluginspaces/new"
                                    text={
                                        <LocaleMessage msg="page.su_pluginspace.list.add" />
                                    }
                                />
                            </div>
                            <div className="col-3">
                                <DataFilter
                                    rawData={pluginspaces}
                                    headerColumns={['name', 'subdomain']}
                                    returnFilteredData={f_data =>
                                        setFilteredData(f_data)
                                    }
                                />
                            </div>
                            <div className="col-3" style={{ padding: '0px' }}>
                                <CSVLink
                                    data={pluginspaces}
                                    headers={headersFile}
                                    filename={GetFileName(
                                        `admin_analytics_pluginspaces`,
                                        'csv'
                                    )}
                                    onClick={() => {
                                        toast.info(
                                            <LocaleMessage msg="message.generating_file" />
                                        );
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            <MdFileDownload
                                                style={{ color: '#fff' }}
                                            />
                                        }
                                    >
                                        <span style={{ color: '#fff' }}>
                                            <LocaleMessage msg="button.export_raw" />
                                        </span>
                                    </Button>
                                </CSVLink>
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.su_pluginspace.title" />}
            currentPage={<LocaleMessage msg="page.su_pluginspace.title" />}
            breadcrumbs={[
                {
                    url: '/admin/analytics',
                    title: <LocaleMessage msg="breadcrumbs.analytics" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}

                <>{buildListView()}</>
            </>
        </PageContent>
    );
}
