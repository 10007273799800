/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
    MdCollections,
    MdVideoLibrary,
    MdSettings,
    MdLibraryBooks,
    MdFace,
    MdDirectionsWalk,
    MdDelete,
    MdCheck,
    MdClose,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CarouselDialog from '~/components/CarouselDialog';
import DataTable from '~/components/DataTable';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkPermission from '~/util/CheckPermission';

const gallery_types = {
    faces: {
        value: 'faces',
        icon: MdFace,
    },
    images: {
        value: 'images',
        icon: MdCollections,
    },
    audios: {
        value: 'audios',
        icon: MdVideoLibrary,
    },
    movements: {
        value: 'movements',
        icon: MdDirectionsWalk,
    },
    scripts: {
        value: 'scripts',
        icon: MdLibraryBooks,
    },
    dances: {
        value: 'dances',
        icon: MdDirectionsWalk,
    },
};

const status_list = [
    {
        value: 'unavailable',
        label: <LocaleMessage msg="list.status.unavailable" />,
    },
    {
        value: 'development',
        label: <LocaleMessage msg="list.status.development" />,
    },
    {
        value: 'testing',
        label: <LocaleMessage msg="list.status.testing" />,
    },
    {
        value: 'available',
        label: <LocaleMessage msg="list.status.available" />,
    },
];

const iconSize = 18;

export default function GalleryForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id, content_id } = match.params;
    const def_body = { type: 'images', free: false, visible: true };

    const [body, setBody] = useState(def_body);
    const [fileBody, setFileBody] = useState({});
    const [operation, setOperation] = useState('create');

    const [contentList, setContentList] = useState([]);
    const [availableList, setAvailableList] = useState([]);

    const [carouselOpen, setCarouselOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [currItem, setCurrItem] = useState();
    const [currIndex, setCurrIndex] = useState(0);
    const [currentForm, setCurrentForm] = useState(null);

    const [objOperation, setObjOperation] = useState('create');
    const [objBody, setObjBody] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const { profile } = useSelector(state => state.user || null);

    const master_user = checkPermission(profile, ['master']);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        await api
            .get(`types/${id}/albums/${content_id}/content`)
            .then(response => {
                const items = response.data;
                setContentList(items);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableMovements(movement_type) {
        setIsLoading(true);
        await api
            .get(
                `movements?type=${id}&movement_type=${movement_type}&status=all`
            )
            .then(response => {
                const data = response.data.map(m => ({
                    ...m,
                }));
                setAvailableList(data);
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableScripts() {
        setIsLoading(true);
        await api
            .get(`scripts?open=true`)
            .then(response => {
                const data = response.data.map(s => ({
                    ...s,
                }));
                setAvailableList(data);
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableObjects(type) {
        switch (type) {
            case 'movements':
                return loadAvailableMovements('preset');
            case 'dances':
                return loadAvailableMovements('sequence');
            case 'scripts':
                return loadAvailableScripts();
            default:
                return null;
        }
    }

    async function loadGallery(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`albums/${_id}`)
                .then(response => {
                    const g = response.data;
                    setBody({
                        name: g.name,
                        description: g.description,
                        type: g.type,
                        free: g.free,
                        visible: g.visible,
                        status: g.status,
                    });
                    loadItems();
                    loadAvailableObjects(g.type);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadObject(_id) {
        if (_id === 'new') {
            setObjOperation('create');
            setObjBody({ free: true });
        } else {
            setObjOperation('update');
            const currObj = contentList.find(c => {
                return c.id === _id;
            });

            if (!currObj) {
                setObjBody({ free: true });
            } else {
                setObjBody({
                    free: currObj.free,
                    object_id: currObj.object_id,
                });
            }
        }
    }

    useEffect(() => {
        loadGallery(content_id);
    }, [content_id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`types/${id}/albums`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.robot_types.defaults.list.create_success" />
                    );

                    history.push(
                        `/robotdefaults/${id}/albums/${response.data.id}`
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`types/${id}/albums/${content_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robot_types.defaults.list.update_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    async function handleObjSubmit(event) {
        event.preventDefault();
        setCurrentForm(null);
        setIsLoading(true);
        const data = { ...objBody };

        if (objOperation === 'create') {
            await api
                .post(`types/${id}/albums/${content_id}/content`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robot_types.defaults.form.media_success" />
                    );
                    loadItems();
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(
                    `types/${id}/albums/${content_id}/content/${currItem}`,
                    data
                )
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.robot_types.defaults.form.media_updated" />
                    );
                    loadItems();
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    async function onFileUpload(e) {
        const fileList = e.target.files;
        const data = new FormData();

        setIsLoading(true);

        Array.from(fileList).forEach(file => {
            data.append('files', file);
        });

        await api
            .post(`types/${id}/albums/${content_id}/files`, data)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.robot_types.defaults.form.media_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
    }

    async function deleteItem(item_id) {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        setCurrIndex(0);
        setCarouselOpen(false);
        await api
            .delete(`types/${id}/albums/${content_id}/content/${item_id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.robot_types.defaults.form.media_deleted" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
    }

    async function editItem(item_id) {
        setIsLoading(true);
        setEditDialogOpen(false);
        await api
            .put(
                `types/${id}/albums/${content_id}/content/${item_id}`,
                fileBody
            )
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.robot_types.defaults.form.media_updated" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
    }

    function handleFormClose(event) {
        event.preventDefault();
        setCurrentForm(null);
    }

    function handleObjectClick(event, _id, form) {
        event.preventDefault();
        setCurrItem(_id);
        loadObject(_id);
        setCurrentForm(form);
    }

    function handleDeleteDialogClose(event) {
        event.stopPropagation();
        setCarouselOpen(false);
        setDeleteDialogOpen(false);
    }

    function handleEditDialogClose(event) {
        event.stopPropagation();
        setEditDialogOpen(false);
    }

    function handleItemClick(event, index) {
        setCurrItem(index);
        setCurrIndex(index);
        setDeleteDialogOpen(false);
        setCarouselOpen(true);
    }

    function handleClose() {
        setCarouselOpen(false);
    }

    function handleDeleteClick(event, index) {
        event.stopPropagation();
        setCurrItem(index);
        setDeleteDialogOpen(true);
    }

    function handleEditClick(event, index) {
        event.stopPropagation();
        setCurrItem(index);
        const originalFile = contentList.find(m => {
            return m.id === index;
        });
        setFileBody({
            name: originalFile.name,
        });

        setEditDialogOpen(true);
    }

    function buildEditDialog() {
        return (
            <SimpleDialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                title={
                    <LocaleMessage msg="page.robot_types.defaults.form.label.rename" />
                }
                content={
                    <TextField
                        autoFocus
                        label={
                            <LocaleMessage msg="page.robot_types.defaults.form.label.file_name" />
                        }
                        type="text"
                        fullWidth
                        value={fileBody.name || ''}
                        onChange={event =>
                            setFileBody({
                                ...fileBody,
                                name: event.target.value,
                            })
                        }
                    />
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setEditDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'save',
                        onClick: () => editItem(currItem),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={<LocaleMessage msg="message.file.delete.title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderFileList(type) {
        const view_type = type.value === 'audios' ? 'audios' : 'images';

        return (
            <div className="col-md-6 col-12">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.robot_types.defaults.form.media_title" />
                    }
                    Icon={type && type.icon ? type.icon : MdCollections}
                    hide
                >
                    <GalleryView
                        media={contentList}
                        allowNew
                        allowDelete={false}
                        fileUpload={e => onFileUpload(e)}
                        multiple
                        deleteFile={(e, _id) => handleDeleteClick(e, _id)}
                        onClick={(e, _id) => handleItemClick(e, _id)}
                        type={view_type}
                    />
                </CardSideBordered>
            </div>
        );
    }

    function buildObjectFormDialog() {
        return (
            <Dialog
                open={!!currentForm}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    {objOperation === 'create' ? (
                        <LocaleMessage msg="page.robot_types.defaults.form.create_content.title" />
                    ) : (
                        <LocaleMessage msg="page.robot_types.defaults.form.edit_content.title" />
                    )}
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.robot_types.defaults.form.media_title" />
                                </InputLabel>
                                <Select
                                    value={
                                        objBody && objBody.object_id
                                            ? objBody.object_id
                                            : ''
                                    }
                                    onChange={event =>
                                        setObjBody({
                                            ...objBody,
                                            object_id: event.target.value,
                                        })
                                    }
                                    disabled={objOperation === 'update'}
                                >
                                    {availableList.map(g => (
                                        <MenuItem value={g.id} key={g.id}>
                                            {g.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 col-12 mb-5">
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={
                                                    objBody && objBody.free
                                                        ? objBody.free
                                                        : false
                                                }
                                                value="free"
                                                onChange={event =>
                                                    setObjBody({
                                                        ...objBody,
                                                        free:
                                                            event.target
                                                                .checked,
                                                    })
                                                }
                                            />
                                        }
                                        label={
                                            <LocaleMessage msg="page.robot_types.defaults.form.label.free" />
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleObjSubmit(event)}
                        color="primary"
                    >
                        {objOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildObjectList(headers, list, type, label) {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteClick,
            },
        ];

        return (
            <>
                <Button
                    className="p-3 mb-3"
                    variant="contained"
                    color="primary"
                    onClick={event => handleObjectClick(event, 'new', type)}
                    fullWidth
                >
                    {label}
                </Button>
                <DataTable
                    headerColumns={headers}
                    data={list}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleObjectClick(event, _id, type)
                    }
                    rowActions={rowActions}
                />
            </>
        );
    }

    function renderDancesList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'status',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'action_type',
                label: <LocaleMessage msg="table.headers.movement_type" />,
            },
            {
                id: 'free_to_use',
                label: <LocaleMessage msg="table.headers.free" />,
            },
        ];

        const list = contentList.map(m => {
            const movement = m && m.object ? m.object : {};
            return {
                id: m.id,
                free: m.free,
                movement_id: m.object_id,
                name: movement.name,
                status: movement.status,
                action_type: movement.action_type,
                free_to_use: m.free ? (
                    <MdCheck size={iconSize} />
                ) : (
                    <MdClose size={iconSize} />
                ),
            };
        });

        return (
            <div className="col-md-6 col-12">
                {buildObjectList(
                    headCells,
                    list,
                    'movements',
                    'Add Dance Move'
                )}
            </div>
        );
    }

    function renderMovementList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'status',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'action_type',
                label: <LocaleMessage msg="table.headers.movement_type" />,
            },
            {
                id: 'free_to_use',
                label: <LocaleMessage msg="table.headers.free" />,
            },
        ];

        const list = contentList.map(m => {
            const movement = m && m.object ? m.object : {};
            return {
                id: m.id,
                free: m.free,
                movement_id: m.object_id,
                name: movement.name,
                status: movement.status,
                action_type: movement.action_type,
                free_to_use: m.free ? (
                    <MdCheck size={iconSize} />
                ) : (
                    <MdClose size={iconSize} />
                ),
            };
        });

        return (
            <div className="col-md-6 col-12">
                {buildObjectList(
                    headCells,
                    list,
                    'movements',
                    <LocaleMessage msg="page.robot_types.defaults.form.label.content.add_movement" />
                )}
            </div>
        );
    }

    function renderScriptList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'free_to_use',
                label: <LocaleMessage msg="table.headers.free" />,
            },
        ];

        const list = contentList.map(m => {
            const script = m.object;
            return {
                id: m.id,
                free: m.free,
                application_content_id: m.object_id,
                name: script.name,
                free_to_use: m.free ? (
                    <MdCheck size={iconSize} />
                ) : (
                    <MdClose size={iconSize} />
                ),
            };
        });
        return (
            <div className="col-md-6 col-12">
                {buildObjectList(
                    headCells,
                    list,
                    'movements',
                    <LocaleMessage msg="page.robot_types.defaults.form.label.content.add_script" />
                )}
                <Button
                    className="p-3 mt-3"
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/tools/scripts')}
                    fullWidth
                >
                    <LocaleMessage msg="page.robot_types.defaults.form.label.content.see_scripts" />
                </Button>
            </div>
        );
    }

    function renderContentListView() {
        const { type } = body;
        const type_obj = gallery_types[body.type];
        switch (type) {
            case 'images':
            case 'audios':
            case 'faces':
                return renderFileList(type_obj);
            case 'dances':
                return renderDancesList();
            case 'movements':
                return renderMovementList();
            case 'scripts':
                return renderScriptList();
            default:
                return <></>;
        }
    }

    function renderContentListCarousel() {
        const view_type = body.type === 'audios' ? 'audios' : 'images';
        return (
            <CarouselDialog
                allowed={{ edit: true, delete: true }}
                type={view_type}
                open={carouselOpen}
                media={contentList}
                current={currIndex}
                editFile={(e, _id) => handleEditClick(e, _id)}
                deleteFile={(e, _id) => handleDeleteClick(e, _id)}
                handleCarouselClose={e => handleClose(e)}
            />
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.robot_types.defaults.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.robot_types.defaults.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/robotdefaults',
                    title: <LocaleMessage msg="breadcrumbs.robot_types" />,
                },
                {
                    url: `/robotdefaults/${id}/albums`,
                    title: (
                        <LocaleMessage msg="page.robot_types.defaults.list.title" />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <div className="row">
                <div className="col-md-6 col-12 mb-5">
                    <form
                        className="row full-body"
                        noValidate
                        autoComplete="off"
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.robot_types.defaults.form.label.settings" />
                            }
                            Icon={MdSettings}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="gallery-name"
                                            label={
                                                <LocaleMessage msg="page.robot_types.defaults.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.robot_types.defaults.form.label.type" />
                                            </InputLabel>
                                            <Select
                                                id="type"
                                                value={
                                                    body.type
                                                        ? body.type
                                                        : 'images'
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        type:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    !master_user &&
                                                    operation === 'update'
                                                }
                                            >
                                                {Object.keys(gallery_types).map(
                                                    t => {
                                                        const type =
                                                            gallery_types[t];
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    type.value
                                                                }
                                                                key={type.value}
                                                            >
                                                                <LocaleMessage
                                                                    msg={`list.galleries.type.${type.value}`}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12 col-12 mb-5">
                                        <TextField
                                            id="gallery-description"
                                            label={
                                                <LocaleMessage msg="page.robot_types.defaults.form.label.description" />
                                            }
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl
                                            className="mb-3"
                                            component="fieldset"
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                body &&
                                                                body.free
                                                                    ? body.free
                                                                    : false
                                                            }
                                                            value="free"
                                                            onChange={event =>
                                                                setBody({
                                                                    ...body,
                                                                    free:
                                                                        event
                                                                            .target
                                                                            .checked,
                                                                })
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.robot_types.defaults.form.label.free" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={
                                                                body &&
                                                                body.visible
                                                                    ? body.visible
                                                                    : false
                                                            }
                                                            value="visible"
                                                            onChange={event =>
                                                                setBody({
                                                                    ...body,
                                                                    visible:
                                                                        event
                                                                            .target
                                                                            .checked,
                                                                })
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <LocaleMessage msg="page.robot_types.defaults.form.label.visible" />
                                                    }
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="label.status" />
                                            </InputLabel>
                                            <Select
                                                id="status"
                                                value={
                                                    body.status
                                                        ? body.status
                                                        : 'available'
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        status:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                {Object.keys(status_list).map(
                                                    s => {
                                                        const status =
                                                            status_list[s];
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    status.value
                                                                }
                                                                key={
                                                                    status.value
                                                                }
                                                            >
                                                                {status.label}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </form>
                </div>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                {editDialogOpen ? buildEditDialog() : null}
                {currentForm ? buildObjectFormDialog() : null}
                {operation === 'update' ? renderContentListView() : null}
                {operation === 'update' && carouselOpen
                    ? renderContentListCarousel()
                    : null}
            </div>
        </PageContent>
    );
}

GalleryForm.propTypes = {
    match: PropTypes.object.isRequired,
};
