/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';

import { TotalContainer, ChartsContainer, RowArea } from '../../style';
import Filter from './FilterData';

export default function MapNavigations({
    settings,
    headerSettings,
    requestError,
    counterData,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, locale } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [groupedData, setGroupedData] = useState({});

    const headers_table = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application_name',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'dialog_name',
            label: <LocaleMessage msg="table.headers.dialog" />,
        },
        {
            id: 'map_name',
            label: <LocaleMessage msg="table.headers.map_name" />,
        },
        {
            id: 'point_name',
            label: <LocaleMessage msg="table.headers.point_name" />,
        },
    ];

    const headers_file = [
        { key: 'group_name', label: 'Group' },
        { key: 'robot_name', label: 'Robot' },
        { key: 'application_name', label: 'Application' },
        { key: 'dialog_name', label: 'Dialog' },
        { key: 'map_name', label: 'Map' },
        { key: 'point_name', label: 'Point' },
        {
            key: 'created_date',
            label: 'Start Date',
        },
        {
            key: 'created_time',
            label: 'Start Time',
        },
    ];

    async function loadData() {
        setIsLoading(true);

        await api
            .get(
                `reports/pluginlogs?action=mapNavigation&from=${fromISO}&until=${untilISO}`
            )
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function renderCSVButton() {
        return (
            <ExportCSVButton
                data={groupedData.data}
                headers={headers_file}
                filename={GetFileName(`REPORT-action_map_navigation`, 'csv')}
            />
        );
    }

    useEffect(() => {
        if (rawData) {
            setGroupedData(Filter(counterData, rawData, settings));
        } else {
            setGroupedData({});
        }
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'dialogs',
            title: 'per_dialog',
            color: colors.dashboard_graphs,
            data: groupedData.dialogs,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'map',
            title: 'per_map',
            color: colors.dashboard_cards,
            data: groupedData.maps,
        },
        {
            key: 'location',
            title: 'per_location',
            color: colors.dashboard_graphs,
            data: groupedData.points,
        },
    ];

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={renderCSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.actions.map_nav.label.total" />
                                }
                                value={groupedData.total}
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.actions.map_nav.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.actions.map_nav.label.per_s_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.actions.map_nav.label.per_s_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        <RowArea className="mt-3 mb-5">
                            <div>
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.actions.map_nav.label.history" />
                                </p>
                            </div>
                            <div
                                style={{
                                    minHeight: '200px',
                                    width: '100%',
                                    padding: '0px 15px',
                                }}
                            >
                                <DataTable
                                    headerColumns={headers_table}
                                    data={groupedData.data || []}
                                    orderDirection="desc"
                                    orderColumn="timestamp"
                                    handleTableRowClick={() => {}}
                                    hasActions={false}
                                    sortable
                                    hasFilter
                                />
                            </div>
                        </RowArea>
                    </>
                )}
            </div>
        </>
    );
}

MapNavigations.defaultProps = {};

MapNavigations.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
};
