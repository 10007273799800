/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDvr, MdSatellite, MdDoneAll, MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Typography } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function RC3Settings() {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [serviceInfo, setServiceInfo] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadServiceInfo() {
        await api
            .get(`rc3/server`)
            .then(response => {
                const { data } = response;
                setServiceInfo(data);
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    async function setupEventsRule() {
        setIsLoading(true);
        await api
            .post(`rc3/server/rule`)
            .then(async () => {
                await loadServiceInfo();
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    async function setupEventsDestination(rule) {
        setIsLoading(true);
        await api
            .post(`rc3/server/destination`, { rule })
            .then(async () => {
                await loadServiceInfo();
            })
            .catch(err => requestError(err));
        setIsLoading(false);
    }

    useEffect(() => {
        loadServiceInfo();
    }, []);

    function renderInfo({ key, label, value }) {
        return (
            <div className="mb-3" key={key}>
                <Typography variant="caption" display="block" gutterBottom>
                    {label}
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                    {value}
                </Typography>
            </div>
        );
    }

    function renderEnvironmentEventSettings(rule) {
        return (
            <div
                key={`rule_${rule.name}`}
                className="mb-3"
                style={{
                    border: '1px solid #999',
                    borderRadius: '3px',
                    padding: '15px',
                }}
            >
                <Typography variant="body1" display="block" gutterBottom>
                    {rule.name || '---'}
                </Typography>

                {rule.destination ? (
                    <div className="row">
                        <div className="col-1">
                            {rule.destination.status &&
                            rule.destination.status === 'ENABLED' ? (
                                <MdDoneAll size={24} />
                            ) : (
                                <MdClose size={24} />
                            )}
                        </div>
                        <Typography
                            className="col-11"
                            variant="body1"
                            display="block"
                            gutterBottom
                        >
                            {rule.destination.url || '---'}
                        </Typography>
                    </div>
                ) : null}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setupEventsDestination(rule.name)}
                    fullWidth
                >
                    <LocaleMessage msg="page.rc3_settings.events.destination.setup" />
                </Button>
            </div>
        );
    }

    const infos = [
        {
            key: 'address',
            label: <LocaleMessage msg="page.rc3_settings.server.address" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.server
                    ? serviceInfo.rc3.server
                    : '',
        },
        {
            key: 'service',
            label: <LocaleMessage msg="page.rc3_settings.user.service" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.user_config
                    ? 'true'
                    : 'false',
        },
        {
            key: 'user_pool',
            label: <LocaleMessage msg="page.rc3_settings.user.pool" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.user_pool
                    ? serviceInfo.rc3.user_pool
                    : '---',
        },
        {
            key: 'identity_pool',
            label: <LocaleMessage msg="page.rc3_settings.identity.pool" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.identity_pool
                    ? serviceInfo.rc3.identity_pool
                    : '---',
        },
        {
            key: 'hive_policy',
            label: <LocaleMessage msg="page.rc3_settings.hive.policy" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.hive_policy
                    ? serviceInfo.rc3.hive_policy
                    : '---',
        },
        {
            key: 'hive_identity',
            label: <LocaleMessage msg="page.rc3_settings.hive.identity" />,
            value:
                serviceInfo.rc3 && serviceInfo.rc3.hive_identity
                    ? serviceInfo.rc3.hive_identity
                    : '---',
        },
    ];

    return (
        <PageContent
            title="RC3 Settings"
            currentPage="RC3 Settings"
            breadcrumbs={[]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="row full-body">
                    {serviceInfo ? (
                        <>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.rc3_settings.rc3.status" />
                                    }
                                    Icon={MdSatellite}
                                    hide
                                >
                                    <div style={{ padding: '15px' }}>
                                        {infos.map(i => {
                                            return renderInfo(i);
                                        })}
                                    </div>
                                </CardSideBordered>
                            </div>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.rc3_settings.events.status" />
                                    }
                                    Icon={MdDvr}
                                    hide
                                >
                                    <div style={{ padding: '15px' }}>
                                        {renderInfo({
                                            key: 'events_topic',
                                            label: (
                                                <LocaleMessage msg="page.rc3_settings.events.topic" />
                                            ),
                                            value:
                                                serviceInfo.events &&
                                                serviceInfo.events.topic
                                                    ? serviceInfo.events.topic
                                                    : '---',
                                        })}
                                        <div>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                                gutterBottom
                                            >
                                                <LocaleMessage msg="page.rc3_settings.events.rules" />
                                            </Typography>
                                            {serviceInfo.events &&
                                            serviceInfo.events.rules &&
                                            serviceInfo.events.rules[0] ? (
                                                serviceInfo.events.rules.map(
                                                    rule => {
                                                        return renderEnvironmentEventSettings(
                                                            rule
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <div className="mb-3">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            setupEventsRule()
                                                        }
                                                        fullWidth
                                                    >
                                                        <LocaleMessage msg="page.rc3_settings.events.rule.setup" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardSideBordered>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}
