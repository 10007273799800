/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSpellcheck, MdFormatShapes, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function ExtractionForm(props) {
    const dispatch = useDispatch();
    const { match } = props;

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const extractor_id = match.params.id;
    const [operation, setOperation] = useState('create');

    const [providers, setProviders] = useState([]);
    const [formatters, setFormatters] = useState([]);

    const [body, setBody] = useState({
        name: '',
        group: 'extraction',
    });

    const [options, setOptions] = useState({
        type: '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadProviders() {
        await api
            .get(`extractions/providers`)
            .then(response => {
                const p = [
                    {
                        label: (
                            <LocaleMessage msg="page.extractions.form.select_provider" />
                        ),
                        value: null,
                        locations: [],
                    },
                ];
                response.data.forEach(prov => {
                    p.push(prov);
                });

                setProviders(p);
            })
            .catch(err => requestError(err));
    }

    async function loadFormatters() {
        await api
            .get(`info_formatters/public?extractor_id=${extractor_id}`)
            .then(response => {
                const data = response.data.map(f => {
                    const updated = new Date(f.updated);
                    return {
                        ...f,
                        name: (
                            <LocaleMessage
                                msg={`tools.info_formatters.${f.name}`}
                            />
                        ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setFormatters(data);
            })
            .catch(err => requestError(err));
    }

    async function loadType(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`types/${_id}`)
                .then(response => {
                    const t = response.data;
                    setBody({
                        name: t.name,
                        slug: t.slug,
                    });
                    setOptions(t.settings);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadProviders();
    }, []);

    useEffect(() => {
        loadType(extractor_id);
        loadFormatters();
    }, [extractor_id]);

    async function updateType(data) {
        await api
            .put(`types/${extractor_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.extractions.form.update_success" />
                );
                setIsLoading(false);
                loadType(extractor_id);
            })
            .catch(error => requestError(error));
    }

    async function deleteType() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`info_formatters/public/${currItem}`)
            .then(async () => {
                // toast.success(
                //     <LocaleMessage msg="page.extractions.list.delete_success" />
                // );
                await loadFormatters();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/extractions/${extractor_id}/public_formatter/${id}`);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, options };

        if (operation === 'create') {
            await api
                .post(`types`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.extractions.form.create_success" />
                    );

                    history.push(`/extractions/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateType(data);
        }
    }

    function IBMWatsonServiceSettings() {
        return (
            <div className="row">
                <div className="col-12 mb-5">
                    <TextField
                        id="extraction-entity"
                        label={
                            <LocaleMessage msg="page.extractions.form.label.entity" />
                        }
                        fullWidth
                        value={options.entity ? options.entity : ''}
                        onChange={event =>
                            setOptions({
                                ...options,
                                entity: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12 mb-5">
                    <FormControl fullWidth>
                        <TextField
                            id="service-url"
                            label={
                                <LocaleMessage msg="page.extractions.form.watson.service_url" />
                            }
                            fullWidth
                            value={options.url ? options.url : ''}
                            onChange={event =>
                                setOptions({
                                    ...options,
                                    url: event.target.value,
                                })
                            }
                        />
                    </FormControl>
                </div>
                <div className="col-12 mb-5">
                    <TextField
                        id="service-username"
                        label={
                            <LocaleMessage msg="page.extractions.form.watson.username" />
                        }
                        fullWidth
                        value={options.username ? options.username : ''}
                        onChange={event =>
                            setOptions({
                                ...options,
                                username: event.target.value,
                            })
                        }
                        helperText={
                            <LocaleMessage msg="page.extractions.form.watson.username.helper" />
                        }
                    />
                </div>
                <div className="col-12 mb-5">
                    <TextField
                        id="service-password"
                        label={
                            <LocaleMessage msg="page.extractions.form.watson.password" />
                        }
                        fullWidth
                        type="password"
                        value={options.password ? options.password : ''}
                        onChange={event =>
                            setOptions({
                                ...options,
                                password: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12 mb-3">
                    <TextField
                        id="service-model"
                        label={
                            <LocaleMessage msg="page.extractions.form.watson.model" />
                        }
                        fullWidth
                        value={options.model ? options.model : ''}
                        onChange={event =>
                            setOptions({
                                ...options,
                                model: event.target.value,
                            })
                        }
                    />
                </div>
            </div>
        );
    }

    function renderServiceSettings() {
        switch (options.provider) {
            case 'ibm_watson_nlu':
                return IBMWatsonServiceSettings();
            default:
                return (
                    <p>
                        <LocaleMessage msg="page.dialogs.form.provider_not_found" />
                    </p>
                );
        }
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title={<></>}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteType(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildFormattersList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={formatters}
                    orderColumn="name"
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.extractions.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.extractions.form.edit.title" />
                )
            }
            currentPage={
                operation === 'create' ? (
                    <LocaleMessage msg="page.extractions.form.create.title.short" />
                ) : (
                    <LocaleMessage msg="page.extractions.form.edit.title.short" />
                )
            }
            breadcrumbs={[
                {
                    url: '/extractions',
                    title: <LocaleMessage msg="breadcrumbs.extractions" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="row full-body">
                    <div className="col-md-8 col-12 mb-5">
                        <form noValidate autoComplete="off">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.extractions.form.label.settings" />
                                }
                                Icon={MdSpellcheck}
                            >
                                <>
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-5">
                                            <TextField
                                                id="extraction-name"
                                                label={
                                                    <LocaleMessage msg="page.extractions.form.label.name" />
                                                }
                                                fullWidth
                                                value={body.name || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        name:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6 col-12 mb-5">
                                            <TextField
                                                id="extraction-slug"
                                                label={
                                                    <LocaleMessage msg="page.extractions.form.label.slug" />
                                                }
                                                fullWidth
                                                value={body.slug || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        slug:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6 col-12 mb-5">
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    <LocaleMessage msg="page.extractions.form.label.provider" />
                                                </InputLabel>
                                                <Select
                                                    id="extraction-provider"
                                                    value={
                                                        options.provider
                                                            ? options.provider
                                                            : ''
                                                    }
                                                    onChange={event =>
                                                        setOptions({
                                                            ...options,
                                                            provider:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                    disabled={
                                                        operation === 'update'
                                                    }
                                                >
                                                    {providers.map(l => {
                                                        return (
                                                            <MenuItem
                                                                value={l.value}
                                                                key={l.value}
                                                            >
                                                                {l.label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    {options && options.provider
                                        ? renderServiceSettings()
                                        : null}
                                </>
                            </CardSideBordered>
                            <Button
                                className="p-3"
                                variant="contained"
                                color="primary"
                                onClick={event => handleSubmit(event)}
                                fullWidth
                                size="large"
                            >
                                <LocaleMessage msg="button.save" />
                            </Button>
                        </form>
                    </div>

                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.extractions.form.public.list" />
                            }
                            Icon={MdFormatShapes}
                            hide
                        >
                            <>
                                <div className="body-top-controls">
                                    <NewItemButton
                                        link={`/extractions/${extractor_id}/public_formatter/new`}
                                        text={
                                            <LocaleMessage msg="page.extractions.form.public.add_formatter" />
                                        }
                                    />
                                </div>
                                {buildFormattersList()}
                            </>
                        </CardSideBordered>
                    </div>
                </div>
            </>
        </PageContent>
    );
}

ExtractionForm.propTypes = {
    match: PropTypes.object.isRequired,
};
