/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdForum, MdInsertLink } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FormPasswordInput from '~/components/Form/PasswordInput';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkPermission from '~/util/CheckPermission';

export default function DialogForm(props) {
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.user || null);
    const su = checkPermission(profile, ['master']);

    const { match } = props;
    const { id } = match.params;

    const [operation, setOperation] = useState('create');
    const [serviceVisible, setServiceVisible] = useState(false);

    const [body, setBody] = useState({
        name: '',
        provider: null,
        description: '',
        credentials: {},
    });
    const [credentials, setCredentials] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const [providers, setProviders] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [forbidden, setForbidden] = useState(false);
    const [currProvider, setCurrProvider] = useState(null);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadProviders() {
        await api
            .get(`dialogs/providers`)
            .then(response => {
                setProviders(response.data);
            })
            .catch(err => requestError(err));
    }

    async function loadDialog(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`dialogs/${_id}`)
                .then(response => {
                    const d = response.data;
                    setServiceVisible(true);
                    setBody({
                        name: d.name,
                        provider: d.provider,
                        description: d.description,
                        language: d.language,
                    });

                    setCredentials(d.credentials);
                })
                .catch(err => requestError(err));
        }
        setIsLoading(false);
    }

    function onProviderChange(value) {
        const prov = providers.find(p => {
            return p.value === value;
        });

        setCurrProvider(prov || null);
        const require_language = prov && prov.require_language;
        const prov_languages = prov && prov.languages ? prov.languages : [];

        setBody({
            ...body,
            provider: value,
            language: require_language ? body.language : 'en',
        });
        setLanguages(prov_languages);
        setServiceVisible(!!value);
    }

    useEffect(() => {
        loadProviders();
        loadDialog(id);
    }, []);

    useEffect(() => {
        onProviderChange(body.provider);
    }, [providers, body.provider]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, credentials };

        if (operation === 'create') {
            await api
                .post(`dialogs`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.dialogs.form.create_success" />
                    );

                    history.push(`/tools/dialogs/${response.data.id}/info`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`dialogs/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.dialogs.form.update_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    function IBMWatsonServiceSettings() {
        const ibm_settings = providers.find(p => {
            return p.key === 'ibm_watson_skill';
        });

        const locations =
            ibm_settings && ibm_settings.locations
                ? ibm_settings.locations
                : [];

        return (
            <div className="row">
                <div className="col-12 mb-5">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.dialogs.form.watson.provider_location" />
                        </InputLabel>
                        <Select
                            id="provider-location"
                            value={
                                credentials.location ? credentials.location : ''
                            }
                            onChange={event =>
                                setCredentials({
                                    ...credentials,
                                    location: event.target.value,
                                })
                            }
                            disabled={operation === 'update'}
                        >
                            {locations.map(l => {
                                return (
                                    <MenuItem value={l.value} key={l.value}>
                                        <LocaleMessage
                                            msg={`provider.location.${l.label}`}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-12 mb-5">
                    <TextField
                        id="service-username"
                        label={
                            <LocaleMessage msg="page.dialogs.form.watson.username" />
                        }
                        fullWidth
                        value={credentials.username ? credentials.username : ''}
                        onChange={event =>
                            setCredentials({
                                ...credentials,
                                username: event.target.value,
                            })
                        }
                        helperText={
                            <LocaleMessage msg="page.dialogs.form.watson.username.helper" />
                        }
                    />
                </div>
                <div className="col-12 mb-5">
                    <FormPasswordInput
                        label={
                            <LocaleMessage msg="page.dialogs.form.watson.password" />
                        }
                        fullWidth
                        value={credentials.password ? credentials.password : ''}
                        onChange={event =>
                            setCredentials({
                                ...credentials,
                                password: event.target.value,
                            })
                        }
                        allowShow={false}
                    />
                </div>
                <div className="col-12 mb-3">
                    <TextField
                        id="service-workspace"
                        label={
                            <LocaleMessage msg="page.dialogs.form.watson.workspace" />
                        }
                        fullWidth
                        value={
                            credentials.workspace ? credentials.workspace : ''
                        }
                        onChange={event =>
                            setCredentials({
                                ...credentials,
                                workspace: event.target.value,
                            })
                        }
                        helperText={
                            <LocaleMessage msg="page.dialogs.form.watson.workspace.helper" />
                        }
                    />
                </div>
            </div>
        );
    }

    function PluginbotQnAServiceSettings() {
        return null;
    }

    function NeurobotServiceSettings() {
        return (
            <div className="row">
                <div className="col-12 mb-5">
                    <TextField
                        label={
                            <LocaleMessage msg="page.dialogs.form.watson.workspace" />
                        }
                        fullWidth
                        value={
                            credentials.workspace ? credentials.workspace : ''
                        }
                        onChange={event =>
                            setCredentials({
                                ...credentials,
                                workspace: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12 mb-5">
                    <FormPasswordInput
                        label={
                            <LocaleMessage msg="page.dialogs.form.watson.password" />
                        }
                        fullWidth
                        value={credentials.password ? credentials.password : ''}
                        onChange={event =>
                            setCredentials({
                                ...credentials,
                                password: event.target.value,
                            })
                        }
                        allowShow={false}
                    />
                </div>
            </div>
        );
    }

    function renderServiceSettings() {
        const provider_settings = {
            'IBM Watson Skill': IBMWatsonServiceSettings(),
            PluginbotQnA: PluginbotQnAServiceSettings(),
            Neurobot: NeurobotServiceSettings(),
        };

        const provider_body = provider_settings[body.provider];

        return provider_body ? (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.dialogs.form.label.service_settings" />
                    }
                    Icon={MdInsertLink}
                >
                    {provider_body}
                </CardSideBordered>
            </div>
        ) : null;
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.dialogs.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.dialogs.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/dialogs',
                    title: <LocaleMessage msg="breadcrumbs.dialogs" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <form className="row full-body" noValidate autoComplete="off">
                <div className="col-md-8 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.dialogs.form.label.settings" />
                        }
                        Icon={MdForum}
                    >
                        <>
                            <div className="row">
                                <div className="col-md-6 col-12 mb-5">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="page.dialogs.form.label.name" />
                                        }
                                        value={body.name ? body.name : ''}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                name: event.target.value,
                                            })
                                        }
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-12 col-12 mb-5">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="page.dialogs.form.label.description" />
                                        }
                                        fullWidth
                                        multiline
                                        value={
                                            body.description
                                                ? body.description
                                                : ''
                                        }
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                description: event.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="page.dialogs.form.label.provider" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                body.provider
                                                    ? body.provider
                                                    : ''
                                            }
                                            onChange={event => {
                                                return setBody({
                                                    ...body,
                                                    provider:
                                                        event.target.value,
                                                });
                                            }}
                                            disabled={operation === 'update'}
                                        >
                                            <MenuItem value="">
                                                <LocaleMessage msg="page.dialogs.form.select_provider" />
                                            </MenuItem>
                                            {providers.map(p => {
                                                return (
                                                    <MenuItem
                                                        key={p.key}
                                                        value={p.value}
                                                    >
                                                        {p.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                {currProvider &&
                                currProvider.require_language ? (
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.dialogs.form.label.language" />
                                            </InputLabel>
                                            <Select
                                                value={
                                                    body.language
                                                        ? body.language
                                                        : ''
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        language:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    operation === 'update' &&
                                                    (!su ||
                                                        body.provider ===
                                                            'PluginbotQnA')
                                                }
                                            >
                                                {languages.map(l => {
                                                    return (
                                                        <MenuItem
                                                            value={l.value}
                                                            key={l.label}
                                                        >
                                                            <LocaleMessage
                                                                msg={`list.languages.${l.label}`}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                ) : null}
                            </div>
                        </>
                    </CardSideBordered>
                </div>

                {serviceVisible ? renderServiceSettings() : null}

                <div className="col-md-8 col-12 mb-5">
                    <Button
                        className="p-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                        disabled={!serviceVisible}
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </form>
        </PageContent>
    );
}

DialogForm.propTypes = {
    match: PropTypes.object.isRequired,
};
