/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import TypesList from '~/components/RobotTypes';

export default function RobotTypes() {
    const { profile } = useSelector(state => state.user);
    const { permission } = profile;
    const is_master = permission === 'master';

    return (
        <TypesList
            breadcrumbs={[]}
            allowNew={is_master}
            linkRoot="/robottypes"
            goLink=""
        />
    );
}

RobotTypes.propTypes = {};
