/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import MultipleInputs from '~/components/Form/MultipleInputs';

const column = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function AnswersSetup({ settings, onChange, languages }) {
    const [answers, setAnswers] = useState(settings);

    useEffect(() => {
        const ans = { ...settings };
        languages.forEach(l => {
            const lang = l.value;
            ans[lang] = settings[lang] || [''];
        });
        setAnswers(ans);
    }, [settings, languages]);

    function onTextChange(lang, texts) {
        let obj = answers || {};
        if (!obj) {
            obj = {
                [lang]: '',
            };
        }

        obj[lang] = texts;
        onChange({ ...obj });
    }

    function renderTexts(_answers) {
        return languages.map(lang => {
            const l = lang.value;
            const l_label = `list.languages.${l}`;
            const l_texts = _answers[l] || [];

            return (
                <MultipleInputs
                    key={`lang_input_${l}`}
                    language={l}
                    label={l_label}
                    texts={l_texts}
                    open={languages.length === 1}
                    onChange={texts => {
                        onTextChange(l, texts);
                    }}
                />
            );
        });
    }

    return (
        <div className="mt-3" style={column}>
            <div className="col-12 mb-3">{renderTexts(answers)}</div>
        </div>
    );
}

AnswersSetup.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    languages: PropTypes.array,
};

AnswersSetup.defaultProps = {
    languages: [],
};
