import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
    const persistedReducer = persistReducer(
        {
            key:
                'BC2AE90651E8326C97CE912D3BDFF7EC9116EAE5DDBCE38364E804444C02B9F1',
            storage,
            whitelist: ['application', 'settings', 'auth', 'user', 'pages'],
        },
        reducers
    );

    return persistedReducer;
};
