import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { switchLanguage } from '~/store/modules/settings/actions';

import CustomScrollbar from '../CustomScrollBar';
import languageData from './data';
import LanguageItem from './LanguageItem';

const LanguageSwitcher = ({ handleRequestClose }) => {
    const dispatch = useDispatch();

    function onChangeLanguage(lang) {
        dispatch(switchLanguage(lang));
    }

    return (
        <CustomScrollbar
            className="messages-list language-list scrollbar"
            style={{ height: 230 }}
        >
            <ul className="list-unstyled" style={{ width: '250px' }}>
                {languageData.map(language => (
                    <LanguageItem
                        key={language.code}
                        language={language}
                        handleRequestClose={handleRequestClose}
                        switchLanguage={onChangeLanguage}
                    />
                ))}
            </ul>
        </CustomScrollbar>
    );
};

export default LanguageSwitcher;

LanguageSwitcher.propTypes = {
    handleRequestClose: PropTypes.func.isRequired,
};
