/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdContacts,
    MdFormatListBulleted,
    MdCancel,
    MdAdd,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

const sources = [
    {
        value: 'other',
        label_key: 'list.contact.source.other',
    },
    {
        value: 'location_zone',
        label_key: 'list.contact.source.location_zone',
    },
];

export default function ContactListForm({ match }) {
    const dispatch = useDispatch();
    const list_id = match.params.id;

    const user_settings = useSelector(state => state.settings || null);
    const { active } = user_settings;

    const [allowEdit, setAllowEdit] = useState(active.id !== '*');
    const [currSource, setCurrSource] = useState(sources[0]);

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState({
        name: '',
        source: 'other',
    });

    const [bindingBody, setBindingBody] = useState({});

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // const [locations, setLocations] = useState([]);
    const [zones, setZones] = useState([]);

    const [availableContacts, setAvailableContacts] = useState([]);
    const [listContacts, setListContacts] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    // async function loadLocations() {
    //     await api
    //         .get(`/locations`)
    //         .then(response => {
    //             const loc = response.data.map(l => {
    //                 return {
    //                     id: l.id,
    //                     name: l.name,
    //                 };
    //             });
    //             setLocations(loc);
    //         })
    //         .catch(error => requestError(error));
    // }

    async function loadZones() {
        await api
            .get(`/zones`)
            .then(response => {
                const l_zones = response.data.map(z => {
                    return {
                        id: z.id,
                        name: z.name,
                    };
                });
                setZones(l_zones);
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableContacts() {
        await api
            .get(`/contacts`)
            .then(response => {
                const c_list = response.data;
                const contacts = c_list.map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${c.source}`}
                            />
                        ),
                    };
                });
                setAvailableContacts(contacts);
            })
            .catch(error => requestError(error));
    }

    async function loadListContacts(_id) {
        await api
            .get(`/contact_lists/${_id}/contacts`)
            .then(response => {
                const c_list = response.data.contacts;
                const contacts = c_list.map(c => {
                    return {
                        id: c.id,
                        name: c.name,
                        source_type: (
                            <LocaleMessage
                                msg={`list.contact.source.${c.source}`}
                            />
                        ),
                    };
                });
                setListContacts(contacts);
            })
            .catch(error => requestError(error));
    }

    async function loadList(_id) {
        if (_id === 'new') {
            setOperation('create');
            setAllowEdit(true);
        } else {
            setOperation('update');
            await api
                .get(`/contact_lists/${_id}`)
                .then(response => {
                    const l = response.data;

                    const { source } = l;
                    const formBody = {
                        name: l.name,
                        description: l.description,
                        source,
                        location_id: null,
                        zone_id: null,
                    };

                    if (source === 'location_zone') {
                        formBody.zone_id =
                            l.zone && l.zone.id ? l.zone.id : null;
                    }
                    loadListContacts(_id);

                    setBody(formBody);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    function handleSourceChange(val) {
        const source = sources.find(s => {
            return s.value === val;
        });

        setCurrSource(source);
    }

    useEffect(() => {
        // loadLocations();
        loadZones();
        loadAvailableContacts();
    }, []);

    useEffect(() => {
        loadList(list_id);
    }, [list_id]);

    useEffect(() => {
        handleSourceChange(body.source);
    }, [body.source]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`/contact_lists`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.contact_lists.form.create_success" />
                    );
                    setIsLoading(false);
                    history.push(`/tools/contacts`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`/contact_lists/${list_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.contact_lists.form.update_success" />
                    );
                    loadList(list_id);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function renderSourceList(type) {
        const source_list = {
            location_zone: {
                key: 'zone_id',
                list: zones,
            },
        };

        const source = source_list[type];
        const { key, list } = source;

        return (
            <div className="col-md-6 col-12">
                <FormSelect
                    classes="mb-5"
                    options={list}
                    labelKey="name"
                    disabled={operation !== 'create'}
                    label={
                        <LocaleMessage msg="page.tools.contact_lists.form.label.source_bind" />
                    }
                    onChange={value => {
                        setBody({
                            ...body,
                            [key]: value,
                        });
                    }}
                    value={body[key] || ''}
                />
            </div>
        );
    }

    function renderContactListForm() {
        return (
            <>
                <div className="col-12 row">
                    <div className="col-md-6 col-12">
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.contact_lists.form.label.source_type" />
                            </InputLabel>
                            <Select
                                value={body.source || 'other'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        source: event.target.value,
                                    })
                                }
                                disabled={operation === 'update'}
                            >
                                {sources.map(s => (
                                    <MenuItem value={s.value} key={s.value}>
                                        <LocaleMessage msg={s.label_key} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {currSource && currSource.value !== 'other'
                        ? renderSourceList(currSource.value)
                        : null}
                </div>
                <div className="col-md-6 col-12">
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.tools.contact_lists.form.label.name" />
                        }
                        fullWidth
                        value={body.name || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                name: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="col-12">
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.tools.contact_lists.form.label.description" />
                        }
                        fullWidth
                        value={body.description || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                description: event.target.value,
                            })
                        }
                    />
                </div>
            </>
        );
    }

    async function bindContact() {
        return api
            .post(`/contact_lists/${list_id}/contacts`, bindingBody)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function unbindContact() {
        return api
            .delete(`/contact_lists/${list_id}/contacts/${currItem}`)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function submitBinding() {
        setIsLoading(true);
        setDialogOpen(false);

        let success = true;
        if (currItem === 'new') {
            success = await bindContact();
        } else {
            success = await unbindContact();
        }

        if (success) {
            toast.success(
                <LocaleMessage msg="page.tools.contact_lists.form.update_success" />
            );
        }

        loadListContacts(list_id);
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setCurrItem('');
        setDialogOpen(false);
    }

    function handleDialogOpen(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        setDialogOpen(true);
    }

    function renderBindingForm() {
        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-8 col-12">
                    <FormSelect
                        classes="mb-5"
                        options={availableContacts}
                        labelKey="name"
                        label={
                            <LocaleMessage msg="page.tools.contact_lists.form.label.contact.choose" />
                        }
                        onChange={value => {
                            setBindingBody({
                                ...bindingBody,
                                contact_id: value,
                            });
                        }}
                        value={bindingBody.contact_id || ''}
                    />
                </div>
            </div>
        );
    }

    function renderDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event)}
                title={
                    <LocaleMessage
                        msg={`page.tools.contact_lists.form.label.contact.${
                            currItem === 'new' ? 'bind' : 'unbind'
                        }`}
                    />
                }
                content={currItem === 'new' ? renderBindingForm() : null}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitBinding(),
                        label: (
                            <LocaleMessage
                                msg={
                                    currItem === 'new'
                                        ? 'button.save'
                                        : 'button.delete'
                                }
                            />
                        ),
                    },
                ]}
            />
        );
    }

    function renderContacts() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'source_type',
                label: <LocaleMessage msg="table.headers.source" />,
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdCancel />,
                action: handleDialogOpen,
            },
        ];

        return (
            <>
                <div className="body-top-controls">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAdd />}
                        style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                        }}
                        onClick={event => handleDialogOpen(event, 'new')}
                    >
                        <LocaleMessage msg="page.tools.contact_lists.form.label.contact.add" />
                    </Button>
                </div>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={listContacts}
                        orderColumn="name"
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.contact_lists.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.contact_lists.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/contacts',
                    title: <LocaleMessage msg="breadcrumbs.contact_lists" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? renderDialog() : null}
                <div className="row full-body mt-5">
                    <div
                        className={`${
                            operation === 'update' ? 'col-md-6' : 'col-md-8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.contact_lists.title" />
                            }
                            Icon={MdContacts}
                        >
                            <>
                                {renderContactListForm()}
                                {allowEdit ? (
                                    <div className="col-12">
                                        <Button
                                            className="p-3 mb-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                ) : null}
                            </>
                        </CardSideBordered>
                    </div>
                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.tools.contact_lists.form.label.bound" />
                                }
                                hide
                                Icon={MdFormatListBulleted}
                            >
                                <>{renderContacts()}</>
                            </CardSideBordered>
                        </div>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

ContactListForm.propTypes = {
    match: PropTypes.object.isRequired,
};
