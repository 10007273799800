/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

import { Container } from './style';

export default function CounterCard({ classes, title, value, footer }) {
    return (
        <Container className={classes || 'col-md-4 col-12'}>
            <Card className="jr-card jr-card-widget counter-card mb-0">
                <p className="card-heading">{title}</p>

                <div className="value-section mt-3 mb-1">
                    <p className="value">{value}</p>
                </div>

                {footer ? <p className="card-label">{footer}</p> : null}
            </Card>
        </Container>
    );
}

CounterCard.defaultProps = {
    title: '',
    value: '',
    footer: '',
    classes: '',
};

CounterCard.propTypes = {
    classes: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
