/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';

export default function QnAContentList({
    masterMode,
    pageKey,
    type,
    requestError,
    locale,
    active,
}) {
    const [contentList, setContentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [currItem, setCurrItem] = useState(null);
    const [deleteContentOpen, setDeleteContentOpen] = useState(false);

    const form_url = `/pluginbot_qna/training/${pageKey}`;

    async function loadContentList() {
        setIsLoading(true);
        await api
            .get(`qna/admin/contents?type=${type}`)
            .then(response => {
                const list = response.data.map(c => {
                    const updated = new Date(c.updated);
                    return {
                        ...c,
                        type_label: c.type.toUpperCase(),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setContentList(list);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadContentList();
    }, [active, type]);

    async function deleteContent() {
        setIsLoading(true);
        setDeleteContentOpen(false);

        await api
            .delete(`qna/admin/contents/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.delete_success" />
                );
                loadContentList();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handledeleteContentOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteContentOpen(true);
    }

    function handleListRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`${form_url}/${id}`);
    }

    function renderDeleteListDialog() {
        return (
            <SimpleDialog
                open={deleteContentOpen}
                onClose={() => setDeleteContentOpen(false)}
                title={<LocaleMessage msg="page.qna.content.delete.title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.qna.content.delete.body" />
                        <br />
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteContentOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteContent(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderLists() {
        const headCells = [
            ...(type === 'defaults'
                ? [
                      {
                          id: 'type_label',
                          label: <LocaleMessage msg="table.headers.type" />,
                      },
                  ]
                : []),
            {
                id: 'identifier',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'status',
                label: <LocaleMessage msg="table.headers.status" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const allow_edit = type !== 'defaults' || masterMode;

        const rowActions = allow_edit
            ? [
                  {
                      id: 'delete',
                      label: <LocaleMessage msg="button.delete" />,
                      icon: <MdDelete />,
                      action: handledeleteContentOpen,
                  },
              ]
            : [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={contentList}
                    orderColumn="identifier"
                    handleTableRowClick={(event, id) =>
                        handleListRowClick(event, id)
                    }
                    rowActions={rowActions}
                    hasHeader
                    header={
                        allow_edit ? (
                            <>
                                <div
                                    className="col-3"
                                    style={{ padding: '0px' }}
                                >
                                    <NewItemButton
                                        link={`${form_url}/new`}
                                        text={
                                            <LocaleMessage
                                                msg={`page.qna.content.${type}.add`}
                                            />
                                        }
                                    />
                                </div>
                            </>
                        ) : null
                    }
                />
            </div>
        );
    }

    return (
        <div className="row full-body">
            {deleteContentOpen ? renderDeleteListDialog() : null}
            {isLoading ? <Splash /> : renderLists()}
        </div>
    );
}

QnAContentList.propTypes = {
    masterMode: PropTypes.bool,
    requestError: PropTypes.func.isRequired,
    pageKey: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
    active: PropTypes.object.isRequired,
};

QnAContentList.defaultProps = {
    masterMode: false,
};
