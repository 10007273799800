import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        list-style-type: none;
    }

    .row {
        padding: 0px;
        width: 100%;
        margin: auto;
    }

    .full-body {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: top;
    }

    a {
        text-decoration: none !important;
        color: #495057 !important;
    }
`;

export const CardRow = styled.div`
    padding: 0px;
    width: 100%;
    min-height: 150px;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Card = styled.div`
    padding: 0px;
    margin-left: 1vw;
    margin-right: 1vw;

    .select:hover {
        background: ${props => {
            const color = props.theme.palette.primary.main;
            return `linear-gradient(45deg, ${darken(0.3, color)}, ${darken(
                0.1,
                color
            )})`;
        }};
        color: #fff;
    }

    .ribbon-primary {
        span {
            background: ${props => props.theme.palette.primary.main} !important;
        }
    }

    .title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            font-weight: bold;
        }
    }

    .action-row {
        padding-left: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .body {
        padding: 15px;

        h2 {
            font-weight: bold;
            overflow-wrap: overflow-wrap;
        }
    }

    .bg-primary {
        background-color: ${props =>
            props.theme.palette.primary.main} !important;
    }

    .text-primary {
        color: ${props => props.theme.palette.primary.main} !important;
    }
`;
