/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import PropTypes from 'prop-types';

import { DialogContentText } from '@material-ui/core';

import Footer from '~/components/Footer';
import Header from '~/components/Header';
import LocaleMessage from '~/components/LocaleMessage';
import Sidebar from '~/components/Sidebar';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Outside';

import MyGroups from '~/pages/Platform/MyGroups/List';
import history from '~/services/history';
import {
    signOut,
    expireSession,
    refreshSessionRequest,
} from '~/store/modules/auth/actions';
import { loadPluginspaceSettingsRequest } from '~/store/modules/settings/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';

import { Container, Body } from './styles';

export default function FullScreen({ selectGroup, children, expired }) {
    const dispatch = useDispatch();

    const [navCollapsed, setNavCollapsed] = useState(false);
    const [idleSettings, setIdleSettings] = useState({
        timeout: 30 * 1000 * 60, // 30 minutes
        isTimedOut: false,
    });

    const [dialogOpen, setDialogOpen] = useState(false);

    const ps_settings = useSelector(state => state.settings);
    const appType = useSelector(state => state.application);
    const isLoading = ps_settings ? ps_settings.isLoading : true;
    const { locale } = ps_settings;
    const lang = locale ? locale.code : 'pt_BR';

    useEffect(() => {
        const { pluginspace } = ps_settings;
        if (pluginspace) {
            dispatch(loadPluginspaceSettingsRequest(pluginspace.id));
            dispatch(loadGroupsRequest(lang));
        }
        dispatch(refreshSessionRequest());
    }, []);

    useEffect(() => {
        setDialogOpen(expired);
    }, [expired]);

    if (appType && appType.app_type !== 'platform') {
        history.push(appType.app_home);
    }

    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height');
    }

    function isDesktop() {
        return window.innerWidth > 1024;
    }

    const drawerStyle = isDesktop() ? 'mini-drawer' : 'collapsible-drawer';

    function onToggleCollapsedNav() {
        setNavCollapsed(!navCollapsed);
    }

    function hideNav() {
        setNavCollapsed(false);
    }

    function handleDialogClose() {
        setDialogOpen(false);
        dispatch(signOut());
        return true;
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="message.session.expired" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.session.inactivity.description" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'close',
                        onClick: handleDialogClose,
                        label: <LocaleMessage msg="button.close" />,
                    },
                ]}
            />
        );
    }

    function onIdle() {
        if (!idleSettings.isTimedOut) {
            setIdleSettings({
                ...idleSettings,
                isTimedOut: true,
            });
            dispatch(expireSession());
        }
    }

    return (
        <>
            <IdleTimer
                element={document}
                onIdle={() => onIdle()}
                debounce={250}
                timeout={idleSettings.timeout}
            />
            <ToastContainer toastClassName="toast-container" autoClose={3000} />

            {isLoading ? (
                <Splash />
            ) : (
                <>
                    {dialogOpen ? buildConfirmDialog() : null}
                    <div className={`app-container ${drawerStyle}`}>
                        <Header
                            selectGroup={selectGroup}
                            drawer={drawerStyle}
                            toggleCollapsedNav={() => onToggleCollapsedNav()}
                        />
                        <Sidebar
                            drawer={drawerStyle}
                            open={navCollapsed}
                            toggleCollapsedNav={() => onToggleCollapsedNav()}
                            hideNav={() => hideNav()}
                        />
                        <Container className="app-main-container">
                            <Body className="app-main-content">
                                {selectGroup ? <MyGroups /> : children}
                            </Body>
                            <Footer />
                        </Container>
                    </div>
                </>
            )}
        </>
    );
}

FullScreen.propTypes = {
    selectGroup: PropTypes.bool,
    expired: PropTypes.bool,
    children: PropTypes.element.isRequired,
};

FullScreen.defaultProps = {
    selectGroup: false,
    expired: false,
};
