/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import AlertExtras from '~/pages/RC3/Alerts/Extras';

import RobotItem from '../../Components/RobotItem';
import { Title, Container } from '../styles';

export default function CallCancelled({
    robot,
    robotType,
    content,
    removeAlert,
}) {
    const { location, zone, destination } = content;
    const location_txt = `${location ? location.name : ''} ${
        location && zone && zone.name ? ` - ${zone.name}` : ''
    }`;

    const destination_txt =
        destination && destination.name ? (
            <>
                <LocaleMessage msg="list.telepresence.request.mode.contact" />
                <span>: {destination.name}</span>
            </>
        ) : (
            <LocaleMessage msg="list.telepresence.request.mode.broadcast" />
        );

    const extras = [
        {
            key: 'location',
            value: location_txt,
        },
        {
            key: 'destination',
            value: destination_txt,
        },
    ];

    return (
        <Container onClick={() => removeAlert()}>
            <Title className="col-12 p-1" fontSize="1.25vh">
                <strong>
                    <LocaleMessage msg="rc3.notifications.telepresence.call_cancel.title" />
                </strong>
            </Title>
            <div>
                <RobotItem
                    active
                    robot={robot}
                    type={robotType}
                    showStatus={false}
                    key={robot.id}
                    size="5vh"
                    fontSize="1.5vh"
                />
            </div>
            <div className="col-12" style={{ textAlign: 'center' }}>
                <AlertExtras infos={extras} />
            </div>
        </Container>
    );
}

CallCancelled.propTypes = {
    robot: PropTypes.object,
    robotType: PropTypes.object,
    content: PropTypes.object,
    removeAlert: PropTypes.func.isRequired,
};

CallCancelled.defaultProps = {
    robot: {},
    robotType: {},
    content: {},
};
