/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import icon from '~/assets/icon-white.png';
import pbLogo from '~/assets/logo-white.png';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { PreviewArea, Screen, ListsBody, ItemCard, Footer } from './styles';

export default function Preview({ configuration }) {
    const targetRef = useRef();
    const theme = GetPluginspaceTheme(useTheme());
    const mainColor = theme && theme.primary ? theme.primary : '#000';
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const colorSettings = {
        background_color:
            configuration && configuration.background_color
                ? configuration.background_color
                : mainColor,
    };
    const dances = configuration.dances || [];

    useEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: (9.0 / 16.0) * targetRef.current.offsetWidth,
            });
        }
    }, []);

    function renderItemCard(item) {
        // const def_color = `#${Math.floor(Math.random() * 16777215)
        //     .toString(16)
        //     .padStart(6, '0')}`;
        const def_color = colorSettings.background_color || mainColor;
        const action_data = item.action || {};
        const button_settings = action_data.button || {};
        const button_file = button_settings.file ? button_settings.file : {};

        return (
            <ItemCard
                dimensions={dimensions}
                key={item.id}
                color={button_settings.color || def_color}
                expand={button_settings.expand || false}
            >
                <img
                    className="card-img"
                    alt={button_file.name || 'Pluginbot Icon'}
                    src={button_file.url || icon}
                />
                <span className="card-txt">
                    {(item.name || '').toUpperCase()}
                </span>
            </ItemCard>
        );
    }

    function renderView({ style, ...props }) {
        const viewStyle = {
            padding: '5px 10px',
        };
        return (
            <div
                className="box"
                style={{ ...style, ...viewStyle }}
                {...props}
            />
        );
    }

    function renderThumb({ style, ...props }) {
        const thumbStyle = {
            backgroundColor: '#fff',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    }

    return (
        <PreviewArea className="mt-3 mb-3" ref={targetRef}>
            <Screen dimensions={dimensions} colors={colorSettings}>
                <div className="header">
                    <img
                        className="pluginbot-logo mb-3"
                        src={pbLogo}
                        alt="Pluginbot Logo"
                        title="Pluginbot Logo"
                    />
                </div>

                <ListsBody dimensions={dimensions}>
                    <Scrollbars
                        className="row"
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                        renderView={renderView}
                        renderThumbHorizontal={renderThumb}
                        renderTrackVertical={p => (
                            <div
                                {...p}
                                style={{ display: 'none' }}
                                className="track-vertical"
                            />
                        )}
                    >
                        <ul className="list-unstyled">
                            {dances.map(item => {
                                return renderItemCard(item);
                            })}
                        </ul>
                    </Scrollbars>
                </ListsBody>

                <Footer dimensions={dimensions}>
                    <Chip
                        className="random-btn mb-1"
                        variant="default"
                        style={{
                            backgroundColor:
                                colorSettings && colorSettings.background_color
                                    ? colorSettings.background_color
                                    : mainColor,
                            color: '#fff',
                            overflowWrap: 'anywhere',
                            maxWidth: '90%',
                        }}
                        label={
                            <LocaleMessage msg="page.dances.pluginconfig.random" />
                        }
                    />
                </Footer>
            </Screen>
        </PreviewArea>
    );
}

Preview.defaultProps = {
    configuration: {},
};

Preview.propTypes = {
    configuration: PropTypes.object,
};
