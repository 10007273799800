import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

export default function BigButton({ onClick, disabled, title }) {
    return (
        <Button
            className="p-3"
            variant="contained"
            color="primary"
            onClick={event => onClick(event)}
            fullWidth
            size="large"
            disabled={disabled}
        >
            {title}
        </Button>
    );
}

BigButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

BigButton.defaultProps = {
    disabled: false,
    title: '',
};
