/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdVolumeUp, MdHeight, MdVolumeOff } from 'react-icons/md';
import { RiParkingBoxFill, RiParkingBoxLine } from 'react-icons/ri';

import PropTypes from 'prop-types';

import { Box, Button, Slider, Tooltip } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';
import { SlidersContainer, Sliders, Column } from './styles';

const h100 = { height: '100%' };

const blacklist = ['viewer'];

const min_versions = {
    temi: [2, 0, 0],
    cruzr: [3, 8, 0],
    double_3: [1, 5, 0],
};

export default function ControlCard({
    width,
    robot,
    status,
    allowAction,
    renderLoading,
    runFunction,
    header,
    permission,
}) {
    const allow_permission = blacklist.indexOf(permission) === -1;
    const { status: robot_status } = status;
    const { type: r_type } = robot;

    const app_version =
        robot_status &&
        robot_status.application &&
        robot_status.application.version
            ? robot_status.application.version
            : '0.0.0';

    function compareVersions(min_version, version) {
        const version_parts = version.split('.');

        if (Number(version_parts[0]) > min_version[0]) return true;

        if (Number(version_parts[0]) === min_version[0]) {
            if (Number(version_parts[1]) > min_version[1]) return true;
            if (Number(version_parts[1]) === min_version[1]) {
                return Number(version_parts[2]) >= min_version[2];
            }
            return false;
        }
        return false;
    }

    const allow_version = compareVersions(
        min_versions[r_type && r_type.code ? r_type.code : ''] || [0, 0, 0],
        app_version
    );

    function sendRC3Control(key, value) {
        runFunction({
            function: 'sendAction',
            params: { action: 'set_values', data: { key, value } },
        });
    }

    function renderVolumeControl({ min, max, level, onChange }) {
        return (
            <SlidersContainer>
                <Sliders>
                    <Slider
                        className="mb-1"
                        orientation="vertical"
                        value={level}
                        marks
                        min={min}
                        max={max || 10}
                        onChangeCommitted={onChange}
                    />
                    <MdVolumeUp className="mt-1" color="#fff" />
                </Sliders>
            </SlidersContainer>
        );
    }

    function renderD3Controls() {
        const volume_status =
            robot_status && robot_status.volume ? robot_status.volume : {};
        const volume_level = Number(volume_status.level || 0) * 100;
        const hardware_status =
            robot_status && robot_status.hardware ? robot_status.hardware : {};
        const robot_pole = hardware_status.pole || 0;
        const robot_kickstand = hardware_status.kickstand || false;

        const KickstandIcon = robot_kickstand
            ? RiParkingBoxLine
            : RiParkingBoxFill;

        return (
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    height: '100%',
                    width: '100%',
                }}
            >
                <div className="col-6 p-0" style={{ height: '100%' }}>
                    <SlidersContainer>
                        <Sliders>
                            <Slider
                                className="mb-1"
                                orientation="vertical"
                                value={volume_level}
                                onChangeCommitted={(event, v) => {
                                    sendRC3Control('volume', v / 100.0);
                                }}
                            />
                            <MdVolumeUp className="mt-1" color="#fff" />
                        </Sliders>
                        <Sliders>
                            <Slider
                                className="mb-1"
                                orientation="vertical"
                                value={robot_pole || 0}
                                onChangeCommitted={(event, v) => {
                                    sendRC3Control('pole', v / 100.0);
                                }}
                            />
                            <MdHeight className="mt-1" color="#fff" />
                        </Sliders>
                    </SlidersContainer>
                </div>
                <div className="col-3 p-0" style={{ height: '100%' }}>
                    <Column>
                        <Tooltip
                            title={
                                <LocaleMessage msg="rc3.robot.data.card.control.volume.mute" />
                            }
                        >
                            <Button variant="contained" color="primary">
                                <MdVolumeOff
                                    size={24}
                                    color="#fff"
                                    onClick={() => {
                                        sendRC3Control('volume', 0);
                                    }}
                                />
                            </Button>
                        </Tooltip>

                        <Tooltip
                            title={
                                <LocaleMessage
                                    msg={`rc3.robot.data.card.control.kickstand.${
                                        !robot_kickstand ? 'deploy' : 'retract'
                                    }`}
                                />
                            }
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    sendRC3Control(
                                        'kickstand',
                                        !robot_kickstand
                                    );
                                }}
                            >
                                <KickstandIcon size={24} color="#fff" />
                            </Button>
                        </Tooltip>
                    </Column>
                </div>
            </div>
        );
    }

    function renderCruzrControls() {
        const volume_status =
            robot_status && robot_status.volume ? robot_status.volume : {};
        const volume_level = Number(volume_status.level || 0);

        return allow_version ? (
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    height: '100%',
                    width: '100%',
                }}
            >
                <div className="col-6 p-0" style={{ height: '100%' }}>
                    <SlidersContainer>
                        <Sliders>
                            <Slider
                                className="mb-1"
                                orientation="vertical"
                                value={volume_level}
                                marks
                                min={1}
                                max={15}
                                onChangeCommitted={(event, v) => {
                                    sendRC3Control('volume', v);
                                }}
                            />
                            <MdVolumeUp className="mt-1" color="#fff" />
                        </Sliders>
                    </SlidersContainer>
                </div>
            </div>
        ) : null;
    }

    function renderTemiControls() {
        const volume_status =
            robot_status && robot_status.volume ? robot_status.volume : {};
        const volume_level = Number(volume_status.level || 0);

        return allow_version ? (
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    height: '100%',
                    width: '100%',
                }}
            >
                <div className="col-6 p-0" style={{ height: '100%' }}>
                    {renderVolumeControl({
                        min: 1,
                        max: 15,
                        level: volume_level,
                        onChange: (event, v) => {
                            sendRC3Control('volume', v);
                        },
                    })}
                </div>
            </div>
        ) : null;
    }

    const controls = {
        temi: renderTemiControls(),
        cruzr: renderCruzrControls(),
        double_3: renderD3Controls(),
    };

    function renderRTypeControls() {
        const type_controls =
            r_type && r_type.code && controls[r_type.code]
                ? controls[r_type.code]
                : null;
        return type_controls || null;
    }

    return robot_status &&
        robot_status.status !== 'disconnected' &&
        allow_permission &&
        allow_version ? (
        <div className={`${width}`} style={{ minHeight: '150px' }}>
            <Box style={h100}>
                <InfoCard>
                    <div className="desc-full">
                        {header || (
                            <span className="mb-3">
                                <LocaleMessage msg="rc3.robot.data.card.control" />
                            </span>
                        )}
                        {allowAction ? (
                            <div className="mb-3 pt-0 desc-full">
                                {renderRTypeControls()}
                            </div>
                        ) : (
                            <div className="mb-3 pt-0 desc-full">
                                {renderLoading()}
                            </div>
                        )}
                    </div>
                </InfoCard>
            </Box>
        </div>
    ) : null;
}

ControlCard.propTypes = {
    // options: PropTypes.object,
    width: PropTypes.string,
    robot: PropTypes.object,
    status: PropTypes.object,
    renderLoading: PropTypes.func.isRequired,
    runFunction: PropTypes.func.isRequired,
    allowAction: PropTypes.bool,
    header: PropTypes.element,
    permission: PropTypes.string,
};

ControlCard.defaultProps = {
    // options: { key: 'total', style: 'bar', size: 'col-12' },
    width: 'col-12',
    robot: {},
    status: {},
    allowAction: false,
    header: null,
    permission: 'viewer',
};
