import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 {
        font-weight: bold;
    }

    .not-full {
        display: flex;
        justify-content: space-around;
    }
`;
