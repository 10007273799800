/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdList, MdDelete, MdAdd, MdWarningAmber } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    // DialogContentText,
    // Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FormCheckList from '~/components/Form/CheckList';
import MultipleInputs from '~/components/Form/MultipleInputs';
import FormSelect from '~/components/Form/Select';
import FormStepper from '~/components/Form/Stepper';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
// import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import SpeakSequenceAction from './SpeakSequence';
import { ParameterArea, IconParameter } from './styles';

const actions = {
    speak: {
        value: 'speak',
        label: 'page.tools.autonomous_actions.actions.speak',
        random_mode: true,
    },
    speak_sequence: {
        value: 'speak_sequence',
        label: 'page.tools.autonomous_actions.actions.speak_sequence',
        random_mode: true,
    },
    navigate: {
        value: 'navigate',
        label: 'page.tools.autonomous_actions.actions.navigate',
        random_mode: true,
        filters: ['map_layer_id'],
    },
    go_base: {
        value: 'go_base',
        label: 'page.tools.autonomous_actions.actions.go_base',
        filters: ['map_layer_id'],
    },
    exit_base: {
        value: 'exit_base',
        label: 'page.tools.autonomous_actions.actions.exit_base',
        filters: ['map_layer_id'],
    },
    rest_body: {
        value: 'rest_body',
        label: 'page.tools.autonomous_actions.actions.rest_body',
    },
    run_script: {
        value: 'run_script',
        label: 'page.tools.autonomous_actions.actions.run_script',
        random_mode: true,
    },
};

const type_actions = {
    cruzr: [
        actions.speak,
        actions.speak_sequence,
        actions.navigate,
        actions.go_base,
        actions.exit_base,
        actions.run_script,
    ],
    temi: [
        actions.speak,
        actions.speak_sequence,
        actions.navigate,
        actions.go_base,
        actions.exit_base,
        actions.run_script,
    ],
    nao: [actions.speak, actions.rest_body],
};

const action_warnings = {
    cruzr: 'warnings.version.cruzr_v390',
    temi: 'warnings.version.temi_v220',
};

const condition_types = {
    timeout: {
        value: 'timeout',
        label: 'page.tools.autonomous_actions.actions.conditions.timeout',
        size: 'col-md-4',
        blacklist: ['go_base', 'exit_base'],
        parameters: [
            {
                key: 'value',
                label:
                    'page.tools.autonomous_actions.actions.conditions.timeout.value',
                type: 'number',
                default: 15,
                inputProps: {
                    min: 1,
                    step: 1,
                },
            },
            {
                key: 'unit',
                label:
                    'page.tools.autonomous_actions.actions.conditions.timeout.value',
                type: 'select',
                default: 'seconds',
                options: [
                    {
                        value: 'seconds',
                        label: 'label.time.seconds',
                    },
                    {
                        value: 'minutes',
                        label: 'label.time.minutes',
                    },
                ],
            },
        ],
    },
    battery_lower: {
        value: 'battery_lower',
        label: 'page.tools.autonomous_actions.actions.conditions.battery_lower',
        actions: ['go_base'],
        parameters: [
            {
                key: 'value',
                label:
                    'page.tools.autonomous_actions.actions.conditions.battery.value',
                type: 'number',
                default: 15,
                inputProps: {
                    min: 1,
                    step: 1,
                },
            },
        ],
    },
    charging_higher: {
        value: 'charging_higher',
        label:
            'page.tools.autonomous_actions.actions.conditions.battery_higher',
        actions: ['exit_base'],
        parameters: [
            {
                key: 'value',
                label:
                    'page.tools.autonomous_actions.actions.conditions.battery.value',
                type: 'number',
                default: 50,
                inputProps: {
                    min: 1,
                    step: 1,
                },
            },
        ],
    },
};

export default function AutonomousModeActions({ match }) {
    const dispatch = useDispatch();

    const { id, action_id } = match.params;

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [rtype, setRType] = useState('');

    const [points, setPoints] = useState([]);
    const [scripts, setScripts] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [autonomousPack, setAutonomousPack] = useState({});
    const [currAction, setCurrAction] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const pack_settings = autonomousPack.content || {};
    const pack_nav = pack_settings.navigation || {};

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const list = response.data;
                const langs = list.map(l => {
                    return l.value;
                });
                setLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadMapPoints(m_id) {
        if (m_id) {
            setIsLoading(true);
            await api
                .get(`map_points?map_layer=${m_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: z.name,
                        reference: z.reference,
                    }));
                    setPoints(data);
                })
                .catch(error => requestError(error));
        } else {
            setPoints([]);
        }
        setIsLoading(false);
    }

    async function loadScripts(r_id) {
        if (r_id) {
            setIsLoading(true);
            await api
                .get(`scripts?robot=${r_id}`)
                .then(response => {
                    const { data } = response;
                    const list = data.map(s => {
                        const script_nav =
                            s.content && s.content.navigation
                                ? s.content.navigation
                                : {};
                        const r_script_nav =
                            rtype && script_nav[rtype] ? script_nav[rtype] : {};
                        const nav_alert =
                            script_nav.active &&
                            (!pack_nav.active ||
                                !pack_nav.map_id ||
                                pack_nav.map_id !== r_script_nav.map_id);
                        const label = (
                            <>
                                {nav_alert ? (
                                    <MdWarningAmber
                                        size={20}
                                        className="mr-2"
                                        color="#f00"
                                    />
                                ) : null}
                                {s.name}
                            </>
                        );
                        return {
                            ...s,
                            label,
                            warnings: {
                                navigation: nav_alert,
                            },
                        };
                    });
                    setScripts(list);
                })
                .catch(error => requestError(error));
        } else {
            setScripts([]);
        }
        setIsLoading(false);
    }

    async function loadPack(_id) {
        if (_id !== 'new') {
            await api
                .get(`autonomous_mode/${_id}`)
                .then(response => {
                    const s = response.data;
                    setRType(s.robottype || '');
                    setAutonomousPack({
                        name: s.name,
                        description: s.description,
                        robottype_id: s.robot ? s.robot.id : null,
                        group_id: s.group ? s.group.id : null,
                        group_name: s.group ? s.group.name : '---',
                        content: s.content,
                    });
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    async function loadAction(_id) {
        setIsLoading(true);
        if (_id === 'new') {
            setCurrAction({});
        } else {
            await api
                .get(`autonomous_mode/${id}/actions/${_id}`)
                .then(response => {
                    const { data } = response;
                    setCurrAction(data);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
    }, []);

    useEffect(() => {
        loadPack(id);
    }, [id]);

    useEffect(() => {
        loadAction(action_id);
    }, [action_id]);

    useEffect(() => {
        if (pack_settings && pack_settings.navigation) {
            loadMapPoints(pack_settings.navigation.map_id);
        } else {
            setPoints([]);
        }
    }, [pack_settings]);

    useEffect(() => {
        if (autonomousPack.robottype_id) {
            loadScripts(autonomousPack.robottype_id);
        } else {
            setScripts([]);
        }
    }, [autonomousPack.robottype_id]);

    async function handleSubmit() {
        const data = {
            content_id: id,
            robot_type_id: autonomousPack.robottype_id,
            ...currAction,
        };

        setIsLoading(true);

        if (!currAction.id) {
            await api
                .post(`autonomous_mode/${id}/actions`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.autonomous_actions.actions.list.created" />
                    );
                    history.push(`/tools/autonomous_actions/${id}`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`autonomous_mode/${id}/actions/${currAction.id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.autonomous_actions.actions.list.updated" />
                    );
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleActionSettings(key, value) {
        const currentSettings = currAction.settings || {};
        setCurrAction({
            ...currAction,
            settings: {
                ...currentSettings,
                [key]: value,
            },
        });
    }

    function handleDeleteCondition(idx) {
        const action_settings = currAction.settings || {};
        const items = action_settings.conditions || [];
        items.splice(idx, 1);
        handleActionSettings('conditions', [...items]);
    }

    function handleNewCondition() {
        const action_settings = currAction.settings || {};
        const items = action_settings.conditions || [];
        items.push({
            type: '',
            value: '',
        });
        handleActionSettings('conditions', [...items]);
    }

    function onLimitationChange(key, value) {
        const action_settings = currAction.settings || {};
        const limitations =
            action_settings && action_settings.limitations
                ? action_settings.limitations
                : {};

        handleActionSettings('limitations', {
            ...limitations,
            [key]: value,
        });
    }

    function buildConditionBody(type, conditions) {
        const old_condition = conditions[type] || {};
        const condition_params = (condition_types[type] || {}).parameters || [];
        const default_body = {
            value: '',
        };
        condition_params.forEach(p => {
            default_body[p.key] = old_condition[p.key] || p.default || '';
        });
        return default_body;
    }

    function onConditionChange(event, idx, key) {
        const action_settings = currAction.settings || {};
        const items = action_settings.conditions || [];
        const { value } = event.target;
        if (items[idx]) {
            let default_body = {};
            if (key === 'type') {
                default_body = buildConditionBody(value, {});
            }

            items[idx] = {
                ...items[idx],
                [key]: event.target.value,
                ...(key === 'type' && { ...default_body }),
            };
        }
        handleActionSettings('conditions', [...items]);
    }

    function handleActionChange(event) {
        const curr_atype = currAction.action_type || '';
        const new_atype = event.target.value || '';

        const new_action = {
            ...currAction,
            action_type: new_atype,
        };

        if (new_atype !== curr_atype) {
            const curr_settings = currAction.settings || {};
            const conditions = curr_settings.conditions || [];
            let condition_filter = [];
            condition_filter = Object.keys(condition_types).filter(c => {
                const condition = condition_types[c] || {};
                const allowed = condition.actions;
                const blacklist = condition.blacklist || [];
                const filter_allow =
                    (!allowed || allowed.includes(new_atype)) &&
                    !blacklist.includes(new_atype);
                return filter_allow;
            });

            const items = [];
            if (condition_filter.length > 0) {
                condition_filter.forEach(c => {
                    const condition_values = buildConditionBody(c, conditions);
                    const condition_body = {
                        type: c,
                        ...condition_values,
                    };
                    items.push(condition_body);
                });
            }

            new_action.settings = {
                ...curr_settings,
                conditions: items,
            };
        }

        setCurrAction(new_action);
    }

    function setActionSettings(action_settings) {
        setCurrAction({
            ...currAction,
            settings: action_settings,
        });
    }

    function renderGoBaseAction(action_settings) {
        const map_id =
            pack_settings.navigation && pack_settings.navigation.map_id
                ? pack_settings.navigation.map_id
                : '';
        const point_list = [
            {
                id: '',
                name: '---',
                reference: '',
            },
            ...(points || []),
        ];

        return (
            <>
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.go_base.pre_dock" />
                    <div
                        className="col-md-6 col-12 mt-3"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <FormSelect
                            classes="mb-3"
                            options={point_list}
                            labelKey="name"
                            label={null}
                            value={
                                action_settings.point &&
                                action_settings.point.id
                                    ? action_settings.point.id
                                    : ''
                            }
                            onChange={value => {
                                const point = points.find(l => {
                                    return l.id === value;
                                });
                                setActionSettings({
                                    ...action_settings,
                                    map_id,
                                    point,
                                });
                            }}
                            helper={
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.go_base.pre_dock.helper" />
                            }
                        />
                    </div>
                </div>
            </>
        );
    }

    function renderScriptAction(action_settings) {
        const has_warning = !!scripts.find(s => {
            return s.warnings && s.warnings.navigation;
        });

        return (
            <>
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div
                        className="col-md-6 col-12 mb-3"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <FormControl fullWidth className="mb-3">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.run_script.mode" />
                            </InputLabel>
                            <Select
                                value={
                                    action_settings.mode
                                        ? action_settings.mode
                                        : ''
                                }
                                onChange={event =>
                                    setActionSettings({
                                        ...action_settings,
                                        mode: event.target.value,
                                        scripts: [],
                                    })
                                }
                            >
                                <MenuItem value="random" key="script_random">
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.run_script.mode.random" />
                                </MenuItem>
                                <MenuItem value="one" key="script_one">
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.run_script.mode.one" />
                                </MenuItem>
                            </Select>
                            {action_settings.mode === 'random' ? (
                                <FormHelperText>
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.run_script.mode.description" />
                                </FormHelperText>
                            ) : null}
                        </FormControl>
                        {action_settings.mode ? (
                            <>
                                {action_settings.mode === 'random' ? (
                                    <>
                                        {has_warning ? (
                                            <FormHelperText>
                                                <LocaleMessage msg="page.tools.autonomous_actions.actions.warning.navigation" />
                                            </FormHelperText>
                                        ) : null}
                                        <FormCheckList
                                            multiple
                                            options={scripts}
                                            listKey="scripts"
                                            primaryLabelKey="label"
                                            settings={action_settings}
                                            onChange={list => {
                                                setActionSettings(list);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <FormControl
                                        fullWidth
                                        className="mt-3 mb-3"
                                    >
                                        <InputLabel>Script</InputLabel>
                                        <Select
                                            value={
                                                action_settings.scripts &&
                                                action_settings.scripts[0]
                                                    ? action_settings.scripts[0]
                                                    : ''
                                            }
                                            onChange={event =>
                                                setActionSettings({
                                                    ...action_settings,
                                                    scripts: [
                                                        event.target.value,
                                                    ],
                                                })
                                            }
                                        >
                                            {scripts.map(s => {
                                                return (
                                                    <MenuItem
                                                        value={s.id}
                                                        key={`script_${s.id}`}
                                                    >
                                                        {s.label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {has_warning ? (
                                            <FormHelperText>
                                                <LocaleMessage msg="page.tools.autonomous_actions.actions.warning.navigation" />
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }

    function renderNavigationAction(action_settings) {
        const map_id =
            pack_settings.navigation && pack_settings.navigation.map_id
                ? pack_settings.navigation.map_id
                : '';
        return (
            <>
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div
                        className="col-md-6 col-12 mb-3"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <FormControl fullWidth className="mb-3">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.navigate.mode" />
                            </InputLabel>
                            <Select
                                value={
                                    action_settings.mode
                                        ? action_settings.mode
                                        : 'random'
                                }
                                onChange={event =>
                                    setActionSettings({
                                        ...action_settings,
                                        mode: event.target.value,
                                        map_id,
                                        points: [],
                                    })
                                }
                            >
                                <MenuItem value="random" key="nav_mode_random">
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.navigate.mode.random" />
                                </MenuItem>
                                <MenuItem
                                    value="to_point"
                                    key="nav_mode_to_point"
                                >
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.navigate.mode.to_point" />
                                </MenuItem>
                            </Select>
                            {action_settings.mode !== 'to_point' ? (
                                <FormHelperText>
                                    <LocaleMessage msg="page.tools.autonomous_actions.actions.navigate.mode.description" />
                                </FormHelperText>
                            ) : null}
                        </FormControl>
                        <FormCheckList
                            multiple={
                                action_settings.mode &&
                                action_settings.mode !== 'to_point'
                            }
                            options={points}
                            listKey="points"
                            settings={action_settings}
                            onChange={list => {
                                setActionSettings({
                                    ...action_settings,
                                    map_id,
                                    points:
                                        list && list.points ? list.points : [],
                                });
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }

    function renderSequenceAction(action_settings) {
        return (
            <div className="col-12">
                <SpeakSequenceAction
                    languages={languages}
                    settings={action_settings}
                    onChange={s => {
                        setActionSettings(s);
                    }}
                />
            </div>
        );
    }

    function renderSpeakAction(action_settings) {
        const texts = action_settings.texts || {};

        return (
            <>
                <h4 className="mb-3">
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.speak.texts" />
                </h4>
                <span className="mb-5">
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.speak.texts.description" />
                </span>
                <div className="col-12 mb-3">
                    {languages.map(l => {
                        const l_label = `list.languages.${l}`;
                        const l_texts = texts[l] || [];
                        return (
                            <MultipleInputs
                                margin="mb-1"
                                key={`lang_input_${l}`}
                                language={l}
                                label={l_label}
                                texts={l_texts}
                                open
                                onChange={t => {
                                    setActionSettings({
                                        ...action_settings,
                                        texts: {
                                            ...texts,
                                            [l]: t,
                                        },
                                    });
                                }}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    const form = {
        speak: mode_settings => renderSpeakAction(mode_settings),
        speak_sequence: mode_settings => renderSequenceAction(mode_settings),
        run_script: mode_settings => renderScriptAction(mode_settings),
        navigate: mode_settings => renderNavigationAction(mode_settings),
        go_base: mode_settings => renderGoBaseAction(mode_settings),
        exit_base: mode_settings => renderNavigationAction(mode_settings),
    };

    function renderLimitations() {
        const action_type = currAction.action_type || '';
        const action_settings = currAction.settings || {};

        const limitations =
            action_settings && action_settings.limitations
                ? action_settings.limitations
                : {};

        const limitation_location = limitations.location || {};

        return (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {action_type !== 'go_base' ? (
                    <div
                        className="col-12 mb-1 row"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <FormSwitch
                            value={
                                limitations && limitations.charging
                                    ? limitations.charging
                                    : action_type === 'exit_base' || false
                            }
                            disabled={action_type === 'exit_base'}
                            onChange={event =>
                                onLimitationChange(
                                    'charging',
                                    event.target.checked
                                )
                            }
                            label={
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.limitations.charging" />
                            }
                            helper={
                                rtype &&
                                action_warnings &&
                                action_warnings[rtype] ? (
                                    <LocaleMessage
                                        msg={action_warnings[rtype]}
                                    />
                                ) : (
                                    false
                                )
                            }
                        />
                    </div>
                ) : null}
                <div
                    className="col-12 mb-1 row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <FormSwitch
                        value={
                            limitation_location && limitation_location.active
                                ? limitation_location.active
                                : false
                        }
                        onChange={event =>
                            onLimitationChange('location', {
                                ...limitation_location,
                                active: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.tools.autonomous_actions.actions.limitations.location" />
                        }
                    />
                </div>
                {limitation_location.active ? (
                    <>
                        <span className="mb-3">
                            <LocaleMessage msg="page.tools.autonomous_actions.actions.limitations.location.allowed" />
                        </span>
                        <FormCheckList
                            multiple
                            options={[
                                {
                                    id: 'unknown',
                                    name: (
                                        <LocaleMessage msg="label.form.point.unknown" />
                                    ),
                                    reference: '',
                                },
                                ...points,
                            ]}
                            listKey="points"
                            settings={limitation_location || {}}
                            onChange={list => {
                                onLimitationChange('location', list);
                            }}
                        />
                    </>
                ) : null}
            </div>
        );
    }

    function renderConditionRow(item, index) {
        const condition_settings =
            item.type && condition_types[item.type]
                ? condition_types[item.type]
                : {};
        const parameters = condition_settings.parameters || [];
        return (
            <ParameterArea
                className="col-12 row p-3"
                key={`condition_${index}`}
            >
                <div className="col-11 row">
                    <div
                        className={`${condition_settings.size ||
                            'col-md-6'} col-12`}
                    >
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions.type" />
                            </InputLabel>
                            <Select
                                id="condition-type"
                                value={item.type ? item.type : ''}
                                onChange={event =>
                                    onConditionChange(event, index, 'type')
                                }
                            >
                                {Object.keys(condition_types).map(c => {
                                    const cond_settings = condition_types[c];
                                    const filter = cond_settings.actions;
                                    const action_type =
                                        currAction.action_type || '';
                                    const blacklist =
                                        cond_settings.blacklist || [];

                                    const filter_allow =
                                        (!filter ||
                                            filter.includes(action_type)) &&
                                        !blacklist.includes(action_type);

                                    return filter_allow ? (
                                        <MenuItem
                                            value={cond_settings.value}
                                            key={cond_settings.value}
                                        >
                                            <LocaleMessage
                                                msg={cond_settings.label}
                                            />
                                        </MenuItem>
                                    ) : null;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {parameters.map(p => {
                        const p_value = item[p.key] ? item[p.key] : '';
                        return (
                            <div
                                className={`${condition_settings.size ||
                                    'col-md-6'} col-12`}
                                key={`param_${p.key}`}
                            >
                                {p.type === 'select' ? (
                                    <>
                                        <FormControl fullWidth>
                                            <InputLabel />
                                            <Select
                                                value={p_value}
                                                onChange={event =>
                                                    onConditionChange(
                                                        event,
                                                        index,
                                                        p.key
                                                    )
                                                }
                                            >
                                                {p.options.map(o => {
                                                    return (
                                                        <MenuItem
                                                            value={o.value}
                                                            key={`${p.key}_${o.value}`}
                                                        >
                                                            <LocaleMessage
                                                                msg={o.label}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </>
                                ) : (
                                    <TextField
                                        label={<LocaleMessage msg={p.label} />}
                                        fullWidth
                                        value={p_value}
                                        onChange={event =>
                                            onConditionChange(
                                                event,
                                                index,
                                                p.key
                                            )
                                        }
                                        type={p.type || undefined}
                                        inputProps={p.inputProps || undefined}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                <IconParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => handleDeleteCondition(index)}
                    />
                </IconParameter>
            </ParameterArea>
        );
    }

    function renderConditions() {
        const show_conditions =
            (pack_settings.mode && pack_settings.mode === 'conditional') ||
            (currAction &&
                actions[currAction.action_type] &&
                !actions[currAction.action_type].random_mode);

        const action_settings = currAction.settings || {};
        const conditions = action_settings.conditions || [];

        return (
            <div
                className="col-12 mt-3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {show_conditions ? (
                    <>
                        <div className="col-12">
                            <span className="mb-5">
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions.label" />
                            </span>
                        </div>

                        <div className="col-md-10 col-12 mb-3">
                            <ParameterArea className="mt-3">
                                {conditions.map((item, idx) => {
                                    return renderConditionRow(item, idx);
                                })}
                            </ParameterArea>
                            <Button
                                className="mb-5"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleNewCondition()}
                                startIcon={
                                    <MdAdd
                                        style={{
                                            color: '#fff',
                                        }}
                                    />
                                }
                            >
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions.add" />
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="mb-5">
                        <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions.random" />
                    </div>
                )}
                {renderLimitations()}
            </div>
        );
    }

    function renderParameters() {
        const action_form =
            currAction && currAction.action_type && form[currAction.action_type]
                ? form[currAction.action_type]
                : undefined;
        return (
            <div
                className="col-12 pt-3"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {action_form ? (
                    action_form(currAction.settings || {})
                ) : (
                    <div>
                        <LocaleMessage msg="page.tools.autonomous_actions.actions.no_parameters" />
                    </div>
                )}
            </div>
        );
    }

    function renderDescription() {
        const p_content = autonomousPack.content || {};

        const saved_action =
            action_id && action_id !== 'new' && currAction.action_type
                ? currAction.action_type
                : '';

        const settings_filter = {
            map_layer_id:
                p_content.navigation &&
                p_content.navigation.active &&
                p_content.navigation.map_id
                    ? p_content.navigation.map_id
                    : '',
        };

        const r_actions =
            rtype && type_actions[rtype] ? type_actions[rtype] : [];

        const allowed_actions = r_actions.filter(action => {
            if (action.value === saved_action) return true;
            if (!action.filters || action.filters.length === 0) return true;
            return !action.filters.find(f => {
                return !settings_filter[f];
            });
        });

        const alerts = {};
        r_actions.forEach(action => {
            if (!action.filters || action.filters.length === 0) return false;
            const has_alert = !!action.filters.find(f => {
                return !settings_filter[f];
            });
            if (has_alert) {
                alerts[action.value] = has_alert;
            }
            return false;
        });

        return (
            <>
                <div className="col-md-8 col-12 row pt-3">
                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.tools.autonomous_actions.actions" />
                            </InputLabel>
                            <Select
                                value={currAction.action_type || ''}
                                onChange={event => handleActionChange(event)}
                                disabled={!!currAction.id}
                            >
                                {allowed_actions.map(action => {
                                    return (
                                        <MenuItem
                                            key={`action_${action.value}`}
                                            value={action.value}
                                        >
                                            <span>
                                                {alerts[action.value] ? (
                                                    <MdWarningAmber
                                                        size={20}
                                                        className="mr-2"
                                                        color="#f00"
                                                    />
                                                ) : null}
                                                <LocaleMessage
                                                    msg={action.label}
                                                />
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.identifier" />
                            }
                            fullWidth
                            value={currAction.description || ''}
                            onChange={event =>
                                setCurrAction({
                                    ...currAction,
                                    description: event.target.value,
                                })
                            }
                        />
                    </div>
                </div>
            </>
        );
    }

    const steps = [
        {
            key: 'description',
            label: (
                <LocaleMessage msg="page.tools.autonomous_actions.actions.description" />
            ),
            content: renderDescription(),
        },
        ...(currAction && currAction.action_type
            ? [
                  {
                      key: 'content',
                      label: (
                          <LocaleMessage msg="page.tools.autonomous_actions.actions.parameters" />
                      ),
                      content: renderParameters(),
                  },
                  {
                      key: 'conditions',
                      label: (
                          <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions" />
                      ),
                      content: renderConditions(),
                  },
              ]
            : []),
    ];

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={`page.tools.autonomous_actions.actions.${
                        action_id === 'new' ? 'new' : 'edit'
                    }`}
                />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/autonomous_actions',
                    title: (
                        <LocaleMessage msg="page.tools.autonomous_actions.list.title" />
                    ),
                },
                {
                    url: `/tools/autonomous_actions/${id}`,
                    title: autonomousPack ? autonomousPack.name : '',
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <div className="col-md-10 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage
                            msg={`page.tools.autonomous_actions.actions.${
                                action_id === 'new' ? 'new' : 'edit'
                            }`}
                        />
                    }
                    Icon={MdList}
                >
                    <div
                        className="col-12 mb-3"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div className="row full-body mt-3">
                            <FormStepper
                                steps={steps}
                                activeStep={activeStep}
                                handleStepClick={index => setActiveStep(index)}
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                handleSubmit={() => handleSubmit()}
                            />
                        </div>
                        <Button
                            className="mt-3 mb-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </CardSideBordered>
            </div>
        </PageContent>
    );
}

AutonomousModeActions.propTypes = {
    match: PropTypes.object.isRequired,
};
