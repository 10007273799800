/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { Card, Placeholder } from './styles';

export default function RobotCard({ width, robot, image, status, format }) {
    const boxShadow = 0;

    const updated =
        status && status.updated
            ? new Date(status.updated).toLocaleDateString(
                  format.format,
                  format.label
              )
            : '';

    return (
        <div className={`${width} mb-3`} style={{ height: '100%' }}>
            <Box boxShadow={boxShadow} style={{ height: '100%' }}>
                <Card className="row">
                    <div
                        className="col-5"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {image ? (
                            <img
                                className={`half-img-title ${status.value}`}
                                src={image}
                                alt={robot.code}
                                style={{
                                    objectPosition: 'top',
                                }}
                            />
                        ) : (
                            <Placeholder />
                        )}
                    </div>
                    <div className="col-7 card-body">
                        <div className="card-title">
                            <span className="robot-name">
                                {robot.name ? robot.name.toUpperCase() : '---'}
                            </span>
                            <br />
                            <span>{robot.code}</span>
                            <br />
                            <span>
                                {robot.group ? robot.group.name : '---'}
                            </span>
                        </div>
                        <div className="card-bottom">
                            <div
                                style={{
                                    marginBottom: '10px',
                                }}
                            >
                                <div className="robot-status">
                                    <LocaleMessage msg="rc3.robot.data.status" />
                                    {status.status === 'alert' &&
                                    status.alert ? (
                                        <LocaleMessage
                                            msg={`rc3.alerts.${status.alert}`}
                                        />
                                    ) : (
                                        <LocaleMessage msg={status.label} />
                                    )}
                                </div>
                            </div>
                            <div>
                                <LocaleMessage msg="rc3.robot.data.last_updated" />
                                <span>{updated || '---'}</span>
                            </div>
                        </div>
                    </div>
                </Card>
            </Box>
        </div>
    );
}

RobotCard.propTypes = {
    width: PropTypes.string,
    robot: PropTypes.object,
    image: PropTypes.string,
    status: PropTypes.object,
    format: PropTypes.object,
};

RobotCard.defaultProps = {
    width: 'col-md-6',
    robot: {},
    image: '',
    status: {},
    format: {},
};
