/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdDelete, MdAdd, MdContentCopy } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    DialogContentText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea, IconParameter } from '../../styles';

export default function ExitConditionSetup(props) {
    const { settings, onChange, stepList, varList } = props;

    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const condition_types = [
        {
            value: 'true',
            label: <LocaleMessage msg="list.comparison.true" />,
        },
        {
            value: 'step-timeout',
            label: <LocaleMessage msg="list.comparison.step_timeout" />,
        },
        {
            value: 'value',
            label: <LocaleMessage msg="list.comparison.value" />,
        },
    ];

    const comparison_types = [
        {
            value: 'value-exists',
            label: <LocaleMessage msg="list.comparison.key_exists" />,
        },
        {
            value: 'value-equals',
            label: <LocaleMessage msg="list.comparison.value_equals" />,
        },
        {
            value: 'value-different',
            label: <LocaleMessage msg="list.comparison.value_different" />,
        },
        {
            value: 'value-less',
            label: <LocaleMessage msg="list.comparison.value_less" />,
        },
        {
            value: 'value-less-equal',
            label: <LocaleMessage msg="list.comparison.value_less_equal" />,
        },
        {
            value: 'value-greater',
            label: <LocaleMessage msg="list.comparison.value_greater" />,
        },
        {
            value: 'value-greater-equal',
            label: <LocaleMessage msg="list.comparison.value_greater_equal" />,
        },
        {
            value: 'value-in-range',
            label: <LocaleMessage msg="list.comparison.value_in_range" />,
            helper: <LocaleMessage msg="list.comparison.value_range.helper" />,
        },
        {
            value: 'value-out-range',
            label: <LocaleMessage msg="list.comparison.value_out_range" />,
            helper: <LocaleMessage msg="list.comparison.value_range.helper" />,
        },
        {
            value: 'value-contains-any',
            label: <LocaleMessage msg="list.comparison.value_contains.any" />,
            helper: <LocaleMessage msg="list.comparison.value_array.helper" />,
        },
        {
            value: 'value-contains-all',
            label: <LocaleMessage msg="list.comparison.value_contains.all" />,
            helper: <LocaleMessage msg="list.comparison.value_array.helper" />,
        },
        {
            value: 'value-not-contains-any',
            label: (
                <LocaleMessage msg="list.comparison.value_not_contains.any" />
            ),
            helper: <LocaleMessage msg="list.comparison.value_array.helper" />,
        },
        {
            value: 'value-not-contains-all',
            label: (
                <LocaleMessage msg="list.comparison.value_not_contains.all" />
            ),
            helper: <LocaleMessage msg="list.comparison.value_array.helper" />,
        },
    ];

    function handleNewCondition() {
        const items = settings || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            name: '',
            type: '',
            comparison: '',
            value: '',
            to: '',
        });
        onChange([...items]);
    }

    function handleCopyCondition(item) {
        const items = settings || [];
        const item_id = (+new Date()).toString(36);
        items.push({
            item_id,
            name: '',
            type: item.type || '',
            field: item.field || '',
            comparison: item.comparison || '',
            value: item.value || '',
            to: item.to || '',
        });
        onChange([...items]);
    }

    function handleDeleteCondition(idx) {
        setDialogOpen(false);
        const old_items = settings || [];
        const items = old_items.filter(c => {
            return c.item_id !== idx;
        });
        onChange([...items]);
    }

    function onConditionChange(value, idx, key) {
        const items = settings || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: value,
            };
        }
        onChange([...items]);
    }

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;

        const list = [...settings];
        const [removed] = list.splice(source.index, 1);
        list.splice(destination.index, 0, removed);

        onChange(list);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleDialogOpen(event, index) {
        setCurrItem(index);
        event.preventDefault();
        setDialogOpen(true);
    }

    function renderConditionRow(type, item, index) {
        const curr_field = item && item.field ? item.field : '';
        const options = varList.filter(f => {
            return f.indexOf(curr_field) !== -1;
        });
        const curr_comparison = comparison_types.find(t => {
            return t.value === item.comparison;
        });

        return (
            <div className="row col-12 move">
                <div className="col-9 row mx-0">
                    <div className={type === 'value' ? 'col-3' : 'col-12'}>
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.exit.condition.label.type" />
                            </InputLabel>
                            <Select
                                id="condition-type"
                                value={item.type ? item.type : ''}
                                onChange={event =>
                                    onConditionChange(
                                        event && event.target
                                            ? event.target.value
                                            : '',
                                        index,
                                        'type'
                                    )
                                }
                            >
                                {condition_types.map(c => {
                                    return (
                                        <MenuItem value={c.value} key={c.value}>
                                            {c.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    {item.type === 'value' ? (
                        <>
                            <div className="col-3">
                                <Autocomplete
                                    freeSolo
                                    options={options}
                                    value={item && item.field ? item.field : ''}
                                    inputValue={
                                        item && item.field ? item.field : ''
                                    }
                                    onInputChange={(event, val) =>
                                        onConditionChange(val, index, 'field')
                                    }
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label={
                                                <LocaleMessage msg="page.survey_steps.form.exit.condition.label.parameter" />
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <div
                                className={
                                    !item.comparison ||
                                    item.comparison === 'value-exists'
                                        ? 'col-6'
                                        : 'col-3'
                                }
                            >
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="page.survey_steps.form.exit.condition.label.title" />
                                    </InputLabel>
                                    <Select
                                        id="comparison-type"
                                        value={
                                            item.comparison
                                                ? item.comparison
                                                : ''
                                        }
                                        onChange={event =>
                                            onConditionChange(
                                                event && event.target
                                                    ? event.target.value
                                                    : '',
                                                index,
                                                'comparison'
                                            )
                                        }
                                    >
                                        {comparison_types.map(c => {
                                            return (
                                                <MenuItem
                                                    value={c.value}
                                                    key={c.value}
                                                >
                                                    {c.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {item.comparison &&
                            item.comparison !== 'value-exists' ? (
                                <div className="col-3">
                                    <TextField
                                        label={
                                            <LocaleMessage msg="page.survey_steps.form.exit.condition.label.value" />
                                        }
                                        fullWidth
                                        value={
                                            item && item.value ? item.value : ''
                                        }
                                        onChange={event =>
                                            onConditionChange(
                                                event && event.target
                                                    ? event.target.value
                                                    : '',
                                                index,
                                                'value'
                                            )
                                        }
                                        helperText={
                                            curr_comparison &&
                                            curr_comparison.helper
                                                ? curr_comparison.helper
                                                : null
                                        }
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </div>

                <div className="col-3 row">
                    <div className="col-9">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.exit.condition.label.go_to" />
                            </InputLabel>
                            <Select
                                id="condition-goTo"
                                value={item.to ? item.to : ''}
                                onChange={event =>
                                    onConditionChange(
                                        event && event.target
                                            ? event.target.value
                                            : '',
                                        index,
                                        'to'
                                    )
                                }
                            >
                                {type === 'step-timeout' ? (
                                    <MenuItem value="cancel">
                                        <LocaleMessage msg="page.survey_steps.form.exit.condition.label.go_to.cancel" />
                                    </MenuItem>
                                ) : null}

                                {type === 'step-timeout' ? (
                                    <MenuItem value="previous">
                                        <LocaleMessage msg="page.survey_steps.form.exit.condition.label.go_to.previous" />
                                    </MenuItem>
                                ) : null}

                                {stepList.map(s => {
                                    return (
                                        <MenuItem value={s.id} key={s.id}>
                                            {s.description}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <IconParameter className="col-3">
                        <MdContentCopy
                            size={18}
                            onClick={() => handleCopyCondition(item)}
                        />
                        <MdDelete
                            size={18}
                            onClick={event =>
                                handleDialogOpen(event, item.item_id)
                            }
                        />
                    </IconParameter>
                </div>
            </div>
        );
    }

    function renderConditionList(conditions) {
        return (
            <DragDropContext onDragEnd={result => onDragEnd(result)}>
                <Droppable droppableId="condition-list">
                    {(drop_provided, drop_snapshot) => {
                        return (
                            <div
                                {...drop_provided.droppableProps}
                                ref={drop_provided.innerRef}
                                style={{
                                    background: drop_snapshot.isDraggingOver
                                        ? 'lightgrey'
                                        : 'white',
                                    width: '100%',
                                }}
                            >
                                {conditions.map((item, idx) => {
                                    const { type } = item;
                                    return (
                                        <Draggable
                                            key={item.item_id}
                                            draggableId={item.item_id}
                                            index={idx}
                                        >
                                            {drag_provided => {
                                                return (
                                                    <ParameterArea
                                                        ref={
                                                            drag_provided.innerRef
                                                        }
                                                        {...drag_provided.draggableProps}
                                                        {...drag_provided.dragHandleProps}
                                                        style={{
                                                            userSelect: 'none',
                                                            ...drag_provided
                                                                .draggableProps
                                                                .style,
                                                        }}
                                                    >
                                                        {renderConditionRow(
                                                            type,
                                                            item,
                                                            idx
                                                        )}
                                                    </ParameterArea>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {drop_provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.survey_steps.form.exit.condition.label.delete.confirm" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => handleDeleteCondition(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            {dialogOpen ? buildDeleteDialog() : null}
            <>
                <div
                    style={{
                        padding: '20px 15px',
                    }}
                >
                    <LocaleMessage msg="page.survey_steps.form.exit.condition.label.info" />
                </div>
                <div style={{ padding: '0px 15px' }}>
                    <ParameterArea className="row mt-1 mb-5">
                        {renderConditionList(settings || [])}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewCondition()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="page.survey_steps.form.exit.condition.label.add" />
                        </Button>
                    </ParameterArea>
                </div>
            </>
        </>
    );
}

ExitConditionSetup.defaultProps = {
    stepList: [],
    varList: [],
};

ExitConditionSetup.propTypes = {
    settings: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    stepList: PropTypes.array,
    varList: PropTypes.array,
};
