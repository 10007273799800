import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import '~/assets/vendors/style';
import AppLocale from '../lngProvider';
import Routes from '../routes';
import GlobalStyle from '../styles/global';

export default function Pages() {
    const { locale } = useSelector(({ settings }) => settings);

    const currentAppLocale = AppLocale[
        locale && locale.locale ? locale.locale : 'en'
    ] || {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    };

    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <>
                <Routes />
                <GlobalStyle />
            </>
        </IntlProvider>
    );
}
