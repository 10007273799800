import { shade } from 'polished';
import styled from 'styled-components';

export const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.35vh;

    .level-title {
        color: ${props => {
            return props.active ? '#fff' : '#444';
        }};
        text-align: left;
    }

    .active {
        color: #fff;
    }

    .MuiBadge-root .MuiBadge-dot {
        height: 16px;
        margin: 0px 0.5vw 0px 0.25vw;
        background-color: ${props => {
            return props.active ? props.color : '#444';
        }};
    }

    :not(:last-child) {
        margin-bottom: 0.5vh;
    }
`;

export const TimeChart = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    width: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1.35vh;
    height: 100%;

    .chart-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-title {
        padding: 10px 20px;
        margin: 0px;

        span {
            font-size: 1.35vh;
            color: #999;
        }
    }

    .jr-overview-row {
        width: 100%;
    }

    .jr-description {
        width: 25%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        border-left: solid 1px #555555;
        order: 2;
        margin: 0px;
        padding: 0px;
        color: #fff;
    }

    .desc-full {
        display: flex;
        flex: 1 1;
        padding: 15px;
        justify-content: center;
        align-items: center;
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info {
        display: flex;
        flex-direction: column;

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.35vh;
        }

        .value {
            font-size: 1.35vh;
            font-weight: bold;
            span {
                margin-top: 20px;
            }
        }

        .big-value {
            font-size: 2.35vh;
            font-weight: bold;
            span {
                margin-top: 20px;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;
