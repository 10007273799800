export function switchLanguage(locale) {
    return {
        type: '@settings/SWITCH_LANGUAGE',
        payload: { locale },
    };
}

export function changePluginspaceRequest(pluginspace) {
    return {
        type: '@settings/CHANGE_PLUGINSPACE_REQUEST',
        payload: { pluginspace },
    };
}

export function changePluginspaceSuccess(id, subdomain, theme, logo, locale) {
    return {
        type: '@settings/CHANGE_PLUGINSPACE_SUCCESS',
        payload: { pluginspace: { id, subdomain }, theme, logo, locale },
    };
}

export function changePluginspaceFailure() {
    return {
        type: '@settings/CHANGE_PLUGINSPACE_FAILURE',
    };
}

export function loadPluginspaceSettingsRequest(pluginspace_id) {
    return {
        type: '@settings/LOAD_PLUGINSPACE_SETTINGS_REQUEST',
        payload: { pluginspace_id },
    };
}

export function loadPluginspaceSettingsSuccess(theme, logo, apps) {
    return {
        type: '@settings/LOAD_PLUGINSPACE_SETTINGS_SUCCESS',
        payload: { theme, logo, apps },
    };
}

export function loadPluginspaceSettingsFailure() {
    return {
        type: '@settings/LOAD_PLUGINSPACE_SETTINGS_FAILURE',
    };
}

export function changeGroupRequest(active_group) {
    return {
        type: '@settings/CHANGE_GROUP_REQUEST',
        payload: { active_group },
    };
}

export function changeGroupSuccess(active_group) {
    return {
        type: '@settings/CHANGE_GROUP_SUCCESS',
        payload: { active_group },
    };
}

export function changeGroupFailure() {
    return {
        type: '@settings/CHANGE_GROUP_FAILURE',
    };
}
