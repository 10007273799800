/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import PropTypes from 'prop-types';

import { Menu } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ColorInput from '~/components/ColorInput';

import lists from '~/config/Lists';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

export default function ColorPicker({
    label,
    colorKey,
    settings,
    handleChange,
    defaultColor,
}) {
    const colors = GetPluginspaceTheme(useTheme());
    const defColor =
        defaultColor || (colors && colors.primary ? colors.primary : '#000');

    const [colorPicker, setColorPicker] = useState(false);
    const [colorAnchor, setColorAnchor] = useState(null);

    function handleClose() {
        setColorPicker(false);
        setColorAnchor(null);
    }

    function handleColorClick(event) {
        setColorAnchor(event.currentTarget);
        setColorPicker(true);
    }

    return (
        <>
            {colorPicker && colorAnchor ? (
                <Menu
                    className="p-0"
                    open={!!colorAnchor}
                    anchorEl={colorAnchor}
                    keepMounted
                    onClose={() => handleClose()}
                >
                    <SketchPicker
                        color={
                            settings[colorKey] ? settings[colorKey] : defColor
                        }
                        onChange={color => handleChange(color)}
                        onChangeComplete={color => {
                            handleChange(color);
                        }}
                        disableAlpha
                        presetColors={
                            colors
                                ? [
                                      colors.primary,
                                      colors.secondary,
                                      ...lists.default_colors,
                                  ]
                                : null
                        }
                    />
                </Menu>
            ) : null}

            <ColorInput
                margin="mt-3 mb-3"
                title={label}
                color={settings[colorKey] ? settings[colorKey] : defColor}
                handleColorClick={e => {
                    handleColorClick(e);
                }}
            />
        </>
    );
}

ColorPicker.defaultProps = {
    settings: {},
    colorKey: 'color',
    defaultColor: '',
};

ColorPicker.propTypes = {
    settings: PropTypes.object,
    colorKey: PropTypes.string,
    defaultColor: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
