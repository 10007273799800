/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdLibraryBooks } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import ContentList from './List';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

const content_type = {
    intents: 'intent',
    subjects: 'subject',
    topics: 'topic',
    defaults: 'defaults',
};

export default function QnATraining() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const user = useSelector(state => state.user);
    const { permission } = user.profile;
    const masterMode = ['master'].includes(permission);

    const date_loc = getDateLocale(settings);
    const { active } = settings;

    const pages = [
        {
            value: 'defaults',
            label: <LocaleMessage msg="page.qna.content.defaults" />,
        },
        {
            value: 'intents',
            label: <LocaleMessage msg="page.qna.content.intents" />,
        },
        {
            value: 'subjects',
            label: <LocaleMessage msg="page.qna.content.subjects" />,
        },
        {
            value: 'topics',
            label: <LocaleMessage msg="page.qna.content.topics" />,
        },
    ];

    const [queryTab, setQueryTab] = useQueryState('tab', '');
    const [value, setValue] = useState(queryTab || pages[0].value);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        setQueryTab(newValue);
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    function renderPage(page) {
        return (
            <ContentList
                masterMode={masterMode}
                pageKey={page}
                type={content_type[page] || 'other'}
                active={active}
                locale={date_loc}
                requestError={error => requestError(error)}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.qna.training.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginbot_qna',
                    title: <LocaleMessage msg="page.qna.title" />,
                },
            ]}
            loading={false}
        >
            <>
                <div className="col-12 mb-5 mt-5">
                    <CardSideBordered
                        hide
                        title={<LocaleMessage msg="page.qna.training.title" />}
                        Icon={MdLibraryBooks}
                    >
                        <>
                            <Tabs
                                indicatorColor="primary"
                                value={value || pages[0].value}
                                onChange={handleChangeTab}
                                className={classes.tabs}
                                centered
                            >
                                {pages.map(p => {
                                    return (
                                        <Tab
                                            key={p.value}
                                            label={p.label}
                                            value={p.value}
                                        />
                                    );
                                })}
                            </Tabs>

                            <div>{renderPage(value)}</div>
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

QnATraining.propTypes = {};
