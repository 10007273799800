/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {};

export default function pages(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@pages/SWITCH_VIEW': {
                const { page } = action.payload;
                draft[page] = action.payload.view;
                break;
            }
            default:
                return state;
        }
    });
}
