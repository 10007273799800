/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { Item, Title } from '../styles';

export default function GroupItem({ onClick, group }) {
    const { name, permission } = group;

    let badge = 'badge-info';
    switch (permission) {
        case 'admin':
            badge = 'badge-primary';
            break;
        case 'editor':
            badge = 'badge-info';
            break;
        case 'viewer':
            badge = 'badge-light';
            break;
        default:
            badge = 'badge-info';
            break;
    }

    return (
        <Item className="media" onClick={() => onClick(group)}>
            <div className="media-body align-self-center">
                <Title className="sub-heading mb-0">
                    {name}
                    {permission ? (
                        <span className={`badge badge-pill ${badge}`}>
                            {permission}
                        </span>
                    ) : null}
                </Title>
            </div>
        </Item>
    );
}

GroupItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
};
