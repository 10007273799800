import { getHours } from 'date-fns';

export default function getDateTimeLabel(date, options = {}) {
    const { format, dateOptions, timeOptions } = options;

    const date_format = format && format.format ? format.format : 'pt-BR';
    const date_opt = dateOptions || {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const time_opt = timeOptions || {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    const date_str = date.toLocaleDateString(date_format, date_opt);
    const date_short = date.toLocaleDateString(date_format, {
        month: '2-digit',
        day: '2-digit',
    });
    const time_str = date.toLocaleTimeString([], time_opt);
    const hour_str = getHours(date);

    return {
        short: date_short,
        date: date_str,
        time: time_str,
        hour: hour_str,
        string: `${date_str} - ${time_str}`,
        timestamp: date.toISOString(),
    };
}
