/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';
import ParseDashboardData from '~/util/ParseDashboardData';

import DataCard from '../DataCard';

const operation_mode_labels = {
    uvc: 'list.operations.mode.uvc',
    o3: 'list.operations.mode.o3',
    uvc_o3: 'list.operations.mode.uvc_o3',
    air: 'list.operations.mode.air',
    floor: 'list.operations.mode.floor',
};

export default function DisinfectionsCard({
    dataValues,
    requestError,
    dateSettings,
    userSettings,
    getFilterInfo,
}) {
    const lang = dateSettings.locale ? dateSettings.locale.code : 'pt_BR';
    const lng_ops = lng_labels[lang];
    const { active, colors } = userSettings;

    const availableFilters = [
        { label: 'page.dashboard.op_mode', value: 'mode' },
        { label: 'page.dashboard.robots', value: 'robot_id' },
        { label: 'page.dashboard.applications', value: 'application_id' },
    ];
    if (active && active.id === '*') {
        availableFilters.unshift({
            label: 'page.dashboard.groups',
            value: 'group_id',
        });
    }

    const [isLoading, setIsLoading] = useState(true);

    const [rawData, setRawData] = useState({ list: [] });
    const [parsedData, setParsedData] = useState({});
    const [groupedData, setGroupedData] = useState([]);

    const [activeFilter, setActiveFilter] = useState(availableFilters[0].value);

    async function loadData() {
        setIsLoading(true);
        await api
            .get(`dashboards/disinfections/period`)
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function filterData(filter) {
        const list = rawData && rawData.list ? rawData.list : [];

        let infoData = {};
        switch (filter) {
            case 'group_id':
                infoData = getFilterInfo(dataValues.groups);
                break;
            case 'robot_id':
                infoData = getFilterInfo(dataValues.robots);
                break;
            case 'application_id':
                infoData = getFilterInfo(dataValues.applications);
                break;
            case 'mode': {
                const modeInfos = {};
                list.forEach(m => {
                    const idx = m[filter];
                    if (!modeInfos[idx]) {
                        const op_key = operation_mode_labels[idx];
                        modeInfos[idx] = lng_ops[op_key] || '---';
                    }
                });
                infoData = modeInfos;
                break;
            }
            default:
                break;
        }

        const f_data = {};
        list.forEach(m => {
            const idx = m[filter];
            f_data[idx] = f_data[idx] ? f_data[idx] + 1 : 1;
        });

        if (infoData) {
            const grouped = Object.keys(f_data).map(f => {
                const val = f_data[f];
                const name = infoData[f] || '---';
                return {
                    name,
                    value: val,
                };
            });

            setGroupedData(grouped);
        }
    }

    function handleDateFilterChange(value) {
        setActiveFilter(value);
    }

    useEffect(() => {
        filterData(activeFilter);
    }, [dataValues, activeFilter, rawData]);

    useEffect(() => {
        setParsedData(ParseDashboardData(rawData, dateSettings));
    }, [rawData, dateSettings]);

    useEffect(() => {
        loadData();
    }, [active]);

    const card_title = {
        title: <LocaleMessage msg="page.dashboard.title.disinfection_logs" />,
        subtitle: (
            <LocaleMessage msg="page.dashboard.disinfections_period_30" />
        ),
        value: `${parsedData.total || '0'}`,
        color: colors.dashboard_graphs,
        link: '/reports/disinfections',
    };

    const card_data = {
        data: parsedData.list || [],
        labels: parsedData.labels || [],
        key: 'value',
    };

    const card_filter = {
        available: availableFilters,
        current: activeFilter || 'application_id',
        grouped: groupedData,
    };

    return (
        <DataCard
            loading={isLoading}
            card={card_title}
            data={card_data}
            filter={card_filter}
            handleDateFilterChange={value => handleDateFilterChange(value)}
        />
    );
}

DisinfectionsCard.defaultProps = {
    requestError: () => {},
    dateSettings: {},
    userSettings: {},
    dataValues: {},
    getFilterInfo: () => {},
};

DisinfectionsCard.propTypes = {
    requestError: PropTypes.func,
    getFilterInfo: PropTypes.func,
    dateSettings: PropTypes.object,
    userSettings: PropTypes.object,
    dataValues: PropTypes.object,
};
