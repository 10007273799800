/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdFormatListBulleted,
    MdWarningAmber,
    MdAdd,
    MdDelete,
    MdDeleteSweep,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    DialogContentText,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
// import FormCheckList from '~/components/Form/CheckList';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
// import getDateLocale from '~/util/GetDateLocale';
// import GetFileName from '~/util/GetFileName';

import { ActionItem } from './styles';

const allowed_robots = ['cruzr', 'temi'];

const not_random_conditions = ['go_base', 'exit_base'];

const warnings = {
    cruzr: ['warnings.version.cruzr_v390'],
    temi: ['warnings.version.temi_v220'],
};

export default function AutonomousModeForm(props) {
    const dispatch = useDispatch();
    const user_settings = useSelector(state => state.settings || null);
    const { admin_groups, active } = user_settings;

    const { match } = props;
    const { id } = match.params;

    const [operation, setOperation] = useState('create');
    const [availableRobots, setAvailableRobots] = useState([]);

    const [body, setBody] = useState({
        name: '',
        group_id: active ? active.id : null,
        group_name: active ? active.name : '',
    });

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteActionOpen, setDeleteActionOpen] = useState('');
    const [needSaving, setNeedSaving] = useState(false);

    const [rawList, setRawList] = useState([]);
    const [parsedList, setParsedList] = useState([]);

    const [currItem, setCurrItem] = useState('new');

    const [mapList, setMapList] = useState([]);
    const [robotType, setRobotType] = useState({});
    const [selectedRobots, setSelectedRobots] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    // const [templateFile, setTemplateFile] = useState({});

    const pack_content = body.content || {};

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getConditionLabel(c) {
        switch (c.type) {
            case 'timeout':
                return `T - ${c.value}${c.unit === 'minutes' ? 'm' : 's'}`;
            case 'battery_lower':
                return `B < ${c.value}%`;
            case 'charging_higher':
                return `B > ${c.value}%`;
            default:
                return c.type;
        }
    }

    function getAlert(item) {
        const a_type = item.action_type || '';
        const a_settings = item.settings || {};

        const nav_alert_functions = ['navigate', 'go_base', 'exit_base'];
        const pack_nav = pack_content.navigation || {};

        if (nav_alert_functions.includes(a_type)) {
            if (!pack_nav.active || !pack_nav.map_id) return true;

            if (a_settings.map_id && pack_nav.map_id !== a_settings.map_id)
                return true;
            return false;
        }
        return false;
    }

    function parseList() {
        const parsed_list = rawList.map(a => {
            const condition_list =
                a.settings && a.settings.conditions
                    ? a.settings.conditions
                    : [];
            const condition_str = condition_list
                .map(c => {
                    return getConditionLabel(c);
                })
                .join(', ');

            const label = (
                <>
                    {getAlert(a) ? (
                        <>
                            <span>
                                {alert ? (
                                    <MdWarningAmber
                                        size={20}
                                        className="mr-2"
                                        color="#f00"
                                    />
                                ) : null}
                            </span>
                        </>
                    ) : null}
                    <LocaleMessage
                        msg={`page.tools.autonomous_actions.actions.${a.action_type}`}
                    />
                    {a.description ? <span>: {a.description}</span> : null}
                </>
            );

            const show_random_label =
                (!pack_content.mode || pack_content.mode === 'random') &&
                !not_random_conditions.includes(a.action_type);

            return {
                id: a.id,
                enabled: a.enabled,
                description: a.description,
                action_type: a.action_type,
                label,
                settings: a.settings,
                condition_list,
                condition_label: show_random_label ? (
                    <LocaleMessage msg="page.tools.autonomous_actions.form.mode.random" />
                ) : (
                    condition_str
                ),
            };
        });
        setParsedList(parsed_list);
    }

    async function loadAvailableRobots() {
        await api
            .get(`types?group=robot`)
            .then(response => {
                const { data } = response;
                const robots = data.filter(s_r => {
                    return allowed_robots.includes(s_r.slug);
                });
                setAvailableRobots(robots || []);
            })
            .catch(error => requestError(error));
    }

    async function loadPack(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`autonomous_mode/${_id}`)
                .then(response => {
                    const s = response.data;
                    const r_types = s.robot_types || [];
                    const sel_robots = r_types.map(r => {
                        return r.slug;
                    });
                    setSelectedRobots(sel_robots);

                    setBody({
                        name: s.name,
                        description: s.description,
                        robottype_id: s.robot ? s.robot.id : null,
                        group_id: s.group ? s.group.id : null,
                        group_name: s.group ? s.group.name : '---',
                        content: s.content,
                    });
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadPackActions(_id) {
        if (_id !== 'new') {
            await api
                .get(`/autonomous_mode/${_id}/actions`)
                .then(response => {
                    const { data } = response;
                    setRawList(data);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadMaps() {
        if (body.group_id !== '*') {
            setIsLoading(true);
            await api
                .get(`map_layers`)
                .then(async response => {
                    const filtered = response.data.filter(m => {
                        return m.group && m.group.id === body.group_id;
                    });
                    const data = filtered.map(m => ({
                        ...m,
                        name: m.map ? m.map.name : '',
                        label: `${m.map ? m.map.name : ''} [${m.reference}]`,
                        r_type: m.robot_type ? m.robot_type.slug : '',
                    }));
                    setMapList(data);
                })
                .catch(error => requestError(error));
        } else {
            setMapList([]);
        }
        setIsLoading(false);
    }

    // async function loadLanguages() {
    //     await api
    //         .get(`applications/voices`)
    //         .then(response => {
    //             const v = response.data;
    //             const lang_list = [];
    //             Object.keys(v).forEach(t => {
    //                 const t_voices = v[t];
    //                 t_voices.forEach(voice => {
    //                     lang_list.push({
    //                         type: t,
    //                         key: `${t}_${voice.value}`,
    //                         ...voice,
    //                     });
    //                 });
    //             });
    //             setLanguages(lang_list);
    //         })
    //         .catch(err => requestError(err));
    // }

    useEffect(() => {
        loadAvailableRobots();
        // loadLanguages();
    }, []);

    useEffect(() => {
        loadPack(id);
        loadPackActions(id);
    }, [id]);

    useEffect(() => {
        parseList();
    }, [body.content, rawList, selectedRobots]);

    useEffect(() => {
        loadMaps();
        const r_type = availableRobots.find(t => {
            return t.id === body.robottype_id;
        });
        setRobotType(r_type || {});
    }, [body.robottype_id, body.group_id]);

    function setSettings(key, value) {
        setNeedSaving(true);
        const updated = {
            ...body,
            content: {
                ...pack_content,
                [key]: value,
            },
        };
        setBody(updated);
    }

    async function updateAutonomousPack(data) {
        await api
            .put(`autonomous_mode/${id}`, data)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.autonomous_actions.form.update_success" />
                );
                await Promise.all([loadPack(id), loadPackActions(id)]);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = {
            ...body,
            robot_types: selectedRobots,
        };

        setIsLoading(true);
        if (operation === 'create') {
            await api
                .post(`autonomous_mode`, data)
                .then(response => {
                    const a_data = response.data;
                    toast.success(
                        <LocaleMessage msg="page.tools.autonomous_actions.form.create_success" />
                    );

                    history.push(`/tools/autonomous_actions/${a_data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateAutonomousPack({
                ...data,
            });
        }
        setNeedSaving(false);
    }

    async function deleteActionsMulti() {
        const action_list = JSON.stringify(selectedRows);
        await api
            .delete(`autonomous_mode/${id}/actions?list=${action_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.autonomous_actions.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteAction(action_id) {
        await api
            .delete(`autonomous_mode/${id}/actions/${action_id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteActions() {
        setIsLoading(true);
        if (deleteActionOpen === 'single') {
            await deleteAction(currItem);
        } else {
            await deleteActionsMulti();
        }
        await loadPackActions(id);
        setDeleteActionOpen('');
        setSelectedRows([]);
        setIsLoading(false);
    }

    // const onFileUpload = async event => {
    //     const fileObj = event.target.files[0];
    //     const reader = new FileReader();

    //     let fileloaded = e => {
    //         const fileContents = e.target.result;
    //         const template = JSON.parse(fileContents);

    //         setTemplateFile({
    //             id: fileObj.name,
    //             name: fileObj.name,
    //         });

    //         if (
    //             template.content_type &&
    //             template.content_type === 'autonomous_actions'
    //         ) {
    //             setSelectedRobots(template.robot_types);
    //             setBody({
    //                 ...body,
    //                 ...(!body.name && { name: template.name }),
    //                 ...(!body.description && {
    //                     description: template.description,
    //                 }),
    //                 type: 'autonomous_actions',
    //                 content: template.content || {},
    //                 template: {
    //                     actions: template.actions,
    //                 },
    //             });
    //         } else {
    //             toast.error(
    //                 <LocaleMessage msg="page.tools.autonomous_actions.form.import_error" />
    //             );
    //         }
    //     };

    //     // Mainline of the method
    //     fileloaded = fileloaded.bind(this);
    //     reader.onload = fileloaded;
    //     reader.readAsText(fileObj);
    // };

    function handleDeleteActionClose(event) {
        event.preventDefault();
        setSelectedRows([]);
        setDeleteActionOpen('');
    }

    function handleDeleteMultiOpen(event) {
        setCurrItem(null);
        event.preventDefault();
        setDeleteActionOpen('multi');
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteActionOpen('single');
        setSelectedRows([]);
    }

    function handleActionSelection(event, _id) {
        setSelectedRows([]);
        setCurrItem(_id);
        if (!needSaving) {
            history.push(`/tools/autonomous_actions/${id}/actions/${_id}`);
        } else {
            toast.warning(<LocaleMessage msg="label.save_first" />);
        }
    }

    function buildDeleteActionDialog() {
        return (
            <SimpleDialog
                open={!!deleteActionOpen}
                onClose={handleDeleteActionClose}
                title={
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteActionOpen(''),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteActions(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    // function renderLanguageConfig() {
    //     const options = languages.map(l => {
    //         return {
    //             id: l.value,
    //             name: <LocaleMessage msg={`list.languages.${l.value}`} />,
    //         };
    //     });

    //     const content = body.content || {};

    //     return (
    //         <div className="col-12 mb-3">
    //             <LocaleMessage msg="page.tools.autonomous_actions.form.label.languages" />
    //             <FormCheckList
    //                 collapsable
    //                 options={options}
    //                 settings={content.languages || {}}
    //                 multiple
    //                 onChange={l => {
    //                     setSettings('languages', l);
    //                 }}
    //                 disabled={body.use_template}
    //             />
    //         </div>
    //     );
    // }

    function renderActionList() {
        const headers = [
            {
                id: 'label',
                label: <LocaleMessage msg="table.headers.action" />,
            },
            {
                id: 'condition_label',
                label: (
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.conditions" />
                ),
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    data={parsedList}
                    orderColumn="order"
                    headerColumns={headers}
                    rowActions={rowActions}
                    handleTableRowClick={(event, _id) => {
                        handleActionSelection(event, _id);
                    }}
                    hasHeader
                    header={
                        <div className="col-12" style={{ padding: '0px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={event =>
                                    handleActionSelection(event, 'new')
                                }
                            >
                                <MdAdd
                                    size={20}
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                                <LocaleMessage msg="page.tools.autonomous_actions.actions.list.add" />
                            </Button>
                        </div>
                    }
                    sortable
                    selectable
                    selectedActions={selAction}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    function renderNavigationConfig() {
        const content = body.content || {};
        const navigation = content.navigation || {};
        const r_filtered = mapList.filter(m => {
            return (
                !m.robot_type ||
                (body.robottype_id && m.robot_type.id === body.robottype_id)
            );
        });

        return (
            <div className="col-12 mb-3 row">
                <div className="col-md-6 col-12">
                    <FormSwitch
                        value={navigation.active ? navigation.active : false}
                        onChange={event =>
                            setSettings('navigation', {
                                ...navigation,
                                active: event.target.checked,
                            })
                        }
                        label={
                            <LocaleMessage msg="page.tools.autonomous_actions.form.label.navigation.active" />
                        }
                    />
                </div>
                {navigation.active ? (
                    <div className="col-md-6 col-12 mb-1">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.tools.autonomous_actions.form.label.navigation.map" />
                            </InputLabel>
                            <Select
                                value={navigation.map_id || ''}
                                onChange={event =>
                                    setSettings('navigation', {
                                        ...navigation,
                                        map_id: event.target.value,
                                    })
                                }
                                disabled={!navigation.active}
                            >
                                {r_filtered.map(m => (
                                    <MenuItem key={m.id} value={m.id}>
                                        {m.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}
            </div>
        );
    }

    function renderGroupSelector() {
        const group_list = admin_groups || [
            {
                id: active ? active.id : null,
                name: active ? active.name : '',
            },
        ];
        return (
            <div className="col-md-6 col-12 mb-5">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="label.form.group" />
                    </InputLabel>
                    <Select
                        id="group"
                        value={
                            body.group_id && body.group_id ? body.group_id : ''
                        }
                        onChange={event =>
                            setBody({
                                ...body,
                                group_id: event.target.value,
                            })
                        }
                        disabled={operation === 'update' || body.open_all}
                    >
                        {operation === 'create' ? (
                            group_list.map(g => (
                                <MenuItem value={g.id} key={g.id}>
                                    {g.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value={body.group_id}>
                                {body.group_name ? (
                                    body.group_name
                                ) : (
                                    <LocaleMessage msg="message.all.short" />
                                )}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
        );
    }

    // function renderImportTemplate() {
    //     return (
    //         <>
    //             <div className="col-12 mb-3">
    //                 <FormSwitch
    //                     value={body.use_template || false}
    //                     onChange={event =>
    //                         setBody({
    //                             ...body,
    //                             use_template: event.target.checked,
    //                         })
    //                     }
    //                     label={
    //                         <LocaleMessage msg="page.tools.autonomous_actions.form.template.use" />
    //                     }
    //                 />
    //             </div>
    //             {body.use_template ? (
    //                 <div
    //                     className="col-12 mb-5"
    //                     style={{
    //                         justifyContent: 'center',
    //                         flexDirection: 'column',
    //                     }}
    //                 >
    //                     <div
    //                         style={{
    //                             height: 'max-content',
    //                         }}
    //                         className="mb-3"
    //                     >
    //                         <FileInput
    //                             defaultValue={templateFile || {}}
    //                             onFileUpload={onFileUpload}
    //                             text={
    //                                 <LocaleMessage msg="label.form.upload_file" />
    //                             }
    //                             type="json"
    //                             multiple={false}
    //                             showFooter={false}
    //                             showIcon={false}
    //                         />
    //                     </div>
    //                     <div className="mt-1 mb-3">
    //                         <LocaleMessage msg="page.tools.autonomous_actions.form.template.warning" />
    //                     </div>
    //                 </div>
    //             ) : null}
    //         </>
    //     );
    // }

    function renderRandomMode() {
        return (
            <>
                <div className="col-12 mb-3 p-0 row">
                    <ActionItem className="col-6">
                        <TextField
                            fullWidth
                            value={
                                pack_content.timeout ? pack_content.timeout : ''
                            }
                            onChange={event =>
                                setSettings('timeout', event.target.value)
                            }
                            type="number"
                            inputProps={{
                                min: 1,
                                step: 1,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocaleMessage msg="label.time.timeout.each" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </ActionItem>
                    <div className="col-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel />
                            <Select
                                value={
                                    pack_content && pack_content.time_unit
                                        ? pack_content.time_unit
                                        : 'seconds'
                                }
                                onChange={event =>
                                    setSettings('time_unit', event.target.value)
                                }
                            >
                                <MenuItem value="seconds">
                                    <LocaleMessage msg="label.time.seconds" />
                                </MenuItem>
                                <MenuItem value="minutes">
                                    <LocaleMessage msg="label.time.minutes" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </>
        );
    }

    function renderConditionalMode() {
        const type_slug = robotType && robotType.slug ? robotType.slug : '';
        const type_warning = warnings[type_slug];

        return (
            <>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={
                            pack_content.reset_on_interaction
                                ? pack_content.reset_on_interaction
                                : false
                        }
                        onChange={event =>
                            setSettings(
                                'reset_on_interaction',
                                event.target.checked
                            )
                        }
                        label={
                            <>
                                <LocaleMessage msg="page.tools.autonomous_actions.form.mode.conditional.reset" />
                                {type_warning ? <span> *</span> : null}
                            </>
                        }
                    />
                    {type_warning ? (
                        <div>
                            <LocaleMessage msg={type_warning} />
                        </div>
                    ) : null}
                </div>
            </>
        );
    }

    function renderMainSettings() {
        return (
            <div className="col-12 mb-3 row">
                <div className="col-12 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.tools.autonomous_actions.form.mode" />
                        </InputLabel>
                        <Select
                            value={
                                pack_content.mode ? pack_content.mode : 'random'
                            }
                            onChange={event =>
                                setSettings('mode', event.target.value)
                            }
                        >
                            <MenuItem value="random">
                                <LocaleMessage msg="page.tools.autonomous_actions.form.mode.random" />
                            </MenuItem>
                            <MenuItem value="conditional">
                                <LocaleMessage msg="page.tools.autonomous_actions.form.mode.conditional" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div
                    className="col-12 mb-3"
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <span>
                        <LocaleMessage
                            msg={`page.tools.autonomous_actions.form.mode.${pack_content.mode ||
                                'random'}.description`}
                        />
                    </span>
                </div>

                {!pack_content.mode || pack_content.mode === 'random'
                    ? renderRandomMode()
                    : null}

                {pack_content.mode === 'conditional'
                    ? renderConditionalMode()
                    : null}
            </div>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage
                    msg={`page.tools.autonomous_actions.form.${
                        operation === 'create' ? 'create' : 'edit'
                    }.title`}
                />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/autonomous_actions',
                    title: (
                        <LocaleMessage msg="page.tools.autonomous_actions.list.title" />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteActionOpen ? buildDeleteActionDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`col-md-${
                            operation === 'update' ? '6' : '8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.tools.autonomous_actions.form.title" />
                            }
                            Icon={MdFormatListBulleted}
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    {renderGroupSelector()}

                                    <div className="col-12 row">
                                        <div className="col-md-6 col-12 mb-5">
                                            <TextField
                                                label={
                                                    <LocaleMessage msg="page.tools.autonomous_actions.form.label.name" />
                                                }
                                                fullWidth
                                                value={body.name || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        name:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6 col-12 mb-5">
                                            <FormControl fullWidth>
                                                <InputLabel>
                                                    <LocaleMessage msg="label.form.robot_type" />
                                                </InputLabel>
                                                <Select
                                                    id="robot_type"
                                                    value={
                                                        body.robottype_id || ''
                                                    }
                                                    onChange={event =>
                                                        setBody({
                                                            ...body,
                                                            robottype_id:
                                                                event.target
                                                                    .value,
                                                        })
                                                    }
                                                    disabled={
                                                        operation === 'update'
                                                    }
                                                >
                                                    {availableRobots.map(r => (
                                                        <MenuItem
                                                            value={r.id}
                                                            key={r.id}
                                                        >
                                                            {r.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.tools.autonomous_actions.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    {/* {operation === 'create'
                                        ? renderImportTemplate()
                                        : null} */}

                                    {body.robottype_id ? (
                                        <>
                                            {renderNavigationConfig()}

                                            {renderMainSettings()}
                                        </>
                                    ) : null}

                                    <div className="col-12">
                                        <Button
                                            className="p-3 mb-4"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                            disabled={!body.robottype_id}
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.tools.autonomous_actions.actions.list.title" />
                                    }
                                    hide
                                    Icon={MdFormatListBulleted}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <>{renderActionList()}</>
                                        </div>
                                    </div>
                                </CardSideBordered>
                            </div>
                        </>
                    ) : null}
                </form>
            </>
        </PageContent>
    );
}

AutonomousModeForm.propTypes = {
    match: PropTypes.object.isRequired,
};
