/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import CustomScrollbars from '~/components/CustomScrollBar';
import LocaleMessage from '~/components/LocaleMessage';

import MapItem from './MapItem';
import { ListPanel, SearchDiv, ListDiv } from './styles';

export default function MapList({
    maps,
    locations,
    onItemClick,
    currentActive,
}) {
    const [currLocation, setCurrLocation] = useState(null);
    const [currZone, setCurrZone] = useState(null);
    const [filteredData, setFilteredData] = useState(maps);

    function filterData() {
        const f_data = maps.filter(m => {
            const { location, zone } = m;
            const location_id = location ? location.id : null;
            const zone_id = zone ? zone.id : null;

            return (
                !currLocation ||
                location_id === currLocation.id ||
                !currLocation ||
                zone_id === currLocation.id
            );
        });

        const f_map = f_data.find(m => {
            return currentActive && m.id === currentActive;
        });
        if (!f_map) {
            onItemClick(null, null);
        }
        setFilteredData(f_data);
    }

    function handleLocationChange(val) {
        if (!val || !locations) {
            setCurrLocation(null);
            setCurrZone(null);
            return;
        }
        if (val !== currLocation) {
            const location = locations.find(l => {
                return l.id === val;
            });
            setCurrZone(null);
            setCurrLocation(location);
        }
    }

    function handleZoneChange(val) {
        if (!val || !currLocation) {
            setCurrZone(null);
            return;
        }
        if (val !== currZone) {
            const zones = currLocation.zones || [];
            const zone = zones.find(z => {
                return z.id === val;
            });
            setCurrZone(zone);
        }
    }

    useEffect(() => {
        filterData();
    }, [maps, currLocation, currZone]);

    return (
        <ListPanel>
            <SearchDiv>
                <FormControl variant="filled" fullWidth>
                    <InputLabel style={{ color: '#fff' }}>
                        <LocaleMessage msg="rc3.label.location" />
                    </InputLabel>
                    <Select
                        value={currLocation ? currLocation.id : '*'}
                        onChange={event => {
                            handleLocationChange(event.target.value);
                        }}
                        label="Location"
                        style={{ color: '#fff' }}
                    >
                        <MenuItem value="*">
                            <LocaleMessage msg="label.all" />
                        </MenuItem>
                        {locations.map(l => {
                            return (
                                <MenuItem key={l.id} value={l.id}>
                                    {l.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth>
                    <InputLabel style={{ color: '#fff' }}>
                        <LocaleMessage msg="rc3.label.zone" />
                    </InputLabel>
                    <Select
                        value={currLocation && currZone ? currZone.id : '*'}
                        onChange={event => {
                            handleZoneChange(event.target.value);
                        }}
                        label="Zone"
                        style={{ color: '#fff' }}
                    >
                        <MenuItem value="*">
                            <LocaleMessage msg="label.all" />
                        </MenuItem>
                        {currLocation && currLocation.zones
                            ? currLocation.zones.map(z => {
                                  return (
                                      <MenuItem key={z.id} value={z.id}>
                                          {z.name}
                                      </MenuItem>
                                  );
                              })
                            : null}
                    </Select>
                </FormControl>
            </SearchDiv>
            <ListDiv>
                <CustomScrollbars>
                    <ul>
                        {filteredData.map(m => {
                            return (
                                <MapItem
                                    object={m}
                                    active={currentActive === m.id}
                                    onListItemClick={(event, obj) => {
                                        onItemClick(event, obj);
                                    }}
                                    key={m.id}
                                />
                            );
                        })}
                    </ul>
                </CustomScrollbars>
            </ListDiv>
        </ListPanel>
    );
}

MapList.propTypes = {
    maps: PropTypes.array,
    locations: PropTypes.array,
    onItemClick: PropTypes.func,
    currentActive: PropTypes.string,
};

MapList.defaultProps = {
    maps: [],
    locations: [],
    onItemClick: () => {},
    currentActive: '',
};
