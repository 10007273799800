/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdCancel, MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';

import DataTable from '~/components/DataTable';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

export default function ContactUsers({
    contact_id,
    requestError,
    allowEdit,
    group_id,
}) {
    const [bindingOperation, setBindingOperation] = useState('create');

    const [currUser, setCurrUser] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [availableUsers, setAvailableUsers] = useState([]);
    const [contactUsers, setContactUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    async function loadAvailableUsers(_id) {
        await api
            .get(`/groups/${_id}/users`)
            .then(response => {
                const { data } = response;
                const u_list = data.users || [];
                const users = u_list.map(u => {
                    return {
                        id: u.id,
                        name: u.name,
                        email: u.email,
                    };
                });
                setAvailableUsers(users);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadContactUsers(_id) {
        await api
            .get(`/contacts/${_id}/users`)
            .then(response => {
                const u_list = response.data.users;
                const users = u_list.map(u => {
                    return {
                        id: u.id,
                        name: u.name,
                        email: u.email,
                    };
                });
                setContactUsers(users);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        if (group_id) {
            loadAvailableUsers(group_id);
        }
    }, [group_id]);

    useEffect(() => {
        loadContactUsers(contact_id);
    }, [contact_id]);

    async function bindUser() {
        return api
            .post(`/contacts/${contact_id}/users`, { user_id: currUser })
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function unbindUser() {
        return api
            .delete(`/contacts/${contact_id}/users/${currUser}`)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function submitBinding() {
        setIsLoading(true);
        setDialogOpen(false);

        let success = true;
        if (bindingOperation === 'create') {
            success = await bindUser();
        } else {
            success = await unbindUser();
        }

        if (success) {
            toast.success(
                <LocaleMessage msg="page.tools.contacts.form.update_success" />
            );
        }

        loadContactUsers(contact_id);
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setCurrUser('');
        setDialogOpen(false);
    }

    function handleDialogOpen(event, _id) {
        event.preventDefault();
        setCurrUser(_id === 'new' ? '' : _id);
        setBindingOperation(_id === 'new' ? 'create' : 'delete');
        setDialogOpen(true);
    }

    function renderBindingForm() {
        const used = {};
        contactUsers.forEach(u => {
            used[u.id] = true;
        });
        const notBound = availableUsers.filter(u => {
            return !used[u.id];
        });

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span className="mb-3">
                    <LocaleMessage msg="page.tools.contacts.form.label.user.helper" />
                </span>
                <div className="col-md-8 col-12">
                    {notBound && notBound.length > 0 ? (
                        <FormSelect
                            classes="mb-5"
                            options={notBound}
                            labelKey="name"
                            label={
                                <LocaleMessage msg="page.tools.contacts.form.label.user.choose" />
                            }
                            onChange={value => {
                                setCurrUser(value);
                            }}
                            value={currUser || ''}
                        />
                    ) : (
                        <FormControl fullWidth className="mb-5">
                            <InputLabel>
                                <LocaleMessage msg="page.tools.contacts.form.label.user.choose" />
                            </InputLabel>
                            <Select>
                                <MenuItem value="" disabled>
                                    <LocaleMessage msg="page.tools.contacts.form.label.user.no_free_users" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </div>
            </div>
        );
    }

    function renderUnbindingForm() {
        const user = contactUsers.find(u => {
            return u.id === currUser;
        });

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="body1">
                    <LocaleMessage msg="page.tools.contacts.form.label.user.unbind.prefix" />
                    <strong>{` ${user.name} `}</strong>
                    <LocaleMessage msg="page.tools.contacts.form.label.user.unbind.sufix" />
                </Typography>
            </div>
        );
    }

    function renderDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event)}
                title={
                    <LocaleMessage
                        msg={`page.tools.contacts.form.label.user.${
                            bindingOperation === 'create' ? 'bind' : 'unbind'
                        }`}
                    />
                }
                content={
                    bindingOperation === 'create'
                        ? renderBindingForm()
                        : renderUnbindingForm()
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitBinding(),
                        label: (
                            <LocaleMessage
                                msg={
                                    bindingOperation === 'create'
                                        ? 'button.add'
                                        : 'button.remove'
                                }
                            />
                        ),
                    },
                ]}
            />
        );
    }

    const headCells = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
    ];

    const rowActions = [
        {
            id: 'delete',
            label: <LocaleMessage msg="button.delete" />,
            icon: <MdCancel />,
            action: handleDialogOpen,
        },
    ];

    return (
        <>
            {dialogOpen ? renderDialog() : null}
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <div className="body-top-controls">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAdd />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={event => handleDialogOpen(event, 'new')}
                            disabled={!allowEdit}
                        >
                            <LocaleMessage msg="page.tools.contacts.form.label.user.add" />
                        </Button>
                    </div>
                    <div
                        style={{
                            minHeight: '150px',
                            width: '100%',
                            padding: '15px',
                        }}
                    >
                        <DataTable
                            headerColumns={headCells}
                            data={contactUsers}
                            orderColumn="name"
                            rowActions={rowActions}
                        />
                    </div>
                </>
            )}
        </>
    );
}

ContactUsers.propTypes = {
    contact_id: PropTypes.string.isRequired,
    requestError: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    group_id: PropTypes.string,
};

ContactUsers.defaultProps = {
    group_id: '',
};
