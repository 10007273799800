import { transparentize } from 'polished';
import styled from 'styled-components';

export const ParameterArea = styled.div`
    padding: 10px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;

export const OptionArea = styled.div`
    width: 100%;

    .area-content {
        /* background-color: #eee; */
        border: 1px solid #eee;
        width: 100%;
        padding: 10px;
        margin: 10px 0px;
    }

    .option-row {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .option-card {
        margin: 10px 0px;
        width: 100%;
    }

    .option-button {
        width: 100%;
        padding: 20px;
        color: ${props => props.color} !important;
        border: 1px solid ${props => props.color} !important;

        .MuiChip-deleteIconOutlinedColorPrimary {
            color: ${props => props.color} !important;
        }

        span {
            font-size: 20px;
        }

        :hover {
            cursor: pointer;
            background: ${props =>
                transparentize(0.75, props.color)} !important;
        }
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }
`;

export const MediaArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;

    .img-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
`;

export const OptionCard = styled.div`
    .card-body {
        min-height: 200px;
        width: 100%;
        display: flex;
        align-items: flex-top;
        justify-content: space-between;
        padding: 10px;
    }

    .card-image {
        background: #eee;
        min-height: 200px;
        padding: 0px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;

            :hover {
                cursor: pointer;
            }
        }
    }

    .card-content {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-around;
    }
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        margin: 0px;
        background-color: #fff;
    }
`;
