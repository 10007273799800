/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import {
    Area,
    AreaChart,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';

import { ChartCard } from './style';

export default function ReportDateChart({
    classes,
    title,
    color,
    data,
    labels,
    dataKey,
    customization = {},
}) {
    const { tooltip, margins } = customization;
    return (
        <ChartCard
            className={`${classes ||
                'col-md-6 col-12 mb-3'} area-chart-container`}
        >
            <div>
                <p className="card-title">{title}</p>
            </div>
            <ResponsiveContainer
                className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                width="100%"
                height={300}
            >
                <AreaChart
                    data={data || []}
                    labels={labels}
                    margin={
                        margins || {
                            top: 30,
                            right: 0,
                            left: -20,
                        }
                    }
                >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="date" />
                    <YAxis allowDecimals={false} />
                    <Tooltip content={tooltip} />
                    <Area
                        dataKey={dataKey}
                        stroke={color}
                        fill={`${hexToRgba(color, 0.7)}`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </ChartCard>
    );
}

ReportDateChart.defaultProps = {
    data: [],
    labels: [],
    classes: '',
    title: null,
    color: '',
    dataKey: 'value',
    customization: {},
};

ReportDateChart.propTypes = {
    data: PropTypes.array,
    labels: PropTypes.array,
    classes: PropTypes.string,
    title: PropTypes.element,
    color: PropTypes.string,
    dataKey: PropTypes.string,
    customization: PropTypes.object,
};
