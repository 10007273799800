/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { MdPeople, MdSatellite, MdCheck } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, TextField, Typography } from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';

import GroupUsers from './UserList';

const centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function GroupForm(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const user_groups = user ? user.groups : [];

    const { match } = props;
    const { id } = match.params;

    const { permission } = user.profile;

    const thisGroup = user_groups.find(g => {
        return g.id === id;
    });

    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);
    const allowEdit =
        adminMode || (thisGroup && thisGroup.permission === 'admin');

    const [allowRC3, setAllowRC3] = useState(false);
    const [principal, setPrincipal] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState({
        name: '',
        description: '',
    });
    const [file, setFile] = useState({
        id: null,
        url: null,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(true);

    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setInternalLoading(false);
        setIsLoading(false);
    }

    async function verifyRC3() {
        await api
            .get(`rc3/pluginspaces/verify`)
            .then(response => {
                const { data } = response;
                setAllowRC3(data.active);
            })
            .catch(() => {});
    }

    async function loadRC3Principal(_id) {
        setInternalLoading(true);
        if (!_id) {
            setPrincipal({ active: false });
        } else {
            await api
                .get(`rc3/groups/${_id}`)
                .then(response => {
                    const c = response.data;

                    if (c.id) {
                        setPrincipal(c);
                    } else {
                        setPrincipal({ active: false });
                    }
                })
                .catch(error => requestError(error));
        }
        setInternalLoading(false);
    }

    async function loadGroup(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            verifyRC3();
            await api
                .get(`groups/${_id}`)
                .then(response => {
                    const g = response.data;
                    setBody({
                        name: g.name,
                        description: g.description,
                    });
                    setFile(g.file);

                    loadRC3Principal(_id);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadGroup(id);
    }, [id]);

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleRevokeOpen(event, _id) {
        event.preventDefault();
        setDialogOpen(true);
    }

    async function updateGroup(data) {
        await api
            .put(`groups/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.groups.form.update_success" />
                );
                dispatch(loadGroupsRequest());
            })
            .catch(error => requestError(error));
        loadGroup(id);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`groups`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.groups.form.create_success" />
                    );

                    dispatch(loadGroupsRequest());
                    history.push(`/pluginspace/groups`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await updateGroup(data);
        }
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`groups/${id}/media`, data)
            .then(response => {
                const updated = { ...body, file_id: response.data.id };
                setBody(updated);
                updateGroup(updated);
            })
            .catch(error => requestError(error));
    };

    function fileInput() {
        return (
            <div
                className="row col-12"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="col-md-8 col-12 mb-3"
                    style={{ height: '200px', justifyContent: 'center' }}
                >
                    <FileInput
                        defaultValue={file}
                        onFileUpload={onFileUpload}
                        multiple={false}
                        text={<LocaleMessage msg="message.change_file" />}
                        disabled={!allowEdit}
                    />
                </div>
            </div>
        );
    }

    async function handleRevokeRC3() {
        setDialogOpen(false);
        setInternalLoading(true);

        await api
            .delete(`rc3/groups/${id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.groups.form.rc3.delete_success" />
                );
                await loadRC3Principal(id);
            })
            .catch(error => requestError(error));
        setInternalLoading(false);
    }

    async function handleSetupRC3(event, op) {
        event.preventDefault();
        setInternalLoading(true);

        if (op === 'create') {
            const data = { group_id: id };

            await api
                .post(`rc3/groups`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.groups.form.rc3.create_success" />
                    );
                    await loadRC3Principal(id);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`rc3/groups/${id}`)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.groups.form.rc3.create_success" />
                    );
                    await loadRC3Principal(id);
                })
                .catch(error => requestError(error));
        }
        setInternalLoading(false);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.groups.form.rc3.revoke_confirm" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'revoke_access',
                        onClick: () => handleRevokeRC3(),
                        label: <LocaleMessage msg="button.revoke_access" />,
                    },
                ]}
            />
        );
    }

    function renderRC3Options() {
        return (
            <>
                {internalLoading ? (
                    <div className="row" style={centerStyle}>
                        <ReactLoading
                            type="bars"
                            color="#c8c8c8"
                            height={50}
                            width={50}
                        />
                    </div>
                ) : (
                    <div className="row" style={centerStyle}>
                        {principal && principal.active ? (
                            <>
                                <div
                                    className="col-12 mb-3"
                                    style={{
                                        ...centerStyle,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <MdCheck
                                        size={42}
                                        style={{ margin: '10px' }}
                                    />
                                    <Typography variant="body1">
                                        <LocaleMessage msg="page.groups.form.rc3.already_set" />
                                    </Typography>
                                </div>
                                <div className="col-12 mb-3 row">
                                    <Button
                                        className="p-3"
                                        variant="contained"
                                        color="secondary"
                                        onClick={event =>
                                            handleRevokeOpen(event)
                                        }
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.revoke_access" />
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className="col-12 mb-3"
                                    style={centerStyle}
                                >
                                    <Typography variant="body1">
                                        <LocaleMessage msg="page.groups.form.rc3.not_set" />
                                    </Typography>
                                </div>
                                <div className="col-12 mb-3">
                                    <Button
                                        className="p-3"
                                        variant="contained"
                                        color="primary"
                                        onClick={event =>
                                            handleSetupRC3(event, 'create')
                                        }
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.set_up" />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.groups.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.groups.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginspace/groups',
                    title: <LocaleMessage msg="breadcrumbs.groups" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div
                    className="col-12 row"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <div
                        className={`col-md-${
                            operation === 'update' ? '6' : '8'
                        } col-12 mb-5`}
                    >
                        <div className="col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.groups.form.label.settings" />
                                }
                                Icon={MdPeople}
                            >
                                <>
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-5">
                                            <TextField
                                                label={
                                                    <LocaleMessage msg="page.groups.form.label.name" />
                                                }
                                                fullWidth
                                                value={body.name}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        name:
                                                            event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="col-md-12 col-12 mb-5">
                                            <TextField
                                                label={
                                                    <LocaleMessage msg="page.groups.form.label.description" />
                                                }
                                                value={body.description}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        description:
                                                            event.target.value,
                                                    })
                                                }
                                                fullWidth
                                                multiline
                                            />
                                        </div>
                                        {operation === 'update'
                                            ? fileInput()
                                            : null}
                                        <div className="col-12 mt-5 mb-3">
                                            <Button
                                                className="p-3"
                                                variant="contained"
                                                color="primary"
                                                onClick={event =>
                                                    handleSubmit(event)
                                                }
                                                fullWidth
                                                size="large"
                                            >
                                                <LocaleMessage msg="button.save" />
                                            </Button>
                                        </div>
                                    </div>
                                    {allowEdit ? (
                                        <>
                                            {operation === 'update' &&
                                            allowRC3 ? (
                                                <div className="col-12 mt-5 mb-3">
                                                    <CardSideBordered
                                                        title={
                                                            <LocaleMessage msg="page.groups.form.rc3.settings" />
                                                        }
                                                        hide
                                                        Icon={MdSatellite}
                                                    >
                                                        <>
                                                            {renderRC3Options()}
                                                        </>
                                                    </CardSideBordered>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                            </CardSideBordered>
                        </div>
                    </div>
                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <GroupUsers groupId={id} />
                        </div>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

GroupForm.propTypes = {
    match: PropTypes.object.isRequired,
};
