/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';

import LocationsList from '~/components/Locations/List';

import CheckGroupPermission from '~/util/CheckGroupPermission';

export default function Locations() {
    const user = useSelector(state => state.user);
    const permissions = CheckGroupPermission(user || {}, '*');

    const allowNew = permissions.allow;

    return (
        <LocationsList
            breadcrumbs={[]}
            allowNew={allowNew}
            linkRoot="/pluginspace"
        />
    );
}

Locations.propTypes = {};
