import React from 'react';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function SplashInside({ label, labelPosition }) {
    return (
        <Container>
            {label && labelPosition === 'top' ? label : null}
            <ReactLoading type="bars" color="#c8c8c8" height={80} width={80} />
            {label && labelPosition === 'bottom' ? label : null}
        </Container>
    );
}

SplashInside.defaultProps = {
    label: '',
    labelPosition: 'bottom',
};

SplashInside.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelPosition: PropTypes.string,
};
