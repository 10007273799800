import appLocaleData from 'react-intl/locale-data/br';

import messages from '../messages/pt_BR.json';

const Lang = {
    messages: {
        ...messages,
    },
    locale: 'br',
    format: 'pt_BR',
    data: appLocaleData,
};
export default Lang;
