import appLocaleData from 'react-intl/locale-data/en';

import messages from '../messages/en_GB.json';

const Lang = {
    messages: {
        ...messages,
    },
    locale: 'en-GB',
    format: 'en_GB',
    data: appLocaleData,
};
export default Lang;
