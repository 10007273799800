/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function PreFilter({
    title,
    filterKey,
    options,
    defaultValue,
    onFilterChange,
}) {
    const [filter, setFilter] = useState(defaultValue);

    const show_options = options.length > 1;

    function handleFilterChange(value) {
        const newFilter = value
            ? {
                  key: filterKey,
                  value,
              }
            : null;
        setFilter(newFilter);
        onFilterChange(newFilter);
    }

    function renderShortcutButton(value, label, selected) {
        return (
            <div className="col-md-3 col-6 mb-3" key={`prefilter_${value}`}>
                <Button
                    fullWidth
                    variant={selected ? 'contained' : 'outlined'}
                    size="small"
                    color="primary"
                    onClick={() => handleFilterChange(value)}
                >
                    {label}
                </Button>
            </div>
        );
    }

    return show_options ? (
        <>
            <div
                className="row col-md-8 col-12 mb-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <Typography variant="h6">{title}</Typography>
                </div>
                {renderShortcutButton(
                    null,
                    <LocaleMessage msg="label.all" />,
                    !filter
                )}
                {options
                    ? options.map(opt => {
                          return renderShortcutButton(
                              opt.value,
                              opt.label,
                              filter && filter.value === opt.value
                          );
                      })
                    : null}
            </div>
        </>
    ) : null;
}

PreFilter.defaultProps = {
    options: [],
    title: '',
    defaultValue: null,
};

PreFilter.propTypes = {
    options: PropTypes.array,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filterKey: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    onFilterChange: PropTypes.func.isRequired,
};
