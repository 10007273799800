import styled from 'styled-components';

export const Splash = styled.div`
    display: flex;
    padding: 0px;
    margin: 0px;
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100vh;
    z-index: 3000;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Container = styled.div`
    height: 100vh;
`;

export const Body = styled.div`
    padding-top: 70px;
    margin: auto;
    height: 100%;
    width: 100%;
    border-bottom: 1px solid #ccc;
`;

export const Logo = styled.div`
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
`;

export const NullDiv = styled.div``;
