/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { MdViewQuilt, MdPeople, MdDelete, MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import BigButton from '~/components/BigButton';
import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import SectionContact from './SectionContact';
import SectionUser from './SectionUser';

const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
};

const all_permissions = {
    admin: {
        label: <LocaleMessage msg="user.permission.admin" />,
        value: 'admin',
    },
    editor: {
        label: <LocaleMessage msg="user.permission.editor" />,
        value: 'editor',
    },
    viewer: {
        label: <LocaleMessage msg="user.permission.viewer" />,
        value: 'viewer',
    },
    operator: {
        label: <LocaleMessage msg="user.permission.operator" />,
        value: 'operator',
    },
};

export default function SectionForm({
    group_id,
    section_id,
    linkRoot,
    allowEdit,
    breadcrumbs,
}) {
    const dispatch = useDispatch();
    const { pluginspace } = useSelector(state => state.settings);
    const pluginspace_id = pluginspace.id;

    const user = useSelector(state => state.user);

    const user_groups = user.groups || [];
    const { id: my_id } = user.profile;

    const currGroup =
        user_groups.find(g => {
            return g.id === group_id;
        }) || {};

    const empty_user = {
        group_id,
        section_id,
        permission: 'viewer',
        new_user: false,
    };

    const [forbidden, setForbidden] = useState(false);
    const [operation, setOperation] = useState('create');
    const [userOperation, setUserOperation] = useState('create');

    const [availableLocations, setAvailableLocations] = useState([]);
    const [availableZones, setAvailableZones] = useState([]);
    const [availableTools, setAvailableTools] = useState({});

    const [section, setSection] = useState({
        group_id,
        name: '',
        description: '',
    });
    const [file, setFile] = useState({
        id: null,
        url: null,
    });
    const [users, setUsers] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [sectionUser, setSectionUser] = useState(empty_user);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [sEdit, setSEdit] = useState(false);

    const headers_users = [
        { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
        {
            id: 'g_permission',
            label: <LocaleMessage msg="table.headers.group_permission" />,
        },
        {
            id: 's_permission',
            label: <LocaleMessage msg="table.headers.section_permission" />,
        },
    ];

    const g_permissions = [
        ...(['admin'].includes(currGroup.permission)
            ? [all_permissions.admin]
            : []),
        ...(['admin', 'editor'].includes(currGroup.permission)
            ? [all_permissions.editor]
            : []),
        {
            label: <LocaleMessage msg="user.permission.viewer" />,
            value: 'viewer',
        },
        {
            label: <LocaleMessage msg="user.permission.operator" />,
            value: 'operator',
        },
    ];

    const s_permissions = [
        all_permissions.admin,
        all_permissions.editor,
        all_permissions.viewer,
        all_permissions.operator,
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    function getSectionPermission(list) {
        const s_perm = list.find(u => {
            return u.id === my_id;
        });

        return setSEdit(s_perm && s_perm.permission === 'admin');
    }

    async function loadLocations() {
        await api
            .get(`locations`)
            .then(response => {
                setAvailableLocations(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadLocationZones() {
        await api
            .get(`zones`)
            .then(response => {
                setAvailableZones(response.data);
            })
            .catch(error => requestError(error));
    }

    async function loadSectionUsers() {
        setIsLoading(true);
        await api
            .get(`sections/${section_id}/users`)
            .then(response => {
                const { users: list } = response.data;
                const user_list = list.map(u => {
                    return {
                        ...u,
                        g_permission: u.group_permission ? (
                            <LocaleMessage
                                msg={`user.permission.${u.group_permission}`}
                            />
                        ) : (
                            <LocaleMessage msg="user.permission.viewer" />
                        ),
                        s_permission: (
                            <LocaleMessage
                                msg={`user.permission.${u.permission}`}
                            />
                        ),
                    };
                });
                setUsers(user_list);
                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function loadSection(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            loadSectionUsers();
            await api
                .get(`sections/${_id}`)
                .then(response => {
                    const s = response.data;
                    setSection({
                        name: s.name,
                        description: s.description,
                        location_id: s.location ? s.location.id : null,
                        zone_id: s.zone ? s.zone.id : null,
                    });
                    setFile(s.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadTools() {
        if (pluginspace_id) {
            await api
                .get(`pluginspaces/tools`)
                .then(response => {
                    const { data } = response;
                    const tools = {};
                    data.forEach(t => {
                        tools[t.slug] = true;
                    });
                    setAvailableTools(tools);
                    return true;
                })
                .catch(() => {});
        }
        return false;
    }

    function handleUserRowClick(event, user_id) {
        event.preventDefault();
        if (allowEdit) {
            setCurrItem(user_id);
            setUserOperation(user_id === 'new' ? 'create' : 'update');
            setUserDialogOpen(true);
            if (user_id === 'new') {
                return setSectionUser(empty_user);
            }

            const s_user = users.find(u => {
                return u.id === user_id;
            });
            return setSectionUser({
                section_id,
                user_id: s_user.id,
                ...s_user,
            });
        }
        return false;
    }

    useEffect(() => {
        loadTools();
        loadLocations();
        loadLocationZones();
    }, []);

    useEffect(() => {
        getSectionPermission(users);
    }, [users]);

    useEffect(() => {
        loadSection(section_id);
    }, [section_id]);

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
        setUserDialogOpen(false);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        return setDeleteDialogOpen(true);
    }

    async function updateSection(data) {
        await api
            .put(`sections/${section_id}`, data, {
                headers: {
                    section: section_id,
                },
            })
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.sections.update_success" />
                );
            })
            .catch(error => requestError(error));
        loadSection(section_id);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...section };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`sections`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.sections.create_success" />
                    );

                    history.push(`${linkRoot}/sections`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateSection(data);
        }
    }

    const onFileUpload = async e => {
        setIsLoading(true);

        const data = new FormData();
        data.append('file', e.target.files[0]);

        await api
            .post(`sections/${section_id}/media`, data)
            .then(response => {
                const updated = { ...section, file_id: response.data.id };
                setSection(updated);
                updateSection(updated);
            })
            .catch(error => requestError(error));
    };

    function renderFileInput() {
        return (
            <div
                className="row col-12"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="col-md-6 col-12 mb-5"
                    style={{ height: '200px', justifyContent: 'center' }}
                >
                    <FileInput
                        defaultValue={file}
                        onFileUpload={onFileUpload}
                        multiple={false}
                        text={<LocaleMessage msg="message.change_file" />}
                        disabled={!allowEdit}
                    />
                </div>
            </div>
        );
    }

    async function submitUser(event) {
        event.preventDefault();

        const data = {
            ...sectionUser,
        };

        setIsLoading(true);
        if (userOperation === 'create') {
            await api
                .post(`sections/${section_id}/users`, data, {
                    headers: {
                        section: section_id,
                    },
                })
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.sections.label.user.add_success" />
                    );
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`sections/${section_id}/users/${currItem}`, data, {
                    headers: {
                        section: section_id,
                    },
                })
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.sections.label.user.update_success" />
                    );
                })
                .catch(error => requestError(error));
        }
        setUserDialogOpen(false);
        setIsLoading(false);
        loadSectionUsers();
    }

    async function removeUser() {
        setIsLoading(true);
        setUserDialogOpen(false);
        setDeleteDialogOpen(false);

        await api
            .delete(`/sections/${section_id}/users/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.sections.label.user.delete_success" />
                );
                await loadSectionUsers();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function buildDeleteDialog() {
        return (
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <LocaleMessage msg="page.sections.label.user.remove" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <LocaleMessage msg="page.sections.label.user.remove_content" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={() => removeUser()}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.remove" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildUserDialog() {
        return (
            <Dialog
                open={userDialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {userOperation === 'create' ? (
                        <LocaleMessage msg="page.sections.label.user.add" />
                    ) : (
                        <LocaleMessage msg="page.sections.label.user.edit" />
                    )}
                </DialogTitle>
                <DialogContent>
                    {userOperation === 'create' ? (
                        <DialogContentText>
                            <LocaleMessage msg="page.sections.label.user.add_content" />
                        </DialogContentText>
                    ) : null}
                    <SectionUser
                        operation={userOperation}
                        body={sectionUser}
                        setBody={setSectionUser}
                        permissions={{
                            group: g_permissions,
                            section: s_permissions,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button onClick={submitUser} color="primary" autoFocus>
                        {userOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderForm() {
        return (
            <div
                className={`${
                    operation === 'create' ? 'col-md-8' : 'col-md-6'
                }  col-12 mb-5`}
            >
                <CardSideBordered
                    title={<LocaleMessage msg="page.sections.label.settings" />}
                    Icon={MdViewQuilt}
                >
                    <>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.sections.label.name" />
                                    }
                                    fullWidth
                                    value={section.name}
                                    onChange={event =>
                                        setSection({
                                            ...section,
                                            name: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.sections.label.description" />
                                    }
                                    value={section.description}
                                    onChange={event =>
                                        setSection({
                                            ...section,
                                            description: event.target.value,
                                        })
                                    }
                                    fullWidth
                                    multiline
                                />
                            </div>
                            <div className="col-12 row">
                                <div className="col-6 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="label.form.location" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                section && section.location_id
                                                    ? section.location_id
                                                    : false
                                            }
                                            onChange={event =>
                                                setSection({
                                                    ...section,
                                                    location_id:
                                                        event.target.value,
                                                })
                                            }
                                        >
                                            <MenuItem value={false}>
                                                <LocaleMessage msg="label.form.no_location" />
                                            </MenuItem>
                                            {availableLocations.map(l => {
                                                return (
                                                    <MenuItem
                                                        value={l.id}
                                                        key={l.id}
                                                    >
                                                        {l.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-6 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="label.form.location_zone" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                section && section.zone_id
                                                    ? section.zone_id
                                                    : false
                                            }
                                            onChange={event =>
                                                setSection({
                                                    ...section,
                                                    zone_id: event.target.value,
                                                })
                                            }
                                        >
                                            <MenuItem value={false}>
                                                <LocaleMessage msg="label.form.no_location_zone" />
                                            </MenuItem>
                                            {availableZones.map(z => {
                                                if (
                                                    section &&
                                                    z.location &&
                                                    z.location.id ===
                                                        section.location_id
                                                ) {
                                                    return (
                                                        <MenuItem
                                                            value={z.id}
                                                            key={z.id}
                                                        >
                                                            {z.name}
                                                        </MenuItem>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            {operation === 'update' ? renderFileInput() : null}
                            {allowEdit || sEdit ? (
                                <div className="col-12 mb-3">
                                    <BigButton
                                        title={
                                            <LocaleMessage msg="button.save" />
                                        }
                                        onClick={event => handleSubmit(event)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function renderUserList() {
        const rowActions = allowEdit
            ? [
                  {
                      id: 'delete',
                      label: <LocaleMessage msg="button.delete" />,
                      icon: <MdDelete />,
                      action: handleClickOpen,
                  },
              ]
            : [];

        return (
            <div className="col-md-6 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.sections.label.user.list" />
                    }
                    Icon={MdPeople}
                    hide
                >
                    <>
                        {allowEdit ? (
                            <div className="body-top-controls">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<MdAdd />}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 20px',
                                    }}
                                    onClick={event =>
                                        handleUserRowClick(event, 'new')
                                    }
                                >
                                    <LocaleMessage msg="page.sections.label.user.add" />
                                </Button>
                            </div>
                        ) : null}
                        <div className="col-12">
                            <DataTable
                                headerColumns={headers_users}
                                data={users}
                                orderColumn="name"
                                handleTableRowClick={(event, _id) =>
                                    handleUserRowClick(event, _id)
                                }
                                rowActions={rowActions}
                            />
                        </div>
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function renderSectionContact() {
        return (
            <div className="col-12 mb-5">
                <SectionContact
                    section={{ ...section, id: section_id }}
                    requestError={e => requestError(e)}
                    allowEdit={allowEdit || sEdit}
                    users={users}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.sections.create.title" />
                ) : (
                    <LocaleMessage msg="page.sections.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...breadcrumbs,
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                {userDialogOpen ? buildUserDialog() : null}
                <div className="row full-group" style={centerStyle}>
                    {renderForm()}

                    {operation === 'update' ? (
                        <>
                            {renderUserList()}

                            {availableTools.contact_list
                                ? renderSectionContact()
                                : null}
                        </>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

SectionForm.defaultProps = {
    breadcrumbs: [],
    allowEdit: false,
    // adminMode: false,
};

SectionForm.propTypes = {
    group_id: PropTypes.string.isRequired,
    section_id: PropTypes.string.isRequired,
    linkRoot: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
    allowEdit: PropTypes.bool,
    // adminMode: PropTypes.bool,
};
