import { combineReducers } from 'redux';

import application from './application/reducer';
import auth from './auth/reducer';
import pages from './pages/reducer';
import settings from './settings/reducer';
import user from './user/reducer';

export default combineReducers({
    application,
    settings,
    pages,
    auth,
    user,
});
