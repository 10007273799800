/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import React, { useState } from 'react';
// import { MdDelete, MdAdd } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    // Button,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../../styles';

export default function ExitActionSetup({ settings, onChange, languages }) {
    const action_settings = settings.data || {};

    const exit_actions = {
        end_conversation: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.exit.action.list.end_conversation" />
            ),
            defaults: {},
        },
        return_conversation: {
            label: (
                <LocaleMessage msg="page.survey_steps.form.exit.action.list.go_conversation" />
            ),
            defaults: {
                save_context: true,
                send_message: false,
                language: languages.default || 'pt_BR',
            },
        },
    };

    // const [currItem, setCurrItem] = useState(null);
    // const [dialogOpen, setDialogOpen] = useState(false);

    // function onVariableChange(event, idx, key) {
    //     const items = settings || [];
    //     if (items[idx]) {
    //         items[idx] = {
    //             ...items[idx],
    //             [key]: event.target.value,
    //         };
    //     }
    //     onChange([...items]);
    // }

    // function handleNewVariable() {
    //     const items = settings || [];
    //     const item_id = (+new Date()).toString(36);
    //     items.push({
    //         item_id,
    //         body_key: '',
    //         response_key: '',
    //     });
    //     onChange([...items]);
    // }

    // function handleDeleteCondition(idx) {
    //     setDialogOpen(false);
    //     const old_items = settings || [];
    //     const items = old_items.filter(c => {
    //         return c.item_id !== idx;
    //     });
    //     onChange([...items]);
    // }

    // function handleDialogClose(event) {
    //     event.preventDefault();
    //     setDialogOpen(false);
    // }

    // function handleDialogOpen(event, index) {
    //     setCurrItem(index);
    //     event.preventDefault();
    //     setDialogOpen(true);
    // }

    // function renderVariableRow(type, item, index) {
    //     return (
    //         <div className="row col-12">
    //             <div className="col-11 row mx-0">
    //                 <div className="col-md-6 col-12">
    //                     <TextField
    //                         label={
    //                             <LocaleMessage msg="page.survey_steps.form.response.label.response.parameter" />
    //                         }
    //                         fullWidth
    //                         value={
    //                             item && item.response_key
    //                                 ? item.response_key
    //                                 : ''
    //                         }
    //                         onChange={event =>
    //                             onVariableChange(event, index, 'response_key')
    //                         }
    //                     />
    //                 </div>
    //                 <div className="col-md-6 col-12">
    //                     <TextField
    //                         label={
    //                             <LocaleMessage msg="page.survey_steps.form.response.label.save_as" />
    //                         }
    //                         fullWidth
    //                         value={item && item.body_key ? item.body_key : ''}
    //                         onChange={event =>
    //                             onVariableChange(event, index, 'body_key')
    //                         }
    //                     />
    //                 </div>
    //             </div>
    //             <IconParameter className="col-1">
    //                 <MdDelete
    //                     size={18}
    //                     onClick={event => handleDialogOpen(event, item.item_id)}
    //                 />
    //             </IconParameter>
    //         </div>
    //     );
    // }

    // function renderVariablesList(values) {
    //     return (
    //         <div
    //             style={{
    //                 width: '100%',
    //             }}
    //         >
    //             {/* {values.map((item, idx) => {
    //                 const { type } = item;
    //                 return (
    //                     <ParameterArea
    //                         key={item.item_id}
    //                         style={{
    //                             userSelect: 'none',
    //                         }}
    //                     >
    //                         {renderVariableRow(type, item, idx)}
    //                     </ParameterArea>
    //                 );
    //             })} */}
    //         </div>
    //     );
    // }

    // function buildDeleteDialog() {
    //     return (
    //         <div>
    //             <Dialog
    //                 open={dialogOpen}
    //                 onClose={handleDialogClose}
    //                 maxWidth="sm"
    //                 fullWidth
    //             >
    //                 <DialogTitle>
    //                     <LocaleMessage msg="page.survey_steps.form.response.label.delete.confirm" />
    //                 </DialogTitle>
    //                 <DialogContent>
    //                     <DialogContentText>
    //                         <LocaleMessage msg="message.undone.content" />
    //                     </DialogContentText>
    //                 </DialogContent>
    //                 <DialogActions>
    //                     <Button
    //                         onClick={() => setDialogOpen(false)}
    //                         color="primary"
    //                     >
    //                         <LocaleMessage msg="button.cancel" />
    //                     </Button>
    //                     <Button
    //                         onClick={() => handleDeleteCondition(currItem)}
    //                         color="primary"
    //                         autoFocus
    //                     >
    //                         <LocaleMessage msg="button.delete" />
    //                     </Button>
    //                 </DialogActions>
    //             </Dialog>
    //         </div>
    //     );
    // }

    function handleUpdateActionData(key, value) {
        onChange({
            ...settings,
            data: {
                ...action_settings,
                [key]: value,
            },
        });
    }

    function handleActionSelection(event) {
        const action = event.target.value;
        const a_settings = exit_actions[action] || {};

        const a_defaults = a_settings.defaults || {};

        onChange({
            ...settings,
            action,
            data: {
                ...a_defaults,
            },
        });
    }

    function renderReturnConversationSettings() {
        return (
            <>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={action_settings.save_context || false}
                        onChange={event =>
                            handleUpdateActionData(
                                'save_context',
                                event.target.checked
                            )
                        }
                        label={
                            <LocaleMessage msg="page.survey_steps.form.exit.action.label.save_context" />
                        }
                        helper={
                            <LocaleMessage msg="page.survey_steps.form.exit.action.label.save_context.helper" />
                        }
                    />
                </div>
                <div className="col-12 mb-3">
                    <FormSwitch
                        value={action_settings.send_message || false}
                        onChange={event =>
                            handleUpdateActionData(
                                'send_message',
                                event.target.checked
                            )
                        }
                        label={
                            <LocaleMessage msg="page.survey_steps.form.exit.action.label.send_message" />
                        }
                        helper={
                            <LocaleMessage msg="page.survey_steps.form.exit.action.label.send_message.helper" />
                        }
                    />
                </div>
                {action_settings.send_message ? (
                    <div className="col-12 row">
                        <div className="col-md-3 col-12 mb-5">
                            <FormControl fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.survey_steps.form.exit.action.label.message_lang" />
                                </InputLabel>
                                <Select
                                    value={action_settings.language || ''}
                                    onChange={event =>
                                        handleUpdateActionData(
                                            'language',
                                            event.target.value
                                        )
                                    }
                                >
                                    {languages && languages.list
                                        ? languages.list.map(l => {
                                              return (
                                                  <MenuItem value={l} key={l}>
                                                      <LocaleMessage
                                                          msg={`list.languages.${l}`}
                                                      />
                                                  </MenuItem>
                                              );
                                          })
                                        : null}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-9 col-12 mb-5">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.survey_steps.form.exit.action.label.message_content" />
                                }
                                fullWidth
                                value={action_settings.message || ''}
                                onChange={event =>
                                    handleUpdateActionData(
                                        'message',
                                        event.target.value
                                    )
                                }
                            />
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    function renderActionSettings() {
        const action = settings && settings.action ? settings.action : '';
        const settings_renderer = {
            end_conversation: null,
            return_conversation: renderReturnConversationSettings(),
        };

        return settings_renderer[action] || null;
    }

    return (
        <div className="row">
            <div className="col-12 mb-3">
                <LocaleMessage msg="page.survey_steps.form.exit.action.info" />
            </div>
            <ParameterArea className="col-12 pt-5 mb-5">
                <div className="col-12">
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.survey_steps.form.exit.action.label" />
                            </InputLabel>
                            <Select
                                value={settings.action ? settings.action : ''}
                                onChange={event => handleActionSelection(event)}
                            >
                                {Object.keys(exit_actions).map(m => {
                                    const action = exit_actions[m];
                                    return (
                                        <MenuItem value={m} key={m}>
                                            {action.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-12">{renderActionSettings()}</div>
            </ParameterArea>

            {/* <div style={{ padding: '0px 15px' }}>
                    <ParameterArea className="row mt-1 mb-5">
                        {renderVariablesList(settings || [])}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewVariable()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="page.survey_steps.form.response.label.add" />
                        </Button>
                    </ParameterArea>
                </div> */}
        </div>
    );
}

ExitActionSetup.defaultProps = {
    languages: {},
};

ExitActionSetup.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    languages: PropTypes.object,
};
