/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, DialogContentText, Typography } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

const centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function RC3User({ user, level }) {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [principal, setPrincipal] = useState(null);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadRC3Principal(_id) {
        setIsLoading(true);
        if (!_id) {
            setPrincipal({ active: false });
        } else {
            await api
                .get(`rc3/users/${_id}`)
                .then(response => {
                    const c = response.data;
                    if (c.id) {
                        setPrincipal(c);
                    } else {
                        setPrincipal({ total: 0, active: false });
                    }
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    async function loadUser(_id) {
        await api
            .get(`users/${_id}`)
            .then(response => {
                const u = response.data;
                if (u.status === 'active') {
                    loadRC3Principal(_id);
                }
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadUser(user);
    }, [user]);

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    function handleRevokeOpen(event, _id) {
        event.preventDefault();
        setDialogOpen(true);
    }

    async function handleRevokeRC3() {
        setDialogOpen(false);
        setIsLoading(true);

        await api
            .delete(`rc3/users/${user}/${level}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.users.form.rc3.delete_success" />
                );
                await loadRC3Principal(user);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleUpdateRC3(event) {
        event.preventDefault();
        setIsLoading(true);

        await api
            .put(`rc3/users/${user}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.users.form.rc3.update_success" />
                );
                await loadRC3Principal(user);
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    async function handleSetupRC3(event) {
        event.preventDefault();
        setIsLoading(true);

        const data = { user_id: user, level };

        await api
            .post(`rc3/users`, data)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.users.form.rc3.create_success" />
                );
                await loadRC3Principal(user);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage
                        msg={`page.users.form.rc3.revoke_confirm.${level}`}
                    />
                }
                content={<DialogContentText />}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'revoke_access',
                        onClick: () => handleRevokeRC3(),
                        label: <LocaleMessage msg="button.revoke_access" />,
                    },
                ]}
            />
        );
    }

    function buildPluginspaceOptions() {
        return principal.allow_rc3 ? (
            <div className="col-md-8 col-12 mb-3 row">
                <Button
                    className="p-3 col-12"
                    variant="contained"
                    color="secondary"
                    onClick={event => handleRevokeOpen(event)}
                    fullWidth
                    size="large"
                >
                    <LocaleMessage msg="button.revoke_access.pluginspace" />
                </Button>
            </div>
        ) : (
            <div className="col-md-8 col-12 mb-3 row">
                <Button
                    className="p-3 col-12"
                    variant="contained"
                    color="secondary"
                    onClick={event => handleUpdateRC3(event)}
                    fullWidth
                    size="large"
                >
                    <LocaleMessage msg="button.update_access" />
                </Button>
            </div>
        );
    }

    function buildProfileOptions() {
        return null;
        // return (
        //     <div className="col-md-8 col-12 mb-3 row">
        //         <Button
        //             className="p-3 col-12"
        //             variant="contained"
        //             color="secondary"
        //             onClick={event => handleRevokeOpen(event)}
        //             fullWidth
        //             size="large"
        //         >
        //             <LocaleMessage msg="button.revoke_access.profile" />
        //         </Button>
        //     </div>
        // );
    }

    return (
        <>
            {dialogOpen ? buildConfirmDialog() : null}
            {isLoading ? (
                <div className="row" style={centerStyle}>
                    <ReactLoading
                        type="bars"
                        color="#c8c8c8"
                        height={50}
                        width={50}
                    />
                </div>
            ) : (
                <div className="row" style={centerStyle}>
                    {principal && principal.id ? (
                        <>
                            {/* <div
                                className="col-12 mb-3"
                                style={{
                                    ...centerStyle,
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="body1">
                                    <LocaleMessage msg="page.users.form.rc3.already_set" />
                                </Typography>
                            </div> */}
                            {/* {level === 'profile' ? (
                                <div
                                    className="col-12 mb-3"
                                    style={{
                                        ...centerStyle,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        style={{ margin: '15px' }}
                                    >
                                        {principal && principal.total
                                            ? principal.total
                                            : 0}
                                    </Typography>
                                    <Typography variant="body1">
                                        <LocaleMessage msg="page.users.form.rc3.groups.all" />
                                    </Typography>
                                </div>
                            ) : null} */}

                            {principal.allow_rc3 ? (
                                <div
                                    className="col-12 mb-3"
                                    style={{
                                        ...centerStyle,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        style={{ margin: '15px' }}
                                    >
                                        {principal && principal.active
                                            ? principal.active
                                            : 0}
                                    </Typography>
                                    <Typography variant="body1">
                                        <LocaleMessage msg="page.users.form.rc3.groups.pluginspace" />
                                    </Typography>
                                    <div
                                        style={{
                                            ...centerStyle,
                                            margin: '10px 0px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {principal && principal.policies
                                            ? principal.policies.map(p => {
                                                  return p.active ? (
                                                      <Typography
                                                          variant="body1"
                                                          key={p.id}
                                                          className="mb-3"
                                                      >
                                                          {p.active ? (
                                                              <MdCheck
                                                                  size={24}
                                                              />
                                                          ) : (
                                                              <MdClose
                                                                  size={24}
                                                              />
                                                          )}
                                                          {p.group}
                                                      </Typography>
                                                  ) : null;
                                              })
                                            : null}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="col-12 mb-5"
                                    style={centerStyle}
                                >
                                    <Typography variant="body1">
                                        <LocaleMessage msg="page.users.form.rc3.not_allowed" />
                                    </Typography>
                                </div>
                            )}
                            {level === 'profile'
                                ? buildProfileOptions()
                                : buildPluginspaceOptions()}
                        </>
                    ) : (
                        <>
                            <div className="col-12 mb-3" style={centerStyle}>
                                <Typography variant="body1">
                                    <LocaleMessage msg="page.users.form.rc3.not_set" />
                                </Typography>
                            </div>
                            <div className="col-md-8 col-12 mb-3 row">
                                <Button
                                    className="p-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={event => handleSetupRC3(event)}
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.set_up" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

RC3User.defaultProps = {
    level: 'pluginspace',
};

RC3User.propTypes = {
    level: PropTypes.string,
    user: PropTypes.string.isRequired,
};
