/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdAdd, MdDelete } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    DialogContentText,
    Select,
    TextField,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import { ParameterArea, IconParameter } from './styles';

export default function PresentationContentForm({
    settings,
    onChange,
    languages,
}) {
    const sequence = settings && settings.speech ? settings.speech : [];

    const [currItem, setCurrItem] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;
        const items = sequence || [];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);

        onChange({
            ...settings,
            speech: items,
        });
    }

    function removeMessage(idx) {
        setDeleteDialogOpen(false);
        const items = sequence || [];

        if (!items[idx]) {
            return;
        }
        items.splice(idx, 1);
        onChange({
            ...settings,
            speech: items,
        });
    }

    function editMessage(idx, key, value) {
        const items = sequence || [];
        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: value,
            };
        }
        onChange({
            ...settings,
            speech: items,
        });
    }

    function handleNewMessage() {
        const items = sequence || [];
        const item_id = (+new Date()).toString(36);
        items.push({ item_id });
        onChange({
            ...settings,
            speech: items,
        });
    }

    function renderText(item, idx) {
        return (
            <div className="row col-12 move">
                <div className="col-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="label.form.language" />
                        </InputLabel>
                        <Select
                            value={item.language ? item.language : ''}
                            onChange={event =>
                                editMessage(idx, 'language', event.target.value)
                            }
                        >
                            {languages.map(l => {
                                return (
                                    <MenuItem value={l} key={l}>
                                        <LocaleMessage
                                            msg={`list.languages.${l}`}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-8">
                    <TextField
                        label={
                            <LocaleMessage msg="page.tools.autonomous_actions.actions.sequence.messages.text" />
                        }
                        fullWidth
                        value={item && item.text ? item.text : ''}
                        onChange={event =>
                            editMessage(idx, 'text', event.target.value)
                        }
                        multiline
                    />
                </div>
                <IconParameter className="col-1">
                    <MdDelete
                        size={18}
                        onClick={() => {
                            setCurrItem(idx);
                            setDeleteDialogOpen(true);
                        }}
                    />
                </IconParameter>
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={
                    <LocaleMessage msg="page.tools.autonomous_actions.actions.sequence.messages.delete.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.need_save.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeMessage(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <>
            <div className="col-12">
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="label.reorder" />
                </div>
                <ParameterArea className="row mt-1 mb-5">
                    <DragDropContext onDragEnd={result => onDragEnd(result)}>
                        <Droppable droppableId="condition-list">
                            {(drop_provided, drop_snapshot) => {
                                return (
                                    <div
                                        className="p-3"
                                        {...drop_provided.droppableProps}
                                        ref={drop_provided.innerRef}
                                        style={{
                                            background: drop_snapshot.isDraggingOver
                                                ? 'darkgrey'
                                                : 'lightgrey',
                                            width: '100%',
                                        }}
                                    >
                                        {sequence.map((item, idx) => {
                                            return (
                                                <Draggable
                                                    key={item.item_id}
                                                    draggableId={item.item_id}
                                                    index={idx}
                                                >
                                                    {drag_provided => {
                                                        return (
                                                            <ParameterArea
                                                                className="mb-3"
                                                                ref={
                                                                    drag_provided.innerRef
                                                                }
                                                                {...drag_provided.draggableProps}
                                                                {...drag_provided.dragHandleProps}
                                                                style={{
                                                                    userSelect:
                                                                        'none',
                                                                    ...drag_provided
                                                                        .draggableProps
                                                                        .style,
                                                                }}
                                                            >
                                                                {renderText(
                                                                    item,
                                                                    idx
                                                                )}
                                                            </ParameterArea>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {drop_provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleNewMessage()}
                        startIcon={
                            <MdAdd
                                style={{
                                    color: '#fff',
                                }}
                            />
                        }
                    >
                        <LocaleMessage msg="page.tools.autonomous_actions.actions.sequence.messages.add" />
                    </Button>
                </ParameterArea>
            </div>
        </>
    );
}

PresentationContentForm.propTypes = {
    languages: PropTypes.array,
    settings: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

PresentationContentForm.defaultProps = {
    settings: {},
    languages: [],
};
