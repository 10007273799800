import { takeLatest, call, put, all } from 'redux-saga/effects';

import history from '~/services/history';
import api from '~/services/pluginbot-api';

import {
    changePluginspaceSuccess,
    changePluginspaceFailure,
    loadPluginspaceSettingsSuccess,
    loadPluginspaceSettingsFailure,
    changeGroupSuccess,
    changeGroupFailure,
} from './actions';

export function* changePluginspace({ payload }) {
    api.defaults.headers.group = '';
    try {
        const { pluginspace } = payload;
        const response = yield call(
            api.get,
            `pluginspaces/load/${pluginspace}`
        );
        const { id, subdomain, theme, logo, locale } = response.data;

        yield put(changePluginspaceSuccess(id, subdomain, theme, logo, locale));
    } catch (err) {
        yield put(changePluginspaceFailure());
    }
}

export function* loadPluginspaceSettings({ payload }) {
    try {
        const { pluginspace_id } = payload;
        const response = yield call(
            api.get,
            `pluginspaces/${pluginspace_id}/settings`
        );
        const { theme, logo, apps } = response.data;

        yield put(loadPluginspaceSettingsSuccess(theme, logo, apps));
    } catch (err) {
        yield put(loadPluginspaceSettingsFailure());
    }
}

export function* changeGroup({ payload }) {
    try {
        const { active_group } = payload;

        api.defaults.headers.group =
            active_group && active_group.id !== '*' ? active_group.id : '';
        setTimeout(() => {
            const url = window.location.pathname.split('/');
            if (url.length > 1) {
                if (
                    url[1] === 'pluginspace' ||
                    url[1] === 'reports' ||
                    url[1] === 'pluginconfig'
                ) {
                    history.push(window.location.pathname);
                } else {
                    const goTo = `${
                        url[1] === 'mygroups' ? '/' : `/${url[1]}`
                    }`;

                    if (window.location.pathname !== goTo) {
                        history.push(goTo);
                    }
                }
            } else {
                history.push(`/`);
            }
        }, 300);
        yield put(changeGroupSuccess(active_group));
    } catch (err) {
        yield put(changeGroupFailure());
    }
}

export function setGroup({ payload }) {
    if (!payload) return;

    const { active } = payload.settings;

    api.defaults.headers.group = active && active.id !== '*' ? active.id : '';
}

export default all([
    takeLatest('persist/REHYDRATE', setGroup),
    takeLatest('@settings/CHANGE_PLUGINSPACE_REQUEST', changePluginspace),
    takeLatest(
        '@settings/LOAD_PLUGINSPACE_SETTINGS_REQUEST',
        loadPluginspaceSettings
    ),
    takeLatest('@settings/CHANGE_GROUP_REQUEST', changeGroup),
]);
