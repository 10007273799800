import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1.5vh 0.25vw;
    margin: 0px;
    display: flex;
    align-items: center;

    .icon-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        height: 100%;
        padding: 0px;

        .card-title {
            color: #999;
            font-size: 1.35vh;
            width: 100%;
            text-align: center;
            margin-bottom: 1.5vh;
        }
    }

    .info-area {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 0.25vw;
        height: 100%;

        .info {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            font-size: 1.35vh;

            .info-title {
                color: #999;
                padding: 0px;
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
            }

            .info-text {
                text-align: left;
            }

            .info-value {
                font-size: 1.35vh;
            }

            .big-value {
                font-size: 2.75vh;
                span {
                    margin-top: 0.5vh;
                }
            }
        }

        .info:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .desc-full {
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1vh;

        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            color: #999;
            width: 100%;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 1.35vh !important;
        }

        .info-chart {
            margin: 1vh 1vw;
        }

        .big-value {
            font-size: 2vh;
            font-weight: bold;

            span {
                margin-top: 10px;
            }
        }

        .info:not(:last-child) {
            border-right: none;
        }
    }

    .desc-full:not(:last-child) {
        border-bottom: solid 1px #555555;
    }

    .info-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;

        .vertical:not(:last-child) {
            border-right: solid 1px #555555;
        }
    }
`;
