import styled from 'styled-components';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SettingsArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0px;
    }
`;

export const ParameterArea = styled.div`
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
`;

export const MediaArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px;

    .img-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;

        img {
            max-height: 30vh;
            max-width: 100%;
            object-fit: contain;
        }
    }
`;

export const ContactsArea = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #999;
    padding: 15px 0px !important;
    margin: 0px 10px;
    border: 1px solid #666;

    .contact-card {
        margin: 10px 0px;
        width: 100%;
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;
    color: ${props => props.theme.palette.primary.main};

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;
