import ColorList from './ColorList';

export default function getPluginspaceTheme(theme) {
    const color_list = ColorList();

    const { pluginspace } = theme.palette;

    const colors = {};
    color_list.forEach(c => {
        if (pluginspace) {
            colors[c.id] = pluginspace[c.id] || c.default;
        } else {
            colors[c.id] = c.default;
        }
    });

    return colors;
}
