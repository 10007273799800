/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import numeral from 'numeral';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import {
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
    PieChart,
    Pie,
    Cell,
} from 'recharts';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import lists from '~/config/Lists';
import useWindowDimensions from '~/hooks/useWindowDimensions';
import api from '~/services/pluginbot-api';
import lng_labels from '~/util/LangMessages';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import InteractionsCard from './InteractionsCard';
import Locations from './Locations';
import {
    CentralPanel,
    Container,
    MainInfo,
    InfoCard,
    TimeChart,
} from './styles';

const date_opt = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};
const time_opt = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
};

const { interaction_keys } = lists;

const label_options = { year: 'numeric', month: '2-digit' };

export default function Dashboard({
    settings,
    robots,
    counters,
    requestError,
}) {
    const boxShadow = 0;
    const update_time = 30 * 1000;
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const interaction_labels = lng_labels[lang];

    const colors = GetPluginspaceTheme(useTheme());
    const windowSize = useWindowDimensions();

    const w_height = windowSize.height || 1080;
    const w_width = windowSize.width || 720;
    const box_height = parseInt(w_height * 0.25, 10);
    const box_margin_h = parseInt(w_height * 0.01, 10);
    const box_margin_w = parseInt(w_width * 0.005, 10);

    const [now, setNow] = useState(null);
    const [interactions, setInteractions] = useState(null);
    const [interactionData, setInteractionData] = useState({});
    const [lastInteractions, setLastInteractions] = useState({});

    function getTime() {
        const datetime = new Date();
        const date = datetime.toLocaleDateString(loc.format, date_opt);
        const time = datetime.toLocaleTimeString([], time_opt);
        setNow({
            date: date.toUpperCase(),
            time,
        });
    }

    function getInteractionLabels(_ints) {
        const n_total = _ints.total;
        let total_label = n_total;
        let scale = 'u';
        if (n_total > 9999 && n_total < 999999) {
            total_label = numeral(n_total / 1000).format('0.0');
            scale = 'k';
        } else if (n_total > 999999) {
            total_label = numeral(n_total / 1000000).format('0.0');
            scale = 'm';
        }

        return {
            total: {
                value: total_label,
                scale,
            },
        };
    }

    function getPeriodString(period) {
        return new Date(period).toLocaleDateString(loc.format, label_options);
    }

    function filterInteractions(_ints) {
        if (!_ints) {
            setInteractionData({});
            return;
        }
        const int_data = [];
        const labels = [];
        let all_rates = [];
        const month_rates = [];

        const { all_time, last_months, current_month } = _ints;

        // Get the values for all time interactions
        Object.keys(last_months).forEach(m => {
            const i = last_months[m];
            const i_values = i.values;
            const i_date = getPeriodString(i.period);
            labels.push();

            int_data.push({
                date: i_date,
                ...i_values,
            });
        });

        // Add the current month to all time interactions
        const i_date = getPeriodString(current_month.period);
        labels.push(i_date);
        int_data.push({
            date: i_date,
            ...current_month.values,
        });

        // Get the values per interaction type
        Object.keys(all_time).forEach(i => {
            if (i !== 'total') {
                const i_val = all_time[i];
                const i_key = interaction_keys[i];
                all_rates.push({
                    key: i,
                    label: interaction_labels[i_key]
                        ? interaction_labels[i_key]
                        : i_key,
                    value: i_val,
                });
            }
        });

        // Dynamically create colors for interaction types
        const a_n_colors = all_rates.length;
        const a_n_step = (1 - 0.25) / a_n_colors;
        all_rates = all_rates.map((r, i) => {
            const light = a_n_step * i;
            const c =
                colors && colors.primary
                    ? lighten(light, colors.primary)
                    : '#ffffff';
            return {
                ...r,
                color: c,
            };
        });

        // Get the values for current month and sets the all-time-interaction color
        Object.keys(current_month.values).forEach(i => {
            if (i !== 'total') {
                const i_val = current_month.values[i];
                const i_key = interaction_keys[i];
                const all_item = all_rates.find(a => {
                    return a.key === i;
                });
                month_rates.push({
                    label: interaction_labels[i_key]
                        ? interaction_labels[i_key]
                        : i_key,
                    value: i_val,
                    color: all_item ? all_item.color : colors.primary,
                });
            }
        });

        setInteractionData({
            info: {
                all_time: getInteractionLabels(all_time),
            },
            list: int_data,
            rate: {
                all_time: all_rates,
                this_month: month_rates,
            },
            labels,
        });
    }

    async function load24Interactions() {
        await api
            .get(`admin/interactions/today`)
            .then(response => {
                const { data } = response;
                setLastInteractions(data);
            })
            .catch(error => requestError(error));
    }

    async function loadInteractions() {
        await api
            .get(`admin/interactions`)
            .then(response => {
                const { data } = response;
                setInteractions(data);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        getTime();
        loadInteractions();
        load24Interactions();
        const interval = setInterval(() => {
            loadInteractions();
            load24Interactions();
        }, update_time);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getTime();
        }, 1 * 1000);
        return () => clearInterval(interval);
    }, [loc]);

    useEffect(() => {
        filterInteractions(interactions);
    }, [interactions, loc]);

    const CustomInteractionTooltip = ({ active, payload }) => {
        if (active && payload) {
            return (
                <div style={{ backgroundColor: '#fff', padding: '15px' }}>
                    <p style={{ color: '#000', marginBottom: '5px' }}>
                        {payload[0] && payload[0].payload
                            ? payload[0].payload.date
                            : ''}
                    </p>
                    <p style={{ color: '#000', marginBottom: '0px' }}>
                        <LocaleMessage msg="rc3.dashboard.chart.label.total" />
                        {`: ${payload[0].value}`}
                    </p>
                </div>
            );
        }

        return null;
    };

    function areaChart(data, labels, areas, tooltip) {
        return (
            <AreaChart
                data={data}
                labels={labels}
                margin={{
                    top: box_margin_h,
                    bottom: box_margin_h,
                    right: box_margin_w,
                    left: -2 * box_margin_w,
                }}
            >
                <CartesianGrid stroke="#464A4F" vertical={false} />
                <XAxis dataKey="date" />
                <YAxis type="number" allowDataOverflow allowDecimals={false} />
                {tooltip ? <Tooltip content={tooltip} /> : null}
                {areas.map(a => {
                    return (
                        <Area
                            key={a.key}
                            dataKey={a.key}
                            stroke={a.color}
                            fill={a.fill}
                            unit={a.unit}
                        />
                    );
                })}
            </AreaChart>
        );
    }

    function pieChart(data, color, key) {
        const pie_size = parseInt(w_height * 0.035, 10);
        return (
            <PieChart>
                <Pie
                    dataKey="value"
                    nameKey="label"
                    data={data || []}
                    innerRadius={pie_size}
                    outerRadius={2 * pie_size}
                    paddingAngle={2}
                    fill={color}
                    stroke="#333333"
                >
                    {data
                        ? data.map(entry => (
                              <Cell
                                  key={`${key}_${entry.label}`}
                                  fill={entry.color ? entry.color : '#ffffff'}
                              />
                          ))
                        : null}
                </Pie>
                <Tooltip />
            </PieChart>
        );
    }

    function renderInteractionPieChart(data, key) {
        const box_size = parseInt(w_height * 0.15, 10);

        if (data) {
            return (
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-1 mt-3"
                    width="100%"
                    height={box_size}
                >
                    {pieChart(data, key, <Tooltip />)}
                </ResponsiveContainer>
            );
        }
        return null;
    }

    function renderMainCard(content) {
        return (
            <Box boxShadow={boxShadow}>
                <InfoCard>
                    <MainInfo>{content}</MainInfo>
                </InfoCard>
            </Box>
        );
    }

    function renderClockCard() {
        return renderMainCard(
            <div className="info">
                <span className="value">{now ? now.time : '--:--'}</span>
                <div className="item">
                    <span className="title">{now ? now.date : '---'}</span>
                </div>
            </div>
        );
    }

    function renderMapCard() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Locations robots={robots} />
            </div>
        );
    }

    const int_this_month =
        interactions && interactions.current_month
            ? getInteractionLabels(interactions.current_month.values)
            : null;

    function renderTimeChart(title, data, labels, tooltip, views = []) {
        const chart = areaChart(data, labels, views, tooltip);

        return (
            <>
                <div className="card-title">{title}</div>
                <div className="area-chart-container">
                    <ResponsiveContainer
                        className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                        width="100%"
                        height={box_height}
                    >
                        {chart}
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    function renderInteractionsChartCard() {
        const data =
            interactionData && interactionData.list ? interactionData.list : [];
        const labels = interactionData ? interactionData.labels : [];
        const tooltip = <CustomInteractionTooltip />;
        const bars = [
            {
                key: 'total',
                color: colors.primary,
                dot: lighten(0.1, colors.primary),
                fill: `${lighten(0.1, colors.primary)}`,
            },
        ];

        return (
            <div className="col-12" style={{ height: '100%' }}>
                <Box boxShadow={boxShadow}>
                    <TimeChart
                        className="jr-overview-row"
                        style={{ height: '100%' }}
                    >
                        <div className="col-12 chart-area">
                            {renderTimeChart(
                                <LocaleMessage msg="rc3.dashboard.robot_interactions" />,
                                data,
                                labels,
                                tooltip,
                                bars
                            )}
                        </div>
                    </TimeChart>
                </Box>
            </div>
        );
    }

    function renderInteractionsPie() {
        const ints_month =
            int_this_month && int_this_month.total ? int_this_month.total : {};

        const interaction_rates =
            interactionData && interactionData.rate ? interactionData.rate : {};

        return renderMainCard(
            <>
                <div className="info">
                    <span className="jr-fs-sm title">
                        <LocaleMessage msg="rc3.dashboard.robot_interactions" />
                    </span>

                    {renderInteractionPieChart(
                        interaction_rates.this_month,
                        'month'
                    )}
                    <span className="big-value mt-3">
                        {ints_month.value || '0'}
                    </span>
                    <div className="item">
                        <span className="title">
                            <LocaleMessage msg="rc3.dashboard.this_month" />
                        </span>
                    </div>
                </div>

                <div className="info mt-3">
                    {renderInteractionPieChart(
                        interaction_rates.this_month,
                        'month'
                    )}
                    <span className="big-value mt-3">
                        {ints_month.value || '0'}
                    </span>
                    <div className="item">
                        <span className="title">In the Last 24h</span>
                    </div>
                </div>
            </>
        );
    }

    function renderInfoCard(card) {
        return renderMainCard(
            <div className="info" key={card.key}>
                <span className="value">{card.value}</span>
                <div className="item">
                    <span className="title">{card.label}</span>
                </div>
            </div>
        );
    }

    function renderInteractionsBox(w) {
        return (
            <InteractionsCard
                key="card_interactions"
                width={w}
                colors={colors}
                windowSize={windowSize}
                locale={loc}
                data={lastInteractions}
            />
        );
    }

    const left_cards = [
        {
            key: 'pluginspaces',
            value: counters.pluginspaces ? counters.pluginspaces.active : 0,
            label: 'Active Pluginspaces',
        },
        {
            key: 'robots',
            value: robots ? robots.length : '',
            label: 'Active Robots',
        },
        {
            key: 'users',
            value: counters.users ? counters.users.active : 0,
            label: 'Active Users',
        },
    ];

    return (
        <CentralPanel>
            <Container>
                <div
                    className="row"
                    style={{
                        width: '100%',
                        height: '55%',
                    }}
                >
                    <div
                        className="col-2 fullsize"
                        style={{
                            paddingLeft: '20px',
                        }}
                    >
                        {renderClockCard()}
                        {left_cards.map(card => {
                            return renderInfoCard(card);
                        })}
                    </div>

                    <div className="col-8 fullsize">{renderMapCard()}</div>
                    <div
                        className="col-2 fullsize"
                        style={{
                            paddingLeft: '20px',
                        }}
                    >
                        {renderInteractionsPie()}
                    </div>
                </div>
                <div
                    className="row"
                    style={{
                        width: '100%',
                        height: '35%',
                    }}
                >
                    <div className="col-md-6 col-12">
                        {renderInteractionsChartCard()}
                    </div>
                    <div className="col-md-6 col-12">
                        {renderInteractionsBox('col-12')}
                    </div>
                </div>
            </Container>
        </CentralPanel>
    );
}

Dashboard.propTypes = {
    settings: PropTypes.object,
    counters: PropTypes.object,
    robots: PropTypes.array,
    pluginspaces: PropTypes.number,
    users: PropTypes.number,
    requestError: PropTypes.func,
};

Dashboard.defaultProps = {
    settings: {},
    counters: {},
    robots: [],
    pluginspaces: 0,
    users: 0,
    requestError: () => {},
};
