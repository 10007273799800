import { darken } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 575px) {
        padding: 0px;
    }
`;

export const Column = styled.div`
    width: 60%;
    height: 100%;
    min-width: 600px;
    margin-top: 30px;

    @media screen and (max-width: 575px) {
        margin-top: 0px;
        padding: 0px 20px;
        width: 100%;
        min-width: 100%;
    }
`;

export const BorderedArea = styled.div`
    padding: 15px 15px;
    border: 1px solid ${props => props.theme.palette.primary.main};
    border-radius: 3px;
    color: #fff !important;
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
        color: #fff !important;
    }

    h3 {
        color: #fff !important;
    }

    .row {
        margin: 10px 0px;
    }

    .MuiFormLabel-root {
        color: #fff !important;
    }

    .MuiOutlinedInput-root {
        color: #fff !important;
        border-color: ${props => props.theme.palette.primary.main} !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.palette.primary.main} !important;
    }

    .space-between {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const IconParameter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 0px;
    padding: 0px;

    :hover {
        cursor: pointer;
    }

    .move {
        :hover {
            cursor: move;
        }
    }
`;

export const PresentationModeArea = styled.div`
    color: #fff;

    h4 {
        color: #fff;
    }
    .chat-main-footer {
        border-top: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        z-index: 1000;
    }

    textarea {
        border-radius: 1.5vh;
        border: 1px solid ${props => props.theme.palette.primary.main} !important;
        box-shadow: none;
        padding: 1vh 2vh 1vh 2vh;
    }

    .MuiIconButton-label {
        color: ${props => props.theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
        padding-right: 0px;
    }

    .MuiSelect-icon {
        display: none;
    }

    .presentation-input {
        display: flex;
        align-items: center;
        padding: 0px;

        .no-margin: {
        }
    }
`;

export const MessageShortcut = styled.div`
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 5px;
    color: #fff;
    padding: 12px;
    font-weight: ${props => {
        return props.bold ? 'bold' : 'regular';
    }};

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    :hover {
        cursor: pointer;
        font-weight: bold;
        border: 1px solid #fff;
    }
`;

export const DarkDialog = styled.div`
    color: #fff !important;
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    border-bottom: 0.5vh solid ${props => props.theme.palette.primary.main};
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return `${darken(0.25, color)}`;
    }};
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiDialogTitle-root {
        h2 {
            color: #fff !important;
        }
    }
`;
