/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function Sections({
    linkRoot,
    allowNew,
    breadcrumbs,
    filter,
    infos,
}) {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const [sections, setSections] = useState([]);

    const [view, setView] = useState('grid');
    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const open = Boolean(anchorEl);

    const headers_sections = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        {
            id: 'description',
            label: <LocaleMessage msg="table.headers.description" />,
        },
        {
            id: 'location_zone',
            label: <LocaleMessage msg="table.headers.location_name" />,
        },
        {
            id: 'user_count',
            label: <LocaleMessage msg="table.headers.user_count" />,
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`sections${filter}`)
            .then(response => {
                const list = response.data.map(s => {
                    const updated = new Date(s.updated);
                    return {
                        ...s,
                        location_id: s.location ? s.location.id : null,
                        location_name: s.location ? s.location.name : null,
                        zone_name: s.zone ? s.zone.name : null,
                        location_zone:
                            s.location && s.location.name
                                ? `${s.location.name}${
                                      s.zone && s.zone.id
                                          ? ` - ${s.zone.name}`
                                          : ''
                                  }`
                                : '',
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                    };
                });
                setSections(list);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`${linkRoot}/sections/${_id}`);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        history.push(`${linkRoot}/sections/${_id}`);
    }

    async function removeSection() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`/sections/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.groupuser.sections.list.delete_success" />
                );

                await loadItems();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function sectionCard(s) {
        const p = s.permission;
        const p_label = p
            ? `user.permission.${p}`
            : 'user.permission.no_member';

        return (
            <div className="col-md-3 col-sm-6 mb-5" key={s.id}>
                <Link to={`${linkRoot}/sections/${s.id}`}>
                    <CardItem
                        title={s.name}
                        optionsClick={event => handleClick(event, s.id)}
                        updated={s.updated}
                        img={{
                            url: s.file && s.file.url ? s.file.url : null,
                            name: s.name,
                        }}
                        position="center"
                        fit="contain"
                        colorBackground
                        group={
                            infos.permission ? (
                                <LocaleMessage msg={p_label} />
                            ) : null
                        }
                    >
                        <>
                            <div className="card-subtitle">
                                {infos.members ? (
                                    <>
                                        <span>{`${s.user_count} `}</span>
                                        <LocaleMessage msg="table.headers.user_count" />
                                    </>
                                ) : null}
                                {s.location_zone ? (
                                    <>
                                        <br />
                                        <small>{`${s.location_zone}`}</small>
                                    </>
                                ) : null}
                            </div>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {sections.map(l => sectionCard(l))}
                </div>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleDeleteOpen(e, currItem)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={<LocaleMessage msg="page.sections.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeSection(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const rowActions = allowNew
            ? [
                  {
                      id: 'delete',
                      label: <LocaleMessage msg="button.delete" />,
                      icon: <MdDelete />,
                      action: (e, _id) => handleDeleteOpen(e, _id),
                  },
              ]
            : [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headers_sections}
                    data={sections}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleTableRowClick(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.sections.list.title" />}
            currentPage={<LocaleMessage msg="page.sections.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...breadcrumbs,
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div className="body-top-controls">
                    <div className="col-3" style={{ padding: '0px' }}>
                        <NewItemButton
                            link={`${linkRoot}/sections/new`}
                            text={
                                <LocaleMessage msg="page.sections.list.add_location" />
                            }
                            disabled={!allowNew}
                            disabledMessage="permission"
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}

Sections.defaultProps = {
    breadcrumbs: [],
    allowNew: false,
    infos: {
        members: false,
        permission: false,
    },
};

Sections.propTypes = {
    linkRoot: PropTypes.string.isRequired,
    filter: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
    allowNew: PropTypes.bool,
    infos: PropTypes.object,
};
