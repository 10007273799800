/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdClose, MdContentCopy } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

export default function ShowInvite({ setToast, handleDialogClose, invite }) {
    function handleCopy() {
        setToast({
            color: 'info',
            body: (
                <LocaleMessage msg="page.applications.invite_manager.invites.copied" />
            ),
        });
    }

    return (
        <Dialog open onClose={handleDialogClose} maxWidth="md" fullWidth>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0px 15px 0px 0px',
                }}
            >
                <DialogTitle>
                    <LocaleMessage msg="page.applications.invite_manager.invites.created" />
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    style={{ padding: '5px' }}
                >
                    <MdClose size={20} />
                </IconButton>
            </div>
            <DialogContent className="mb-5">
                <Card>
                    <CardContent
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '20px',
                            backgroundColor: '#f5f5f5',
                            fontSize: '14px',
                        }}
                    >
                        <p style={{ margin: '0px' }}>{invite}</p>
                        <CopyToClipboard
                            text={invite}
                            onCopy={() => handleCopy()}
                        >
                            <Button
                                variant="outlined"
                                startIcon={<MdContentCopy />}
                            >
                                <LocaleMessage msg="button.copy" />
                            </Button>
                        </CopyToClipboard>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
}

ShowInvite.defaultProps = {
    invite: '',
};

ShowInvite.propTypes = {
    setToast: PropTypes.func.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    invite: PropTypes.string,
};
