/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

import { MediaArea, ParameterArea } from '../styles';

const mediatype_list = [
    {
        value: 'no-media',
        label_code: 'label.form.no_media',
        allow_all: true,
    },
    {
        value: 'image',
        label_code: 'list.content.type.images',
        allow_all: true,
    },
    {
        value: 'face',
        label_code: 'list.content.type.faces',
        allow_all: true,
    },
];

export default function BehaviourContent({
    group,
    galleries,
    content,
    updateContent,
    handleError,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [galleryList, setGalleryList] = useState([]);
    const [imageGalleries, setImageGalleries] = useState([]);
    const [currGallery, setCurrGallery] = useState(null);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    const [movementList, setMovementList] = useState([]);

    const behaviour_settings = content.behaviour ? content.behaviour : {};
    const screen_settings = behaviour_settings.screen
        ? behaviour_settings.screen
        : {};

    async function loadMovements(g_list = []) {
        const dance_gallery = g_list.find(g => {
            return g.type === 'dances';
        });
        if (dance_gallery) {
            setIsLoading(true);
            await api
                .get(`albums/${dance_gallery.id}/files`)
                .then(response => {
                    const files = response.data;
                    const list = files.map(m => {
                        const obj = m.object;
                        return {
                            id: obj.id,
                            name: obj.name,
                        };
                    });
                    setMovementList(list);
                })
                .catch(error => handleError(error));
        } else {
            setMovementList([]);
        }
        setIsLoading(false);
    }

    async function loadFiles(gallery) {
        if (gallery) {
            setIsLoading(true);
            await api
                .get(`albums/${gallery}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
        }
        setIsLoading(false);
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group && (g.group.id === '*' || g.group.id === group)
                    );
                });
                setImageGalleries(filtered);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function updateSettings(key, data) {
        updateContent({
            ...content,
            [key]: data,
        });
    }

    function onMediaTypeChange(type) {
        if (!type) {
            setGalleryList([]);
        } else {
            const keys = {
                face: 'faces',
            };
            const defaults = (keys[type] && galleries[keys[type]]) || [];
            const list = [
                ...defaults,
                ...(type === 'image' ? imageGalleries : []),
            ];
            setGalleryList(list);

            const currGalleryObj = list.find(g => {
                return g.id === currGallery;
            });

            if (!currGalleryObj) {
                setCurrGallery(list[0] ? list[0].id : '');
            }
        }
    }

    useEffect(() => {
        loadGalleryList();
    }, [group]);

    useEffect(() => {
        onMediaTypeChange(screen_settings.type);
    }, [screen_settings.type, imageGalleries]);

    useEffect(() => {
        loadFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        loadMovements(galleries.dances);
    }, [galleries.dances]);

    // Dance ending
    function renderEndForm() {
        return (
            <ParameterArea
                className="col-12 row p-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="col-md-8 col-12 pt-3 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.dances.form.behaviour.sync_end" />
                        </InputLabel>
                        <Select
                            value={
                                behaviour_settings.dance_sync
                                    ? behaviour_settings.dance_sync
                                    : 'movement-end'
                            }
                            onChange={event =>
                                updateSettings('behaviour', {
                                    ...behaviour_settings,
                                    dance_sync: event.target.value,
                                })
                            }
                        >
                            <MenuItem value="movement-end">
                                <LocaleMessage msg="page.dances.form.behaviour.sync_end.movement" />
                            </MenuItem>
                            <MenuItem value="audio-end">
                                <LocaleMessage msg="page.dances.form.behaviour.sync_end.audio" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </ParameterArea>
        );
    }

    // Movements
    function renderMovementForm() {
        return (
            <ParameterArea
                className="col-12 row p-3 mb-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.dances.form.behaviour.movement" />
                </strong>
                <div className="col-md-8 col-12 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="label.form.movement" />
                        </InputLabel>
                        <Select
                            value={
                                behaviour_settings.movement_id
                                    ? behaviour_settings.movement_id
                                    : 'no-movement'
                            }
                            onChange={event =>
                                updateSettings('behaviour', {
                                    ...behaviour_settings,
                                    movement_id: event.target.value,
                                })
                            }
                        >
                            <MenuItem value="random">
                                <LocaleMessage msg="label.form.random_movement" />
                            </MenuItem>
                            {movementList.map(m => (
                                <MenuItem key={m.id} value={m.id}>
                                    {m.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </ParameterArea>
        );
    }

    // Screen
    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(async () => {
                toast.success(<LocaleMessage msg="message.media_uploaded" />);
                await loadFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const file_url = file.url;
        const f_properties = file_url.split('.');
        const f_format = f_properties[f_properties.length - 1];

        const file_obj = {
            file_id: file.id,
            url: file.url,
            image: file.path,
            format: file.format || f_format,
        };

        switch (screen_settings.type) {
            case 'face': {
                updateSettings('behaviour', {
                    ...behaviour_settings,
                    screen: {
                        type: 'face',
                        face: file.name,
                        file: file_obj,
                    },
                });
                break;
            }
            case 'image': {
                updateSettings('behaviour', {
                    ...behaviour_settings,
                    screen: {
                        type: 'image',
                        file: file_obj,
                    },
                });
                break;
            }
            default:
                break;
        }
        setMediaDialogOpen(false);
    }

    function renderGallery(type) {
        const currGalleryObj = galleryList.find(g => {
            return g.id === currGallery;
        });

        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        {type === 'face' ? (
                            <LocaleMessage msg="list.galleries.type.faces" />
                        ) : (
                            <LocaleMessage msg="list.galleries.type.images" />
                        )}
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleryList.map(g => {
                            return (
                                <MenuItem key={g.id} value={g.id}>
                                    {!g.editable ? (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                <LocaleMessage msg="message.open_all.short" />
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    ) : (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                g.group &&
                                                g.group.id &&
                                                g.group.id !== '*' ? (
                                                    g.group.name
                                                ) : (
                                                    <LocaleMessage msg="message.all_groups.short" />
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    )}

                                    {g.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={currGalleryObj.editable}
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type =
            screen_settings.type && screen_settings.type === 'face'
                ? 'face'
                : 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => setMediaDialogOpen(false)}
                title={
                    g_type === 'face' ? (
                        <LocaleMessage msg="list.galleries.type.faces" />
                    ) : (
                        <LocaleMessage msg="list.galleries.type.images" />
                    )
                }
                content={renderGallery(g_type)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderCurrImage() {
        const file = screen_settings.file || {};
        return (
            <MediaArea className="col-md-8 col-12 mb-3">
                {file && file.url ? (
                    <Card className="img-card">
                        <img src={file.url} alt={file.id} />
                    </Card>
                ) : null}

                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setMediaDialogOpen(true)}
                >
                    <LocaleMessage msg="label.form.change_image" />
                </Button>
            </MediaArea>
        );
    }

    function renderScreenForm() {
        return (
            <ParameterArea
                className="col-12 p-3 mb-3"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.dances.form.behaviour.screen" />
                </strong>
                <div className="col-md-8 col-12 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.dances.form.behaviour.screen.type" />
                        </InputLabel>
                        <Select
                            value={screen_settings.type || 'no-media'}
                            onChange={event => {
                                const type = event.target.value;
                                if (screen_settings.type !== type) {
                                    updateSettings('behaviour', {
                                        ...behaviour_settings,
                                        screen: {
                                            type,
                                            file: null,
                                        },
                                    });
                                    if (type !== 'no-media') {
                                        setMediaDialogOpen(true);
                                    }
                                }
                            }}
                        >
                            {mediatype_list.map(t => {
                                return t.allow_all ? (
                                    <MenuItem key={t.value} value={t.value}>
                                        <LocaleMessage msg={t.label_code} />
                                    </MenuItem>
                                ) : null;
                            })}
                        </Select>
                    </FormControl>
                </div>
                {screen_settings.type && screen_settings.type !== 'no-media'
                    ? renderCurrImage()
                    : null}
            </ParameterArea>
        );
    }

    // const r_available = robot_settings[r_type] || [];
    // const filtered_settings = action_contents[action_type]
    //     ? action_contents[action_type].filter(s => {
    //           return r_available.includes(s);
    //       })
    //     : [];

    // const allow_screen = filtered_settings.includes('screen');

    return (
        <>
            {mediaDialogOpen ? renderMediaDialog() : null}
            {renderScreenForm()}
            {renderMovementForm()}
            {renderEndForm()}
            {/* {allow_screen ? renderScreenForm() : null}
                {filtered_settings.includes('movement')
                    ? renderMovementForm()
                    : null} */}
        </>
    );
}

BehaviourContent.defaultProps = {
    content: {},
    galleries: {},
    group: '*',
};

BehaviourContent.propTypes = {
    group: PropTypes.string,
    content: PropTypes.object,
    galleries: PropTypes.object,
    updateContent: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
};
