/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const PAGE_VIEW_KEY = 'locations';
const PAGE_VIEW_DEF = 'grid';

export default function Locations({ linkRoot, allowNew, breadcrumbs }) {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const date_loc = getDateLocale(settings);

    const [locations, setLocations] = useState([]);

    const [view, setView] = useState(
        page_settings[PAGE_VIEW_KEY] || PAGE_VIEW_DEF
    );
    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);

        await api
            .get(`locations`)
            .then(response => {
                const data = response.data.map(l => {
                    const updated = new Date(l.updated);
                    return {
                        ...l,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        group_name:
                            l.group && l.group.name ? (
                                l.group.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                        formatted_address:
                            l.address && l.address.street && l.address.city
                                ? l.address.formatted
                                : '',
                    };
                });

                setLocations(data);
            })
            .catch(error => requestError(error));

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`${linkRoot}/locations/${_id}`);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleTableRowClick(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        history.push(`${linkRoot}/locations/${_id}`);
    }

    async function deleteLocation() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`locations/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.locations.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function locationCard(l) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={l.id}>
                <Link to={`${linkRoot}/locations/${l.id}`}>
                    <CardItem
                        title={l.name}
                        optionsClick={event => handleClick(event, l.id)}
                        updated={l.updated}
                        img={{
                            url: l.file && l.file.url ? l.file.url : null,
                            name: l.name,
                        }}
                        group={
                            l.group_name || (
                                <LocaleMessage msg="message.all_groups.short" />
                            )
                        }
                        position="center"
                    >
                        <>
                            <div className="card-subtitle">
                                <small>{l.formatted_address}</small>
                                <br />
                            </div>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {locations.map(l => locationCard(l))}
                </div>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={<LocaleMessage msg="page.locations.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteLocation(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'formatted_address',
                label: <LocaleMessage msg="table.headers.address" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <>
                <div
                    className="mb-5"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={locations}
                        orderColumn="name"
                        setCurrItem={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.locations.list.title" />}
            currentPage={<LocaleMessage msg="page.locations.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...breadcrumbs,
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div className="body-top-controls">
                    <div className="col-3" style={{ padding: '0px' }}>
                        <NewItemButton
                            link={`${linkRoot}/locations/new`}
                            text={
                                <LocaleMessage msg="page.locations.list.add_location" />
                            }
                            disabled={!allowNew}
                            disabledMessage="permission"
                        />
                    </div>

                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_VIEW_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}

Locations.defaultProps = {
    breadcrumbs: [],
    allowNew: false,
};

Locations.propTypes = {
    linkRoot: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
    allowNew: PropTypes.bool,
};
