/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { MdCollections, MdVideoLibrary, MdSettings } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CarouselDialog from '~/components/CarouselDialog';
import GroupSelector from '~/components/Form/GroupSelector';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import CheckGroupPermission from '~/util/CheckGroupPermission';

const gallery_types = {
    images: {
        value: 'images',
        icon: MdCollections,
    },
    audios: {
        value: 'audios',
        icon: MdVideoLibrary,
    },
};

export default function GalleryForm(props) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user || null);
    const user_settings = useSelector(state => state.settings || null);
    const { active } = user_settings;
    const permissions = CheckGroupPermission(user || {}, '*');
    const adminMode = permissions.admin;

    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        type: 'images',
        group_id: active ? active.id : '*',
    });
    const [fileBody, setFileBody] = useState({});
    const [media, setMedia] = useState([]);
    const [deleteArray, setDeleteArray] = useState([]);

    const [carouselOpen, setCarouselOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [currItem, setCurrItem] = useState();
    const [currIndex, setCurrIndex] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        await api
            .get(`albums/${id}/files`)
            .then(response => {
                const files = response.data;
                setMedia(files);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadGallery(_id) {
        if (_id === 'new') {
            setOperation('create');
            setBody({ group_id: active.id });
        } else {
            setOperation('update');
            await api
                .get(`albums/${_id}`)
                .then(response => {
                    const g = response.data;
                    setBody({
                        group_id: g.group ? g.group.id : '*',
                        name: g.name,
                        description: g.description,
                        type: g.type,
                    });
                    loadItems();
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadGallery(id);
    }, [id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`albums`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.galleries.form.create_success" />
                    );

                    history.push(`/galleries/${response.data.id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`albums/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.galleries.form.update_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    async function onFileUpload(e) {
        const fileList = e.target.files;
        const data = new FormData();

        setIsLoading(true);

        Array.from(fileList).forEach(file => {
            data.append('files', file);
        });

        await api
            .post(`albums/${id}/files`, data)
            .then(async () => {
                toast.success(<LocaleMessage msg="message.media_uploaded" />);
                await loadItems(id);
            })
            .catch(error => requestError(error));
    }

    async function deleteItem(item_id) {
        setIsLoading(true);
        setDeleteDialogOpen('');
        setCurrIndex(0);
        setCarouselOpen(false);
        await api
            .delete(`albums/${id}/files/${item_id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.galleries.form.media_deleted" />
                );
                await loadItems(id);
            })
            .catch(error => requestError(error));
    }

    async function deleteMulti(items) {
        setIsLoading(true);
        setDeleteDialogOpen('');
        setDeleteArray([]);
        setCarouselOpen(false);
        const file_list = JSON.stringify(items);
        await api
            .delete(`albums/${id}/files/multi?files=${file_list}`, {
                files: items,
            })
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.galleries.form.media_deleted" />
                );
                await loadItems(id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function editItem(item_id) {
        setIsLoading(true);
        setEditDialogOpen(false);
        await api
            .put(`albums/${id}/files/${item_id}`, fileBody)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.galleries.form.media_updated" />
                );
                await loadItems(id);
            })
            .catch(error => requestError(error));
    }

    function handleDeleteDialogClose(event) {
        event.stopPropagation();
        setCarouselOpen(false);
        setDeleteDialogOpen('');
    }

    function handleEditDialogClose(event) {
        event.stopPropagation();
        setEditDialogOpen(false);
    }

    function handleItemClick(event, index) {
        setCurrItem(index);
        setCurrIndex(index);
        setDeleteDialogOpen('');
        setCarouselOpen(true);
    }

    function handleClose() {
        setCarouselOpen(false);
    }

    function handleDeleteClick(event, index) {
        event.stopPropagation();
        setCurrItem(index);
        setDeleteDialogOpen('single');
    }

    function handleEditClick(event, index) {
        event.stopPropagation();
        setCurrItem(index);
        const originalFile = media.find(m => {
            return m.id === index;
        });
        setFileBody({
            name: originalFile.name,
        });

        setEditDialogOpen(true);
    }

    function buildEditDialog() {
        return (
            <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <LocaleMessage msg="page.galleries.form.label.rename" />
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        label={
                            <LocaleMessage msg="page.galleries.form.label.file_name" />
                        }
                        type="text"
                        fullWidth
                        value={fileBody.name || ''}
                        onChange={event =>
                            setFileBody({
                                ...fileBody,
                                name: event.target.value,
                            })
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setEditDialogOpen(false)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button onClick={() => editItem(currItem)} color="primary">
                        <LocaleMessage msg="button.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildDeleteDialog() {
        return (
            <div>
                <Dialog
                    open={!!deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <LocaleMessage
                            msg={
                                deleteDialogOpen === 'single'
                                    ? 'message.file.delete.title'
                                    : 'page.galleries.form.label.delete_files.title'
                            }
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LocaleMessage msg="message.undone.content" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteDialogOpen('')}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                if (deleteDialogOpen === 'single') {
                                    deleteItem(currItem);
                                } else {
                                    deleteMulti(deleteArray);
                                }
                            }}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.delete" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderMediaView() {
        const type = gallery_types[body.type];
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={
                        <LocaleMessage msg="page.galleries.form.media_title" />
                    }
                    Icon={type && type.icon ? type.icon : MdCollections}
                    hide
                >
                    <div className="mb-5">
                        <div
                            style={{
                                height: '100%',
                                padding: '20px',
                                backgroundColor: '#fff',
                                minHeight: '40vh',
                            }}
                        >
                            <GalleryView
                                media={media}
                                allowNew
                                allowDelete={false}
                                fileUpload={e => onFileUpload(e)}
                                multiple
                                deleteFile={(e, _id) =>
                                    handleDeleteClick(e, _id)
                                }
                                onClick={(e, _id) => handleItemClick(e, _id)}
                                type={body.type}
                                allowDeleteArray
                                deleteArray={deleteArray}
                                onDeleteArrayChanged={d => setDeleteArray(d)}
                            />
                        </div>
                        {deleteArray.length > 0 ? (
                            <div className="col-6 row">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setDeleteDialogOpen('multi')}
                                    fullWidth
                                    disabled={deleteArray.length === 0}
                                >
                                    <LocaleMessage msg="page.galleries.form.label.delete_files" />
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </CardSideBordered>
            </div>
        );
    }

    function renderMediaCarousel() {
        return (
            <CarouselDialog
                allowed={{ edit: true, delete: true }}
                type={body.type}
                open={carouselOpen}
                media={media}
                current={currIndex}
                editFile={(e, _id) => handleEditClick(e, _id)}
                deleteFile={(e, _id) => handleDeleteClick(e, _id)}
                handleCarouselClose={e => handleClose(e)}
            />
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.galleries.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.galleries.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/galleries',
                    title: <LocaleMessage msg="breadcrumbs.galleries" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.galleries.form.label.settings" />
                            }
                            Icon={MdSettings}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            id="gallery-name"
                                            label={
                                                <LocaleMessage msg="page.galleries.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {adminMode ? (
                                        <div className="col-md-6 col-12 mb-5">
                                            <GroupSelector
                                                value={body.group_id || ''}
                                                onChange={value =>
                                                    setBody({
                                                        ...body,
                                                        group_id: value,
                                                    })
                                                }
                                                disabled={
                                                    operation !== 'create'
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.galleries.form.label.type" />
                                            </InputLabel>
                                            <Select
                                                id="type"
                                                value={
                                                    body.type
                                                        ? body.type
                                                        : 'images'
                                                }
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        type:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={
                                                    operation === 'update'
                                                }
                                            >
                                                {Object.keys(gallery_types).map(
                                                    t => {
                                                        const type =
                                                            gallery_types[t];
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    type.value
                                                                }
                                                                key={type.value}
                                                            >
                                                                <LocaleMessage
                                                                    msg={`list.galleries.type.${type.value}`}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-12 col-12 mb-5">
                                        <TextField
                                            id="gallery-description"
                                            label={
                                                <LocaleMessage msg="page.galleries.form.label.description" />
                                            }
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                            fullWidth
                                            multiline
                                        />
                                    </div>
                                    <div className="col-12 mt-3 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                </form>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                {editDialogOpen ? buildEditDialog() : null}
                {operation === 'update' ? renderMediaView() : null}
                {operation === 'update' && carouselOpen
                    ? renderMediaCarousel()
                    : null}
            </>
        </PageContent>
    );
}

GalleryForm.propTypes = {
    match: PropTypes.object.isRequired,
};
