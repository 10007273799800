import styled from 'styled-components';

export const PreviewArea = styled.div`
    border: 2px solid #999;
    padding: 2px;
    width: 100%;
`;

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid #999;
    width: 100%;
    height: ${props => {
        const w = props.dimensions.width;
        const h = (9.0 / 16.0) * w;
        return `${parseInt(h, 10) || 0}px`;
    }};

    background: #000;
    color: #eee;

    .overlay-template {
        display: flex;
        align-items: center;

        .img_container {
            padding: 0px !important;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: ${props => {
                    const exp = props.settings
                        ? props.settings.expand_img
                        : false;
                    return exp ? 'cover' : 'contain';
                }};
            }
        }

        .overlay_container {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            z-index: 2;
            padding-top: 12%;
            padding-bottom: 8%;

            .text_container {
                height: fit-content;
                background-color: #ffffff88;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span {
                    color: #000;
                    max-width: 80%;
                    text-align: center;
                    word-wrap: break-word;
                }

                .title {
                    font-weight: bold;
                    font-size: ${props => {
                        const h = props.dimensions.height;
                        const s =
                            props.settings && props.settings.title_size
                                ? props.settings.title_size
                                : 'small';
                        const sizes = {
                            small: 0.015,
                            medium: 0.025,
                            large: 0.035,
                        };
                        return `${parseInt(h * sizes[s], 10) || 0}px`;
                    }};
                }

                .text {
                    font-style: italic;
                    font-size: ${props => {
                        const h = props.dimensions.height;
                        const s =
                            props.settings && props.settings.text_size
                                ? props.settings.text_size
                                : 'small';
                        const sizes = {
                            small: 0.01,
                            medium: 0.02,
                            large: 0.03,
                        };
                        return `${parseInt(h * sizes[s], 10) || 0}px`;
                    }};
                }
            }
        }
    }

    .side-template {
        display: flex;
        align-items: center;

        .img_container {
            padding: 0px !important;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: ${props => {
                    const exp = props.settings
                        ? props.settings.expand_img
                        : false;
                    return exp ? 'cover' : 'contain';
                }};
            }
        }

        .text_container {
            height: 100%;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                color: #000;
                max-width: 80%;
                text-align: center;
                word-wrap: break-word;
            }

            .title {
                font-weight: bold;
                font-size: ${props => {
                    const h = props.dimensions.height;
                    const s =
                        props.settings && props.settings.title_size
                            ? props.settings.title_size
                            : 'small';
                    const sizes = {
                        small: 0.02,
                        medium: 0.03,
                        large: 0.04,
                    };
                    return `${parseInt(h * sizes[s], 10) || 0}px`;
                }};
            }

            .text {
                font-style: italic;
                font-size: ${props => {
                    const h = props.dimensions.height;
                    const s =
                        props.settings && props.settings.text_size
                            ? props.settings.text_size
                            : 'small';
                    const sizes = {
                        small: 0.015,
                        medium: 0.025,
                        large: 0.035,
                    };
                    return `${parseInt(h * sizes[s], 10) || 0}px`;
                }};
            }
        }
    }

    .text-template {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            color: #ffffff;
            max-width: 80%;
            text-align: center;
            word-wrap: break-word;
        }

        .title {
            font-weight: bold;
            font-size: ${props => {
                const h = props.dimensions.height;
                const s =
                    props.settings && props.settings.title_size
                        ? props.settings.title_size
                        : 'small';
                const sizes = {
                    small: 0.03,
                    medium: 0.04,
                    large: 0.05,
                };
                return `${parseInt(h * sizes[s], 10) || 0}px`;
            }};
        }

        .text {
            font-style: italic;
            font-size: ${props => {
                const h = props.dimensions.height;
                const s =
                    props.settings && props.settings.text_size
                        ? props.settings.text_size
                        : 'small';
                const sizes = {
                    small: 0.025,
                    medium: 0.035,
                    large: 0.045,
                };
                return `${parseInt(h * sizes[s], 10) || 0}px`;
            }};
        }
    }
`;
