import styled from 'styled-components';

export const SpecialCard = styled.div`
    border-top: 5px solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    height: 100%;

    .plugin-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        color: ${props => props.theme.palette.primary.main};
    }
`;

export const NewCard = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5vh solid
        ${props => {
            const color = props.theme.palette.primary.main;
            return color;
        }};
    background: #fff;
    color: ${props => {
        const color = props.theme.palette.primary.main;
        return color;
    }};
    :hover {
        cursor: pointer;
    }

    .disabled {
        pointer-events: none;
        .card,
        .card-body {
            border: none;
            color: #999;
            background: #ddd;
        }
    }
`;
