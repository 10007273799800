/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import ReactLoading from 'react-loading';
import reactCSS from 'reactcss';

import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    Card,
    FormControlLabel,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@material-ui/core';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import ColorInput from '~/components/ColorInput';
import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';

import { MediaArea, ParameterArea } from '../styles';

const action_contents = {
    navigate: ['screen', 'led'],
    speak: ['movement', 'screen', 'led'],
    play_audio: ['movement', 'screen', 'led'],
    no_action: ['movement', 'screen', 'led'],
};

const robot_settings = {
    cruzr: ['movement', 'screen'],
    nao: ['movement', 'led'],
    temi: ['screen'],
};

const mediatype_list = [
    {
        value: 'no-media',
        label_code: 'label.form.no_media',
        allow_all: true,
    },
    {
        value: 'image',
        label_code: 'list.content.type.images',
        allow_all: true,
    },
    {
        value: 'face',
        label_code: 'list.content.type.faces',
        allow_all: false,
    },
];

export default function BehaviourConfig({
    scriptSettings,
    handleError,
    setToast,
    config,
    setConfig,
    robotTypes,
    currType,
    setCurrType,
    allowAction,
}) {
    const r_type = currType ? currType.slug : '';

    const { group } = scriptSettings;
    const group_id = group ? group.id : '*';

    const [internalLoading, setInternalLoading] = useState(false);

    const [galleryList, setGalleryList] = useState([]);
    const [imageGalleriesList, setImageGalleriesList] = useState([]);
    const [currGallery, setCurrGallery] = useState(null);
    const [fileList, setFileList] = useState([]);

    const [defaultGalleries, setDefaultGalleries] = useState({});
    const [movementList, setMovementList] = useState([]);
    const [showMovement, setShowMovement] = useState(false);

    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [colorPicker, setColorPicker] = useState({
        visible: false,
    });

    const action_type =
        config.action && config.action.type ? config.action.type : '';

    const behaviour_settings = config.behaviour || {};
    const all_behaviour =
        behaviour_settings && behaviour_settings.all
            ? behaviour_settings.all
            : {};
    const type_behaviour =
        behaviour_settings && behaviour_settings[r_type]
            ? behaviour_settings[r_type]
            : {};

    const all_screen = all_behaviour.screen || {};

    const movement = type_behaviour.movement || { class: 'no-movement' };
    const screen = type_behaviour.screen || {};
    const led = type_behaviour.led || {};

    const diffScreen =
        !!behaviour_settings.different_screen || robotTypes.length === 1;

    const styles = reactCSS({
        default: {
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    async function loadRobotDefaults(_id) {
        await api
            .get(`types/${_id}/defaults`)
            .then(response => {
                const defaults = response.data;

                const default_galleries = {};

                defaults.forEach(g => {
                    const { type } = g;
                    if (!default_galleries[type]) {
                        default_galleries[type] = [];
                    }

                    default_galleries[type].push(g);
                });
                setDefaultGalleries(default_galleries);
            })
            .catch(error => handleError(error));
    }

    async function loadFiles(_id) {
        if (_id) {
            setInternalLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const files = response.data;
                    setFileList(files);
                })
                .catch(error => handleError(error));
            setInternalLoading(false);
        } else {
            setFileList([]);
        }
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group &&
                        (g.group.id === '*' || g.group.id === group_id)
                    );
                });

                setImageGalleriesList(filtered);
            })
            .catch(error => handleError(error));
    }

    async function loadMovementList(content_id) {
        setShowMovement(
            content_id !== 'random' && content_id !== 'no-movement'
        );
        if (
            !content_id ||
            content_id === 'random' ||
            content_id === 'no-movement'
        ) {
            // setContentSettings('movement', {
            //     class: content_id,
            //     id: content_id,
            // });
        } else if (currType) {
            setInternalLoading(true);
            await api
                .get(`types/${currType.id}/albums/${content_id}/content`)
                .then(response => {
                    const items = response.data;
                    const list = items.map(m => {
                        const mov = m.object;
                        return {
                            id: m.object_id,
                            name: mov.name,
                        };
                    });
                    setMovementList(list);
                })
                .catch(error => handleError(error));
            setInternalLoading(false);
        }
    }

    function filterGalleries(type) {
        const defFaceGalleries = defaultGalleries.faces || [];
        const list = type === 'face' ? defFaceGalleries : imageGalleriesList;
        setGalleryList(list);
        setCurrGallery(list[0] ? list[0].id : null);
    }

    function setAllBehaviourBody(key, value) {
        setConfig({
            ...config,
            behaviour: {
                ...behaviour_settings,
                different_screen: false,
                all: {
                    ...type_behaviour,
                    [key]: value,
                },
            },
        });
    }

    function setBehaviourBody(key, value) {
        setConfig({
            ...config,
            behaviour: {
                ...behaviour_settings,
                different_screen: true,
                [r_type]: {
                    ...type_behaviour,
                    [key]: value,
                },
            },
        });
    }

    function setLed(key, value) {
        setBehaviourBody('led', {
            ...led,
            [key]: value,
        });
    }

    function setMovement(key, value) {
        setBehaviourBody('movement', {
            ...movement,
            [key]: value,
        });
    }

    useEffect(() => {
        loadGalleryList();
    }, []);

    useEffect(() => {
        if (currType && currType.id) {
            loadRobotDefaults(currType.id);
        } else {
            setDefaultGalleries({});
        }
    }, [currType]);

    useEffect(() => {
        loadFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        filterGalleries(screen.type);
    }, [screen.type, imageGalleriesList, defaultGalleries]);

    useEffect(() => {
        loadMovementList(movement.class);
    }, [movement.class]);

    // LEDs
    function handleColorClick() {
        setColorPicker({
            visible: !colorPicker.visible,
        });
    }

    function handleColorClose() {
        setColorPicker({ ...colorPicker, visible: false });
    }

    function handleColorChange(c) {
        setLed('color', c.hex);
    }

    function renderLEDForm() {
        return (
            <ParameterArea
                className="col-12 row p-3 mb-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.tools.scripts.step.form.behaviour.leds" />
                </strong>
                <div
                    className="col-md-6 col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <FormControlLabel
                        className="mb-3"
                        control={
                            <Switch
                                color="primary"
                                checked={led && led.active ? led.active : false}
                                value="led"
                                onChange={event =>
                                    setLed('active', event.target.checked)
                                }
                            />
                        }
                        label={
                            <LocaleMessage msg="page.tools.scripts.step.form.behaviour.leds.use" />
                        }
                    />
                    {led && led.active ? (
                        <ColorInput
                            title={
                                <LocaleMessage msg="page.tools.scripts.step.form.behaviour.leds.color" />
                            }
                            margin="mb-3"
                            color={led && led.color ? led.color : '#fff'}
                            handleColorClick={() => handleColorClick(led)}
                        />
                    ) : null}
                </div>
                {colorPicker.visible ? (
                    <div style={styles.popover}>
                        <div
                            style={styles.cover}
                            onClick={() => handleColorClose()}
                        />
                        <SketchPicker
                            disableAlpha
                            color={led && led.color ? led.color : '#fff'}
                            onChange={color => handleColorChange(color)}
                            onChangeComplete={color => {
                                handleColorChange(color);
                            }}
                        />
                    </div>
                ) : null}
            </ParameterArea>
        );
    }

    // Movements
    function onMovementChange(key, val) {
        setMovement(key, val);
    }

    function renderMovementForm() {
        const defMovementGalleries = defaultGalleries.movements || [];
        return (
            <ParameterArea
                className="col-12 row p-3 mb-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.tools.scripts.step.form.behaviour.movement" />
                </strong>
                <div className="col-md-5 col-12 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.tools.scripts.step.form.behaviour.movement.class" />
                        </InputLabel>
                        <Select
                            value={movement.class || 'no-movement'}
                            onChange={event =>
                                onMovementChange('class', event.target.value)
                            }
                        >
                            <MenuItem value="no-movement">
                                <LocaleMessage msg="label.form.no_movement" />
                            </MenuItem>
                            {defMovementGalleries.map(m => (
                                <MenuItem key={m.id} value={m.id}>
                                    {m.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {showMovement ? (
                    <div className="col-md-5 col-12 mb-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="label.form.movement" />
                            </InputLabel>
                            <Select
                                value={
                                    movement.movement_id
                                        ? movement.movement_id
                                        : 'no-movement'
                                }
                                onChange={event =>
                                    onMovementChange(
                                        'movement_id',
                                        event.target.value
                                    )
                                }
                            >
                                <MenuItem value="random">
                                    <LocaleMessage msg="label.form.random_movement" />
                                </MenuItem>
                                {movementList.map(m => (
                                    <MenuItem key={m.id} value={m.id}>
                                        {m.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                ) : null}
            </ParameterArea>
        );
    }

    // Screen
    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setInternalLoading(true);

        Array.from(files).forEach(file => {
            data.append('files', file);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(async () => {
                setToast({
                    color: 'success',
                    body: <LocaleMessage msg="message.media_uploaded" />,
                });
                await loadFiles(currGallery);
            })
            .catch(error => handleError(error));
        setInternalLoading(false);
    }

    function handleDifferentScreenSwitch(event) {
        const allow_different = event.target.checked;

        const update_config = {
            ...config,
            behaviour: {
                ...behaviour_settings,
                different_screen: allow_different,
                all: {
                    ...all_behaviour,
                    ...(!allow_different && { screen: {} }),
                },
            },
        };

        setConfig(update_config);
    }

    function handleItemClick(event, index) {
        const file = fileList[index];
        const file_url = file.url;
        const f_properties = file_url.split('.');
        const f_format = f_properties[f_properties.length - 1];

        const file_obj = {
            file_id: file.id,
            url: file.url,
            image: file.path,
            format: file.format || f_format,
        };

        const screen_settings = diffScreen ? screen : all_screen;

        switch (screen_settings.type) {
            case 'face': {
                setBehaviourBody('screen', {
                    ...screen,
                    type: 'face',
                    face: file.name,
                    file: file_obj,
                });
                break;
            }
            case 'image': {
                const new_screen = {
                    ...screen_settings,
                    type: 'image',
                    file: file_obj,
                };

                if (diffScreen) {
                    setBehaviourBody('screen', new_screen);
                } else {
                    setAllBehaviourBody('screen', new_screen);
                }
                break;
            }
            default:
                break;
        }
        setMediaDialogOpen(false);
    }

    function onMediaTypeChange(event) {
        const type = event.target.value;
        const screen_settings = diffScreen ? screen : all_screen;

        filterGalleries(type);

        if (!screen_settings.type || screen_settings.type !== type) {
            if (diffScreen) {
                setBehaviourBody('screen', {
                    type,
                });
            } else {
                setAllBehaviourBody('screen', {
                    type,
                });
            }

            if (type !== 'no-media') {
                setMediaDialogOpen(true);
            }
        }
    }

    function renderGallery(type) {
        const currGalleryObj = galleryList.find(g => {
            return g.id === currGallery;
        });

        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        {type === 'face' ? (
                            <LocaleMessage msg="list.galleries.type.faces" />
                        ) : (
                            <LocaleMessage msg="list.galleries.type.images" />
                        )}
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleryList.map(g => {
                            return (
                                <MenuItem key={g.id} value={g.id}>
                                    {!g.editable ? (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                <LocaleMessage msg="message.open_all.short" />
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    ) : (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            label={
                                                g.group &&
                                                g.group.id &&
                                                g.group.id !== '*' ? (
                                                    g.group.name
                                                ) : (
                                                    <LocaleMessage msg="message.all_groups.short" />
                                                )
                                            }
                                            style={{ marginRight: '10px' }}
                                        />
                                    )}

                                    {g.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {internalLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    allowNew={currGalleryObj.editable}
                                    multiple
                                    fileUpload={e => onFileUpload(e)}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        const g_type = screen.type && screen.type === 'face' ? 'face' : 'image';
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => setMediaDialogOpen(false)}
                title={
                    g_type === 'face' ? (
                        <LocaleMessage msg="list.galleries.type.faces" />
                    ) : (
                        <LocaleMessage msg="list.galleries.type.images" />
                    )
                }
                content={renderGallery(g_type)}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderCurrImage() {
        const screen_settings = diffScreen ? screen : all_screen;
        const file = screen_settings.file || {};
        return (
            <MediaArea className="col-md-6 col-12 mb-3">
                {file && file.url ? (
                    <Card className="img-card">
                        <img src={file.url} alt={file.id} />
                    </Card>
                ) : null}

                <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => setMediaDialogOpen(true)}
                >
                    <LocaleMessage msg="label.form.change_image" />
                </Button>
            </MediaArea>
        );
    }

    function renderScreenForm() {
        const screen_settings = diffScreen ? screen : all_screen;

        return (
            <ParameterArea
                className="col-12 p-3 mb-3"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <strong className="col-12 mb-3" style={{ textAlign: 'center' }}>
                    <LocaleMessage msg="page.tools.scripts.step.form.behaviour.screen" />
                </strong>
                <div className="col-md-5 col-12 mb-3">
                    <FormControl fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.tools.scripts.step.form.behaviour.screen.type" />
                        </InputLabel>
                        <Select
                            value={screen_settings.type || 'no-media'}
                            onChange={event => onMediaTypeChange(event)}
                        >
                            {mediatype_list.map(t => {
                                return t.allow_all ||
                                    diffScreen ||
                                    robotTypes.length === 1 ? (
                                    <MenuItem key={t.value} value={t.value}>
                                        <LocaleMessage msg={t.label_code} />
                                    </MenuItem>
                                ) : null;
                            })}
                        </Select>
                    </FormControl>
                </div>
                {screen_settings.type && screen_settings.type !== 'no-media'
                    ? renderCurrImage()
                    : null}
            </ParameterArea>
        );
    }

    function renderRTypeSettings() {
        const r_available = robot_settings[r_type] || [];
        const filtered_settings = action_contents[action_type]
            ? action_contents[action_type].filter(s => {
                  return r_available.includes(s);
              })
            : [];

        const r_screen = robotTypes.filter(r => {
            return (
                robot_settings[r.slug] &&
                robot_settings[r.slug].includes('screen')
            );
        });

        const allow_screen = filtered_settings.includes('screen');
        const multi_screen = r_screen && r_screen.length > 1;

        return (
            <>
                {multi_screen ? (
                    <>
                        <div className="col-12 row mt-2 mb-4 justify-content-center">
                            <div className="col-md-8 col-12 row mt-2">
                                <FormSwitch
                                    value={diffScreen}
                                    onChange={event => {
                                        handleDifferentScreenSwitch(event);
                                    }}
                                    label={
                                        <LocaleMessage msg="page.applications.conversation.condition.media.different" />
                                    }
                                />
                            </div>
                        </div>
                    </>
                ) : null}
                <AppConfigRobotType
                    className="col-12 mb-1"
                    robotTypes={robotTypes}
                    currType={currType}
                    setCurrType={t => setCurrType(t)}
                >
                    {allowAction ? (
                        <>
                            {allow_screen ? renderScreenForm() : null}
                            {filtered_settings.includes('movement')
                                ? renderMovementForm()
                                : null}
                            {filtered_settings.includes('led')
                                ? renderLEDForm()
                                : null}
                        </>
                    ) : (
                        <div
                            className="col-12 row mb-5"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <LocaleMessage msg="page.tools.scripts.step.form.action.skip" />
                        </div>
                    )}
                </AppConfigRobotType>
            </>
        );
    }

    return (
        <div
            className="col-12 row mt-3 mb-3"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            {mediaDialogOpen ? renderMediaDialog() : null}
            {action_type ? (
                renderRTypeSettings()
            ) : (
                <LocaleMessage msg="page.tools.scripts.step.form.action.choose_first" />
            )}
        </div>
    );
}

BehaviourConfig.defaultProps = {
    config: {},
    allowAction: true,
    currType: {},
};

BehaviourConfig.propTypes = {
    allowAction: PropTypes.bool,
    scriptSettings: PropTypes.object.isRequired,
    handleError: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object,
    setCurrType: PropTypes.func.isRequired,
};
