import { lighten, desaturate, shade } from 'polished';

export default function get(props) {
    const p_color = props.primary || '#fff';

    return [
        {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#0a2639',
                },
            ],
        },
        {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    gamma: 0.01,
                },
                {
                    lightness: 20,
                },
            ],
        },
        {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    saturation: -30,
                },
                {
                    lightness: -35,
                },
                {
                    weight: 2,
                },
                {
                    gamma: 0.8,
                },
            ],
        },
        {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    lightness: '-1',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#ffffff',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#000000',
                },
                {
                    weight: '1.50',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#000000',
                },
                {
                    weight: '1.50',
                },
            ],
        },
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
                {
                    color: desaturate(0.35, shade(0.35, p_color)),
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    saturation: 20,
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
                {
                    lightness: 20,
                },
                {
                    saturation: -20,
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    lightness: 10,
                },
                {
                    saturation: -30,
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#000000',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    saturation: 25,
                },
                {
                    lightness: 1,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: lighten(0.15, p_color),
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    lightness: '-42',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: shade(0.95, p_color),
                },
            ],
        },
    ];
}
