import numeral from 'numeral';

export default function getOperationTime(time, format = { hours: '2-digit' }) {
    // Seconds to Minutes
    const m_time = Math.floor(time / 60);

    // Active hours and minutes
    const t_hours = Math.floor(m_time / 60);
    const hours =
        format.hours === '2-digit' ? numeral(t_hours).format('00') : t_hours;

    const t_minutes = hours > 0 ? m_time % 60 : m_time;
    const minutes = numeral(t_minutes).format('00');

    const t_seconds = time - (hours * 60 * 60 + minutes * 60);
    const seconds = numeral(t_seconds).format('00');

    return {
        hours,
        minutes,
        seconds,
        text: `${hours}:${minutes}`,
        label: `${hours || '00'}:${minutes || '00'}:${seconds || '00'}`,
    };
}
