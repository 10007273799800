/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import {
    GoogleMap,
    Marker,
    useLoadScript,
    MarkerClusterer,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';

import mapStyle from '~/styles/map/map_style';

import m3 from '~/assets/markers/m3.png';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Container } from './styles';

const containerStyle = {
    width: '100%',
    height: '100%',
};

const defZoom = 2;
const defPosition = { lat: -50, lng: -130 };

export default function Locations({ robots }) {
    const [map, setMap] = useState(null);
    const [center] = useState({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState(defZoom);

    const colors = GetPluginspaceTheme(useTheme());
    const options = {
        styles: mapStyle(colors),
        disableDefaultUI: true,
        minZoom: 2,
        maxZoom: 14,
        restriction: {
            latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        },
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    });

    if (loadError) {
        return <div>Error...</div>;
    }

    if (!isLoaded) {
        return <div>Waiting...</div>;
    }

    function renderLocationRobots(clusterer, robot) {
        const position = robot.coordinates
            ? {
                  lat: Number(robot.coordinates.lat),
                  lng: Number(robot.coordinates.lng),
              }
            : defPosition;
        return (
            <Marker
                key={robot.id}
                className="marker"
                position={position}
                title={robot.name}
                label={robot.id}
                clusterer={clusterer}
            />
        );
    }

    return (
        <Container>
            <GoogleMap
                onLoad={m => {
                    setMap(m);
                }}
                onUnmount={() => setMap(null)}
                mapContainerStyle={containerStyle}
                zoom={zoom}
                center={center}
                options={options}
                onZoomChanged={() => {
                    setZoom(map ? map.getZoom() : defZoom);
                }}
                onRightClick={() => setZoom(defZoom)}
            >
                <MarkerClusterer
                    averageCenter
                    minimumClusterSize={1}
                    options={{
                        styles: [
                            {
                                url: m3,
                                width: 66,
                                height: 66,
                                textColor: '#fff',
                            },
                        ],
                    }}
                    zoomOnClick
                >
                    {clusterer =>
                        robots.map(r => {
                            return renderLocationRobots(clusterer, r);
                        })
                    }
                </MarkerClusterer>
            </GoogleMap>
        </Container>
    );
}

Locations.propTypes = {
    robots: PropTypes.object,
    locations: PropTypes.array.isRequired,
    robotLocations: PropTypes.object,
    handleChangeTab: PropTypes.func,
};

Locations.defaultProps = {
    robots: {},
    robotLocations: {},
    handleChangeTab: () => {},
};
