/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

const step_types = [
    {
        value: 'start',
        label: <LocaleMessage msg="page.tools.scripts.step.type.start" />,
    },
    {
        value: 'step',
        label: <LocaleMessage msg="page.tools.scripts.step.type.step" />,
    },
    {
        value: 'end',
        label: <LocaleMessage msg="page.tools.scripts.step.type.end" />,
    },
];

export default function BasicInfoConfig({ config, setConfig }) {
    return (
        <div
            className="col-md-10 col-12 row mt-3"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            <div className="col-md-6 col-12 mb-3">
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.tools.scripts.step.form.type" />
                    </InputLabel>
                    <Select
                        value={config.type || ''}
                        onChange={event =>
                            setConfig({
                                ...config,
                                type: event.target.value,
                            })
                        }
                    >
                        {step_types.map(t => (
                            <MenuItem key={t.value} value={t.value}>
                                {t.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="col-md-6 col-12 mb-3">
                <TextField
                    id="description"
                    label={
                        <LocaleMessage msg="page.tools.scripts.step.form.description" />
                    }
                    fullWidth
                    value={
                        config && config.description ? config.description : ''
                    }
                    onChange={event =>
                        setConfig({
                            ...config,
                            description: event.target.value,
                        })
                    }
                />
            </div>
        </div>
    );
}

BasicInfoConfig.defaultProps = {
    config: {},
};

BasicInfoConfig.propTypes = {
    config: PropTypes.object,
    setConfig: PropTypes.func.isRequired,
};
