export function changeAppRequest(app) {
    return {
        type: '@application/CHANGE_APP_REQUEST',
        payload: { app },
    };
}

export function changeAppSuccess(app) {
    return {
        type: '@application/CHANGE_APP_SUCCESS',
        payload: { app },
    };
}

export function changeAppFailure() {
    return {
        type: '@application/CHANGE_APP_FAILURE',
        payload: {},
    };
}

export function changeAppSettings(settings) {
    return {
        type: '@application/CHANGE_APP_SETTINGS',
        payload: { settings },
    };
}
